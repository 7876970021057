export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Sipariş Listem",
    "OrderList.Input.Search": "Sipariş numarası...",
    "OrderList.Label.Status": "Spocket Durumuna Göre Filtrele",

    "order.status.unpaid": "Ödenmemiş",
    "order.status.paid": "Ödenmiş",
    "order.status.processing": "İşlemde",
    "order.status.shipped": "Gönderildi",
    "order.status.cancelled": "İptal Edildi",
    "order.status.pending": "Beklemede",
    "order.status.partially_paid": "Kısmen Ödenmiş",
    "order.status.partially_refunded": "Kısmî Para İadesi Yapılmış",
    "order.status.refunded": "Para İadesi Yapılmış",
    "order.status.voided": "Geçersiz",
    "order.status.authorized": "Onaylı",
    "order.status.deleted": "Silindi",
    "order.status.pending_payment_confirmation": "Beklemede",

    "OrderList.Label.DownloadHistory": "Tüm sipariş geçmişini CSV biçiminde indir",
    "OrderList.Document.Title": "Siparişler - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Ürün Arayın",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Siparişler nasıl işleme alınır",
    "OrderList.EmptyOrders.Message.Loading.Title": "Siparişleriniz yükleniyor!",
    "OrderList.EmptyOrders.Message.Loading.Description": "Siparişleriniz yüklenirken lütfen bekleyin.",
    "OrderList.EmptyOrders.Message.Title": "Sipariş listeniz boş!",
    "OrderList.EmptyOrders.Message.Description":
      "Siparişlerinizi buradan yönetip işleme alabilirsiniz. İçe aktarma listenize ürün eklemeye başlamak için arama sayfasına geçin.",
    "OrderList.CheckBox.SelectAllOrders": "Tüm siparişleri seçin",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} sipariş seçildi",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} sipariş seçildi",
    "OrderList.CheckBox.BulkCheckoutOrders": "Siparişi toplu öde",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Siparişleri toplu öde",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Siparişleri Senkronize Et",
    "RefreshModal.Button.Cancel": "İptal Et",
    "RefreshModal.Button.Synchronize": "Senkronize Et",
    "RefreshModal.Modal.Body":
      "Siparişler mağazanız üzerinden otomatik senkronize edilir. Sadece, sipariş bilgisi eksikse veya güncellenmemişse senkronize edin. Lütfen 30 saniye sonra sayfayı yenileyin.",

    "Order.Label.OrderNumber": "Sipariş Numarası",
    "Order.Label.Date": "Tarih",
    "Order.Label.OrderStatus": "{{integrationName}} Sipariş Durumu",
    "Order.Table.Th.Product": "Ürün",
    "Order.Table.Th.Qty": "Adet",
    "Order.Table.Th.UnitPrice": "Birim Fiyatı",
    "Order.Table.Th.CheckoutDate": "Ödeme Tarihi",
    "Order.Table.Th.Action": "İşlem",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Müşteri Bilgilerini Göster",
    "CustomerModal.Modal.Title.CustomerInfo": "Müşteri Bilgileri",
    "CustomerModal.Modal.Body.CustomerInfo": "Müşteri Bilgileri",
    "CustomerModal.Modal.Body.Address": "Adres",

    "OrderLine.Button.Checkout": "Ödeme Yap",
    "OrderLine.Tooltip.PreviewInvoice": "Fatura Önizleme",
    "OrderLine.Tooltip.PaymentReceipt": "Ödeme Dekontu",
    "OrderLine.Tooltip.TrackOrder": "Sipariş Takibi",
    "OrderLine.Tooltip.OrderProcessing": "Sipariş işlemde",
    "OrderLine.Tooltip.OrderCancelled": "Sipariş iptal edildi",
    "OrderLine.Tooltip.PleaseConfirm": "Sipariş ödemenizi onaylamak için lütfen tıklayın",
    "OrderLine.Label.PurchaseEtsy": "Etsy üzerinden satın alın",
    "OrderLine.Paying": "Ödeniyor",
    "OrderLine.Failure": "Başarısız",
    "OrderLine.Sample": "Numune",
    "OrderLine.ErrorOrderRow":
      "Sipariş edilen liste kaydı sistemde bulunamadı! Lütfen destek ekibiyle iletişime geçin!",

    "ConfirmationModal.Label.OrderDetails": "Sipariş Detayları",
    "ConfirmationModal.Label.Total": "Toplam",
    "ConfirmationModal.Label.Shipping": "Teslimat",
    "ConfirmationModal.Label.TransactionFees": "İşlem Ücretleri",
    "ConfirmationModal.Tooltip.TransactionFees":
      "İşlem ücretlerine Spocket’in ödeme hizmeti sağlayıcısı, Stripe ve hizmet ücretleri dahil edilir.",
    "ConfirmationModal.Modal.OrderConfirmation": "Sipariş Onayı",
    "ConfirmationModal.Modal.Alert.Attention": "Dikkat!",
    "ConfirmationModal.Modal.Alert.Description": "Bu sipariş bir müşteri notu içeriyor.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Şu ürünün siparişini vermek üzeresiniz:",
    "ConfirmationModal.Modal.Table.Th.Name": "Adı",
    "ConfirmationModal.Modal.Table.Th.Price": "Fiyatı",
    "ConfirmationModal.Modal.Table.Th.Qty": "Adet",
    "ConfirmationModal.Modal.Table.Th.Total": "Toplam",
    "ConfirmationModal.Modal.Label.Note": "Satıcıya not",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Mesajınız",
    "ConfirmationModal.Button.PlaceOrder": "Sipariş ver",

    "PaymentCompleteModal.PaymentCompleted": "Ödeme Tamamlandı",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Ödeme için teşekkür ederiz. Satıcı sipariş konusunda bilgilendirildi, siparişiniz kısa süre içinde işleme alınacaktır. Sipariş durumunu güncelleyip, takip numarasını sipariş sayfanıza ekleyeceğiz.",
    "PaymentCompleteModal.PaymentIncomplete": "Siparişinizde bir eksiklik var!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Devam edebilmeniz için bankanıza ödeme onayı vermeniz gerekiyor",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Lütfen ödemeyi bu bağlantıyı kullanarak onaylayın.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Ödeme onaylandığında satıcı bilgilendirilecektir ve siparişiniz normal olarak işleme alınacaktır. Sipariş durumunuz ve takip güncellemeleri sipariş sayfanıza eklenecektir. Lütfen onay verdikten sonra sayfayı yenileyin.",

    "SetYourGoalsModal.Title": "Mağazaya eklenen ürün sayısı",
    "SetYourGoalsModal.Subtitle": "Tipik bir mağaza 25 ürünle satışa başlar",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} ürün",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} ürün",
    "SetYourGoalsModal.Button": "Hedef belirleyin ve stoksuz satışa başlayın",

    "YourGoalsBanner.Container.Description": "Hedefleriniz",
    "YourGoalsBanner.Header.Title": "İşletmeye başlarken",
    "YourGoalsBanner.Header.Subtitle": "Mağazanızı hazırlayın",
    "YourGoalsBanner.Progress.Completed": "Tamamlandı",
    "YourGoalsBanner.ProductPushedCount": "<0>Hedef: {{goalsNumberOfProduct}}</0> ürün satışa çıkarıldı",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Spocket’e Katılın",
    "YourGoalsBanner.CheckPoints.FirstSearch": "İlk arama",
    "YourGoalsBanner.CheckPoints.ProductsPushed":
      "{{count}} / {{goalsNumberOfProduct}} Ürün Satışa Çıkarıldı",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} Ürün Satışa Çıkarıldı",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} Ürün İçe Aktarıldı",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} Ürün İçe Aktarıldı",

    "Plan.Starter": "Başlangıç",
    "Plan.Professional": "Profesyonel",
    "Plan.Empire": "İmparator",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Otomatik AliExpress Stoksuz Satış",
    "Aliscraper.Subtitle":
      "Tek tıkla binlerce ürünü eTicaret mağazanıza aktarın ve stoksuz satış işletmenizi otomasyona bağlayın.",
    "Aliscraper.Features.Feature1": "Tek tıkla hızlı içe aktarma",
    "Aliscraper.Features.Feature2": "Spocket ile yüzlerce siparişi anında işleme alın",
    "Aliscraper.Features.Feature3": "7/24 Müşteri Desteği",
    "Aliscraper.Button": "Uzantıyı İndirin",
    "Aliscraper.Chrome": "Chrome Web Mağazası’nda Mevcut",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English

    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Mağazanızı bağlayın",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Başlamak için bir platform seçin",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Geçersiz mağaza adı",
    "ConnectStoreModal.ShopifyForm.Placeholder": "ör. BenimMağazam",
    "ConnectStoreModal.ShopifyForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.ShopifyForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Doğrulama anahtarınız:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Mağazanızı Spocket’e nasıl bağlayacaksınız",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Spocket eklentisini indirmek için:",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "tıklayın",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Eklentiyi eklentiler sayfanızdan etkinleştirin",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Şu adımları izleyin: WordPress Paneli > Spocket ",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Doğrulama anahtarınızı (yukarıda listelenmiştir) yapıştırıp “Kaydet” düğmesini tıklayın",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Bitti",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.WixForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.WixForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.FelexForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.FelexForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.FelexForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce Store URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Geçersiz mağaza adı",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "ör. BenimMağazam",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.BigcommerceForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.EcwidForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.EcwidForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.WhiteLabelForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.SquarespaceForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.SquareForm.FooterText": "Mağazanız yok mu?",
    "ConnectStoreModal.SquareForm.FooterLink": "Ücretsiz deneyin",
    "ConnectStoreModal.SquareForm.Button": "Mağaza Bağlayın",
    "ConnectStoreModal.FelexButton.Explanation":
      "Girişimcilerin 10 dakika içinde stoksuz satış mağazası açabilmesini sağlayan bir online mağaza platformu.",
    "ConnectStoreModal.DontHaveStore":  "Mağazanız yok mu?",
    "ConnectStoreModal.TryForFree": "Ücretsiz deneyin",

    "PaymentTab.Document.Title": "Ayarlar - Otomasyon - Spocket",
    "PaymentTab.Title.Billing": "Faturalandırma",
    "PaymentTab.Tooltip.Billing": "Sipariş işlemleri ve abonelik ödemeleri için kredi kartı kullanılır",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Paketinizi duraklatın/değiştirin",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Paketinizi değiştirin",
    "PaymentTab.AccordionItem.Button.Downgrade": "Alt Seviyeye Geçin",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Paketinizi duraklatın",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Mağazanız daha önce zaten duraklatılmış",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Paketinizi duraklatın",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Spocket hesabınızı geçici olarak duraklatın ve daha sonra verilerinizden hiçbir şey kaybetmeden geri dönün.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Daha sonra hatırlat",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Daha sonra hatırlat",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 gün önce",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Avantajlarımı koru ve bana üyeliğim yenilenmeden <1>{{daysBefore}}</1> hatırlat",
    "RemindMeLaterModal.Header.ReminderSet": "Hatırlatıcı kurun",
    "RemindMeLaterModal.Body.DaysBefore": "3 gün önce hatırlatma e-postası göndereceğiz",
    "RemindMeLaterModal.Body.ReminderSet":
      "Hatırlatıcınız kuruldu, paket avantajlarınız yenilenmeden <1>{{daysBefore}}</1> size hatırlatma e-postası göndereceğiz. O zamana dek girişimcilik maceranızın tadını çıkarın.",
    "RemindMeLaterModal.Button.Continue": "Devam Et",
    "PauseStoreModal.Title.PauseYourStore": "Mağazanızı Duraklatın",
    "PauseStoreModal.Description.PauseYourStore":
      "Bu tek seferlik tekliften faydalanın ve önümüzdeki 30 gün için paketinizin ücretini %60 azaltın",
    "PauseStoreModal.Button.PauseStore": "Mağazayı Duraklat",
    "PauseStoreModal.Description.StorePaused":
      "Mağazanız duraklatıldı, bir sonraki aylık abonelik ücretiniz %60 azaltılacak. Daha sonra düzenli ödemelerinizin devam edeceğini lütfen unutmayın.",
    "ConfirmPasswordModal.Title": "Lütfen Şifrenizi Onaylayın",
    "ConfirmPasswordModal.SubTitle": "Güvenlik nedenlerinden ötürü lütfen şifrenizi onaylayın",
    "ConfirmPasswordModal.Input.Placeholder": "Şifreniz",
    "ConfirmPasswordModal.Button.Cancel": "İptal Et",
    "ConfirmPasswordModal.Button.Confirm": "Onayla",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "tr",
    "Config.Plan.Starter": "Başlangıç",
    "Config.Plan.Professional": "Profesyonel",
    "Config.Plan.Empire": "İmparator",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Spocket ile daha hızlı büyüyün ",
    "UpgradeConfirmationModal.Body.Title": "Faturalandırma döngünüzü seçin",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "İlk",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "gün ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "ücretsiz, deneme süreniz içinde veya sonrasında iptal edin",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "İlk faturanızın kesilme tarihi:",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial": "İlk faturanız onay üzerine anında kesilecek",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "Faturanız her ay kesilecek",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Aylık ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/ay",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Yıllık ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/ay",
    "UpgradeConfirmationModal.YearlyTab.Discount": "İndirim",
    "UpgradeConfirmationModal.TaxRates.Text": "satış vergisi",
    "UpgradeConfirmationModal.TaxRates.Period": "ay",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Şimdi ödeyin",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/ay X 12 ay",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Tasarruf miktarınız: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/yıl",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "şu miktara denk",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% indirim",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Yıllık planlarda deneme süreci yoktur",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "Faturanız tam fiyattan anında kesilir",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Kredi/banka kartı ile ödeme yapın",
    "UpgradeConfirmationModal.PayPalTab.Info": "PayPal ile ödeme yapın",
    "UpgradeConfirmationModal.PaymentSection.Details": "Ödeme detayları ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Ücretsiz deneme hakkımı kullanmak istiyorum",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Şu pakete yükseltin ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "50.000’den fazla girişimcinin güvenini kazandı",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "Müşterilerimiz bizi sürekli 5/5 dereceyle değerlendiriyor.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128-bit SSL şifreleme",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "Tüm ödeme detaylarınız bizimle %100 güvende.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "50.000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "50.000’den fazla girişimcinin güvenini kazandı",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "Müşterilerimiz bizi sürekli 5/5 dereceyle değerlendiriyor.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128-bit SSL şifreleme",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "Tüm ödeme detaylarınız bizimle %100 güvende.",
    "UpgradeConfirmationModal.Agreement.Intro": "Devam ederek, şunları kabul etmiş olursunuz: ",
    "UpgradeConfirmationModal.Agreement.Terms": "Kullanım Şartları",
    "UpgradeConfirmationModal.Agreement.Privacy": "Gizlilik İlkeleri",
    "UpgradeConfirmationModal.Agreement.Refund": "İade İlkeleri",
    "UpgradeConfirmationModal.Agreement.Middle": "ve",
    "UpgradeConfirmationModal.Agreement.Cancelation": "İptal Etme İlkeleri",

    "OrderDetails.Title": "Sipariş detayları",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Sonu şu rakamlarla biten kredi kartı:",
    "CreditCard.Update.Button": "Kartı Güncelle",
    "CreditCard.AddCreditCard.Text":
      "Henüz herhangi bir kredi kartı eklemediniz. Lütfen aşağıdaki düğmeyi kullanarak bir kredi kartı ekleyin, böylece siparişlerinizi otomatik olarak işleme alabiliriz",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Kredi Kartı Ekle",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Kazandıran Ürünler",
    "Sidebar.WinningProducts.Variant": "Yeni",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Uygulamalar",
    "Sidebar.HelpCenter": "Yardım Merkezi",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Ayarlar",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Size yardımcı olmak için buradayız!",
    "TutorialModal.WhatIsSpocket": "Spocket nedir?",
    "TutorialModal.DiscoverProducts": "Ürün Keşfedin",
    "TutorialModal.ProductCustomization": "Ürün Özelleştirme",
    "TutorialModal.OrderProcessing": "Sipariş İşleme",
    "TutorialModal.Settings": "Ayarlar",
    "TutorialModal.GetHelp": "Yardım Alın",
    "TutorialModal.JoinTheCommunity": "Topluluğa katılın",

    "NotificationCenter.Header": "Bildirim Merkezi",
    "NotificationCenter.Footer": "Tüm Bildirimleri Görün",
    "NotificationCenter.NoNotification": "Burada Görülecek Bir Şey Yok!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Ürünlerim - Spocket",
    "Products.Title.Header": "Ürünlerim",
    "Products.Loading.Top": "Ürün listeniz yükleniyor!",
    "Products.Loading.Bottom": "Ürün listeniz yüklenirken lütfen bekleyin.",
    "Products.Empty.Search": "Herhangi bir ürün bulunamadı!",
    "Products.Empty.Top": "Ürün listeniz boş!",
    "Products.Empty.Bottom": "İçe aktarma listenize ürün eklemeye başlamak için arama sayfasına geçin.",
    "Products.Empty.Button.Search": "Ürün Ara",
    "Products.Empty.Button.How": "Ürünler nasıl bulunur",
    "Products.Alert.Removed": "Ürün kaldırıldı",

    "Products.Search.Label": "Ürünlerinizde Arayın",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Ara",

    "ProductListItem.Inactive.Tooltip": "Bu ürün artık tedarik edilemiyor",
    "ProductListItem.Inactive.Title": "Etkin Değil",
    "ProductListItem.OutOfStock.Tooltip": "Bu ürünün tüm seçenekleri stoklarda tükenmiş",
    "ProductListItem.OutOfStock.Title": "Stokta Yok",
    "ProductListItem.InventoryCount.Singular": "adet",
    "ProductListItem.InventoryCount.Plural": "adet",
    "ProductListItem.InventoryCount.End.Singular": "kaldı",
    "ProductListItem.InventoryCount.End.Plural": "kaldı",
    "ProductListItem.Button.View": "Mağazada Görüntüle",
    "ProductListItem.Button.Remove": "Mağazadan Kaldır",

    "ImportList.Title.Head": "Listeyi İçe Aktar - Spocket",
    "ImportList.Title.Header": "İçe Aktarma Listem",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "İçe aktarma listeniz yükleniyor!",
    "ImportList.Loading.Bottom": "Ürün listeniz yüklenirken lütfen bekleyin.",
    "ImportList.Empty.Button.Search": "Ürün Ara",
    "ImportList.Empty.Top": "İçe aktarma listeniz boş!",
    "ImportList.Empty.Bottom": "İçe aktarma listenize ürün eklemeye başlamak için arama sayfasına geçin.",
    "ImportList.Empty.Button.How": "Ürünler nasıl özelleştirilir",
    "ImportList.Alert.Pushed": "Bu ürün mağazanızda satışa çıkarıldı:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Search.Label": "İçe aktarılmış ürünlerinizde arayın",
    "ImportList.Search.Placeholder": "Ürünlerinizde Arayın",
    "ImportList.Search.Button": "Ara",
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Mevcut Sayfadaki Ürünleri Satışa Çıkar",
    "PushAllModal.Button.Inactive": "Mevcut Sayfayı Satışa Çıkar",
    "PushAllModal.Modal.Text":
      "Bu sayfadaki tüm ürünleri satışa çıkarmak istediğinizden emin misiniz? Mevcut sayfadaki geçerli tüm ürünler mağazanızda satışa çıkarılacak.",
    "PushAllModal.Modal.Push": "Tümünü Satışa Çıkar",
    "PushAllModal.Modal.Cancel": "İptal Et",

    "RemoveAllModal.Button.Active": "Mevcut Sayfadaki Ürünler Kaldırılıyor",
    "RemoveAllModal.Button.Inactive": "Mevcut Sayfayı Kaldır",
    "RemoveAllModal.Modal.Text":
      "Mevcut sayfadaki tüm ürünleri kaldırmak istediğinizden emin misiniz? Mevcut sayfanızdaki tüm ürünler içe aktarma listenizden kaldırılacak.",
    "RemoveAllModal.Modal.Remove": "Tümünü Kaldır",
    "RemoveAllModal.Modal.Cancel": "İptal Et",

    "ImportListItem.Tab.Product": "Ürün",
    "ImportListItem.Tab.Description": "Açıklama",
    "ImportListItem.Tab.Variants": "Seçenekler",
    "ImportListItem.Tab.Images": "Resimler",
    "ImportListItem.Remove.Active": "Ürün Kaldırılıyor",
    "ImportListItem.Remove.Inactive": "Ürünü Kaldır",
    "ImportListItem.Push.Active": "Mağazada Satışa Çıkarılıyor",
    "ImportListItem.Push.Inactive": "Mağazada Satışa Çıkar",
    "ImportListItem.Save.Button": "Kaydet",
    "ImportListItem.Alert.Removed": "Bu ürün içe aktarma listenizden kaldırıldı: ",
    "ImportListItem.Alert.RemoveFailed": "Kaldırma işlemi sırasında bir sorunla karşılaşıldı: ",
    "ImportListItem.Alert.CompareAtPrice": "Karşılaştırma fiyatı satış fiyatından daha büyük olmalı: ",
    "ImportListItem.Alert.OneActiveVariation": "Bu ürünün en az bir aktif seçeneği olmalıdır: ",
    "ImportListItem.Alert.Saved": "Bu ürün kaydedildi: ",
    "ImportListItem.Alert.ErrorPushing": "Satışa çıkarma işlemi esnasında bir hata oluştu",
    "ImportListItem.Alert.ErrorSaving": "Mağazanız kaydedilirken bir hata oluştu",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Ürünleri satışa çıkarmak için mağazanızı bağlayın: ",
    "ImportListItem.Tooltip.OneActiveVariation": "En az bir Aktif Seçenek gerekli",
    "ImportListItem.Tooltip.Unavailable": "Bu ürün şu anda satılmamaktadır",
    "ImportListItem.Tooltip.Unsaved": "Kaydedilmemiş değişiklikleriniz var",
    "ImportListItem.Tooltip.Profit": "Uyarı: Bu ürünün brüt kârı eksi rakamlarda",
    "ImportListItem.Tooltip.Unpurchasable": "Bu ürünü kaydedebilirsiniz ama ürün şu anda satılmamaktadır",
    "ImportListItem.Variants.ShowShippingPrices": "Teslimat Ücretlerini Göster",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Ürün Adı",
    "ItemInfo.ProductTags.Label": "Ürün Etiketleri",
    "ItemInfo.ProductTags.Remove": "Tüm etiketleri kaldır",
    "ItemInfo.ProductTags.Placeholder": "Yeni etiket ekle",
    "ItemInfo.ProductType.Label": "Ürün Türü",
    "ItemInfo.Collection.Label": "Koleksiyon",
    "ItemInfo.Collection.Placeholder": "Seçin",
    "ItemInfo.Collection.Option": "Seçin",
    "ItemInfo.SquareLocation.Option": "Seçin",

    "ItemVariantList.Modal.Title": "Teslimat Bilgileri",
    "ItemVariantList.Modal.Intro": "Teslimat Çıkış Yeri: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Bu üründe uluslararası teslimat yapılmamaktadır",
    "ItemVariantList.Modal.DoesNotShipTo": "Teslimat yapılmayan yerler: ",
    "ItemVariantList.Table.Head.Destination": "Varış Yeri",
    "ItemVariantList.Table.Head.Cost": "Maliyeti",
    "ItemVariantList.Table.Head.Time": "Teslim Süresi (Gün)",
    "ItemVariantList.Table.Body.Domestic": "Yurt İçi",
    "ItemVariantList.Table.Body.International": "Yurt Dışı",
    "ItemVariantList.VariantsTable.ShippingPrice": "Teslimat Ücreti ",
    "ItemVariantList.VariantsTable.Image": "Resim",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Envanter",
    "ItemVariantList.VariantsTable.Price": "Fiyatı",
    "ItemVariantList.VariantsTable.SalesPrice": "Satış Fiyatı ",
    "ItemVariantList.VariantsTable.Profit": "Kâr",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Karşılaştırma Fiyatı",
    "ItemVariantList.VariantsTable.Unavailable": "Bu ürün şu anda satılmamaktadır",
    "ItemVariantList.VariantsTable.Head": "Bu ürünün tüm seçenekleri burada gösteriliyor.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ör.: %40",
    "ItemVariantList.Alert.MaxVariants": "Shopify en fazla 100 seçeneğe izin verir.",

    "ItemVariantList.ContextPopover.Title": "Kâr Belirleyin",
    "ItemVariantList.ContextPopover.Apply": "Uygula",

    "ProductVariation.Description":
      "Numune ürün siparişlerini Spocket üzerinden doğrudan verebilirsiniz. Hızlı ve kolay ödeme için aşağıdaki adımları izleyin.",
    "ProductVariation.Label.SelectVariant": "Seçenek seçin",
    "ProductVariation.Label.SelectTheProductVariation": "Ürün seçeneği seçin",
    "ProductVariation.Qty": "Adet",
    "ProductVariation.Label.NotesForTheSupplier": "Satıcıya notlar",

    "InputAddress.Label.FirstName": "Ad",
    "InputAddress.PlaceHolder.FirstName": "Adınızı Girin",
    "InputAddress.Label.LastName": "Soyad",
    "InputAddress.PlaceHolder.LastName": "Soyadınızı Girin",
    "InputAddress.Label.StreetAddress": "Sokak Adresi",
    "InputAddress.Label.Country": "Ülke",
    "InputAddress.PlaceHolder.Country": "Ülke Ara",
    "InputAddress.Label.State": "Eyalet",
    "InputAddress.Placeholder.State": "Eyalet Ara",
    "InputAddress.Label.City": "Şehir",
    "InputAddress.PlaceHolder.City": "Şehir Girin",
    "InputAddress.Label.Zip": "Posta Kodu",
    "InputAddress.PlaceHolder.Zip": "Posta kodu girin",
    "InputAddress.Label.Phone": "Telefon Numarası",
    "InputAddress.Placeholder.Phone": "Telefon numarası girin",

    "OrderReview.OrderDetails": "Sipariş detayları",
    "OrderReview.Unit": "{{count}} Birim",
    "OrderReview.Unit_plural": "{{count}} Birim",
    "OrderReview.ShippingAddress": "Teslimat adresi",
    "OrderReview.PaymentMethod": "Ödeme yöntemi",
    "OrderReview.Amounts": "Miktar",
    "OrderReview.Subtotal": "Ara Toplam",
    "OrderReview.ShippingCost": "Teslimat ücreti",
    "OrderReview.TransactionFees": "İşlem Ücretleri",
    "OrderReview.TransactionFees.ToolTips":
      "İşlem ücretlerine Spocket’in ödeme hizmeti sağlayıcısı, Stripe ve hizmet ücretleri dahil edilir.",
    "OrderReview.OrderTotal": "Sipariş Toplamı",
    "OrderReview.ItemWithCount": "{{count}} birim",
    "OrderReview.ItemWithCount_plural": "{{count}} birim",
    "OrderReview.Placeholder.CreditCard": "Sonu şu hanelerle biten kredi kartı {{last4}}",

    "PaymentComplete.PaymentCompleted": "Ödeme Tamamlandı",
    "PaymentComplete.PaymentCompleted.Note":
      "Ödeme için teşekkür ederiz. Satıcı sipariş konusunda bilgilendirildi, siparişiniz kısa süre içinde işleme alınacaktır. Sipariş durumunu güncelleyip, takip numarasını sipariş sayfanıza ekleyeceğiz.",
    "PaymentComplete.PaymentIncomplete": "Siparişinizde bir eksiklik var!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Devam edebilmeniz için bankanıza ödeme onayı vermeniz gerekiyor",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink":
      "Lütfen ödemeyi bu bağlantıyı kullanarak onaylayın.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Ödeme onaylandığında satıcı bilgilendirilecektir ve siparişiniz normal olarak işleme alınacaktır. Sipariş durumunuz ve takip güncellemeleri sipariş sayfanıza eklenecektir. Lütfen onay verdikten sonra sayfayı yenileyin.",

    "CreditCard.AddCreditCard.Description":
      "Henüz herhangi bir kredi kartı eklemediniz. Lütfen aşağıdaki düğmeyi kullanarak bir kredi kartı ekleyin, böylece siparişlerinizi otomatik olarak işleme alabiliriz",
    "CreditCard.Button.AddCreditCard": "Kredi Kartı Ekle",
    "CreditCard.Button.UpdateCard": "Kartı Güncelle",
    "CreditCard.UpdateCard.Label.CreditCard": "Kredi kartı",
    "CreditCard.PlaceHolder.CreditCard": "Son dört hanesi {{last4}} olan kredi kartı",

    "UpdateCreditCardModal.Label.CreditCard": "Kredi kartı",
    "UpdateCreditCardModal.Button.UpdateCard": "Kartı Güncelle",
    "UpdateCreditCardModal.Button.AddCreditCard": "Kredi Kartı Ekle",
    "UpdateCreditCardModal.Button.Cancel": "İptal Et",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Kart bilgilerinizi güncelleyin",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Hesabınız Spocket ürünlerinin sipariş işlemleri gerçekleştirilirken kullanılacaktır.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Hükümler ve koşullar",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "- ödeme işlemlerine dair.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Seçenek Seçin",
    "SampleOrderModal.Title.ShippingAddress": "Teslimat Adresi",
    "SampleOrderModal.Title.PaymentMethod": "Ödeme Yöntemi",
    "SampleOrderModal.Title.Review": "Önizleme",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Ödeme Onayı Gerekiyor!",
    "SampleOrderModal.Title.OrderPlaced": "Sipariş Verildi!",
    "SampleOrderModal.Steps": "Adım {{current}} / {{total}}",
    "SampleOrderModal.FinalStep": "Son Adım",
    "SampleOrderModal.ConfirmPayment": "Ödemeyi Onaylayın!",
    "SampleOrderModal.Finished": "Tamamlandı",
    "SampleOrderModal.Footer.Continue": "Devam Et",
    "SampleOrderModal.Footer.PlaceOrder": "Sipariş ver",
    "SampleOrderModal.Footer.Close": "Kapat",
    "SampleOrderModal.Footer.Cancel": "İptal Et",
    "SampleOrderModal.Footer.GoBack": "Geri Dön",


    "AdvancedFiltersModal.Title.Filters": "Filtreler",
    "AdvancedFiltersModal.Title.Shipping": "Teslimat",
    "AdvancedFiltersModal.Label.ShipsFrom": "Teslimat çıkış yeri:",
    "AdvancedFiltersModal.Label.ShipsTo": "Teslimat yapılan yer:",
    "AdvancedFiltersModal.Label.ShippingTime": "Teslimat süresi",
    "AdvancedFiltersModal.Label.Popular": "Popüler",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Amerika Birleşik Devletleri",
    "AdvancedFiltersModal.Label.Country.Europe": "Avrupa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Sadece ABD teslimatları için",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Tümü",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 Gün",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 Gün",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 Gün",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15+ Gün",
    "AdvancedFiltersModal.Title.ItemsCost": "Birim fiyat",
    "AdvancedFiltersModal.Label.ItemCost": "Birim fiyat",
    "AdvancedFiltersModal.Label.ShippingCost": "Teslimat ücreti",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Tümü",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Ücretsiz",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "$5 ve altı",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "$15 ve altı",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "$25 ve altı",
    "AdvancedFiltersModal.Title.Supplier": "Tedarikçi",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Tedarikçiler",
    "AdvancedFiltersModal.Label.TopSupplier": "Popüler tedarikçiler",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Bu tedarikçiler yüksek kaliteli ürünleri, harika hizmetleri ve yüksek dereceli değerlendirmeleri olan tedarikçilerdir.",
    "AdvancedFiltersModal.Title.Advanced": "Gelişmiş",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Premium ürünler",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Yüksek indirimli ve hızlı teslimat yapılan ürünler",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Çok Satanlar",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Spocket üzerinde en iyi satan ürünler",
    "AdvancedFiltersModal.Button.Cancel": "İptal Et",
    "AdvancedFiltersModal.Button.ViewResults": "Sonuçları Görüntüle",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Ülke Ara...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Ülkeler",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Tedarikçi Ara...",
    "AdvancedFiltersModal.Label.All Suppliers": "Tüm Tedarikçiler",
    "AdvancedFiltersModal.Label.New Suppliers": "Yeni Tedarikçiler",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Tümü",
    "AdvancedFilters.ShippingTime.One": "1-3 Gün",
    "AdvancedFilters.ShippingTime.Four": "4-7 Gün",
    "AdvancedFilters.ShippingTime.Eight": "8-14 Gün",
    "AdvancedFilters.ShippingTime.Fifteen": "15+ Gün",

    "AdvancedFilters.ShippingCost.Free": "Ücretsiz Teslimat",
    "AdvancedFilters.ShippingCost.Five": "Teslimat ücreti en fazla $5",
    "AdvancedFilters.ShippingCost.Fifteen": "Teslimat ücreti en fazla $15",
    "AdvancedFilters.ShippingCost.TwentyFive": "Teslimat ücreti en fazla $25",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Tümünü Temizle",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Teslimat",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Ara",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Teslimat Çıkış Yeri:",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Teslimat Yapılan Yer:",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Tedarikçi",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Şuna Göre Sırala:",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Birim Fiyat",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Popüler Tedarikçiler",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Kişiselleştirilmiş Faturalar",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Çok Satanlar",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "{{from}} ila {{to}} arası",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Anahtar kelimeler girin...",
    "Search.Header.Button.Search": "Ara",
    "Search.Header.Button.Filters": "Filtreler",

    "Navigation.UpgradeButton.TryPro": "Spocket Pro’yu ÜCRETSİZ Deneyin",
    "Navigation.UpgradeButton.TryEmpire": "Spocket İMPARATOR’u ÜCRETSİZ Deneyin",
    "Navigation.UpgradeButton.TryUnicorn": "Spocket Unicorn’u ÜCRETSİZ Deneyin",

    "CategoriesCards.Label.MoreCategories": "Daha Çok Kategori",

    "StaticCategories.WomensClothing": "Kadın Kıyafetleri",
    "StaticCategories.WomensClothing.Activewear": "Spor Giyim",
    "StaticCategories.WomensClothing.Bodysuits": "Tek Parça Streç Giysiler",
    "StaticCategories.WomensClothing.Dresses": "Elbiseler",
    "StaticCategories.WomensClothing.JacketsCoats": "Ceketler ve Paltolar",
    "StaticCategories.WomensClothing.Jeans": "Kot Pantolonlar",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Tulumlar ve Bebek Tulumları",
    "StaticCategories.WomensClothing.Leggings": "Taytlar",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Kadın İç Çamaşırı ve Gecelik",
    "StaticCategories.WomensClothing.MatchingSets": "Eşleşen Takım Ürünler",
    "StaticCategories.WomensClothing.Pants": "Pantolonlar",
    "StaticCategories.WomensClothing.Shorts": "Şortlar",
    "StaticCategories.WomensClothing.Skirts": "Etekler",
    "StaticCategories.WomensClothing.Sleepwear": "Pijamalar",
    "StaticCategories.WomensClothing.Socks": "Çoraplar",
    "StaticCategories.WomensClothing.SweatersHoodies": "Sweatshirt’ler ve Kapüşonlular",
    "StaticCategories.WomensClothing.Swimwear": "Plaj Kıyafetleri",
    "StaticCategories.WomensClothing.TankTops": "Askılı Üst",
    "StaticCategories.WomensClothing.TopsBlouses": "Üst ve Bluzler",
    "StaticCategories.WomensClothing.Tshirts": "Tişörtler",

    "StaticCategories.JewelryWatches": "Takı ve Saatler",
    "StaticCategories.JewelryWatches.Bracelets": "Bilezikler",
    "StaticCategories.JewelryWatches.Earrings": "Küpeler",
    "StaticCategories.JewelryWatches.Necklaces": "Kolyeler",
    "StaticCategories.JewelryWatches.OtherAccessories": "Diğer Aksesuarlar",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Kolye Uçları, Tılsımlı Taşlar",
    "StaticCategories.JewelryWatches.Rings": "Yüzükler",
    "StaticCategories.JewelryWatches.Sets": "Setler",
    "StaticCategories.JewelryWatches.Watches": "Saatler",

    "StaticCategories.TechAccessories": "Teknoloji Aksesuarları",
    "StaticCategories.TechAccessories.AudioVideo": "Ses ve Video",
    "StaticCategories.TechAccessories.CasesCovers": "Kılıflar ve Koruyucular",
    "StaticCategories.TechAccessories.Lighting": "Işıklandırma",
    "StaticCategories.TechAccessories.MobileLaptopAccessories":
      "Mobil Cihaz ve Dizüstü Bilgisayar Aksesuarları",
    "StaticCategories.TechAccessories.Mousepads": "Fare Altlıkları",
    "StaticCategories.TechAccessories.Novelty": "Acayip Ürünler",

    "StaticCategories.KidsBabies": "Çocuklar ve Bebekler",
    "StaticCategories.KidsBabies.BabyClothing": "Bebek Kıyafetleri",
    "StaticCategories.KidsBabies.Bathing": "Banyo",
    "StaticCategories.KidsBabies.BlanketsPillows": "Yastık ve Battaniye",
    "StaticCategories.KidsBabies.CapsHeadbands": "Şapkalar ve Kafa Bantları",
    "StaticCategories.KidsBabies.Footwear": "Ayakkabılar",
    "StaticCategories.KidsBabies.Furniture": "Mobilya",
    "StaticCategories.KidsBabies.KidsClothing": "Çocuk Kıyafetleri",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Ebeveyn Aksesuarları",

    "StaticCategories.Toys": "Oyuncaklar",
    "StaticCategories.Footwear": "Ayakkabılar",
    "StaticCategories.Footwear.Boots": "Botlar",
    "StaticCategories.Footwear.Flats": "Topuksuz Ayakkabılar",
    "StaticCategories.Footwear.Heels": "Topuklu Ayakkabılar",
    "StaticCategories.Footwear.Mens": "Erkek Ayakkabıları",
    "StaticCategories.Footwear.Sandals": "Sandaletler",
    "StaticCategories.Footwear.Slippers": "Terlikler",
    "StaticCategories.Footwear.SneakersRunners": "Koşu ve Spor Ayakkabıları",

    "StaticCategories.BathBeauty": "Banyo ve Güzellik",
    "StaticCategories.BathBeauty.Bodycare": "Vücut Bakım Ürünleri",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Mumlar, Esanslar ve Parfümler",
    "StaticCategories.BathBeauty.Haircare": "Saç Bakım Ürünleri",
    "StaticCategories.BathBeauty.Healthcare": "Sağlık Ürünleri",
    "StaticCategories.BathBeauty.Makeup": "Makyaj Ürünleri",
    "StaticCategories.BathBeauty.Nails": "Tırnak Bakım Ürünleri",
    "StaticCategories.BathBeauty.Skincare": "Cilt Bakım Ürünleri",
    "StaticCategories.BathBeauty.TowelsRobes": "Havlular ve Bornozlar",

    "StaticCategories.Pets": "Evcil Hayvan Ürünleri",
    "StaticCategories.Pets.BedsBlankets": "Yatak ve Nevresim",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Kayışlar, Tasmalar ve Evcil Hayvan Kıyafetleri",
    "StaticCategories.Pets.Petcare": "Evcil Hayvan Bakım Ürünleri",
    "StaticCategories.Pets.Toys": "Oyuncaklar",

    "StaticCategories.HomeGarden": "Ev ve Bahçe",
    "StaticCategories.HomeGarden.Bathroom": "Banyo",
    "StaticCategories.HomeGarden.Clocks": "Ev Saatleri",
    "StaticCategories.HomeGarden.Drinkware": "Bardaklar",
    "StaticCategories.HomeGarden.FoodBeverage": "Gıda ve Meşrubat",
    "StaticCategories.HomeGarden.Furniture": "Mobilya",
    "StaticCategories.HomeGarden.Garden": "Bahçe",
    "StaticCategories.HomeGarden.HomeDecor": "Ev Dekorasyonu",
    "StaticCategories.HomeGarden.HomeImprovement": "Kullanışlı Ev Ürünleri",
    "StaticCategories.HomeGarden.Kitchen": "Mutfak",
    "StaticCategories.HomeGarden.Lighting": "Işıklandırma",
    "StaticCategories.HomeGarden.Organization": "Organizasyon",
    "StaticCategories.HomeGarden.StationeryCrafts": "Ofis ve El Sanatı Malzemeleri",
    "StaticCategories.HomeGarden.TextilesPillows": "Kumaşlar ve Yastıklar",
    "StaticCategories.HomeGarden.Tools": "Aletler",

    "StaticCategories.Accessories": "Aksesuarlar",
    "StaticCategories.Accessories.Belts": "Kemerler",
    "StaticCategories.Accessories.Cufflinks": "Kol Düğmeleri",
    "StaticCategories.Accessories.Hats": "Şapkalar",
    "StaticCategories.Accessories.Keychains": "Anahtarlıklar",
    "StaticCategories.Accessories.ScarvesBandanas": "Eşarplar ve Bandanalar",
    "StaticCategories.Accessories.Sunglasses": "Güneş Gözlükleri",

    "StaticCategories.MensClothing": "Erkek Kıyafetleri",
    "StaticCategories.MensClothing.Activewear": "Spor Giyim",
    "StaticCategories.MensClothing.DressShirts": "Kıyafet Etekleri",
    "StaticCategories.MensClothing.JacketsCoats": "Ceketler ve Paltolar",
    "StaticCategories.MensClothing.Pants": "Pantolonlar",
    "StaticCategories.MensClothing.Shorts": "Şortlar",
    "StaticCategories.MensClothing.Socks": "Çoraplar",
    "StaticCategories.MensClothing.SweatersHoodies": "Sweatshirt’ler ve Kapüşonlular",
    "StaticCategories.MensClothing.Swimwear": "Plaj Kıyafetleri",
    "StaticCategories.MensClothing.TankTops": "Askılı Üst",
    "StaticCategories.MensClothing.Tshirts": "Tişörtler",
    "StaticCategories.MensClothing.Underwear": "İç Çamaşırı",

    "StaticCategories.SportsOutdoors": "Spor ve Outdoors Ürünleri",
    "StaticCategories.SportsOutdoors.Activewear": "Spor Giyim",
    "StaticCategories.SportsOutdoors.Biking": "Bisiklet Ürünleri",
    "StaticCategories.SportsOutdoors.Camping": "Kamp Ürünleri",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Ekipman ve Aksesuarlar",
    "StaticCategories.SportsOutdoors.Fishing": "Balıkçılık Ürünleri",

    "StaticCategories.Gifts": "Hediyeler",
    "StaticCategories.Gifts.Adults": "Yetişkin Ürünleri",
    "StaticCategories.Gifts.Funny": "Komik Ürünler",
    "StaticCategories.Gifts.Mugs": "Kupalar",
    "StaticCategories.Gifts.Novelty": "Acayip Ürünler",
    "StaticCategories.Gifts.Personalized": "Kişiselleştirilebilir Ürünler",
    "StaticCategories.Gifts.Religious": "Dinî Ürünler",

    "StaticCategories.Automotive": "Otomotiv",
    "StaticCategories.BagsWallets": "Çantalar ve Cüzdanlar",
    "StaticCategories.Seasonal": "Özel Gün Ürünleri",
    "StaticCategories.Seasonal.Christmas": "Noel",
    "StaticCategories.Seasonal.FathersDay": "Babalar Günü",
    "StaticCategories.Seasonal.Halloween": "Cadılar Bayramı",
    "StaticCategories.Seasonal.MothersDay": "Anneler Günü",

    "StaticCategories.FestivalsParties": "Festivaller ve Partiler",
    "StaticCategories.FestivalsParties.PartySupplies": "Parti Malzemeleri",
    "StaticCategories.FestivalsParties.Wedding": "Düğün",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "ABD’ye hızlı teslimat",
    "QuickFilters.Label.Under5": "ABD teslimat ücreti en fazla $5",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Şuna göre sırala:",
    "SortBy.DropDown.Item.Relevance": "Alaka",
    "SortBy.DropDown.Item.PriceLowToHigh": "Fiyat: yükselen",
    "SortBy.DropDown.Item.PriceHighToLow": "Fiyat: azalan",
    "SortBy.DropDown.Item.Newest": "En Yeni",
    "SortBy.DropDown.Item.ShippingTime": "Teslimat süresi",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Ana Sayfa",
    "Breadcrumb.Item.Search": "ara",

    "UpgradeModal.Modal.Title": "Bir fiyatlandırma türü seçin",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Aylık",
    "Plans.ToggleSection.Yearly": "Yıllık",
    "Plans.ToggleSection.DiscountInitial": "Şu değere kadar indirim:",
    "Plans.ToggleSection.DiscountSingular": "Ay",
    "Plans.ToggleSection.DiscountPlural": "Ay",
    "Plans.ToggleSection.DiscountFinal": "İndirim",
    "Plans.ProrationCost.TextInitial": "Mevcut ödeme tutarınız/periyodunuz:",
    "Plans.ProrationCost.TextFinal": "/yıl. Belirtilen ek ücret karşılığında İmparator pakete yükseltin:",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Satış yapmaya başlayın",
    "Card.DescriptionVariantType.Professional": "Satışlarınızı artırın",
    "Card.DescriptionVariantType.Empire": "Satışlarınızı maksimuma çıkarın",
    "Card.DescriptionVariantType.Unicorn": "Satışlarınızı toptan yönetin",
    "Card.DescriptionVariantType.Period": "ay",
    "Card.DescriptionVariantType.PricePeriod": "/mo",
    // eslint-disable-next-line
    "Card.DescriptionVariantType.AnnualDescription": "yıllık faturalandırmayla ${{value}}",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Mevcut paketiniz",
    "Card.DescriptionVariantType.Button.Start": "Hemen başlayın",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Markalı faturalandırma",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Kazandıran Ürünler",
    "Card.DescriptionVariantType.SupplierSourcing": "Tedarikçi kaynakları",
    "Card.DescriptionVariantType.ProductRequests": "Ürün talepleri",
    "Card.DescriptionVariantType.BulkCheckout": "Toplu ödeme",
    "Card.DescriptionVariantType.Special": "özel",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Spocket aboneliğiniz sona erdi.",
    "ReactivationBanner.InfoSubtitle": "Tekrar etkinleştirin",
    "ReactivationBanner.CheckList.HighQuality": "Yüksek kalite",
    "ReactivationBanner.CheckList.Curated": "Küratörlü",
    "ReactivationBanner.CheckList.FastShipping": "Hızlı Teslimat",
    "ReactivationBanner.CheckList.FromUSAndEU": "ABD’den ve AB’den",
    "ReactivationBanner.TextByPlan.Starter.Description":
      "Bundan sonra <1>herhangi bir ürünü satışa sunamayacaksınız</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket Hesabı",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Ürünleri mağazanızda satışa sunmaya hemen başlayın",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Artık <1>Ürünleri Satışa Çıkar</1> canlı sunuş özelliğini kullanamayacaksınız ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Premium Ürünler",
    "ReactivationBanner.TextByPlan.Others.Title": "Premium Hesap",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Şu özelliği taşıyan Premium Ürünlere erişim sağlayın:",
    "ReactivationBanner.Button": "Hesabımı ŞİMDİ tekrar etkinleştir",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Daha Fazla Ürünü Satışa Çıkarın",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Sınırsız Sipariş Alın",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Markalı Faturalandırma",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Premium Ürünler",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Ayrıcalıklı Teklifler",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Sohbet ve Çağrı Desteği",
    "UpgradeReasonModal.Modal.Title": "Spocket ile hangi hedefe ulaşmak istiyorsunuz?",
    "UpgradeReasonModal.Modal.Question": "Paketinizi yükseltme nedeniniz neydi?",
    "UpgradeReasonModal.Modal.Button": "Spocket {{plan}} Paketi Kullanmaya Başlayın",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Bu deneyiminizi iyileştirmemize yardımcı olur",

    "UpgradeSuccessModal.Col.One.A": "Tebrikler",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Başarılı bir stoksuz satış işletmesi kurmak için gereken tüm araçları edindiniz.",
    "UpgradeSuccessModal.Col.Two.B": "Artık şunları kullanabilirsiniz: ",
    "UpgradeSuccessModal.Col.Two.C": " Spocket ile İyi Çalışmalar Dileriz!",
    "UpgradeSuccessModal.Button": "Devam Et",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 ay",
    "Promotion.TrialDaysNormalizer.28days": "4 hafta",
    "Promotion.TrialDaysNormalizer.21days": "3 hafta",
    "Promotion.TrialDaysNormalizer.14days": "2 hafta",
    "Promotion.TrialDaysNormalizer.7days": "1 hafta",
    "Promotion.TrialDaysNormalizer.1day": "1 gün",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} gün",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Ücretsiz deneyin",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Şimdi deneyin →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Dil",

    "WhatYouWillLoseModal.Button.Offer": "Devam edin",
    "WhatYouWillLoseModal.Button.Downgrade": "Vazgeçiyorum, hesabımı alt seviyeye geçirin",
    "WhatYouWillLoseModal.ProductSection.Title":
      "<1>İçe aktarılmış {{product_count}} ürünün tamamını</1> kaybedeceksiniz",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} daha fazla",
    "WhatYouWillLoseModal.ModalWrapper.Header": "Şimdiden vaz mı geçiyorsunuz?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "%50 indirim kazanın",
    "WhatYouWillLoseModal.ModalWrapper.Title": "Girişimcilik yolculuğunuza 3 ay daha devam edin.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Sizi başarıya ulaştırmak istiyoruz. Bu işe zamanınızın büyük kısmını yatırdığınızı biliyoruz. O yüzden devam edin. Ekibimizin desteği sizinle. Spocket Ekibi",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Tebrikler, önümüzdeki 3 ay için paketinize %50 indirim yaptık!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Devam Et",

    "DowngradeReasonModal.DowngradeSection": "Spocket’i sizin için nasıl daha iyi hâle getirebiliriz?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Her konuda geri bildirimlerinizi bekliyoruz (en az 100 karakter)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Lütfen geri bildiriminizi girin (en az 100 karakter)",
    "DowngradeReasonModal.Button.Downgrade": "Alt Seviyeye Geçin",
    "DowngradeReasonModal.ModalWrapper.Header": "{{storeName}} Alt Seviyeye Geçirilsin mi?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Bizimle iletişime geçin",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "İptal Et",

    "DowngradeSuccessModal.Header": "Alt Seviyeye Geçirme İşlemi Başarılı",
    "DowngradeSuccessModal.Body":
      "Geri bildiriminiz için teşekkür ederiz. Spocket’i her gün sürekli geliştiriyoruz, yakında bize bir şans daha vermenizi umuyoruz!",

    "Settings.title": "Ayarlar",
    "Settings.buttonSaveChanges": "Değişiklikleri Kaydet",
    "Settings.buttonSaveAndPreview": "Kaydet ve Önizle",
    "Settings.PricingPlans.Title": "Fiyatlandırma Planları",

    "Settings.MenuTab.Plans": "Planlar",
    "Settings.MenuTab.Account": "Hesap",
    "Settings.MenuTab.BrandedInvoicing": "Markalı Faturalandırma",
    "Settings.MenuTab.Billing": "Faturalandırma",
    "Settings.MenuTab.GlobalPricingRules": "Küresel Fiyatlandırma Kuralları",
    "Settings.MenuTab.Security": "Güvenlik",

    "AccountTab.documentTitle": "Ayarlar - Hesap - Spocket",
    "AccountTab.title": "Hesap Ayarları",
    "AccountTab.shopName": "Mağaza Adı",
    "AccountTab.email": "E-posta",
    "AccountTab.shopUrl": "Mağaza URL’si",
    "AccountTab.defaultCurrency": "Varsayılan Para Birimi",
    "AccountTab.yourPlan": "Paketiniz",
    "AccountTab.accountNotActive": "Hesabınız şu anda etkin değil",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Bu özellik kısa süre içinde eklenecek! Şimdilik lütfen Spocket Shopify uygulaması üzerinden bağlanın!",
    "AccountTab.alertAccountSettingsUpdated": "Hesap ayarları güncellendi",
    "AccountTab.alertInvalidShopUrl": "Geçersiz Mağaza URL’si",
    "AccountTab.productsImported": "Ürünler İçe Aktarıldı",
    "AccountTab.products": "Ürünler",
    "AccountTab.premiumProducts": "Premium Ürünler",
    "AccountTab.total": "toplam",
    "AccountTab.signOut": "Çıkış Yap",
    "AccountTab.limitReachedProductsAndPremium": "Ürün ve Premium Ürün limitine erişildi.",
    "AccountTab.limitReachedProducts": "Ürün limitine erişildi.",
    "AccountTab.limitReachedPremium": "Premium Ürün limitine erişildi.",
    "AccountTab.buttonSwitchToAnnualPlan": "Yıllık Plana Geçiş Yapın",
    "AccountTab.off30Percent": "%30 İndirim",
    "AccountTab.off40Percent": "%40 İndirim",
    "AccountTab.off45Percent": "%45 İndirim",
    "AccountTab.sellMoreWithEmpirePlan": "İmparator Paketi ile Daha Çok Satış Yapın",
    "AccountTab.tooltipCurrency":
      "Para biriminiz mağaza hesabınız üzerinden yönetilir. İçe aktardığınız tüm ürünlerin fiyatı hesabınızdaki para birimine çevrilir.",
    "AccountTab.shopNotConnectedYet": "Mağazanız henüz bu hesaba bağlı değil.",
    "AccountTab.connectYourShop": "Mağazanızı Bağlayın",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Ayarlar - Faturalandırma - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Fatura ayarları kaydedildi!",
    "InvoiceTab.shopLogo": "Mağaza Logosu",
    "InvoiceTab.contactEmail": "İletişim E-postası",
    "InvoiceTab.phoneNumber": "Telefon Numarası",
    "InvoiceTab.personalNote": "Özel Not",
    "InvoiceTab.tooltipPersonalNote": "Markalı tüm faturalarınıza özel bir not ekleyin",
    "InvoiceTab.brandedInvoicing": "Markalı Faturalandırma",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Ödemeli bir paket kullanıyorsanız, markalı faturalandırma sunan tedarikçilerden aldığınız tüm siparişlere marka logonuzu ve özel notunuzu ekleyin",
    "InvoiceTab.disabledInvoicing": "Tüm Siparişlerde Markalı Faturalandırma Kullan",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Bu seçeneği devre dışı bırakırsanız sipariş verdiğinizde markalı faturalandırma yapılmaz",

    "PricingTab.titleDefaultPricingRules": "Varsayılan Fiyatlandırma Kuralları",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Gelişmiş Fiyatlandırma Kuralları",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Kuruş Ata",
    "PricingTab.tooltipAssignCents":
      "Bu değer ürününüzün son fiyatı gösterilirken eklenir (ör. ürününüzün fiyatını XX.99 olarak belirlemek isterseniz aşağıdaki alana 99 değerini girin).",
    "PricingTab.Markup": "Kâr Yüzdesi",
    "PricingTab.MarkupType": "Kâr Yüzdesi Türü",
    "PricingTab.SelectDefault": "-- Birini Seçin --",
    "PricingTab.SelectPercent": "Yüzde",
    "PricingTab.SelectMultiplier": "Çarpan",
    "PricingTab.SelectFixed": "Sabit",
    "PricingTab.titlePricingRules": "Ayarlar - Fiyatlandırma Kuralları - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Gelişmiş Fiyatlandırma Kurallarını Aç/Kapat",
    "PricingTab.from": "Değerinden",
    "PricingTab.to": "Değerine",
    "PricingTab.selectFixedAmount": "Sabit Fiyat",
    "PricingTab.buttonRemove": "Kaldır",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Boş alanlarla birden fazla kural oluşturamazsınız",
    "PricingTab.costRange": "Bedel Aralığı",
    "PricingTab.markup": "Kâr Yüzdesi",
    "PricingTab.add": "Ekle",

    "SecurityTab.Document.Title": "Ayarlar - Güvenlik - Spocket",
    "SecurityTab.alertIncorrectPassword": "Şifre Yanlış",
    "SecurityTab.title": "Güvenlik",
    "SecurityTab.yourNewPassword": "Yeni Şifreniz",
    "SecurityTab.repeatPassword": "Şifreyi Yineleyin",
    "SecurityTab.errorPleaseEnterAPassword": "Lütfen bir şifre girin.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Lütfen bir şifre onayı girin.",
    "SecurityTab.errorPasswordsDoNotMatch": "Şifreler uyuşmuyor",
    "SecurityTab.errorPasswordShort": "Şifre en az 8 karakterden oluşmalıdır",

    "ListingCards.TopSupplierTag.Tooltip":
      "Popüler Tedarikçiler yüksek kaliteli ürünleri, harika hizmetleri ve yüksek dereceli değerlendirmeleri olan tedarikçilerdir.",
    "ListingCards.TopSupplierTag.Text": "Tedarikçi",

    "ListingCard.OriginCountry.Intro": "Tarafından",
    "ListingCard.OriginCountry.Tooltip": "Teslimat çıkış yeri:",
    "ListingCard.Shipping.Price.Title": "Teslimat Ücreti",
    "ListingCard.Shipping.Price.Free": "ÜCRETSİZ",
    "ListingCard.Price.Intro": "Liste Fiyatı",
    "ListingCard.Price.Retail": "Perakende Fiyatı",
    "ListingCard.Shipping.Time.Title": "Teslimat Süresi",
    "ListingCard.Shipping.Time.Period": "gün",
    "ListingCard.PremiumIcon.Tooltip":
      "Mağazanızda özel, yüksek indirimli ve hızlı teslimat yapılan ürünler satın",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "İçe Aktarma Listesine Ekle",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Mağazada Satışa Çıkarıldı",
    "ListingCard.StatusTag.Imported.Title": "İçe Aktarıldı",
    "ListingCard.StatusTag.Imported.Text": "Bu ürün zaten İçe Aktarma Listenizde",
    "ListingCard.StatusTag.Pushed.Title": "Mağazada",
    "ListingCard.StatusTag.Pusehd.Text": "Bu ürün mağazanızda satışa çıkarıldı",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Ürün Açıklaması",
    "ListingModal.DetailsSection.Button.ProductVariations": "Ürün Seçeneklerini Aç",
    "ListingModal.DetailsSection.Button.OrderSamples": "Numune Sipariş Edin",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Yeni",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert": "Bu ürün için markalı faturalandırma yapılamıyor",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Uluslararası siparişler Çin’deki depomuzdan gönderilir",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "ABD deposunda stokta kalmayan ürünler Çin’deki depomuzdan gönderilebilir",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Siparişlerinizin genel teslimat süresinin düşürülebilmesi için siparişler birden çok paket halinde gönderilebilir",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Sipariş vermek için mağazanızı bağlayın",
    "ListingModal.DetailsSection.PushedListButton": "Mağazada Satışa Çıkarıldı",
    "ListingModal.DetailsSection.RemoveListButton": "Listeden Kaldır",
    "ListingModal.DetailsSection.ImportListButton": "İçe Aktarma Listesine Ekle",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Listeleme Devre Dışı Bırakıldı",

    "InfoSection.ShippingTime.Tooltip": "Ürün gönderildikten sonraki teslimat süresi.",
    "InfoSection.ShippingTime.Title": "Teslimat Süresi",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Dünya Geneli",
    "InfoSection.ShippingExcluded.Intro": "Teslimat yapılmayan yerler:",
    "InfoSection.ShippingExcluded.Various": "Çeşitli",
    "InfoSection.ProcessingTime.Tooltip":
      "Tedarikçinin ürünleri göndermesi ve takip numarası eklemesi için gereken iş günü sayısı",
    "InfoSection.ProcessingTime.Title": "İşlem Süresi",
    "InfoSection.ProcessingTime.Period": "iş günü",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Bu üründe teslimat ücreti {{price_formatted}} olmakla birlikte, aynı siparişteki ilave her ürün için ek olarak {{incremental_price_formatted}} talep edilir",
    "InfoSection.ShippingInfoLine.Period": "iş günü",
    "InfoSection.ShippingInfoLine.Free": "ÜCRETSİZ",
    "InfoSection.ShippingInfoLine.NoShipping": "Teslimat yapılmaz",

    "ReturnPolicySection.Title": "İade İlkeleri",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Değerinden",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Tarafından",
    "TitleSection.PriceMSRP.Listing": "Liste Fiyatı",
    "TitleSection.PriceMSRP.Retail": "Perakende Fiyatı",

    "ProductVariationsModal.Title": "Ürün Seçenekleri",
    "ProductVariationsModal.Table.Image": "Resim",
    "ProductVariationsModal.Table.Inventory": "Envanter",
    "ProductVariationsModal.Table.Price": "Fiyatı",
    "ProductVariationsModal.ImageSpecifications.Title": "Boyut Cetveli",
    "ProductVariationsModal.ImageSpecifications.Button": "İndir",

    "TopSupplier.Tooltip":
      "Popüler Tedarikçiler yüksek kaliteli ürünleri, harika hizmetleri ve yüksek dereceli değerlendirmeleri olan tedarikçilerdir.",
    "TopSupplier.Text": "Popüler Tedarikçi",

    "BestSelling.Tooltip.Top": "Bu ürün Spocket üzerinde en iyi satan ürünlerden",
    "BestSelling.Tooltip.Bottom": "Çok Satanlar",

    "GallerySection.PremiumIcon.Tooltip":
      "Mağazanızda özel, yüksek indirimli ve hızlı teslimat yapılan ürünler satın",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "Ekstra %{{value}} İndirim",

    "Upgrade.Title.AnnualToggled": "Spocket premiumu bugün edinin",
    "Upgrade.Title.MonthlyToggled": "Spocket premiumu 14 gün boyunca ücretsiz deneyin",
    "Upgrade.Subtitle":
      "Bugün bir Spocket premium paketi deneyin ve ABD ve Avrupa kaynaklı yüksek kazanç getiren ürünleri hemen mağazanıza eklemeye başlayın.",
    "Upgrade.Button": "Spocket premium olmadan devam et",

    "Search.NoProductsFound.Title": "Üzgünüz! Şu arama için herhangi bir sonuç bulamadık",
    "Search.NoProductsFound.For": ":",
    "Search.NoProductsFound.Description":
      "Lütfen yazılışının doğruluğunu kontrol edin, ilişkili bir başka arama terimi veya daha genel bir terim kullanarak tekrar deneyin.",
    "Search.Searching.Title": "Ürünleri arıyoruz...",
    "Search.Searching.Description": "En yeni ürünler yüklenirken lütfen bekleyin.",

    "Suppliers.StarRating.Tooltip": "Tedarikçinin sipariş teslim başarısı oranı",
    "Suppliers.RefundPolicy": "Tedarikçinin iade politikası",
    "Suppliers.Joined.Title": "Spocket’e Katıldı",
    "Suppliers.Origin.Title": "Şu ülkeden gönderiliyor:",
    "Suppliers.Time.Title": "Ortalama işlem süresi",
    "Suppliers.HasMore.Title": "Ürünleri arıyoruz...",
    "Suppliers.HasMore.Subtitle": "En yeni ürünler yüklenirken lütfen bekleyin.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Popüler Tedarikçiler yüksek kaliteli ürünleri, harika hizmetleri ve yüksek dereceli değerlendirmeleri olan tedarikçilerdir.",
    "Suppliers.TopSupplierTag.Text": "Popüler Tedarikçi",

    "AltTextModal.Header.Title": "Resim Detaylarını Düzenleyin",
    "AltTextModal.Body.Description":
      "Arama motoru optimizasyonunu (SEO) iyileştirmek ve görme engelli müşterilere yardımcı olmak için bu resme kısa bir açıklama ekleyin.",
    "AltTextModal.Input.Label": "Alt Metin",
    "AltTextModal.Input.Placeholder": "Resim ALT Metni",
    "AltTextModal.Button.Cancel": "İptal Et",
    "AltTextModal.Button.Save": "Kaydet",

    "WistiaModal.WhatIsSpocket.Title": "Spocket nedir?",
    "WistiaModal.WhatIsSpocket.Text":
      "Spocket’in işletme kurulumunuzda size nasıl yardımcı olabileceğini öğrenin. Spocket’in nasıl işlediğini ve onu en iyi nasıl kullanabileceğinizi öğrenin.",
    "WistiaModal.WhatIsSpocket.Button": "Ürün Bulmaya Başlayın",
    "WistiaModal.DiscoverProducts.Title": "Ürün Keşfedin",
    "WistiaModal.DiscoverProducts.Text":
      "Uygun filtreler kullanarak doğru ürünleri bulun ve mağazanıza ekleyin.",
    "WistiaModal.DiscoverProducts.Button": "Ürün Bulmaya Başlayın",
    "WistiaModal.ProductCustomization.Title": "Ürünlerinizi Özelleştirin",
    "WistiaModal.ProductCustomization.Text":
      "İçe Aktarma Listesini kullanarak ürünlerinizi mağazanıza uygun şekilde özelleştirin. Ürün açıklamalarını düzenleyebilir, fiyat belirleyebilir, ürünü bir koleksiyona dahil edebilir veya resimlerini kaldırabilirsiniz.",
    "WistiaModal.ProductCustomization.Button": "İçe Aktarma Listesine Git",
    "WistiaModal.OrderProcessing.Title": "Otomatik Sipariş İşleme",
    "WistiaModal.OrderProcessing.Text":
      "Siparişlerinizi tamamlayın ve  tek tıkla tedarikçinin ürünü müşterinizin kapısına kadar teslim etmesini sağlayın.",
    "WistiaModal.OrderProcessing.Button": "Siparişlere Git",
    "WistiaModal.Settings.Title": "Ayarlar",
    "WistiaModal.Settings.Text":
      "Ayarlar sayfası üzerinden tüm hesap bilgilerinizi yönetebilir, faturalarınızı markalandırabilir ve kredi kartınızı ekleyebilirsiniz. Ayrıca tüm ürünleriniz için fiyatlandırma kurallarını ayarlayabilir ve bir abonelik planı seçebilirsiniz.",
    "WistiaModal.Settings.Button": "Hesabınızı Kurun",

    "Footer.Copyright": "Telif Hakkı {{year}}, Spocket. Tüm hakları saklıdır",
    "Footer.PrivacyPolicy": "Gizlilik İlkeleri",
    "Footer.TermsConditions": "Hükümler ve Koşullar",
    "Footer.DataProcessingAddendum": "Veri İşleme Ek Sözleşmesi",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
