export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Mijn Bestellingen",
    "OrderList.Input.Search": "Bestelnummer...",
    "OrderList.Label.Status": "Filteren op Spocket-status",

    "order.status.unpaid": "Onbetaald",
    "order.status.paid": "Betaald",
    "order.status.processing": "In behandeling",
    "order.status.shipped": "Verzonden",
    "order.status.cancelled": "Geannuleerd",
    "order.status.pending": "In afwachting",
    "order.status.partially_paid": "Gedeeltelijk betaald",
    "order.status.partially_refunded": "Gedeeltelijk terugbetaald",
    "order.status.refunded": "Terugbetaald",
    "order.status.voided": "Vervallen",
    "order.status.authorized": "Geautoriseerd",
    "order.status.deleted": "Verwijderd",
    "order.status.pending_payment_confirmation": "In afwachting",

    "OrderList.Label.DownloadHistory": "Download je bestelgeschiedenis als CSV bestand",
    "OrderList.Document.Title": "Bestellingen - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Zoek producten",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Hoe bestellingen te verwerken",
    "OrderList.EmptyOrders.Message.Loading.Title": "Je bestellingen worden geladen!",
    "OrderList.EmptyOrders.Message.Loading.Description": "Een momentje, je bestellingen worden geladen.",
    "OrderList.EmptyOrders.Message.Title": "Je lijst met bestellingen is leeg!",
    "OrderList.EmptyOrders.Message.Description":
      "Je kunt je bestellingen hier beheren en verwerken. Ga naar de zoekpagina om producten aan je importlijst toe te voegen.",
    "OrderList.CheckBox.SelectAllOrders": "Selecteer alle bestellingen",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} bestelling geselecteerd",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} bestelling geselecteerd",
    "OrderList.CheckBox.BulkCheckoutOrders": "Bulkbestelling",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Bulkbestellingen",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Synchroniseer bestellingen",
    "RefreshModal.Button.Cancel": "Annuleren",
    "RefreshModal.Button.Synchronize": "Synchroniseren",
    "RefreshModal.Modal.Body":
      "Bestellingen worden automatisch gesynchroniseerd vanuit je winkel. Synchroniseer alleen wanneer er informatie ontbreekt of niet bijgewerkt is. Laad de pagina na 30 seconden opnieuw.",

    "Order.Label.OrderNumber": "Bestelnummer",
    "Order.Label.Date": "Datum",
    "Order.Label.OrderStatus": "{{integrationName}} Bestelstatus",
    "Order.Table.Th.Product": "Product",
    "Order.Table.Th.Qty": "AANTAL",
    "Order.Table.Th.UnitPrice": "Stukprijs",
    "Order.Table.Th.CheckoutDate": "Afrekendatum",
    "Order.Table.Th.Action": "Actie",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Klantinformatie bekijken",
    "CustomerModal.Modal.Title.CustomerInfo": "Klantinformatie",
    "CustomerModal.Modal.Body.CustomerInfo": "Klantinformatie",
    "CustomerModal.Modal.Body.Address": "Adres",

    "OrderLine.Button.Checkout": "Afrekenen",
    "OrderLine.Tooltip.PreviewInvoice": "Voorbeeldfactuur",
    "OrderLine.Tooltip.PaymentReceipt": "Betalingsbewijs",
    "OrderLine.Tooltip.TrackOrder": "Volg bestelling",
    "OrderLine.Tooltip.OrderProcessing": "Bestelling in behandeling",
    "OrderLine.Tooltip.OrderCancelled": "Bestelling geannuleerd",
    "OrderLine.Tooltip.PleaseConfirm": "Klik om de betaling voor je bestelling te bevestigen",
    "OrderLine.Label.PurchaseEtsy": "Kopen op Etsy",
    "OrderLine.Paying": "Betalen",
    "OrderLine.Failure": "Mislukt",
    "OrderLine.Sample": "Voorbeeld",
    "OrderLine.ErrorOrderRow":
      "Kan de lijst met bestellingen niet vinden in het systeem! Neem contact op met de klantenservice!",

    "ConfirmationModal.Label.OrderDetails": "Bestelgegevens",
    "ConfirmationModal.Label.Total": "Totaal",
    "ConfirmationModal.Label.Shipping": "Verzending",
    "ConfirmationModal.Label.TransactionFees": "Transactiekosten",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Transactiekosten omvatten kosten die in rekening worden gebracht door Stripe, de betalingsprovider van Spocket, en servicekosten.",
    "ConfirmationModal.Modal.OrderConfirmation": "Orderbevestiging",
    "ConfirmationModal.Modal.Alert.Attention": "Let op!",
    "ConfirmationModal.Modal.Alert.Description": "Deze bestelling bevat een opmerking van de klant.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Je staat op het punt een bestelling te plaatsen voor",
    "ConfirmationModal.Modal.Table.Th.Name": "Naam",
    "ConfirmationModal.Modal.Table.Th.Price": "Prijs",
    "ConfirmationModal.Modal.Table.Th.Qty": "AANTAL",
    "ConfirmationModal.Modal.Table.Th.Total": "Totaal",
    "ConfirmationModal.Modal.Label.Note": "Opmerking voor de leverancier",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Je bericht",
    "ConfirmationModal.Button.PlaceOrder": "Plaats bestelling",

    "PaymentCompleteModal.PaymentCompleted": "Betaling voltooid",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Bedankt voor de betaling. De leverancier is op de hoogte gebracht en zal je bestelling binnenkort verwerken. We zullen de status van de bestelling bijwerken en het trackingnummer toevoegen aan je bestelpagina.",
    "PaymentCompleteModal.PaymentIncomplete": "Je bestelling is niet compleet!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Je bank heeft een bevestiging van de betaling nodig om door te gaan",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Gebruik alsjeblieft deze link om de betaling te bevestigen.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Zodra de betaling is bevestigd, wordt de leverancier op de hoogte gebracht en wordt de bestelling verwerkt zoals gewoonlijk. Status- en tracking updates worden toegevoegd aan je bestelpagina. Laad de pagina opnieuw na bevestiging.",

    "SetYourGoalsModal.Title": "Aantal producten toegevoegd aan de winkel",
    "SetYourGoalsModal.Subtitle":
      "De gemiddelde winkel pusht 25 producten voordat er sales gegenereerd worden",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} product",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} producten",
    "SetYourGoalsModal.Button": "Bepaal mijn doel en start met dropshipping",

    "YourGoalsBanner.Container.Description": "Jouw doelen",
    "YourGoalsBanner.Header.Title": "Een bedrijf starten",
    "YourGoalsBanner.Header.Subtitle": "Zorg dat je winkel er klaar voor is",
    "YourGoalsBanner.Progress.Completed": "Voltooid",
    "YourGoalsBanner.ProductPushedCount": "<0> Doel:{{goalsNumberOfProduct}}</0> producten gepusht",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Word lid van Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Eerste zoekopdracht",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} product gepusht",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} producten gepusht",
    "YourGoalsBanner.CheckPoints.ProductsImported":
      "{{count}} / {{goalsNumberOfProduct}} product geïmporteerd",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} producten geïmporteerd",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professioneel",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Geautomatiseerde AliExpress dropshipping",
    "Aliscraper.Subtitle":
      "Importeer met één klik duizenden AliExpress-producten in je e-commerce winkel en automatiseer je dropshipping bedrijf.",
    "Aliscraper.Features.Feature1": "Razendsnel importeren met één klik",
    "Aliscraper.Features.Feature2": "Verwerk direct honderden bestellingen met Spocket",
    "Aliscraper.Features.Feature3": "24/7 klantenservice",
    "Aliscraper.Button": "Download de extensie",
    "Aliscraper.Chrome": "Beschikbaar in de Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Verbind je winkel",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Kies je platform om te beginnen",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Ongeldige winkelnaam",
    "ConnectStoreModal.ShopifyForm.Placeholder": "bijv. MijnWinkel",
    "ConnectStoreModal.ShopifyForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.ShopifyForm.Button": "Verbind winkel",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Je authenticatiesleutel:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Je winkel met Spocket verbinden:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Installeer de Spocket plug-in vanaf",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "hier",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Activeer de plug-in op je pagina met plug-ins",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Ga naar het WordPress Dashboard > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      'Plak je authenticatiesleutel (zoals hierboven beschreven) en klik op "Opslaan"',
    "ConnectStoreModal.WoocommerceInstruction.Button": "Klaar",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.WixForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.WixForm.Button": "Verbind winkel",
    "ConnectStoreModal.FelexForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.FelexForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.FelexForm.Button": "Verbind winkel",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce winkel URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Ongeldige winkelnaam",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "bijv. MijnWinkel",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.BigcommerceForm.Button": "Verbind winkel",
    "ConnectStoreModal.EcwidForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.EcwidForm.Button": "Verbind winkel",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.WhiteLabelForm.Button": "Verbind winkel",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.SquarespaceForm.Button": "Verbind winkel",
    "ConnectStoreModal.SquareForm.FooterText": "Heb je nog geen winkel?",
    "ConnectStoreModal.SquareForm.FooterLink": "Probeer gratis",
    "ConnectStoreModal.SquareForm.Button": "Verbind winkel",
    "ConnectStoreModal.FelexButton.Explanation":
      "Een webwinkel creatie platform voor ondernemers om binnen 10 minuten een dropshipping winkel te lanceren.",
    "ConnectStoreModal.DontHaveStore":  "Heb je nog geen winkel?",
    "ConnectStoreModal.TryForFree": "Probeer gratis",

    "PaymentTab.Document.Title": "Instellingen - Automatisering - Spocket",
    "PaymentTab.Title.Billing": "Facturering",
    "PaymentTab.Tooltip.Billing":
      "Creditcards zullen worden gebruikt voor het verwerken van bestellingen en voor betaling van abonnementen",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Pauzeer/wijzig je abonnement",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Wijzig je abonnement",
    "PaymentTab.AccordionItem.Button.Downgrade": "Downgraden",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Pauzeer je abonnement",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Je winkel is al eerder gepauzeerd geweest",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Pauzeer je abonnement",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Je kunt je Spocket-account tijdelijk pauzeren en terugkomen wanneer je wilt zonder data te verliezen.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Herinner me later",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Herinner me later",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 dagen voordat",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Behoud mijn voordelen en herinner me <1>{{daysBefore}}</1> mijn abonnement verlengd wordt",
    "RemindMeLaterModal.Header.ReminderSet": "Herinnering ingesteld",
    "RemindMeLaterModal.Body.DaysBefore": "e-mail, 3 dagen voordat",
    "RemindMeLaterModal.Body.ReminderSet":
      "Je herinnering is ingesteld en we sturen een <1>{{daysBefore}}</1> je voordelen worden verlengd. Blijf tot die tijd vooral doorgaan met je ondernemersavontuur.",
    "RemindMeLaterModal.Button.Continue": "Doorgaan",
    "PauseStoreModal.Title.PauseYourStore": "Pauzeer je winkel",
    "PauseStoreModal.Description.PauseYourStore":
      "Profiteer van deze eenmalige aanbieding en verlaag je abonnementsprijs met 60% voor de komende 30 dagen",
    "PauseStoreModal.Button.PauseStore": "Pauzeer winkel",
    "PauseStoreModal.Description.StorePaused":
      "Je winkel is gepauzeerd en je abonnementskosten voor de volgende maand worden met 60% verlaagd. Onthoud dat hierna de reguliere betalingen hervat zullen worden.",
    "ConfirmPasswordModal.Title": "Bevestig je wachtwoord",
    "ConfirmPasswordModal.SubTitle": "Om veiligheidsredenen vragen we je om je wachtwoord te bevestigen",
    "ConfirmPasswordModal.Input.Placeholder": "Je wachtwoord",
    "ConfirmPasswordModal.Button.Cancel": "Annuleren",
    "ConfirmPasswordModal.Button.Confirm": "Bevestigen",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "nl",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Groei sneller met Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Kies je factureringsperiode",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Je eerste",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "dagen ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "zijn gratis, annuleer tijdens of na je proefperiode",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Je eerste factuur is ingepland voor",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "Je eerste factuur is verschuldigd na bevestiging",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "Facturering vindt maandelijks plaats",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Maandelijks ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/maand",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Jaarlijks ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/maand",
    "UpgradeConfirmationModal.YearlyTab.Discount": "korting",
    "UpgradeConfirmationModal.TaxRates.Text": "btw",
    "UpgradeConfirmationModal.TaxRates.Period": "maand",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Nu te betalen",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/maand X 12 maanden",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Jouw besparing: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/jaar",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "gelijk aan",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% korting",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Jaarlijkse abonnementen hebben geen proefperiode",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "Het volledige bedrag wordt direct in rekening gebracht",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Betaal met creditcard of debetkaart",
    "UpgradeConfirmationModal.PayPalTab.Info": "Betaal met PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Betalingsgegevens ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Claim mijn gratis proefperiode",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Upgrade naar ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Vertrouwd door meer dan 50.000 ondernemers",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "Onze klanten geven ons consequent een 5-sterren beoordeling.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128-bit SSL encryptie",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "Al je betalingsgegevens zijn 100% veilig bij ons.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "100,000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Vertrouwd door meer dan 50.000 ondernemers",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "Onze klanten geven ons consequent een 5-sterren beoordeling.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128-bit SSL encryptie",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "Al je betalingsgegevens zijn 100% veilig bij ons.",
    "UpgradeConfirmationModal.Agreement.Intro": "Door verder te gaan ga je akkoord met ons/onze ",
    "UpgradeConfirmationModal.Agreement.Terms": "Gebruiksvoorwaarden",
    "UpgradeConfirmationModal.Agreement.Privacy": "Privacybeleid",
    "UpgradeConfirmationModal.Agreement.Refund": "Restitutiebeleid",
    "UpgradeConfirmationModal.Agreement.Middle": "en",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Annuleringsvoorwaarden",

    "OrderDetails.Title": "Bestelgegevens",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Creditcard eindigt op",
    "CreditCard.Update.Button": "Vernieuw kaart",
    "CreditCard.AddCreditCard.Text":
      "Je hebt nog geen creditcard toegevoegd. Voeg een creditcard toe via de onderstaande knop zodat wij je bestellingen automatisch kunnen verwerken",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Creditcard toevoegen",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Winnende producten",
    "Sidebar.WinningProducts.Variant": "Nieuw",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Helpcentrum",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Instellingen",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "We staan klaar om je te helpen!",
    "TutorialModal.WhatIsSpocket": "Wat is Spocket?",
    "TutorialModal.DiscoverProducts": "Ontdek producten",
    "TutorialModal.ProductCustomization": "Productaanpassing",
    "TutorialModal.OrderProcessing": "Verwerken van bestellingen",
    "TutorialModal.Settings": "Instellingen",
    "TutorialModal.GetHelp": "Krijg hulp",
    "TutorialModal.JoinTheCommunity": "Word lid van de community",

    "NotificationCenter.Header": "Meldingencentrum",
    "NotificationCenter.Footer": "Bekijk alle meldingen",
    "NotificationCenter.NoNotification": "Hier is niets te zien!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Mijn producten - Spocket",
    "Products.Title.Header": "Mijn producten",
    "Products.Loading.Top": "Je productenlijst wordt geladen!",
    "Products.Loading.Bottom": "Een momentje, je producten worden geladen.",
    "Products.Empty.Search": "Er zijn geen producten gevonden!",
    "Products.Empty.Top": "Je productenlijst is leeg!",
    "Products.Empty.Bottom": "Ga naar de zoekpagina om producten aan je importlijst toe te voegen.",
    "Products.Empty.Button.Search": "Zoek producten",
    "Products.Empty.Button.How": "Hoe kan ik producten vinden",
    "Products.Alert.Removed": "Product verwijderd",

    "Products.Search.Label": "Zoek je producten",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Zoeken",

    "ProductListItem.Inactive.Tooltip": "Dit product is niet meer beschikbaar bij de leverancier",
    "ProductListItem.Inactive.Title": "Inactief",
    "ProductListItem.OutOfStock.Tooltip": "Alle variaties van dit product zijn niet meer op voorraad",
    "ProductListItem.OutOfStock.Title": "Niet op voorraad",
    "ProductListItem.InventoryCount.Singular": "stuk",
    "ProductListItem.InventoryCount.Plural": "stuks",
    "ProductListItem.InventoryCount.End.Singular": "beschikbaar",
    "ProductListItem.InventoryCount.End.Plural": "beschikbaar",
    "ProductListItem.Button.View": "Bekijk in winkel",
    "ProductListItem.Button.Remove": "Verwijder uit winkel",

    "ImportList.Title.Head": "Importlijst - Spocket",
    "ImportList.Title.Header": "Mijn importlijst",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "Je importlijst wordt geladen!",
    "ImportList.Loading.Bottom": "Een momentje, je producten worden geladen.",
    "ImportList.Empty.Button.Search": "Zoek producten",
    "ImportList.Empty.Top": "Je importlijst is leeg!",
    "ImportList.Empty.Bottom": "Ga naar de zoekpagina om producten aan je importlijst toe te voegen.",
    "ImportList.Empty.Button.How": "Producten aanpassen",
    "ImportList.Alert.Pushed": "Dit product is naar je winkel gepusht:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Search.Label": "Zoeken in je geïmporteerde producten",
    "ImportList.Search.Placeholder": "Zoek je producten",
    "ImportList.Search.Button": "Zoeken",
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Pushen van producten van huidige pagina",
    "PushAllModal.Button.Inactive": "Push huidige pagina",
    "PushAllModal.Modal.Text":
      "Weet je zeker dat je alle producten van de huidige pagina wilt pushen? Alle geldige producten op de huidige pagina zullen naar je winkel worden gepusht.",
    "PushAllModal.Modal.Push": "Alles pushen",
    "PushAllModal.Modal.Cancel": "Annuleren",

    "RemoveAllModal.Button.Active": "Verwijderen van producten van huidige pagina",
    "RemoveAllModal.Button.Inactive": "Verwijder huidige pagina",
    "RemoveAllModal.Modal.Text":
      "Weet je zeker dat je alle producten van de huidige pagina wilt verwijderen? Alle producten op de huidige pagina zullen worden verwijderd van de importlijst.",
    "RemoveAllModal.Modal.Remove": "Alles verwijderen",
    "RemoveAllModal.Modal.Cancel": "Annuleren",

    "ImportListItem.Tab.Product": "Product",
    "ImportListItem.Tab.Description": "Omschrijving",
    "ImportListItem.Tab.Variants": "Varianten",
    "ImportListItem.Tab.Images": "Afbeeldingen",
    "ImportListItem.Remove.Active": "Product aan het verwijderen",
    "ImportListItem.Remove.Inactive": "Product verwijderen",
    "ImportListItem.Push.Active": "Aan het pushen naar winkel",
    "ImportListItem.Push.Inactive": "Pushen naar winkel",
    "ImportListItem.Save.Button": "Opslaan",
    "ImportListItem.Alert.Removed": "Dit product is verwijderd uit je importlijst: ",
    "ImportListItem.Alert.RemoveFailed": "Er is iets mis gegaan met het verwijderen: ",
    "ImportListItem.Alert.CompareAtPrice": "De prijs vergelijken moet hoger zijn dan de verkoopprijs voor: ",
    "ImportListItem.Alert.OneActiveVariation": "Dit product heeft tenminste één actieve variant nodig: ",
    "ImportListItem.Alert.Saved": "Dit product is opgeslagen: ",
    "ImportListItem.Alert.ErrorPushing": "Er is iets fout gegaan tijdens het pushen naar je winkel",
    "ImportListItem.Alert.ErrorSaving": "Er is iets fout gegaan tijdens het opslaan van je winkel",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Verbind je winkel om producten te pushen: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Je hebt tenminste één actieve variatie nodig",
    "ImportListItem.Tooltip.Unavailable": "Dit product is momenteel niet voor verkoop beschikbaar",
    "ImportListItem.Tooltip.Unsaved": "Je hebt niet-opgeslagen wijzigingen",
    "ImportListItem.Tooltip.Profit": "Waarschuwing: Je nettowinst is negatief voor dit product",
    "ImportListItem.Tooltip.Unpurchasable":
      "Je kunt dit product opslaan, maar het is momenteel niet voor verkoop beschikbaar",
    "ImportListItem.Variants.ShowShippingPrices": "Toon verzendkosten",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Productnaam",
    "ItemInfo.ProductTags.Label": "Product tags",
    "ItemInfo.ProductTags.Remove": "Alle tags verwijderen",
    "ItemInfo.ProductTags.Placeholder": "Nieuwe tag toevoegen",
    "ItemInfo.ProductType.Label": "Producttype",
    "ItemInfo.Collection.Label": "Collectie",
    "ItemInfo.Collection.Placeholder": "Selecteren",
    "ItemInfo.Collection.Option": "Selecteren",
    "ItemInfo.SquareLocation.Option": "Selecteren",

    "ItemVariantList.Modal.Title": "Verzendgegevens",
    "ItemVariantList.Modal.Intro": "Verzonden vanuit: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Dit item wordt niet internationaal verzonden.",
    "ItemVariantList.Modal.DoesNotShipTo": "Verzending niet mogelijk naar: ",
    "ItemVariantList.Table.Head.Destination": "Bestemming",
    "ItemVariantList.Table.Head.Cost": "Kosten",
    "ItemVariantList.Table.Head.Time": "Levertijd (dagen)",
    "ItemVariantList.Table.Body.Domestic": "Binnenlands",
    "ItemVariantList.Table.Body.International": "Internationaal",
    "ItemVariantList.VariantsTable.ShippingPrice": "Verzendkosten ",
    "ItemVariantList.VariantsTable.Image": "Afbeelding",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Voorraad",
    "ItemVariantList.VariantsTable.Price": "Prijs",
    "ItemVariantList.VariantsTable.SalesPrice": "Verkoopprijs ",
    "ItemVariantList.VariantsTable.Profit": "Winst",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Prijs vergelijken",
    "ItemVariantList.VariantsTable.Unavailable": "Dit product is momenteel niet voor verkoop beschikbaar",
    "ItemVariantList.VariantsTable.Head": "Alle variaties voor dit product worden hier weergegeven.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ex: 40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify ondersteunt niet meer dan 100 variaties.",

    "ItemVariantList.ContextPopover.Title": "Winst instellen",
    "ItemVariantList.ContextPopover.Apply": "Toepassen",

    "ProductVariation.Description":
      "Je kunt bestellingen voor voorbeeldproducten direct op Spocket plaatsen. Volg de onderstaande stappen om snel en eenvoudig af te rekenen.",
    "ProductVariation.Label.SelectVariant": "Selecteer variant",
    "ProductVariation.Label.SelectTheProductVariation": "Selecteer de productvariant",
    "ProductVariation.Qty": "Aantal",
    "ProductVariation.Label.NotesForTheSupplier": "Opmerkingen voor de leverancier",

    "InputAddress.Label.FirstName": "Voornaam",
    "InputAddress.PlaceHolder.FirstName": "Voer de voornaam in",
    "InputAddress.Label.LastName": "Achternaam",
    "InputAddress.PlaceHolder.LastName": "Voer de achternaam in",
    "InputAddress.Label.StreetAddress": "Straatnaam + huisnummer",
    "InputAddress.Label.Country": "Land",
    "InputAddress.PlaceHolder.Country": "Zoek land",
    "InputAddress.Label.State": "Staat / provincie",
    "InputAddress.Placeholder.State": "Zoek staat / provincie",
    "InputAddress.Label.City": "Woonplaats",
    "InputAddress.PlaceHolder.City": "Voer woonplaats in",
    "InputAddress.Label.Zip": "Postcode",
    "InputAddress.PlaceHolder.Zip": "Voer de postcode in",
    "InputAddress.Label.Phone": "Telefoonnummer",
    "InputAddress.Placeholder.Phone": "Voer het telefoonnummer in",

    "OrderReview.OrderDetails": "Bestelgegevens",
    "OrderReview.Unit": "{{count}} stuk",
    "OrderReview.Unit_plural": "{{count}} stuks",
    "OrderReview.ShippingAddress": "Verzendingsadres",
    "OrderReview.PaymentMethod": "Betaalmethode",
    "OrderReview.Amounts": "Bedragen",
    "OrderReview.Subtotal": "Subtotaal",
    "OrderReview.ShippingCost": "Verzendkosten",
    "OrderReview.TransactionFees": "Transactiekosten",
    "OrderReview.TransactionFees.ToolTips":
      "Transactiekosten omvatten kosten die in rekening worden gebracht door Stripe, de betalingsprovider van Spocket, en servicekosten.",
    "OrderReview.OrderTotal": "Totaal bestelling",
    "OrderReview.ItemWithCount": "{{count}} item",
    "OrderReview.ItemWithCount_plural": "{{count}} items",
    "OrderReview.Placeholder.CreditCard": "Creditcard eindigt op {{last4}}",

    "PaymentComplete.PaymentCompleted": "Betaling voltooid",
    "PaymentComplete.PaymentCompleted.Note":
      "Bedankt voor de betaling. De leverancier is op de hoogte gebracht en zal je bestelling binnenkort verwerken. We zullen de status van de bestelling bijwerken en het trackingnummer toevoegen aan je bestelpagina.",
    "PaymentComplete.PaymentIncomplete": "Je bestelling is niet compleet!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Je bank heeft een bevestiging van de betaling nodig om door te gaan",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink":
      "Gebruik alsjeblieft deze link om de betaling te bevestigen.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Zodra de betaling is bevestigd, wordt de leverancier op de hoogte gebracht en wordt de bestelling verwerkt zoals gewoonlijk. Status- en tracking updates worden toegevoegd aan je bestelpagina. Laad de pagina opnieuw na bevestiging.",

    "CreditCard.AddCreditCard.Description":
      "Je hebt nog geen creditcard toegevoegd. Voeg een creditcard toe via de onderstaande knop zodat wij je bestellingen automatisch kunnen verwerken",
    "CreditCard.Button.AddCreditCard": "Creditcard toevoegen",
    "CreditCard.Button.UpdateCard": "Vernieuw kaart",
    "CreditCard.UpdateCard.Label.CreditCard": "Creditcard",
    "CreditCard.PlaceHolder.CreditCard": "Creditcard eindigt op {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Creditcard",
    "UpdateCreditCardModal.Button.UpdateCard": "Vernieuw kaart",
    "UpdateCreditCardModal.Button.AddCreditCard": "Creditcard toevoegen",
    "UpdateCreditCardModal.Button.Cancel": "Annuleren",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Update je kaartgegevens",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Je account wordt gebruikt tijdens het verwerken van bestellingen van Spocket producten.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Algemene Voorwaarden",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "voor het verwerken van betalingen.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Selecteer variatie",
    "SampleOrderModal.Title.ShippingAddress": "Verzendadres",
    "SampleOrderModal.Title.PaymentMethod": "Betaalmethode",
    "SampleOrderModal.Title.Review": "Recensie",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Betalingsbevestiging is vereist!",
    "SampleOrderModal.Title.OrderPlaced": "Bestelling geplaatst!",
    "SampleOrderModal.Steps": "Stap {{current}} van {{total}}",
    "SampleOrderModal.FinalStep": "Laatste stap",
    "SampleOrderModal.ConfirmPayment": "Bevestig betaling!",
    "SampleOrderModal.Finished": "Klaar",
    "SampleOrderModal.Footer.Continue": "Doorgaan",
    "SampleOrderModal.Footer.PlaceOrder": "Plaats bestelling",
    "SampleOrderModal.Footer.Close": "Sluiten",
    "SampleOrderModal.Footer.Cancel": "Annuleren",
    "SampleOrderModal.Footer.GoBack": "Ga terug",

    "AdvancedFiltersModal.Title.Filters": "Filters",
    "AdvancedFiltersModal.Title.Shipping": "Verzending",
    "AdvancedFiltersModal.Label.ShipsFrom": "Verzending vanuit",
    "AdvancedFiltersModal.Label.ShipsTo": "Verzending naar",
    "AdvancedFiltersModal.Label.ShippingTime": "Verzendtijd",
    "AdvancedFiltersModal.Label.Popular": "Populair",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Verenigde Staten",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Enkel beschikbaar voor verzending naar de VS",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Elk aantal dagen",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 dagen",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 dagen",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 dagen",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15+ dagen",
    "AdvancedFiltersModal.Title.ItemsCost": "Kosten items",
    "AdvancedFiltersModal.Label.ItemCost": "Kosten item",
    "AdvancedFiltersModal.Label.ShippingCost": "Verzendkosten",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Alle kosten",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratis",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "$5 en minder",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "$15 en minder",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "$25 en minder",
    "AdvancedFiltersModal.Title.Supplier": "Leverancier",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Leveranciers",
    "AdvancedFiltersModal.Label.TopSupplier": "Topleverancier",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Leveranciers die bekend staan om hoogwaardige producten, uitstekende service en goede recensies",
    "AdvancedFiltersModal.Title.Advanced": "Geavanceerd",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Premium producten",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Sterk afgeprijsde en snel verzonden producten",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Bestseller",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Best verkopende producten op Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Annuleren",
    "AdvancedFiltersModal.Button.ViewResults": "Bekijk resultaten",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Zoek land...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Landen",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Zoek leverancier...",
    "AdvancedFiltersModal.Label.All Suppliers": "Alle leveranciers",
    "AdvancedFiltersModal.Label.New Suppliers": "Nieuwe leveranciers",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Elk aantal dagen",
    "AdvancedFilters.ShippingTime.One": "1-3 dagen",
    "AdvancedFilters.ShippingTime.Four": "4-7 dagen",
    "AdvancedFilters.ShippingTime.Eight": "8-14 dagen",
    "AdvancedFilters.ShippingTime.Fifteen": "15+ dagen",

    "AdvancedFilters.ShippingCost.Free": "Gratis verzending",
    "AdvancedFilters.ShippingCost.Five": "Verzending onder $5",
    "AdvancedFilters.ShippingCost.Fifteen": "Verzending onder $15",
    "AdvancedFilters.ShippingCost.TwentyFive": "Verzending onder $25",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Alles wissen",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Verzending",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Zoeken",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Verzending vanuit",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Verzending naar",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Leverancier",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Sorteren op",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Kosten item",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Topleveranciers",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Gepersonaliseerde facturen",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Bestseller",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "van {{from}} tot {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Vul trefwoorden in...",
    "Search.Header.Button.Search": "Zoeken",
    "Search.Header.Button.Filters": "Filters",

    "Navigation.UpgradeButton.TryPro": "Probeer Spocket Pro nu GRATIS",
    "Navigation.UpgradeButton.TryEmpire": "Probeer Spocket EMPIRE nu GRATIS",
    "Navigation.UpgradeButton.TryUnicorn": "Probeer Spocket Unicorn nu GRATIS",

    "CategoriesCards.Label.MoreCategories": "Meer categorieën",

    "StaticCategories.WomensClothing": "Dameskleding",
    "StaticCategories.WomensClothing.Activewear": "Sportkleding",
    "StaticCategories.WomensClothing.Bodysuits": "Bodysuits",
    "StaticCategories.WomensClothing.Dresses": "Jurken",
    "StaticCategories.WomensClothing.JacketsCoats": "Jassen",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Jumpsuits en rompertjes",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lingerie en ondergoed",
    "StaticCategories.WomensClothing.MatchingSets": "Bijpassende sets",
    "StaticCategories.WomensClothing.Pants": "Broeken",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Rokken",
    "StaticCategories.WomensClothing.Sleepwear": "Nachtkleding",
    "StaticCategories.WomensClothing.Socks": "Sokken",
    "StaticCategories.WomensClothing.SweatersHoodies": "Sweaters en hoodies",
    "StaticCategories.WomensClothing.Swimwear": "Zwemkleding",
    "StaticCategories.WomensClothing.TankTops": "Tanktops",
    "StaticCategories.WomensClothing.TopsBlouses": "Tops en blouses",
    "StaticCategories.WomensClothing.Tshirts": "T-shirts",

    "StaticCategories.JewelryWatches": "Sieraden en horloges",
    "StaticCategories.JewelryWatches.Bracelets": "Armbanden",
    "StaticCategories.JewelryWatches.Earrings": "Oorbellen",
    "StaticCategories.JewelryWatches.Necklaces": "Kettingen",
    "StaticCategories.JewelryWatches.OtherAccessories": "Andere accessoires",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Hangers, edelstenen en bedeltjes",
    "StaticCategories.JewelryWatches.Rings": "Ringen",
    "StaticCategories.JewelryWatches.Sets": "Sets",
    "StaticCategories.JewelryWatches.Watches": "Horloges",

    "StaticCategories.TechAccessories": "Tech accessoires",
    "StaticCategories.TechAccessories.AudioVideo": "Audio en video",
    "StaticCategories.TechAccessories.CasesCovers": "Hoesjes en covers",
    "StaticCategories.TechAccessories.Lighting": "Verlichting",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Mobiele en laptop accessoires",
    "StaticCategories.TechAccessories.Mousepads": "Muismatten",
    "StaticCategories.TechAccessories.Novelty": "Nieuwigheden",

    "StaticCategories.KidsBabies": "Kinderen en baby's",
    "StaticCategories.KidsBabies.BabyClothing": "Babykleertjes",
    "StaticCategories.KidsBabies.Bathing": "Bad en douche",
    "StaticCategories.KidsBabies.BlanketsPillows": "Dekens en kussens",
    "StaticCategories.KidsBabies.CapsHeadbands": "Caps en hoofdbanden",
    "StaticCategories.KidsBabies.Footwear": "Schoenen",
    "StaticCategories.KidsBabies.Furniture": "Meubilair",
    "StaticCategories.KidsBabies.KidsClothing": "Kinderkleding",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Ouderschap en accessoires",

    "StaticCategories.Toys": "Speelgoed",
    "StaticCategories.Footwear": "Schoenen",
    "StaticCategories.Footwear.Boots": "Laarzen",
    "StaticCategories.Footwear.Flats": "Platte schoenen",
    "StaticCategories.Footwear.Heels": "Hakken",
    "StaticCategories.Footwear.Mens": "Heren",
    "StaticCategories.Footwear.Sandals": "Sandalen",
    "StaticCategories.Footwear.Slippers": "Slippers",
    "StaticCategories.Footwear.SneakersRunners": "Sneakers en hardloopschoenen",

    "StaticCategories.BathBeauty": "Bad en beauty",
    "StaticCategories.BathBeauty.Bodycare": "Lichaamsverzorging",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffusers, oliën en kaarsen",
    "StaticCategories.BathBeauty.Haircare": "Haarproducten",
    "StaticCategories.BathBeauty.Healthcare": "Gezondheidsproducten",
    "StaticCategories.BathBeauty.Makeup": "Make-up",
    "StaticCategories.BathBeauty.Nails": "Nagels",
    "StaticCategories.BathBeauty.Skincare": "Huidverzorging",
    "StaticCategories.BathBeauty.TowelsRobes": "Handdoeken en badjassen",

    "StaticCategories.Pets": "Huisdieren",
    "StaticCategories.Pets.BedsBlankets": "Bedden en dekens",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Riemen, halsbanden en dierenkleding",
    "StaticCategories.Pets.Petcare": "Dierverzorging",
    "StaticCategories.Pets.Toys": "Speelgoed",

    "StaticCategories.HomeGarden": "Huis en tuin",
    "StaticCategories.HomeGarden.Bathroom": "Badkamer",
    "StaticCategories.HomeGarden.Clocks": "Klokken",
    "StaticCategories.HomeGarden.Drinkware": "Drinkbekers",
    "StaticCategories.HomeGarden.FoodBeverage": "Eten en drinken",
    "StaticCategories.HomeGarden.Furniture": "Meubilair",
    "StaticCategories.HomeGarden.Garden": "Tuin",
    "StaticCategories.HomeGarden.HomeDecor": "Woningdecoratie",
    "StaticCategories.HomeGarden.HomeImprovement": "Doe-het-zelf",
    "StaticCategories.HomeGarden.Kitchen": "Keuken",
    "StaticCategories.HomeGarden.Lighting": "Verlichting",
    "StaticCategories.HomeGarden.Organization": "Organisatie",
    "StaticCategories.HomeGarden.StationeryCrafts": "Kantoor en hobby artikelen",
    "StaticCategories.HomeGarden.TextilesPillows": "Textiel en kussens",
    "StaticCategories.HomeGarden.Tools": "Gereedschap",

    "StaticCategories.Accessories": "Accessoires",
    "StaticCategories.Accessories.Belts": "Riemen",
    "StaticCategories.Accessories.Cufflinks": "Manchetknopen",
    "StaticCategories.Accessories.Hats": "Hoeden",
    "StaticCategories.Accessories.Keychains": "Sleutelhangers",
    "StaticCategories.Accessories.ScarvesBandanas": "Sjaals en bandana's",
    "StaticCategories.Accessories.Sunglasses": "Zonnebrillen",

    "StaticCategories.MensClothing": "Herenkleding",
    "StaticCategories.MensClothing.Activewear": "Sportkleding",
    "StaticCategories.MensClothing.DressShirts": "Overhemden",
    "StaticCategories.MensClothing.JacketsCoats": "Jassen",
    "StaticCategories.MensClothing.Pants": "Broeken",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Sokken",
    "StaticCategories.MensClothing.SweatersHoodies": "Sweaters en hoodies",
    "StaticCategories.MensClothing.Swimwear": "Zwemkleding",
    "StaticCategories.MensClothing.TankTops": "Tanktops",
    "StaticCategories.MensClothing.Tshirts": "T-shirts",
    "StaticCategories.MensClothing.Underwear": "Ondergoed",

    "StaticCategories.SportsOutdoors": "Sport en outdoor",
    "StaticCategories.SportsOutdoors.Activewear": "Sportkleding",
    "StaticCategories.SportsOutdoors.Biking": "Fietsen",
    "StaticCategories.SportsOutdoors.Camping": "Kamperen",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Uitrusting en accessoires",
    "StaticCategories.SportsOutdoors.Fishing": "Vissen",

    "StaticCategories.Gifts": "Cadeaus",
    "StaticCategories.Gifts.Adults": "Volwassenen",
    "StaticCategories.Gifts.Funny": "Grappig",
    "StaticCategories.Gifts.Mugs": "Mokken",
    "StaticCategories.Gifts.Novelty": "Nieuwigheden",
    "StaticCategories.Gifts.Personalized": "Gepersonaliseerd",
    "StaticCategories.Gifts.Religious": "Religieus",

    "StaticCategories.Automotive": "Automotive",
    "StaticCategories.BagsWallets": "Tassen en portemonnees",
    "StaticCategories.Seasonal": "Seizoensgebonden",
    "StaticCategories.Seasonal.Christmas": "Kerst",
    "StaticCategories.Seasonal.FathersDay": "Vaderdag",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Moederdag",

    "StaticCategories.FestivalsParties": "Festivals en feesten",
    "StaticCategories.FestivalsParties.PartySupplies": "Feestbenodigdheden",
    "StaticCategories.FestivalsParties.Wedding": "Bruiloft",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Snelle verzending in de VS",
    "QuickFilters.Label.Under5": "Verzending onder $5 in de VS",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> vanuit de <2/> {{ country }}",
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Sorteren op",
    "SortBy.DropDown.Item.Relevance": "Relevantie",
    "SortBy.DropDown.Item.PriceLowToHigh": "Prijs: laag naar hoog",
    "SortBy.DropDown.Item.PriceHighToLow": "Prijs: hoog naar laag",
    "SortBy.DropDown.Item.Newest": "Nieuwste",
    "SortBy.DropDown.Item.ShippingTime": "Verzendtijd",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Home",
    "Breadcrumb.Item.Search": "zoeken",

    "UpgradeModal.Modal.Title": "Selecteer een tariefplan",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Maandelijks",
    "Plans.ToggleSection.Yearly": "Jaarlijks",
    "Plans.ToggleSection.DiscountInitial": "Krijg tot wel",
    "Plans.ToggleSection.DiscountSingular": "Maand",
    "Plans.ToggleSection.DiscountPlural": "maanden",
    "Plans.ToggleSection.DiscountFinal": "korting",
    "Plans.ProrationCost.TextInitial": "Je betaalt momenteel",
    "Plans.ProrationCost.TextFinal": "/jaar. Upgrade naar Empire voor een extra bedrag van slechts",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Start met verkopen",
    "Card.DescriptionVariantType.Professional": "Boost je verkopen",
    "Card.DescriptionVariantType.Empire": "Maximaliseer je verkopen",
    "Card.DescriptionVariantType.Unicorn": "Beheer de verkopen op schaal",
    "Card.DescriptionVariantType.Period": "maand",
    "Card.DescriptionVariantType.PricePeriod": "/maand",
    // eslint-disable-next-line
    "Card.DescriptionVariantType.AnnualDescription": "jaarlijks gefactureerd voor ${{value}}",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Je huidige abonnement",
    "Card.DescriptionVariantType.Button.Start": "Start nu",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Branded facturering",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Winnende producten",
    "Card.DescriptionVariantType.SupplierSourcing": "Inkoop bij leverancier",
    "Card.DescriptionVariantType.ProductRequests": "Productverzoeken",
    "Card.DescriptionVariantType.BulkCheckout": "Bulkbestelling",
    "Card.DescriptionVariantType.Special": "speciaal",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Je Spocket-abonnement is geëindigd.",
    "ReactivationBanner.InfoSubtitle": "Heractiveer je",
    "ReactivationBanner.CheckList.HighQuality": "Hoge kwaliteit",
    "ReactivationBanner.CheckList.Curated": "Samengesteld",
    "ReactivationBanner.CheckList.FastShipping": "Snelle verzending",
    "ReactivationBanner.CheckList.FromUSAndEU": "Vanuit de VS en de EU",
    "ReactivationBanner.TextByPlan.Starter.Description":
      "Je zult niet langer de mogelijk hebben om <1>producten te pushen</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket account",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Begin nu met het pushen van producten naar je winkel",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Je hebt niet langer de mogelijkheid om <1>producten live te pushen</1> of gebruik te maken van ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Premium producten",
    "ReactivationBanner.TextByPlan.Others.Title": "Premium account",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Krijg toegang tot premium producten die",
    "ReactivationBanner.Button": "Heractiveer mijn account NU",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Meer product pushes",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Ongelimiteerd aantal bestellingen",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Branded facturering",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Premium producten",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Exclusieve deals",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Chat en telefonische ondersteuning",
    "UpgradeReasonModal.Modal.Title": "Wat wil je bereiken met Spocket?",
    "UpgradeReasonModal.Modal.Question": "Wat heeft je doen upgraden?",
    "UpgradeReasonModal.Modal.Button": "Start met het gebruiken van Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Het helpt ons om jouw ervaring te verbeteren",

    "UpgradeSuccessModal.Col.One.A": "Gefeliciteerd",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Je hebt alle tools ontgrendeld die je nodig hebt om een succesvol dropshipping bedrijf op te bouwen.",
    "UpgradeSuccessModal.Col.Two.B": "Je hebt nu de beschikking over ",
    "UpgradeSuccessModal.Col.Two.C": " Veel Spocket-plezier!",
    "UpgradeSuccessModal.Button": "Doorgaan",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 maand",
    "Promotion.TrialDaysNormalizer.28days": "4 weken",
    "Promotion.TrialDaysNormalizer.21days": "3 weken",
    "Promotion.TrialDaysNormalizer.14days": "2 weken",
    "Promotion.TrialDaysNormalizer.7days": "1 week",
    "Promotion.TrialDaysNormalizer.1day": "1 dag",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} dagen",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Probeer gratis",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Probeer het nu →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Taal",

    "WhatYouWillLoseModal.Button.Offer": "Ga verder",
    "WhatYouWillLoseModal.Button.Downgrade": "Ik geef het op, downgrade mijn account",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Je verliest alle <1>{{product_count}} geïmporteerde producten</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} extra",
    "WhatYouWillLoseModal.ModalWrapper.Header": "geef je het nu al op?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Krijg 50% korting",
    "WhatYouWillLoseModal.ModalWrapper.Title": "Zet je ondernemersreis de komende 3 maanden voort.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "We willen graag dat jij óók een succesverhaal wordt. We weten dat je al een hoop tijd geïnvesteerd hebt. Dus ga vooral door. We staan allemaal voor je te juichen. Het team van Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Gefeliciteerd, we hebben een korting van 50% toegevoegd aan je abonnement voor de komende 3 maanden!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Doorgaan",

    "DowngradeReasonModal.DowngradeSection": "Hoe kunnen we Spocket voor jou verbeteren?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "We willen je feedback over alles (minimaal 100 tekens)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Voer je feedback in (minimaal 100 tekens)",
    "DowngradeReasonModal.Button.Downgrade": "Downgraden",
    "DowngradeReasonModal.ModalWrapper.Header": "{{storeName}} downgraden?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Neem contact op",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Annuleren",

    "DowngradeSuccessModal.Header": "Downgrade succesvol",
    "DowngradeSuccessModal.Body":
      "Bedankt voor je feedback. We werken er iedere dag hard aan Spocket te verbeteren en hopen dat je ons snel weer een kans geeft!",

    "Settings.title": "Instelling",
    "Settings.buttonSaveChanges": "Wijzigingen opslaan",
    "Settings.buttonSaveAndPreview": "Opslaan en bekijken",
    "Settings.PricingPlans.Title": "Tarieven van abonnementen",

    "Settings.MenuTab.Plans": "Abonnementen",
    "Settings.MenuTab.Account": "Account",
    "Settings.MenuTab.BrandedInvoicing": "Branded facturering",
    "Settings.MenuTab.Billing": "Facturering",
    "Settings.MenuTab.GlobalPricingRules": "Globale prijsregels",
    "Settings.MenuTab.Security": "Beveiliging",

    "AccountTab.documentTitle": "Instellingen - Account - Spocket",
    "AccountTab.title": "Accountinstelling",
    "AccountTab.shopName": "Shopnaam",
    "AccountTab.email": "E-mail",
    "AccountTab.shopUrl": "Shop URL",
    "AccountTab.defaultCurrency": "Standaardvaluta",
    "AccountTab.yourPlan": "Je abonnement",
    "AccountTab.accountNotActive": "Je account is momenteel niet actief",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Deze functie zal binnenkort worden toegevoegd! Nu kun je nog verbinden via de Spocket Shopify app!",
    "AccountTab.alertAccountSettingsUpdated": "Accountinstellingen bijgewerkt",
    "AccountTab.alertInvalidShopUrl": "Ongeldige shop URL",
    "AccountTab.productsImported": "Producten geïmporteerd",
    "AccountTab.products": "Producten",
    "AccountTab.premiumProducts": "Premium producten",
    "AccountTab.total": "totaal",
    "AccountTab.signOut": "Afmelden",
    "AccountTab.limitReachedProductsAndPremium": "Limiet voor producten en premium producten bereikt.",
    "AccountTab.limitReachedProducts": "Limiet voor producten bereikt.",
    "AccountTab.limitReachedPremium": "Limiet voor premium producten bereikt.",
    "AccountTab.buttonSwitchToAnnualPlan": "Wijzig naar jaarabonnement",
    "AccountTab.off30Percent": "30% korting",
    "AccountTab.off40Percent": "40% korting",
    "AccountTab.off45Percent": "45% korting",
    "AccountTab.sellMoreWithEmpirePlan": "Verkoop meer met het Empire abonnement",
    "AccountTab.tooltipCurrency":
      "Je valuta wordt beheerd via je winkelaccount. Alle prijzen voor je geïmporteerde producten worden omgerekend naar die valuta.",
    "AccountTab.shopNotConnectedYet": "Je winkel is nog niet met dit account verbonden.",
    "AccountTab.connectYourShop": "Verbind je winkel",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Instellingen - Facturering - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Factuurinstellingen opgeslagen!",
    "InvoiceTab.shopLogo": "Winkellogo",
    "InvoiceTab.contactEmail": "Contact e-mailadres",
    "InvoiceTab.phoneNumber": "Telefoonnummer",
    "InvoiceTab.personalNote": "Persoonlijk bericht",
    "InvoiceTab.tooltipPersonalNote":
      "Stel een persoonlijk bericht in om toe te voegen aan al je branded facturen",
    "InvoiceTab.brandedInvoicing": "Branded facturering",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Voeg het logo van je merk en een persoonlijk bericht toe aan alle bestellingen van leveranciers die branded facturering aanbieden wanneer je een betaald abonnement hebt",
    "InvoiceTab.disabledInvoicing": "Voeg branded facturering toe aan alle bestellingen",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Het uitschakelen van deze optie voorkomt branded facturering bij je bestellingen",

    "PricingTab.titleDefaultPricingRules": "Standaard prijsregels",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Geavanceerde prijsregels",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Centen toevoegen",
    "PricingTab.tooltipAssignCents":
      "Deze waarde wordt gebruikt bij het weergeven van de uiteindelijke prijs voor je artikelen (als je bijvoorbeeld wilt dat de kosten van je product XX,99 zijn, voeg je 99 toe aan de onderstaande velden).",
    "PricingTab.Markup": "Verhoging",
    "PricingTab.MarkupType": "Type verhoging",
    "PricingTab.SelectDefault": "-- Selecteer een --",
    "PricingTab.SelectPercent": "Procent",
    "PricingTab.SelectMultiplier": "Vermenigvuldiger",
    "PricingTab.SelectFixed": "Vast",
    "PricingTab.titlePricingRules": "Instellingen - Prijsregels - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Geavanceerde prijsregels inschakelen",
    "PricingTab.from": "Van",
    "PricingTab.to": "Voor",
    "PricingTab.selectFixedAmount": "Vast bedrag",
    "PricingTab.buttonRemove": "Verwijderen",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Je kunt niet meer dan één regel met lege velden maken",
    "PricingTab.costRange": "Kostenbereik",
    "PricingTab.markup": "Verhoging",
    "PricingTab.add": "Toevoegen",

    "SecurityTab.Document.Title": "Instellingen - Beveiliging - Spocket",
    "SecurityTab.alertIncorrectPassword": "Onjuist wachtwoord",
    "SecurityTab.title": "Beveiliging",
    "SecurityTab.yourNewPassword": "Je nieuwe wachtwoord",
    "SecurityTab.repeatPassword": "Herhaal wachtwoord",
    "SecurityTab.errorPleaseEnterAPassword": "Voer een wachtwoord in.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Voer een wachtwoordbevestiging in.",
    "SecurityTab.errorPasswordsDoNotMatch": "De wachtwoorden komen niet overeen",
    "SecurityTab.errorPasswordShort": "Het wachtwoord moet 8 of meer tekens bevatten",

    "ListingCards.TopSupplierTag.Tooltip":
      "Topleveranciers staan bekend om hun hoogwaardige producten, uitstekende service en goede recensies",
    "ListingCards.TopSupplierTag.Text": "Leverancier",

    "ListingCard.OriginCountry.Intro": "Door",
    "ListingCard.OriginCountry.Tooltip": "Verzending vanuit",
    "ListingCard.Shipping.Price.Title": "Verzendkosten",
    "ListingCard.Shipping.Price.Free": "GRATIS",
    "ListingCard.Price.Intro": "Prijsvermelding",
    "ListingCard.Price.Retail": "Verkoopprijs",
    "ListingCard.Shipping.Time.Title": "Verzendtijd",
    "ListingCard.Shipping.Time.Period": "dagen",
    "ListingCard.PremiumIcon.Tooltip":
      "Verkoop exclusieve, sterk afgeprijsde en snel verzonden producten in je winkel",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Toevoegen aan importlijst",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Gepusht naar winkel",
    "ListingCard.StatusTag.Imported.Title": "Geïmporteerd",
    "ListingCard.StatusTag.Imported.Text": "Dit item staat al op je Importlijst",
    "ListingCard.StatusTag.Pushed.Title": "Op voorraad",
    "ListingCard.StatusTag.Pusehd.Text": "Dit item is naar je winkel gepusht",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Productomschrijving",
    "ListingModal.DetailsSection.Button.ProductVariations": "Open productvariaties",
    "ListingModal.DetailsSection.Button.OrderSamples": "Bestel voorbeelden",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Nieuw",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "Branded facturering is niet beschikbaar voor dit product",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Internationale bestellingen worden verzonden vanuit ons magazijn in China",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Items die niet meer op voorraad zijn in ons magazijn in de VS kunnen vanuit ons magazijn in China worden verzonden",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "De bestellingen worden mogelijkerwijs als meerdere pakketten verzonden om de algehele verzendtijd voor je bestellingen te reduceren",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Verbind je winkel om een bestelling te plaatsen",
    "ListingModal.DetailsSection.PushedListButton": "Gepusht naar winkel",
    "ListingModal.DetailsSection.RemoveListButton": "Verwijder van lijst",
    "ListingModal.DetailsSection.ImportListButton": "Toevoegen aan importlijst",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Aanbieding gedeactiveerd",

    "InfoSection.ShippingTime.Tooltip": "Aantal dagen tot levering nadat het product verzonden is.",
    "InfoSection.ShippingTime.Title": "Verzendtijd",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Wereldwijd",
    "InfoSection.ShippingExcluded.Intro": "Verzending niet mogelijk naar",
    "InfoSection.ShippingExcluded.Various": "Verschillende",
    "InfoSection.ProcessingTime.Tooltip":
      "Aantal werkdagen dat de leverancier nodig heeft voor verzending en levering van trackingnummer",
    "InfoSection.ProcessingTime.Title": "Verwerkingstijd",
    "InfoSection.ProcessingTime.Period": "werkdagen",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Dit product wordt verzonden voor {{price_formatted}}, plus {{incremental_price_formatted}} voor ieder aanvullend product in dezelfde bestelling",
    "InfoSection.ShippingInfoLine.Period": "werkdagen",
    "InfoSection.ShippingInfoLine.Free": "GRATIS",
    "InfoSection.ShippingInfoLine.NoShipping": "Verzending niet mogelijk",

    "ReturnPolicySection.Title": "Retourbeleid",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Van",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Door",
    "TitleSection.PriceMSRP.Listing": "Aanbiedingsprijs",
    "TitleSection.PriceMSRP.Retail": "Verkoopprijs",

    "ProductVariationsModal.Title": "Productvariaties",
    "ProductVariationsModal.Table.Image": "Afbeelding",
    "ProductVariationsModal.Table.Inventory": "Voorraad",
    "ProductVariationsModal.Table.Price": "Prijs",
    "ProductVariationsModal.ImageSpecifications.Title": "Maattabel",
    "ProductVariationsModal.ImageSpecifications.Button": "Downloaden",

    "TopSupplier.Tooltip":
      "Topleveranciers staan bekend om hun hoogwaardige producten, uitstekende service en goede recensies",
    "TopSupplier.Text": "Topleverancier",

    "BestSelling.Tooltip.Top": "Dit product behoort tot de best verkopende producten op Spocket",
    "BestSelling.Tooltip.Bottom": "Bestseller",

    "GallerySection.PremiumIcon.Tooltip":
      "Verkoop exclusieve, sterk afgeprijsde en snel verzonden producten in je winkel",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "{{value}}% extra korting",

    "Upgrade.Title.AnnualToggled": "Meld je vandaag nog aan voor Spocket premium",
    "Upgrade.Title.MonthlyToggled": "Probeer Spocket premium nu 14 dagen gratis",
    "Upgrade.Subtitle":
      "Probeer vandaag nog een Spocket premium abonnement en begin producten met hoog conversieratio uit de VS en Europa aan je winkel toe te voegen.",
    "Upgrade.Button": "Ga verder zonder Spocket premium",

    "Search.NoProductsFound.Title": "Sorry! We konden geen resultaten vinden",
    "Search.NoProductsFound.For": "voor",
    "Search.NoProductsFound.Description":
      "Controleer de spelling, probeer een gerelateerde of meer algemene zoekterm.",
    "Search.Searching.Title": "We zijn aan het zoeken naar producten...",
    "Search.Searching.Description": "Een moment geduld, we zijn de nieuwste producten voor je aan het laden.",

    "Suppliers.StarRating.Tooltip": "Succespercentage van orderafhandeling van leverancier",
    "Suppliers.RefundPolicy": "Retourbeleid van de leverancier",
    "Suppliers.Joined.Title": "Heeft zich aangemeld voor Spocket",
    "Suppliers.Origin.Title": "Verstuurt vanuit land",
    "Suppliers.Time.Title": "Gemiddelde verwerkingstijd",
    "Suppliers.HasMore.Title": "We zijn aan het zoeken naar producten...",
    "Suppliers.HasMore.Subtitle": "Een moment geduld, we zijn de nieuwste producten voor je aan het laden.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Topleveranciers staan bekend om hun hoogwaardige producten, uitstekende service en goede recensies",
    "Suppliers.TopSupplierTag.Text": "Topleverancier",

    "AltTextModal.Header.Title": "Bewerk afbeeldingsdetails",
    "AltTextModal.Body.Description":
      "Geef een korte beschrijving van deze afbeelding om de zoekmachineoptimalisatie (SEO) en toegankelijkheid voor slechtziende klanten te verbeteren.",
    "AltTextModal.Input.Label": "Alt tekst",
    "AltTextModal.Input.Placeholder": "Afbeelding ALT tekst",
    "AltTextModal.Button.Cancel": "Annuleren",
    "AltTextModal.Button.Save": "Opslaan",

    "WistiaModal.WhatIsSpocket.Title": "Wat is Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Ontdek hoe Spocket jou kan helpen bij het opbouwen van je bedrijf. Begrijp hoe Spocket werkt en hoe je er het meeste uit kunt halen.",
    "WistiaModal.WhatIsSpocket.Button": "Start met het vinden van producten",
    "WistiaModal.DiscoverProducts.Title": "Ontdek producten",
    "WistiaModal.DiscoverProducts.Text":
      "Vind de juiste producten met behulp van geschikte filters en voeg ze toe aan je winkel.",
    "WistiaModal.DiscoverProducts.Button": "Start met het vinden van producten",
    "WistiaModal.ProductCustomization.Title": "Pas je producten aan",
    "WistiaModal.ProductCustomization.Text":
      "Gebruik de importlijst om je producten aan te passen aan jouw winkel. Je kunt de productbeschrijvingen bewerken, de prijzen instellen, het product aan een collectie toevoegen en afbeeldingen verwijderen.",
    "WistiaModal.ProductCustomization.Button": "Ga naar importlijst",
    "WistiaModal.OrderProcessing.Title": "Geautomatiseerd verwerken van bestellingen",
    "WistiaModal.OrderProcessing.Text":
      "Voer je bestellingen uit en laat de leverancier het product met één klik bij de klant afleveren.",
    "WistiaModal.OrderProcessing.Button": "Ga naar bestellingen",
    "WistiaModal.Settings.Title": "Instellingen",
    "WistiaModal.Settings.Text":
      "Op de instellingenpagina kun je al je accountinformatie beheren, je factuur van eigen branding voorzien en je creditcard toevoegen. Je kunt ook de prijsregels voor al je producten aanpassen en een abonnement kiezen.",
    "WistiaModal.Settings.Button": "Stel je account in",

    "Footer.Copyright": "Copyright {{year}}, Spocket. Alle rechten voorbehouden",
    "Footer.PrivacyPolicy": "Privacybeleid",
    "Footer.TermsConditions": "Algemene voorwaarden",
    "Footer.DataProcessingAddendum": "Addendum gegevensverwerking",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
