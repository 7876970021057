export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "我的訂單清單",
    "OrderList.Input.Search": "訂單號碼...",
    "OrderList.Label.Status": "按Spocket狀態篩選",

    "order.status.unpaid": "未付款",
    "order.status.paid": "已付款",
    "order.status.processing": "處理中",
    "order.status.shipped": "已出貨",
    "order.status.cancelled": "已取消",
    "order.status.pending": "等候中",
    "order.status.partially_paid": "部分付款",
    "order.status.partially_refunded": "部分退款",
    "order.status.refunded": "已退款",
    "order.status.voided": "無效",
    "order.status.authorized": "已授權",
    "order.status.deleted": "已刪除",
    "order.status.pending_payment_confirmation": "等候中",

    "OrderList.Label.DownloadHistory": "下載所有訂單紀錄CSV",
    "OrderList.Document.Title": "訂單 - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "搜尋產品",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "如何處理訂單",
    "OrderList.EmptyOrders.Message.Loading.Title": "正在載入您的訂單！",
    "OrderList.EmptyOrders.Message.Loading.Description": "請稍候，我們正在擷取您的訂單。",
    "OrderList.EmptyOrders.Message.Title": "您的訂單清單是空的！",
    "OrderList.EmptyOrders.Message.Description":
      "您可以在此處管理與處理您的訂單。前往搜尋頁面，開始將產品新增至您的導入清單。",
    "OrderList.CheckBox.SelectAllOrders": "選取所有訂單",
    "OrderList.CheckBox.SelectedXOrders": "已選取{{count}}筆訂單",
    "OrderList.CheckBox.SelectedXOrders_plural": "已選取{{count}}筆訂單",
    "OrderList.CheckBox.BulkCheckoutOrders": "大量結帳訂單",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "大量結帳訂單",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "同步訂單",
    "RefreshModal.Button.Cancel": "取消",
    "RefreshModal.Button.Synchronize": "同步",
    "RefreshModal.Modal.Body":
      "訂單會從您的商店自動同步。僅在缺少訂單資訊或尚未更新訂單資訊時才會進行同步。請您在30秒後重新整理頁面。",

    "Order.Label.OrderNumber": "訂單號碼",
    "Order.Label.Date": "日期",
    "Order.Label.OrderStatus": "{{integrationName}}訂單狀態",
    "Order.Table.Th.Product": "產品",
    "Order.Table.Th.Qty": "數量",
    "Order.Table.Th.UnitPrice": "單位價格",
    "Order.Table.Th.CheckoutDate": "結帳日期",
    "Order.Table.Th.Action": "動作",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "查看客戶資訊",
    "CustomerModal.Modal.Title.CustomerInfo": "客戶資訊",
    "CustomerModal.Modal.Body.CustomerInfo": "客戶資訊",
    "CustomerModal.Modal.Body.Address": "地址",

    "OrderLine.Button.Checkout": "結帳",
    "OrderLine.Tooltip.PreviewInvoice": "預覽發票",
    "OrderLine.Tooltip.PaymentReceipt": "付款收據",
    "OrderLine.Tooltip.TrackOrder": "追蹤訂單",
    "OrderLine.Tooltip.OrderProcessing": "訂單處理",
    "OrderLine.Tooltip.OrderCancelled": "訂單已取消",
    "OrderLine.Tooltip.PleaseConfirm": "請點擊以確認您的訂單付款",
    "OrderLine.Label.PurchaseEtsy": "在Etsy購買",
    "OrderLine.Paying": "付款",
    "OrderLine.Failure": "失敗",
    "OrderLine.Sample": "樣品",
    "OrderLine.ErrorOrderRow": "無法在系統中找到已訂購的清單！請聯絡支援服務！",

    "ConfirmationModal.Label.OrderDetails": "訂單詳細資訊",
    "ConfirmationModal.Label.Total": "總計",
    "ConfirmationModal.Label.Shipping": "運送",
    "ConfirmationModal.Label.TransactionFees": "交易費用",
    "ConfirmationModal.Tooltip.TransactionFees":
      "交易費用包含Spocket的支付服務供應商Stripe收取的費用，以及服務費用。",
    "ConfirmationModal.Modal.OrderConfirmation": "訂單確認",
    "ConfirmationModal.Modal.Alert.Attention": "注意！",
    "ConfirmationModal.Modal.Alert.Description": "此訂單包含來自於客戶的備註。",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "您將要訂購，產品為",
    "ConfirmationModal.Modal.Table.Th.Name": "名稱",
    "ConfirmationModal.Modal.Table.Th.Price": "價格",
    "ConfirmationModal.Modal.Table.Th.Qty": "數量",
    "ConfirmationModal.Modal.Table.Th.Total": "總計",
    "ConfirmationModal.Modal.Label.Note": "給供應商的備註",
    "ConfirmationModal.Modal.PlaceHolder.Note": "您的訊息",
    "ConfirmationModal.Button.PlaceOrder": "下單",

    "PaymentCompleteModal.PaymentCompleted": "付款完成",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "感謝您的付款。供應商已收到通知，並將立即處理訂單。我們將更新訂單狀態，並在您的訂單頁面新增追蹤號碼。",
    "PaymentCompleteModal.PaymentIncomplete": "您的訂單不完整！",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note": "您的銀行需要您確認付款才能繼續",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink": "請使用此鏈接確認付款。",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "確認付款後，供應商會收到通知，您的訂單將正常處理。狀態和追蹤更新將會新增至您的訂單頁面。請在確認後重新載入頁面。",

    "SetYourGoalsModal.Title": "新增至商店的產品數量",
    "SetYourGoalsModal.Subtitle": "一家典型的商店會在進行銷售之前推送25項產品",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}}項產品",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}}項產品",
    "SetYourGoalsModal.Button": "設定我的目標，並開始直運（Dropshipping）",

    "YourGoalsBanner.Container.Description": "您的目標",
    "YourGoalsBanner.Header.Title": "開始您的業務",
    "YourGoalsBanner.Header.Subtitle": "讓您的商店做好準備",
    "YourGoalsBanner.Progress.Completed": "已完成",
    "YourGoalsBanner.ProductPushedCount": "<0>目標：推送{{goalsNumberOfProduct}}</0>項產品",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "加入Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "優先搜尋",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "已推送{{count}} / {{goalsNumberOfProduct}}項產品",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural": "已推送{{count}} / {{goalsNumberOfProduct}}項產品",
    "YourGoalsBanner.CheckPoints.ProductsImported": "已導入{{count}} / {{goalsNumberOfProduct}}項產品",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural": "已導入{{count}} / {{goalsNumberOfProduct}}項產品",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "自動化AliExpress（全球速賣通）直運",
    "Aliscraper.Subtitle": "一鍵式將數以千計的AliExpress產品導入到您的電子商務店鋪，並且自動化您的直運業務。",
    "Aliscraper.Features.Feature1": "一鍵快速導入",
    "Aliscraper.Features.Feature2": "使用Spocket立即處理數以百計的產品",
    "Aliscraper.Features.Feature3": "全年無休的客戶支援",
    "Aliscraper.Button": "下載延伸套件",
    "Aliscraper.Chrome": "Chrome網路商店現已提供",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "連線您的商店",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "選擇您要開始使用的平台",
    "ConnectStoreModal.ShopifyForm.InvalidName": "無效的商店名稱",
    "ConnectStoreModal.ShopifyForm.Placeholder": "例如：MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.ShopifyForm.FooterLink": "免費試用",
    "ConnectStoreModal.ShopifyForm.Button": "連線商店",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "您的授權金鑰：",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "如何將您的商店連線至Spocket：",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "安裝Spocket外掛程式，從",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "此處",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "在您的外掛程式頁面啟動外掛程式",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "前往WordPress控制台主頁 > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "貼上您的授權金鑰（如上方所列），並點擊「Save（儲存）」",
    "ConnectStoreModal.WoocommerceInstruction.Button": "完成",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.WixForm.FooterLink": "免費試用",
    "ConnectStoreModal.WixForm.Button": "連線商店",
    "ConnectStoreModal.FelexForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.FelexForm.FooterLink": "免費試用",
    "ConnectStoreModal.FelexForm.Button": "連線商店",
    "ConnectStoreModal.BigcommerceForm.UrlText": "BigCommerce商店網址",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "無效的商店名稱",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "例如：MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "免費試用",
    "ConnectStoreModal.BigcommerceForm.Button": "連線商店",
    "ConnectStoreModal.EcwidForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.EcwidForm.FooterLink": "免費試用",
    "ConnectStoreModal.EcwidForm.Button": "連線商店",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "免費試用",
    "ConnectStoreModal.WhiteLabelForm.Button": "連線商店",
    "ConnectStoreModal.SquarespaceForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "免費試用",
    "ConnectStoreModal.SquarespaceForm.Button": "連線商店",
    "ConnectStoreModal.SquareForm.FooterText": "還沒有商店嗎？",
    "ConnectStoreModal.SquareForm.FooterLink": "免費試用",
    "ConnectStoreModal.SquareForm.Button": "連線商店",
    "ConnectStoreModal.FelexButton.Explanation":
      "專為企業家提供的網路商店建立平台，讓企業家在十分鐘內即可推出直運商店。",
    "ConnectStoreModal.DontHaveStore":  "還沒有商店嗎？",
    "ConnectStoreModal.TryForFree": "免費試用",

    "PaymentTab.Document.Title": "設定 - 自動化 - Spocket",
    "PaymentTab.Title.Billing": "計費",
    "PaymentTab.Tooltip.Billing": "信用卡將用於訂單處理與訂購付款",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "暫停/變更您的方案",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "變更您的方案",
    "PaymentTab.AccordionItem.Button.Downgrade": "降級",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "暫停您的方案",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "您的商店之前已暫停",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "暫停您的方案",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "您可以暫停Spocket帳戶，之後可隨時恢復而不會遺失任何資料。",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "稍後提醒我",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "稍後提醒我",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3天前",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater": "保留我的權益並在續訂前<1>{{daysBefore}}</1>提醒我",
    "RemindMeLaterModal.Header.ReminderSet": "提醒設定",
    "RemindMeLaterModal.Body.DaysBefore": "在3天前寄送電子郵件給您",
    "RemindMeLaterModal.Body.ReminderSet":
      "您的提醒已設定，我們將在您的權益續訂前<1>{{daysBefore}}</1>提醒您，在此之前，請繼續您的創業。",
    "RemindMeLaterModal.Button.Continue": "繼續",
    "PauseStoreModal.Title.PauseYourStore": "暫停您的商店",
    "PauseStoreModal.Description.PauseYourStore": "利用這個一次性優惠，在接下來的30天內降低60%的方案價格",
    "PauseStoreModal.Button.PauseStore": "暫停商店",
    "PauseStoreModal.Description.StorePaused":
      "您的商店已暫停，下個月的訂購金額將減少60%。請注意，之後將繼續定期付款。",
    "ConfirmPasswordModal.Title": "請確認您的密碼",
    "ConfirmPasswordModal.SubTitle": "基於安全原因，請確認您的密碼",
    "ConfirmPasswordModal.Input.Placeholder": "您的密碼",
    "ConfirmPasswordModal.Button.Cancel": "取消",
    "ConfirmPasswordModal.Button.Confirm": "確認",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "zh-cn",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "借助Spocket實現更快發展 ",
    "UpgradeConfirmationModal.Body.Title": "選擇您的計費週期",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "您的前",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "天 ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final": "為免費，請在試用期間或試用結束後取消",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "您的第一筆帳單將安排在",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial": "您的第一筆帳單將在確認後到期",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "計費方式將按月循環",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "每月 ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/月",
    "UpgradeConfirmationModal.YearlyTab.RateType": "每年 ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/月",
    "UpgradeConfirmationModal.YearlyTab.Discount": "關閉",
    "UpgradeConfirmationModal.TaxRates.Text": "銷售稅",
    "UpgradeConfirmationModal.TaxRates.Period": "月",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "立即付款",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/月 X 12個月",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "您節省的金額：",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/年",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "等於",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "%優惠",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "年度方案沒有試用期",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "將立即向您全額收費",
    "UpgradeConfirmationModal.CreditCardTab.Info": "使用信用卡/簽帳金融卡支付",
    "UpgradeConfirmationModal.PayPalTab.Info": "使用PayPal支付",
    "UpgradeConfirmationModal.PaymentSection.Details": "付款詳細資訊 ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "索取免費試用",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "升級至 ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "受到100,000多位企業家的信賴",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom": "客戶始終給予我們5/5的評價。",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128位元SSL加密",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "我們會100%保護您的付款詳細資訊。",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "100,000多",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "受到100,000多位企業家的信賴",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom": "客戶始終給予我們5/5的評價。",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128位元SSL加密",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "我們會100%保護您的付款詳細資訊。",
    "UpgradeConfirmationModal.Agreement.Intro": "如果繼續，即代表您同意 ",
    "UpgradeConfirmationModal.Agreement.Terms": "使用條款",
    "UpgradeConfirmationModal.Agreement.Privacy": "隱私政策",
    "UpgradeConfirmationModal.Agreement.Refund": "退款政策",
    "UpgradeConfirmationModal.Agreement.Middle": "，與",
    "UpgradeConfirmationModal.Agreement.Cancelation": "取消政策",

    "OrderDetails.Title": "訂單詳細資訊",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "信用卡尾號為",
    "CreditCard.Update.Button": "更新銀行卡",
    "CreditCard.AddCreditCard.Text":
      "您尚未新增任何的信用卡。請從下方的按鈕新增信用卡，以允許我們自動處理您的訂單",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "新增信用卡",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "暢銷產品",
    "Sidebar.WinningProducts.Variant": "新的",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "應用程式",
    "Sidebar.HelpCenter": "說明中心",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "設定",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "我們在此協助您!",
    "TutorialModal.WhatIsSpocket": "Spocket是什麼呢？",
    "TutorialModal.DiscoverProducts": "探索產品",
    "TutorialModal.ProductCustomization": "產品客製化",
    "TutorialModal.OrderProcessing": "訂單處理",
    "TutorialModal.Settings": "設定",
    "TutorialModal.GetHelp": "取得說明",
    "TutorialModal.JoinTheCommunity": "加入社群",

    "NotificationCenter.Header": "通知中心",
    "NotificationCenter.Footer": "查看所有通知",
    "NotificationCenter.NoNotification": "沒有任何內容！",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English


    "Products.Title.Head": "我的產品 - Spocket",
    "Products.Title.Header": "我的產品",
    "Products.Loading.Top": "正在載入您的產品清單！",
    "Products.Loading.Bottom": "請稍候，我們正在擷取您的產品。",
    "Products.Empty.Search": "找不到產品！",
    "Products.Empty.Top": "您的產品清單是空的！",
    "Products.Empty.Bottom": "前往搜尋頁面，開始將產品新增至您的導入清單。",
    "Products.Empty.Button.Search": "搜尋產品",
    "Products.Empty.Button.How": "如何尋找產品",
    "Products.Alert.Removed": "產品已移除",

    "Products.Search.Label": "搜尋您的產品",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "搜尋",

    "ProductListItem.Inactive.Tooltip": "該供應商已不再提供此產品",
    "ProductListItem.Inactive.Title": "非使用中",
    "ProductListItem.OutOfStock.Tooltip": "此產品的所有款式皆已售罄",
    "ProductListItem.OutOfStock.Title": "缺貨",
    "ProductListItem.InventoryCount.Singular": "件",
    "ProductListItem.InventoryCount.Plural": "件",
    "ProductListItem.InventoryCount.End.Singular": "剩下",
    "ProductListItem.InventoryCount.End.Plural": "剩下",
    "ProductListItem.Button.View": "在商店查看",
    "ProductListItem.Button.Remove": "從商店移除",

    "ImportList.Title.Head": "導入清單 - Spocket",
    "ImportList.Title.Header": "我的導入清單",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "正在載入您的導入清單！",
    "ImportList.Loading.Bottom": "請稍候，我們正在擷取您的產品。",
    "ImportList.Empty.Button.Search": "搜尋產品",
    "ImportList.Empty.Top": "您的導入清單是空的！",
    "ImportList.Empty.Bottom": "前往搜尋頁面，開始將產品新增至您的導入清單。",
    "ImportList.Empty.Button.How": "如何自訂產品",
    "ImportList.Alert.Pushed": "此產品已推送至您的商店：",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Search.Label": "透過導入的產品進行搜尋",
    "ImportList.Search.Placeholder": "搜尋您的產品",
    "ImportList.Search.Button": "搜尋",
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "推送當前頁面產品",
    "PushAllModal.Button.Inactive": "推送當前頁面",
    "PushAllModal.Modal.Text":
      "您是否確定要從此當前頁面推送所有產品？所有在當前頁面的有效產品將會推送至您的商店。",
    "PushAllModal.Modal.Push": "推送全部",
    "PushAllModal.Modal.Cancel": "取消",

    "RemoveAllModal.Button.Active": "移除當前頁面產品",
    "RemoveAllModal.Button.Inactive": "移除當前頁面",
    "RemoveAllModal.Modal.Text":
      "您是否確定要從當前頁面移除所有產品？您當前頁面上的所有產品將從導入清單中移除。",
    "RemoveAllModal.Modal.Remove": "移除全部",
    "RemoveAllModal.Modal.Cancel": "取消",

    "ImportListItem.Tab.Product": "產品",
    "ImportListItem.Tab.Description": "說明",
    "ImportListItem.Tab.Variants": "款式",
    "ImportListItem.Tab.Images": "影像",
    "ImportListItem.Remove.Active": "移除產品",
    "ImportListItem.Remove.Inactive": "移除產品",
    "ImportListItem.Push.Active": "推送至商店",
    "ImportListItem.Push.Inactive": "推送至商店",
    "ImportListItem.Save.Button": "儲存",
    "ImportListItem.Alert.Removed": "此產品已經從您的導入清單中移除：",
    "ImportListItem.Alert.RemoveFailed": "嘗試移除時發生錯誤：",
    "ImportListItem.Alert.CompareAtPrice": "比較價格應該高於以下銷售價格：",
    "ImportListItem.Alert.OneActiveVariation": "此產品需要至少一項使用中的款式：",
    "ImportListItem.Alert.Saved": "已經儲存此產品：",
    "ImportListItem.Alert.ErrorPushing": "在推送至您的商店時發生錯誤",
    "ImportListItem.Alert.ErrorSaving": "儲存您的商店時發生錯誤",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "連線您的商店以推送產品：",
    "ImportListItem.Tooltip.OneActiveVariation": "您需要至少一項使用中的款式",
    "ImportListItem.Tooltip.Unavailable": "目前無法購買此產品",
    "ImportListItem.Tooltip.Unsaved": "您有尚未儲存的變更",
    "ImportListItem.Tooltip.Profit": "警告：此產品的毛利為負值",
    "ImportListItem.Tooltip.Unpurchasable": "您可以儲存此產品，但目前無法購買",
    "ImportListItem.Variants.ShowShippingPrices": "顯示運送價格",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "產品名稱",
    "ItemInfo.ProductTags.Label": "產品標籤",
    "ItemInfo.ProductTags.Remove": "移除全部標籤",
    "ItemInfo.ProductTags.Placeholder": "新增新的標籤",
    "ItemInfo.ProductType.Label": "產品類型",
    "ItemInfo.Collection.Label": "系列",
    "ItemInfo.Collection.Placeholder": "選取",
    "ItemInfo.Collection.Option": "選取",
    "ItemInfo.SquareLocation.Option": "選取",

    "ItemVariantList.Modal.Title": "運送詳細資訊",
    "ItemVariantList.Modal.Intro": "託運人：",
    "ItemVariantList.Modal.DoesNotShipInternational": "無法運送此品項到國外。",
    "ItemVariantList.Modal.DoesNotShipTo": "無法運送至以下地點：",
    "ItemVariantList.Table.Head.Destination": "目的地",
    "ItemVariantList.Table.Head.Cost": "費用",
    "ItemVariantList.Table.Head.Time": "運送時間（天數）",
    "ItemVariantList.Table.Body.Domestic": "國內",
    "ItemVariantList.Table.Body.International": "國際",
    "ItemVariantList.VariantsTable.ShippingPrice": "運送價格 ",
    "ItemVariantList.VariantsTable.Image": "影像",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "庫存",
    "ItemVariantList.VariantsTable.Price": "價格",
    "ItemVariantList.VariantsTable.SalesPrice": "銷售價格 ",
    "ItemVariantList.VariantsTable.Profit": "利潤",
    "ItemVariantList.VariantsTable.CompareAtPrice": "比較價格",
    "ItemVariantList.VariantsTable.Unavailable": "目前無法購買此產品",
    "ItemVariantList.VariantsTable.Head": "此產品的所有款式皆顯示於此處。",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "例如：40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify最多支援100種款式。",

    "ItemVariantList.ContextPopover.Title": "設定利潤",
    "ItemVariantList.ContextPopover.Apply": "套用",

    "ProductVariation.Description": "您可以直接在Spocket訂購樣品。請依循以下步驟，以便快速輕鬆地結帳。",
    "ProductVariation.Label.SelectVariant": "選取款式",
    "ProductVariation.Label.SelectTheProductVariation": "選取產品款式",
    "ProductVariation.Qty": "數量",
    "ProductVariation.Label.NotesForTheSupplier": "供應商注意事項",

    "InputAddress.Label.FirstName": "名字",
    "InputAddress.PlaceHolder.FirstName": "輸入名字",
    "InputAddress.Label.LastName": "姓氏",
    "InputAddress.PlaceHolder.LastName": "輸入姓氏",
    "InputAddress.Label.StreetAddress": "街道地址",
    "InputAddress.Label.Country": "國家",
    "InputAddress.PlaceHolder.Country": "搜尋國家",
    "InputAddress.Label.State": "州/省",
    "InputAddress.Placeholder.State": "搜尋州/省",
    "InputAddress.Label.City": "城市",
    "InputAddress.PlaceHolder.City": "輸入城市",
    "InputAddress.Label.Zip": "郵遞區號",
    "InputAddress.PlaceHolder.Zip": "輸入郵遞區號",
    "InputAddress.Label.Phone": "電話號碼",
    "InputAddress.Placeholder.Phone": "輸入電話號碼",

    "OrderReview.OrderDetails": "其他詳細資訊",
    "OrderReview.Unit": "{{count}}單位",
    "OrderReview.Unit_plural": "{{count}}個單位",
    "OrderReview.ShippingAddress": "運送地址",
    "OrderReview.PaymentMethod": "付款方式",
    "OrderReview.Amounts": "金額",
    "OrderReview.Subtotal": "小計",
    "OrderReview.ShippingCost": "運送費用",
    "OrderReview.TransactionFees": "交易費用",
    "OrderReview.TransactionFees.ToolTips":
      "交易費用包含Spocket的支付服務供應商Stripe收取的費用，以及服務費用。",
    "OrderReview.OrderTotal": "訂單總計",
    "OrderReview.ItemWithCount": "{{count}}個品項",
    "OrderReview.ItemWithCount_plural": "{{count}}個品項",
    "OrderReview.Placeholder.CreditCard": "信用卡尾號為{{last4}}",

    "PaymentComplete.PaymentCompleted": "付款完成",
    "PaymentComplete.PaymentCompleted.Note":
      "感謝您的付款。供應商已收到通知，並將立即處理訂單。我們將更新訂單狀態，並在您的訂單頁面新增追蹤號碼。",
    "PaymentComplete.PaymentIncomplete": "您的訂單不完整！",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note": "您的銀行需要您確認付款才能繼續",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "請使用此鏈接確認付款。",
    "PaymentComplete.PaymentIncomplete.Note":
      "確認付款後，供應商會收到通知，您的訂單將正常處理。狀態和追蹤更新將會新增至您的訂單頁面。請在確認後重新載入頁面。",

    "CreditCard.AddCreditCard.Description":
      "您尚未新增任何的信用卡。請從下方的按鈕新增信用卡，以允許我們自動處理您的訂單",
    "CreditCard.Button.AddCreditCard": "新增信用卡",
    "CreditCard.Button.UpdateCard": "更新銀行卡",
    "CreditCard.UpdateCard.Label.CreditCard": "信用卡",
    "CreditCard.PlaceHolder.CreditCard": "信用卡尾號為{{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "信用卡",
    "UpdateCreditCardModal.Button.UpdateCard": "更新銀行卡",
    "UpdateCreditCardModal.Button.AddCreditCard": "新增信用卡",
    "UpdateCreditCardModal.Button.Cancel": "取消",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "更新您的銀行卡詳細資訊",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One": "您的帳戶將會在Spocket產品訂單處理期間使用。",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "條款與條件",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "以處理付款。",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "選取款式",
    "SampleOrderModal.Title.ShippingAddress": "運送地址",
    "SampleOrderModal.Title.PaymentMethod": "付款方式",
    "SampleOrderModal.Title.Review": "檢閱",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "需要付款確認！",
    "SampleOrderModal.Title.OrderPlaced": "已經下單！",
    "SampleOrderModal.Steps": "步驟{{current}}，共{{total}}步驟",
    "SampleOrderModal.FinalStep": "最後步驟",
    "SampleOrderModal.ConfirmPayment": "確認付款！",
    "SampleOrderModal.Finished": "已完成",
    "SampleOrderModal.Footer.Continue": "繼續",
    "SampleOrderModal.Footer.PlaceOrder": "下單",
    "SampleOrderModal.Footer.Close": "關閉",
    "SampleOrderModal.Footer.Cancel": "取消",
    "SampleOrderModal.Footer.GoBack": "回到上一頁",

    "AdvancedFiltersModal.Title.Filters": "篩選",
    "AdvancedFiltersModal.Title.Shipping": "運送",
    "AdvancedFiltersModal.Label.ShipsFrom": "託運人",
    "AdvancedFiltersModal.Label.ShipsTo": "收貨人",
    "AdvancedFiltersModal.Label.ShippingTime": "運送時間",
    "AdvancedFiltersModal.Label.Popular": "熱門",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "美國",
    "AdvancedFiltersModal.Label.Country.Europe": "歐洲",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "僅提供運送至美國的服務",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "任何天數",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3天",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7天",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14天",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15天以上",
    "AdvancedFiltersModal.Title.ItemsCost": "品項費用",
    "AdvancedFiltersModal.Label.ItemCost": "品項費用",
    "AdvancedFiltersModal.Label.ShippingCost": "運送費用",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "任何費用",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "免費",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "$5及以下",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "$15及以下",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "$25及以下",
    "AdvancedFiltersModal.Title.Supplier": "供應商",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "供應商",
    "AdvancedFiltersModal.Label.TopSupplier": "熱門供應商",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "供應商以其高品質產品、絕佳服務以及高評等的評論而聞名",
    "AdvancedFiltersModal.Title.Advanced": "進階",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "優質產品",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription": "高折扣與快速運送的產品",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "最佳賣家",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Spocket上表現最佳產品",
    "AdvancedFiltersModal.Button.Cancel": "取消",
    "AdvancedFiltersModal.Button.ViewResults": "查看結果",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "搜尋國家…",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "國家",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "搜尋供應商…",
    "AdvancedFiltersModal.Label.All Suppliers": "所有供應商",
    "AdvancedFiltersModal.Label.New Suppliers": "新供應商",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "任何天數",
    "AdvancedFilters.ShippingTime.One": "1-3天",
    "AdvancedFilters.ShippingTime.Four": "4-7天",
    "AdvancedFilters.ShippingTime.Eight": "8-14天",
    "AdvancedFilters.ShippingTime.Fifteen": "15天以上",

    "AdvancedFilters.ShippingCost.Free": "免費運送",
    "AdvancedFilters.ShippingCost.Five": "運費低於$5",
    "AdvancedFilters.ShippingCost.Fifteen": "運費低於$15",
    "AdvancedFilters.ShippingCost.TwentyFive": "運費低於$25",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "清除全部",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "運送",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "搜尋",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "託運人",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "收貨人",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "供應商",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "排序方式",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "品項費用",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "熱門供應商",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "個人化發票",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "最佳賣家",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "優質",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "從{{from}}至{{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "輸入關鍵字…",
    "Search.Header.Button.Search": "搜尋",
    "Search.Header.Button.Filters": "篩選",

    "Navigation.UpgradeButton.TryPro": "免費試用Spocket Pro",
    "Navigation.UpgradeButton.TryEmpire": "免費試用Spocket EMPIRE",
    "Navigation.UpgradeButton.TryUnicorn": "免費試用Spocket Unicorn",

    "CategoriesCards.Label.MoreCategories": "更多類別",

    "StaticCategories.WomensClothing": "女士衣著",
    "StaticCategories.WomensClothing.Activewear": "運動服飾",
    "StaticCategories.WomensClothing.Bodysuits": "連身衣",
    "StaticCategories.WomensClothing.Dresses": "洋裝",
    "StaticCategories.WomensClothing.JacketsCoats": "夾克與外套",
    "StaticCategories.WomensClothing.Jeans": "牛仔褲",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "連身裙與連身褲",
    "StaticCategories.WomensClothing.Leggings": "緊身褲",
    "StaticCategories.WomensClothing.LingerieUnderwear": "內衣與內褲",
    "StaticCategories.WomensClothing.MatchingSets": "套裝",
    "StaticCategories.WomensClothing.Pants": "長褲",
    "StaticCategories.WomensClothing.Shorts": "短褲",
    "StaticCategories.WomensClothing.Skirts": "裙裝",
    "StaticCategories.WomensClothing.Sleepwear": "睡衣",
    "StaticCategories.WomensClothing.Socks": "襪子",
    "StaticCategories.WomensClothing.SweatersHoodies": "毛衣與連帽衫",
    "StaticCategories.WomensClothing.Swimwear": "泳衣",
    "StaticCategories.WomensClothing.TankTops": "坦克背心",
    "StaticCategories.WomensClothing.TopsBlouses": "上衣與襯衫",
    "StaticCategories.WomensClothing.Tshirts": "T恤",

    "StaticCategories.JewelryWatches": "珠寶與手錶",
    "StaticCategories.JewelryWatches.Bracelets": "手鍊",
    "StaticCategories.JewelryWatches.Earrings": "耳環",
    "StaticCategories.JewelryWatches.Necklaces": "項鍊",
    "StaticCategories.JewelryWatches.OtherAccessories": "其他飾品",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "掛飾、寶石與小綴飾",
    "StaticCategories.JewelryWatches.Rings": "戒指",
    "StaticCategories.JewelryWatches.Sets": "套組",
    "StaticCategories.JewelryWatches.Watches": "手錶",

    "StaticCategories.TechAccessories": "科技配件",
    "StaticCategories.TechAccessories.AudioVideo": "影音商品",
    "StaticCategories.TechAccessories.CasesCovers": "保護套與保護殼",
    "StaticCategories.TechAccessories.Lighting": "照明",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "手機與筆記型電腦配件",
    "StaticCategories.TechAccessories.Mousepads": "滑鼠墊",
    "StaticCategories.TechAccessories.Novelty": "創意商品",

    "StaticCategories.KidsBabies": "兒童與嬰兒",
    "StaticCategories.KidsBabies.BabyClothing": "嬰兒衣著",
    "StaticCategories.KidsBabies.Bathing": "沐浴",
    "StaticCategories.KidsBabies.BlanketsPillows": "毯子與枕頭",
    "StaticCategories.KidsBabies.CapsHeadbands": "帽子與頭巾",
    "StaticCategories.KidsBabies.Footwear": "鞋類",
    "StaticCategories.KidsBabies.Furniture": "家具",
    "StaticCategories.KidsBabies.KidsClothing": "兒童衣著",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "育兒與配件",

    "StaticCategories.Toys": "玩具",
    "StaticCategories.Footwear": "鞋類",
    "StaticCategories.Footwear.Boots": "靴子",
    "StaticCategories.Footwear.Flats": "平底鞋",
    "StaticCategories.Footwear.Heels": "高跟鞋",
    "StaticCategories.Footwear.Mens": "男士",
    "StaticCategories.Footwear.Sandals": "涼鞋",
    "StaticCategories.Footwear.Slippers": "拖鞋",
    "StaticCategories.Footwear.SneakersRunners": "運動鞋與跑步鞋",

    "StaticCategories.BathBeauty": "沐浴與美妝",
    "StaticCategories.BathBeauty.Bodycare": "身體保養",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "擴香、精油與蠟燭",
    "StaticCategories.BathBeauty.Haircare": "護髮",
    "StaticCategories.BathBeauty.Healthcare": "保健",
    "StaticCategories.BathBeauty.Makeup": "化妝品",
    "StaticCategories.BathBeauty.Nails": "指甲",
    "StaticCategories.BathBeauty.Skincare": "皮膚保養",
    "StaticCategories.BathBeauty.TowelsRobes": "毛巾與長袍",

    "StaticCategories.Pets": "寵物",
    "StaticCategories.Pets.BedsBlankets": "寢具與毯子",
    "StaticCategories.Pets.LeashesCollarsPetwear": "牽繩、項圈與寵物服飾",
    "StaticCategories.Pets.Petcare": "寵物照護",
    "StaticCategories.Pets.Toys": "玩具",

    "StaticCategories.HomeGarden": "家居與庭園",
    "StaticCategories.HomeGarden.Bathroom": "浴廁",
    "StaticCategories.HomeGarden.Clocks": "時鐘",
    "StaticCategories.HomeGarden.Drinkware": "飲具",
    "StaticCategories.HomeGarden.FoodBeverage": "食物與飲料",
    "StaticCategories.HomeGarden.Furniture": "家具",
    "StaticCategories.HomeGarden.Garden": "庭園",
    "StaticCategories.HomeGarden.HomeDecor": "居家裝飾",
    "StaticCategories.HomeGarden.HomeImprovement": "居家修繕",
    "StaticCategories.HomeGarden.Kitchen": "廚房",
    "StaticCategories.HomeGarden.Lighting": "照明",
    "StaticCategories.HomeGarden.Organization": "收納",
    "StaticCategories.HomeGarden.StationeryCrafts": "文具與工藝品",
    "StaticCategories.HomeGarden.TextilesPillows": "織品與枕頭",
    "StaticCategories.HomeGarden.Tools": "工具",

    "StaticCategories.Accessories": "配件",
    "StaticCategories.Accessories.Belts": "皮帶",
    "StaticCategories.Accessories.Cufflinks": "袖扣",
    "StaticCategories.Accessories.Hats": "帽子",
    "StaticCategories.Accessories.Keychains": "鑰匙圈",
    "StaticCategories.Accessories.ScarvesBandanas": "圍巾與頭巾",
    "StaticCategories.Accessories.Sunglasses": "太陽眼鏡",

    "StaticCategories.MensClothing": "男士衣著",
    "StaticCategories.MensClothing.Activewear": "運動服飾",
    "StaticCategories.MensClothing.DressShirts": "正裝襯衫",
    "StaticCategories.MensClothing.JacketsCoats": "夾克與外套",
    "StaticCategories.MensClothing.Pants": "長褲",
    "StaticCategories.MensClothing.Shorts": "短褲",
    "StaticCategories.MensClothing.Socks": "襪子",
    "StaticCategories.MensClothing.SweatersHoodies": "毛衣與連帽衫",
    "StaticCategories.MensClothing.Swimwear": "泳衣",
    "StaticCategories.MensClothing.TankTops": "坦克背心",
    "StaticCategories.MensClothing.Tshirts": "T恤",
    "StaticCategories.MensClothing.Underwear": "內衣褲",

    "StaticCategories.SportsOutdoors": "運動與戶外",
    "StaticCategories.SportsOutdoors.Activewear": "運動服飾",
    "StaticCategories.SportsOutdoors.Biking": "單車",
    "StaticCategories.SportsOutdoors.Camping": "露營",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "裝備與配件",
    "StaticCategories.SportsOutdoors.Fishing": "釣魚",

    "StaticCategories.Gifts": "禮品",
    "StaticCategories.Gifts.Adults": "成人用品",
    "StaticCategories.Gifts.Funny": "趣味",
    "StaticCategories.Gifts.Mugs": "馬克杯",
    "StaticCategories.Gifts.Novelty": "創意商品",
    "StaticCategories.Gifts.Personalized": "個人化",
    "StaticCategories.Gifts.Religious": "宗教",

    "StaticCategories.Automotive": "自動化",
    "StaticCategories.BagsWallets": "包款與錢包",
    "StaticCategories.Seasonal": "季節性",
    "StaticCategories.Seasonal.Christmas": "聖誕節",
    "StaticCategories.Seasonal.FathersDay": "父親節",
    "StaticCategories.Seasonal.Halloween": "萬聖節",
    "StaticCategories.Seasonal.MothersDay": "母親節",

    "StaticCategories.FestivalsParties": "節日與派對",
    "StaticCategories.FestivalsParties.PartySupplies": "派對用品",
    "StaticCategories.FestivalsParties.Wedding": "婚禮",

    "QuickFilters.Label.Premium": "優質",
    "QuickFilters.Label.FastUSAShipping": "快速美國運送",
    "QuickFilters.Label.Under5": "美國運費低於$5",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "排序方式",
    "SortBy.DropDown.Item.Relevance": "關聯性",
    "SortBy.DropDown.Item.PriceLowToHigh": "價格：從低到高",
    "SortBy.DropDown.Item.PriceHighToLow": "價格：從高到低",
    "SortBy.DropDown.Item.Newest": "最新",
    "SortBy.DropDown.Item.ShippingTime": "運送時間",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "首頁",
    "Breadcrumb.Item.Search": "搜尋",

    "UpgradeModal.Modal.Title": "選取定價方案",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "每月",
    "Plans.ToggleSection.Yearly": "每年",
    "Plans.ToggleSection.DiscountInitial": "獲得高達",
    "Plans.ToggleSection.DiscountSingular": "月",
    "Plans.ToggleSection.DiscountPlural": "月",
    "Plans.ToggleSection.DiscountFinal": "關閉",
    "Plans.ProrationCost.TextInitial": "您目前正在支付",
    "Plans.ProrationCost.TextFinal": "/年。升級到Empire只需一個額外的",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "開始銷售",
    "Card.DescriptionVariantType.Professional": "促進銷售",
    "Card.DescriptionVariantType.Empire": "最大化銷售",
    "Card.DescriptionVariantType.Unicorn": "管理大規模銷售",
    "Card.DescriptionVariantType.Period": "月",
    "Card.DescriptionVariantType.PricePeriod": "/月",
    // eslint-disable-next-line
    "Card.DescriptionVariantType.AnnualDescription": "每年以${{value}}計費",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "您的目前方案",
    "Card.DescriptionVariantType.Button.Start": "立即開始",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "品牌發票",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "暢銷產品",
    "Card.DescriptionVariantType.SupplierSourcing": "尋找供應商",
    "Card.DescriptionVariantType.ProductRequests": "產品請求",
    "Card.DescriptionVariantType.BulkCheckout": "大量結帳",
    "Card.DescriptionVariantType.Special": "特殊",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "您的Spocket訂購已結束。",
    "ReactivationBanner.InfoSubtitle": "重新啟動您的",
    "ReactivationBanner.CheckList.HighQuality": "高品質",
    "ReactivationBanner.CheckList.Curated": "已規劃",
    "ReactivationBanner.CheckList.FastShipping": "快速運送",
    "ReactivationBanner.CheckList.FromUSAndEU": "從美國和歐盟",
    "ReactivationBanner.TextByPlan.Starter.Description": "您將無法再<1>推送任何產品</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket帳戶",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "立即開始將產品推送到您的商店",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne": "您無法即時或使用<1>推送產品</1> ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "優質產品",
    "ReactivationBanner.TextByPlan.Others.Title": "優質帳戶",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "存取優質產品",
    "ReactivationBanner.Button": "立即重新啟動我的帳戶",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "更多產品推送",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "無限訂單",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "品牌發票",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "優質產品",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "獨家交易",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "聊天與電話支援",
    "UpgradeReasonModal.Modal.Title": "您想通過Spocket實現什麼？",
    "UpgradeReasonModal.Modal.Question": "是什麼讓您升級？",
    "UpgradeReasonModal.Modal.Button": "開始使用Spocket{{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "這能夠幫助我們改善您的體驗",

    "UpgradeSuccessModal.Col.One.A": "恭喜。",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A": "您已經解鎖了需要用來建立成功直送業務的所有工具。",
    "UpgradeSuccessModal.Col.Two.B": "您現在可以擁有 ",
    "UpgradeSuccessModal.Col.Two.C": " 快樂使用Spoket！",
    "UpgradeSuccessModal.Button": "繼續",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "１個月",
    "Promotion.TrialDaysNormalizer.28days": "4週",
    "Promotion.TrialDaysNormalizer.21days": "3週",
    "Promotion.TrialDaysNormalizer.14days": "2週",
    "Promotion.TrialDaysNormalizer.7days": "1週",
    "Promotion.TrialDaysNormalizer.1day": "1天",
    "Promotion.TrialDaysNormalizer.Days": "{{value}}天",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "免費試用",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "立即試用→",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "語言",

    "WhatYouWillLoseModal.Button.Offer": "繼續下去",
    "WhatYouWillLoseModal.Button.Downgrade": "我放棄了，請將我的帳戶降級",
    "WhatYouWillLoseModal.ProductSection.Title": "您將會失去所有<1>{{product_count}}項導入產品</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "優質",
    "WhatYouWillLoseModal.Product.SkeletonImage": "再加{{product_count}}項",
    "WhatYouWillLoseModal.ModalWrapper.Header": "您已經放棄了嗎？",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "獲取5折的折扣",
    "WhatYouWillLoseModal.ModalWrapper.Title": "在未來3個月繼續您的創業。",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "我們希望您成為另一個成功故事。我們知道您已經投入了大量時間。因此，繼續下去。我們都會支援您。Spocket團隊",

    "FiftyOff3MonthsOfferSuccessModal.Header": "恭喜，我們已經將未來3個月的5折優惠新增至您的方案！",
    "FiftyOff3MonthsOfferSuccessModal.Button": "繼續",

    "DowngradeReasonModal.DowngradeSection": "我們要如何讓Spocket為您做得更多？",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "我們希望能得到您對於每一件事情的回饋意見（最少100個字元）",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "請輸入您的回饋意見（最少100個字元）",
    "DowngradeReasonModal.Button.Downgrade": "降級",
    "DowngradeReasonModal.ModalWrapper.Header": "是否要降級{{storeName}}？",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "聯絡我們",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "取消",

    "DowngradeSuccessModal.Header": "降級成功",
    "DowngradeSuccessModal.Body":
      "感謝您的回饋意見。我們每天都在不斷地改善Spocket，希望您能盡快再次給予我們一個機會！",

    "Settings.title": "設定",
    "Settings.buttonSaveChanges": "儲存變更",
    "Settings.buttonSaveAndPreview": "儲存與預覽",
    "Settings.PricingPlans.Title": "定價方案",

    "Settings.MenuTab.Plans": "方案",
    "Settings.MenuTab.Account": "帳戶",
    "Settings.MenuTab.BrandedInvoicing": "品牌發票",
    "Settings.MenuTab.Billing": "計費",
    "Settings.MenuTab.GlobalPricingRules": "全球定價規則",
    "Settings.MenuTab.Security": "安全性",

    "AccountTab.documentTitle": "設定 - 帳戶 - Spocket",
    "AccountTab.title": "帳戶設定",
    "AccountTab.shopName": "商店名稱",
    "AccountTab.email": "電子郵件",
    "AccountTab.shopUrl": "商店網址",
    "AccountTab.defaultCurrency": "預設貨幣",
    "AccountTab.yourPlan": "您的方案",
    "AccountTab.accountNotActive": "您的帳戶目前無效",
    "AccountTab.alertFeatureWillBeAddedSoon": "即將新增此功能！現在，請透過Spocket Shopify應用程式連線！",
    "AccountTab.alertAccountSettingsUpdated": "帳戶設定已更新",
    "AccountTab.alertInvalidShopUrl": "無效的商店網址",
    "AccountTab.productsImported": "產品已導入",
    "AccountTab.products": "產品",
    "AccountTab.premiumProducts": "優質產品",
    "AccountTab.total": "總計",
    "AccountTab.signOut": "登出",
    "AccountTab.limitReachedProductsAndPremium": "已達到產品與優質產品限制。",
    "AccountTab.limitReachedProducts": "已達到產品限制。",
    "AccountTab.limitReachedPremium": "已達到優質產品限制。",
    "AccountTab.buttonSwitchToAnnualPlan": "切換為年度方案",
    "AccountTab.off30Percent": "7折",
    "AccountTab.off40Percent": "6折",
    "AccountTab.off45Percent": "55折",
    "AccountTab.sellMoreWithEmpirePlan": "搭配Empire方案，出售更多商品",
    "AccountTab.tooltipCurrency":
      "您的貨幣是透過您的商店帳戶管理。您導入產品的所有價格將會以該貨幣進行轉換。",
    "AccountTab.shopNotConnectedYet": "您的商店尚未連線至此帳戶。",
    "AccountTab.connectYourShop": "連線您的商店",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "設定 - 發票 - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "已儲存發票設定！",
    "InvoiceTab.shopLogo": "商店商標",
    "InvoiceTab.contactEmail": "聯絡人電子郵件",
    "InvoiceTab.phoneNumber": "電話號碼",
    "InvoiceTab.personalNote": "個人備註",
    "InvoiceTab.tooltipPersonalNote": "設定個人備註，並在每一張品牌發票中加入此個人備註",
    "InvoiceTab.brandedInvoicing": "品牌發票",
    "InvoiceTab.tooltipBrandedInvoicing":
      "從使用付費方案提供品牌發票的供應商，將您的品牌商標與個人備註加入所有訂單",
    "InvoiceTab.disabledInvoicing": "在所有訂單上包含品牌發票",
    "InvoiceTab.tooltipDisabledInvoicing": "關閉此選項將會在您下單時排除品牌發票",

    "PricingTab.titleDefaultPricingRules": "預設定價規則",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "進階定價規則",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "指定分值",
    "PricingTab.tooltipAssignCents":
      "此數值將用於顯示品項的最後價格（例如，假設您希望產品的成本為xx.99，則在下方的欄位填入99）。",
    "PricingTab.Markup": "加成",
    "PricingTab.MarkupType": "加成類型",
    "PricingTab.SelectDefault": "- 選取一項 --",
    "PricingTab.SelectPercent": "百分比",
    "PricingTab.SelectMultiplier": "乘數",
    "PricingTab.SelectFixed": "固定",
    "PricingTab.titlePricingRules": "設定 - 定價規則 - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "切換進階定價規則",
    "PricingTab.from": "從",
    "PricingTab.to": "至",
    "PricingTab.selectFixedAmount": "固定金額",
    "PricingTab.buttonRemove": "移除",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields": "您無法使用空白欄位建立多項規則",
    "PricingTab.costRange": "成本範圍",
    "PricingTab.markup": "加成",
    "PricingTab.add": "新增",

    "SecurityTab.Document.Title": "設定 - 安全性 - Spocket",
    "SecurityTab.alertIncorrectPassword": "不正確的密碼",
    "SecurityTab.title": "安全性",
    "SecurityTab.yourNewPassword": "您的新密碼",
    "SecurityTab.repeatPassword": "確認密碼",
    "SecurityTab.errorPleaseEnterAPassword": "請輸入密碼。",
    "SecurityTab.errorPasswordsConfirmationNeeded": "請輸入密碼確認。",
    "SecurityTab.errorPasswordsDoNotMatch": "密碼不相符",
    "SecurityTab.errorPasswordShort": "密碼長度必須為8個或8個以上字元",

    "ListingCards.TopSupplierTag.Tooltip": "熱門供應商以其高品質產品、絕佳服務以及高評等而聞名",
    "ListingCards.TopSupplierTag.Text": "供應商",

    "ListingCard.OriginCountry.Intro": "由",
    "ListingCard.OriginCountry.Tooltip": "託運人",
    "ListingCard.Shipping.Price.Title": "運送價格",
    "ListingCard.Shipping.Price.Free": "免費",
    "ListingCard.Price.Intro": "刊登費用",
    "ListingCard.Price.Retail": "零售價格",
    "ListingCard.Shipping.Time.Title": "運送時間",
    "ListingCard.Shipping.Time.Period": "天",
    "ListingCard.PremiumIcon.Tooltip": "在您的商店銷售獨家、高折扣與快速出貨的產品",
    "ListingCard.PremiumIcon.Text": "優質",
    "ListingCard.ListButton.Import": "新增至導入清單",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "已推送至商店",
    "ListingCard.StatusTag.Imported.Title": "已導入",
    "ListingCard.StatusTag.Imported.Text": "此品項已經在您的導入清單中",
    "ListingCard.StatusTag.Pushed.Title": "現貨",
    "ListingCard.StatusTag.Pusehd.Text": "已將此品項推送至您的商店",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "產品說明",
    "ListingModal.DetailsSection.Button.ProductVariations": "開啟產品款式",
    "ListingModal.DetailsSection.Button.OrderSamples": "訂購樣品",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "新的",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert": "品牌發票不適用於此產品",
    "ListingModal.DetailsSection.InternationalShipping.Alert": "國際訂單將從我們在中國的倉庫出貨",
    "ListingModal.DetailsSection.StockUsa.Alert": "在美國倉庫中缺貨的品項，可能會從我們的在中國的倉庫寄送",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "訂單可能會以多個包裹寄送，以助於減少訂單的整體運送時間",
    "ListingModal.DetailsSection.ConnectStore.Alert": "連線至您的商店來下訂單",
    "ListingModal.DetailsSection.PushedListButton": "已推送至商店",
    "ListingModal.DetailsSection.RemoveListButton": "從清單移除",
    "ListingModal.DetailsSection.ImportListButton": "新增至導入清單",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "清單已停用",

    "InfoSection.ShippingTime.Tooltip": "出貨後運送產品的天數。",
    "InfoSection.ShippingTime.Title": "運送時間",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "全球",
    "InfoSection.ShippingExcluded.Intro": "無法運送至",
    "InfoSection.ShippingExcluded.Various": "各種",
    "InfoSection.ProcessingTime.Tooltip": "供應商運送的工作天數與提供追蹤號碼",
    "InfoSection.ProcessingTime.Title": "處理時間",
    "InfoSection.ProcessingTime.Period": "工作天數",
    "InfoSection.ShippingInfoLine.Tooltip":
      "此產品的運費為{{price_formatted}}，在相同訂單中，每增加一項額外產品，則加{{incremental_price_formatted}}",
    "InfoSection.ShippingInfoLine.Period": "工作天數",
    "InfoSection.ShippingInfoLine.Free": "免費",
    "InfoSection.ShippingInfoLine.NoShipping": "無法運送",

    "ReturnPolicySection.Title": "退貨政策",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "從",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "由",
    "TitleSection.PriceMSRP.Listing": "建議售價",
    "TitleSection.PriceMSRP.Retail": "零售價格",

    "ProductVariationsModal.Title": "產品款式",
    "ProductVariationsModal.Table.Image": "影像",
    "ProductVariationsModal.Table.Inventory": "庫存",
    "ProductVariationsModal.Table.Price": "價格",
    "ProductVariationsModal.ImageSpecifications.Title": "尺寸表",
    "ProductVariationsModal.ImageSpecifications.Button": "下載",

    "TopSupplier.Tooltip": "熱門供應商以其高品質產品、絕佳服務以及高評等而聞名",
    "TopSupplier.Text": "熱門供應商",

    "BestSelling.Tooltip.Top": "這是在Spocket的最佳表現產品",
    "BestSelling.Tooltip.Bottom": "最佳賣家",

    "GallerySection.PremiumIcon.Tooltip": "在您的商店銷售獨家、高折扣與快速出貨的產品",
    "GallerySection.PremiumIcon.Text": "優質",
    "GallerySection.DiscountStamp.Text": "額外的{{value}}%優惠",

    "Upgrade.Title.AnnualToggled": "立即取得Spocket Premium",
    "Upgrade.Title.MonthlyToggled": "免費試用Spocket Premium 14天",
    "Upgrade.Subtitle": "立即試用Spocket Premium方案，並開始從美國和歐洲將熱銷產品新增到您的商店。",
    "Upgrade.Button": "不使用Spocket Premium並繼續",

    "Search.NoProductsFound.Title": "抱歉！我們無法找到任何結果",
    "Search.NoProductsFound.For": "對於",
    "Search.NoProductsFound.Description": "請檢查拼寫、嘗試相關的搜尋詞語或更通用的搜尋詞語。",
    "Search.Searching.Title": "我們正在搜尋產品...",
    "Search.Searching.Description": "請稍候，我們將為您載入最新產品。",

    "Suppliers.StarRating.Tooltip": "供應商訂單履行成功率",
    "Suppliers.RefundPolicy": "供應商的退貨政策",
    "Suppliers.Joined.Title": "已加入Spocket",
    "Suppliers.Origin.Title": "出貨國家",
    "Suppliers.Time.Title": "平均處理時間",
    "Suppliers.HasMore.Title": "我們正在搜尋產品...",
    "Suppliers.HasMore.Subtitle": "請稍候，我們將為您載入最新產品。",
    "Suppliers.TopSupplierTag.Tooltip": "熱門供應商以其高品質產品、絕佳服務以及高評等而聞名",
    "Suppliers.TopSupplierTag.Text": "熱門供應商",

    "AltTextModal.Header.Title": "編輯影像詳細資訊",
    "AltTextModal.Body.Description":
      "請輸入此影像的簡短說明，以改善搜尋引擎最佳化（SEO）並為視力受損客戶改善輔助使用。",
    "AltTextModal.Input.Label": "替代文字",
    "AltTextModal.Input.Placeholder": "影像替代文字",
    "AltTextModal.Button.Cancel": "取消",
    "AltTextModal.Button.Save": "儲存",

    "WistiaModal.WhatIsSpocket.Title": "Spocket是什麼呢？",
    "WistiaModal.WhatIsSpocket.Text":
      "了解Spocket如何協助您建立業務。了解Spocket的運作方式，以及您可以充分利用Spocket的方法。",
    "WistiaModal.WhatIsSpocket.Button": "開始尋找產品",
    "WistiaModal.DiscoverProducts.Title": "探索產品",
    "WistiaModal.DiscoverProducts.Text": "採用合適的篩選條件，找尋正確的產品，並將這些產品新增到您的商店。",
    "WistiaModal.DiscoverProducts.Button": "開始尋找產品",
    "WistiaModal.ProductCustomization.Title": "自訂您的產品",
    "WistiaModal.ProductCustomization.Text":
      "使用導入清單來修改您的產品，以適合您的商店。您可以編輯產品說明、設定價格、將產品新增到產品系列以及移除影像。",
    "WistiaModal.ProductCustomization.Button": "前往導入清單",
    "WistiaModal.OrderProcessing.Title": "自動化訂單處理",
    "WistiaModal.OrderProcessing.Text": "履行您的訂單，讓供應商一鍵將產品直接運送到客戶家門口。",
    "WistiaModal.OrderProcessing.Button": "前往訂單",
    "WistiaModal.Settings.Title": "設定",
    "WistiaModal.Settings.Text":
      "設定頁面可讓您管理所有的帳戶資訊、將品牌放上發票並且新增您的信用卡。您也可以為所有的產品調整定價規則，並選擇訂購方案。",
    "WistiaModal.Settings.Button": "設定您的帳戶",

    "Footer.Copyright": "版權所有 {{year}}，Spocket。保留所有權利",
    "Footer.PrivacyPolicy": "隱私政策",
    "Footer.TermsConditions": "條款與條件",
    "Footer.DataProcessingAddendum": "資料處理增補合約",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
