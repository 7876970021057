export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Meine Bestellliste",
    "OrderList.Input.Search": "Bestellnummer...",
    "OrderList.Label.Status": "Nach Spocket-Status filtern",

    "order.status.unpaid": "Unbezahlt",
    "order.status.paid": "Bezahlt",
    "order.status.processing": "Wird bearbeitet",
    "order.status.shipped": "Versandt",
    "order.status.cancelled": "Abgebrochen",
    "order.status.pending": "Anstehend",
    "order.status.partially_paid": "Teilweise gezahlt",
    "order.status.partially_refunded": "Teilweise erstattet",
    "order.status.refunded": "Erstattet",
    "order.status.voided": "Annulliert",
    "order.status.authorized": "Autorisiert",
    "order.status.deleted": "Gelöscht",
    "order.status.pending_payment_confirmation": "Anstehend",

    "OrderList.Label.DownloadHistory": "Download der gesamten Bestellhistorie CSV",
    "OrderList.Document.Title": "Bestellungen - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Produkte suchen",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "So bearbeitest du Bestellungen",
    "OrderList.EmptyOrders.Message.Loading.Title": "Deine Bestellungen werden geladen!",
    "OrderList.EmptyOrders.Message.Loading.Description":
      "Bitte warte, während wir deine Bestellungen abrufen.",
    "OrderList.EmptyOrders.Message.Title": "Deine Bestellliste ist leer!",
    "OrderList.EmptyOrders.Message.Description":
      "Hier kannst du deine Bestellungen verwalten und bearbeiten. Gehe auf die Suchseite und fange an, Produkte zu deiner Importliste hinzuzufügen.",
    "OrderList.CheckBox.SelectAllOrders": "Alle Bestellungen auswählen",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} Bestellung ausgewählt",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} Bestellung ausgewählt",
    "OrderList.CheckBox.BulkCheckoutOrders": "Massen-Bestellung zur Kasse",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Massen-Bestellungen zur Kasse",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Bestellungen synchronisieren",
    "RefreshModal.Button.Cancel": "Abbrechen",
    "RefreshModal.Button.Synchronize": "Synchronisieren",
    "RefreshModal.Modal.Body":
      "Bestellungen werden automatisch von deinem Shop synchronisiert. Nur synchronisieren, wenn Bestellinformationen fehlen oder nicht aktualisiert wurden. Bitte aktualisiere die Seite nach 30 Sekunden.",

    "Order.Label.OrderNumber": "Bestellnummer",
    "Order.Label.Date": "Datum",
    "Order.Label.OrderStatus": "{{integrationName}} Bestellstatus",
    "Order.Table.Th.Product": "Produkt",
    "Order.Table.Th.Qty": "MENGE",
    "Order.Table.Th.UnitPrice": "Stückpreis",
    "Order.Table.Th.CheckoutDate": "Checkout-Datum",
    "Order.Table.Th.Action": "Aktion",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Kundeninfo ansehen",
    "CustomerModal.Modal.Title.CustomerInfo": "Kundeninfo",
    "CustomerModal.Modal.Body.CustomerInfo": "Kundeninfo",
    "CustomerModal.Modal.Body.Address": "Adresse",

    "OrderLine.Button.Checkout": "Kasse",
    "OrderLine.Tooltip.PreviewInvoice": "Rechnungsvorschau",
    "OrderLine.Tooltip.PaymentReceipt": "Zahlungseingang",
    "OrderLine.Tooltip.TrackOrder": "Bestellung nachverfolgen",
    "OrderLine.Tooltip.OrderProcessing": "Bestellabwicklung",
    "OrderLine.Tooltip.OrderCancelled": "Bestellung storniert",
    "OrderLine.Tooltip.PleaseConfirm": "Bitte klicke, um deine Bestellung zu bestätigen",
    "OrderLine.Label.PurchaseEtsy": "Auf Etsy kaufen",
    "OrderLine.Paying": "Zahlung",
    "OrderLine.Failure": "Fehlgeschlagen",
    "OrderLine.Sample": "Probe",
    "OrderLine.ErrorOrderRow":
      "Kann das bestellte Angebot im System nicht finden! Bitte kontaktiere den Support!",

    "ConfirmationModal.Label.OrderDetails": "Bestelldetails",
    "ConfirmationModal.Label.Total": "Gesamt",
    "ConfirmationModal.Label.Shipping": "Versand",
    "ConfirmationModal.Label.TransactionFees": "Transaktionsgebühren",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Die Transaktionsgebühren beinhalten die von Stripe, dem Zahlungsanbieter von Spocket, erhobenen Gebühren sowie die Servicegebühren.",
    "ConfirmationModal.Modal.OrderConfirmation": "Bestellbestätigung",
    "ConfirmationModal.Modal.Alert.Attention": "Hinweis!",
    "ConfirmationModal.Modal.Alert.Description": "Diese Bestellung enthält eine Notiz des Kunden.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Sie sind dabei, eine Bestellung aufzugeben für",
    "ConfirmationModal.Modal.Table.Th.Name": "Name",
    "ConfirmationModal.Modal.Table.Th.Price": "Preis",
    "ConfirmationModal.Modal.Table.Th.Qty": "MENGE",
    "ConfirmationModal.Modal.Table.Th.Total": "Gesamt",
    "ConfirmationModal.Modal.Label.Note": "Hinweis für den Anbieter",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Deine Nachricht",
    "ConfirmationModal.Button.PlaceOrder": "Bestellung aufgeben",

    "PaymentCompleteModal.PaymentCompleted": "Zahlung abgeschlossen",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Vielen Dank für die Zahlung. Der Anbieter wurde benachrichtigt und wird die Bestellung in Kürze bearbeiten. Wir werden den Status der Bestellung aktualisieren und die Trackingnummer deiner Bestellübersichtsseite hinzufügen.",
    "PaymentCompleteModal.PaymentIncomplete": "Deine Bestellung ist unvollständig!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Deine Bank benötigt eine Bestätigung deiner Zahlung, um fortzufahren",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Bitte benutze diesen Link, um die Zahlung zu bestätigen.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Sobald die Zahlung bestätigt ist, wird der Anbieter benachrichtigt und deine Bestellung wird wie gewohnt bearbeitet. Status- und Tracking-Updates werden deiner Bestellübersichtsseite hinzugefügt. Bitte lade die Seite nach der Bestätigung neu.",

    "SetYourGoalsModal.Title": "Anzahl der dem Store hinzugefügten Produkte",
    "SetYourGoalsModal.Subtitle": "Ein typischer Store pusht 25 Produkte, bevor er Umsatz macht",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} Produkt",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} Produkte",
    "SetYourGoalsModal.Button": "Mein Ziel setzen und Dropshipping starten",

    "YourGoalsBanner.Container.Description": "Deine Ziele",
    "YourGoalsBanner.Header.Title": "Gründe ein Business",
    "YourGoalsBanner.Header.Subtitle": "Erstelle deinen Store",
    "YourGoalsBanner.Progress.Completed": "Abgeschlossen",
    "YourGoalsBanner.ProductPushedCount": "<0>Ziel: {{goalsNumberOfProduct}}</0> Produkte gepusht",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Spocket beitreten",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Erste Suche",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} Produkt gepusht",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produkte gepusht",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} Produkt importiert",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produkte importiert",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professionell",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Automatisiertes AliExpress Dropshipping",
    "Aliscraper.Subtitle":
      "Importiere tausende AliExpress Produkte mit einem Klick in deinen eCommerce Shop und automatisiere dein Dropshipping Business.",
    "Aliscraper.Features.Feature1": "Schneller Import mit nur einem Klick",
    "Aliscraper.Features.Feature2": "Mit Spocket kannst du hunderte von Bestellungen direkt bearbeiten",
    "Aliscraper.Features.Feature3": "Kundenbetreuung rund um die Uhr",
    "Aliscraper.Button": "Lade die Erweiterung herunter",
    "Aliscraper.Chrome": "Verfügbar im Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Verbinde deinen Shop",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Wähle deine Plattform und leg los",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Ungültiger Store-Name",
    "ConnectStoreModal.ShopifyForm.Placeholder": "z. B. MeinStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.ShopifyForm.Button": "Store verbinden",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Dein Authentifizierungsschlüssel:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "So verbindest du deinen Laden mit Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Installiere das Spocket Plugin von",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "hier aus",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Aktiviere das Plugin auf der Seite deiner Plugins",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Zum WordPress-Dashboard > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Füge deinen Authentifizierungsschlüssel (oben aufgelistet) ein und klicke auf „Speichern”",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Erledigt",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.WixForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.WixForm.Button": "Store verbinden",
    "ConnectStoreModal.FelexForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.FelexForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.FelexForm.Button": "Store verbinden",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce Store-URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Ungültiger Store-Name",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "z. B. MeinStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.BigcommerceForm.Button": "Store verbinden",
    "ConnectStoreModal.EcwidForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.EcwidForm.Button": "Store verbinden",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.WhiteLabelForm.Button": "Store verbinden",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.SquarespaceForm.Button": "Store verbinden",
    "ConnectStoreModal.SquareForm.FooterText": "Du hast keinen Store?",
    "ConnectStoreModal.SquareForm.FooterLink": "Kostenlos ausprobieren",
    "ConnectStoreModal.SquareForm.Button": "Store verbinden",
    "ConnectStoreModal.FelexButton.Explanation":
      "Eine Plattform zur Erstellung von Webshops für Unternehmer, um Dropshipping-Shops in weniger als 10 Minuten zu starten.",
    "ConnectStoreModal.DontHaveStore":  "Du hast keinen Store?",
    "ConnectStoreModal.TryForFree": "Kostenlos ausprobieren",

    "PaymentTab.Document.Title": "Einstellungen - Automatisierung - Spocket",
    "PaymentTab.Title.Billing": "Abrechnung",
    "PaymentTab.Tooltip.Billing":
      "Für die Abwicklung von Bestellungen und die Bezahlung von Abonnements werden Kreditkarten verwendet",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Pausiere/Ändere dein Abonnement",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Ändere dein Abonnement",
    "PaymentTab.AccordionItem.Button.Downgrade": "Downgraden",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Abonnement unterbrechen",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Dein Shop wurde schon einmal pausiert",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Abonnement unterbrechen",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Du kannst deinen Spocket-Account vorübergehend pausieren und jederzeit zurückkommen, ohne Daten zu verlieren.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Erinnere mich später",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Erinnere mich später",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 Tage vorher",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Meine Vorteile behalten und erinnere mich <1>{{daysBefore}}</1> an die Erneuerung meiner Mitgliedschaft",
    "RemindMeLaterModal.Header.ReminderSet": "Erinnerung gesetzt",
    "RemindMeLaterModal.Body.DaysBefore": "Eine E-Mail wird dir 3 Tage vorher geschickt",
    "RemindMeLaterModal.Body.ReminderSet":
      "Deine Erinnerung ist gesetzt und wir werden <1>{{daysBefore}}</1> deine Leistungen erneuern. Bis es soweit ist, genieße weiterhin deine unternehmerischen Reise.",
    "RemindMeLaterModal.Button.Continue": "Weiter",
    "PauseStoreModal.Title.PauseYourStore": "Abonnement pausieren",
    "PauseStoreModal.Description.PauseYourStore":
      "Nutze dieses einmalige Angebot und reduziere den Preis deines Abos für die nächsten 30 Tage um 60 %",
    "PauseStoreModal.Button.PauseStore": "Store pausieren",
    "PauseStoreModal.Description.StorePaused":
      "Dein Shop wurde pausiert und das Abonnement für den nächsten Monat wird um 60 % reduziert. Bitte beachte, reguläre Zahlungen werden danach wieder aufgenommen.",
    "ConfirmPasswordModal.Title": "Bitte bestätige dein Passwort",
    "ConfirmPasswordModal.SubTitle": "Aus Sicherheitsgründen, bestätige bitte dein Passwort",
    "ConfirmPasswordModal.Input.Placeholder": "Dein Passwort",
    "ConfirmPasswordModal.Button.Cancel": "Abbrechen",
    "ConfirmPasswordModal.Button.Confirm": "Bestätigen",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "de",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Schneller wachsen mit Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Wähle deinen Abrechnungszyklus",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Deine ersten",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "Tage ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "sind kostenlos, kündige während oder nach deinem Test",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Deine erste Rechnung wird geplant für",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "Deine erste Rechnung wird mit der Bestätigung fällig",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "Die Abrechnung wiederholt sich monatlich",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Monatlich ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/Monat",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Jährlich ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/Monat",
    "UpgradeConfirmationModal.YearlyTab.Discount": "Aus",
    "UpgradeConfirmationModal.TaxRates.Text": "Umsatzsteuer",
    "UpgradeConfirmationModal.TaxRates.Period": "Mo.",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Jetzt zu bezahlen",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/Monat X 12 Monate",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Deine Ersparnisse: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/Jahr",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "entsprechen",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% Rabatt",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Es gibt keine Testphase für Jahresabonnements",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "Die Kosten werden dir sofort in voller Höhe berechnet",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Mit Kredit- / Debitkarte bezahlen",
    "UpgradeConfirmationModal.PayPalTab.Info": "Mit PayPal bezahlen",
    "UpgradeConfirmationModal.PaymentSection.Details": "Zahlungsdetails ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Mein kostenloses Probeabo anfordern",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Upgrade auf ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "50.000+ Unternehmer vertrauen auf uns",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom": "Unsere Kunden haben uns durchweg mit 5/5 bewertet.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128-bit SSL Verschlüsselung",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Alle deine Zahlungsdaten sind bei uns 100 % sicher.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "50.000 +",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "50.000+ Unternehmer vertrauen auf uns",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom": "Unsere Kunden haben uns durchweg mit 5/5 bewertet.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128-bit SSL Verschlüsselung",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Alle deine Zahlungsdaten sind bei uns 100 % sicher.",
    "UpgradeConfirmationModal.Agreement.Intro": "Indem du fortfährst, stimmst du den ",
    "UpgradeConfirmationModal.Agreement.Terms": "Nutzungsbedingungen,",
    "UpgradeConfirmationModal.Agreement.Privacy": "der Datenschutzrichtlinie,",
    "UpgradeConfirmationModal.Agreement.Refund": "der Erstattungsrichtlinie",
    "UpgradeConfirmationModal.Agreement.Middle": "und",
    "UpgradeConfirmationModal.Agreement.Cancelation": "den Stornierungsbedingungen zu",

    "OrderDetails.Title": "Bestelldaten",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Kreditkarte endend mit",
    "CreditCard.Update.Button": "Kreditkarte aktualisieren",
    "CreditCard.AddCreditCard.Text":
      "Du hast noch keine Kreditkarte hinzugefügt. Bitte füge eine Kreditkarte über den Button unten hinzu, damit wir deine Bestellungen automatisch bearbeiten können",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Kreditkarte hinzufügen",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Ausgezeichnete Produkte",
    "Sidebar.WinningProducts.Variant": "Neu",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Hilfecenter",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Einstellungen",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Wir sind für dich da!",
    "TutorialModal.WhatIsSpocket": "Was ist Spocket?",
    "TutorialModal.DiscoverProducts": "Produkte entdecken",
    "TutorialModal.ProductCustomization": "Produktanpassung",
    "TutorialModal.OrderProcessing": "Bestellabwicklung",
    "TutorialModal.Settings": "Einstellungen",
    "TutorialModal.GetHelp": "Erhalte Hilfe",
    "TutorialModal.JoinTheCommunity": "Werde Mitglied der Community",

    "NotificationCenter.Header": "Benachrichtigungscenter",
    "NotificationCenter.Footer": "Alle Benachrichtigungen ansehen",
    "NotificationCenter.NoNotification": "Hier gibt es nichts zu sehen!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English

    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English
    
    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English
    
    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Meine Produkte - Spocket",
    "Products.Title.Header": "Meine Produkte",
    "Products.Loading.Top": "Deine Produktliste wird geladen!",
    "Products.Loading.Bottom": "Bitte warte, während wir deine Produkte abrufen.",
    "Products.Empty.Search": "Es wurden keine Produkte gefunden!",
    "Products.Empty.Top": "Deine Produktliste ist leer!",
    "Products.Empty.Bottom":
      "Gehe auf die Suchseite und fange an, Produkte zu deiner Importliste hinzuzufügen.",
    "Products.Empty.Button.Search": "Produkte suchen",
    "Products.Empty.Button.How": "So findest du Produkte",
    "Products.Alert.Removed": "Produkt entfernt",

    "Products.Search.Label": "Suche deine Produkte",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Suche",

    "ProductListItem.Inactive.Tooltip": "Dieses Produkt ist beim Anbieter nicht mehr verfügbar",
    "ProductListItem.Inactive.Title": "Inaktiv",
    "ProductListItem.OutOfStock.Tooltip": "Alle Variationen dieses Produkts sind ausverkauft",
    "ProductListItem.OutOfStock.Title": "Ausverkauft",
    "ProductListItem.InventoryCount.Singular": "Stück",
    "ProductListItem.InventoryCount.Plural": "Stücke",
    "ProductListItem.InventoryCount.End.Singular": "übrig",
    "ProductListItem.InventoryCount.End.Plural": "übrig",
    "ProductListItem.Button.View": "Ansicht im Shop",
    "ProductListItem.Button.Remove": "Aus dem Shop entfernen",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "Importliste - Spocket",
    "ImportList.Title.Header": "Meine Importliste",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "Deine Importliste wird geladen!",
    "ImportList.Loading.Bottom": "Bitte warte, während wir deine Produkte abrufen.",
    "ImportList.Empty.Button.Search": "Produkte suchen",
    "ImportList.Empty.Top": "Deine Importliste wird geladen!",
    "ImportList.Empty.Bottom":
      "Gehe auf die Suchseite und fange an, Produkte zu deiner Importliste hinzuzufügen.",
    "ImportList.Empty.Button.How": "Wie man Produkte anpasst",
    "ImportList.Alert.Pushed": "Dieses Produkt wurde in deinen Shop gepusht:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "Durchsuche deine importierten Produkte",
    "ImportList.Search.Placeholder": "Suche deine Produkte",
    "ImportList.Search.Button": "Suche",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Aktuelle Seitenprodukte pushen",
    "PushAllModal.Button.Inactive": "Aktuelle Seite pushen",
    "PushAllModal.Modal.Text":
      "Bist du sicher, dass du alle Produkte von dieser aktuellen Seite pushen möchtest? Alle gültigen Produkte auf der aktuellen Seite werden in deinen Shop übertragen.",
    "PushAllModal.Modal.Push": "Alle übertragen",
    "PushAllModal.Modal.Cancel": "Abbrechen",

    "RemoveAllModal.Button.Active": "Aktuelle Seitenprodukte entfernen",
    "RemoveAllModal.Button.Inactive": "Aktuelle Seite entfernen",
    "RemoveAllModal.Modal.Text":
      "Bist du sicher, dass du alle Produkte von dieser aktuellen Seite entfernen möchtest? Alle Produkte, die sich auf der aktuellen Seite befinden, werden aus der Importliste entfernt.",
    "RemoveAllModal.Modal.Remove": "Alle entfernen",
    "RemoveAllModal.Modal.Cancel": "Abbrechen",

    "ImportListItem.Tab.Product": "Produkt",
    "ImportListItem.Tab.Description": "Beschreibung",
    "ImportListItem.Tab.Variants": "Varianten",
    "ImportListItem.Tab.Images": "Bilder",
    "ImportListItem.Remove.Active": "Produkt wird entfernt",
    "ImportListItem.Remove.Inactive": "Produkt entfernen",
    "ImportListItem.Push.Active": "Zum Shop pushen",
    "ImportListItem.Push.Inactive": "Zum Store übertragen",
    "ImportListItem.Save.Button": "Speichern",
    "ImportListItem.Alert.Removed": "Dieses Produkt wurde aus deiner Importliste entfernt: ",
    "ImportListItem.Alert.RemoveFailed": "Irgendetwas ist beim Entfernen schief gelaufen: ",
    "ImportListItem.Alert.CompareAtPrice":
      "„Vergleich zum”-Preis sollte größer sein als der Verkaufspreis für: ",
    "ImportListItem.Alert.OneActiveVariation": "Dieses Produkt benötigt mindestens eine aktive Variante: ",
    "ImportListItem.Alert.Saved": "Dieses Produkt wurde gespeichert: ",
    "ImportListItem.Alert.ErrorPushing": "Beim Pushen zu deinem Shop ist etwas schief gelaufen",
    "ImportListItem.Alert.ErrorSaving": "Beim Speichern deines Shops ist etwas schief gelaufen",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Verbinde deinen Shop, um Produkte zu pushen: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Du brauchst mindestens eine Aktive Variation",
    "ImportListItem.Tooltip.Unavailable": "Dieses Produkt ist derzeit nicht zum Kauf verfügbar",
    "ImportListItem.Tooltip.Unsaved": "Du hast ungesicherte Änderungen",
    "ImportListItem.Tooltip.Profit": "Warnung: Dein Bruttogewinn ist für dieses Produkt negativ",
    "ImportListItem.Tooltip.Unpurchasable":
      "Du kannst dieses Produkt speichern, aber es ist derzeit nicht zum Kauf verfügbar",
    "ImportListItem.Variants.ShowShippingPrices": "Versandpreise anzeigen",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Produktname",
    "ItemInfo.ProductTags.Label": "Produkt-Tags",
    "ItemInfo.ProductTags.Remove": "Alle Tags entfernen",
    "ItemInfo.ProductTags.Placeholder": "Neuen Tag hinzufügen",
    "ItemInfo.ProductType.Label": "Produktart",
    "ItemInfo.Collection.Label": "Kollektion",
    "ItemInfo.Collection.Placeholder": "Auswählen",
    "ItemInfo.Collection.Option": "Auswählen",
    "ItemInfo.SquareLocation.Option": "Auswählen",

    "ItemVariantList.Modal.Title": "Versanddetails",
    "ItemVariantList.Modal.Intro": "Wird geliefert aus: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Dieser Artikel wird nicht international versendet.",
    "ItemVariantList.Modal.DoesNotShipTo": "Kein Versand nach: ",
    "ItemVariantList.Table.Head.Destination": "Ziel",
    "ItemVariantList.Table.Head.Cost": "Kosten",
    "ItemVariantList.Table.Head.Time": "Lieferzeit (Tage)",
    "ItemVariantList.Table.Body.Domestic": "Inländisch",
    "ItemVariantList.Table.Body.International": "International",
    "ItemVariantList.VariantsTable.ShippingPrice": "Versandpreis ",
    "ItemVariantList.VariantsTable.Image": "Bild",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Inventar",
    "ItemVariantList.VariantsTable.Price": "Preis",
    "ItemVariantList.VariantsTable.SalesPrice": "Verkaufspreis ",
    "ItemVariantList.VariantsTable.Profit": "Gewinn",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Vergleichspreis",
    "ItemVariantList.VariantsTable.Unavailable": "Dieses Produkt ist derzeit nicht zum Kauf verfügbar",
    "ItemVariantList.VariantsTable.Head": "Alle Varianten für dieses Produkt werden hier gezeigt.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "Bsp.: 40 %",
    "ItemVariantList.Alert.MaxVariants": "Shopify unterstützt jetzt über 100 Varianten.",

    "ItemVariantList.ContextPopover.Title": "Gewinn einstellen",
    "ItemVariantList.ContextPopover.Apply": "Anwenden",

    "ProductVariation.Description":
      "Du kannst Bestellungen für Musterprodukte direkt auf Spocket aufgeben. Folge den untenstehenden Schritten für einen schnellen und einfachen Checkout.",
    "ProductVariation.Label.SelectVariant": "Variante wählen",
    "ProductVariation.Label.SelectTheProductVariation": "Wähle die Produktvariante",
    "ProductVariation.Qty": "Menge",
    "ProductVariation.Label.NotesForTheSupplier": "Hinweise für den Anbieter",

    "InputAddress.Label.FirstName": "Vorname",
    "InputAddress.PlaceHolder.FirstName": "Vorname eingeben",
    "InputAddress.Label.LastName": "Nachname",
    "InputAddress.PlaceHolder.LastName": "Nachname eingeben",
    "InputAddress.Label.StreetAddress": "Straße",
    "InputAddress.Label.Country": "Land",
    "InputAddress.PlaceHolder.Country": "Land suchen",
    "InputAddress.Label.State": "Bundesland",
    "InputAddress.Placeholder.State": "Bundesland suchen",
    "InputAddress.Label.City": "Stadt",
    "InputAddress.PlaceHolder.City": "Stadt eingeben",
    "InputAddress.Label.Zip": "PLZ",
    "InputAddress.PlaceHolder.Zip": "Postleitzahl eingeben",
    "InputAddress.Label.Phone": "Telefonnummer",
    "InputAddress.Placeholder.Phone": "Telefonnummer eingeben",

    "OrderReview.OrderDetails": "Bestelldaten",
    "OrderReview.Unit": "{{count}}Einheit",
    "OrderReview.Unit_plural": "{{count}} Einheiten",
    "OrderReview.ShippingAddress": "Lieferadresse",
    "OrderReview.PaymentMethod": "Zahlungsmethode",
    "OrderReview.Amounts": "Beträge",
    "OrderReview.Subtotal": "Zwischensumme",
    "OrderReview.ShippingCost": "Versandkosten",
    "OrderReview.TransactionFees": "Transaktionsgebühren",
    "OrderReview.TransactionFees.ToolTips":
      "Die Transaktionsgebühren beinhalten die von Stripe, dem Zahlungsanbieter von Spocket, erhobenen Gebühren sowie die Servicegebühren.",
    "OrderReview.OrderTotal": "Auftragssumme",
    "OrderReview.ItemWithCount": "{{count}}Artikel",
    "OrderReview.ItemWithCount_plural": "{{count}} Artikel",
    "OrderReview.Placeholder.CreditCard": "Kreditkarte endend mit {{last4}}",

    "PaymentComplete.PaymentCompleted": "Zahlung abgeschlossen",
    "PaymentComplete.PaymentCompleted.Note":
      "Vielen Dank für die Zahlung. Der Anbieter wurde benachrichtigt und wird die Bestellung in Kürze bearbeiten. Wir werden den Status der Bestellung aktualisieren und die Trackingnummer deiner Bestellübersichtsseite hinzufügen.",
    "PaymentComplete.PaymentIncomplete": "Deine Bestellung ist unvollständig!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Deine Bank benötigt eine Bestätigung deiner Zahlung, um fortzufahren",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink":
      "Bitte benutze diesen Link, um die Zahlung zu bestätigen.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Sobald die Zahlung bestätigt ist, wird der Anbieter benachrichtigt und deine Bestellung wird wie gewohnt bearbeitet. Status- und Tracking-Updates werden deiner Bestellübersichtsseite hinzugefügt. Bitte lade die Seite nach der Bestätigung neu.",

    "CreditCard.AddCreditCard.Description":
      "Du hast noch keine Kreditkarte hinzugefügt. Bitte füge eine Kreditkarte über den Button unten hinzu, damit wir deine Bestellungen automatisch bearbeiten können",
    "CreditCard.Button.AddCreditCard": "Kreditkarte hinzufügen",
    "CreditCard.Button.UpdateCard": "Kreditkarte aktualisieren",
    "CreditCard.UpdateCard.Label.CreditCard": "Kreditkarte",
    "CreditCard.PlaceHolder.CreditCard": "Kreditkarte endend mit {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Kreditkarte",
    "UpdateCreditCardModal.Button.UpdateCard": "Kreditkarte aktualisieren",
    "UpdateCreditCardModal.Button.AddCreditCard": "Kreditkarte hinzufügen",
    "UpdateCreditCardModal.Button.Cancel": "Abbrechen",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Aktualisiere deine Kartendetails",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Dein Konto wird bei der Bestellabwicklung von Spocket-Produkten verwendet.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Allgemeine Geschäftsbedingungen",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "für die Abwicklung von Zahlungen.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Variante wählen",
    "SampleOrderModal.Title.ShippingAddress": "Lieferadresse",
    "SampleOrderModal.Title.PaymentMethod": "Zahlungsmethode",
    "SampleOrderModal.Title.Review": "Überprüfen",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Zahlungsbestätigung erforderlich!",
    "SampleOrderModal.Title.OrderPlaced": "Bestellung aufgegeben!",
    "SampleOrderModal.Steps": "Schritt {{current}} von {{total}}",
    "SampleOrderModal.FinalStep": "Letzter Schritt",
    "SampleOrderModal.ConfirmPayment": "Bestätige die Zahlung!",
    "SampleOrderModal.Finished": "Fertig",
    "SampleOrderModal.Footer.Continue": "Weiter",
    "SampleOrderModal.Footer.PlaceOrder": "Bestellung aufgeben",
    "SampleOrderModal.Footer.Close": "Schließen",
    "SampleOrderModal.Footer.Cancel": "Abbrechen",
    "SampleOrderModal.Footer.GoBack": "Zurück",

    "AdvancedFiltersModal.Title.Filters": "Filter",
    "AdvancedFiltersModal.Title.Shipping": "Versand",
    "AdvancedFiltersModal.Label.ShipsFrom": "Wird geliefert aus",
    "AdvancedFiltersModal.Label.ShipsTo": "Wird geliefert nach",
    "AdvancedFiltersModal.Label.ShippingTime": "Versanddauer",
    "AdvancedFiltersModal.Label.Popular": "Beliebt",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Vereinigte Staaten",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Nur für den Versand in die USA verfügbar",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Beliebige Lieferzeit",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 Tage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 Tage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 Tage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15 Tage",
    "AdvancedFiltersModal.Title.ItemsCost": "Artikelkosten",
    "AdvancedFiltersModal.Label.ItemCost": "Artikelkosten",
    "AdvancedFiltersModal.Label.ShippingCost": "Versandkosten",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Beliebige Kosten",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Kostenlos",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5 $ und weniger",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15 $ und weniger",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25 $ und weniger",
    "AdvancedFiltersModal.Title.Supplier": "Anbieter",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Anbieter",
    "AdvancedFiltersModal.Label.TopSupplier": "Top-Anbieter",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Anbieter, die sich durch hochwertige Produkte, tollen Service und hohe Bewertungen auszeichnen",
    "AdvancedFiltersModal.Title.Advanced": "Fortgeschritten",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Premiumprodukte",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Produkte mit hohen Rabatten und schnellem Versand",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Bestseller",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Die erfolgreichsten Produkte auf Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Abbrechen",
    "AdvancedFiltersModal.Button.ViewResults": "Ergebnisse ansehen",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Land suchen...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Länder",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Anbieter suchen...",
    "AdvancedFiltersModal.Label.All Suppliers": "Alle Anbieter",
    "AdvancedFiltersModal.Label.New Suppliers": "Neue Anbieter",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Beliebige Lieferzeit",
    "AdvancedFilters.ShippingTime.One": "1-3 Tage",
    "AdvancedFilters.ShippingTime.Four": "4-7 Tage",
    "AdvancedFilters.ShippingTime.Eight": "8-14 Tage",
    "AdvancedFilters.ShippingTime.Fifteen": "15 Tage",

    "AdvancedFilters.ShippingCost.Free": "Kostenloser Versand",
    "AdvancedFilters.ShippingCost.Five": "Versand unter 5 $",
    "AdvancedFilters.ShippingCost.Fifteen": "Versand unter 15 $",
    "AdvancedFilters.ShippingCost.TwentyFive": "Versand unter 25 $",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Alle löschen",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Versand",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Suche",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Lieferung aus",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Lieferung nach",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Anbieter",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Sortieren nach",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Artikelkosten",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Top-Anbieter",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Personalisierte Rechnungen",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Bestseller",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "von {{from}} nach {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Stichwörter eingeben...",
    "Search.Header.Button.Search": "Suche",
    "Search.Header.Button.Filters": "Filter",

    "Navigation.UpgradeButton.TryPro": "Teste Spocket Pro KOSTENLOS",
    "Navigation.UpgradeButton.TryEmpire": "Teste Spocket EMPIRE KOSTENLOS",
    "Navigation.UpgradeButton.TryUnicorn": "Teste Spocket Unicorn KOSTENLOS",

    "CategoriesCards.Label.MoreCategories": "Weitere Kategorien",

    "StaticCategories.WomensClothing": "Damenmode",
    "StaticCategories.WomensClothing.Activewear": "Sportkleidung",
    "StaticCategories.WomensClothing.Bodysuits": "Bodysuits",
    "StaticCategories.WomensClothing.Dresses": "Kleider",
    "StaticCategories.WomensClothing.JacketsCoats": "Jacken & Mäntel",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Jumpsuits & Strampler",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Dessous & Unterwäsche",
    "StaticCategories.WomensClothing.MatchingSets": "Passende Sets",
    "StaticCategories.WomensClothing.Pants": "Hosen",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Röcke",
    "StaticCategories.WomensClothing.Sleepwear": "Nachtwäsche",
    "StaticCategories.WomensClothing.Socks": "Socken",
    "StaticCategories.WomensClothing.SweatersHoodies": "Pullover & Hoodies",
    "StaticCategories.WomensClothing.Swimwear": "Bademode",
    "StaticCategories.WomensClothing.TankTops": "Tank-Tops",
    "StaticCategories.WomensClothing.TopsBlouses": "Oberteile & Blusen",
    "StaticCategories.WomensClothing.Tshirts": "T-Shirts",

    "StaticCategories.JewelryWatches": "Schmuck & Uhren",
    "StaticCategories.JewelryWatches.Bracelets": "Armbänder",
    "StaticCategories.JewelryWatches.Earrings": "Ohrringe",
    "StaticCategories.JewelryWatches.Necklaces": "Halsketten",
    "StaticCategories.JewelryWatches.OtherAccessories": "Weitere Accessoires",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Anhänger, Schmucksteine & Charms",
    "StaticCategories.JewelryWatches.Rings": "Ringe",
    "StaticCategories.JewelryWatches.Sets": "Sets",
    "StaticCategories.JewelryWatches.Watches": "Uhren",

    "StaticCategories.TechAccessories": "Tech-Accessoires",
    "StaticCategories.TechAccessories.AudioVideo": "Audio & Video",
    "StaticCategories.TechAccessories.CasesCovers": "Etuis & Hüllen",
    "StaticCategories.TechAccessories.Lighting": "Beleuchtung",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Handy- & Laptop-Zubehör",
    "StaticCategories.TechAccessories.Mousepads": "Mauspads",
    "StaticCategories.TechAccessories.Novelty": "Neuheit",

    "StaticCategories.KidsBabies": "Kinder & Babies",
    "StaticCategories.KidsBabies.BabyClothing": "Babybekleidung",
    "StaticCategories.KidsBabies.Bathing": "Baden",
    "StaticCategories.KidsBabies.BlanketsPillows": "Decken & Kissen",
    "StaticCategories.KidsBabies.CapsHeadbands": "Mützen & Stirnbänder",
    "StaticCategories.KidsBabies.Footwear": "Schuhe",
    "StaticCategories.KidsBabies.Furniture": "Möbel",
    "StaticCategories.KidsBabies.KidsClothing": "Kinderbekleidung",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Elternschaft & Zubehör",

    "StaticCategories.Toys": "Spielzeug",
    "StaticCategories.Footwear": "Schuhe",
    "StaticCategories.Footwear.Boots": "Stiefel",
    "StaticCategories.Footwear.Flats": "Ballerinas",
    "StaticCategories.Footwear.Heels": "Absätze",
    "StaticCategories.Footwear.Mens": "Herren",
    "StaticCategories.Footwear.Sandals": "Sandalen",
    "StaticCategories.Footwear.Slippers": "Hausschuhe",
    "StaticCategories.Footwear.SneakersRunners": "Turn- & Laufschuhe",

    "StaticCategories.BathBeauty": "Bad & Schönheit",
    "StaticCategories.BathBeauty.Bodycare": "Körperpflege",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffusoren, Öle & Kerzen",
    "StaticCategories.BathBeauty.Haircare": "Haarpflege",
    "StaticCategories.BathBeauty.Healthcare": "Gesundheit",
    "StaticCategories.BathBeauty.Makeup": "Make-up",
    "StaticCategories.BathBeauty.Nails": "Nägel",
    "StaticCategories.BathBeauty.Skincare": "Hautpflege",
    "StaticCategories.BathBeauty.TowelsRobes": "Handtücher & Bademäntel",

    "StaticCategories.Pets": "Haustiere",
    "StaticCategories.Pets.BedsBlankets": "Betten & Decken",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Leinen, Halsbänder & Tierbekleidung",
    "StaticCategories.Pets.Petcare": "Haustierpflege",
    "StaticCategories.Pets.Toys": "Spielzeug",

    "StaticCategories.HomeGarden": "Haus & Garten",
    "StaticCategories.HomeGarden.Bathroom": "Badezimmer",
    "StaticCategories.HomeGarden.Clocks": "Uhren",
    "StaticCategories.HomeGarden.Drinkware": "Trinkgeschirr",
    "StaticCategories.HomeGarden.FoodBeverage": "Essen & Trinken",
    "StaticCategories.HomeGarden.Furniture": "Möbel",
    "StaticCategories.HomeGarden.Garden": "Garten",
    "StaticCategories.HomeGarden.HomeDecor": "Wohnungseinrichtung",
    "StaticCategories.HomeGarden.HomeImprovement": "Heimwerkerbedarf",
    "StaticCategories.HomeGarden.Kitchen": "Küche",
    "StaticCategories.HomeGarden.Lighting": "Beleuchtung",
    "StaticCategories.HomeGarden.Organization": "Organisation",
    "StaticCategories.HomeGarden.StationeryCrafts": "Schreibwaren & Kunsthandwerk",
    "StaticCategories.HomeGarden.TextilesPillows": "Textilien & Kissen",
    "StaticCategories.HomeGarden.Tools": "Werkzeuge",

    "StaticCategories.Accessories": "Accessoires",
    "StaticCategories.Accessories.Belts": "Gürtel",
    "StaticCategories.Accessories.Cufflinks": "Manschettenknöpfe",
    "StaticCategories.Accessories.Hats": "Hüte",
    "StaticCategories.Accessories.Keychains": "Schlüsselanhänger",
    "StaticCategories.Accessories.ScarvesBandanas": "Schals & Bandanas",
    "StaticCategories.Accessories.Sunglasses": "Sonnenbrillen",

    "StaticCategories.MensClothing": "Herrenmode",
    "StaticCategories.MensClothing.Activewear": "Sportkleidung",
    "StaticCategories.MensClothing.DressShirts": "Smokinghemden",
    "StaticCategories.MensClothing.JacketsCoats": "Jacken & Mäntel",
    "StaticCategories.MensClothing.Pants": "Hosen",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Socken",
    "StaticCategories.MensClothing.SweatersHoodies": "Pullover & Hoodies",
    "StaticCategories.MensClothing.Swimwear": "Bademode",
    "StaticCategories.MensClothing.TankTops": "Tank-Tops",
    "StaticCategories.MensClothing.Tshirts": "T-Shirts",
    "StaticCategories.MensClothing.Underwear": "Unterwäsche",

    "StaticCategories.SportsOutdoors": "Sport & Freizeit",
    "StaticCategories.SportsOutdoors.Activewear": "Sportkleidung",
    "StaticCategories.SportsOutdoors.Biking": "Radfahren",
    "StaticCategories.SportsOutdoors.Camping": "Camping",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Ausrüstung & Zubehör",
    "StaticCategories.SportsOutdoors.Fishing": "Angeln",

    "StaticCategories.Gifts": "Geschenke",
    "StaticCategories.Gifts.Adults": "Erwachsene",
    "StaticCategories.Gifts.Funny": "Lustiges",
    "StaticCategories.Gifts.Mugs": "Tassen",
    "StaticCategories.Gifts.Novelty": "Neuheit",
    "StaticCategories.Gifts.Personalized": "Personalisiert",
    "StaticCategories.Gifts.Religious": "Religiös",

    "StaticCategories.Automotive": "Kfz",
    "StaticCategories.BagsWallets": "Taschen & Geldbörsen",
    "StaticCategories.Seasonal": "Saisonal",
    "StaticCategories.Seasonal.Christmas": "Weihnachten",
    "StaticCategories.Seasonal.FathersDay": "Vatertag",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Muttertag",

    "StaticCategories.FestivalsParties": "Festivals & Partys",
    "StaticCategories.FestivalsParties.PartySupplies": "Partyzubehör",
    "StaticCategories.FestivalsParties.Wedding": "Hochzeit",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Schneller USA-Versand",
    "QuickFilters.Label.Under5": "USA-Versand unter 5 $",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Sortieren nach",
    "SortBy.DropDown.Item.Relevance": "Relevanz",
    "SortBy.DropDown.Item.PriceLowToHigh": "Preis: niedrig bis hoch",
    "SortBy.DropDown.Item.PriceHighToLow": "Preis: hoch bis niedrig",
    "SortBy.DropDown.Item.Newest": "Neueste",
    "SortBy.DropDown.Item.ShippingTime": "Versanddauer",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Haus",
    "Breadcrumb.Item.Search": "Suche",

    "UpgradeModal.Modal.Title": "Wähle einen Preisplan",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Monatlich",
    "Plans.ToggleSection.Yearly": "Jährlich",
    "Plans.ToggleSection.DiscountInitial": "Geh hoch auf",
    "Plans.ToggleSection.DiscountSingular": "Monat",
    "Plans.ToggleSection.DiscountPlural": "Monate",
    "Plans.ToggleSection.DiscountFinal": "Aus",
    "Plans.ProrationCost.TextInitial": "Du bezahlst derzeit",
    "Plans.ProrationCost.TextFinal": "/Jahr Upgrade auf Empire für nur einen zusätzlichen",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", //English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Beginne zu verkaufen",
    "Card.DescriptionVariantType.Professional": "Umsatz steigern",
    "Card.DescriptionVariantType.Empire": "Verkäufe maximieren",
    "Card.DescriptionVariantType.Unicorn": "Verwalte Verkäufe im Maßstab",
    "Card.DescriptionVariantType.Period": "Mo.",
    "Card.DescriptionVariantType.PricePeriod": "/Mo.",

    "Card.DescriptionVariantType.AnnualDescription": "{{value}} $ jährlich abgerechnet",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Dein aktuelles Abonnement",
    "Card.DescriptionVariantType.Button.Start": "Jetzt loslegen",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English

    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Gebrandete Rechnungsstellung",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Ausgezeichnete Produkte",
    "Card.DescriptionVariantType.SupplierSourcing": "Anbieterbeschaffung",
    "Card.DescriptionVariantType.ProductRequests": "Produktwünsche",
    "Card.DescriptionVariantType.BulkCheckout": "Bulk-Checkout",
    "Card.DescriptionVariantType.Special": "Besonderes",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Dein Spocket-Abonnement ist abgelaufen.",
    "ReactivationBanner.InfoSubtitle": "Reaktiviere dein",
    "ReactivationBanner.CheckList.HighQuality": "Hohe Qualität",
    "ReactivationBanner.CheckList.Curated": "Kuratiert",
    "ReactivationBanner.CheckList.FastShipping": "Schneller Versand",
    "ReactivationBanner.CheckList.FromUSAndEU": "Aus den USA und der EU",
    "ReactivationBanner.TextByPlan.Starter.Description": "Du kannst keine <1>Push-Produkte</1> mehr pushen",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket-Konto",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Beginne jetzt Produkte in deinen Shop zu pushen",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Du kannst <1>Push Produkte</1> nicht mehr live oder mit ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Premiumprodukten nutzen",
    "ReactivationBanner.TextByPlan.Others.Title": "Premium-Konto",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Erhalte Zugang zu Premiumprodukten, die",
    "ReactivationBanner.Button": "Mein Konto JETZT reaktivieren",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Mehr Produkt-Pushes",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Unbegrenzte Bestellungen",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Gebrandete Rechnungsstellung",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Premiumprodukte",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Exklusive Deals",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Chat- & Anruf-Support",
    "UpgradeReasonModal.Modal.Title": "Was möchtest du mit Spocket erreichen?",
    "UpgradeReasonModal.Modal.Question": "Was hat dich zum Upgrade bewogen?",
    "UpgradeReasonModal.Modal.Button": "Beginne, Spocket {{plan}} zu nutzen",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Es hilft uns, deine Erfahrung zu verbessern",

    "UpgradeSuccessModal.Col.One.A": "Herzlichen Glückwunsch",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Du hast alle Tools freigeschaltet, die du brauchst, um ein erfolgreiches Dropshipping-Business aufzubauen.",
    "UpgradeSuccessModal.Col.Two.B": "Du kannst jetzt ",
    "UpgradeSuccessModal.Col.Two.C": " Viel Spaß beim Spocketing!",
    "UpgradeSuccessModal.Button": "Weiter",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 Monat",
    "Promotion.TrialDaysNormalizer.28days": "4 Wochen",
    "Promotion.TrialDaysNormalizer.21days": "3 Wochen",
    "Promotion.TrialDaysNormalizer.14days": "2 Wochen",
    "Promotion.TrialDaysNormalizer.7days": "1 Woche",
    "Promotion.TrialDaysNormalizer.1day": "1 Tag",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} Tage",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Kostenlos ausprobieren",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Jetzt ausprobieren →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Sprache",

    "WhatYouWillLoseModal.Button.Offer": "Mach weiter",
    "WhatYouWillLoseModal.Button.Downgrade": "Ich gebe auf und downgrade mein Konto",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Du wirst alle <1>{{product_count}} importierten Produkte verlieren</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} mehr",
    "WhatYouWillLoseModal.ModalWrapper.Header": "Gibst du schon auf?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Erhalte 50 % Rabatt",
    "WhatYouWillLoseModal.ModalWrapper.Title":
      "Setze deine unternehmerische Reise in den nächsten 3 Monaten fort.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Wir wollen, dass auch du zur Erfolgsgeschichte wirst. Wir wissen, dass du bereits eine Menge Zeit investiert hast. Mach also weiter. Wir alle drücken dir die Daumen. Spocket-Team",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Herzlichen Glückwunsch, wir haben jetzt einen 50%igen Rabatt auf dein Abonnement für die nächsten 3 Monate hinzugefügt!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Weiter",

    "DowngradeReasonModal.DowngradeSection": "Wie können wir Spocket besser für dich machen?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Wir möchten dein Feedback zu allem was dir in den Sinn kommt (mindestens 100 Zeichen)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Bitte gib dein Feedback ein (mindestens 100 Zeichen)",
    "DowngradeReasonModal.Button.Downgrade": "Downgraden",
    "DowngradeReasonModal.ModalWrapper.Header": "Downgraden {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Kontaktiere uns",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Abbrechen",

    "DowngradeSuccessModal.Header": "Downgrade erfolgreich",
    "DowngradeSuccessModal.Body":
      "Vielen Dank für dein Feedback. Wir verbessern Spocket jeden Tag, hoffentlich gibst du uns bald eine weitere Chance!",

    "Settings.title": "Einstellung",
    "Settings.buttonSaveChanges": "Änderungen speichern",
    "Settings.buttonSaveAndPreview": "Speichern und Vorschau",
    "Settings.PricingPlans.Title": "Preismodelle",

    "Settings.MenuTab.Plans": "Pläne",
    "Settings.MenuTab.Account": "Konto",
    "Settings.MenuTab.BrandedInvoicing": "Gebrandete Rechnungsstellung",
    "Settings.MenuTab.Billing": "Abrechnung",
    "Settings.MenuTab.GlobalPricingRules": "Standardmäßige Preisgestaltungsregeln",
    "Settings.MenuTab.Security": "Sicherheit",

    "AccountTab.documentTitle": "Einstellungen - Konto - Spocket",
    "AccountTab.title": "Kontoeinstellung",
    "AccountTab.shopName": "Shopname",
    "AccountTab.email": "E-Mail",
    "AccountTab.shopUrl": "Shop-URL",
    "AccountTab.defaultCurrency": "Standardwährung",
    "AccountTab.yourPlan": "Dein Abonnement",
    "AccountTab.accountNotActive": "Dein Konto ist derzeit nicht aktiv",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Dieses Feature wird bald hinzugefügt! Verbinde dich zunächst über die Spocket Shopify App!",
    "AccountTab.alertAccountSettingsUpdated": "Kontoeinstellungen aktualisiert",
    "AccountTab.alertInvalidShopUrl": "Ungültige Shop-URL",
    "AccountTab.productsImported": "Importierte Produkte",
    "AccountTab.products": "Produkte",
    "AccountTab.premiumProducts": "Premiumprodukte",
    "AccountTab.total": "Gesamt",
    "AccountTab.signOut": "Ausloggen",
    "AccountTab.limitReachedProductsAndPremium": "Produkt- und Premiumprodukt-Limit erreicht.",
    "AccountTab.limitReachedProducts": "Produktlimit erreicht.",
    "AccountTab.limitReachedPremium": "Limit für Premiumprodukte erreicht.",
    "AccountTab.buttonSwitchToAnnualPlan": "Zum Jahresabonnement wechseln",
    "AccountTab.off30Percent": "30 % Rabatt",
    "AccountTab.off40Percent": "40 % Rabatt",
    "AccountTab.off45Percent": "45 % Rabatt",
    "AccountTab.sellMoreWithEmpirePlan": "Mehr verkaufen mit dem Empire-Abonnement",
    "AccountTab.tooltipCurrency":
      "Deine Währung wird über dein Shop-Konto verwaltet. Alle Preise für deine importierten Produkte werden in diese Währung umgerechnet.",
    "AccountTab.shopNotConnectedYet": "Dein Shop wurde noch nicht mit diesem Konto verbunden.",
    "AccountTab.connectYourShop": "Verbinde deinen Shop",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Einstellungen - Rechnungen - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Rechnungseinstellungen gespeichert!",
    "InvoiceTab.shopLogo": "Shop-Logo",
    "InvoiceTab.contactEmail": "Kontakt-E-Mail",
    "InvoiceTab.phoneNumber": "Telefonnummer",
    "InvoiceTab.personalNote": "Persönliche Notiz",
    "InvoiceTab.tooltipPersonalNote":
      "Lege eine persönliche Notiz fest, die jede deiner gebrandeten Rechnungen enthalten soll",
    "InvoiceTab.brandedInvoicing": "Gebrandete Rechnungsstellung",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Falls du ein kostenpflichtiges Abonnement hast, kannst du das Logo deiner Marke und eine persönliche Notiz zu allen Bestellungen bei Anbietern hinzufügen",
    "InvoiceTab.disabledInvoicing": "Alle Bestellungen mit gebrandeten Rechnungen versehen",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Wenn du diese Option ausschaltest, wird die gebrandete Rechnungsstellung deaktiviert, wenn du deine Bestellungen aufgibst",

    "PricingTab.titleDefaultPricingRules": "Standardmäßige Preisgestaltungsregeln",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Erweiterte Preisgestaltungsregeln",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Cents zuweisen",
    "PricingTab.tooltipAssignCents":
      "Dieser Wert wird verwendet, wenn der Endpreis für deine Artikel angezeigt wird (z. B. wenn du möchtest, dass der Preis für dein Produkt XX,99 beträgt, dann füge 99 zu den Feldern unten hinzu).",
    "PricingTab.Markup": "Aufschlag",
    "PricingTab.MarkupType": "Aufschlagstyp",
    "PricingTab.SelectDefault": "-- Auswählen --",
    "PricingTab.SelectPercent": "Prozent",
    "PricingTab.SelectMultiplier": "Multiplikator",
    "PricingTab.SelectFixed": "Fix",
    "PricingTab.titlePricingRules": "Einstellungen - Preisgestaltung - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Erweiterte Preisregeln umstellen",
    "PricingTab.from": "Von",
    "PricingTab.to": "An",
    "PricingTab.selectFixedAmount": "Pauschale",
    "PricingTab.buttonRemove": "Entfernen",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Du kannst nicht mehr als eine Regel mit leeren Feldern erstellen",
    "PricingTab.costRange": "Kostenspanne",
    "PricingTab.markup": "Aufschlag",
    "PricingTab.add": "Hinzufügen",

    "SecurityTab.Document.Title": "Einstellungen - Sicherheit - Spocket",
    "SecurityTab.alertIncorrectPassword": "Falsches Passwort",
    "SecurityTab.title": "Sicherheit",
    "SecurityTab.yourNewPassword": "Dein neues Passwort",
    "SecurityTab.repeatPassword": "Passwort wiederholen",
    "SecurityTab.errorPleaseEnterAPassword": "Bitte gib ein Passwort ein.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Bitte gib eine Passwortbestätigung ein.",
    "SecurityTab.errorPasswordsDoNotMatch": "Passwörter stimmen nicht überein",
    "SecurityTab.errorPasswordShort": "Das Passwort muss mindestens 8 Zeichen lang sein",

    "ListingCards.TopSupplierTag.Tooltip":
      "Top-Anbieter sind bekannt für ihre hochwertigen Produkte, tollen Service und hohe Bewertungen",
    "ListingCards.TopSupplierTag.Text": "Anbieter",

    "ListingCard.OriginCountry.Intro": "Durch",
    "ListingCard.OriginCountry.Tooltip": "Wird geliefert aus",
    "ListingCard.Shipping.Price.Title": "Versandpreis",
    "ListingCard.Shipping.Price.Free": "GRATIS",
    "ListingCard.Price.Intro": "Auflistung Kosten",
    "ListingCard.Price.Retail": "Verkaufspreis",
    "ListingCard.Shipping.Time.Title": "Versanddauer",
    "ListingCard.Shipping.Time.Period": "Tage",
    "ListingCard.PremiumIcon.Tooltip":
      "Verkaufe exklusive, stark reduzierte und schnell versendbare Produkte in deinem Shop",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Zur Importliste hinzufügen",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Zum Store übertragen",
    "ListingCard.StatusTag.Imported.Title": "Importiert",
    "ListingCard.StatusTag.Imported.Text": "Dieser Artikel befindet sich bereits in Ihrer Importliste",
    "ListingCard.StatusTag.Pushed.Title": "Auf Lager",
    "ListingCard.StatusTag.Pusehd.Text": "Dieser Artikel wurde in deinen Shop gepusht:",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Produktbeschreibung",
    "ListingModal.DetailsSection.Button.ProductVariations": "Produktvariationen öffnen",
    "ListingModal.DetailsSection.Button.OrderSamples": "Bestellbeispiele",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Neu",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "Gebrandete Rechnungen sind für dieses Produkt nicht verfügbar",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Internationale Bestellungen werden von unserem Lager in China verschickt",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Artikel, die im Lager in den USA nicht vorrätig sind, können von unserem Lager in China verschickt werden",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Bestellungen können in mehreren Paketen verschickt werden, um die Gesamtversandzeit deiner Bestellungen zu reduzieren",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Verbinde deinen Shop, um eine Bestellung aufzugeben",
    "ListingModal.DetailsSection.PushedListButton": "Zum Store übertragen",
    "ListingModal.DetailsSection.RemoveListButton": "Aus Liste entfernen",
    "ListingModal.DetailsSection.ImportListButton": "Zur Importliste hinzufügen",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Angebot deaktiviert",

    "InfoSection.ShippingTime.Tooltip": "Anzahl der Tage, bis das Produkt nach dem Versand geliefert wird.",
    "InfoSection.ShippingTime.Title": "Versanddauer",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Weltweit",
    "InfoSection.ShippingExcluded.Intro": "Kein Versand nach",
    "InfoSection.ShippingExcluded.Various": "Verschiedenes",
    "InfoSection.ProcessingTime.Tooltip":
      "Anzahl der Werktage, die der Anbieter für den Versand und die Bereitstellung der Tracking-Nummer benötigt",
    "InfoSection.ProcessingTime.Title": "Bearbeitungszeit",
    "InfoSection.ProcessingTime.Period": "Werktage",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Dieses Produkt wird für {{price_formatted}} versendet, plus {{incremental_price_formatted}} für jedes weitere Produkt in der gleichen Bestellung",
    "InfoSection.ShippingInfoLine.Period": "Werktage",
    "InfoSection.ShippingInfoLine.Free": "GRATIS",
    "InfoSection.ShippingInfoLine.NoShipping": "Versendet nicht",

    "ReturnPolicySection.Title": "Rückgaberichtlinie",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Von",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Durch",
    "TitleSection.PriceMSRP.Listing": "Angebotspreis",
    "TitleSection.PriceMSRP.Retail": "Verkaufspreis",

    "ProductVariationsModal.Title": "Produktvariationen",
    "ProductVariationsModal.Table.Image": "Bild",
    "ProductVariationsModal.Table.Inventory": "Inventar",
    "ProductVariationsModal.Table.Price": "Preis",
    "ProductVariationsModal.ImageSpecifications.Title": "Größentabelle",
    "ProductVariationsModal.ImageSpecifications.Button": "Download",

    "TopSupplier.Tooltip":
      "Top-Anbieter sind bekannt für ihre hochwertigen Produkte, tollen Service und hohe Bewertungen",
    "TopSupplier.Text": "Top-Anbieter",

    "BestSelling.Tooltip.Top": "Dies ist eines der erfolgreichsten Produkte auf Spocket",
    "BestSelling.Tooltip.Bottom": "Bestseller",

    "GallerySection.PremiumIcon.Tooltip":
      "Verkaufe exklusive, stark reduzierte und schnell versendbare Produkte in deinem Shop",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "Extra {{value}} % Rabatt",

    "Upgrade.Title.AnnualToggled": "Hol dir noch heute Spocket-Premium",
    "Upgrade.Title.MonthlyToggled": "Teste Spocket-Premium 14 Tage lang kostenlos",
    "Upgrade.Subtitle":
      "Probiere noch heute ein Spocket Premium-Abonnement aus und fange an, umsatzstarke Produkte aus den USA und Europa in deinen Shop aufzunehmen.",
    "Upgrade.Button": "Weiter ohne Spocket-Premium",

    "Search.NoProductsFound.Title": "Es tut uns leid! Wir konnten keine Ergebnisse finden",
    "Search.NoProductsFound.For": "für",
    "Search.NoProductsFound.Description":
      "Bitte überprüfe die Rechtschreibung, versuche einen verwandten oder einen allgemeineren Suchbegriff.",
    "Search.Searching.Title": "Suchvorgang nach Produkten läuft...",
    "Search.Searching.Description": "Bitte warte, während wir die neuesten Produkte für dich laden.",

    "Suppliers.StarRating.Tooltip": "Erfolgsquote der Bestellabwicklungen des Anbieters",
    "Suppliers.RefundPolicy": "Erstattungsrichtlinie des Anbieters",
    "Suppliers.Joined.Title": "Spocket beigetreten",
    "Suppliers.Origin.Title": "Versand aus dem Land",
    "Suppliers.Time.Title": "Durchschnittliche Bearbeitungszeit",
    "Suppliers.HasMore.Title": "Suchvorgang nach Produkten läuft...",
    "Suppliers.HasMore.Subtitle": "Bitte warte, während wir die neuesten Produkte für dich laden.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Top-Anbieter sind bekannt für ihre hochwertigen Produkte, tollen Service und hohe Bewertungen",
    "Suppliers.TopSupplierTag.Text": "Top-Anbieter",

    "AltTextModal.Header.Title": "Bilddetails bearbeiten",
    "AltTextModal.Body.Description":
      "Schreibe eine kurze Beschreibung zu diesem Bild, um die Suchmaschinenoptimierung (SEO) und die Zugänglichkeit für sehbehinderte Kunden zu verbessern.",
    "AltTextModal.Input.Label": "Alt-Text",
    "AltTextModal.Input.Placeholder": "Bild ALT-Text",
    "AltTextModal.Button.Cancel": "Abbrechen",
    "AltTextModal.Button.Save": "Speichern",

    "WistiaModal.WhatIsSpocket.Title": "Was ist Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Erfahre, wie Spocket dir helfen kann, dein Business aufzubauen. Verstehe, wie Spocket funktioniert und wie du es am besten nutzen kannst.",
    "WistiaModal.WhatIsSpocket.Button": "Beginne, Produkte zu finden",
    "WistiaModal.DiscoverProducts.Title": "Produkte entdecken",
    "WistiaModal.DiscoverProducts.Text":
      "Finde die richtigen Produkte mit Hilfe von geeigneten Filtern und füge sie deinem Shop hinzu.",
    "WistiaModal.DiscoverProducts.Button": "Beginne, Produkte zu finden",
    "WistiaModal.ProductCustomization.Title": "Passe deine Produkte an",
    "WistiaModal.ProductCustomization.Text":
      "Benutze die Importliste, um deine Produkte an deinen Shop anzupassen. Du kannst die Produktbeschreibungen bearbeiten, die Preise festlegen, das Produkt zu einer Kollektion hinzufügen sowie Bilder entfernen.",
    "WistiaModal.ProductCustomization.Button": "Zur Importliste gehen",
    "WistiaModal.OrderProcessing.Title": "Automatisierte Bestellabwicklung",
    "WistiaModal.OrderProcessing.Text":
      "Erfülle deine Bestellungen und lasse den Anbieter das Produkt mit nur einem Klick direkt an die Haustür deines Kunden liefern.",
    "WistiaModal.OrderProcessing.Button": "Zu Bestellungen gehen",
    "WistiaModal.Settings.Title": "Einstellungen",
    "WistiaModal.Settings.Text":
      "Auf der Einstellungsseite kannst du alle deine Kontoinformationen verwalten, deine Rechnung brandmarken und deine Kreditkarte hinzufügen. Du kannst auch Preisregeln für alle deine Produkte anpassen und ein Abonnement wählen.",
    "WistiaModal.Settings.Button": "Richte dein Konto ein",

    "Footer.Copyright": "Copyright {{year}}, Spocket. Alle Rechte vorbehalten",
    "Footer.PrivacyPolicy": "Datenschutzrichtlinie",
    "Footer.TermsConditions": "Allgemeine Geschäftsbedingungen",
    "Footer.DataProcessingAddendum": "Nachtrag zur Datenverarbeitung",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Search.NoOrdersFound.Title": "No results for your search", // English
    "Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English

    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English

    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
        
    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English
    
    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
    
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
    
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
