export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Lista das minhas encomendas",
    "OrderList.Input.Search": "Número da encomenda…",
    "OrderList.Label.Status": "Filtrar por estado Spocket",

    "order.status.unpaid": "Não pago",
    "order.status.paid": "Pago",
    "order.status.processing": "Em processamento",
    "order.status.shipped": "Enviado",
    "order.status.cancelled": "Cancelado",
    "order.status.pending": "Pendente",
    "order.status.partially_paid": "Parcialmente pago",
    "order.status.partially_refunded": "Parcialmente reembolsado",
    "order.status.refunded": "Reembolsado",
    "order.status.voided": "Anulado",
    "order.status.authorized": "Autorizado",
    "order.status.deleted": "Eliminado",
    "order.status.pending_payment_confirmation": "Pendente",

    "OrderList.Label.DownloadHistory": "Fazer download de todo o histórico de encomendas CSV",
    "OrderList.Document.Title": "Encomendas - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Procurar produtos",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Como processar encomendas",
    "OrderList.EmptyOrders.Message.Loading.Title": "As suas encomendas estão a ser carregadas!",
    "OrderList.EmptyOrders.Message.Loading.Description":
      "Por favor, aguarde enquanto reavemos as suas encomendas.",
    "OrderList.EmptyOrders.Message.Title": "A sua lista de encomendas está vazia!",
    "OrderList.EmptyOrders.Message.Description":
      "Pode gerir e processar as suas encomendas aqui. Dirija-se à página de pesquisa para começar a adicionar produtos à sua lista de importação.",
    "OrderList.CheckBox.SelectAllOrders": "Selecionar todas as encomendas",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} encomenda selecionada",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} encomenda selecionada",
    "OrderList.CheckBox.BulkCheckoutOrders": "Finalização de uma encomenda de grande volume",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Finalização de encomendas de grande volume",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Sincronizar encomendas",
    "RefreshModal.Button.Cancel": "Cancelar",
    "RefreshModal.Button.Synchronize": "Sincronizar",
    "RefreshModal.Modal.Body":
      "As encomendas são sincronizadas automaticamente a partir da sua loja. Sincronizar apenas se as informações da encomenda estiverem em falta ou não tiverem sido atualizadas. Atualize a página após 30 segundos.",

    "Order.Label.OrderNumber": "Número da encomenda",
    "Order.Label.Date": "Data",
    "Order.Label.OrderStatus": "{{integrationName}} Estado da encomenda",
    "Order.Table.Th.Product": "Produto",
    "Order.Table.Th.Qty": "QTD",
    "Order.Table.Th.UnitPrice": "Preço unitário",
    "Order.Table.Th.CheckoutDate": "Data de finalização da compra",
    "Order.Table.Th.Action": "Ação",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Ver informações do cliente",
    "CustomerModal.Modal.Title.CustomerInfo": "Informações do cliente",
    "CustomerModal.Modal.Body.CustomerInfo": "Informações do cliente",
    "CustomerModal.Modal.Body.Address": "Endereço",

    "OrderLine.Button.Checkout": "Finalização da compra",
    "OrderLine.Tooltip.PreviewInvoice": "Pré-visualizar a fatura",
    "OrderLine.Tooltip.PaymentReceipt": "Recibo de pagamento",
    "OrderLine.Tooltip.TrackOrder": "Seguimento da encomenda",
    "OrderLine.Tooltip.OrderProcessing": "Processamento da encomenda",
    "OrderLine.Tooltip.OrderCancelled": "Encomenda cancelada",
    "OrderLine.Tooltip.PleaseConfirm": "Clique para confirmar o pagamento da sua encomenda",
    "OrderLine.Label.PurchaseEtsy": "Compra no Etsy",
    "OrderLine.Paying": "A efetuar pagamento",
    "OrderLine.Failure": "Falha",
    "OrderLine.Sample": "Amostra",
    "OrderLine.ErrorOrderRow":
      "Não foi possível encontrar a lista encomendada no sistema! Contacte a assistência técnica!",

    "ConfirmationModal.Label.OrderDetails": "Detalhes da encomenda",
    "ConfirmationModal.Label.Total": "Total",
    "ConfirmationModal.Label.Shipping": "Envio",
    "ConfirmationModal.Label.TransactionFees": "Taxas de transação",
    "ConfirmationModal.Tooltip.TransactionFees":
      "As taxas de transação incluem as taxas cobradas pelo Stripe, o prestador responsável pelas ordens de pagamento do Spocket, bem como as taxas de serviço.",
    "ConfirmationModal.Modal.OrderConfirmation": "Confirmação da encomenda",
    "ConfirmationModal.Modal.Alert.Attention": "Atenção!",
    "ConfirmationModal.Modal.Alert.Description": "Esta encomenda contém uma nota do cliente.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Está prestes a fazer uma encomenda para",
    "ConfirmationModal.Modal.Table.Th.Name": "Nome",
    "ConfirmationModal.Modal.Table.Th.Price": "Preço",
    "ConfirmationModal.Modal.Table.Th.Qty": "QTD",
    "ConfirmationModal.Modal.Table.Th.Total": "Total",
    "ConfirmationModal.Modal.Label.Note": "Nota para o fornecedor",
    "ConfirmationModal.Modal.PlaceHolder.Note": "A sua mensagem",
    "ConfirmationModal.Button.PlaceOrder": "Fazer encomenda",

    "PaymentCompleteModal.PaymentCompleted": "Pagamento concluído",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Obrigado pelo pagamento. O fornecedor foi notificado e a encomenda será processada em breve. O estado da encomenda será atualizado e o número de seguimento será adicionado à sua página de encomendas.",
    "PaymentCompleteModal.PaymentIncomplete": "A sua encomenda está incompleta!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "O seu banco exige a confirmação do pagamento para continuar",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Utilize este link para confirmar o pagamento.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Assim que o pagamento tiver sido confirmado, o fornecedor será notificado e a sua encomenda será processada normalmente. As atualizações de estado e seguimento serão adicionadas à sua página de encomendas. Volte a carregar a página após a confirmação.",

    "SetYourGoalsModal.Title": "Número de produtos adicionados à loja",
    "SetYourGoalsModal.Subtitle": "Normalmente, uma loja lança 25 produtos antes de obter vendas",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} produto",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} produtos",
    "SetYourGoalsModal.Button": "Definir o meu objetivo e começar o dropshipping ou a venda direta",

    "YourGoalsBanner.Container.Description": "Os meus objetivos",
    "YourGoalsBanner.Header.Title": "Iniciar uma atividade",
    "YourGoalsBanner.Header.Subtitle": "Prepare a sua loja",
    "YourGoalsBanner.Progress.Completed": "Concluído",
    "YourGoalsBanner.ProductPushedCount": "<0>Objetivo: {{goalsNumberOfProduct}}</0> produtos lançados",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Aderir ao Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Primeira pesquisa",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} Produto lançado",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produtos lançados",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} Produto importado",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produtos importados",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Dropshipping automatizado do AliExpress",
    "Aliscraper.Subtitle":
      "Importe milhares de produtos do AliExpress para a sua loja de comércio eletrónico com um só clique e automatize o seu negócio de dropshipping ou venda direta.",
    "Aliscraper.Features.Feature1": "Importação rápida com um só clique",
    "Aliscraper.Features.Feature2": "Processe instantaneamente centenas de encomendas com o Spocket",
    "Aliscraper.Features.Feature3": "Apoio ao cliente 24/7",
    "Aliscraper.Button": "Fazer download da extensão",
    "Aliscraper.Chrome": "Disponível na Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Conecte a sua loja",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Selecione a sua plataforma para começar",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Nome da loja inválido",
    "ConnectStoreModal.ShopifyForm.Placeholder": "p. ex. MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.ShopifyForm.Button": "Conecte a loja",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "A sua chave de autenticação:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Como conectar a sua loja ao Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Instale o plugin do Spocket a partir de",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "aqui",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Ative o plugin na página dos seus plugins",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Aceda ao Painel WordPress > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      'Insira a sua chave de autenticação (listada acima) e clique em "Guardar"',
    "ConnectStoreModal.WoocommerceInstruction.Button": "Já está",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.WixForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.WixForm.Button": "Conecte a loja",
    "ConnectStoreModal.FelexForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.FelexForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.FelexForm.Button": "Conecte a loja",
    "ConnectStoreModal.BigcommerceForm.UrlText": "URL da loja Bigcommerce",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Nome da loja inválido",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "p. ex. MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.BigcommerceForm.Button": "Conecte a loja",
    "ConnectStoreModal.EcwidForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.EcwidForm.Button": "Conecte a loja",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.WhiteLabelForm.Button": "Conecte a loja",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.SquarespaceForm.Button": "Conecte a loja",
    "ConnectStoreModal.SquareForm.FooterText": "Não tem uma loja?",
    "ConnectStoreModal.SquareForm.FooterLink": "Experimente gratuitamente",
    "ConnectStoreModal.SquareForm.Button": "Conecte a loja",
    "ConnectStoreModal.FelexButton.Explanation":
      "Uma plataforma de criação de loja virtual onde os empreendedores podem lançar uma loja de dropshipping em menos de 10 minutos.",
    "ConnectStoreModal.DontHaveStore":  "Não tem uma loja?",
    "ConnectStoreModal.TryForFree": "Experimente gratuitamente",

    "PaymentTab.Document.Title": "Configurações - Automação - Spocket",
    "PaymentTab.Title.Billing": "Cobrança",
    "PaymentTab.Tooltip.Billing":
      "Os cartões de crédito são utilizados para processamento de encomendas e pagamento de subscrições",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Interromper/alterar o plano",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Alterar o plano",
    "PaymentTab.AccordionItem.Button.Downgrade": "Passar para uma categoria inferior",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Interromper o plano",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "A sua loja já foi interrompida anteriormente",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Interromper o plano",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Pode interromper temporariamente a sua conta Spocket e voltar a qualquer momento sem perder nenhum dado.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Lembrem-me mais tarde",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Lembrem-me mais tarde",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 dias antes",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Guardar as minhas vantagens e lembrar-me <1>{{daysBefore}}</1> da renovação da minha adesão",
    "RemindMeLaterModal.Header.ReminderSet": "Lembrete criado",
    "RemindMeLaterModal.Body.DaysBefore": "enviar email 3 dias antes",
    "RemindMeLaterModal.Body.ReminderSet":
      "O seu lembrete foi criado e <1>{{daysBefore}}</1> as suas vantagens serão renovadas. Entretanto, continue a sua viagem empreendedora.",
    "RemindMeLaterModal.Button.Continue": "Continuar",
    "PauseStoreModal.Title.PauseYourStore": "Interromper a loja",
    "PauseStoreModal.Description.PauseYourStore":
      "Aproveite esta oferta única e reduza o preço do seu plano em 60% nos próximos 30 dias",
    "PauseStoreModal.Button.PauseStore": "Interromper a loja",
    "PauseStoreModal.Description.StorePaused":
      "A sua loja foi interrompida e a subscrição do próximo mês será reduzida em 60%. Tenha em atenção que os pagamentos regulares serão posteriormente retomados.",
    "ConfirmPasswordModal.Title": "Por favor, confirme a sua palavra-passe",
    "ConfirmPasswordModal.SubTitle": "Por motivos de segurança, confirme a sua palavra-passe",
    "ConfirmPasswordModal.Input.Placeholder": "A sua palavra-passe",
    "ConfirmPasswordModal.Button.Cancel": "Cancelar",
    "ConfirmPasswordModal.Button.Confirm": "Confirmar",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "pt",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Cresça a um ritmo mais rápido com o Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Selecione o seu ciclo de cobrança",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Os primeiros",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "dias ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final": "são gratuitos. Cancele durante ou após o teste",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "A sua primeira fatura está prevista para",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "A sua primeira fatura será paga após a confirmação",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "A cobrança ocorrerá mensalmente",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Mensalmente ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/mês",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Anualmente ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/mês",
    "UpgradeConfirmationModal.YearlyTab.Discount": "de desconto",
    "UpgradeConfirmationModal.TaxRates.Text": "imposto sobre as vendas",
    "UpgradeConfirmationModal.TaxRates.Period": "mês",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Para pagar agora",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/mês X 12 meses",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "As suas poupanças: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/ano",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "equivalente a",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% de desconto",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Os planos anuais não incluem um período de teste",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "A cobrança será imediatamente efetuada na íntegra",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Pagar com cartão de crédito/débito",
    "UpgradeConfirmationModal.PayPalTab.Info": "Pagar com PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Dados de pagamento ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Obter o meu teste gratuito",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Atualizar para ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Mais de 50.000 empresários confiam em nós",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "A classificação atribuída pelos nossos clientes tem sido consistentemente 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "Encriptação SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Garantimos a 100% a segurança de todos os seus dados de pagamento.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "Mais de 50.000",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Mais de 50.000 empresários confiam em nós",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "A classificação atribuída pelos nossos clientes tem sido consistentemente 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "Encriptação SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Garantimos a 100% a segurança de todos os seus dados de pagamento.",
    "UpgradeConfirmationModal.Agreement.Intro": "Ao prosseguir, o utilizador concorda com ",
    "UpgradeConfirmationModal.Agreement.Terms": "Condições de utilização",
    "UpgradeConfirmationModal.Agreement.Privacy": "Política de privacidade",
    "UpgradeConfirmationModal.Agreement.Refund": "Política de reembolso",
    "UpgradeConfirmationModal.Agreement.Middle": "e",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Política de cancelamento",

    "OrderDetails.Title": "Detalhes da encomenda",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Cartão de crédito que termina em",
    "CreditCard.Update.Button": "Atualizar cartão",
    "CreditCard.AddCreditCard.Text":
      "Ainda não foi adicionado nenhum cartão de crédito. Adicione um cartão de crédito, utilizando o botão abaixo, para que as suas encomendas possam ser processadas automaticamente",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Adicionar cartão de crédito",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Obter produtos",
    "Sidebar.WinningProducts.Variant": "Novo",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Centro de ajuda",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Configurações",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Estamos aqui para o ajudar!",
    "TutorialModal.WhatIsSpocket": "O que é o Spocket?",
    "TutorialModal.DiscoverProducts": "Descobrir produtos",
    "TutorialModal.ProductCustomization": "Personalização de produtos",
    "TutorialModal.OrderProcessing": "Processamento da encomenda",
    "TutorialModal.Settings": "Configurações",
    "TutorialModal.GetHelp": "Obter ajuda",
    "TutorialModal.JoinTheCommunity": "Aderir à comunidade",

    "NotificationCenter.Header": "Centro de notificação",
    "NotificationCenter.Footer": "Ver todas as notificações",
    "NotificationCenter.NoNotification": "Não há nada aqui!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English
    
    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English
    
    "Products.Title.Head": "Os meus produtos - Spocket",
    "Products.Title.Header": "Os meus produtos",
    "Products.Loading.Top": "A sua lista de produtos está a carregar!",
    "Products.Loading.Bottom": "Por favor, aguarde enquanto reavemos os seus produtos.",
    "Products.Empty.Search": "Não foram encontrados produtos!",
    "Products.Empty.Top": "A sua lista de produtos está vazia!",
    "Products.Empty.Bottom":
      "Dirija-se à página de pesquisa para começar a adicionar produtos à sua lista de importação.",
    "Products.Empty.Button.Search": "Procurar produtos",
    "Products.Empty.Button.How": "Como encontrar produtos",
    "Products.Alert.Removed": "Produto removido",

    "Products.Search.Label": "Procure os seus produtos",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Procurar",

    "ProductListItem.Inactive.Tooltip": "Este produto já não está disponível por parte do fornecedor",
    "ProductListItem.Inactive.Title": "Inativo",
    "ProductListItem.OutOfStock.Tooltip": "Todas as variações deste produto estão esgotadas",
    "ProductListItem.OutOfStock.Title": "Esgotado",
    "ProductListItem.InventoryCount.Singular": "peça",
    "ProductListItem.InventoryCount.Plural": "peças",
    "ProductListItem.InventoryCount.End.Singular": "restante",
    "ProductListItem.InventoryCount.End.Plural": "restantes",
    "ProductListItem.Button.View": "Ver na loja",
    "ProductListItem.Button.Remove": "Remover da loja",

    "ImportList.Title.Head": "Lista de importação - Spocket",
    "ImportList.Title.Header": "A minha Lista de importação",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "A sua lista de importação está a carregar!",
    "ImportList.Loading.Bottom": "Por favor, aguarde enquanto reavemos os seus produtos.",
    "ImportList.Empty.Button.Search": "Procurar produtos",
    "ImportList.Empty.Top": "A sua lista de importação está vazia!",
    "ImportList.Empty.Bottom":
      "Dirija-se à página de pesquisa para começar a adicionar produtos à sua lista de importação.",
    "ImportList.Empty.Button.How": "Como personalizar os produtos",
    "ImportList.Alert.Pushed": "Este produto foi lançado para a sua loja:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Search.Label": "Procurar nos produtos importados",
    "ImportList.Search.Placeholder": "Procure os seus produtos",
    "ImportList.Search.Button": "Procurar",
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "A lançar produtos da página atual",
    "PushAllModal.Button.Inactive": "Lançar a página atual",
    "PushAllModal.Modal.Text":
      "Tem a certeza de que pretende lançar todos os produtos da página atual? Todos os produtos válidos da página atual serão lançados para a sua loja.",
    "PushAllModal.Modal.Push": "Lançar todos",
    "PushAllModal.Modal.Cancel": "Cancelar",

    "RemoveAllModal.Button.Active": "A remover produtos da página atual",
    "RemoveAllModal.Button.Inactive": "Remover Página atual",
    "RemoveAllModal.Modal.Text":
      "Tem a certeza de que pretende remover todos os produtos da página atual? Todos os produtos da página atual serão removidos da lista de importação.",
    "RemoveAllModal.Modal.Remove": "Remover todos",
    "RemoveAllModal.Modal.Cancel": "Cancelar",

    "ImportListItem.Tab.Product": "Produto",
    "ImportListItem.Tab.Description": "Descrição",
    "ImportListItem.Tab.Variants": "Variantes",
    "ImportListItem.Tab.Images": "Imagens",
    "ImportListItem.Remove.Active": "A remover o produto",
    "ImportListItem.Remove.Inactive": "Remover o produto",
    "ImportListItem.Push.Active": "A lançar para a loja",
    "ImportListItem.Push.Inactive": "Lançar para a loja",
    "ImportListItem.Save.Button": "Guardar",
    "ImportListItem.Alert.Removed": "Este produto foi removido da sua lista de importação: ",
    "ImportListItem.Alert.RemoveFailed": "Algo não correu bem ao tentar remover: ",
    "ImportListItem.Alert.CompareAtPrice": "O preço de comparação deve ser superior ao preço de venda para: ",
    "ImportListItem.Alert.OneActiveVariation": "Este produto necessita de pelo menos uma variante ativa: ",
    "ImportListItem.Alert.Saved": "Este produto foi guardado: ",
    "ImportListItem.Alert.ErrorPushing": "Algo não correu bem ao lançar para a sua loja",
    "ImportListItem.Alert.ErrorSaving": "Algo não correu bem ao guardar a sua loja",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Conecte a sua loja para lançar produtos: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Necessita de pelo menos uma variação ativa",
    "ImportListItem.Tooltip.Unavailable": "Este produto não está de momento disponível para compra",
    "ImportListItem.Tooltip.Unsaved": "Existem alterações que não foram guardadas",
    "ImportListItem.Tooltip.Profit": "Atenção: O seu lucro bruto neste produto é negativo",
    "ImportListItem.Tooltip.Unpurchasable":
      "Poderá guardar este produto, embora ele não esteja de momento disponível para compra",
    "ImportListItem.Variants.ShowShippingPrices": "Mostrar preços de envio",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Nome do produto",
    "ItemInfo.ProductTags.Label": "Marcadores do produto",
    "ItemInfo.ProductTags.Remove": "Remover todos os marcadores",
    "ItemInfo.ProductTags.Placeholder": "Adicionar um marcador novo",
    "ItemInfo.ProductType.Label": "Tipo de produto",
    "ItemInfo.Collection.Label": "Coleção",
    "ItemInfo.Collection.Placeholder": "Selecionar",
    "ItemInfo.Collection.Option": "Selecionar",
    "ItemInfo.SquareLocation.Option": "Selecionar",

    "ItemVariantList.Modal.Title": "Dados do envio",
    "ItemVariantList.Modal.Intro": "Envio de: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Este artigo não é enviado internacionalmente.",
    "ItemVariantList.Modal.DoesNotShipTo": "Não é enviado para: ",
    "ItemVariantList.Table.Head.Destination": "Destino",
    "ItemVariantList.Table.Head.Cost": "Custo",
    "ItemVariantList.Table.Head.Time": "Prazo de entrega (dias)",
    "ItemVariantList.Table.Body.Domestic": "Nacional",
    "ItemVariantList.Table.Body.International": "Internacional",
    "ItemVariantList.VariantsTable.ShippingPrice": "Preço de envio ",
    "ItemVariantList.VariantsTable.Image": "Imagem",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Inventário",
    "ItemVariantList.VariantsTable.Price": "Preço",
    "ItemVariantList.VariantsTable.SalesPrice": "Preço de venda ",
    "ItemVariantList.VariantsTable.Profit": "Lucro",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Preço de comparação",
    "ItemVariantList.VariantsTable.Unavailable": "Este produto não está de momento disponível para compra",
    "ItemVariantList.VariantsTable.Head": "Todas as variantes deste produto são exibidas aqui.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ex.: 40%",
    "ItemVariantList.Alert.MaxVariants": "O Shopify não aceita mais de 100 variações.",

    "ItemVariantList.ContextPopover.Title": "Definir lucro",
    "ItemVariantList.ContextPopover.Apply": "Aplicar",

    "ProductVariation.Description":
      "Pode encomendar amostras de produtos diretamente no Spocket. Siga os passos abaixo para uma finalização de compra rápida e fácil.",
    "ProductVariation.Label.SelectVariant": "Selecionar variante",
    "ProductVariation.Label.SelectTheProductVariation": "Selecionar a variação do produto",
    "ProductVariation.Qty": "Qtd",
    "ProductVariation.Label.NotesForTheSupplier": "Notas para o fornecedor",

    "InputAddress.Label.FirstName": "Nome próprio",
    "InputAddress.PlaceHolder.FirstName": "Inserir o nome próprio",
    "InputAddress.Label.LastName": "Apelido",
    "InputAddress.PlaceHolder.LastName": "Inserir o apelido",
    "InputAddress.Label.StreetAddress": "Endereço postal",
    "InputAddress.Label.Country": "País",
    "InputAddress.PlaceHolder.Country": "Procurar país",
    "InputAddress.Label.State": "Estado",
    "InputAddress.Placeholder.State": "Procurar estado",
    "InputAddress.Label.City": "Cidade",
    "InputAddress.PlaceHolder.City": "Inserir cidade",
    "InputAddress.Label.Zip": "Código postal",
    "InputAddress.PlaceHolder.Zip": "Inserir o código postal",
    "InputAddress.Label.Phone": "Número de telefone",
    "InputAddress.Placeholder.Phone": "Inserir número de telefone",

    "OrderReview.OrderDetails": "Detalhes da encomenda",
    "OrderReview.Unit": "{{count}} Unidade",
    "OrderReview.Unit_plural": "{{count}} Unidades",
    "OrderReview.ShippingAddress": "Endereço de envio",
    "OrderReview.PaymentMethod": "Modo de pagamento",
    "OrderReview.Amounts": "Montantes",
    "OrderReview.Subtotal": "Subtotal",
    "OrderReview.ShippingCost": "Custo de envio",
    "OrderReview.TransactionFees": "Taxas de transação",
    "OrderReview.TransactionFees.ToolTips":
      "As taxas de transação incluem as taxas cobradas pelo Stripe, o prestador responsável pelas ordens de pagamento do Spocket, bem como as taxas de serviço.",
    "OrderReview.OrderTotal": "Total da encomenda",
    "OrderReview.ItemWithCount": "{{count}} artigo",
    "OrderReview.ItemWithCount_plural": "{{count}} artigos",
    "OrderReview.Placeholder.CreditCard": "Cartão de crédito que termina em {{last4}}",

    "PaymentComplete.PaymentCompleted": "Pagamento concluído",
    "PaymentComplete.PaymentCompleted.Note":
      "Obrigado pelo pagamento. O fornecedor foi notificado e a encomenda será processada em breve. O estado da encomenda será atualizado e o número de seguimento será adicionado à sua página de encomendas.",
    "PaymentComplete.PaymentIncomplete": "A sua encomenda está incompleta!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "O seu banco exige a confirmação do pagamento para continuar",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "Utilize este link para confirmar o pagamento.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Assim que o pagamento tiver sido confirmado, o fornecedor será notificado e a sua encomenda será processada normalmente. As atualizações de estado e seguimento serão adicionadas à sua página de encomendas. Volte a carregar a página após a confirmação.",

    "CreditCard.AddCreditCard.Description":
      "Ainda não foi adicionado nenhum cartão de crédito. Adicione um cartão de crédito, utilizando o botão abaixo, para que as suas encomendas possam ser processadas automaticamente",
    "CreditCard.Button.AddCreditCard": "Adicionar cartão de crédito",
    "CreditCard.Button.UpdateCard": "Atualizar cartão",
    "CreditCard.UpdateCard.Label.CreditCard": "Cartão de crédito",
    "CreditCard.PlaceHolder.CreditCard": "Cartão de crédito que termina em {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Cartão de crédito",
    "UpdateCreditCardModal.Button.UpdateCard": "Atualizar cartão",
    "UpdateCreditCardModal.Button.AddCreditCard": "Adicionar cartão de crédito",
    "UpdateCreditCardModal.Button.Cancel": "Cancelar",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Atualize os dados do seu cartão",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "A sua conta será utilizada durante o processamento de encomendas de produtos Spocket.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Condições gerais",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "para processamento de pagamentos.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Selecionar variação",
    "SampleOrderModal.Title.ShippingAddress": "Endereço de envio",
    "SampleOrderModal.Title.PaymentMethod": "Modo de pagamento",
    "SampleOrderModal.Title.Review": "Rever",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "É necessária a confirmação do pagamento!",
    "SampleOrderModal.Title.OrderPlaced": "Encomenda concluída!",
    "SampleOrderModal.Steps": "Passo {{current}} de {{total}}",
    "SampleOrderModal.FinalStep": "Último passo",
    "SampleOrderModal.ConfirmPayment": "Confirmar pagamento!",
    "SampleOrderModal.Finished": "Concluído",
    "SampleOrderModal.Footer.Continue": "Continuar",
    "SampleOrderModal.Footer.PlaceOrder": "Fazer encomenda",
    "SampleOrderModal.Footer.Close": "Encerrar",
    "SampleOrderModal.Footer.Cancel": "Cancelar",
    "SampleOrderModal.Footer.GoBack": "Voltar",

    "AdvancedFiltersModal.Title.Filters": "Filtros",
    "AdvancedFiltersModal.Title.Shipping": "Envio",
    "AdvancedFiltersModal.Label.ShipsFrom": "Envio de",
    "AdvancedFiltersModal.Label.ShipsTo": "Envio para",
    "AdvancedFiltersModal.Label.ShippingTime": "Prazo de envio",
    "AdvancedFiltersModal.Label.Popular": "Popular",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Estados Unidos",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Disponível apenas para envio para os EUA",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Qualquer dia",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 dias",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 dias",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 dias",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "Mais de 15 dias",
    "AdvancedFiltersModal.Title.ItemsCost": "Custo dos artigos",
    "AdvancedFiltersModal.Label.ItemCost": "Custo do artigo",
    "AdvancedFiltersModal.Label.ShippingCost": "Custo de envio",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Qualquer custo",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratuito",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5 USD ou menos",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15 USD ou menos",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25 USD ou menos",
    "AdvancedFiltersModal.Title.Supplier": "Fornecedor",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Fornecedores",
    "AdvancedFiltersModal.Label.TopSupplier": "Melhor fornecedor",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Fornecedores conhecidos pelos seus produtos de alta qualidade, pelo serviço excelente e por avaliações com classificação alta",
    "AdvancedFiltersModal.Title.Advanced": "Avançado",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Produtos premium",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Produtos com desconto elevado e envio rápido",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Os mais vendidos",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription":
      "Os produtos com o melhor desempenho no Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Cancelar",
    "AdvancedFiltersModal.Button.ViewResults": "Ver resultados",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Procurar país…",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Países",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Procurar fornecedor…",
    "AdvancedFiltersModal.Label.All Suppliers": "Todos os fornecedores",
    "AdvancedFiltersModal.Label.New Suppliers": "Fornecedores novos",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Qualquer dia",
    "AdvancedFilters.ShippingTime.One": "1-3 dias",
    "AdvancedFilters.ShippingTime.Four": "4-7 dias",
    "AdvancedFilters.ShippingTime.Eight": "8-14 dias",
    "AdvancedFilters.ShippingTime.Fifteen": "Mais de 15 dias",

    "AdvancedFilters.ShippingCost.Free": "Envio gratuito",
    "AdvancedFilters.ShippingCost.Five": "Envio com valor inferior a 5 USD",
    "AdvancedFilters.ShippingCost.Fifteen": "Envio com valor inferior a 15 USD",
    "AdvancedFilters.ShippingCost.TwentyFive": "Envio com valor inferior a 25 USD",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Apagar tudo",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Envio",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Procurar",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Envio de",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Envio para",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Fornecedor",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Ordenar por",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Custo do artigo",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Melhores fornecedores",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Faturas personalizadas",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Os mais vendidos",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "de {{from}} para {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Inserir palavras-chave…",
    "Search.Header.Button.Search": "Procurar",
    "Search.Header.Button.Filters": "Filtros",

    "Navigation.UpgradeButton.TryPro": "Experimente GRATUITAMENTE o Spocket Pro",
    "Navigation.UpgradeButton.TryEmpire": "Experimente GRATUITAMENTE o Spocket EMPIRE",
    "Navigation.UpgradeButton.TryUnicorn": "Experimente GRATUITAMENTE o Spocket Unicorn",

    "CategoriesCards.Label.MoreCategories": "Mais categorias",

    "StaticCategories.WomensClothing": "Roupa de mulher",
    "StaticCategories.WomensClothing.Activewear": "Roupa desportiva",
    "StaticCategories.WomensClothing.Bodysuits": "Bodies",
    "StaticCategories.WomensClothing.Dresses": "Vestidos",
    "StaticCategories.WomensClothing.JacketsCoats": "Blusões e casacos",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Macacões",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lingerie e roupa interior",
    "StaticCategories.WomensClothing.MatchingSets": "Conjuntos a condizer",
    "StaticCategories.WomensClothing.Pants": "Calças",
    "StaticCategories.WomensClothing.Shorts": "Calções",
    "StaticCategories.WomensClothing.Skirts": "Saias",
    "StaticCategories.WomensClothing.Sleepwear": "Camisas de noite e pijamas",
    "StaticCategories.WomensClothing.Socks": "Meias",
    "StaticCategories.WomensClothing.SweatersHoodies": "Sweaters e camisolas com capuz",
    "StaticCategories.WomensClothing.Swimwear": "Fatos de banho",
    "StaticCategories.WomensClothing.TankTops": "Camisolas de alças",
    "StaticCategories.WomensClothing.TopsBlouses": "Tops e blusas",
    "StaticCategories.WomensClothing.Tshirts": "T-shirts",

    "StaticCategories.JewelryWatches": "Joias e relógios",
    "StaticCategories.JewelryWatches.Bracelets": "Pulseiras",
    "StaticCategories.JewelryWatches.Earrings": "Brincos",
    "StaticCategories.JewelryWatches.Necklaces": "Colares",
    "StaticCategories.JewelryWatches.OtherAccessories": "Outros acessórios",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Pingentes, pedras e amuletos",
    "StaticCategories.JewelryWatches.Rings": "Anéis",
    "StaticCategories.JewelryWatches.Sets": "Conjuntos",
    "StaticCategories.JewelryWatches.Watches": "Relógios",

    "StaticCategories.TechAccessories": "Acessórios de tecnologia",
    "StaticCategories.TechAccessories.AudioVideo": "Áudio e vídeo",
    "StaticCategories.TechAccessories.CasesCovers": "Estojos e capas",
    "StaticCategories.TechAccessories.Lighting": "Iluminação",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Acessórios para telemóveis e laptops",
    "StaticCategories.TechAccessories.Mousepads": "Tapetes de rato",
    "StaticCategories.TechAccessories.Novelty": "Novidades",

    "StaticCategories.KidsBabies": "Criança e bebé",
    "StaticCategories.KidsBabies.BabyClothing": "Roupa de bebé",
    "StaticCategories.KidsBabies.Bathing": "Banho",
    "StaticCategories.KidsBabies.BlanketsPillows": "Cobertores e almofadas",
    "StaticCategories.KidsBabies.CapsHeadbands": "Bonés e fitas para a cabeça",
    "StaticCategories.KidsBabies.Footwear": "Calçado",
    "StaticCategories.KidsBabies.Furniture": "Mobiliário",
    "StaticCategories.KidsBabies.KidsClothing": "Roupa de criança",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Parentalidade e acessórios",

    "StaticCategories.Toys": "Brinquedos",
    "StaticCategories.Footwear": "Calçado",
    "StaticCategories.Footwear.Boots": "Botas",
    "StaticCategories.Footwear.Flats": "Sapatos rasos",
    "StaticCategories.Footwear.Heels": "Sapatos de salto alto",
    "StaticCategories.Footwear.Mens": "Homem",
    "StaticCategories.Footwear.Sandals": "Sandálias",
    "StaticCategories.Footwear.Slippers": "Pantufas",
    "StaticCategories.Footwear.SneakersRunners": "Ténis e sapatilhas",

    "StaticCategories.BathBeauty": "Banho e beleza",
    "StaticCategories.BathBeauty.Bodycare": "Cuidados corporais",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Difusores, óleos e velas",
    "StaticCategories.BathBeauty.Haircare": "Cuidados capilares",
    "StaticCategories.BathBeauty.Healthcare": "Cuidados de saúde",
    "StaticCategories.BathBeauty.Makeup": "Maquilhagem",
    "StaticCategories.BathBeauty.Nails": "Unhas",
    "StaticCategories.BathBeauty.Skincare": "Cuidados cutâneos",
    "StaticCategories.BathBeauty.TowelsRobes": "Toalhas e roupões",

    "StaticCategories.Pets": "Animais de companhia",
    "StaticCategories.Pets.BedsBlankets": "Camas e cobertores",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Trelas, coleiras e roupa para animais de companhia",
    "StaticCategories.Pets.Petcare": "Cuidados para animais de companhia",
    "StaticCategories.Pets.Toys": "Brinquedos",

    "StaticCategories.HomeGarden": "Casa e jardim",
    "StaticCategories.HomeGarden.Bathroom": "Casa de banho",
    "StaticCategories.HomeGarden.Clocks": "Relógios de mesa ou parede",
    "StaticCategories.HomeGarden.Drinkware": "Copos",
    "StaticCategories.HomeGarden.FoodBeverage": "Comida e bebida",
    "StaticCategories.HomeGarden.Furniture": "Mobiliário",
    "StaticCategories.HomeGarden.Garden": "Jardim",
    "StaticCategories.HomeGarden.HomeDecor": "Decoração de interiores",
    "StaticCategories.HomeGarden.HomeImprovement": "Melhorias da habitação",
    "StaticCategories.HomeGarden.Kitchen": "Cozinha",
    "StaticCategories.HomeGarden.Lighting": "Iluminação",
    "StaticCategories.HomeGarden.Organization": "Arrumação",
    "StaticCategories.HomeGarden.StationeryCrafts": "Papelaria e lavores",
    "StaticCategories.HomeGarden.TextilesPillows": "Têxteis e almofadas",
    "StaticCategories.HomeGarden.Tools": "Ferramentas",

    "StaticCategories.Accessories": "Acessórios",
    "StaticCategories.Accessories.Belts": "Cintos",
    "StaticCategories.Accessories.Cufflinks": "Botões de punho",
    "StaticCategories.Accessories.Hats": "Chapéus",
    "StaticCategories.Accessories.Keychains": "Porta-chaves",
    "StaticCategories.Accessories.ScarvesBandanas": "Lenços e bandanas",
    "StaticCategories.Accessories.Sunglasses": "Óculos de sol",

    "StaticCategories.MensClothing": "Roupa de homem",
    "StaticCategories.MensClothing.Activewear": "Roupa desportiva",
    "StaticCategories.MensClothing.DressShirts": "Camisas formais",
    "StaticCategories.MensClothing.JacketsCoats": "Blusões e casacos",
    "StaticCategories.MensClothing.Pants": "Calças",
    "StaticCategories.MensClothing.Shorts": "Calções",
    "StaticCategories.MensClothing.Socks": "Meias",
    "StaticCategories.MensClothing.SweatersHoodies": "Sweaters e camisolas com capuz",
    "StaticCategories.MensClothing.Swimwear": "Fatos de banho",
    "StaticCategories.MensClothing.TankTops": "Camisolas de alças",
    "StaticCategories.MensClothing.Tshirts": "T-shirts",
    "StaticCategories.MensClothing.Underwear": "Roupa interior",

    "StaticCategories.SportsOutdoors": "Desporto e atividades ao ar livre",
    "StaticCategories.SportsOutdoors.Activewear": "Roupa desportiva",
    "StaticCategories.SportsOutdoors.Biking": "Ciclismo",
    "StaticCategories.SportsOutdoors.Camping": "Campismo",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Equipamento e acessórios",
    "StaticCategories.SportsOutdoors.Fishing": "Pesca",

    "StaticCategories.Gifts": "Presentes",
    "StaticCategories.Gifts.Adults": "Adultos",
    "StaticCategories.Gifts.Funny": "Engraçados",
    "StaticCategories.Gifts.Mugs": "Canecas",
    "StaticCategories.Gifts.Novelty": "Novidades",
    "StaticCategories.Gifts.Personalized": "Personalizados",
    "StaticCategories.Gifts.Religious": "Religiosos",

    "StaticCategories.Automotive": "Automóvel",
    "StaticCategories.BagsWallets": "Malas e carteiras",
    "StaticCategories.Seasonal": "Sazonal",
    "StaticCategories.Seasonal.Christmas": "Natal",
    "StaticCategories.Seasonal.FathersDay": "Dia do Pai",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Dia da Mãe",

    "StaticCategories.FestivalsParties": "Celebrações e festas",
    "StaticCategories.FestivalsParties.PartySupplies": "Artigos de festa",
    "StaticCategories.FestivalsParties.Wedding": "Casamentos",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Envio rápido dos EUA",
    "QuickFilters.Label.Under5": "Envio dos EUA com valor inferior a 5 USD",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Ordenar por",
    "SortBy.DropDown.Item.Relevance": "Relevância",
    "SortBy.DropDown.Item.PriceLowToHigh": "Preço: do mais baixo para o mais alto",
    "SortBy.DropDown.Item.PriceHighToLow": "Preço: do mais alto para o mais baixo",
    "SortBy.DropDown.Item.Newest": "Mais recente",
    "SortBy.DropDown.Item.ShippingTime": "Prazo de envio",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Página inicial",
    "Breadcrumb.Item.Search": "procurar",

    "UpgradeModal.Modal.Title": "Selecionar um plano de preços",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English

    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Mensalmente",
    "Plans.ToggleSection.Yearly": "Anualmente",
    "Plans.ToggleSection.DiscountInitial": "Receba até",
    "Plans.ToggleSection.DiscountSingular": "Mês",
    "Plans.ToggleSection.DiscountPlural": "Meses",
    "Plans.ToggleSection.DiscountFinal": "de desconto",
    "Plans.ProrationCost.TextInitial": "Atualmente, o seu pagamento é de",
    "Plans.ProrationCost.TextFinal": "/ano Adira ao Empire por apenas mais um",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Comece a obter mais vendas",
    "Card.DescriptionVariantType.Professional": "Aumente as vendas",
    "Card.DescriptionVariantType.Empire": "Maximize as vendas",
    "Card.DescriptionVariantType.Unicorn": "Faça a gestão das vendas em escala",
    "Card.DescriptionVariantType.Period": "mês",
    "Card.DescriptionVariantType.PricePeriod": "/mês",

    "Card.DescriptionVariantType.AnnualDescription": "cobrança anual de {{value}} USD",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "O seu plano atual",
    "Card.DescriptionVariantType.Button.Start": "Iniciar agora",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Faturação com marca",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Obter produtos",
    "Card.DescriptionVariantType.SupplierSourcing": "Aquisição de fornecedores",
    "Card.DescriptionVariantType.ProductRequests": "Pedido de produtos",
    "Card.DescriptionVariantType.BulkCheckout": "Finalização de um grande volume de compras",
    "Card.DescriptionVariantType.Special": "especial",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "A sua subscrição do Spocket terminou.",
    "ReactivationBanner.InfoSubtitle": "Reative a sua",
    "ReactivationBanner.CheckList.HighQuality": "Alta qualidade",
    "ReactivationBanner.CheckList.Curated": "Supervisionado",
    "ReactivationBanner.CheckList.FastShipping": "Envio rápido",
    "ReactivationBanner.CheckList.FromUSAndEU": "A partir dos EUA e da UE",
    "ReactivationBanner.TextByPlan.Starter.Description": "Deixará de ser capaz de <1>lançar produtos</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Conta Spocket",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Comece já a lançar produtos para a sua loja",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Deixará de ser capaz de <1>lançar produtos</1> ao vivo ou utilizar ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Produtos premium",
    "ReactivationBanner.TextByPlan.Others.Title": "Conta premium",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Aceda a produtos premium que são",
    "ReactivationBanner.Button": "Reativar minha conta AGORA",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Mais lançamentos de produtos",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Encomendas ilimitadas",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Faturação com marca",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Produtos premium",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Ofertas exclusivas",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Apoio por chat e telefone",
    "UpgradeReasonModal.Modal.Title": "O que pretende alcançar com o Spocket?",
    "UpgradeReasonModal.Modal.Question": "O que o levou a optar pela atualização?",
    "UpgradeReasonModal.Modal.Button": "Começar a utilizar o Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Isso ajuda-nos a melhorar a sua experiência",

    "UpgradeSuccessModal.Col.One.A": "Parabéns",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Desbloqueou todas as ferramentas de que necessita para criar com sucesso um negócio de dropshipping ou venda direta.",
    "UpgradeSuccessModal.Col.Two.B": "Agora pode ter ",
    "UpgradeSuccessModal.Col.Two.C": " Divirta-se a Spocketear!",
    "UpgradeSuccessModal.Button": "Continuar",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 mês",
    "Promotion.TrialDaysNormalizer.28days": "4 semanas",
    "Promotion.TrialDaysNormalizer.21days": "3 semanas",
    "Promotion.TrialDaysNormalizer.14days": "2 semanas",
    "Promotion.TrialDaysNormalizer.7days": "1 semana",
    "Promotion.TrialDaysNormalizer.1day": "1 dia",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} dias",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Experimente gratuitamente",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Experimente agora →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Idioma",

    "WhatYouWillLoseModal.Button.Offer": "Prosseguir",
    "WhatYouWillLoseModal.Button.Downgrade": "Desisto. Passar a minha conta para uma categoria inferior",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Perderá todos os <1>{{product_count}} produtos importados</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ outros {{product_count}}",
    "WhatYouWillLoseModal.ModalWrapper.Header": "já está a desistir?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Receba 50% de desconto",
    "WhatYouWillLoseModal.ModalWrapper.Title":
      "Continue o seu percurso empreendedor durante os próximos 3 meses.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Queremos que seja mais uma história de sucesso. Sabemos que já investiu muito tempo. Por isso, continue. Estamos todos a torcer por si. Equipa Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Parabéns, acabámos de adicionar um desconto de 50% ao seu plano para os próximos 3 meses!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Continuar",

    "DowngradeReasonModal.DowngradeSection": "Como podemos fazer com que o Spocket funcione melhor para si?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Queremos a sua opinião sobre tudo (100 caracteres, no mínimo)",
    "DowngradeReasonModal.DowngradeSection.Tooltip":
      "Por favor, escreva o seu comentário (100 caracteres, no mínimo)",
    "DowngradeReasonModal.Button.Downgrade": "Passar para uma categoria inferior",
    "DowngradeReasonModal.ModalWrapper.Header": "Passar {{storeName}} para uma categoria inferior?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Contacte-nos",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Cancelar",

    "DowngradeSuccessModal.Header": "Passou com sucesso para uma categoria inferior",
    "DowngradeSuccessModal.Body":
      "Obrigado pelo seu comentário. Estamos constantemente a aperfeiçoar o Spocket. Esperamos que nos dê outra oportunidade em breve!",

    "Settings.title": "Configuração",
    "Settings.buttonSaveChanges": "Guardar alterações",
    "Settings.buttonSaveAndPreview": "Guardar e pré-visualizar",
    "Settings.PricingPlans.Title": "Planos de preços",

    "Settings.MenuTab.Plans": "Planos",
    "Settings.MenuTab.Account": "Conta",
    "Settings.MenuTab.BrandedInvoicing": "Faturação com marca",
    "Settings.MenuTab.Billing": "Cobrança",
    "Settings.MenuTab.GlobalPricingRules": "Regras globais de determinação de preços",
    "Settings.MenuTab.Security": "Segurança",

    "AccountTab.documentTitle": "Configurações - Conta - Spocket",
    "AccountTab.title": "Configuração da conta",
    "AccountTab.shopName": "Nome da loja",
    "AccountTab.email": "Email",
    "AccountTab.shopUrl": "URL da loja",
    "AccountTab.defaultCurrency": "Moeda predefinida",
    "AccountTab.yourPlan": "O seu plano",
    "AccountTab.accountNotActive": "Neste momento, a sua conta não está ativa",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Esta funcionalidade será adicionada em breve! Enquanto isso, estabeleça ligação através da app Spocket Shopify!",
    "AccountTab.alertAccountSettingsUpdated": "Configurações da conta atualizadas",
    "AccountTab.alertInvalidShopUrl": "URL da loja inválido",
    "AccountTab.productsImported": "Produtos importados",
    "AccountTab.products": "Produtos",
    "AccountTab.premiumProducts": "Produtos premium",
    "AccountTab.total": "total",
    "AccountTab.signOut": "Terminar sessão",
    "AccountTab.limitReachedProductsAndPremium": "Foi alcançado o limite de produtos e produtos premium.",
    "AccountTab.limitReachedProducts": "Foi alcançado o limite de produtos.",
    "AccountTab.limitReachedPremium": "Foi alcançado o limite de produtos premium.",
    "AccountTab.buttonSwitchToAnnualPlan": "Mudar para o plano anual",
    "AccountTab.off30Percent": "30% de desconto",
    "AccountTab.off40Percent": "40% de desconto",
    "AccountTab.off45Percent": "45% de desconto",
    "AccountTab.sellMoreWithEmpirePlan": "Venda mais com o plano Empire",
    "AccountTab.tooltipCurrency":
      "A sua moeda é gerida através da sua conta da loja. Os preços de todos os seus produtos importados são convertidos para essa moeda.",
    "AccountTab.shopNotConnectedYet": "A sua loja ainda não foi conectada a esta conta.",
    "AccountTab.connectYourShop": "Conecte a sua loja",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Configurações - Faturação - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Configurações da fatura guardadas!",
    "InvoiceTab.shopLogo": "Logótipo da loja",
    "InvoiceTab.contactEmail": "Email de contacto",
    "InvoiceTab.phoneNumber": "Número de telefone",
    "InvoiceTab.personalNote": "Nota pessoal",
    "InvoiceTab.tooltipPersonalNote":
      "Estabeleça uma nota pessoal para incluir em cada uma das suas faturas com marca",
    "InvoiceTab.brandedInvoicing": "Faturação com marca",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Adicione o logótipo da sua marca e uma nota pessoal a todas as encomendas de fornecedores que ofereçam faturação com marca, caso tenha um plano pago",
    "InvoiceTab.disabledInvoicing": "Incluir faturação com marca em todas as encomendas",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Desativar esta opção exclui a faturação com marca ao efetuar encomendas",

    "PricingTab.titleDefaultPricingRules": "Regras predefinidas de determinação de preços",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Regras avançadas de determinação de preços",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Atribuir cêntimos",
    "PricingTab.tooltipAssignCents":
      "Este valor será utilizado ao exibir o preço final dos seus artigos (por exemplo, se pretender que o custo do seu produto seja XX.99, insira 99 nos campos abaixo).",
    "PricingTab.Markup": "Margem de lucro",
    "PricingTab.MarkupType": "Tipo de margem de lucro",
    "PricingTab.SelectDefault": "-- Selecionar um --",
    "PricingTab.SelectPercent": "Percentagem",
    "PricingTab.SelectMultiplier": "Multiplicador",
    "PricingTab.SelectFixed": "Fixo",
    "PricingTab.titlePricingRules": "Configurações - Regras de determinação de preços - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Alternar Regras avançadas de determinação de preços",
    "PricingTab.from": "De",
    "PricingTab.to": "Para",
    "PricingTab.selectFixedAmount": "Montante fixo",
    "PricingTab.buttonRemove": "Remover",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Não é possível criar mais de uma regra com campos vazios",
    "PricingTab.costRange": "Gama de custos",
    "PricingTab.markup": "Margem de lucro",
    "PricingTab.add": "Adicionar",

    "SecurityTab.Document.Title": "Configurações - Segurança - Spocket",
    "SecurityTab.alertIncorrectPassword": "Palavra-passe incorreta",
    "SecurityTab.title": "Segurança",
    "SecurityTab.yourNewPassword": "A sua nova palavra-passe",
    "SecurityTab.repeatPassword": "Repetir palavra-passe",
    "SecurityTab.errorPleaseEnterAPassword": "Insira uma palavra-passe.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Insira a confirmação da palavra-passe.",
    "SecurityTab.errorPasswordsDoNotMatch": "As palavras-passe não coincidem",
    "SecurityTab.errorPasswordShort": "A palavra-passe deve ter 8 caracteres ou mais",

    "ListingCards.TopSupplierTag.Tooltip":
      "Os melhores fornecedores são conhecidos pelos seus produtos de alta qualidade, pelo serviço excelente e pelas classificações altas",
    "ListingCards.TopSupplierTag.Text": "Fornecedor",

    "ListingCard.OriginCountry.Intro": "Por",
    "ListingCard.OriginCountry.Tooltip": "Envio de",
    "ListingCard.Shipping.Price.Title": "Preço de envio",
    "ListingCard.Shipping.Price.Free": "GRATUITO",
    "ListingCard.Price.Intro": "Custo de catálogo",
    "ListingCard.Price.Retail": "Preço de retalho",
    "ListingCard.Shipping.Time.Title": "Prazo de envio",
    "ListingCard.Shipping.Time.Period": "dias",
    "ListingCard.PremiumIcon.Tooltip":
      "Venda produtos exclusivos, com desconto elevado e envio rápido, na sua loja",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Adicionar à Lista de importação",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Lançado para a loja",
    "ListingCard.StatusTag.Imported.Title": "Importado",
    "ListingCard.StatusTag.Imported.Text": "Este item já se encontra na sua lista de importação",
    "ListingCard.StatusTag.Pushed.Title": "Em loja",
    "ListingCard.StatusTag.Pusehd.Text": "Este item foi lançado para a sua loja",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Descrição do produto",
    "ListingModal.DetailsSection.Button.ProductVariations": "Abrir variações do produto",
    "ListingModal.DetailsSection.Button.OrderSamples": "Encomendar amostras",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Novo",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "A faturação com marca não está disponível para este produto",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "As encomendas internacionais são enviadas do nosso armazém na China",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Os artigos que estejam esgotados no armazém dos EUA podem ser enviados do nosso armazém na China",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "As encomendas podem ser enviadas em várias embalagens, a fim de reduzir o prazo geral de envio",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Conecte a sua loja para fazer uma encomenda",
    "ListingModal.DetailsSection.PushedListButton": "Lançado para a loja",
    "ListingModal.DetailsSection.RemoveListButton": "Remover da lista",
    "ListingModal.DetailsSection.ImportListButton": "Adicionar à Lista de importação",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Oferta desativada",

    "InfoSection.ShippingTime.Tooltip": "Número de dias para que o produto seja entregue após o envio.",
    "InfoSection.ShippingTime.Title": "Prazo de envio",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "A nível mundial",
    "InfoSection.ShippingExcluded.Intro": "Não é enviado para",
    "InfoSection.ShippingExcluded.Various": "Vários",
    "InfoSection.ProcessingTime.Tooltip":
      "Número de dias úteis para que o fornecedor envie e forneça o número de seguimento",
    "InfoSection.ProcessingTime.Title": "Tempo de processamento",
    "InfoSection.ProcessingTime.Period": "dias úteis",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Este produto é enviado por {{price_formatted}}, acrescido de {{incremental_price_formatted}} para cada produto adicional na mesma encomenda",
    "InfoSection.ShippingInfoLine.Period": "dias úteis",
    "InfoSection.ShippingInfoLine.Free": "GRATUITO",
    "InfoSection.ShippingInfoLine.NoShipping": "Não é enviado",

    "ReturnPolicySection.Title": "Política de devolução",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "De",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Por",
    "TitleSection.PriceMSRP.Listing": "Preço de catálogo",
    "TitleSection.PriceMSRP.Retail": "Preço de retalho",

    "ProductVariationsModal.Title": "Variações do produto",
    "ProductVariationsModal.Table.Image": "Imagem",
    "ProductVariationsModal.Table.Inventory": "Inventário",
    "ProductVariationsModal.Table.Price": "Preço",
    "ProductVariationsModal.ImageSpecifications.Title": "Tabela de tamanhos",
    "ProductVariationsModal.ImageSpecifications.Button": "Fazer download",

    "TopSupplier.Tooltip":
      "Os melhores fornecedores são conhecidos pelos seus produtos de alta qualidade, pelo serviço excelente e pelas classificações altas",
    "TopSupplier.Text": "Melhor fornecedor",

    "BestSelling.Tooltip.Top": "Este é um produto com um ótimo desempenho no Spocket",
    "BestSelling.Tooltip.Bottom": "Os mais vendidos",

    "GallerySection.PremiumIcon.Tooltip":
      "Venda produtos exclusivos, com desconto elevado e envio rápido, na sua loja",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "{{value}}% de desconto extra",

    "Upgrade.Title.AnnualToggled": "Receba hoje mesmo o Spocket premium",
    "Upgrade.Title.MonthlyToggled": "Experimente gratuitamente o Spocket premium durante 14 dias",
    "Upgrade.Subtitle":
      "Experimente hoje mesmo um plano Spocket premium e comece a adicionar produtos de alta conversão dos EUA e da Europa à sua loja.",
    "Upgrade.Button": "Continuar sem o Spocket premium",

    "Search.NoProductsFound.Title": "Lamentamos! Não foi possível encontrar nenhum resultado",
    "Search.NoProductsFound.For": "para",
    "Search.NoProductsFound.Description":
      "Verifique a ortografia, insira um termo de pesquisa relacionado ou um termo de pesquisa mais geral.",
    "Search.Searching.Title": "Estamos à procura de produtos…",
    "Search.Searching.Description":
      "Por favor, aguarde enquanto carregamos os produtos mais recentes para si.",

    "Suppliers.StarRating.Tooltip": "Taxa de sucesso de fornecimento da encomenda pelo fornecedor",
    "Suppliers.RefundPolicy": "Política de reembolso do fornecedor",
    "Suppliers.Joined.Title": "Aderiu ao Spocket",
    "Suppliers.Origin.Title": "Envio a partir do país",
    "Suppliers.Time.Title": "Tempo médio de processamento",
    "Suppliers.HasMore.Title": "Estamos à procura de produtos…",
    "Suppliers.HasMore.Subtitle": "Por favor, aguarde enquanto carregamos os produtos mais recentes para si.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Os melhores fornecedores são conhecidos pelos seus produtos de alta qualidade, pelo serviço excelente e pelas classificações altas",
    "Suppliers.TopSupplierTag.Text": "Melhor fornecedor",

    "AltTextModal.Header.Title": "Editar detalhes da imagem",
    "AltTextModal.Body.Description":
      "Forneça uma breve descrição desta imagem para melhorar a otimização para motores de pesquisa (SEO) e a acessibilidade para clientes com deficiência visual.",
    "AltTextModal.Input.Label": "Texto Alt",
    "AltTextModal.Input.Placeholder": "Texto ALT da imagem",
    "AltTextModal.Button.Cancel": "Cancelar",
    "AltTextModal.Button.Save": "Guardar",

    "WistiaModal.WhatIsSpocket.Title": "O que é o Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Saiba como o Spocket o pode ajudar a construir o seu negócio. Entenda como o Sprocket funciona e como poderá aproveitá-lo ao máximo.",
    "WistiaModal.WhatIsSpocket.Button": "Começar a procurar produtos",
    "WistiaModal.DiscoverProducts.Title": "Descobrir produtos",
    "WistiaModal.DiscoverProducts.Text":
      "Encontre os produtos certos com a ajuda dos filtros adequados e adicione-os à sua loja.",
    "WistiaModal.DiscoverProducts.Button": "Começar a procurar produtos",
    "WistiaModal.ProductCustomization.Title": "Personalize os seus produtos",
    "WistiaModal.ProductCustomization.Text":
      "Utilize a Lista de importação para modificar os seus produtos em função da sua loja. Poderá editar as descrições do produto, definir os preços, adicionar o produto a uma coleção, ou remover imagens.",
    "WistiaModal.ProductCustomization.Button": "Ir para a Lista de importação",
    "WistiaModal.OrderProcessing.Title": "Processamento automatizado de encomendas",
    "WistiaModal.OrderProcessing.Text":
      "Faça as suas encomendas e deixe que o fornecedor entregue o produto à porta do seu cliente, tudo com um só clique.",
    "WistiaModal.OrderProcessing.Button": "Ir para encomendas",
    "WistiaModal.Settings.Title": "Configurações",
    "WistiaModal.Settings.Text":
      "A página de configurações permite gerir todas as informações da sua conta, atribuir uma marca às suas faturas e adicionar o seu cartão de crédito. Além disso, pode ajustar as regras de determinação de preços para todos os seus produtos e selecionar um plano de subscrição.",
    "WistiaModal.Settings.Button": "Crie a sua conta",

    "Footer.Copyright": "Copyright {{ano}}, Spocket. Todos os direitos reservados",
    "Footer.PrivacyPolicy": "Política de privacidade",
    "Footer.TermsConditions": "Termos e condições",
    "Footer.DataProcessingAddendum": "Adenda ao processamento de dados",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Próxima imagem", //English
    "CardImage.ArrowContainer.PreviousImage": "Imagem anterior", //English

    "Api.Error.Authorization": "Sua sessão expirou. Por favor entre novamente.",
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "Minhas configurações", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Planos", //English
    "Settings.Tabs.Title.Account": "Conta", //English
    "Settings.Tabs.Title.Membership": "Inscrição", //English
    "Settings.Tabs.Title.Store": "Loja", //English
    "Settings.Tabs.Title.Pricing": "Preços Globais", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Informações de cadastro", //English
    "Settings.Membership.MembershipInformation.Subtitle": "Essa seção contém informações importantes sobre seu cadastro na Spocket", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "O seu periodo de expêriencia gratuito termina em {{remaining_days}} dias, seu plano sera renovado automaticamente em <1>{{renew_date}}</1> e voce sera cobrado <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Seu Plano", //English
    "Settings.Membership.MembershipUserData.Joined": "Iniciou na Spocket em", //English
    "Settings.Membership.MembershipUserData.Amount": "Valor do Plano", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Próximo Ciclo de Cobrança", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "ano", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "mês", //English
    "Settings.Membership.MembershipCancellation.Title": "Cancelamento do cadastro",
    "Settings.Membership.MembershipCancellation.Subtitle": "Essa seção oferece opções para a pausa ou cancelamento do seu cadastro.",
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancelar seu cadastro na Spocket", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancelar Cadastro", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Me avise mais tarde", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "Você pode continar com os benefícios e ativar um aviso sobre o seu cadastro para 3 dias antes dele ser renovado.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Me avise mais tarde", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause seu plano", //English
    "Settings.Membership.MembershipPause.Subtitle": "Você pode pausar sua conta da Spocket temporariamente e retornar a qualquer momento sem perder nenhum dado.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pausar sua conta atual", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pausar plano", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English 
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    
    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English
    
    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
