// as per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);

// Checks if email is valid based on regex
export const isEmailValid = (email = "") => {
  return EMAIL_REGEX.test(email);
};

// Checks if given email is a valid @spocket.co address
export const isSpocketEmail = (email = "") => {
  if (!isEmailValid(email)) return false;
  const domain = email.split("@")[1];
  return (
    domain === "spocket.co" || email === "tom.hansen.cc@gmail.com" || email === "productleader08+01@gmail.com"
  );
};

// Checks if the logged user have a spocket email
export const isSpocketUser = () => {
  return isSpocketEmail(localStorage.getItem("user_email"));
};
