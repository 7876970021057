import { sendGet, sendPost, sendDelete, sendPut, sendPatch } from "../baseUtils";

export const getSquareLocations = id =>
  sendGet(`/integrated_stores/${id}/square_locations`).then(res => {
    return res.data;
  });

export const getSettings = () =>
  sendGet("/data/account").then(res => {
    return res.data;
  });

export const getPricingRules = () => sendGet("/stores/pricing_rule").then(res => res.data);

export const updatePricingRules = params => sendPatch("/stores/pricing_rule", params);

export const setChurnReminder = async () => sendPut("/stores/churn_reminder");

export const setPauseStore = async () => sendPut("/store/pause");

export const setDisableBrandedInvoicing = async disabled_invoicing =>
  sendPut("/stores/personalization", disabled_invoicing);

export const updateStore = async params => sendPut("/store", params);

export const updateLogo = async params => sendPost("/stores/logo", params);

export const deleteLogo = async () => sendDelete("/stores/logo");
