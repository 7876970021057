import { lazy } from "react";
import paths from "../paths";

const Search = lazy(() => import("pages/search/index"));
const AliScraper = lazy(() => import("pages/AliScraper").then(module => ({ default: module.AliScraper })));
const ImageSearch = lazy(() => import("pages/ImageSearch").then(module => ({ default: module.ImageSearch })));
const Jubilee = lazy(() => import("pages/Jubilee").then(module => ({ default: module.Jubilee })));
const Chat = lazy(() => import("pages/chat").then(module => ({ default: module.Chat })));
const Maintenance = lazy(() => import("pages/Maintenance").then(module => ({ default: module.Maintenance })));
const Orders = lazy(() => import("pages/Orders").then(module => ({ default: module.Orders })));
const Notifications = lazy(() =>
  import("pages/Notifications").then(module => ({ default: module.Notifications }))
);
const PageRedirect = lazy(() =>
  import("pages/PageRedirect").then(module => ({ default: module.PageRedirect }))
);

export const appRoutes = [
  { path: paths.app.search, Component: Search, app: true },
  { path: paths.app.aliscraper, Component: AliScraper, app: true, blockedForAmazonUsers: true },
  { path: paths.app.imageSearch, Component: ImageSearch, app: true, blockedForAmazonUsers: true },
  { path: paths.app.jubilee, Component: Jubilee, app: true },
  { path: paths.app.chat, Component: Chat, app: true },
  { path: paths.app.maintenance, Component: Maintenance },
  { path: paths.app.orders, Component: Orders, app: true, shop: true },
  { path: paths.app.notifications, Component: Notifications, app: true },
  { path: `${paths.app.pageRedirect}/:slug`, Component: PageRedirect, app: true }
];
