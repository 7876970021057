import { sendGet, sendPost, sendDelete, sendPostWithoutSignOut, sendGetWithoutSignOut } from "../baseUtils";

export const refreshPayPalPaymentMethods = () => {
  return sendGet("/marketplace/payment_methods/refresh").then(res => {
    return res.data;
  });
};

export const getChargebeePaymentInfo = () => sendGet("/chargebee_integrations/payment_sources");

export const getStripePaymentInfo = () => sendGet("/stripe_integrations/payment_methods?account_source=new");

export const authorizePayPalOrder = async ({ source, authorizationId }) =>
  sendPost(`/marketplace/payment_methods/${source}/authorize`, {
    authorization_id: authorizationId
  });

export const deletePaymentMethod = async methodId => sendDelete(`/marketplace/payment_methods/${methodId}`);

export const deleteStripe = ({ id }) => sendDelete(`/stripe_integrations/payment_methods/${id}`);

export const deleteCreditCard = ({ isStripe, id }) =>
  sendDelete(
    isStripe
      ? `/stripe_integrations/payment_methods/${id}?account_source=new`
      : `/chargebee_integrations/payment_sources/${id}`
  );

export const createPaymentCharge = async ({ store, planId }) => {
  if (store === "wix") return sendPostWithoutSignOut(`/stores/wix/payment_subscribe/${planId}`);
  return sendGetWithoutSignOut(`/stores/${store}/payment_subscribe/${planId}`);
};

export const createPaypalCharge = async ({ planId }) =>
  sendGetWithoutSignOut(`/stores/paypal/billing_agreement_start_authorization?plan_id=${planId}`);

export const createSubscription = async ({ storeId, payload }) =>
  sendPostWithoutSignOut(`/stores/${storeId}/subscriptions`, payload);

export const getInvoices = async () => {
  const response = await sendGet("/invoices");
  return response.data;
};

export const getEInvoices = ({ EInvoiceId }) => {
  const response = sendGet(`/invoices/${EInvoiceId}`);
  return response;
};
