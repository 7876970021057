export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Liste de Mes commandes",
    "OrderList.Input.Search": "Numéro de commande...",
    "OrderList.Label.Status": "Filtrer par Statut de Spocket",

    "order.status.unpaid": "Non payé",
    "order.status.paid": "Payé",
    "order.status.processing": "En cours de traitement",
    "order.status.shipped": "Expédié",
    "order.status.cancelled": "Annulé",
    "order.status.pending": "En cours",
    "order.status.partially_paid": "Payé en partie",
    "order.status.partially_refunded": "Partiellement remboursé",
    "order.status.refunded": "Remboursé",
    "order.status.voided": "Annulé",
    "order.status.authorized": "Autorisé",
    "order.status.deleted": "Supprimé",
    "order.status.pending_payment_confirmation": "En cours",

    "OrderList.Label.DownloadHistory": "Téléchargez tout l’historique de commande CSV",
    "OrderList.Document.Title": "Commandes - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Rechercher des produits",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Comment traiter des commandes",
    "OrderList.EmptyOrders.Message.Loading.Title": "Vos commandes sont en cours de chargement !",
    "OrderList.EmptyOrders.Message.Loading.Description": "Veuillez patienter, nous récupérons vos commandes.",
    "OrderList.EmptyOrders.Message.Title": "La liste de vos commandes est vide !",
    "OrderList.EmptyOrders.Message.Description":
      "Vous pouvez gérer et traiter vos commandes ici. Dirigez-vous vers la page de recherche pour commencer à ajouter des produits à votre liste d’importation.",
    "OrderList.CheckBox.SelectAllOrders": "Sélectionner toutes les commandes",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} commande sélectionnée",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} commande sélectionnée",
    "OrderList.CheckBox.BulkCheckoutOrders": "Commande de paiement groupé",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Commandes de paiement groupé",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Synchroniser les commandes",
    "RefreshModal.Button.Cancel": "Annuler",
    "RefreshModal.Button.Synchronize": "Synchroniser",
    "RefreshModal.Modal.Body":
      "Les commandes sont automatiquement synchronisées depuis votre magasin. Ne synchronisez que s’il manque des informations de la commande ou si elles n’ont pas été mises à jour. Veuillez actualiser la page après 30 secondes.",

    "Order.Label.OrderNumber": "Numéro de commande",
    "Order.Label.Date": "Date",
    "Order.Label.OrderStatus": "{{integrationName}} Statut de la commande",
    "Order.Table.Th.Product": "Produit",
    "Order.Table.Th.Qty": "QTÉ",
    "Order.Table.Th.UnitPrice": "Prix unitaire",
    "Order.Table.Th.CheckoutDate": "Date de paiement",
    "Order.Table.Th.Action": "Action",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Afficher les données client",
    "CustomerModal.Modal.Title.CustomerInfo": "Données client",
    "CustomerModal.Modal.Body.CustomerInfo": "Données client",
    "CustomerModal.Modal.Body.Address": "Adresse",

    "OrderLine.Button.Checkout": "Paiement",
    "OrderLine.Tooltip.PreviewInvoice": "Prévisualiser la facture",
    "OrderLine.Tooltip.PaymentReceipt": "Reçu de paiement",
    "OrderLine.Tooltip.TrackOrder": "Suivi de commande",
    "OrderLine.Tooltip.OrderProcessing": "Commande en cours de traitement",
    "OrderLine.Tooltip.OrderCancelled": "Commande annulée",
    "OrderLine.Tooltip.PleaseConfirm": "Veuillez cliquer pour confirmer le paiement de votre commande",
    "OrderLine.Label.PurchaseEtsy": "Achat sur Etsy",
    "OrderLine.Paying": "Paiement",
    "OrderLine.Failure": "Échec",
    "OrderLine.Sample": "Échantillon",
    "OrderLine.ErrorOrderRow":
      "Impossible de trouver la liste des commandes dans le système ! Veuillez contacter le service client !",

    "ConfirmationModal.Label.OrderDetails": "Détails de la commande",
    "ConfirmationModal.Label.Total": "Total",
    "ConfirmationModal.Label.Shipping": "Expédition",
    "ConfirmationModal.Label.TransactionFees": "Frais de transaction",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Les frais de transaction comprennent les frais facturés par Stripe, le prestataire de paiement de Spocket ainsi que les frais de service.",
    "ConfirmationModal.Modal.OrderConfirmation": "Confirmation de commande",
    "ConfirmationModal.Modal.Alert.Attention": "Attention !",
    "ConfirmationModal.Modal.Alert.Description": "Cette commande contient une remarque du client.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Vous êtes sur le point de passer une commande pour",
    "ConfirmationModal.Modal.Table.Th.Name": "Nom",
    "ConfirmationModal.Modal.Table.Th.Price": "Prix",
    "ConfirmationModal.Modal.Table.Th.Qty": "QTÉ",
    "ConfirmationModal.Modal.Table.Th.Total": "Total",
    "ConfirmationModal.Modal.Label.Note": "Remarque au fournisseur",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Votre message",
    "ConfirmationModal.Button.PlaceOrder": "Passer une commande",

    "PaymentCompleteModal.PaymentCompleted": "Paiement terminé",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Merci pour votre paiement. Le prestataire a été averti et traitera la commande sous peu. Nous mettrons à jour le statut de la commande et ajouterons le numéro de suivi sur la page de vos commandes.",
    "PaymentCompleteModal.PaymentIncomplete": "Votre commande n’est pas terminée !",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Votre banque a besoin d’une confirmation de votre paiement pour continuer",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Veuillez utiliser ce lien pour confirmer le paiement.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Une fois le paiement confirmé, le prestataire en sera averti et votre commande sera traitée normalement. La mise à jour du statut et du suivi sera ajoutée à votre page des commandes. Veuillez actualiser la page après confirmation.",

    "SetYourGoalsModal.Title": "Nombre de produits ajoutés au magasin",
    "SetYourGoalsModal.Subtitle": "Un magasin type met en avant 25 produits avant d’obtenir des ventes",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} produit",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} produits",
    "SetYourGoalsModal.Button": "Définir mon objectif et démarrer le dropshipping",

    "YourGoalsBanner.Container.Description": "Vos objectifs",
    "YourGoalsBanner.Header.Title": "Lancement d’une entreprise",
    "YourGoalsBanner.Header.Subtitle": "Préparez votre magasin",
    "YourGoalsBanner.Progress.Completed": "Terminé",
    "YourGoalsBanner.ProductPushedCount": "<0>Objectif : {{goalsNumberOfProduct}}</0> produits mis en avant",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Rejoignez Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Première recherche",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} Produit mis en avant",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produits mis en avant",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} Produit importé",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} Produits importés",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Dropshipping AliExpress automatisé",
    "Aliscraper.Subtitle":
      "Importez des milliers de produits AliExpress sur votre boutique d’eCommerce en un clic et automatisez votre activité de dropshipping.",
    "Aliscraper.Features.Feature1": "Importation rapide en un seul clic",
    "Aliscraper.Features.Feature2": "Traitez des centaines de commandes directement avec Spocket",
    "Aliscraper.Features.Feature3": "Service client 24/7",
    "Aliscraper.Button": "Télécharger l’extension",
    "Aliscraper.Chrome": "Disponible dans le Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Connectez votre boutique",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Choisissez votre plateforme pour démarrer",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Nom de boutique non valide",
    "ConnectStoreModal.ShopifyForm.Placeholder": "par ex. MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.ShopifyForm.Button": "Connecter la boutique",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Votre clé Auth :",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Comment connecter votre boutique à Spocket :",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Installez le plug-in de Spocket depuis",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "ici",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Activez le plug-in sur votre page de plug-in",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Allez sur le Tableau de bord WordPress > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Collez votre clé Auth (énumérée ci-dessus) cliquez sur « Enregistrer »",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Fait",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.WixForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.WixForm.Button": "Connecter la boutique",
    "ConnectStoreModal.FelexForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.FelexForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.FelexForm.Button": "Connecter la boutique",
    "ConnectStoreModal.BigcommerceForm.UrlText": "URL de boutique Bigcommerce",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Nom de boutique non valide",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "par ex. MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.BigcommerceForm.Button": "Connecter la boutique",
    "ConnectStoreModal.EcwidForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.EcwidForm.Button": "Connecter la boutique",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.WhiteLabelForm.Button": "Connecter la boutique",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.SquarespaceForm.Button": "Connecter la boutique",
    "ConnectStoreModal.SquareForm.FooterText": "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.SquareForm.FooterLink": "Essayez gratuitement",
    "ConnectStoreModal.SquareForm.Button": "Connecter la boutique",
    "ConnectStoreModal.FelexButton.Explanation":
      "Une plateforme de création de boutique sur le Web pour entrepreneurs en vue de lancer une boutique de dropshipping en moins de 10 minutes.",
    "ConnectStoreModal.DontHaveStore":  "Vous n’avez pas de boutique ?",
    "ConnectStoreModal.TryForFree": "Essayez gratuitement",

    "PaymentTab.Document.Title": "Paramètres - Automatisation - Spocket",
    "PaymentTab.Title.Billing": "Facturation",
    "PaymentTab.Tooltip.Billing":
      "Les cartes de crédit seront utilisées pour le traitement de la commande et les paiements d’abonnement",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Suspendez/Modifiez votre plan",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Modifiez votre plan",
    "PaymentTab.AccordionItem.Button.Downgrade": "Déclassez",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Suspendez votre plan",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Votre boutique a déjà été à l’arrêt auparavant",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Suspendez votre plan",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Vous pouvez suspendre temporairement votre compte Spocket et le réactiver à tout moment sans perdre vos données.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Me le rappeler plus tard",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Me le rappeler plus tard",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 jours avant",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Gardez mes avantages et rappelez-moi <1>{{daysBefore}}</1> mon renouvellement d’abonnement",
    "RemindMeLaterModal.Header.ReminderSet": "Rappel fixé",
    "RemindMeLaterModal.Body.DaysBefore": "vous envoie un e-mail 3 jours avant",
    "RemindMeLaterModal.Body.ReminderSet":
      "Votre rappel est fixé et nous vous préviendrons <1>{{daysBefore}}</1> que vos avantages sont renouvelés. En attendant poursuivez votre expérience entrepreneuriale.",
    "RemindMeLaterModal.Button.Continue": "Continuer",
    "PauseStoreModal.Title.PauseYourStore": "Suspendez votre boutique",
    "PauseStoreModal.Description.PauseYourStore":
      "Profitez de cette offre exceptionnelle et baissez le prix de votre plan de 60% pour les 30 prochains jours",
    "PauseStoreModal.Button.PauseStore": "Suspendre la boutique",
    "PauseStoreModal.Description.StorePaused":
      "Votre boutique a été suspendue et l’abonnement du mois suivant sera 60% moins élevé. Veuillez noter que les paiements réguliers reprendront ensuite.",
    "ConfirmPasswordModal.Title": "Veuillez confirmer votre mot de passe",
    "ConfirmPasswordModal.SubTitle": "Pour des raisons de sécurité, veuillez confirmer votre mot de passe",
    "ConfirmPasswordModal.Input.Placeholder": "Votre mot de passe",
    "ConfirmPasswordModal.Button.Cancel": "Annuler",
    "ConfirmPasswordModal.Button.Confirm": "Confirmer",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "fr",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Accélérez votre développement avec Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Choisissez votre cycle de facturation",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Vos premiers",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "jours ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "sont gratuits, annulez pendant ou après votre essai",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Votre première facture sera planifiée pour",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "Votre première facture sera due à la confirmation",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "La facturation se répétera tous les mois",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Mensuellement ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/mois",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Annuellement ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/mois",
    "UpgradeConfirmationModal.YearlyTab.Discount": "Désactivé",
    "UpgradeConfirmationModal.TaxRates.Text": "taxe de vente",
    "UpgradeConfirmationModal.TaxRates.Period": "me",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "À payer maintenant",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/mois X 12 mois",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Vos économies : ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/an",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "équivalent à",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% de remise",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial":
      "Les plans annuels n’incluent pas de période d’essai",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "Vous serez immédiatement facturés de la totalité",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Payez avec une carte de crédit/débit",
    "UpgradeConfirmationModal.PayPalTab.Info": "Payez avec PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Détails de paiement ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Réclamer mon essai gratuit",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Mettre à niveau vers ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Approuvé par + de 50 000 Entrepreneurs",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "Nos clients nous ont toujours octroyé une cote de 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "Cryptage SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Toutes vos informations de paiement sont en totale sécurité avec nous.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "+ de 50 000",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Approuvé par + de 50 000 Entrepreneurs",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "Nos clients nous ont toujours octroyé une cote de 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "Cryptage SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Toutes vos informations de paiement sont en totale sécurité avec nous.",
    "UpgradeConfirmationModal.Agreement.Intro": "En poursuivant, vous acceptez les ",
    "UpgradeConfirmationModal.Agreement.Terms": "Conditions d’utilisation",
    "UpgradeConfirmationModal.Agreement.Privacy": "Politique de confidentialité",
    "UpgradeConfirmationModal.Agreement.Refund": "Politique de remboursement",
    "UpgradeConfirmationModal.Agreement.Middle": "et",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Politique d’annulation",

    "OrderDetails.Title": "Détails de la commande",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Carte de crédit valable jusque",
    "CreditCard.Update.Button": "Mettre la carte à jour",
    "CreditCard.AddCreditCard.Text":
      "Vous n’avez pas encore ajouté de carte de crédit. Veuillez ajouter une carte de crédit à partir du bouton ci-dessous pour vous permettre de traiter vos commandes automatiquement",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Ajoutez une carte de crédit",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Produits phares",
    "Sidebar.WinningProducts.Variant": "Nouveauté",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Applis",
    "Sidebar.HelpCenter": "Centre d’assistance",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Paramètres",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Nous sommes là pour vous aider!",
    "TutorialModal.WhatIsSpocket": "Qu’est-ce que Spocket ?",
    "TutorialModal.DiscoverProducts": "Découvrez les produits",
    "TutorialModal.ProductCustomization": "Personnalisation du produit",
    "TutorialModal.OrderProcessing": "Commande en cours de traitement",
    "TutorialModal.Settings": "Paramètres",
    "TutorialModal.GetHelp": "Obtenir de l’aide",
    "TutorialModal.JoinTheCommunity": "Rejoignez la communauté",

    "NotificationCenter.Header": "Centre de notification",
    "NotificationCenter.Footer": "Voir toutes les notifications",
    "NotificationCenter.NoNotification": "Rien à voir ici !",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English
    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English
    
    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Mes produits - Spocket",
    "Products.Title.Header": "Mes produits",
    "Products.Loading.Top": "Votre liste de produit est en cours de chargement !",
    "Products.Loading.Bottom": "Veuillez patienter, nous récupérons vos produits.",
    "Products.Empty.Search": "Aucun produit n’a été trouvé !",
    "Products.Empty.Top": "Votre liste de produit est vide !",
    "Products.Empty.Bottom":
      "Dirigez-vous vers la page de recherche pour commencer à ajouter des produits à votre liste d’importation.",
    "Products.Empty.Button.Search": "Rechercher des produits",
    "Products.Empty.Button.How": "Comment trouver des produits",
    "Products.Alert.Removed": "Produit supprimé",

    "Products.Search.Label": "Recherchez vos produits",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Recherche",

    "ProductListItem.Inactive.Tooltip": "Ce produit n’est plus disponible chez le fournisseur",
    "ProductListItem.Inactive.Title": "Inactif",
    "ProductListItem.OutOfStock.Tooltip": "Toutes les versions de ce produit sont en rupture de stock",
    "ProductListItem.OutOfStock.Title": "En rupture de stock",
    "ProductListItem.InventoryCount.Singular": "pièce",
    "ProductListItem.InventoryCount.Plural": "pièces",
    "ProductListItem.InventoryCount.End.Singular": "reste",
    "ProductListItem.InventoryCount.End.Plural": "reste",
    "ProductListItem.Button.View": "Afficher dans la boutique",
    "ProductListItem.Button.Remove": "Supprimer de la boutique",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "Liste d’importation - Spocket",
    "ImportList.Title.Header": "Ma liste d’importation",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "Votre liste d’importation est en cours de chargement !",
    "ImportList.Loading.Bottom": "Veuillez patienter, nous récupérons vos produits.",
    "ImportList.Empty.Button.Search": "Rechercher des produits",
    "ImportList.Empty.Top": "Votre liste d’importation est vide !",
    "ImportList.Empty.Bottom":
      "Dirigez-vous vers la page de recherche pour commencer à ajouter des produits à votre liste d’importation.",
    "ImportList.Empty.Button.How": "Comment personnaliser des produits",
    "ImportList.Alert.Pushed": "Ce produit est mis en avant dans votre boutique :",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "Recherchez parmi vos produits importés",
    "ImportList.Search.Placeholder": "Recherchez vos produits",
    "ImportList.Search.Button": "Recherche",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Mise en avant des produits sur la page actuelle",
    "PushAllModal.Button.Inactive": "Mettre la page actuelle en avant",
    "PushAllModal.Modal.Text":
      "Êtes-vous sûr de vouloir mettre en avant tous les produits de cette page ? Tous les produits valides de la page actuelle seront vendus sur votre boutique.",
    "PushAllModal.Modal.Push": "Les mettre tous en avant",
    "PushAllModal.Modal.Cancel": "Annuler",

    "RemoveAllModal.Button.Active": "Suppression des produits de la page actuelle",
    "RemoveAllModal.Button.Inactive": "Supprimer la page actuelle",
    "RemoveAllModal.Modal.Text":
      "Êtes-vous sûr de vouloir supprimer tous les produits de cette page ? Tous les produits de la page actuelle seront supprimés de la liste d’importation.",
    "RemoveAllModal.Modal.Remove": "Supprimer tout",
    "RemoveAllModal.Modal.Cancel": "Annuler",

    "ImportListItem.Tab.Product": "Produit",
    "ImportListItem.Tab.Description": "Description",
    "ImportListItem.Tab.Variants": "Versions",
    "ImportListItem.Tab.Images": "Images",
    "ImportListItem.Remove.Active": "Suppression du produit",
    "ImportListItem.Remove.Inactive": "Supprimer le produit",
    "ImportListItem.Push.Active": "Mise en avant sur la boutique",
    "ImportListItem.Push.Inactive": "Mettre en avant sur la boutique",
    "ImportListItem.Save.Button": "Enregistrer",
    "ImportListItem.Alert.Removed": "Ce produit a été supprimé de votre liste d’importation : ",
    "ImportListItem.Alert.RemoveFailed": "Un problème est survenu pendant la suppression : ",
    "ImportListItem.Alert.CompareAtPrice":
      "Comparer que le prix doit être supérieur au prix de vente pour : ",
    "ImportListItem.Alert.OneActiveVariation": "Ce produit a besoin d’au moins une version active : ",
    "ImportListItem.Alert.Saved": "Ce produit a été enregistré : ",
    "ImportListItem.Alert.ErrorPushing":
      "Un problème est survenu lors de la mise en avant sur votre boutique",
    "ImportListItem.Alert.ErrorSaving": "Un problème est survenu lors de l’enregistrement de votre boutique",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Connectez votre boutique pour mettre des produits en avant : ",
    "ImportListItem.Tooltip.OneActiveVariation": "Vous avez besoin d’au moins une version active",
    "ImportListItem.Tooltip.Unavailable": "Ce produit est actuellement indisponible",
    "ImportListItem.Tooltip.Unsaved": "Vous avez des modifications non enregistrées",
    "ImportListItem.Tooltip.Profit": "Avertissement : Votre marge brute est négative pour ce produit",
    "ImportListItem.Tooltip.Unpurchasable":
      "Vous pouvez enregistrer ce produit mais il est actuellement indisponible",
    "ImportListItem.Variants.ShowShippingPrices": "Afficher les frais d’expédition",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Nom du produit",
    "ItemInfo.ProductTags.Label": "Étiquettes du produit",
    "ItemInfo.ProductTags.Remove": "Supprimer toutes les étiquettes",
    "ItemInfo.ProductTags.Placeholder": "Ajouter une nouvelle étiquette",
    "ItemInfo.ProductType.Label": "Type de produit",
    "ItemInfo.Collection.Label": "Collection",
    "ItemInfo.Collection.Placeholder": "Sélectionner",
    "ItemInfo.Collection.Option": "Sélectionner",
    "ItemInfo.SquareLocation.Option": "Sélectionner",

    "ItemVariantList.Modal.Title": "Détails d’expédition",
    "ItemVariantList.Modal.Intro": "Expédition depuis : ",
    "ItemVariantList.Modal.DoesNotShipInternational":
      "Cet article n’est pas expédié au niveau international.",
    "ItemVariantList.Modal.DoesNotShipTo": "N’est pas expédié vers : ",
    "ItemVariantList.Table.Head.Destination": "Destination",
    "ItemVariantList.Table.Head.Cost": "Coût",
    "ItemVariantList.Table.Head.Time": "Délai de livraison (jours)",
    "ItemVariantList.Table.Body.Domestic": "National",
    "ItemVariantList.Table.Body.International": "International",
    "ItemVariantList.VariantsTable.ShippingPrice": "Frais d’expédition ",
    "ItemVariantList.VariantsTable.Image": "Image",
    "ItemVariantList.VariantsTable.SKU": "Unité de gestion des stocks",
    "ItemVariantList.VariantsTable.Inventory": "Inventaire",
    "ItemVariantList.VariantsTable.Price": "Prix",
    "ItemVariantList.VariantsTable.SalesPrice": "Prix de vente ",
    "ItemVariantList.VariantsTable.Profit": "Profit",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Comparer le prix",
    "ItemVariantList.VariantsTable.Unavailable": "Ce produit est actuellement indisponible",
    "ItemVariantList.VariantsTable.Head": "Toutes les versions de ce produit sont affichées ici.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ex : 40 %",
    "ItemVariantList.Alert.MaxVariants": "Shopify ne peut pas prendre en charge plus de 100 versions.",

    "ItemVariantList.ContextPopover.Title": "Fixer le bénéfice",
    "ItemVariantList.ContextPopover.Apply": "Appliquer",

    "ProductVariation.Description":
      "Vous pouvez directement passer des commandes pour des échantillons sur Spocket. Respectez les étapes ci-dessous pour un paiement rapide et facile.",
    "ProductVariation.Label.SelectVariant": "Sélectionnez une version",
    "ProductVariation.Label.SelectTheProductVariation": "Sélectionnez la version du produit",
    "ProductVariation.Qty": "Qté",
    "ProductVariation.Label.NotesForTheSupplier": "Remarques pour le fournisseur",

    "InputAddress.Label.FirstName": "Prénom",
    "InputAddress.PlaceHolder.FirstName": "Saisissez le prénom",
    "InputAddress.Label.LastName": "Nom",
    "InputAddress.PlaceHolder.LastName": "Saisissez le nom",
    "InputAddress.Label.StreetAddress": "Rue",
    "InputAddress.Label.Country": "Pays",
    "InputAddress.PlaceHolder.Country": "Rechercher le pays",
    "InputAddress.Label.State": "État",
    "InputAddress.Placeholder.State": "Rechercher l’État",
    "InputAddress.Label.City": "Ville",
    "InputAddress.PlaceHolder.City": "Saisissez la ville",
    "InputAddress.Label.Zip": "Code postal",
    "InputAddress.PlaceHolder.Zip": "Saisissez le code postal",
    "InputAddress.Label.Phone": "Numéro de téléphone",
    "InputAddress.Placeholder.Phone": "Saisissez le numéro de téléphone",

    "OrderReview.OrderDetails": "Détails de la commande",
    "OrderReview.Unit": "{{count}} Unité",
    "OrderReview.Unit_plural": "{{count}} Unités",
    "OrderReview.ShippingAddress": "Adresse de livraison",
    "OrderReview.PaymentMethod": "Mode de paiement",
    "OrderReview.Amounts": "Montant",
    "OrderReview.Subtotal": "Sous-total",
    "OrderReview.ShippingCost": "Frais d’expédition",
    "OrderReview.TransactionFees": "Frais de transaction",
    "OrderReview.TransactionFees.ToolTips":
      "Les frais de transaction comprennent les frais facturés par Stripe, le prestataire de paiement de Spocket ainsi que les frais de service.",
    "OrderReview.OrderTotal": "Total de la commande",
    "OrderReview.ItemWithCount": "{{count}} article",
    "OrderReview.ItemWithCount_plural": "{{count}} articles",
    "OrderReview.Placeholder.CreditCard": "Carte de crédit valable jusqu’au {{last4}}",

    "PaymentComplete.PaymentCompleted": "Paiement terminé",
    "PaymentComplete.PaymentCompleted.Note":
      "Merci pour votre paiement. Le prestataire a été averti et traitera la commande sous peu. Nous mettrons à jour le statut de la commande et ajouterons le numéro de suivi sur la page de vos commandes.",
    "PaymentComplete.PaymentIncomplete": "Votre commande n’est pas terminée !",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Votre banque a besoin d’une confirmation de votre paiement pour continuer",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink":
      "Veuillez utiliser ce lien pour confirmer le paiement.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Une fois le paiement confirmé, le prestataire en sera averti et votre commande sera traitée normalement. La mise à jour du statut et du suivi sera ajoutée à votre page des commandes. Veuillez actualiser la page après confirmation.",

    "CreditCard.AddCreditCard.Description":
      "Vous n’avez pas encore ajouté de carte de crédit. Veuillez ajouter une carte de crédit à partir du bouton ci-dessous pour vous permettre de traiter vos commandes automatiquement",
    "CreditCard.Button.AddCreditCard": "Ajoutez une carte de crédit",
    "CreditCard.Button.UpdateCard": "Mettre la carte à jour",
    "CreditCard.UpdateCard.Label.CreditCard": "Carte de crédit",
    "CreditCard.PlaceHolder.CreditCard": "Carte de crédit valable jusqu’au {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Carte de crédit",
    "UpdateCreditCardModal.Button.UpdateCard": "Mettre la carte à jour",
    "UpdateCreditCardModal.Button.AddCreditCard": "Ajoutez une carte de crédit",
    "UpdateCreditCardModal.Button.Cancel": "Annuler",
    "UpdateCreditCardModal.Label.UpdateCardDetails":
      "Mettez les informations de votre carte de crédit à jour",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Votre compte sera utilisé pendant le traitement de la commande de produits Spocket.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Conditions d’utilisation",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "pour traiter les paiements.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Sélectionnez une version",
    "SampleOrderModal.Title.ShippingAddress": "Adresse de livraison",
    "SampleOrderModal.Title.PaymentMethod": "Mode de paiement",
    "SampleOrderModal.Title.Review": "Examiner",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Confirmation de paiement requise !",
    "SampleOrderModal.Title.OrderPlaced": "Commande passée !",
    "SampleOrderModal.Steps": "Étape {{current}} sur {{total}}",
    "SampleOrderModal.FinalStep": "Étape finale",
    "SampleOrderModal.ConfirmPayment": "Confirmez le paiement !",
    "SampleOrderModal.Finished": "Terminé",
    "SampleOrderModal.Footer.Continue": "Continuer",
    "SampleOrderModal.Footer.PlaceOrder": "Passer une commande",
    "SampleOrderModal.Footer.Close": "Fermer",
    "SampleOrderModal.Footer.Cancel": "Annuler",
    "SampleOrderModal.Footer.GoBack": "Revenir en arrière",

    "AdvancedFiltersModal.Title.Filters": "Filtres",
    "AdvancedFiltersModal.Title.Shipping": "Expédition",
    "AdvancedFiltersModal.Label.ShipsFrom": "Expédition depuis",
    "AdvancedFiltersModal.Label.ShipsTo": "Expédition vers",
    "AdvancedFiltersModal.Label.ShippingTime": "Délai de livraison",
    "AdvancedFiltersModal.Label.Popular": "Populaire",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "États-Unis",
    "AdvancedFiltersModal.Label.Country.Europe": "Europe",
    "AdvancedFiltersModal.Label.ShippingTimeDescription":
      "Uniquement disponible pour la livraison aux États-Unis",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "N’importe quel jour",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1 à 3 jours",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4 à 7 jours",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8 à 14 jours",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "+ de 15 jours",
    "AdvancedFiltersModal.Title.ItemsCost": "Frais des articles",
    "AdvancedFiltersModal.Label.ItemCost": "Frais de l’article",
    "AdvancedFiltersModal.Label.ShippingCost": "Frais d’expédition",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Frais",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratuit",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5 $ maximum",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15 $ maximum",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25 $ maximum",
    "AdvancedFiltersModal.Title.Supplier": "Fournisseur",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Fournisseurs",
    "AdvancedFiltersModal.Label.TopSupplier": "Premier fournisseur",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Les fournisseurs dont les produits de grande qualité, leur service supérieur et leurs très bonnes notations font leur réputation",
    "AdvancedFiltersModal.Title.Advanced": "Avancé",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Produits premium",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Produits comptant de fortes remises et une livraison rapide",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Meilleure vente",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Produits les plus performants sur Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Annuler",
    "AdvancedFiltersModal.Button.ViewResults": "Affichez les résultats",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Recherchez le pays...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Pays",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Recherchez un fournisseur…",
    "AdvancedFiltersModal.Label.All Suppliers": "Tous les fournisseurs",
    "AdvancedFiltersModal.Label.New Suppliers": "Nouveaux fournisseurs",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "N’importe quel jour",
    "AdvancedFilters.ShippingTime.One": "1 à 3 jours",
    "AdvancedFilters.ShippingTime.Four": "4 à 7 jours",
    "AdvancedFilters.ShippingTime.Eight": "8 à 14 jours",
    "AdvancedFilters.ShippingTime.Fifteen": "+ de 15 jours",

    "AdvancedFilters.ShippingCost.Free": "Livraison gratuite",
    "AdvancedFilters.ShippingCost.Five": "Livraison en-dessous de 5 $",
    "AdvancedFilters.ShippingCost.Fifteen": "Livraison en-dessous de 15 $",
    "AdvancedFilters.ShippingCost.TwentyFive": "Livraison en-dessous de 25 $",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Supprimer tout",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Expédition",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Recherche",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Expédition depuis",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Expédition vers",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Fournisseur",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Trier par",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Frais de l’article",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Fournisseurs principaux",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Factures personnalisées",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Meilleure vente",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "de {{from}} à {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Saisissez des mots-clés…",
    "Search.Header.Button.Search": "Recherche",
    "Search.Header.Button.Filters": "Filtres",

    "Navigation.UpgradeButton.TryPro": "Essayez Spocket Pro GRATUITEMENT",
    "Navigation.UpgradeButton.TryEmpire": "Essayez Spocket EMPIRE GRATUITEMENT",
    "Navigation.UpgradeButton.TryUnicorn": "Essayez Spocket Unicorn GRATUITEMENT",

    "CategoriesCards.Label.MoreCategories": "Plus de catégories",

    "StaticCategories.WomensClothing": "Vêtements pour femmes",
    "StaticCategories.WomensClothing.Activewear": "Vêtements de sport",
    "StaticCategories.WomensClothing.Bodysuits": "Bodies",
    "StaticCategories.WomensClothing.Dresses": "Robes",
    "StaticCategories.WomensClothing.JacketsCoats": "Vestes et manteaux",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Salopettes et grenouillères",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lingerie et sous-vêtements",
    "StaticCategories.WomensClothing.MatchingSets": "Ensembles",
    "StaticCategories.WomensClothing.Pants": "Bas",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Jupes",
    "StaticCategories.WomensClothing.Sleepwear": "Vêtements de nuit",
    "StaticCategories.WomensClothing.Socks": "Chaussettes",
    "StaticCategories.WomensClothing.SweatersHoodies": "Pulls et sweats à capuche",
    "StaticCategories.WomensClothing.Swimwear": "Maillots de bain",
    "StaticCategories.WomensClothing.TankTops": "Débardeurs",
    "StaticCategories.WomensClothing.TopsBlouses": "Hauts et chemisiers",
    "StaticCategories.WomensClothing.Tshirts": "T-shirts",

    "StaticCategories.JewelryWatches": "Bijoux et montres",
    "StaticCategories.JewelryWatches.Bracelets": "Bracelets",
    "StaticCategories.JewelryWatches.Earrings": "Boucles d’oreille",
    "StaticCategories.JewelryWatches.Necklaces": "Colliers",
    "StaticCategories.JewelryWatches.OtherAccessories": "Autres accessoires",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Pendentifs, pierres et fantaisies",
    "StaticCategories.JewelryWatches.Rings": "Bagues",
    "StaticCategories.JewelryWatches.Sets": "Ensembles",
    "StaticCategories.JewelryWatches.Watches": "Montres",

    "StaticCategories.TechAccessories": "Accessoires technologiques",
    "StaticCategories.TechAccessories.AudioVideo": "Audio et vidéo",
    "StaticCategories.TechAccessories.CasesCovers": "Étuis et boîtiers",
    "StaticCategories.TechAccessories.Lighting": "Éclairage",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Accessoires pour mobiles et portables",
    "StaticCategories.TechAccessories.Mousepads": "Tapis de souris",
    "StaticCategories.TechAccessories.Novelty": "Nouveautés",

    "StaticCategories.KidsBabies": "Enfants et bébés",
    "StaticCategories.KidsBabies.BabyClothing": "Vêtements pour bébés",
    "StaticCategories.KidsBabies.Bathing": "Baignade",
    "StaticCategories.KidsBabies.BlanketsPillows": "Couvertures et oreillers",
    "StaticCategories.KidsBabies.CapsHeadbands": "Chapeaux et bandeaux",
    "StaticCategories.KidsBabies.Footwear": "Chaussures",
    "StaticCategories.KidsBabies.Furniture": "Mobilier",
    "StaticCategories.KidsBabies.KidsClothing": "Vêtements pour enfants",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Parentalité et accessoires",

    "StaticCategories.Toys": "Jouets",
    "StaticCategories.Footwear": "Chaussures",
    "StaticCategories.Footwear.Boots": "Bottes",
    "StaticCategories.Footwear.Flats": "Plates",
    "StaticCategories.Footwear.Heels": "Talons",
    "StaticCategories.Footwear.Mens": "Hommes",
    "StaticCategories.Footwear.Sandals": "Sandales",
    "StaticCategories.Footwear.Slippers": "Pantoufles",
    "StaticCategories.Footwear.SneakersRunners": "Baskets et chaussures de course",

    "StaticCategories.BathBeauty": "Bain et beauté",
    "StaticCategories.BathBeauty.Bodycare": "Soins du corps",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffuseurs, huiles et bougies",
    "StaticCategories.BathBeauty.Haircare": "Soin des cheveux",
    "StaticCategories.BathBeauty.Healthcare": "Santé",
    "StaticCategories.BathBeauty.Makeup": "Maquillage",
    "StaticCategories.BathBeauty.Nails": "Ongles",
    "StaticCategories.BathBeauty.Skincare": "Soin de la peau",
    "StaticCategories.BathBeauty.TowelsRobes": "Serviettes et peignoirs",

    "StaticCategories.Pets": "Animaux de compagnie",
    "StaticCategories.Pets.BedsBlankets": "Lits et couvertures",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Laisses, colliers et vêtements pour animaux",
    "StaticCategories.Pets.Petcare": "Produits pour animaux de compagnie",
    "StaticCategories.Pets.Toys": "Jouets",

    "StaticCategories.HomeGarden": "Maison et jardin",
    "StaticCategories.HomeGarden.Bathroom": "Salle de bain",
    "StaticCategories.HomeGarden.Clocks": "Horloges",
    "StaticCategories.HomeGarden.Drinkware": "Nécessaires à boire",
    "StaticCategories.HomeGarden.FoodBeverage": "Alimentation et boissons",
    "StaticCategories.HomeGarden.Furniture": "Mobilier",
    "StaticCategories.HomeGarden.Garden": "Jardin",
    "StaticCategories.HomeGarden.HomeDecor": "Décoration intérieure",
    "StaticCategories.HomeGarden.HomeImprovement": "Amélioration résidentielle",
    "StaticCategories.HomeGarden.Kitchen": "Cuisine",
    "StaticCategories.HomeGarden.Lighting": "Éclairage",
    "StaticCategories.HomeGarden.Organization": "Organisation",
    "StaticCategories.HomeGarden.StationeryCrafts": "Papeterie et bricolages",
    "StaticCategories.HomeGarden.TextilesPillows": "Textiles et oreillers",
    "StaticCategories.HomeGarden.Tools": "Outils",

    "StaticCategories.Accessories": "Accessoires",
    "StaticCategories.Accessories.Belts": "Ceintures",
    "StaticCategories.Accessories.Cufflinks": "Boutons de manchette",
    "StaticCategories.Accessories.Hats": "Chapeaux",
    "StaticCategories.Accessories.Keychains": "Porte-clés",
    "StaticCategories.Accessories.ScarvesBandanas": "Foulards et bandanas",
    "StaticCategories.Accessories.Sunglasses": "Lunettes de soleil",

    "StaticCategories.MensClothing": "Vêtements pour hommes",
    "StaticCategories.MensClothing.Activewear": "Vêtements de sport",
    "StaticCategories.MensClothing.DressShirts": "Chemises",
    "StaticCategories.MensClothing.JacketsCoats": "Vestes et manteaux",
    "StaticCategories.MensClothing.Pants": "Bas",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Chaussettes",
    "StaticCategories.MensClothing.SweatersHoodies": "Pulls et sweats à capuche",
    "StaticCategories.MensClothing.Swimwear": "Maillots de bain",
    "StaticCategories.MensClothing.TankTops": "Débardeurs",
    "StaticCategories.MensClothing.Tshirts": "T-shirts",
    "StaticCategories.MensClothing.Underwear": "Sous-vêtements",

    "StaticCategories.SportsOutdoors": "Sports et plein air",
    "StaticCategories.SportsOutdoors.Activewear": "Vêtements de sport",
    "StaticCategories.SportsOutdoors.Biking": "Cyclisme",
    "StaticCategories.SportsOutdoors.Camping": "Camping",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Équipement et accessoires",
    "StaticCategories.SportsOutdoors.Fishing": "Pêche",

    "StaticCategories.Gifts": "Cadeaux",
    "StaticCategories.Gifts.Adults": "Adultes",
    "StaticCategories.Gifts.Funny": "Drôle",
    "StaticCategories.Gifts.Mugs": "Mugs",
    "StaticCategories.Gifts.Novelty": "Nouveautés",
    "StaticCategories.Gifts.Personalized": "Personnalisé",
    "StaticCategories.Gifts.Religious": "Religieux",

    "StaticCategories.Automotive": "Automobile",
    "StaticCategories.BagsWallets": "Sacs et portefeuilles",
    "StaticCategories.Seasonal": "Saisonnier",
    "StaticCategories.Seasonal.Christmas": "Noël",
    "StaticCategories.Seasonal.FathersDay": "Fête des pères",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Fête des mères",

    "StaticCategories.FestivalsParties": "Festivals et soirées",
    "StaticCategories.FestivalsParties.PartySupplies": "Articles de fête",
    "StaticCategories.FestivalsParties.Wedding": "Mariage",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Expédition rapide depuis les États-Unis",
    "QuickFilters.Label.Under5": "Livraison aux États-Unis en-dessous de 5 $",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Trier par",
    "SortBy.DropDown.Item.Relevance": "Pertinence",
    "SortBy.DropDown.Item.PriceLowToHigh": "Prix : faible à élevé",
    "SortBy.DropDown.Item.PriceHighToLow": "Prix : élevé à faible",
    "SortBy.DropDown.Item.Newest": "Le plus récent",
    "SortBy.DropDown.Item.ShippingTime": "Délai de livraison",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Maison",
    "Breadcrumb.Item.Search": "recherche",

    "UpgradeModal.Modal.Title": "Sélectionnez un plan tarifaire",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English

    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Mensuellement",
    "Plans.ToggleSection.Yearly": "Annuellement",
    "Plans.ToggleSection.DiscountInitial": "Remonter à",
    "Plans.ToggleSection.DiscountSingular": "Mois",
    "Plans.ToggleSection.DiscountPlural": "Mois",
    "Plans.ToggleSection.DiscountFinal": "Désactivé",
    "Plans.ProrationCost.TextInitial": "Vous payez actuellement",
    "Plans.ProrationCost.TextFinal": "/an Passez au niveau Empire pour seulement ",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Commencez à faire des affaires",
    "Card.DescriptionVariantType.Professional": "Augmentez les ventes",
    "Card.DescriptionVariantType.Empire": "Maximisez les ventes",
    "Card.DescriptionVariantType.Unicorn": "Gérez les ventes en proportion",
    "Card.DescriptionVariantType.Period": "me",
    "Card.DescriptionVariantType.PricePeriod": "/mo",

    "Card.DescriptionVariantType.AnnualDescription": "facturé annuellement à {{value}} $",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Votre plan actuel",
    "Card.DescriptionVariantType.Button.Start": "Démarrez maintenant",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Facturation avec marque",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Produits phares",
    "Card.DescriptionVariantType.SupplierSourcing": "Approvisionnement fournisseur",
    "Card.DescriptionVariantType.ProductRequests": "Demandes de produit",
    "Card.DescriptionVariantType.BulkCheckout": "Paiement groupé",
    "Card.DescriptionVariantType.Special": "spécial",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Votre abonnement Spocket a pris fin.",
    "ReactivationBanner.InfoSubtitle": "Réactivez votre",
    "ReactivationBanner.CheckList.HighQuality": "Qualité supérieure",
    "ReactivationBanner.CheckList.Curated": "Conservé",
    "ReactivationBanner.CheckList.FastShipping": "Livraison rapide",
    "ReactivationBanner.CheckList.FromUSAndEU": "Depuis les États-Unis et l’Europe",
    "ReactivationBanner.TextByPlan.Starter.Description":
      "Vous ne pourrez plus <1>mettre en avant des produits</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Compte Spocket",
    "ReactivationBanner.TextByPlan.Starter.Subtitle":
      "Commencez à mettre en avant des produits dans votre boutique maintenant",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Vous n’êtes plus capable de <1>Mettre en avant des produits</1> en direct ou d’utiliser ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Produits premium",
    "ReactivationBanner.TextByPlan.Others.Title": "Compte Premium",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Accédez à des produits Premium qui sont",
    "ReactivationBanner.Button": "Réactivez mon compte MAINTENANT",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Plus de mises en avant de produit",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Commandes illimitées",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Facturation avec marque",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Produits premium",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Affaires exclusives",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Assistance par chat et par téléphone",
    "UpgradeReasonModal.Modal.Title": "Quel est votre objectif avec Spocket ?",
    "UpgradeReasonModal.Modal.Question": "Qu’est-ce qui vous a poussé à une mise à niveau ?",
    "UpgradeReasonModal.Modal.Button": "Commencez à utiliser Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Cela nous permet d’améliorer votre expérience",

    "UpgradeSuccessModal.Col.One.A": "Félicitations",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Vous avez débloqué tous les outils dont vous avez besoin pour développer une activité de dropshipping fructueuse.",
    "UpgradeSuccessModal.Col.Two.B": "Vous pouvez maintenant avoir ",
    "UpgradeSuccessModal.Col.Two.C": " Bon amusement chez Spocket !",
    "UpgradeSuccessModal.Button": "Continuer",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 mois",
    "Promotion.TrialDaysNormalizer.28days": "4 semaines",
    "Promotion.TrialDaysNormalizer.21days": "3 semaines",
    "Promotion.TrialDaysNormalizer.14days": "2 semaines",
    "Promotion.TrialDaysNormalizer.7days": "1 semaine",
    "Promotion.TrialDaysNormalizer.1day": "1 jour",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} jours",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Essayez gratuitement",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Essayez maintenant →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Langue",

    "WhatYouWillLoseModal.Button.Offer": "Continuer",
    "WhatYouWillLoseModal.Button.Downgrade": "J’abandonne, déclassez mon compte",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Vous allez perdre tous les <1>{{product_count}} produits importés</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} en plus",
    "WhatYouWillLoseModal.ModalWrapper.Header": "vous abandonnez déjà ?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Obtenez une remise de 50 %",
    "WhatYouWillLoseModal.ModalWrapper.Title":
      "Poursuivez votre expérience entrepreneuriale pendant les 3 prochains mois.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Nous voulons que vous soyez une autre réussite exemplaire. Nous savons que vous avez déjà investi beaucoup de temps. Alors, continuez. Nous nous occupons de tout. Équipe Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Félicitations, nous avons maintenant ajouté une remise de 50 % à votre plan pour les 3 prochains mois !",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Continuer",

    "DowngradeReasonModal.DowngradeSection":
      "Comment pouvons-nous faire en sorte que Spocket fonctionne encore mieux pour vous ?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Nous aimerions connaître votre ressenti sur tout (100 caractères minimum)",
    "DowngradeReasonModal.DowngradeSection.Tooltip":
      "Veuillez saisir votre commentaire (100 caractères minimum)",
    "DowngradeReasonModal.Button.Downgrade": "Déclasser",
    "DowngradeReasonModal.ModalWrapper.Header": "Déclasser {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Contactez-nous",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Annuler",

    "DowngradeSuccessModal.Header": "Déclassement réussi",
    "DowngradeSuccessModal.Body":
      "Merci pour votre commentaire. Nous améliorons constamment Spocket tous les jours, nous espérons que vous nous donnerez une autre chance bientôt !",

    "Settings.title": "Paramètres",
    "Settings.buttonSaveChanges": "Enregistrer les modifications",
    "Settings.buttonSaveAndPreview": "Enregistrer et prévisualiser",
    "Settings.PricingPlans.Title": "Programmes tarifaires",

    "Settings.MenuTab.Plans": "Programmes",
    "Settings.MenuTab.Account": "Compte",
    "Settings.MenuTab.BrandedInvoicing": "Facturation avec marque",
    "Settings.MenuTab.Billing": "Facturation",
    "Settings.MenuTab.GlobalPricingRules": "Règles de tarification globales",
    "Settings.MenuTab.Security": "Sécurité",

    "AccountTab.documentTitle": "Paramètres - Compte - Spocket",
    "AccountTab.title": "Paramètres du compte",
    "AccountTab.shopName": "Nom du magasin",
    "AccountTab.email": "E-mail",
    "AccountTab.shopUrl": "URL du magasin",
    "AccountTab.defaultCurrency": "Devise par défaut",
    "AccountTab.yourPlan": "Votre plan",
    "AccountTab.accountNotActive": "Votre compte n’est pas actif actuellement",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Cette fonctionnalité sera bientôt ajoutée ! Pour l’instant, veuillez connecter l’appli Spocket Shopify !",
    "AccountTab.alertAccountSettingsUpdated": "Paramètres du compte mis à jour",
    "AccountTab.alertInvalidShopUrl": "URL du magasin non valide",
    "AccountTab.productsImported": "Produits importés",
    "AccountTab.products": "Produits",
    "AccountTab.premiumProducts": "Produits premium",
    "AccountTab.total": "total",
    "AccountTab.signOut": "Déconnexion",
    "AccountTab.limitReachedProductsAndPremium": "Limite des produits et produits Premium atteinte.",
    "AccountTab.limitReachedProducts": "Limite des produits atteinte.",
    "AccountTab.limitReachedPremium": "Limite des produits Premium atteinte.",
    "AccountTab.buttonSwitchToAnnualPlan": "Passez au plan annuel",
    "AccountTab.off30Percent": "Remise de 30 %",
    "AccountTab.off40Percent": "Remise de 40 %",
    "AccountTab.off45Percent": "Remise de 45 %",
    "AccountTab.sellMoreWithEmpirePlan": "Vendez plus avec le plan Empire",
    "AccountTab.tooltipCurrency":
      "Votre devise est gérée via votre compte de magasin. Tous les prix pour vos produits importés seront convertis dans cette devise.",
    "AccountTab.shopNotConnectedYet": "Votre magasin n’a pas encore été connecté à ce compte.",
    "AccountTab.connectYourShop": "Connectez votre boutique",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Paramètres - Facturation - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Paramètres de facturation enregistrés !",
    "InvoiceTab.shopLogo": "Logo du magasin",
    "InvoiceTab.contactEmail": "E-mail de contact",
    "InvoiceTab.phoneNumber": "Numéro de téléphone",
    "InvoiceTab.personalNote": "Note personnelle",
    "InvoiceTab.tooltipPersonalNote":
      "Déterminez une note personnelle à inclure sur chacune de vos factures mentionnant votre marque",
    "InvoiceTab.brandedInvoicing": "Facturation avec marque",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Ajoutez le logo de vos marques et une note personnelle sur toutes les commandes des fournisseurs qui offrent la facturation avec marque lorsque vous faites partie d’un plan payant",
    "InvoiceTab.disabledInvoicing": "Intégrez une facturation avec marque sur toutes les commandes",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Désactiver cette option exclura la facturation avec marque lorsque vous passez vos commandes",

    "PricingTab.titleDefaultPricingRules": "Règles de tarification par défaut",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Règles de tarification approfondies",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Attribuez des Cents",
    "PricingTab.tooltipAssignCents":
      "Cette valeur sera utilisée lors de l’affichage du prix final pour vos articles (par ex., si vous souhaitez que le coût de votre produit soit de XX,99 alors ajoutez 99 aux champs ci-dessous).",
    "PricingTab.Markup": "Marge",
    "PricingTab.MarkupType": "Type de marge",
    "PricingTab.SelectDefault": "-- Sélectionnez-en un --",
    "PricingTab.SelectPercent": "Pourcent",
    "PricingTab.SelectMultiplier": "Multiplicateur",
    "PricingTab.SelectFixed": "Fixe",
    "PricingTab.titlePricingRules": "Paramètres - Règles de tarification - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Bascule vers les règles de tarification approfondies",
    "PricingTab.from": "De",
    "PricingTab.to": "À",
    "PricingTab.selectFixedAmount": "Montant fixe",
    "PricingTab.buttonRemove": "Supprimer",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Vous ne pouvez pas créer plus d’une règle avec des champs vides",
    "PricingTab.costRange": "Fourchette des coûts",
    "PricingTab.markup": "Marge",
    "PricingTab.add": "Ajouter",

    "SecurityTab.Document.Title": "Paramètres - Sécurité - Spocket",
    "SecurityTab.alertIncorrectPassword": "Mot de passe incorrect",
    "SecurityTab.title": "Sécurité",
    "SecurityTab.yourNewPassword": "Votre nouveau mot de passe",
    "SecurityTab.repeatPassword": "Répétez le mot de passe",
    "SecurityTab.errorPleaseEnterAPassword": "Veuillez saisir un mot de passe",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Veuillez saisir la confirmation du mot de passe.",
    "SecurityTab.errorPasswordsDoNotMatch": "Les mots de passe ne coïncident pas",
    "SecurityTab.errorPasswordShort": "Le mot de passe doit comporter au moins 8 caractères",

    "ListingCards.TopSupplierTag.Tooltip":
      "Les meilleurs fournisseurs sont connus pour leur produits de grande qualité, leur service extra et leurs très bonnes notations",
    "ListingCards.TopSupplierTag.Text": "Fournisseur",

    "ListingCard.OriginCountry.Intro": "Par",
    "ListingCard.OriginCountry.Tooltip": "Expédition depuis",
    "ListingCard.Shipping.Price.Title": "Frais d’expédition",
    "ListingCard.Shipping.Price.Free": "GRATUIT",
    "ListingCard.Price.Intro": "Liste des coûts",
    "ListingCard.Price.Retail": "Prix au détail",
    "ListingCard.Shipping.Time.Title": "Délai de livraison",
    "ListingCard.Shipping.Time.Period": "jours",
    "ListingCard.PremiumIcon.Tooltip":
      "Vendez des produits exclusifs, à fortes remises et à livraison rapide sur votre boutique",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Ajouter à la liste d’importation",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Mis en avant sur la boutique",
    "ListingCard.StatusTag.Imported.Title": "Importé",
    "ListingCard.StatusTag.Imported.Text": "Cet article se trouve déjà sur votre Liste d’importation",
    "ListingCard.StatusTag.Pushed.Title": "En Boutique",
    "ListingCard.StatusTag.Pusehd.Text": "Cet article était mis en avant dans votre boutique",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Description de produit",
    "ListingModal.DetailsSection.Button.ProductVariations": "Ouvrir des versions de produit",
    "ListingModal.DetailsSection.Button.OrderSamples": "Commandez des échantillons",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Nouveauté",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "La facturation avec marque n’est pas disponible pour ce produit",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Les commandes internationales seront expédiées de notre entrepôt en Chine",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Les articles qui sont en rupture de stock dans l’entrepôt des États-Unis peuvent être envoyés depuis notre entrepôt en Chine",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Les commandes peuvent être envoyées en plusieurs colis afin de réduire le délai de livraison global sur vos commandes",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Connectez votre boutique pour passer commande",
    "ListingModal.DetailsSection.PushedListButton": "Mis en avant sur la boutique",
    "ListingModal.DetailsSection.RemoveListButton": "Supprimer de la liste",
    "ListingModal.DetailsSection.ImportListButton": "Ajouter à la liste d’importation",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Liste désactivée",

    "InfoSection.ShippingTime.Tooltip": "Nombre de jours pour que le produit soit livré une fois expédié.",
    "InfoSection.ShippingTime.Title": "Délai de livraison",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "International",
    "InfoSection.ShippingExcluded.Intro": "N’est pas expédié vers",
    "InfoSection.ShippingExcluded.Various": "Divers",
    "InfoSection.ProcessingTime.Tooltip":
      "Nombre de jours ouvrables pour que le fournisseur expédie et fournisse le numéro de suivi",
    "InfoSection.ProcessingTime.Title": "Délai de traitement",
    "InfoSection.ProcessingTime.Period": "jours ouvrables",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Ce produit expédie pour {{price_formatted}}, plus {{incremental_price_formatted}} pour chaque produit supplémentaire dans la même commande",
    "InfoSection.ShippingInfoLine.Period": "jours ouvrables",
    "InfoSection.ShippingInfoLine.Free": "GRATUIT",
    "InfoSection.ShippingInfoLine.NoShipping": "N’expédie pas",

    "ReturnPolicySection.Title": "Politique de retour",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "De",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Par",
    "TitleSection.PriceMSRP.Listing": "Liste des prix",
    "TitleSection.PriceMSRP.Retail": "Prix au détail",

    "ProductVariationsModal.Title": "Versions de produit",
    "ProductVariationsModal.Table.Image": "Image",
    "ProductVariationsModal.Table.Inventory": "Inventaire",
    "ProductVariationsModal.Table.Price": "Prix",
    "ProductVariationsModal.ImageSpecifications.Title": "Guide des tailles",
    "ProductVariationsModal.ImageSpecifications.Button": "Télécharger",

    "TopSupplier.Tooltip":
      "Les meilleurs fournisseurs sont connus pour leur produits de grande qualité, leur service extra et leurs très bonnes notations",
    "TopSupplier.Text": "Premier fournisseur",

    "BestSelling.Tooltip.Top": "Il s’agit des produits les plus performants sur Spocket",
    "BestSelling.Tooltip.Bottom": "Meilleure vente",

    "GallerySection.PremiumIcon.Tooltip":
      "Vendez des produits exclusifs, à fortes remises et à livraison rapide sur votre boutique",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "Remise supplémentaire de {{value}} %",

    "Upgrade.Title.AnnualToggled": "Obtenez Spocket premium aujourd’hui",
    "Upgrade.Title.MonthlyToggled": "Essayez Spocket premium gratuitement pendant 14 jours",
    "Upgrade.Subtitle":
      "Essayez le plan premium de Spocket aujourd’hui et commencez à ajouter des produits à conversion élevée des USA et de l’Europe dans votre magasin.",
    "Upgrade.Button": "Continuez sans Spocket premium",

    "Search.NoProductsFound.Title": "Désolé ! Aucun résultat trouvé",
    "Search.NoProductsFound.For": "pour",
    "Search.NoProductsFound.Description":
      "Veuillez vérifier l’orthographe, essayez un mot-clé apparenté ou un mot-clé plus général.",
    "Search.Searching.Title": "Nous recherchons des produits...",
    "Search.Searching.Description":
      "Veuillez patienter pendant que nous chargeons les derniers produits pour vous.",

    "Suppliers.StarRating.Tooltip": "Taux de réussite d’exécution des commandes fournisseur",
    "Suppliers.RefundPolicy": "Politique de remboursement du fournisseur",
    "Suppliers.Joined.Title": "Spocket rejoint",
    "Suppliers.Origin.Title": "Expédition depuis pays",
    "Suppliers.Time.Title": "Délai de traitement moyen",
    "Suppliers.HasMore.Title": "Nous recherchons des produits...",
    "Suppliers.HasMore.Subtitle":
      "Veuillez patienter pendant que nous chargeons les derniers produits pour vous.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Les meilleurs fournisseurs sont connus pour leur produits de grande qualité, leur service extra et leurs très bonnes notations",
    "Suppliers.TopSupplierTag.Text": "Premier fournisseur",

    "AltTextModal.Header.Title": "Modifier les détails de l’image",
    "AltTextModal.Body.Description":
      "Rédigez une brève description de cette image pour améliorer l’optimisation de moteur de recherche (référencement) ainsi que l’accessibilité aux clients malvoyants.",
    "AltTextModal.Input.Label": "Alt Texte",
    "AltTextModal.Input.Placeholder": "Image ALT Texte",
    "AltTextModal.Button.Cancel": "Annuler",
    "AltTextModal.Button.Save": "Enregistrer",

    "WistiaModal.WhatIsSpocket.Title": "Qu’est-ce que Spocket ?",
    "WistiaModal.WhatIsSpocket.Text":
      "Apprenez comment Spocket peut vous aider à développer votre activité. Comprenez comment Spocket fonctionne et comment vous pouvez en tirer le maximum.",
    "WistiaModal.WhatIsSpocket.Button": "Lancer la recherche de produits",
    "WistiaModal.DiscoverProducts.Title": "Découvrez les produits",
    "WistiaModal.DiscoverProducts.Text":
      "Recherchez les bons produits avec l’aide des bons filtres et ajoutez-les à votre magasin.",
    "WistiaModal.DiscoverProducts.Button": "Lancer la recherche de produits",
    "WistiaModal.ProductCustomization.Title": "Personnalisez vos produits",
    "WistiaModal.ProductCustomization.Text":
      "Utilisez la liste d’importation pour modifier vos produits pour convenir à votre boutique. Vous pouvez modifier les descriptions de produit, fixer les prix, ajouter le produit à une collection ainsi que supprimer des images.",
    "WistiaModal.ProductCustomization.Button": "Aller à la liste d’importation",
    "WistiaModal.OrderProcessing.Title": "Commande en cours de traitement automatisé",
    "WistiaModal.OrderProcessing.Text":
      "Honorez vos commandes et faites en sorte que le fournisseur livre le produit au pas de la porte du client en un clic.",
    "WistiaModal.OrderProcessing.Button": "Aller sur Commandes",
    "WistiaModal.Settings.Title": "Paramètres",
    "WistiaModal.Settings.Text":
      "La page des paramètres vous permet de gérer toutes les informations de votre compte, apposer votre marque sur les factures et ajouter votre carte de crédit. Vous pouvez également adapter les règles de tarification pour tous vos produits et choisir un plan d’abonnement.",
    "WistiaModal.Settings.Button": "Configurez votre compte",

    "Footer.Copyright": "Droit d’auteur {{année}}, Spocket. Tous droits réservés",
    "Footer.PrivacyPolicy": "Politique de confidentialité",
    "Footer.TermsConditions": "Conditions d’utilisation",
    "Footer.DataProcessingAddendum": "Addendum de traitement de données",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English

    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    
    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English

    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English
    
    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
