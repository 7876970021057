export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.",

    "OrderList.Header.Title": "My Orders List",
    "OrderList.Input.Search": "Order number...",
    "OrderList.Label.Status": "Filter By Spocket Status",

    "order.status.unpaid": "Unpaid",
    "order.status.paid": "Paid",
    "order.status.processing": "Processing",
    "order.status.shipped": "Shipped",
    "order.status.cancelled": "Canceled",
    "order.status.pending": "Pending",
    "order.status.partially_paid": "Partially Paid",
    "order.status.partially_refunded": "Partially Refunded",
    "order.status.refunded": "Refunded",
    "order.status.voided": "Voided",
    "order.status.authorized": "Authorized",
    "order.status.deleted": "Deleted",
    "order.status.pending_payment_confirmation": "Pending",

    "OrderList.Label.DownloadHistory": "Download all order history CSV",
    "OrderList.Document.Title": "Orders - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Search Products",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "How to process orders",
    "OrderList.EmptyOrders.Message.Loading.Title": "Your orders are being loaded!",
    "OrderList.EmptyOrders.Message.Loading.Description": "Please wait while we retrieve your orders.",
    "OrderList.EmptyOrders.Message.Title": "Your orders list is empty!",
    "OrderList.EmptyOrders.Message.Description":
      "You can manage and process your orders here. Head over to the search page to start adding products to your import list.",
    "OrderList.CheckBox.SelectAllOrders": "Select all orders",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} order selected",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} order selected",
    "OrderList.CheckBox.BulkCheckoutOrders": "Bulk checkout order",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Bulk checkout orders",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation",
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.",
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.",

    "RefreshModal.Button.SynchronizeOrders": "Synchronize Orders",
    "RefreshModal.Button.Cancel": "Cancel",
    "RefreshModal.Button.Synchronize": "Synchronize",
    "RefreshModal.Modal.Body": "Orders are synced automatically from your store. Only sync if order information is missing or has not updated. Please refresh the page after 30 seconds.",

    "Order.Label.OrderNumber": "Order Number",
    "Order.Label.Date": "Date",
    "Order.Label.OrderStatus": "{{integrationName}} Order Status",
    "Order.Table.Th.Product": "Product",
    "Order.Table.Th.Qty": "QTY",
    "Order.Table.Th.UnitPrice": "Unit Price",
    "Order.Table.Th.CheckoutDate": "Checkout Date",
    "Order.Table.Th.Action": "Action",

    "MessageSupplierModal.Title": "Message your supplier directly!",
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!",
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier",
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier",

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.",
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!",
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.",
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel",
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel",
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support",
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support",

    "CustomerModal.Button.ViewCustomerInfo": "View Customer Info",
    "CustomerModal.Modal.Title.CustomerInfo": "Customer Info",
    "CustomerModal.Modal.Body.CustomerInfo": "Customer Info",
    "CustomerModal.Modal.Body.Address": "Address",

    "OrderLine.Button.Checkout": "Checkout",
    "OrderLine.Button.Alibaba": "Alibaba Details",
    "OrderLine.Tooltip.PreviewInvoice": "Preview Invoice",
    "OrderLine.Tooltip.PaymentReceipt": "Payment Receipt",
    "OrderLine.Tooltip.TrackOrder": "Track Order",
    "OrderLine.Tooltip.OrderProcessing": "Order processing",
    "OrderLine.Tooltip.OrderCancelled": "Order cancelled",
    "OrderLine.Tooltip.PleaseConfirm": "Please click to confirm your order payment",
    "OrderLine.Label.PurchaseEtsy": "Purchase on Etsy",
    "OrderLine.Paying": "Paying",
    "OrderLine.Failure": "Failure",
    "OrderLine.Sample": "Sample",
    "OrderLine.ErrorOrderRow": "Cannot find ordered listing in the system! Please contact support!",

    "ConfirmationModal.Label.OrderDetails": "Order Details",
    "ConfirmationModal.Label.Total": "Total",
    "ConfirmationModal.Label.Shipping": "Shipping",
    "ConfirmationModal.Label.TransactionFees": "Transaction Fees",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Transaction fees include fees charged by Stripe, Spocket's payment provider as well as service fees.",
    "ConfirmationModal.Modal.OrderConfirmation": "Order Confirmation",
    "ConfirmationModal.Modal.Alert.Attention": "Attention!",
    "ConfirmationModal.Modal.Alert.Description": "This order contains a note from the customer.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "You are about to place an order for",
    "ConfirmationModal.Modal.Table.Th.Name": "Name",
    "ConfirmationModal.Modal.Table.Th.Price": "Price",
    "ConfirmationModal.Modal.Table.Th.Qty": "QTY",
    "ConfirmationModal.Modal.Table.Th.Total": "Total",
    "ConfirmationModal.Modal.Label.Note": "Note to supplier",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Your message",
    "ConfirmationModal.Button.PlaceOrder": "Place order",

    "PaymentCompleteModal.PaymentCompleted": "Payment Completed",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Thanks for the payment. The supplier has been notified and will process the order shortly. We will update the status of the order and add the tracking number in your orders page.",
    "PaymentCompleteModal.PaymentIncomplete": "Your order is incomplete!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Your bank requires confirmation of your payment to proceed",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink": "Please use this link to confirm the payment.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Once payment is confirmed, the supplier will be notified and your order will process as normal. Status and tracking updates will be added to your orders page. Please reload the page after confirmation.",

    "SetYourGoalsModal.Title": "Number of products added to the store",
    "SetYourGoalsModal.Subtitle": "A typical store pushes 25 products before getting sales",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} product",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} products",
    "SetYourGoalsModal.Button": "Set my goal and start dropshipping",

    "YourGoalsBanner.Container.Description": "Your Goals",
    "YourGoalsBanner.Header.Title": "Starting a business",
    "YourGoalsBanner.Header.Subtitle": "Get your store ready",
    "YourGoalsBanner.Progress.Completed": "Completed",
    "YourGoalsBanner.ProductPushedCount": "<0>Goal: {{goalsNumberOfProduct}}</0> products pushed",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Join Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "First search",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} Product Pushed",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} Products Pushed",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} Product Imported",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} Products Imported",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Automated AliExpress Dropshipping",
    "Aliscraper.Subtitle":
      "Import thousands of AliExpress products to your eCommerce store with one click and automate your dropshipping business.",
    "Aliscraper.Features.Feature1": "Fast import with one click",
    "Aliscraper.Features.Feature2": "Process hundreds of orders instantly with Spocket",
    "Aliscraper.Features.Feature3": "24/7 Customer Support",
    "Aliscraper.Button": "Download the Extension",
    "Aliscraper.Chrome": "Available in the Chrome Web Store",

    "AliscraperPage.Title": "AliScraper",
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>",
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.",
    "AliscraperPage.ButtonCTA": "Download AliScraper Free",
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store",
    "AliscraperPage.InstallCount": "50,000+ installs",
    "AliscraperPage.Reviews": "(155 reviews)",
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>",
    "AliscraperPage.HowItWorks.Title": "How it works",
    "AliscraperPage.HowItWorks.Install": "Install",
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>",
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>",
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>",
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>",
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>",
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>",
    "AliscraperPage.Installed": "You have successfully installed AliScraper",
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>",
    "AliscraperPage.AliExpressLink": "Go to AliExpress",

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label",
    "SpocketLabs.TopPartner": "Spocket Top Partner",
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire",
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.",
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging",
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity",
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping",
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE",
    "SpocketLabs.HowItWorks.Title": "How it works",
    "SpocketLabs.HowItWorks.Step1": "Install the app",
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand",
    "SpocketLabs.HowItWorks.Step3": "Find beauty products",
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉",

    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover dropshipping products on the go and add them to your store with the tap of your finger. Build your business from anywhere at any time with the Spocket iOS app!", // English
    "SpocketIphoneApp.Features.Feature1": "Conveniently manage orders on your phone", // English
    "SpocketIphoneApp.Features.Feature2": "Be the first to check out trending new products", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover dropshipping products on the go and add them to your store with the tap of your finger. Build your business from anywhere at any time with the Spocket Android app!", // English
    "SpocketAndroidApp.Features.Feature1": "Manage orders on your phone from anywhere", // English
    "SpocketAndroidApp.Features.Feature2": "Be the first to check out trending products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Discover fast shipping dropshipping products from Alibaba and import them directly to your store within a few clicks.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Access Alibaba.com",

    "ConnectStoreModal.Header.Title": "Connect your Store",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!",
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?",
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free",
    "ConnectStoreModal.Header.Featured": "Featured",
    "ConnectStoreModal.Footer.Text": "Choose your platform to get started",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Invalid store name",
    "ConnectStoreModal.ShopifyForm.Placeholder": "e.g MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Try for free",
    "ConnectStoreModal.ShopifyForm.Button": "Connect Store",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store",
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.",
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?",
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free",
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!",
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store",
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page",
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions",
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Your Auth key:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "How to connect your store to Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Install the Spocket plugin from",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "here",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Activate the plugin in your plugins' page",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Go to WordPress Dashboard > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4": 'Paste your Auth key (listed above) click on "Save"',
    "ConnectStoreModal.WoocommerceInstruction.Button": "Done",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free",
    "ConnectStoreModal.WixForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.WixForm.FooterLink": "Try for free",
    "ConnectStoreModal.WixForm.Button": "Connect Store",
    "ConnectStoreModal.FelexForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.FelexForm.FooterLink": "Try for free",
    "ConnectStoreModal.FelexForm.Button": "Connect Store",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce Store URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Invalid store name",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "e.g MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Try for free",
    "ConnectStoreModal.BigcommerceForm.Button": "Connect Store",
    "ConnectStoreModal.EcwidForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Try for free",
    "ConnectStoreModal.EcwidForm.Button": "Connect Store",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Try for free",
    "ConnectStoreModal.WhiteLabelForm.Button": "Connect Store",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Try for free",
    "ConnectStoreModal.SquarespaceForm.Button": "Connect Store",
    "ConnectStoreModal.SquareForm.FooterText": "Don't have a store?",
    "ConnectStoreModal.SquareForm.FooterLink": "Try for free",
    "ConnectStoreModal.TryForFree": "Try for free",
    "ConnectStoreModal.FelexButton.Explanation":
      "A web store creation platform for entrepreneurs to launch dropshipping store in less than 10 minutes.",
    "ConnectStoreModal.SquareForm.Button": "Connect Store",
    "ConnectStoreModal.DontHaveStore": "Don't have a store?",

    "PaymentTab.Document.Title": "Settings - Automation - Spocket",
    "PaymentTab.Title.Billing": "Billing",
    "PaymentTab.Tooltip.Billing": "Credit cards will be used for order processing and subscription payments",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Pause/Change your plan",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Change your plan",
    "PaymentTab.AccordionItem.Button.Downgrade": "Downgrade",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Pause your plan",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Your store has already been paused before",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Pause your plan",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "You can pause your Spocket account temporarily and come back at any time without losing any data.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Remind me later",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Remind me later",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 days before",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Keep my benefits and remind me <1>{{daysBefore}}</1> my membership renews",
    "RemindMeLaterModal.Header.ReminderSet": "Reminder set",
    "RemindMeLaterModal.Body.DaysBefore": "email you 3 days before",
    "RemindMeLaterModal.Body.ReminderSet":
      "Your reminder is set and we will <1>{{daysBefore}}</1> your benefits are renewed, until then keep going with your entrepreneurial journey.",
    "RemindMeLaterModal.Button.Continue": "Continue",
    "PauseStoreModal.Title.PauseYourStore": "Pause Your Store",
    "PauseStoreModal.Description.PauseYourStore":
      "Take advantage of this one-time offer and reduce your plan price by 60% for the next 30 days",
    "PauseStoreModal.Button.PauseStore": "Pause Store",
    "PauseStoreModal.Description.StorePaused":
      "Your store has been paused and your next month's subscription will be reduced by 60%. Please note, regular payments will resume afterwards.",
    "ConfirmPasswordModal.Title": "Please Confirm Your Password",
    "ConfirmPasswordModal.SubTitle": "For security reasons, please confirm your password",
    "ConfirmPasswordModal.Input.Placeholder": "Your password",
    "ConfirmPasswordModal.Button.Cancel": "Cancel",
    "ConfirmPasswordModal.Button.Confirm": "Confirm",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.",

    "NewPasswordForm.Title": "Please set a password before logging out!",
    "NewPasswordForm.PasswordInput.Placeholder": "New Password",
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password",

    "Config.MomentJs.Locale": "en", // List of locales https://stackoverflow.com/a/55827203
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Grow faster with Spocket ", // Keep the extra space in the end
    "UpgradeConfirmationModal.Body.Title": "Choose your billing cycle",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Your first",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "", // Keep the extra space in the end (if active)
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "days ", // Keep the extra space in the end (if active)
    "UpgradeConfirmationModal.BulletPoints.Trial.Final": "are free, cancel during or after your trial",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Your first bill will be scheduled for",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial": "Your first bill will be due upon confirmation",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "Billing will recur monthly",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Monthly ", // Keep the extra space in the end
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/month",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Yearly ", // Keep the extra space in the end
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/month",
    "UpgradeConfirmationModal.YearlyTab.Discount": "Off",
    "UpgradeConfirmationModal.TaxRates.Text": "sales tax",
    "UpgradeConfirmationModal.TaxRates.Period": "mo",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "To pay now",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/month X 12 months",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Your Savings: ", // Keep the extra space in the end
    "UpgradeConfirmationModal.YearlyPanel.Period": "/year",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "equivalent to",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% off",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Yearly plans do not have a trial period",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "You will be charged in full immediately",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Pay with credit/debit card",
    "UpgradeConfirmationModal.PayPalTab.Info": "Pay with PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Payment details ", // Keep the extra space in the end
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Claim my free trial",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Upgrade to ", // Keep the extra space in the end
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Trusted by 150,000+ Entrepreneurs",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom": "Our customers have consistently rated us 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128-bit SSL encryption",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "All your payment details are 100% safe with us.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "100,000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Trusted by 150,000+ Entrepreneurs",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom": "Our customers have consistently rated us 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128-bit SSL encryption",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "All your payment details are 100% safe with us.",
    "UpgradeConfirmationModal.Agreement.Intro": "By proceeding, you agree to the ", // Keep the extra space in the end
    "UpgradeConfirmationModal.Agreement.Terms": "Terms of Use",
    "UpgradeConfirmationModal.Agreement.Privacy": "Privacy Policy",
    "UpgradeConfirmationModal.Agreement.Refund": "Refund Policy",
    "UpgradeConfirmationModal.Agreement.Middle": ", and",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Cancelation Policy",

    "OrderDetails.Title": "Order details",

    "CreditCard.Update.Label": "Credit/Debit Card",
    "CreditCard.Update.InputValue": "Credit card ending in",
    "CreditCard.Update.Button": "Update Card",
    "CreditCard.AddCreditCard.Text": "You have not added any credit card yet. Please add a credit card from the button below to allow us process your orders automatically",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",
    "CreditCard.AddCreditCard.Button": "Add Card",

    "CardDeclineModal.Title": "Your current subscription payment is overdue",
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.",
    "CardDeclineModal.Button.Title": "Update your credit card",
    "CardDeclineModal.Button.Label": "Update your card",

    "Sidebar.SearchProducts": "Find Products",
    "Sidebar.UsEuProducts": "US/EU Products",
    "Sidebar.AliExpress": "AliExpress",
    "Sidebar.ImageSearch": "Image Search",
    "Sidebar.Jubilee": "Private Label",
    "Sidebar.WinningProducts": "Winning Products",
    "Sidebar.WinningProducts.Variant": "New",
    "Sidebar.ImportList": "Import List",
    "Sidebar.MyProducts": "Products",
    "Sidebar.MyOrders": "Orders",
    "Sidebar.Notifications": "Notifications",
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Help Center",
    "Sidebar.MyShop": "Your Store",
    "Sidebar.Settings": "Settings",
    "Sidebar.MyMessages": "Supplier Chat",
    "Sidebar.Academy": "Academy",

    "TutorialModal.Title": "We’re here to help you!",
    "TutorialModal.WhatIsSpocket": "What is Spocket?",
    "TutorialModal.DiscoverProducts": "Discover Products",
    "TutorialModal.ProductCustomization": "Product Customization",
    "TutorialModal.OrderProcessing": "Order Processing",
    "TutorialModal.Settings": "Settings",
    "TutorialModal.GetHelp": "Get Help",
    "TutorialModal.JoinTheCommunity": "Join the community",

    "NotificationCenter.Header": "Notification Center",
    "NotificationCenter.Footer": "See All Notifications",
    "NotificationCenter.NoNotification": "Nothing to See Here!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.",

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store",
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store",
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store",

    "NotificationCenter.EmptyState.Title": "You have no notifications",
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates",
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification",
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification",
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read",

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read",
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list",

    "NotificationCenter.TableHeader.Shipping": "Shipping type",
    "NotificationCenter.TableHeader.Product": "SKU",
    "NotificationCenter.TableHeader.OldCost": "Old Cost",
    "NotificationCenter.TableHeader.NewCost": "New Cost",

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List",
    "NotificationCenter.NotificationCard.Restored": "Restored",
    "NotificationCenter.NotificationCard.Read": "Mark as Read",
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread",
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.",

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update",
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update",
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>",
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost",
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}",
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ",

    "Products.Title.Head": "My Products - Spocket", // Page title
    "Products.Title.Header": "My Products",
    "Products.Loading.Top": "Your product list is loading!",
    "Products.Loading.Bottom": "Please wait while we retrieve your products.",
    "Products.Empty.Search": "No products were found!",
    "Products.Empty.Top": "Your product list is empty!",
    "Products.Empty.Bottom": "Head over to the search page to start adding products to your import list.",
    "Products.Empty.Button.Search": "Search Products",
    "Products.Empty.Button.How": "How to find products",
    "Products.Alert.Removed": "Product removed",

    "Products.Search.Label": "Search Your Products",
    "Products.Search.Placeholder": "Search Your Products",
    "Products.Search.Button": "Search",

    "ProductListItem.Inactive.Tooltip": "This product is no longer available from the supplier",
    "ProductListItem.Inactive.Title": "Inactive",
    "ProductListItem.OutOfStock.Tooltip": "All variations of this product are out of stock",
    "ProductListItem.OutOfStock.Title": "Out of Stock",
    "ProductListItem.InventoryCount.Singular": "piece",
    "ProductListItem.InventoryCount.Plural": "pieces",
    "ProductListItem.InventoryCount.End.Singular": "left",
    "ProductListItem.InventoryCount.End.Plural": "left",
    "ProductListItem.Button.View": "View on Store",
    "ProductListItem.Button.Remove": "Remove From Store",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock",

    "ImportList.Title.Head": "Import List - Spocket", // Page title
    "ImportList.Title.Header": "My Import List",
    "ImportList.Title.Error": "Failed to Push",
    "ImportList.Loading.Top": "Your import list is loading!",
    "ImportList.Loading.Bottom": "Please wait while we retrieve your products.",
    "ImportList.Empty.Button.Search": "Search Products",
    "ImportList.Empty.Top": "Your import list is empty!",
    "ImportList.Empty.Bottom": "Head over to the search page to start adding products to your import list.",
    "ImportList.Empty.Button.How": "How to customize products",
    "ImportList.Alert.Pushed": "Product successfully pushed to store",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:",
    "ImportList.LowStockVariants.Label": "Low stock variants",

    "ImportList.Actions.PushAll.Label": "Push All",
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store",
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ",
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.",
    "ImportList.Actions.RemoveAll.Label": "Remove All",
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List",
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List",
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ",

    "ImportList.PushAllModal.Title": "Push All?",
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.",
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:",
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>",
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase",
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>",
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price",
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products",
    "ImportList.PushAllModal.CancelButton.Label": "Cancel",
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store",
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All",
    "ImportList.PushAllModal.Minutes": "minute(s)",
    "ImportList.PushAllModal.Hours": "hour(s)",

    "ImportList.RemoveAllModal.Title": "Remove All",
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.",
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products",
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel",
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List",
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure",

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>",
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes",
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes",
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.",
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!",
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!",
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.",
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.",

    "ImportList.Description.Stats.Words": "Words:",
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>",

    "ImportList.Search.Label": "Search through your imported products",
    "ImportList.Search.Placeholder": "Search Your Products",
    "ImportList.Search.Button": "Search",

    "ImportList.PageTitle": "Import List",
    "ImportList.HeaderTags.Premium": "Premium Product",
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page",
    "ImportList.LinksSection.ProductLink.Text": "View Product",
    "ImportList.LinksSection.ProductLink.Title": "Visit product page",
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.",
    "ImportList.CategorySection.Label": "Category",
    "ImportList.CategorySection.Placeholder": "Enter category",
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.",
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.",
    "ImportList.CategoriesSection.Label": "Categories",
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added",
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories",
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes",
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.",
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.",
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category",
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes",
    "ImportList.CategoriesSection.Modal.Values": "Values",
    "ImportList.CategoriesSection.Modal.Apply": "Apply",
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel",
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection",
    "ImportList.CategoriesSection.Modal.Error": "All fields are required",
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here",
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.",
    "ImportList.CollectionSection.Label": "Collection(s)",
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported",
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider",
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.",

    "ImportList.Footer.RemoveButton.Label": "Remove Product",
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List",
    "ImportList.Footer.SaveButton.Label": "Save",
    "ImportList.Footer.SaveButton.Title": "Save item changes",
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes",

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.",
    "ImportList.ImageAltText.Placeholder": "Enter Alt text",
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.",
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text",
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text",
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image",
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image",

    "ImportList.ListingErrors.Title": "Your listing has the following errors:",
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.",
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.",
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.",
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.",
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.",
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.",
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.",

    "ImportList.ConnectStorePopup.Title": "Connect Your Store",
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>",
    "ImportList.ConnectStorePopup.Cancel": "Cancel",
    "ImportList.ConnectStorePopup.Submit": "Go to My Store",

    "PushAllModal.Button.Active": "Pushing Current Page Products",
    "PushAllModal.Button.Inactive": "Push Current Page",
    "PushAllModal.Modal.Text": "Are you sure you want to push all products from this current page? All of the valid products on the current page will be pushed to your store.",
    "PushAllModal.Modal.Push": "Push All",
    "PushAllModal.Modal.Cancel": "Cancel",

    "RemoveAllModal.Button.Active": "Removing Current Page Products",
    "RemoveAllModal.Button.Inactive": "Remove Current Page",
    "RemoveAllModal.Modal.Text": "Are you sure you want to remove all products from this current page? All of the products on the current page of your will be removed from the import list.",
    "RemoveAllModal.Modal.Remove": "Remove All",
    "RemoveAllModal.Modal.Cancel": "Cancel",

    "ImportListItem.Tab.Product": "Product",
    "ImportListItem.Tab.Description": "Description",
    "ImportListItem.Tab.Variants": "Variants",
    "ImportListItem.Tab.Images": "Images",
    "ImportListItem.Remove.Active": "Removing Product",
    "ImportListItem.Remove.Inactive": "Remove Product",
    "ImportListItem.Push.Active": "Pushing To Store",
    "ImportListItem.Push.Inactive": "Push To Store",
    "ImportListItem.Save.Button": "Save",
    "ImportListItem.Alert.Removed": "This product has been removed from your import list: ",
    "ImportListItem.Alert.RemoveFailed": "Something went wrong trying to remove: ",
    "ImportListItem.Alert.CompareAtPrice": "Compare at price should be greater than sales price for: ",
    "ImportListItem.Alert.OneActiveVariation": "This product needs at least one active variant: ",
    "ImportListItem.Alert.Saved": "This product has been saved: ",
    "ImportListItem.Alert.ErrorPushing": "Something went wrong when pushing to your store",
    "ImportListItem.Alert.ErrorSaving": "Something went wrong when saving your store",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.",
    "ImportListItem.Modal.ConnectStore": "Connect your store to push products: ",
    "ImportListItem.Tooltip.OneActiveVariation": "You need at least one Active Variation",
    "ImportListItem.Tooltip.Unavailable": "This product is currently unavailable for purchase",
    "ImportListItem.Tooltip.Unsaved": "You have unsaved changes",
    "ImportListItem.Tooltip.Profit": "Warning: Your gross profit is negative for this product",
    "ImportListItem.Tooltip.Unpurchasable": "You may save this product, but it is currently unavailable for purchase",
    "ImportListItem.Variants.ShowShippingPrices": "Show Shipping Prices",

    "ImportList.Filters": "Filters",
    "ImportList.Filters.Title": "Filter",
    "ImportList.Filters.Supplier": "Supplier",
    "ImportList.Filters.Supplier.Placeholder": "Select supplier",
    "ImportList.Filters.ProductsFrom": "Products from",
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket",
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress",
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from",
    "ImportList.Filters.Button.Reset": "Reset",
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options",
    "ImportList.Filters.Button.Apply": "Apply Filter",
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters",

    "ImportList.Variants.SelectAll": "Select All",
    "ImportList.Variants.Image": "Image",
    "ImportList.Variants.Sku": "SKU",
    "ImportList.Variants.Inventory": "Inventory",
    "ImportList.Variants.Cost": "Cost",
    "ImportList.Variants.Shipping": "Shipping",
    "ImportList.Variants.Profit": "Profit",
    "ImportList.Variants.SalesPrice": "Sales Price",
    "ImportList.Variants.CompareAtPrice": "Compare At Price",
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase",
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.",
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price",
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices",
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit",
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin",
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price",
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price",
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants",
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value",
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants",
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants",
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping",
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices",
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details",
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item",
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock",
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants",
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected",

    "ItemInfo.ProductName.Label": "Product Name",
    "ItemInfo.ProductTags.Label": "Product Tags",
    "ItemInfo.ProductTags.Remove": "Remove All Tags",
    "ItemInfo.ProductTags.Placeholder": "Add New Tag",
    "ItemInfo.ProductType.Label": "Product Type",
    "ItemInfo.Collection.Label": "Collection",
    "ItemInfo.Collection.Placeholder": "Select",
    "ItemInfo.Collection.Option": "Select",
    "ItemInfo.SquareLocation.Option": "Select",

    "ItemVariantList.Modal.Title": "Shipping Details",
    "ItemVariantList.Modal.Intro": "Ships From: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "This item does not ship internationally.",
    "ItemVariantList.Modal.DoesNotShipTo": "Does not ship to: ",
    "ItemVariantList.Table.Head.Destination": "Destination",
    "ItemVariantList.Table.Head.Cost": "Cost",
    "ItemVariantList.Table.Head.Time": "Delivery Time (Days)",
    "ItemVariantList.Table.Body.Domestic": "Domestic",
    "ItemVariantList.Table.Body.International": "International",
    "ItemVariantList.VariantsTable.ShippingPrice": "Shipping Price ", // Keep the extra space in the end
    "ItemVariantList.VariantsTable.Image": "Image",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Inventory",
    "ItemVariantList.VariantsTable.Price": "Price",
    "ItemVariantList.VariantsTable.SalesPrice": "Sales Price ", // Keep the extra space in the end
    "ItemVariantList.VariantsTable.Profit": "Profit",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Compare At Price",
    "ItemVariantList.VariantsTable.Unavailable": "This product is currently unavailable for purchase",
    "ItemVariantList.VariantsTable.Head": "All of the variants for this product are shown here.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ex: 40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify does not support over 100 variantions.",

    "ItemVariantList.ContextPopover.Title": "Set Profit",
    "ItemVariantList.ContextPopover.Apply": "Apply",

    "ProductVariation.Description": "You can place orders for sample products directly on Spocket. Follow the steps below for a quick easy checkout.",
    "ProductVariation.Label.SelectVariant": "Select variant",
    "ProductVariation.Label.SelectTheProductVariation": "Select the product variation",
    "ProductVariation.Qty": "Qty",
    "ProductVariation.Label.NotesForTheSupplier": "Notes for the supplier",

    "InputAddress.Label.FirstName": "First Name",
    "InputAddress.PlaceHolder.FirstName": "Enter First Name",
    "InputAddress.Label.LastName": "Last Name",
    "InputAddress.PlaceHolder.LastName": "Enter Last Name",
    "InputAddress.Label.StreetAddress": "Street Address",
    "InputAddress.Label.Country": "Country",
    "InputAddress.PlaceHolder.Country": "Search Country",
    "InputAddress.Label.State": "State",
    "InputAddress.Placeholder.State": "Search State",
    "InputAddress.Label.City": "City",
    "InputAddress.PlaceHolder.City": "Enter City",
    "InputAddress.Label.Zip": "Zip",
    "InputAddress.PlaceHolder.Zip": "Enter Zip code",
    "InputAddress.Label.Phone": "Phone Number",
    "InputAddress.Placeholder.Phone": "Enter phone number",

    "OrderReview.OrderDetails": "Order details",
    "OrderReview.Unit": "{{count}} Unit",
    "OrderReview.Unit_plural": "{{count}} Units",
    "OrderReview.ShippingAddress": "Shipping address",
    "OrderReview.PaymentMethod": "Payment method",
    "OrderReview.Amounts": "Amounts",
    "OrderReview.Subtotal": "Subtotal",
    "OrderReview.ShippingCost": "Shipping cost",
    "OrderReview.TransactionFees": "Transaction Fees",
    "OrderReview.TransactionFees.ToolTips":
      "Transaction fees include fees charged by Stripe, Spocket's payment provider as well as service fees.",
    "OrderReview.OrderTotal": "Order Total",
    "OrderReview.ItemWithCount": "{{count}} item",
    "OrderReview.ItemWithCount_plural": "{{count}} items",
    "OrderReview.Placeholder.CreditCard": "Credit card ending in {{last4}}",

    "PaymentComplete.PaymentCompleted": "Payment Completed",
    "PaymentComplete.PaymentCompleted.Note":
      "Thanks for the payment. The supplier has been notified and will process the order shortly. We will update the status of the order and add the tracking number in your orders page.",
    "PaymentComplete.PaymentIncomplete": "Your order is incomplete!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Your bank requires confirmation of your payment to proceed",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "Please use this link to confirm the payment.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Once payment is confirmed, the supplier will be notified and your order will process as normal. Status and tracking updates will be added to your orders page. Please reload the page after confirmation.",

    "CreditCard.AddCreditCard.Description":
      "You have not added any credit card yet. Please add a credit card from the button below to allow us process your orders automatically",
    "CreditCard.Button.AddCreditCard": "Add Credit Card",
    "CreditCard.Button.UpdateCard": "Update Card",
    "CreditCard.UpdateCard.Label.CreditCard": "Credit Card",
    "CreditCard.PlaceHolder.CreditCard": "Credit card ending in {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Credit Card",
    "UpdateCreditCardModal.Button.UpdateCard": "Update Card",
    "UpdateCreditCardModal.Button.AddCreditCard": "Add Card",
    "UpdateCreditCardModal.Button.Cancel": "Cancel",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Update your card details",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Your account will be used during order processing of Spocket products.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Terms and conditions",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "for processing payments.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.",

    "ExtraCreditCardInputs.Label.Name": "Full Name",
    "ExtraCreditCardInputs.Label.Country": "Country",

    "SampleOrderModal.Title.SelectVariation": "Select Variation",
    "SampleOrderModal.Title.ShippingAddress": "Shipping Address",
    "SampleOrderModal.Title.PaymentMethod": "Payment Method",
    "SampleOrderModal.Title.Review": "Review",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Payment Confirmation Required!",
    "SampleOrderModal.Title.OrderPlaced": "Order Placed!",
    "SampleOrderModal.Steps": "Step {{current}} of {{total}}",
    "SampleOrderModal.FinalStep": "Final Step",
    "SampleOrderModal.ConfirmPayment": "Confirm Payment!",
    "SampleOrderModal.Finished": "Finished",
    "SampleOrderModal.Footer.Continue": "Continue",
    "SampleOrderModal.Footer.PlaceOrder": "Place order",
    "SampleOrderModal.Footer.Close": "Close",
    "SampleOrderModal.Footer.Cancel": "Cancel",
    "SampleOrderModal.Footer.GoBack": "Go Back",

    "AdvancedFiltersModal.Title.Filters": "Filters",
    "AdvancedFiltersModal.Title.Shipping": "Shipping",
    "AdvancedFiltersModal.Label.ShipsFrom": "Ships from",
    "AdvancedFiltersModal.Label.ShipsTo": "Ships to",
    "AdvancedFiltersModal.Label.ShippingTime": "Shipping time",
    "AdvancedFiltersModal.Label.Popular": "Popular",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "United States",
    "AdvancedFiltersModal.Label.Country.Europe": "Europe",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Only available for shipment to the US",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Any Days",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 Days",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 Days",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 Days",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15+ Days",
    "AdvancedFiltersModal.Title.ItemsCost": "Items cost",
    "AdvancedFiltersModal.Label.ItemCost": "Item cost",
    "AdvancedFiltersModal.Label.ShippingCost": "Shipping cost",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Any Cost",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Free",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "$5 and less",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "$15 and less",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "$25 and less",
    "AdvancedFiltersModal.Title.Supplier": "Supplier",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Suppliers",
    "AdvancedFiltersModal.Label.TopSupplier": "Top supplier",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Suppliers known for their high-quality products, great service and high ratings reviews",
    "AdvancedFiltersModal.Title.Advanced": "Advanced",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Premium products",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Highly discounted and fast shipping products",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Best Seller",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Top performing products on Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Cancel",
    "AdvancedFiltersModal.Button.ViewResults": "View Results",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Search Country…",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Countries",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Search Supplier…",
    "AdvancedFiltersModal.Label.All Suppliers": "All Suppliers",
    "AdvancedFiltersModal.Label.New Suppliers": "New Suppliers",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies",
    "AdvancedFiltersModal.Label.Currency": "Currency:",
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)",
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)",
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}",

    "AdvancedFilters.ShippingTime.Any": "Any Days",
    "AdvancedFilters.ShippingTime.One": "1-3 Days",
    "AdvancedFilters.ShippingTime.Four": "4-7 Days",
    "AdvancedFilters.ShippingTime.Eight": "8-14 Days",
    "AdvancedFilters.ShippingTime.Fifteen": "15+ Days",

    "AdvancedFilters.ShippingCost.Free": "Free Shipping",
    "AdvancedFilters.ShippingCost.Five": "Shipping Under $5",
    "AdvancedFilters.ShippingCost.Fifteen": "Shipping Under $15",
    "AdvancedFilters.ShippingCost.TwentyFive": "Shipping Under $25",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Clear All",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Shipping",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Search",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Ships From",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Ships To",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Supplier",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Sort By",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Item Cost",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Top Suppliers",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Personalized Invoices",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Best Seller",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "from {{from}} to {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}",
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory",

    "Search.Header.PlaceHolder.Search": "Enter keywords…",
    "Search.Header.Button.Search": "Search",
    "Search.Header.Button.Filters": "Filters",

    "Navigation.UpgradeButton.TryPro": "Try Spocket Pro for FREE",
    "Navigation.UpgradeButton.TryEmpire": "Try Spocket EMPIRE for FREE",
    "Navigation.UpgradeButton.TryUnicorn": "Try Spocket Unicorn for FREE",

    "CategoriesCards.Label.MoreCategories": "More Categories",

    "StaticCategories.WomensClothing": "Women's Clothing",
    "StaticCategories.WomensClothing.Activewear": "Activewear",
    "StaticCategories.WomensClothing.Bodysuits": "Bodysuits",
    "StaticCategories.WomensClothing.Dresses": "Dresses",
    "StaticCategories.WomensClothing.JacketsCoats": "Jackets & Coats",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Jumpsuits & Rompers",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lingerie & Underwear",
    "StaticCategories.WomensClothing.MatchingSets": "Matching Sets",
    "StaticCategories.WomensClothing.Pants": "Pants",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Skirts",
    "StaticCategories.WomensClothing.Sleepwear": "Sleepwear",
    "StaticCategories.WomensClothing.Socks": "Socks",
    "StaticCategories.WomensClothing.SweatersHoodies": "Sweaters & Hoodies",
    "StaticCategories.WomensClothing.Swimwear": "Swimwear",
    "StaticCategories.WomensClothing.TankTops": "Tank Tops",
    "StaticCategories.WomensClothing.TopsBlouses": "Tops & Blouses",
    "StaticCategories.WomensClothing.Tshirts": "T-shirts",

    "StaticCategories.JewelryWatches": "Jewelry & Watches",
    "StaticCategories.JewelryWatches.Bracelets": "Bracelets",
    "StaticCategories.JewelryWatches.Earrings": "Earrings",
    "StaticCategories.JewelryWatches.Necklaces": "Necklaces",
    "StaticCategories.JewelryWatches.OtherAccessories": "Other Accessories",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Pendants, Stones & Charms",
    "StaticCategories.JewelryWatches.Rings": "Rings",
    "StaticCategories.JewelryWatches.Sets": "Sets",
    "StaticCategories.JewelryWatches.Watches": "Watches",

    "StaticCategories.TechAccessories": "Tech Accessories",
    "StaticCategories.TechAccessories.AudioVideo": "Audio & Video",
    "StaticCategories.TechAccessories.CasesCovers": "Cases & Covers",
    "StaticCategories.TechAccessories.Lighting": "Lighting",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Mobile & Laptop Accessories",
    "StaticCategories.TechAccessories.Mousepads": "Mousepads",
    "StaticCategories.TechAccessories.Novelty": "Novelty",

    "StaticCategories.KidsBabies": "Kids & Babies",
    "StaticCategories.KidsBabies.BabyClothing": "Baby Clothing",
    "StaticCategories.KidsBabies.Bathing": "Bathing",
    "StaticCategories.KidsBabies.BlanketsPillows": "Blankets & Pillows",
    "StaticCategories.KidsBabies.CapsHeadbands": "Caps & Headbands",
    "StaticCategories.KidsBabies.Footwear": "Footwear",
    "StaticCategories.KidsBabies.Furniture": "Furniture",
    "StaticCategories.KidsBabies.KidsClothing": "Kid's Clothing",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Parenthood & Accessories",

    "StaticCategories.Toys": "Toys",
    "StaticCategories.Footwear": "Footwear",
    "StaticCategories.Footwear.Boots": "Boots",
    "StaticCategories.Footwear.Flats": "Flats",
    "StaticCategories.Footwear.Heels": "Heels",
    "StaticCategories.Footwear.Mens": "Mens",
    "StaticCategories.Footwear.Sandals": "Sandals",
    "StaticCategories.Footwear.Slippers": "Slippers",
    "StaticCategories.Footwear.SneakersRunners": "Sneakers & Runners",

    "StaticCategories.BathBeauty": "Bath & Beauty",
    "StaticCategories.BathBeauty.Bodycare": "Bodycare",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffusers, Oils & Candles",
    "StaticCategories.BathBeauty.Haircare": "Haircare",
    "StaticCategories.BathBeauty.Healthcare": "Healthcare",
    "StaticCategories.BathBeauty.Makeup": "Makeup",
    "StaticCategories.BathBeauty.Nails": "Nails",
    "StaticCategories.BathBeauty.Skincare": "Skincare",
    "StaticCategories.BathBeauty.TowelsRobes": "Towels & Robes",

    "StaticCategories.Pets": "Pets",
    "StaticCategories.Pets.BedsBlankets": "Beds & Blankets",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Leashes, Collars & Petwear",
    "StaticCategories.Pets.Petcare": "Petcare",
    "StaticCategories.Pets.Toys": "Toys",

    "StaticCategories.HomeGarden": "Home & Garden",
    "StaticCategories.HomeGarden.Bathroom": "Bathroom",
    "StaticCategories.HomeGarden.Clocks": "Clocks",
    "StaticCategories.HomeGarden.Drinkware": "Drinkware",
    "StaticCategories.HomeGarden.FoodBeverage": "Food & Beverage",
    "StaticCategories.HomeGarden.Furniture": "Furniture",
    "StaticCategories.HomeGarden.Garden": "Garden",
    "StaticCategories.HomeGarden.HomeDecor": "Home Decor",
    "StaticCategories.HomeGarden.HomeImprovement": "Home Improvement",
    "StaticCategories.HomeGarden.Kitchen": "Kitchen",
    "StaticCategories.HomeGarden.Lighting": "Lighting",
    "StaticCategories.HomeGarden.Organization": "Organization",
    "StaticCategories.HomeGarden.StationeryCrafts": "Stationery & Crafts",
    "StaticCategories.HomeGarden.TextilesPillows": "Textiles & Pillows",
    "StaticCategories.HomeGarden.Tools": "Tools",

    "StaticCategories.Accessories": "Accessories",
    "StaticCategories.Accessories.Belts": "Belts",
    "StaticCategories.Accessories.Cufflinks": "Cufflinks",
    "StaticCategories.Accessories.Hats": "Hats",
    "StaticCategories.Accessories.Keychains": "Keychains",
    "StaticCategories.Accessories.ScarvesBandanas": "Scarves & Bandanas",
    "StaticCategories.Accessories.Sunglasses": "Sunglasses",

    "StaticCategories.MensClothing": "Men's Clothing",
    "StaticCategories.MensClothing.Activewear": "Activewear",
    "StaticCategories.MensClothing.DressShirts": "Dress Shirts",
    "StaticCategories.MensClothing.JacketsCoats": "Jackets & Coats",
    "StaticCategories.MensClothing.Pants": "Pants",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Socks",
    "StaticCategories.MensClothing.SweatersHoodies": "Sweaters & Hoodies",
    "StaticCategories.MensClothing.Swimwear": "Swimwear",
    "StaticCategories.MensClothing.TankTops": "Tank Tops",
    "StaticCategories.MensClothing.Tshirts": "T-shirts",
    "StaticCategories.MensClothing.Underwear": "Underwear",

    "StaticCategories.SportsOutdoors": "Sports & Outdoors",
    "StaticCategories.SportsOutdoors.Activewear": "Activewear",
    "StaticCategories.SportsOutdoors.Biking": "Biking",
    "StaticCategories.SportsOutdoors.Camping": "Camping",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Equipment & Accessories",
    "StaticCategories.SportsOutdoors.Fishing": "Fishing",

    "StaticCategories.Gifts": "Gifts",
    "StaticCategories.Gifts.Adults": "Adults",
    "StaticCategories.Gifts.Funny": "Funny",
    "StaticCategories.Gifts.Mugs": "Mugs",
    "StaticCategories.Gifts.Novelty": "Novelty",
    "StaticCategories.Gifts.Personalized": "Personalized",
    "StaticCategories.Gifts.Religious": "Religious",

    "StaticCategories.Automotive": "Automotive",
    "StaticCategories.BagsWallets": "Bags & Wallets",
    "StaticCategories.Seasonal": "Seasonal",
    "StaticCategories.Seasonal.Christmas": "Christmas",
    "StaticCategories.Seasonal.FathersDay": "Father's Day",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Mother's Day",

    "StaticCategories.FestivalsParties": "Festivals & Parties",
    "StaticCategories.FestivalsParties.PartySupplies": "Party Supplies",
    "StaticCategories.FestivalsParties.Wedding": "Wedding",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Fast USA shipping",
    "QuickFilters.Label.Under5": "USA shipping under $5",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", //English
    "QuickFilters.Label.ShowAll": "Show All", //English
    "QuickFilters.Label.ShipsFrom": "<1/> Ships from <2/> {{ country }}", //English
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", //English
    "QuickFilters.Label.Currency": "Currency",
    "QuickFilters.Label.Currency.All": "All",

    "SortBy.Label.SortBy": "Sort by",
    "SortBy.DropDown.Item.Relevance": "Relevance",
    "SortBy.DropDown.Item.PriceLowToHigh": "Price: low to high",
    "SortBy.DropDown.Item.PriceHighToLow": "Price: high to low",
    "SortBy.DropDown.Item.Newest": "Newest",
    "SortBy.DropDown.Item.ShippingTime": "Shipping time",
    "SortBy.DropDown.Item.Default": "Default",
    "SortBy.Label.SortBy.Price": "Sort by Price",
    "SortBy.DropDown.Item.LowToHigh": "Low To High",
    "SortBy.DropDown.Item.HighToLow": "High To Low",

    "Breadcrumb.Item.Home": "Home",
    "Breadcrumb.Item.Search": "Search",

    "UpgradeModal.Modal.Title": "Select a pricing plan",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.",
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", 
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular",

    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>",

    "Plans.ToggleSection.Monthly": "Monthly",
    "Plans.ToggleSection.Yearly": "Yearly",
    "Plans.ToggleSection.DiscountInitial": "Get Up to",
    "Plans.ToggleSection.DiscountSingular": "Month",
    "Plans.ToggleSection.DiscountPlural": "Months",
    "Plans.ToggleSection.DiscountFinal": "Off",
    "Plans.ProrationCost.TextInitial": "You are currently paying",
    "Plans.ProrationCost.TextFinal": "/yr. Upgrade to Empire for only an additional",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app",

    "Card.Title.PeriodSingular": "month",
    "Card.Title.PeriodPlural": "months",
    "Card.Title.Off": "off",
    "Card.DescriptionVariantType.Starter": "Start getting sales",
    "Card.DescriptionVariantType.Professional": "Boost sales",
    "Card.DescriptionVariantType.Empire": "Maximize sales",
    "Card.DescriptionVariantType.Unicorn": "Manage sales at scale",
    "Card.DescriptionVariantType.Period": "mo",
    "Card.DescriptionVariantType.PricePeriod": "/mo",
    // eslint-disable-next-line
    "Card.DescriptionVariantType.AnnualDescription": "billed annually at ${{value}}",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial",
    "Card.DescriptionVariantType.CurrentPlan": "Your current plan",
    "Card.DescriptionVariantType.Button.Start": "Start now",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE",

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:",
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:",
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:",
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:",

    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products",
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products",
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support",
    "Card.DescriptionVariantType.Invoicing": "Branded invoicing",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support",
    "Card.DescriptionVariantType.WinningProducts": "Winning Products",
    "Card.DescriptionVariantType.SupplierSourcing": "Supplier sourcing",
    "Card.DescriptionVariantType.ProductRequests": "Product requests",
    "Card.DescriptionVariantType.BulkCheckout": "Bulk checkout",
    "Card.DescriptionVariantType.Special": "special",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping",
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee",
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog",
    "Card.DescriptionVariantType.ImageSearch": "Image Search",
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers",
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping",
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders",
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>",
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat",
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited",
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy",


    "ReactivationBanner.Alert": "Your Spocket subscription has ended.",
    "ReactivationBanner.InfoSubtitle": "Reactivate your",
    "ReactivationBanner.CheckList.HighQuality": "High quality",
    "ReactivationBanner.CheckList.Curated": "Curated",
    "ReactivationBanner.CheckList.FastShipping": "Fast Shipping",
    "ReactivationBanner.CheckList.FromUSAndEU": "From US and EU",
    "ReactivationBanner.TextByPlan.Starter.Description":
      "You will no longer be able to <1>push any Products</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket Account",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Start pushing products to your store now",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "You are no longer be able to <1>Push Products</1> live or use ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Premium Products",
    "ReactivationBanner.TextByPlan.Others.Title": "Premium Account",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Get access to Premium Products that are",
    "ReactivationBanner.Button": "Reactivate my account NOW",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer",
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%",
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}",
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes",
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds",
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.",
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual",
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual",
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan",
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan",
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.",
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".",

    "UpgradeReasonModal.Benefits.IncreasedProducts": "More Product Pushes",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Unlimited Orders",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Branded Invoicing",
    "UpgradeReasonModal.Benefits.PremiumProducts": "Premium Products",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Exclusive Deals",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Chat & Call Support",
    "UpgradeReasonModal.Modal.Title": "What do you want to achieve with Spocket?",
    "UpgradeReasonModal.Modal.Question": "What made you upgrade?",
    "UpgradeReasonModal.Modal.Button": "Start Using Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "It helps us improve your experience",

    "UpgradeSuccessModal.Col.One.A": "Congratulations",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!",
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!",
    "UpgradeSuccessModal.Col.Two.A":
      "You’ve unlocked all the tools you need to build a successful dropshipping business.",
    "UpgradeSuccessModal.Col.Two.B": "You can now have ",
    "UpgradeSuccessModal.Col.Two.C": " Happy Spocketing!",
    "UpgradeSuccessModal.Button": "Continue",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:",

    "Promotion.TrialDaysNormalizer.30days": "1 month",
    "Promotion.TrialDaysNormalizer.28days": "4 weeks",
    "Promotion.TrialDaysNormalizer.21days": "3 weeks",
    "Promotion.TrialDaysNormalizer.14days": "2 weeks",
    "Promotion.TrialDaysNormalizer.7days": "1 week",
    "Promotion.TrialDaysNormalizer.1day": "1 day",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} days",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!",
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products",
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers",
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!",
    "Promotion.Button": "Try for free",
    "Promotion.Button.ExpiredTrial": "Start Now",
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for",
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}",
    "StickyPromotion.Button": "Try now →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →",


    "Language.Title": "Language",

    "WhatYouWillLoseModal.Button.Offer": "Keep going",
    "WhatYouWillLoseModal.Button.Downgrade": "I give up, downgrade my account",
    "WhatYouWillLoseModal.ProductSection.Title": "You’ll lose all <1>{{product_count}} imported products</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} more",
    "WhatYouWillLoseModal.ModalWrapper.Header": "are you giving up already?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Get 50% off",
    "WhatYouWillLoseModal.ModalWrapper.Title": "Continue your entrepreneurial journey for the next 3 months.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "We want you to be another success story. We know you have already invested a lot of time. So keep going. We are all rooting for you. Team Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Congratulations, we have now added a 50% discount to your plan for the next 3 months!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Continue",

    "DowngradeReasonModal.DowngradeSection": "How can we make Spocket work better for you?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "We want your feedback about everything (100 character minimum)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Please enter your feedback (100 character minimum)",
    "DowngradeReasonModal.Button.Downgrade": "Downgrade",
    "DowngradeReasonModal.ModalWrapper.Header": "Downgrade {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Contact us",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Cancel",

    "DowngradeSuccessModal.Header": "Downgrade Successful",
    "DowngradeSuccessModal.Body":
      "Thank you for your feedback. We are constantly improving Spocket everyday, hope you give us another chance soon!",

    "Settings.title": "Settings",
    "Settings.buttonSaveChanges": "Save Changes",
    "Settings.buttonSaveAndPreview": "Save and Preview",
    "Settings.PricingPlans.Title": "Pricing Plans",

    "Settings.MenuTab.Plans": "Plans",
    "Settings.MenuTab.Account": "Account",
    "Settings.MenuTab.BrandedInvoicing": "Branded Invoicing",
    "Settings.MenuTab.Billing": "Billing",
    "Settings.MenuTab.GlobalPricingRules": "Global Pricing Rules",
    "Settings.MenuTab.Security": "Security",

    "AccountTab.documentTitle": "Settings - Account - Spocket",
    "AccountTab.title": "Account Setting",
    "AccountTab.shopName": "Shop Name",
    "AccountTab.email": "Email",
    "AccountTab.SquareLocation": "Square Location",
    "AccountTab.shopUrl": "Shop URL",
    "AccountTab.defaultCurrency": "Default Currency",
    "AccountTab.yourPlan": "Your Plan",
    "AccountTab.accountNotActive": "Your account is not currently active",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "This feature will be added soon! For now, please connect through the Spocket Shopify app!",
    "AccountTab.alertAccountSettingsUpdated": "Account settings updated",
    "AccountTab.alertInvalidShopUrl": "Invalid Shop URL",
    "AccountTab.productsImported": "Products Imported",
    "AccountTab.products": "Products",
    "AccountTab.premiumProducts": "Premium Products",
    "AccountTab.total": "total",
    "AccountTab.signOut": "Sign Out",
    "AccountTab.limitReachedProductsAndPremium": "Products and Premium Products limit reached.",
    "AccountTab.limitReachedProducts": "Products limit reached.",
    "AccountTab.limitReachedPremium": "Premium Products limit reached.",
    "AccountTab.buttonSwitchToAnnualPlan": "Switch to Annual Plan",
    "AccountTab.off30Percent": "30% Off",
    "AccountTab.off40Percent": "40% Off",
    "AccountTab.off45Percent": "45% Off",
    "AccountTab.sellMoreWithEmpirePlan": "Sell More with Empire Plan",
    "AccountTab.tooltipCurrency": "Your currency is managed via your store account. All prices for your imported products will be converted to that currency.",
    "AccountTab.shopNotConnectedYet": "Your shop has not been connected with this account yet.",
    "AccountTab.connectYourShop": "Connect Your Shop",

    "SignOut.SignOutConfirmation.Title": "Log Out?",
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?",
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket",
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out",
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out",
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel",

    "SignOut.SignOutNewPassword.Title": "Hold Up!",
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!",
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket",
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out",
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out",
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel",
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password",
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation",
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match",
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer",

    "InvoiceTab.documentTitle": "Settings - Invoicing - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Invoice settings saved!",
    "InvoiceTab.shopLogo": "Shop Logo",
    "InvoiceTab.contactEmail": "Contact Email",
    "InvoiceTab.phoneNumber": "Phone Number",
    "InvoiceTab.personalNote": "Personal Note",
    "InvoiceTab.tooltipPersonalNote": "Set a personal note to be included in each of your branded invoices",
    "InvoiceTab.brandedInvoicing": "Branded Invoicing",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Add your brands logo and a personal note to all orders from suppliers who offer branded invoicing when on a paid plan",
    "InvoiceTab.disabledInvoicing": "Include Branded Invoicing on All Orders",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Turning off this option will exclude branded invoicing when you place your orders",

    "PricingTab.titleDefaultPricingRules": "Default Pricing Rules",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings",
    "PricingTab.titleAdvancedPricingRules": "Advanced Pricing Rules",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)",
    "PricingTab.assignCents": "Assign Cents",
    "PricingTab.tooltipAssignCents":
      "This value will be used when displaying the final price for your items (e.g., if you want the cost of your product to be XX.99 then add 99 to the fields below).",
    "PricingTab.Markup": "Markup",
    "PricingTab.MarkupType": "Markup Type",
    "PricingTab.SelectDefault": "-- Select One --",
    "PricingTab.SelectPercent": "Percent",
    "PricingTab.SelectMultiplier": "Multiplier",
    "PricingTab.SelectFixed": "Fixed",
    "PricingTab.titlePricingRules": "Settings - Pricing Rules - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Toggle Advanced Pricing Rules",
    "PricingTab.from": "From",
    "PricingTab.to": "To",
    "PricingTab.selectFixedAmount": "Fixed Amount",
    "PricingTab.buttonRemove": "Remove",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "You cannot create more than one rule with empty fields",
    "PricingTab.costRange": "Cost Range",
    "PricingTab.markup": "Markup",
    "PricingTab.add": "Add",

    "SecurityTab.Document.Title": "Settings - Security - Spocket",
    "SecurityTab.alertIncorrectPassword": "Incorrect Password",
    "SecurityTab.title": "Security",
    "SecurityTab.yourNewPassword": "Your New Password",
    "SecurityTab.repeatPassword": "Repeat Password",
    "SecurityTab.errorPleaseEnterAPassword": "Please enter a password.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Please enter a password confirmation.",
    "SecurityTab.errorPasswordsDoNotMatch": "Passwords do not match",
    "SecurityTab.errorPasswordShort": "Password must be 8 characters or longer",

    "ListingCards.TopSupplierTag.Tooltip":
      "Top Suppliers are known for their high-quality products, great service and high ratings",
    "ListingCards.TopSupplierTag.Text": "Supplier",

    "ListingCard.OriginCountry.Intro": "By",
    "ListingCard.OriginCountry.Tooltip": "Ships from",
    "ListingCard.Shipping.Price.Title": "Shipping Price",
    "ListingCard.Shipping.Price.Free": "FREE",
    "ListingCard.Price.Intro": "Listing Cost",
    "ListingCard.Price.Retail": "Retail Price",
    "ListingCard.Shipping.Time.Title": "Shipping Time",
    "ListingCard.Shipping.Time.Period": "days",
    "ListingCard.PremiumIcon.Tooltip": "Sell exclusive, highly discounted and fast shipping products on your shop",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Add to Import List",
    "ListingCard.ListButton.Remove": "Remove from Import List",
    "ListingCard.ListButton.Pushed": "Pushed to Store",
    "ListingCard.StatusTag.Imported.Title": "Imported",
    "ListingCard.StatusTag.Imported.Text": "This item is already on your Import List",
    "ListingCard.StatusTag.Pushed.Title": "In Store",
    "ListingCard.StatusTag.Pusehd.Text": "This item was pushed to your store",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list",
    "ListingCard.OrderSamples": "Order Samples",

    "ListingModal.Page.Title": "Product Details",
    "ListingModal.Tabs.DescriptionHeader": "Product Description",
    "ListingModal.Tabs.ReviewsHeader": "Reviews",
    "ListingModal.MainSection.Title": "Product Description",
    "ListingModal.DetailsSection.Button.ProductVariations": "Open Product Variations",
    "ListingModal.DetailsSection.Button.OrderSamples": "Order Samples",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "New",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert": "Branded Invoicing is not available for this product",
    "ListingModal.DetailsSection.InternationalShipping.Alert": "International orders will be shipped from our warehouse in China",
    "ListingModal.DetailsSection.StockUsa.Alert": "Items that are out-of-stock in the USA warehouse may be sent from our China warehouse",
    "ListingModal.DetailsSection.MultiplePackage.Alert": "Orders may be sent in multiple packages to help reduce overall shipping time on your orders",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Connect your store to place order",
    "ListingModal.DetailsSection.PushedListButton": "Pushed to Store",
    "ListingModal.DetailsSection.RemoveListButton": "Remove from List",
    "ListingModal.DetailsSection.ImportListButton": "Add to Import List",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Listing Deactivated",

    "InfoSection.ShippingTime.Tooltip": "Number of days for the product to be delivered after being shipped.",
    "InfoSection.ShippingTime.Title": "Shipping Time",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.",
    "InfoSection.ShippingInfo.Worldwide": "Worldwide",
    "InfoSection.ShippingExcluded.Intro": "Does not ship to",
    "InfoSection.ShippingExcluded.Various": "Various",
    "InfoSection.ProcessingTime.Tooltip":
      "Number of business days for the supplier to ship and provide tracking number",
    "InfoSection.ProcessingTime.Title": "Processing Time",
    "InfoSection.ProcessingTime.Period": "business days",
    "InfoSection.ShippingInfoLine.Tooltip":
      "This product ships for {{price_formatted}}, plus {{incremental_price_formatted}} for each additional product in the same order",
    "InfoSection.ShippingInfoLine.Period": "business days",
    "InfoSection.ShippingInfoLine.Free": "FREE",
    "InfoSection.ShippingInfoLine.NoShipping": "Does not ship",

    "ReturnPolicySection.Title": "Return Policy",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "From",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "By",
    "TitleSection.PriceMSRP.Listing": "Listing Price",
    "TitleSection.PriceMSRP.Retail": "Retail Price",

    "ProductVariationsModal.Title": "Product Variations",
    "ProductVariationsModal.Table.Image": "Image",
    "ProductVariationsModal.Table.Inventory": "Inventory",
    "ProductVariationsModal.Table.Price": "Price",
    "ProductVariationsModal.ImageSpecifications.Title": "Size Chart",
    "ProductVariationsModal.ImageSpecifications.Button": "Download",

    "TopSupplier.Tooltip":
      "Top Suppliers are known for their high-quality products, great service and high ratings",
    "TopSupplier.Text": "Top Supplier",

    "BestSelling.Tooltip.Top": "This is a top performing product on Spocket",
    "BestSelling.Tooltip.Bottom": "Best Seller",

    "GallerySection.PremiumIcon.Tooltip":
      "Sell exclusive, highly discounted and fast shipping products on your shop",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "Extra {{value}}% Off",

    "Upgrade.Title.AnnualToggled": "Get Spocket premium today",
    "Upgrade.Title.MonthlyToggled": "Try Spocket premium free for 14 days",
    "Upgrade.Subtitle":
      "Try a Spocket premium plan today and start adding high converting products from the US and Europe to your store.",
    "Upgrade.Button": "Continue without Spocket premium",

    "Search.NoProductsFound.Title": "Sorry! We couldn’t find any results",
    "Search.NoProductsFound.For": "for",
    "Search.NoProductsFound.Description":
      "Please check the spelling, try a related search term or a more general search term.",
    "Search.Searching.Title": "We are searching for products...",
    "Search.Searching.Description": "Please wait while we load the latest products for you.",

    "Suppliers.StarRating.Tooltip": "Supplier order fulfillment success rate",
    "Suppliers.RefundPolicy": "Supplier’s refund policy",
    "Suppliers.Joined.Title": "Joined Spocket",
    "Suppliers.Origin.Title": "Shipping from country",
    "Suppliers.Time.Title": "Average processing time",
    "Suppliers.HasMore.Title": "We are searching for products...",
    "Suppliers.HasMore.Subtitle": "Please wait while we load the latest products for you.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Top Suppliers are known for their high-quality products, great service and high ratings",
    "Suppliers.TopSupplierTag.Text": "Top Supplier",

    "AltTextModal.Header.Title": "Edit Image Details",
    "AltTextModal.Body.Description":
      "Write a brief description of this image to improve search engine optimization (SEO) and accessibility for visually impaired customers.",
    "AltTextModal.Input.Label": "Alt Text",
    "AltTextModal.Input.Placeholder": "Image ALT Text",
    "AltTextModal.Button.Cancel": "Cancel",
    "AltTextModal.Button.Save": "Save",

    "WistiaModal.WhatIsSpocket.Title": "What is Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Learn how Spocket can help you build your business. Understand how Spocket works and how you can make the most of it.",
    "WistiaModal.WhatIsSpocket.Button": "Start Finding Products",
    "WistiaModal.DiscoverProducts.Title": "Discover Products",
    "WistiaModal.DiscoverProducts.Text":
      "Find the right products with the help of adequate filters, and add them to your store.",
    "WistiaModal.DiscoverProducts.Button": "Start Finding Products",
    "WistiaModal.ProductCustomization.Title": "Customize your Products",
    "WistiaModal.ProductCustomization.Text":
      "Use the Import List to modify your products to suit your store. You can edit the product descriptions, set the prices, add the product to a collection as well as remove images.",
    "WistiaModal.ProductCustomization.Button": "Go to Import List",
    "WistiaModal.OrderProcessing.Title": "Automated Order Processing",
    "WistiaModal.OrderProcessing.Text":
      "Fulfill your orders, and have the supplier deliver the product right to your customer’s doorstep in one click.",
    "WistiaModal.OrderProcessing.Button": "Go to Orders",
    "WistiaModal.Settings.Title": "Settings",
    "WistiaModal.Settings.Text":
      "The settings page allows you to manage all your account information, brand your invoice and add your credit card. You can also adjust pricing rules for all your products and choose a subscription plan.",
    "WistiaModal.Settings.Button": "Setup Your Account",

    "Footer.Copyright": "Copyright {{year}}, Spocket. All rights reserved",
    "Footer.PrivacyPolicy": "Privacy Policy",
    "Footer.TermsConditions": "Terms & Conditions",
    "Footer.DataProcessingAddendum": "Data Processing Addendum",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.",

    "Maintenance.GoBackHome": "Go Back Home",
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>",

    "ConnectStore.Alert.Message": "Please connect your store",
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL",
    "ConnectStore.Button": "Connect",
    "ConnectStore.Error.MissingUrl": "*Add a store URL",
    "ConnectStore.Error.Select": "*Select a country",
    "ConnectStore.Button.Title": "Connect your store to Ebay",
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.",

    "Products.Search.Filter.Button": "Filter",
    "Products.Filters.Option.LowStock": "Low stock (less than 10)",
    "Products.Filters.Option.OutOfStock": "Out of stock",
    "Products.Filters.Option.Deactivated": "Deactivated",
    "Products.Filters.Option.Default": "Select status",
    "Products.Filters.Title": "Filter",
    "Products.Filters.Subtitle": "Inventory Status",
    "Products.Filters.Button.Reset": "Reset",
    "Products.Filters.Button.Title.Reset": "Reset all filter options",
    "Products.Filters.Button.Apply": "Apply Filter",
    "Products.Filters.Button.Title.Apply": "Apply selected filters",
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ",

    "CardImage.ArrowContainer.NextImage": "Next image",
    "CardImage.ArrowContainer.PreviousImage": "Previous image",

    "Api.Error.Authorization": "Your session has expired. Please log in.",
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.",

    "Settings.Title": "My Settings",
    "Settings.Error.Title": "Internal server error!",
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.",
    "Settings.Tabs.Title.Plans": "Plans",
    "Settings.Tabs.Title.Account": "Account",
    "Settings.Tabs.Title.Membership": "Membership",
    "Settings.Tabs.Title.Store": "Store",
    "Settings.Tabs.Title.Pricing": "Global Pricing",
    "Settings.Tabs.Title.Apps": "Apps",
    "Settings.PageTitle.plans" : "Plan Details - Spocket",
    "Settings.PageTitle.account" : "Account Settings - Spocket",
    "Settings.PageTitle.membership" : "Membership Settings - Spocket",
    "Settings.PageTitle.store" : "Store Settings - Spocket",
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket",
    "Settings.PageTitle.apps" : "Apps - Spocket",

    "Settings.Apps.Title": "Apps",
    "Settings.Apps.Description": "This section contains information about your apps.",
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress",
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket",
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products",
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync",
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location",
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.",
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress",

    "Settings.Membership.MembershipInformation.Title": "Membership Information",
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.",
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.",
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.",
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan",
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at",
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount",
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle",
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until",
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year",
    "Settings.Membership.MembershipUserData.Cycle.Month": "month",
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation",
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.",
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership",
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership",
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.",
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later",
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.",
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.",
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder",
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later",
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder",
    "Settings.Membership.MembershipPause.Title": "Pause your plan",
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.",
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan",
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan",
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.",
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.",
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>",
    "Settings.Membership.UpgradePlan.Yearly": "Yearly",
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers",
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan",
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan",
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>",
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!",
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual",
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan",
    "Settings.Membership.Billing.Header.Billing": "Billing Period",
    "Settings.Membership.Billing.Header.Amount": "Amount",
    "Settings.Membership.Billing.Header.Status": "Status",
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice",
    "Settings.Membership.Billing.Title": "Billing History",
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.",
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.",
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.",
    "Settings.Membership.Billing.Table.Amount.Tooltip": "{{ value }} has been credited",
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>",
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>",
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected",
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided",
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed",
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file",

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier",
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.",
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier",
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On",
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On",
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly",
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature",
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons",
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier",
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount",
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month",
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle",
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go",
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ",
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On",
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99",
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On",
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On",

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.",
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats",
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.",
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products",
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products",
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products",
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products",
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}",
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing",
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.",
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing",
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders",
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing",
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email",
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...",
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email",
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number",
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...",
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL",
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...",
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.",
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note",
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully",
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo",
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice",
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated",
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice",
    "Settings.StoreSettings.SquareLocation.Title": "Square Location",
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.",
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location",
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location",
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save",
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location",
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully",
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store",
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name",
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...",

    "Settings.AccountInformation.Title": "Account Information",
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.",
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password",
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.",
    "Settings.AccountInformation.Password.Title": "New Password",
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...",
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password",
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...",
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again",
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any credit card yet. Please add a credit card from the button below to allow us process your orders automatically",
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following credit card to process your orders automatically.",
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",
    "Settings.AccountInformation.Language.Title": "Language Preference",
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.",
    "Settings.AccountInformation.Language.Label": "Language",

    "ExistingPayPalAccount.Email": "PayPal Account Email",
    "ExistingPayPalAccount.Button.Label": "Remove Account",
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account",
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.",

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?",
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.",
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal",
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account",
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel",
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection",

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.",
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated",
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules",
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products",
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule",
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule",
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>",
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price",
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict",

    "Chat.Title": "Supplier Chat",
    "Chat.Start.ButtonText": "Chat with supplier",
    "Chat.Start.Button": "Start Chat",
    "Chat.Start.Button.Mobile": "Start",
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received",
    "Chat.SupplierOrderInfo.Status.Processing": "Processing",
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped",
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered",
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled",
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:",
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.",
    "Chat.EmptyPlaceholder.Title": "No messages",
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.",
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry",

    "Chat.NewChatMessageModal.Title": "New Message",

    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry",
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry",
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues",
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status",
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund",
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback",
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others",

    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...",

    "Checkout.Title": "Checkout",
    "Checkout.PaymentMethods.Title": "Choose your payment method",
    "Checkout.PaymentMethods.PayPal": "PayPal",
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card",
    "Checkout.PaymentMethods.GPay": "Google Pay",
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card",
    "Checkout.BillingCycles.Title": "Choose your billing cycle",
    "Checkout.BillingCycles.Monthly": "Monthly",
    "Checkout.BillingCycles.Yearly": "Yearly",
    "Checkout.BillingCycleTabs.Off": "<1></1> Off",
    "Checkout.BackButton": "Back",
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout",
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs",
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.",
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.",
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.",
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.",
    "Checkout.SelectedPlanInfo.Year": "year",
    "Checkout.SelectedPlanInfo.Month": "month",
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left",
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount",
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits",
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.",
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.",
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.",
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy",
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy",
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service",
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy",
    "Checkout.Button.Claim": "Claim Your Trial",
    "Checkout.Button.Free": "Claim Free Trial",
    "Checkout.Button.Start": "Start Membership",
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}",
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}",
    "Checkout.ChangePlanLink.Text": "Change Plan",
    "Checkout.ChangePlanLink.Title": "Change the selected plan",
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period",
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.",
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing",
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off",
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products",
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support",
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products",
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded <strong>Invoicing</strong>",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", 
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>",  
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier",
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries",
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping",
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping",
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication",
    "Checkout.PlanFeatures.ImageSearch": "Image Search",
    "Checkout.PlanFeatures.WinningProducts": "Winning Products",  
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>",
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>",
    "Checkout.CouponCode.Button.Title": "Add coupon code",
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?",
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here",
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon",
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply",
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon",
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove",
    "Checkout.CouponCode.Error.Required": "This field is required",

    "ErrorModal.Button.Text":"Talk to Customer Support",
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error",

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers",
    "AddonSuccessModal.Button.Title":"Click the button start chatting",
    "AddonSuccessModal.Button.Label":"Start chatting now",
    "ChatAddonSlider.Slides.New": "New",

    "Cancellation.Back": "Back",
    "Cancellation.Intro": "Hey {{name}} 👋, as part of your current membership, you're enjoying the below features.",
    "Cancellation.SubIntro": "You'll no longer be able to access these <1>features</1> post cancellation.",

    "Benefits.Benefit1.Title": "Premium Products",
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.",
    "Benefits.Benefit2.Title": "Branded Invoicing",
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.",
    "Benefits.Benefit3.Title": "Unique Products",
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.",
    "Benefits.Benefit4.Title": "Bulk Checkout",
    "Benefits.Benefit4.Description": "Process all your orders with just one click.",
    "Benefits.Benefit5.Title": "24/7 Chat Support",
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.",
    "Benefits.Benefit6.Title": "Supplier Sourcing",
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.",
    "Benefits.Benefit7.Title": "Email Support",
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.",
    "Benefits.Cancel.Button.Label": "Downgrade Membership",
    "Benefits.Cancel.Button.Title": "Downgrade your membership",
    "Benefits.Keep.Button.Label": "Keep My Benefits",
    "Benefits.Keep.Button.Title": "Keep benefits",

    "HelperCenter.Tooltip": "Help & Resources",

    "Pagination.Page": "Page",
    "Pagination.Of": "of {{value}}",
    "Pagination.First.Title": "Go to first page",
    "Pagination.Previous.Title": "Go to previous page",
    "Pagination.Next.Title": "Go to next page",
    "Pagination.Last.Title": "Go to last page",

    "Orders.Title.Head": "My Orders - Spocket", // Page title
    "Orders.PageTitle": "Orders",
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>",
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>",
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.",
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information",
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info",
    "Orders.OrderInfo.Sample": "Sample Order",
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>",
    "Orders.Empty.Top": "Your orders list is empty",
    "Orders.Empty.Bottom": "You can manage and proccess your orders here. Head over to the search page to start adding products to your import list.",
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store",
    "Orders.Empty.Button.Search.Label": "Go to search page ",
    "Orders.Search.NoOrdersFound.Title": "No results for your search",
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.",
    "Orders.Checkout.Button.Label": "Checkout",
    "Orders.Checkout.Button.Title": "Checkout these items",
    "Orders.Cancel.Button.Enabled": "Cancel this order",
    "Orders.Cancel.Button.Disabled": "This order can't be canceled",
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):",
    "Orders.OrderStatus.DropdownLabel": "Order Status",
    "Orders.OrderStatus.ChipLabel": "Order status: ",
    "Orders.OrderStatus.Cancelled": "Canceled",
    "Orders.OrderStatus.Refunded": "Refunded",
    "Orders.OrderStatus.Unpaid": "Unpaid",
    "Orders.OrderStatus.Paid": "Order Placed",
    "Orders.OrderStatus.Processing": "Processing",
    "Orders.OrderStatus.Shipping": "Shipped",
    "Orders.ExportButton.Label": "Download Order History CSV",
    "Orders.ExportModal.Title": "Select Range",
    "Orders.ExportModal.From": "From",
    "Orders.ExportModal.To": "To",
    "Orders.ExportModal.Submit": "Download CSV",
    "Orders.Search.Label": "Search through your orders",
    "Orders.Search.Placeholder": "Order number",
    "Orders.Search.Button": "Search",
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.",
    "Orders.SyncButton.Button": "Synchronize Orders",
    "Orders.Table.Header.Images": "Images",
    "Orders.Table.Header.Title": "Title",
    "Orders.Table.Header.Quantity": "Quantity",
    "Orders.Table.Header.UnitCost": "Unit Cost",
    "Orders.Table.Header.CheckoutDate": "Checkout Date",
    "Orders.Table.Header.Supplier": "Supplier",
    "Orders.Table.Header.Status": "Status",
    "Orders.Table.Header.Action": "Action",
    "Orders.Table.Header.Total": "Total",
    "Orders.Table.OutOfStock": "Out Of Stock",
    "Orders.StatusColumn.Refunded.Tag": "Refunded",
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.",
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.",
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details",
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available",
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet",
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice",
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available",
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt",
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available",
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed",
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider",
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation",
    "Orders.CancelOrderModal.Title": "Are you sure?",
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.",
    "Orders.CancelOrderModal.Back.Title": "Go back",
    "Orders.CancelOrderModal.Back.Label": "Go Back",
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order",
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order",
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support",
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment",
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment",
    "Orders.AlibabaDetails.Button.Label": "Order Details",
    "Orders.AlibabaDetails.Button.Title": "See more information about this order",
    "Orders.OrderErrors.Warning": "Warning",
    "Orders.Bulk.Button.Label": "Bulk checkout orders",
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders",
    "Orders.Bulk.Error": "There was an error processing your order.",
    "Orders.Bulk.Text.NoOrders": "No orders available",
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders",
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected",

    "ChatButton.Tooltip": "Chat with order supplier",
    "ChatButton.Tooltip.Cancelled": "Chat not available",

    "ModalWithSteps.Cancel": "Cancel",
    "ModalWithSteps.GoBack": "Go Back",
    "ModalWithSteps.Continue": "Continue",
    "ModalWithSteps.Confirm": "Confirm",
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}",

    "Orders.OrderConfirmationSteps.Title": "Order Confirmation",
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal",
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost",
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee",
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total",
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier",
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...",
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders",
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress",
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order",

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed",
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending",
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed",
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped",
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment",

    "Orders.CustomerModal.Name": "Name",
    "Orders.CustomerModal.Email": "Email",
    "Orders.CustomerModal.Address": "Address",
    
    "Products.Title": "My Products - Spocket", // Page title
    "Products.PageTitle": "Products",
    "Products.Product.Button.ViewStore.Label": "View on Store",
    "Products.Product.Button.ViewStore.Title": "View product on store",
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List",
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List",
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored",
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product",
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.",
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store",
    "Products.Product.RemoveProduct.Success": "Product successfully removed",
    "Products.Product.RemoveProduct.Error": "There was an error removing the product",
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store",
    "Products.Product.Tags.Premium": "Premium",
    "Products.Product.Tags.Inactive": "Inactive",
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.",
    "Products.Product.Tags.Stock": "Out of Stock",
    "Products.Checkbox.CheckboxText": "Don't show me this message again",
    "Products.RestoreImportListModal.Title": "Restore to Import List?",
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.",
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore",
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel",
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List",
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List",
    "Products.RemoveItemModal.Title": "Remove From Store?",
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.",
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal",
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel",
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store",
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store",


    "Products.Filters.Supplier": "Supplier",
    "Products.Filters.Supplier.Placeholder": "Select supplier",
    "Products.Filters.ProductsFrom": "Products from",
    "Products.Filters.ProductsFrom.Spocket": "Spocket",
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress",
    "Products.Filters.ProductsFrom.Placeholder": "Select products from",
    "Products.Empty.Button.Search.Label": "Search Products",
    "Products.Empty.Button.Search.Title": "Search for products",
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products",
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products",
    "Products.Search.NoProductsFound.Title": "No results for your search",
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.",
    
    "ImageSearchSlider.Title": "Image Search",
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products",
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products",
    "ImageSearchSlider.Slides.Title": "Search products with an image!",
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.",
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.",
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!",
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`,
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}",

    "WinningProducts.Title.Head": "Winning Products - Spocket",
    "WinningProducts.Title": "Winning Products",

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!",
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!",
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket",
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.",
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress",
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account",
    "AliExpressConnectAlert.Orders.Connect.Title": "Your order has one or more AliExpress products. Connect an AliExpress account to auto-sync your orders from Spocket.",

    "ConnectAliexpressModal.Title": "Connect AliExpress Account",
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket",
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later",
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later",
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account",
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress",
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account",
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection",

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.",
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", 
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", 
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", 
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", 
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", 
    "WinningProducts.Modal.Button": "Unlock this page",
    "WinningProducts.SkipButton.Title": "Skip for now",
    "PaymentModal.Title": "Payment Method",
    
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",
    "PaymentModal.Card": "Credit Card",
    "PaymentModal.CardCTA": "Add Credit Card",
    "PaymentModal.Email": "PayPal Account Email",
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>",
    "UpgradeCouponModal.SkipButton.Title": "Skip for now",
    "UpgradeCouponModal.SkipButton.Label": "Skip for now",
    "UpgradeCouponModal.Description.Starter": "Boost sales",
    "UpgradeCouponModal.Description.Pro": "Maximize sales",
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale",
    "UpgradeCouponModal.Button.Label": "Try for FREE",
    "UpgradeCouponModal.Button.Title": "Try this plan for free",

    "SmartliModal.Title": "Want to Supercharge Your Sales?",
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!",
    "SmartliModal.ListTitle": "Auto-generate",
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions",
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms",
    "SmartliModal.ListItem.Three": "Blogs for improving SEO",
    "SmartliModal.Button.Text": "Try Smartli Free",
    "SmartliModal.CancelButton.Text": "Do it manually",
    "SmartliModal.HoverButton.Text": "Ask AI to write",

    "Academy.SmallTitle": "SPOCKET ACADEMY",
    "Academy.Title": "Start and Grow Your",
    "Academy.TitleHighlight": "Dropshipping Business.",
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.",
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and",
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry",
    "Academy.Description.LineThree": "experts to help you succeed.",
    "Academy.Button": "Start Learning",
    "Academy.CourseButton": "View courses",
    "Academy.Course.Title": "Courses",
    "Academy.Course.One.Title": "Email",
    "Academy.Course.One.Description": "02 Lessons",
    "Academy.Course.Two.Title": "Facebook",
    "Academy.Course.Two.Description": "07 Lessons",
    "Academy.Course.Three.Title": "Instagram",
    "Academy.Course.Three.Description": "03 Lessons",
    "Academy.Course.Four.Title": "Sales",
    "Academy.Course.Four.Description": "12 Lessons",
    "Academy.Course.Five.Title": "SEO",
    "Academy.Course.Five.Description": "02 Lessons",
    "Academy.Course.Six.Title": "Starting Fresh",
    "Academy.Course.Six.Description": "02 Lessons",
    "Academy.Course.Seven.Title": "Traffic",
    "Academy.Course.Seven.Description": "04 Lessons",
  }
};
