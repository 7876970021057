export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "내 주문 목록",
    "OrderList.Input.Search": "주문 번호...",
    "OrderList.Label.Status": "Spocket 상태별 정렬",

    "order.status.unpaid": "미결제",
    "order.status.paid": "결제 완료",
    "order.status.processing": "처리 중",
    "order.status.shipped": "배송 완료",
    "order.status.cancelled": "취소됨",
    "order.status.pending": "대기 중",
    "order.status.partially_paid": "일부 결제 완료",
    "order.status.partially_refunded": "일부 환불 완료",
    "order.status.refunded": "환불 완료",
    "order.status.voided": "무효 처리됨",
    "order.status.authorized": "승인 완료",
    "order.status.deleted": "삭제됨",
    "order.status.pending_payment_confirmation": "대기 중",

    "OrderList.Label.DownloadHistory": "모든 주문 내역 CSV로 다운로드",
    "OrderList.Document.Title": "주문 내역 - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "제품 검색",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "주문 처리 방법",
    "OrderList.EmptyOrders.Message.Loading.Title": "주문 내역을 불러오고 있습니다!",
    "OrderList.EmptyOrders.Message.Loading.Description": "주문 내역을 찾는 동안 잠시만 기다려주세요.",
    "OrderList.EmptyOrders.Message.Title": "주문 목록이 비어있습니다!",
    "OrderList.EmptyOrders.Message.Description":
      "이곳에서 주문 내역을 관리하고 처리할 수 있습니다. 가져오기 목록에 제품을 추가하려면 검색 페이지로 이동하세요.",
    "OrderList.CheckBox.SelectAllOrders": "모든 주문 선택",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} 주문 선택됨",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} 주문 선택됨",
    "OrderList.CheckBox.BulkCheckoutOrders": "대량 결제 주문",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "대량 결제 주문",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "주문 내역 동기화",
    "RefreshModal.Button.Cancel": "취소",
    "RefreshModal.Button.Synchronize": "동기화",
    "RefreshModal.Modal.Body":
      "내 스토어의 주문 내역이 자동으로 동기화되었습니다. 주문 정보가 누락되거나 업데이트되지 않은 경우에만 동기화합니다. 30초 후에 페이지를 새로고침해 주세요.",

    "Order.Label.OrderNumber": "주문 번호",
    "Order.Label.Date": "날짜",
    "Order.Label.OrderStatus": "{{integrationName}} 주문 상태",
    "Order.Table.Th.Product": "제품",
    "Order.Table.Th.Qty": "수량",
    "Order.Table.Th.UnitPrice": "단가",
    "Order.Table.Th.CheckoutDate": "결제일",
    "Order.Table.Th.Action": "조치",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "고객 정보 확인",
    "CustomerModal.Modal.Title.CustomerInfo": "고객 정보",
    "CustomerModal.Modal.Body.CustomerInfo": "고객 정보",
    "CustomerModal.Modal.Body.Address": "주소",

    "OrderLine.Button.Checkout": "결제",
    "OrderLine.Tooltip.PreviewInvoice": "송장 미리 보기",
    "OrderLine.Tooltip.PaymentReceipt": "결제 영수증",
    "OrderLine.Tooltip.TrackOrder": "주문 추적",
    "OrderLine.Tooltip.OrderProcessing": "주문 처리 중",
    "OrderLine.Tooltip.OrderCancelled": "주문 취소됨",
    "OrderLine.Tooltip.PleaseConfirm": "해당 주문 내역의 결제를 확인하려면 클릭하세요",
    "OrderLine.Label.PurchaseEtsy": "Etsy에서 구매",
    "OrderLine.Paying": "결제 중",
    "OrderLine.Failure": "실패",
    "OrderLine.Sample": "샘플",
    "OrderLine.ErrorOrderRow": "시스템에서 주문 목록을 찾을 수 없습니다! 고객 지원팀에 문의해 주세요!",

    "ConfirmationModal.Label.OrderDetails": "주문 세부 정보",
    "ConfirmationModal.Label.Total": "합계",
    "ConfirmationModal.Label.Shipping": "배송",
    "ConfirmationModal.Label.TransactionFees": "거래 수수료",
    "ConfirmationModal.Tooltip.TransactionFees":
      "거래 수수료에는 Spocket의 결제업체인 Stripe에서 부과하는 수수료 및 서비스 수수료가 포함됩니다.",
    "ConfirmationModal.Modal.OrderConfirmation": "주문 확인",
    "ConfirmationModal.Modal.Alert.Attention": "주의!",
    "ConfirmationModal.Modal.Alert.Description": "해당 주문 건에는 고객의 메모가 포함되어 있습니다.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "다음 사항을 주문하려 합니다.",
    "ConfirmationModal.Modal.Table.Th.Name": "이름",
    "ConfirmationModal.Modal.Table.Th.Price": "가격",
    "ConfirmationModal.Modal.Table.Th.Qty": "수량",
    "ConfirmationModal.Modal.Table.Th.Total": "합계",
    "ConfirmationModal.Modal.Label.Note": "공급업체 참고 사항",
    "ConfirmationModal.Modal.PlaceHolder.Note": "내 메시지",
    "ConfirmationModal.Button.PlaceOrder": "주문하기",

    "PaymentCompleteModal.PaymentCompleted": "결제 완료",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "결제해 주셔서 감사합니다. 공급업체에 주문 내역을 통보했으며 곧 처리될 예정입니다. 주문 상태를 업데이트하고 주문 페이지에서 송장 번호를 추가할 예정입니다.",
    "PaymentCompleteModal.PaymentIncomplete": "주문이 완료되지 않았습니다!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "계속 진행하려면 은행의 결제 확인이 필요합니다",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink": "결제를 확인하려면 이 링크를 사용해 주세요.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "결제가 확인되면 공급업체에 통보하여 주문이 정상적으로 처리됩니다. 주문 페이지에 상태 및 추적 업데이트가 추가됩니다. 확인 후 해당 페이지를 새로고침해 주세요.",

    "SetYourGoalsModal.Title": "스토어에 추가된 제품 개수",
    "SetYourGoalsModal.Subtitle": "일반적인 스토어는 판매 전에 제품 25개를 올립니다",
    "SetYourGoalsModal.DropdownItem.Product": "제품 {{count}}개",
    "SetYourGoalsModal.DropdownItem.Product_plural": "제품 {{count}}개",
    "SetYourGoalsModal.Button": "목표를 설정하고 드랍쉬핑을 시작하세요",

    "YourGoalsBanner.Container.Description": "내 목표",
    "YourGoalsBanner.Header.Title": "비즈니스 시작하기",
    "YourGoalsBanner.Header.Subtitle": "내 스토어를 준비하세요",
    "YourGoalsBanner.Progress.Completed": "완료됨",
    "YourGoalsBanner.ProductPushedCount": "<0>목표: {{goalsNumberOfProduct}}</0>제품 올리기",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Spocket 가입",
    "YourGoalsBanner.CheckPoints.FirstSearch": "첫 번째 검색",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "제품 {{goalsNumberOfProduct}}개 중 {{count}}개 올림",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "제품 {{goalsNumberOfProduct}}개 중 {{count}}개 올림",
    "YourGoalsBanner.CheckPoints.ProductsImported": "제품 {{goalsNumberOfProduct}}개 중 {{count}}개 가져옴",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "제품 {{goalsNumberOfProduct}}개 중 {{count}}개 가져옴",

    "Plan.Starter": "스타터",
    "Plan.Professional": "프로페셔널",
    "Plan.Empire": "엠파이어",
    "Plan.Unicorn": "유니콘",

    "Aliscraper.Title": "자동화 AliExpress 드랍쉬핑",
    "Aliscraper.Subtitle":
      "클릭 한 번으로 수천 개의 Ali Express 제품을 온라인 스토어로 가져올 수 있으며, 배송 업무도 자동화할 수 있습니다.",
    "Aliscraper.Features.Feature1": "클릭 한 번으로 빠르게 가져오기",
    "Aliscraper.Features.Feature2": "Spocket을 통해 수백 개의 주문 즉시 처리",
    "Aliscraper.Features.Feature3": "연중무휴 고객 지원",
    "Aliscraper.Button": "확장 프로그램 다운로드",
    "Aliscraper.Chrome": "Chrome 웹 스토어에서 이용 가능",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "내 쇼핑몰 연결",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "시작할 플랫폼 선택",
    "ConnectStoreModal.ShopifyForm.InvalidName": "유효하지 않은 스토어 이름",
    "ConnectStoreModal.ShopifyForm.Placeholder": "예: MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.ShopifyForm.Button": "스토어 연결하기",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "내 인증키:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "내 스토어를 Spocket에 연결하는 방법:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Spocket 플러그인을",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "여기에서 설치합니다",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "내 플러그인 페이지에서 해당 플러그인을 활성화합니다",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "WordPress 대시보드 > Spocket으로 이동합니다",
    "ConnectStoreModal.WoocommerceInstruction.Step4": '위에 언급된 인증키를 붙여넣고 "저장"을 클릭합니다',
    "ConnectStoreModal.WoocommerceInstruction.Button": "완료",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.WixForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.WixForm.Button": "스토어 연결하기",
    "ConnectStoreModal.FelexForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.FelexForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.FelexForm.Button": "스토어 연결하기",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce 스토어 URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "유효하지 않은 스토어 이름",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "예: MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.BigcommerceForm.Button": "스토어 연결하기",
    "ConnectStoreModal.EcwidForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.EcwidForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.EcwidForm.Button": "스토어 연결하기",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.WhiteLabelForm.Button": "스토어 연결하기",
    "ConnectStoreModal.SquarespaceForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.SquarespaceForm.Button": "스토어 연결하기",
    "ConnectStoreModal.SquareForm.FooterText": "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.SquareForm.FooterLink": "무료 체험하기",
    "ConnectStoreModal.SquareForm.Button": "스토어 연결하기",
    "ConnectStoreModal.FelexButton.Explanation":
      "기업가들이 10분 이내에 드랍쉬핑 쇼핑몰을 시작할 수 있는 온라인 쇼핑몰 제작 플랫폼입니다.",
    "ConnectStoreModal.DontHaveStore":  "아직 보유한 스토어가 없나요?",
    "ConnectStoreModal.TryForFree": "무료 체험하기",

    "PaymentTab.Document.Title": "설정 - 자동화 - Spocket",
    "PaymentTab.Title.Billing": "요금 청구",
    "PaymentTab.Tooltip.Billing": "주문 처리 및 구독 결제에 신용카드가 사용됩니다.",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "플랜 일시 중지 및 변경",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "플랜 변경",
    "PaymentTab.AccordionItem.Button.Downgrade": "다운그레이드",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "플랜 일시 중지",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "스토어가 이미 일시 중지 상태입니다",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "플랜 일시 중지",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "단기간 Spocket 계정을 일시 중지하고 데이터 손실 없이 언제든지 돌아올 수 있습니다.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "나중에 다시 알림",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "나중에 다시 알림",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3일 전",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "혜택을 유지하고 <1>{{daysBefore}}</1>일 전에 멤버십 갱신 알림을 받습니다",
    "RemindMeLaterModal.Header.ReminderSet": "알림 설정 완료",
    "RemindMeLaterModal.Body.DaysBefore": "3일 전에 알림 이메일을 발송합니다",
    "RemindMeLaterModal.Body.ReminderSet":
      "알림이 설정되었으며 혜택 갱신 <1>{{daysBefore}}</1>일 전에 알림이 발송됩니다. 그때까지 비즈니스 여정을 진행하세요.",
    "RemindMeLaterModal.Button.Continue": "계속",
    "PauseStoreModal.Title.PauseYourStore": "내 스토어 일시 중지",
    "PauseStoreModal.Description.PauseYourStore":
      "일회성 프로모션을 이용하여 향후 30일 동안 플랜 가격의 60% 할인 혜택을 받아보세요",
    "PauseStoreModal.Button.PauseStore": "스토어 일시 중지",
    "PauseStoreModal.Description.StorePaused":
      "스토어가 일시 중지되었으며 다음 달 구독 요금은 60% 할인됩니다. 이후에 정기 결제가 다시 진행됩니다.",
    "ConfirmPasswordModal.Title": "비밀번호를 확인해 주세요",
    "ConfirmPasswordModal.SubTitle": "보안상의 이유로 비밀번호 확인이 필요합니다",
    "ConfirmPasswordModal.Input.Placeholder": "내 비밀번호",
    "ConfirmPasswordModal.Button.Cancel": "취소",
    "ConfirmPasswordModal.Button.Confirm": "확인",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "ko",
    "Config.Plan.Starter": "스타터",
    "Config.Plan.Professional": "프로",
    "Config.Plan.Empire": "엠파이어",
    "Config.Plan.Unicorn": "유니콘",

    "UpgradeConfirmationModal.Header.Title": "Spocket과 함께 빠르게 성장하세요 ",
    "UpgradeConfirmationModal.Body.Title": "결제 주기 선택",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "첫",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "일 동안은 ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final": "무료이며, 언제든지 취소할 수 있습니다",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "첫 번째 결제일은 다음과 같습니다",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial": "첫 번째 결제는 확인 즉시 지불됩니다",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "결제는 매달 반복 청구됩니다",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "월간 ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/월",
    "UpgradeConfirmationModal.YearlyTab.RateType": "연간 ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/월",
    "UpgradeConfirmationModal.YearlyTab.Discount": "할인",
    "UpgradeConfirmationModal.TaxRates.Text": "판매세",
    "UpgradeConfirmationModal.TaxRates.Period": "월",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "지금 결제 시",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/월 X 12개월",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "할인 적용: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/년",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "다음에 상응",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% 할인",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "연간 플랜에는 무료 체험 기간이 없습니다",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "전액이 즉시 결제됩니다",
    "UpgradeConfirmationModal.CreditCardTab.Info": "신용/직불 카드 결제",
    "UpgradeConfirmationModal.PayPalTab.Info": "PayPal 결제",
    "UpgradeConfirmationModal.PaymentSection.Details": "결제 세부 정보 ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "무료 체험 혜택 받기",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "다음으로 업그레이드: ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "5만 명 이상의 사업가가 신뢰하는 브랜드",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom": "고객 평가 5/5점 만점으로 지속적인 고객 만족.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128비트 SSL 암호화",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "모든 결제 정보는 100% 안전합니다.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "100,000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "5만 명 이상의 사업가가 신뢰하는 브랜드",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom": "고객 평가 5/5점 만점으로 지속적인 고객 만족.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128비트 SSL 암호화",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "모든 결제 정보는 100% 안전합니다.",
    "UpgradeConfirmationModal.Agreement.Intro": "진행하시려면 다음에 대한 동의가 필요합니다 ",
    "UpgradeConfirmationModal.Agreement.Terms": "이용 약관",
    "UpgradeConfirmationModal.Agreement.Privacy": "개인정보 보호정책",
    "UpgradeConfirmationModal.Agreement.Refund": "환불 정책",
    "UpgradeConfirmationModal.Agreement.Middle": "및",
    "UpgradeConfirmationModal.Agreement.Cancelation": "취소 정책",

    "OrderDetails.Title": "주문 내역",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "신용카드 만료 기간",
    "CreditCard.Update.Button": "카드 업데이트",
    "CreditCard.AddCreditCard.Text":
      "아직 추가된 신용카드가 없습니다. 주문을 자동으로 처리할 수 있도록 아래 버튼을 눌러 신용카드를 추가해 주세요",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "신용카드 추가",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "우수한 제품",
    "Sidebar.WinningProducts.Variant": "신제품",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "앱",
    "Sidebar.HelpCenter": "도움말 센터",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "설정",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "언제든 도움을 드립니다!",
    "TutorialModal.WhatIsSpocket": "Spocket은 무엇인가요?",
    "TutorialModal.DiscoverProducts": "제품 검색",
    "TutorialModal.ProductCustomization": "제품 맞춤 설정",
    "TutorialModal.OrderProcessing": "주문 처리 중",
    "TutorialModal.Settings": "설정",
    "TutorialModal.GetHelp": "도움말 보기",
    "TutorialModal.JoinTheCommunity": "커뮤니티 가입",

    "NotificationCenter.Header": "알림 센터",
    "NotificationCenter.Footer": "모든 알림 보기",
    "NotificationCenter.NoNotification": "새로운 알림이 없습니다!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "내 제품 - Spocket",
    "Products.Title.Header": "내 제품",
    "Products.Loading.Top": "제품 목록을 불러오는 중입니다!",
    "Products.Loading.Bottom": "제품 목록을 가져올 때까지 잠시만 기다려주세요.",
    "Products.Empty.Search": "제품을 찾을 수 없습니다!",
    "Products.Empty.Top": "제품 목록이 비어있습니다!",
    "Products.Empty.Bottom": "가져오기 목록에 제품을 추가하려면 검색 페이지로 이동하세요.",
    "Products.Empty.Button.Search": "제품 검색",
    "Products.Empty.Button.How": "제품 검색 방법",
    "Products.Alert.Removed": "제품 제거 완료",

    "Products.Search.Label": "제품을 검색하세요",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "검색",

    "ProductListItem.Inactive.Tooltip": "해당 제품은 공급업체에서 제공하지 않습니다",
    "ProductListItem.Inactive.Title": "비활성",
    "ProductListItem.OutOfStock.Tooltip": "해당 제품의 모든 옵션이 품절되었습니다",
    "ProductListItem.OutOfStock.Title": "품절",
    "ProductListItem.InventoryCount.Singular": "개",
    "ProductListItem.InventoryCount.Plural": "개",
    "ProductListItem.InventoryCount.End.Singular": "남았음",
    "ProductListItem.InventoryCount.End.Plural": "남았음",
    "ProductListItem.Button.View": "스토어에 표시",
    "ProductListItem.Button.Remove": "스토어에서 제거",

    "ImportList.Title.Head": "목록 가져오기 - Spocket",
    "ImportList.Title.Header": "내 가져오기 목록",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "가져오기 목록을 불러오고 있습니다!",
    "ImportList.Loading.Bottom": "제품 목록을 가져올 때까지 잠시만 기다려주세요.",
    "ImportList.Empty.Button.Search": "제품 검색",
    "ImportList.Empty.Top": "가져오기 목록이 비어있습니다!",
    "ImportList.Empty.Bottom": "가져오기 목록에 제품을 추가하려면 검색 페이지로 이동하세요.",
    "ImportList.Empty.Button.How": "제품 맞춤 설정 방법",
    "ImportList.Alert.Pushed": "다음 제품을 내 스토어에 올렸습니다.",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Search.Label": "가져온 제품에서 검색하기",
    "ImportList.Search.Placeholder": "제품을 검색하세요",
    "ImportList.Search.Button": "검색",
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "현재 페이지의 제품 올리기",
    "PushAllModal.Button.Inactive": "현재 페이지 올리기",
    "PushAllModal.Modal.Text":
      "현재 페이지의 모든 제품을 올리시겠습니까? 현재 페이지의 모든 유효한 제품이 스토어에 올라갑니다.",
    "PushAllModal.Modal.Push": "모두 올리기",
    "PushAllModal.Modal.Cancel": "취소",

    "RemoveAllModal.Button.Active": "현재 페이지 제품 제거",
    "RemoveAllModal.Button.Inactive": "현재 페이지 제거",
    "RemoveAllModal.Modal.Text":
      "현재 페이지의 모든 제품을 제거하시겠습니까? 현재 페이지의 모든 제품이 가져오기 목록에서 제거됩니다.",
    "RemoveAllModal.Modal.Remove": "모두 제거",
    "RemoveAllModal.Modal.Cancel": "취소",

    "ImportListItem.Tab.Product": "제품",
    "ImportListItem.Tab.Description": "설명",
    "ImportListItem.Tab.Variants": "옵션",
    "ImportListItem.Tab.Images": "이미지",
    "ImportListItem.Remove.Active": "제품 제거하기",
    "ImportListItem.Remove.Inactive": "제품 제거",
    "ImportListItem.Push.Active": "스토어에 올리기",
    "ImportListItem.Push.Inactive": "스토어에 올리기",
    "ImportListItem.Save.Button": "저장",
    "ImportListItem.Alert.Removed": "다음 제품이 내 가져오기 목록에서 제거됩니다. ",
    "ImportListItem.Alert.RemoveFailed": "제거하는 중 다음과 같은 문제가 발생했습니다. ",
    "ImportListItem.Alert.CompareAtPrice": "비교 가격은 다음 판매 가격보다 커야 합니다. ",
    "ImportListItem.Alert.OneActiveVariation": "해당 제품에는 하나 이상의 활성 옵션이 필요합니다. ",
    "ImportListItem.Alert.Saved": "제품이 저장되었습니다. ",
    "ImportListItem.Alert.ErrorPushing": "스토어에 제품을 올리는 중 문제가 발생했습니다",
    "ImportListItem.Alert.ErrorSaving": "스토어를 저장하는 중 문제가 발생했습니다",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "스토어에 연결하여 제품 올리기: ",
    "ImportListItem.Tooltip.OneActiveVariation": "하나 이상의 활성 옵션이 필요합니다",
    "ImportListItem.Tooltip.Unavailable": "해당 제품은 현재 구매할 수 없습니다",
    "ImportListItem.Tooltip.Unsaved": "저장하지 않은 변경 사항이 있습니다",
    "ImportListItem.Tooltip.Profit": "경고: 해당 제품의 총 수익은 마이너스입니다",
    "ImportListItem.Tooltip.Unpurchasable": "제품을 저장할 수 있지만 현재 구매할 수 없습니다",
    "ImportListItem.Variants.ShowShippingPrices": "배송비 보기",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "제품명",
    "ItemInfo.ProductTags.Label": "제품 태그",
    "ItemInfo.ProductTags.Remove": "모든 태그 제거",
    "ItemInfo.ProductTags.Placeholder": "새 태그 추가",
    "ItemInfo.ProductType.Label": "제품 유형",
    "ItemInfo.Collection.Label": "컬렉션",
    "ItemInfo.Collection.Placeholder": "선택",
    "ItemInfo.Collection.Option": "선택",
    "ItemInfo.SquareLocation.Option": "선택",

    "ItemVariantList.Modal.Title": "배송 정보",
    "ItemVariantList.Modal.Intro": "발송인: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "해당 제품은 국제 배송이 불가합니다.",
    "ItemVariantList.Modal.DoesNotShipTo": "배송 불가 지역: ",
    "ItemVariantList.Table.Head.Destination": "배송지",
    "ItemVariantList.Table.Head.Cost": "비용",
    "ItemVariantList.Table.Head.Time": "배송 기간(일)",
    "ItemVariantList.Table.Body.Domestic": "국내",
    "ItemVariantList.Table.Body.International": "국제",
    "ItemVariantList.VariantsTable.ShippingPrice": "배송비 ",
    "ItemVariantList.VariantsTable.Image": "이미지",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "재고",
    "ItemVariantList.VariantsTable.Price": "가격",
    "ItemVariantList.VariantsTable.SalesPrice": "판매 가격 ",
    "ItemVariantList.VariantsTable.Profit": "수익",
    "ItemVariantList.VariantsTable.CompareAtPrice": "비교 가격",
    "ItemVariantList.VariantsTable.Unavailable": "해당 제품은 현재 구매할 수 없습니다",
    "ItemVariantList.VariantsTable.Head": "해당 제품의 모든 옵션이 이곳에 표시됩니다.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "예: 40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify는 옵션이 100개 이상인 모델을 지원하지 않습니다.",

    "ItemVariantList.ContextPopover.Title": "수익 설정",
    "ItemVariantList.ContextPopover.Apply": "적용",

    "ProductVariation.Description":
      "Spocket에서 샘플을 직접 주문할 수 있습니다. 간편 결제를 진행하려면 아래의 단계를 따르세요.",
    "ProductVariation.Label.SelectVariant": "옵션 선택",
    "ProductVariation.Label.SelectTheProductVariation": "제품 옵션 선택",
    "ProductVariation.Qty": "수량",
    "ProductVariation.Label.NotesForTheSupplier": "공급업체 참고 사항",

    "InputAddress.Label.FirstName": "이름",
    "InputAddress.PlaceHolder.FirstName": "이름 입력",
    "InputAddress.Label.LastName": "성",
    "InputAddress.PlaceHolder.LastName": "성 입력",
    "InputAddress.Label.StreetAddress": "세부 주소",
    "InputAddress.Label.Country": "국가",
    "InputAddress.PlaceHolder.Country": "국가 검색",
    "InputAddress.Label.State": "주/도",
    "InputAddress.Placeholder.State": "주/도 검색",
    "InputAddress.Label.City": "시",
    "InputAddress.PlaceHolder.City": "시 입력",
    "InputAddress.Label.Zip": "우편번호",
    "InputAddress.PlaceHolder.Zip": "우편번호 입력",
    "InputAddress.Label.Phone": "전화번호",
    "InputAddress.Placeholder.Phone": "전화번호 입력",

    "OrderReview.OrderDetails": "주문 정보",
    "OrderReview.Unit": "{{count}}개",
    "OrderReview.Unit_plural": "{{count}}개",
    "OrderReview.ShippingAddress": "배송지 주소",
    "OrderReview.PaymentMethod": "결제 방식",
    "OrderReview.Amounts": "수량",
    "OrderReview.Subtotal": "소계",
    "OrderReview.ShippingCost": "배송비",
    "OrderReview.TransactionFees": "거래 수수료",
    "OrderReview.TransactionFees.ToolTips":
      "거래 수수료에는 Spocket의 결제업체인 Stripe에서 부과하는 수수료 및 서비스 수수료가 포함됩니다.",
    "OrderReview.OrderTotal": "총 주문 내역",
    "OrderReview.ItemWithCount": "{{count}}개",
    "OrderReview.ItemWithCount_plural": "{{count}}개",
    "OrderReview.Placeholder.CreditCard": "신용카드 만료 기간 {{last4}}",

    "PaymentComplete.PaymentCompleted": "결제 완료",
    "PaymentComplete.PaymentCompleted.Note":
      "결제해 주셔서 감사합니다. 공급업체에 주문 내역을 통보했으며 곧 처리될 예정입니다. 주문 상태를 업데이트하고 주문 페이지에서 송장 번호를 추가할 예정입니다.",
    "PaymentComplete.PaymentIncomplete": "주문이 완료되지 않았습니다!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "계속 진행하려면 은행의 결제 확인이 필요합니다",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "결제를 확인하려면 이 링크를 사용해 주세요.",
    "PaymentComplete.PaymentIncomplete.Note":
      "결제가 확인되면 공급업체에 통보하여 주문이 정상적으로 처리됩니다. 주문 페이지에 상태 및 추적 업데이트가 추가됩니다. 확인 후 해당 페이지를 새로고침해 주세요.",

    "CreditCard.AddCreditCard.Description":
      "아직 추가된 신용카드가 없습니다. 주문을 자동으로 처리할 수 있도록 아래 버튼을 눌러 신용카드를 추가해 주세요",
    "CreditCard.Button.AddCreditCard": "신용카드 추가",
    "CreditCard.Button.UpdateCard": "카드 업데이트",
    "CreditCard.UpdateCard.Label.CreditCard": "신용카드",
    "CreditCard.PlaceHolder.CreditCard": "신용카드 만료 기간 {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "신용카드",
    "UpdateCreditCardModal.Button.UpdateCard": "카드 업데이트",
    "UpdateCreditCardModal.Button.AddCreditCard": "신용카드 추가",
    "UpdateCreditCardModal.Button.Cancel": "취소",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "카드 정보 업데이트",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Spockete 제품 주문 처리 중 귀하의 계정이 사용됩니다.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "결제 처리",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "이용 약관.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "옵션 선택",
    "SampleOrderModal.Title.ShippingAddress": "배송지 주소",
    "SampleOrderModal.Title.PaymentMethod": "결제 수단",
    "SampleOrderModal.Title.Review": "리뷰",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "결제 확인이 필요합니다!",
    "SampleOrderModal.Title.OrderPlaced": "주문이 완료되었습니다!",
    "SampleOrderModal.Steps": "{{total}}단계 중 {{current}}단계",
    "SampleOrderModal.FinalStep": "마지막 단계",
    "SampleOrderModal.ConfirmPayment": "결제 확인!",
    "SampleOrderModal.Finished": "완료",
    "SampleOrderModal.Footer.Continue": "계속",
    "SampleOrderModal.Footer.PlaceOrder": "주문하기",
    "SampleOrderModal.Footer.Close": "닫기",
    "SampleOrderModal.Footer.Cancel": "취소",
    "SampleOrderModal.Footer.GoBack": "뒤로",

    "AdvancedFiltersModal.Title.Filters": "필터",
    "AdvancedFiltersModal.Title.Shipping": "배송",
    "AdvancedFiltersModal.Label.ShipsFrom": "발송지",
    "AdvancedFiltersModal.Label.ShipsTo": "배송지",
    "AdvancedFiltersModal.Label.ShippingTime": "배송 기간",
    "AdvancedFiltersModal.Label.Popular": "인기",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "미국",
    "AdvancedFiltersModal.Label.Country.Europe": "유럽",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "미국으로만 배송 가능합니다",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "무관",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3일",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7일",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14일",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15일 이상",
    "AdvancedFiltersModal.Title.ItemsCost": "제품 가격",
    "AdvancedFiltersModal.Label.ItemCost": "제품 가격",
    "AdvancedFiltersModal.Label.ShippingCost": "배송비",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "모든 비용",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "무료",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5$ 이하",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15$ 이하",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25$ 이하",
    "AdvancedFiltersModal.Title.Supplier": "공급업체",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "공급업체",
    "AdvancedFiltersModal.Label.TopSupplier": "인기 공급업체",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "고품질 제품과 최고의 서비스, 높은 평점 리뷰로 유명한 공급업체",
    "AdvancedFiltersModal.Title.Advanced": "고급 설정",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "프리미엄 제품",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription": "높은 할인율 및 빠른 배송 제품",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "베스트셀러",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Spocket의 최다 판매 제품",
    "AdvancedFiltersModal.Button.Cancel": "취소",
    "AdvancedFiltersModal.Button.ViewResults": "결과 보기",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "국가 검색...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "국가",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "공급업체 검색...",
    "AdvancedFiltersModal.Label.All Suppliers": "모든 공급업체",
    "AdvancedFiltersModal.Label.New Suppliers": "신규 공급업체",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "무관",
    "AdvancedFilters.ShippingTime.One": "1-3일",
    "AdvancedFilters.ShippingTime.Four": "4-7일",
    "AdvancedFilters.ShippingTime.Eight": "8-14일",
    "AdvancedFilters.ShippingTime.Fifteen": "15일 이상",

    "AdvancedFilters.ShippingCost.Free": "무료 배송",
    "AdvancedFilters.ShippingCost.Five": "배송비 5$ 이하",
    "AdvancedFilters.ShippingCost.Fifteen": "배송비 15$ 이하",
    "AdvancedFilters.ShippingCost.TwentyFive": "배송비 25$ 이하",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "모두 삭제",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "배송",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "검색",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "발송지",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "배송지",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "공급업체",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "정렬 기준",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "제품 가격",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "인기 공급업체",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "맞춤 송장",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "베스트셀러",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "프리미엄",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "{{from}}~{{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "키워드 검색...",
    "Search.Header.Button.Search": "검색",
    "Search.Header.Button.Filters": "필터",

    "Navigation.UpgradeButton.TryPro": "Spocket 프로 무료 체험하기",
    "Navigation.UpgradeButton.TryEmpire": "Spocket 엠파이어 무료 체험하기",
    "Navigation.UpgradeButton.TryUnicorn": "Spocket 유니콘 무료 체험하기",

    "CategoriesCards.Label.MoreCategories": "더 많은 카테고리",

    "StaticCategories.WomensClothing": "여성 의류",
    "StaticCategories.WomensClothing.Activewear": "액티브웨어",
    "StaticCategories.WomensClothing.Bodysuits": "바디수트",
    "StaticCategories.WomensClothing.Dresses": "드레스",
    "StaticCategories.WomensClothing.JacketsCoats": "재킷 & 코트",
    "StaticCategories.WomensClothing.Jeans": "청바지",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "점프수트 & 롬퍼",
    "StaticCategories.WomensClothing.Leggings": "레깅스",
    "StaticCategories.WomensClothing.LingerieUnderwear": "란제리 & 속옷",
    "StaticCategories.WomensClothing.MatchingSets": "상하의 세트",
    "StaticCategories.WomensClothing.Pants": "바지",
    "StaticCategories.WomensClothing.Shorts": "쇼츠",
    "StaticCategories.WomensClothing.Skirts": "스커트",
    "StaticCategories.WomensClothing.Sleepwear": "슬립웨어",
    "StaticCategories.WomensClothing.Socks": "양말",
    "StaticCategories.WomensClothing.SweatersHoodies": "스웨터 & 후드",
    "StaticCategories.WomensClothing.Swimwear": "수영복",
    "StaticCategories.WomensClothing.TankTops": "탱크탑",
    "StaticCategories.WomensClothing.TopsBlouses": "탑 & 블라우스",
    "StaticCategories.WomensClothing.Tshirts": "티셔츠",

    "StaticCategories.JewelryWatches": "주얼리 & 시계",
    "StaticCategories.JewelryWatches.Bracelets": "팔찌",
    "StaticCategories.JewelryWatches.Earrings": "귀걸이",
    "StaticCategories.JewelryWatches.Necklaces": "목걸이",
    "StaticCategories.JewelryWatches.OtherAccessories": "기타 액세서리",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "펜던트 & 원석 & 참",
    "StaticCategories.JewelryWatches.Rings": "반지",
    "StaticCategories.JewelryWatches.Sets": "세트",
    "StaticCategories.JewelryWatches.Watches": "시계",

    "StaticCategories.TechAccessories": "전자 기기 액세서리",
    "StaticCategories.TechAccessories.AudioVideo": "오디오 & 비디오",
    "StaticCategories.TechAccessories.CasesCovers": "케이스 & 커버",
    "StaticCategories.TechAccessories.Lighting": "조명",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "모바일 기기 & 노트북 액세서리",
    "StaticCategories.TechAccessories.Mousepads": "마우스패드",
    "StaticCategories.TechAccessories.Novelty": "특이한 제품",

    "StaticCategories.KidsBabies": "어린이 & 유아",
    "StaticCategories.KidsBabies.BabyClothing": "유아복",
    "StaticCategories.KidsBabies.Bathing": "침구류",
    "StaticCategories.KidsBabies.BlanketsPillows": "이불 & 베개",
    "StaticCategories.KidsBabies.CapsHeadbands": "모자 & 헤어밴드",
    "StaticCategories.KidsBabies.Footwear": "신발",
    "StaticCategories.KidsBabies.Furniture": "가구",
    "StaticCategories.KidsBabies.KidsClothing": "어린이 의류",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "육아 제품 & 액세서리",

    "StaticCategories.Toys": "장난감",
    "StaticCategories.Footwear": "신발",
    "StaticCategories.Footwear.Boots": "부츠",
    "StaticCategories.Footwear.Flats": "플랫 슈즈",
    "StaticCategories.Footwear.Heels": "하이힐",
    "StaticCategories.Footwear.Mens": "남성용",
    "StaticCategories.Footwear.Sandals": "샌들",
    "StaticCategories.Footwear.Slippers": "슬리퍼",
    "StaticCategories.Footwear.SneakersRunners": "스니커즈 & 러닝화",

    "StaticCategories.BathBeauty": "목욕 & 뷰티",
    "StaticCategories.BathBeauty.Bodycare": "바디케어",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "디퓨저 & 오일 & 캔들",
    "StaticCategories.BathBeauty.Haircare": "헤어케어",
    "StaticCategories.BathBeauty.Healthcare": "헬스케어",
    "StaticCategories.BathBeauty.Makeup": "메이크업",
    "StaticCategories.BathBeauty.Nails": "네일",
    "StaticCategories.BathBeauty.Skincare": "스킨케어",
    "StaticCategories.BathBeauty.TowelsRobes": "타월 & 가운",

    "StaticCategories.Pets": "반려동물",
    "StaticCategories.Pets.BedsBlankets": "침구 & 이불",
    "StaticCategories.Pets.LeashesCollarsPetwear": "목줄 & 반려동물 의류",
    "StaticCategories.Pets.Petcare": "반려동물 용품",
    "StaticCategories.Pets.Toys": "장난감",

    "StaticCategories.HomeGarden": "실내 & 정원",
    "StaticCategories.HomeGarden.Bathroom": "욕실",
    "StaticCategories.HomeGarden.Clocks": "시계",
    "StaticCategories.HomeGarden.Drinkware": "컵",
    "StaticCategories.HomeGarden.FoodBeverage": "음식 & 음료",
    "StaticCategories.HomeGarden.Furniture": "가구",
    "StaticCategories.HomeGarden.Garden": "정원",
    "StaticCategories.HomeGarden.HomeDecor": "홈 데코",
    "StaticCategories.HomeGarden.HomeImprovement": "홈 레노베이션",
    "StaticCategories.HomeGarden.Kitchen": "부엌",
    "StaticCategories.HomeGarden.Lighting": "조명",
    "StaticCategories.HomeGarden.Organization": "정리",
    "StaticCategories.HomeGarden.StationeryCrafts": "문구류 & 공예",
    "StaticCategories.HomeGarden.TextilesPillows": "텍스타일 & 쿠션",
    "StaticCategories.HomeGarden.Tools": "도구",

    "StaticCategories.Accessories": "액세서리",
    "StaticCategories.Accessories.Belts": "벨트",
    "StaticCategories.Accessories.Cufflinks": "커프스",
    "StaticCategories.Accessories.Hats": "모자",
    "StaticCategories.Accessories.Keychains": "키체인",
    "StaticCategories.Accessories.ScarvesBandanas": "스카프 & 반다나",
    "StaticCategories.Accessories.Sunglasses": "선글라스",

    "StaticCategories.MensClothing": "남성복",
    "StaticCategories.MensClothing.Activewear": "액티브웨어",
    "StaticCategories.MensClothing.DressShirts": "드레스 셔츠",
    "StaticCategories.MensClothing.JacketsCoats": "재킷 & 코트",
    "StaticCategories.MensClothing.Pants": "바지",
    "StaticCategories.MensClothing.Shorts": "쇼츠",
    "StaticCategories.MensClothing.Socks": "양말",
    "StaticCategories.MensClothing.SweatersHoodies": "스웨터 & 후드",
    "StaticCategories.MensClothing.Swimwear": "수영복",
    "StaticCategories.MensClothing.TankTops": "탱크탑",
    "StaticCategories.MensClothing.Tshirts": "티셔츠",
    "StaticCategories.MensClothing.Underwear": "속옷",

    "StaticCategories.SportsOutdoors": "스포츠 & 아웃도어",
    "StaticCategories.SportsOutdoors.Activewear": "액티브웨어",
    "StaticCategories.SportsOutdoors.Biking": "자전거용품",
    "StaticCategories.SportsOutdoors.Camping": "캠핑용품",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "장비 & 액세서리",
    "StaticCategories.SportsOutdoors.Fishing": "낚시용품",

    "StaticCategories.Gifts": "선물",
    "StaticCategories.Gifts.Adults": "성인용품",
    "StaticCategories.Gifts.Funny": "재밌는 제품",
    "StaticCategories.Gifts.Mugs": "머그컵",
    "StaticCategories.Gifts.Novelty": "특이한 제품",
    "StaticCategories.Gifts.Personalized": "개인",
    "StaticCategories.Gifts.Religious": "종교",

    "StaticCategories.Automotive": "자동차용품",
    "StaticCategories.BagsWallets": "가방 & 지갑",
    "StaticCategories.Seasonal": "시즌 제품",
    "StaticCategories.Seasonal.Christmas": "크리스마스",
    "StaticCategories.Seasonal.FathersDay": "아버지의 날",
    "StaticCategories.Seasonal.Halloween": "핼러윈",
    "StaticCategories.Seasonal.MothersDay": "어머니의 날",

    "StaticCategories.FestivalsParties": "축제 & 파티",
    "StaticCategories.FestivalsParties.PartySupplies": "파티 제품",
    "StaticCategories.FestivalsParties.Wedding": "웨딩",

    "QuickFilters.Label.Premium": "프리미엄",
    "QuickFilters.Label.FastUSAShipping": "빠른 미국 배송",
    "QuickFilters.Label.Under5": "5$ 이하의 미국 배송",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "정렬 기준",
    "SortBy.DropDown.Item.Relevance": "관련성",
    "SortBy.DropDown.Item.PriceLowToHigh": "가격: 낮은 가격부터",
    "SortBy.DropDown.Item.PriceHighToLow": "가격: 높은 가격부터",
    "SortBy.DropDown.Item.Newest": "최신",
    "SortBy.DropDown.Item.ShippingTime": "배송 기간",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "홈",
    "Breadcrumb.Item.Search": "검색",

    "UpgradeModal.Modal.Title": "요금 플랜 선택",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "월간",
    "Plans.ToggleSection.Yearly": "연간",
    "Plans.ToggleSection.DiscountInitial": "최대",
    "Plans.ToggleSection.DiscountSingular": "개월",
    "Plans.ToggleSection.DiscountPlural": "개월",
    "Plans.ToggleSection.DiscountFinal": "할인",
    "Plans.ProrationCost.TextInitial": "현재 결제 금액은",
    "Plans.ProrationCost.TextFinal": "/년입니다. 다음 추가 기간에 한해 엠파이어로 업그레이드",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "판매 시작",
    "Card.DescriptionVariantType.Professional": "판매 촉진",
    "Card.DescriptionVariantType.Empire": "판매 극대화",
    "Card.DescriptionVariantType.Unicorn": "규모별 판매 관리",
    "Card.DescriptionVariantType.Period": "월",
    "Card.DescriptionVariantType.PricePeriod": "/월",

    "Card.DescriptionVariantType.AnnualDescription": "연간 결제 금액 {{value}}$가",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "현재 플랜",
    "Card.DescriptionVariantType.Button.Start": "지금 시작하기",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "브랜드 송장",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "우수한 제품",
    "Card.DescriptionVariantType.SupplierSourcing": "공급업체 소싱",
    "Card.DescriptionVariantType.ProductRequests": "제품 요청",
    "Card.DescriptionVariantType.BulkCheckout": "대량 결제",
    "Card.DescriptionVariantType.Special": "스페셜",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Spocket 구독이 만료되었습니다.",
    "ReactivationBanner.InfoSubtitle": "다시 활성화하기",
    "ReactivationBanner.CheckList.HighQuality": "고품질",
    "ReactivationBanner.CheckList.Curated": "엄선된",
    "ReactivationBanner.CheckList.FastShipping": "빠른 배송",
    "ReactivationBanner.CheckList.FromUSAndEU": "미국 및 유럽에서 발송",
    "ReactivationBanner.TextByPlan.Starter.Description": "더 이상 <1>제품을 올릴 수</1> 없습니다",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket 계정",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "지금 내 스토어에 제품 올리기",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "더 이상 실시간으로 <1>제품을 올릴 수</1> 없거나  ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "프리미엄 제품을 사용할 수 없습니다",
    "ReactivationBanner.TextByPlan.Others.Title": "프리미엄 계정",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "다음과 같은 프리미엄 제품 이용 가능",
    "ReactivationBanner.Button": "지금 내 계정 다시 활성화하기",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "더 많은 제품 올리기",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "무제한 주문",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "브랜드 송장",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "프리미엄 제품을 사용할 수 없습니다",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "특별 할인가",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "채팅 & 전화 지원",
    "UpgradeReasonModal.Modal.Title": "Spocket에서 달성하려는 목표는 무엇인가요?",
    "UpgradeReasonModal.Modal.Question": "업그레이드 계기는 무엇인가요?",
    "UpgradeReasonModal.Modal.Button": "Spocket {{plan}} 이용 시작하기",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "여러분의 이용 경험을 개선하는 데 도움이 됩니다",

    "UpgradeSuccessModal.Col.One.A": "축하합니다",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "성공적인 드랍쉬핑 비즈니스를 구축하기 위해 필요한 모든 도구를 이용하실 수 있습니다.",
    "UpgradeSuccessModal.Col.Two.B": "이제 다음이 지원됩니다 ",
    "UpgradeSuccessModal.Col.Two.C": " Spocket을 즐겨보세요!",
    "UpgradeSuccessModal.Button": "계속",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1개월",
    "Promotion.TrialDaysNormalizer.28days": "4주",
    "Promotion.TrialDaysNormalizer.21days": "3주",
    "Promotion.TrialDaysNormalizer.14days": "2주",
    "Promotion.TrialDaysNormalizer.7days": "1주",
    "Promotion.TrialDaysNormalizer.1day": "1일",
    "Promotion.TrialDaysNormalizer.Days": "{{value}}일",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "무료 체험하기",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "지금 무료 체험하기 →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English
    

    "Language.Title": "언어",

    "WhatYouWillLoseModal.Button.Offer": "계속 유지하기",
    "WhatYouWillLoseModal.Button.Downgrade": "포기하고 계정 다운그레이드하기",
    "WhatYouWillLoseModal.ProductSection.Title": "<1>{{product_count}}가져온 제품</1>이 모두 사라집니다",
    "WhatYouWillLoseModal.Product.PremiumTag": "프리미엄",
    "WhatYouWillLoseModal.Product.SkeletonImage": "{{product_count}}개 이상",
    "WhatYouWillLoseModal.ModalWrapper.Header": "벌써 포기하시나요?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "50% 할인 혜택을 받으세요",
    "WhatYouWillLoseModal.ModalWrapper.Title": "다음 3개월 동안 비즈니스 여정을 이어가세요.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "여러분만의 성공 스토리를 만드시길 바랍니다. 이미 많은 시간을 투자하신 것을 알고 있습니다. 조금만 더 노력해 보세요. 저희가 응원하겠습니다. Spocket 팀",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "축하합니다. 다음 3개월 동안 구매하신 플랜에 50% 할인 혜택이 추가되었습니다!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "계속",

    "DowngradeReasonModal.DowngradeSection": "어떻게 하면 Spocket의 사용 경험을 더욱 개선할 수 있을까요?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "모든 것에 대한 피드백을 받고 있습니다(최소 100자)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "피드백을 입력해 주세요(최소 100자)",
    "DowngradeReasonModal.Button.Downgrade": "다운그레이드",
    "DowngradeReasonModal.ModalWrapper.Header": "{{storeName}} 다운그레이드를 진행할까요?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "문의하기",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "취소",

    "DowngradeSuccessModal.Header": "다운그레이드 완료",
    "DowngradeSuccessModal.Body":
      "피드백을 보내주셔서 감사합니다. Spocket을 개선하기 위해 매일 노력하고 있습니다. 조만간 다시 기회를 주시면 감사하겠습니다!",

    "Settings.title": "설정",
    "Settings.buttonSaveChanges": "변경 사항 저장",
    "Settings.buttonSaveAndPreview": "저장 및 미리보기",
    "Settings.PricingPlans.Title": "유료 플랜",

    "Settings.MenuTab.Plans": "플랜",
    "Settings.MenuTab.Account": "계정",
    "Settings.MenuTab.BrandedInvoicing": "브랜드 송장",
    "Settings.MenuTab.Billing": "요금 청구",
    "Settings.MenuTab.GlobalPricingRules": "글로벌 가격 설정 규칙",
    "Settings.MenuTab.Security": "보안",

    "AccountTab.documentTitle": "설정 - 계정 - Spocket",
    "AccountTab.title": "계정 설정",
    "AccountTab.shopName": "쇼핑몰 이름",
    "AccountTab.email": "이메일",
    "AccountTab.shopUrl": "쇼핑몰 URL",
    "AccountTab.defaultCurrency": "기본 통화",
    "AccountTab.yourPlan": "내 플랜",
    "AccountTab.accountNotActive": "내 계정은 현재 활성 상태가 아닙니다",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "해당 기능은 곧 추가될 예정입니다! 지금은 Spocket Shopify 앱을 사용하여 연결해 주세요!",
    "AccountTab.alertAccountSettingsUpdated": "계정 설정 업데이트 완료",
    "AccountTab.alertInvalidShopUrl": "유효하지 않은 쇼핑몰 URL",
    "AccountTab.productsImported": "가져온 제품",
    "AccountTab.products": "제품",
    "AccountTab.premiumProducts": "프리미엄 제품을 사용할 수 없습니다",
    "AccountTab.total": "합계",
    "AccountTab.signOut": "로그아웃",
    "AccountTab.limitReachedProductsAndPremium": "제품 및 프리미엄 제품 한도에 도달했습니다.",
    "AccountTab.limitReachedProducts": "제품 한도에 도달했습니다.",
    "AccountTab.limitReachedPremium": "프라미엄 제품 한도에 도달했습니다.",
    "AccountTab.buttonSwitchToAnnualPlan": "연간 플랜으로 변경하기",
    "AccountTab.off30Percent": "30% 할인",
    "AccountTab.off40Percent": "40% 할인",
    "AccountTab.off45Percent": "45% 할인",
    "AccountTab.sellMoreWithEmpirePlan": "엠파이어 플랜으로 판매 실적을 높여보세요",
    "AccountTab.tooltipCurrency":
      "통화는 스토어 계정에서 관리할 수 있습니다. 가져온 제품의 모든 가격이 해당 통화로 변경됩니다.",
    "AccountTab.shopNotConnectedYet": "쇼핑몰을 아직 해당 계정에 연결하지 않았습니다.",
    "AccountTab.connectYourShop": "쇼핑몰 연결",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "설정 - 송장 - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "송장 설정이 저장되었습니다!",
    "InvoiceTab.shopLogo": "쇼핑몰 로고",
    "InvoiceTab.contactEmail": "이메일 문의",
    "InvoiceTab.phoneNumber": "전화번호",
    "InvoiceTab.personalNote": "개인 메모",
    "InvoiceTab.tooltipPersonalNote": "각 브랜드 송장에 삽입할 개인 메모를 설정합니다",
    "InvoiceTab.brandedInvoicing": "브랜드 송장",
    "InvoiceTab.tooltipBrandedInvoicing":
      "유료 플랜을 이용하면 브랜드 송장 제공 공급업체에서 주문한 모든 내역에 브랜드 로고 및 개인 메모를 추가할 수 있습니다",
    "InvoiceTab.disabledInvoicing": "모든 주문 내역에 브랜드 송장을 추가합니다",
    "InvoiceTab.tooltipDisabledInvoicing": "해당 옵션을 해제하면 주문 시 브랜드 송장이 제외됩니다",

    "PricingTab.titleDefaultPricingRules": "가격 설정 기본 규칙",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "가격 설정 고급 규칙",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "센트 지정",
    "PricingTab.tooltipAssignCents":
      "해당 값은 제품의 최종 가격을 표시할 때 사용됩니다(예: 제품 가격을 XX.99로 설정하려면 아래 필드에 99를 추가).",
    "PricingTab.Markup": "마크업",
    "PricingTab.MarkupType": "마크업 유형",
    "PricingTab.SelectDefault": "-- 1개 선택 --",
    "PricingTab.SelectPercent": "%",
    "PricingTab.SelectMultiplier": "배율",
    "PricingTab.SelectFixed": "고정",
    "PricingTab.titlePricingRules": "설정 - 가격 설정 규칙 - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "가격 설정 고급 규칙 선택",
    "PricingTab.from": "최소",
    "PricingTab.to": "최대",
    "PricingTab.selectFixedAmount": "고정 가격",
    "PricingTab.buttonRemove": "제거",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "필드가 비어 있으면 2개 이상의 규칙을 생성할 수 없습니다",
    "PricingTab.costRange": "가격 범위",
    "PricingTab.markup": "마크업",
    "PricingTab.add": "추가",

    "SecurityTab.Document.Title": "설정 - 보안 - Spocket",
    "SecurityTab.alertIncorrectPassword": "잘못된 비밀번호",
    "SecurityTab.title": "보안",
    "SecurityTab.yourNewPassword": "새 비밀번호",
    "SecurityTab.repeatPassword": "비밀번호 확인",
    "SecurityTab.errorPleaseEnterAPassword": "비밀번호를 입력해 주세요.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "비밀번호를 다시 한 번 입력해 주세요.",
    "SecurityTab.errorPasswordsDoNotMatch": "비밀번호가 맞지 않습니다",
    "SecurityTab.errorPasswordShort": "비밀번호는 최소 8자로 설정해야 합니다",

    "ListingCards.TopSupplierTag.Tooltip":
      "인기 공급업체는 고품질 제품, 최상의 서비스, 높은 평가로 유명합니다",
    "ListingCards.TopSupplierTag.Text": "공급업체",

    "ListingCard.OriginCountry.Intro": "기준",
    "ListingCard.OriginCountry.Tooltip": "발송지",
    "ListingCard.Shipping.Price.Title": "배송비",
    "ListingCard.Shipping.Price.Free": "무료",
    "ListingCard.Price.Intro": "목록 비용",
    "ListingCard.Price.Retail": "소매가",
    "ListingCard.Shipping.Time.Title": "배송 기간",
    "ListingCard.Shipping.Time.Period": "일",
    "ListingCard.PremiumIcon.Tooltip":
      "내 쇼핑몰에서 할인율이 높으며 배송이 빠른 제품을 독점적으로 판매하세요",
    "ListingCard.PremiumIcon.Text": "프리미엄",
    "ListingCard.ListButton.Import": "가져오기 목록에 추가",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "스토어에 올렸습니다",
    "ListingCard.StatusTag.Imported.Title": "내보냄",
    "ListingCard.StatusTag.Imported.Text": "해당 제품은 이미 내보내기 목록에 존재합니다",
    "ListingCard.StatusTag.Pushed.Title": "판매중",
    "ListingCard.StatusTag.Pusehd.Text": "해당 제품을 내 쇼핑몰에 올렸습니다",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "제품 설명",
    "ListingModal.DetailsSection.Button.ProductVariations": "제품 옵션 열기",
    "ListingModal.DetailsSection.Button.OrderSamples": "샘플 주문",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "신제품",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert": "해당 제품에 브랜드 송장을 이용할 수 없습니다",
    "ListingModal.DetailsSection.InternationalShipping.Alert": "국제 주문은 중국의 물류센터에서 배송됩니다",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "미국 물류센터에 재고가 없는 제품은 중국의 물류센터에서 발송될 수 있습니다",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "주문 내역에 대한 전체 배송 기간을 단축하기 위해 여러 개로 나뉘어 발송될 수 있습니다",
    "ListingModal.DetailsSection.ConnectStore.Alert": "주문하려면 내 스토어를 연결하세요",
    "ListingModal.DetailsSection.PushedListButton": "스토어에 올렸습니다",
    "ListingModal.DetailsSection.RemoveListButton": "목록에서 제거",
    "ListingModal.DetailsSection.ImportListButton": "가져오기 목록에 추가",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "목록이 비활성화되었습니다",

    "InfoSection.ShippingTime.Tooltip": "제품 발송 후 배송 기간.",
    "InfoSection.ShippingTime.Title": "배송 기간",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "글로벌",
    "InfoSection.ShippingExcluded.Intro": "배송 불가 지역",
    "InfoSection.ShippingExcluded.Various": "여러 지역",
    "InfoSection.ProcessingTime.Tooltip": "공급업체에서 배송 및 배송 조회 번호를 제공하는 영업일 수",
    "InfoSection.ProcessingTime.Title": "처리 시간",
    "InfoSection.ProcessingTime.Period": "영업일",
    "InfoSection.ShippingInfoLine.Tooltip":
      "해당 제품의 배송비는 {{price_formatted}}이며, 동일한 주문 내의 각 추가 제품에 대해 {{incremental_price_formatted}}만큼 더해집니다",
    "InfoSection.ShippingInfoLine.Period": "영업일",
    "InfoSection.ShippingInfoLine.Free": "무료",
    "InfoSection.ShippingInfoLine.NoShipping": "배송 불가",

    "ReturnPolicySection.Title": "환불 정책",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "발송지",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "기준",
    "TitleSection.PriceMSRP.Listing": "표시가",
    "TitleSection.PriceMSRP.Retail": "소매가",

    "ProductVariationsModal.Title": "제품 옵션",
    "ProductVariationsModal.Table.Image": "이미지",
    "ProductVariationsModal.Table.Inventory": "재고",
    "ProductVariationsModal.Table.Price": "가격",
    "ProductVariationsModal.ImageSpecifications.Title": "사이즈 차트",
    "ProductVariationsModal.ImageSpecifications.Button": "다운로드",

    "TopSupplier.Tooltip": "인기 공급업체는 고품질 제품, 최상의 서비스, 높은 평가로 유명합니다",
    "TopSupplier.Text": "인기 공급업체",

    "BestSelling.Tooltip.Top": "Spocket의 최다 판매 제품입니다",
    "BestSelling.Tooltip.Bottom": "베스트셀러",

    "GallerySection.PremiumIcon.Tooltip":
      "내 쇼핑몰에서 할인율이 높으며 배송이 빠른 제품을 독점적으로 판매하세요",
    "GallerySection.PremiumIcon.Text": "프리미엄",
    "GallerySection.DiscountStamp.Text": "추가 {{value}}% 할인",

    "Upgrade.Title.AnnualToggled": "지금 Spocket 프리미엄 시작",
    "Upgrade.Title.MonthlyToggled": "14일 동안 Spocket 프리미엄을 무료로 사용해 보세요",
    "Upgrade.Subtitle":
      "지금 Spocket 프리미엄 플랜 구독을 시작하고 미국 및 유럽에서 전환율이 높은 제품을 내 스토어에 추가할 수 있습니다.",
    "Upgrade.Button": "Spocket 프리미엄 없이 진행",

    "Search.NoProductsFound.Title": "죄송합니다! 다음에 대한",
    "Search.NoProductsFound.For": "결과를 찾지 못했습니다",
    "Search.NoProductsFound.Description":
      "맞춤법을 확인하거나 관련 검색어 또는 보다 일반적인 검색어를 사용해 보세요.",
    "Search.Searching.Title": "제품 검색 중입니다...",
    "Search.Searching.Description": "최근 제품을 불러오는 동안 잠시만 기다려 주세요.",

    "Suppliers.StarRating.Tooltip": "공급업체 주문 처리 달성률",
    "Suppliers.RefundPolicy": "공급업체 환불 정책",
    "Suppliers.Joined.Title": "Spocket에 가입했습니다",
    "Suppliers.Origin.Title": "배송 국가",
    "Suppliers.Time.Title": "평균 처리 시간",
    "Suppliers.HasMore.Title": "제품 검색 중입니다...",
    "Suppliers.HasMore.Subtitle": "최근 제품을 불러오는 동안 잠시만 기다려 주세요.",
    "Suppliers.TopSupplierTag.Tooltip": "인기 공급업체는 고품질 제품, 최상의 서비스, 높은 평가로 유명합니다",
    "Suppliers.TopSupplierTag.Text": "인기 공급업체",

    "AltTextModal.Header.Title": "이미지 세부 정보 편집",
    "AltTextModal.Body.Description":
      "해당 이미지에 대한 간단한 설명을 추가하여 검색 엔진 최적화(SEO)를 수행하고 시각 장애 고객의 접근성을 개선할 수 있습니다.",
    "AltTextModal.Input.Label": "대체 텍스트",
    "AltTextModal.Input.Placeholder": "이미지 대체 텍스트",
    "AltTextModal.Button.Cancel": "취소",
    "AltTextModal.Button.Save": "저장",

    "WistiaModal.WhatIsSpocket.Title": "Spocket은 무엇인가요?",
    "WistiaModal.WhatIsSpocket.Text":
      "Spocket이 비즈니스 구축에 어떤 도움을 제공하는지 알아보세요. Spocket 사용 방법과 최상의 활용법을 이해해 보세요.",
    "WistiaModal.WhatIsSpocket.Button": "제품 검색 시작",
    "WistiaModal.DiscoverProducts.Title": "제품 검색",
    "WistiaModal.DiscoverProducts.Text": "적절한 필터를 활용해 적합한 제품을 찾아 내 스토어에 추가합니다.",
    "WistiaModal.DiscoverProducts.Button": "제품 검색 시작",
    "WistiaModal.ProductCustomization.Title": "제품 맞춤 설정",
    "WistiaModal.ProductCustomization.Text":
      "가져오기 목록을 사용해 내 스토어에 맞게 제품을 수정합니다. 제품 설명 편집, 가격 설정, 컬렉션에 제품 추가 및 이미지 제거 등을 수행할 수 있습니다.",
    "WistiaModal.ProductCustomization.Button": "가져오기 목록으로 이동",
    "WistiaModal.OrderProcessing.Title": "주문 처리 자동화",
    "WistiaModal.OrderProcessing.Text":
      "주문을 처리하고 클릭 한 번으로 공급업체가 고객의 문 앞까지 제품을 배송합니다.",
    "WistiaModal.OrderProcessing.Button": "주문 내역으로 이동",
    "WistiaModal.Settings.Title": "설정",
    "WistiaModal.Settings.Text":
      "설정 페이지에서 모든 계정 정보를 관리하고, 브랜드 송장을 만들고, 신용카드를 추가할 수 있습니다.  또한 모든 제품의 가격 설정 규칙을 조정하고 구독 플랜을 선택할 수도 있습니다.",
    "WistiaModal.Settings.Button": "계정 설정",

    "Footer.Copyright": "Copyright {{year}}, Spocket. All rights reserved",
    "Footer.PrivacyPolicy": "개인정보 보호정책",
    "Footer.TermsConditions": "이용약관",
    "Footer.DataProcessingAddendum": "데이터 처리 부록",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
