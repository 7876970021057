import { lazy } from "react";
import paths from "../paths";

const Login = lazy(() => import("components/Login"));
const Register = lazy(() => import("components/Register"));
const AlibabaRegister = lazy(() => import("components/AlibabaRegister"));
const AliexpressRegister = lazy(() =>
  import("utils/redirects/AliexpressRegisterRedirect").then(module => ({
    default: module.AliexpressRegister
  }))
);
const Signup = lazy(() => import("pages/Signup"));
const CreateAccount = lazy(() => import("pages/CreateAccount"));
const Registration = lazy(() => import("pages/Registration"));

export const authRoutes = [
  { path: paths.auth.login, Component: Login },
  { path: paths.auth.register, Component: Register },
  { path: paths.auth.alibabaRegister, Component: AlibabaRegister },
  {
    path: paths.auth.aliexpressRegister,
    Component: AliexpressRegister
  },
  { path: paths.auth.signup, Component: Signup },
  { path: paths.auth.createAccount, Component: CreateAccount },
  { path: paths.auth.registration, Component: Registration }
];
