export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English

    "OrderList.Header.Title": "Liste over mine ordrer",
    "OrderList.Input.Search": "Ordrenummer...",
    "OrderList.Label.Status": "Filtrer efter Spocket-status",

    "order.status.unpaid": "Ikke betalt",
    "order.status.paid": "Betalt",
    "order.status.processing": "Behandles",
    "order.status.shipped": "Afsendt",
    "order.status.cancelled": "Annulleret",
    "order.status.pending": "Afventer",
    "order.status.partially_paid": "Delvist betalt",
    "order.status.partially_refunded": "Delvist refunderet",
    "order.status.refunded": "Refunderet",
    "order.status.voided": "Annulleret",
    "order.status.authorized": "Autoriseret",
    "order.status.deleted": "Slettet",
    "order.status.pending_payment_confirmation": "Afventer",

    "OrderList.Label.DownloadHistory": "Download al ordrehistorik som CSV",
    "OrderList.Document.Title": "Ordrer - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Søg i produkter",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Sådan behandles ordrer",
    "OrderList.EmptyOrders.Message.Loading.Title": "Dine ordrer indlæses!",
    "OrderList.EmptyOrders.Message.Loading.Description": "Vent venligst mens vi indlæser dine ordrer.",
    "OrderList.EmptyOrders.Message.Title": "Din ordreliste er tom!",
    "OrderList.EmptyOrders.Message.Description":
      "Du kan administrere og behandle dine ordrer her. Gå til søg og begynd at tilføje produkter til din importliste.",
    "OrderList.CheckBox.SelectAllOrders": "Vælg alle ordrer",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} ordre valgt",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} ordre valgt",
    "OrderList.CheckBox.BulkCheckoutOrders": "Stort parti gennemfør ordre",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Stort parti gennemfør ordrer",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Synkroniser ordrer",
    "RefreshModal.Button.Cancel": "Annuller",
    "RefreshModal.Button.Synchronize": "Synkroniser",
    "RefreshModal.Modal.Body":
      "Ordrer synkroniseres automatisk fra din webshop. Synkroniser kun hvis ordreinformation mangler eller ikke er opdateret. Genindlæs venligst siden efter 30 sekunder.",

    "Order.Label.OrderNumber": "Ordrenummer",
    "Order.Label.Date": "Dato",
    "Order.Label.OrderStatus": "{{integrationName}} ordrestatus",
    "Order.Table.Th.Product": "Produkt",
    "Order.Table.Th.Qty": "Antal",
    "Order.Table.Th.UnitPrice": "Enhedspris",
    "Order.Table.Th.CheckoutDate": "Købsdato",
    "Order.Table.Th.Action": "Handling",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Vis kundeinfo",
    "CustomerModal.Modal.Title.CustomerInfo": "Kundeinfo",
    "CustomerModal.Modal.Body.CustomerInfo": "Kundeinfo",
    "CustomerModal.Modal.Body.Address": "Adresse",

    "OrderLine.Button.Checkout": "Kasse",
    "OrderLine.Tooltip.PreviewInvoice": "Forhåndsvis faktura",
    "OrderLine.Tooltip.PaymentReceipt": "Betalingskvittering",
    "OrderLine.Tooltip.TrackOrder": "Følg ordre",
    "OrderLine.Tooltip.OrderProcessing": "Ordre behandles",
    "OrderLine.Tooltip.OrderCancelled": "Ordre annulleret",
    "OrderLine.Tooltip.PleaseConfirm": "Klik venligst for at bekræfte din betaling af ordren",
    "OrderLine.Label.PurchaseEtsy": "Køb på Etsy",
    "OrderLine.Paying": "Betaler",
    "OrderLine.Failure": "Fejl",
    "OrderLine.Sample": "Vareprøve",
    "OrderLine.ErrorOrderRow": "Kan ikke finde ordren i systemet! Kontakt venligst supporten!",

    "ConfirmationModal.Label.OrderDetails": "Ordredetaljer",
    "ConfirmationModal.Label.Total": "Total",
    "ConfirmationModal.Label.Shipping": "Forsendelse",
    "ConfirmationModal.Label.TransactionFees": "Transaktionsgebyrer",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Transaktionsgebyrer inkluderer gebyrer opkrævet af Stripe, Spockets betalingsudbyder samt administrationsgebyrer.",
    "ConfirmationModal.Modal.OrderConfirmation": "Ordrebekræftelse",
    "ConfirmationModal.Modal.Alert.Attention": "OBS!",
    "ConfirmationModal.Modal.Alert.Description": "Denne ordre indeholder en bemærkning fra kunden.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Du er ved at afgive en ordre for",
    "ConfirmationModal.Modal.Table.Th.Name": "Navn",
    "ConfirmationModal.Modal.Table.Th.Price": "Pris",
    "ConfirmationModal.Modal.Table.Th.Qty": "Antal",
    "ConfirmationModal.Modal.Table.Th.Total": "Total",
    "ConfirmationModal.Modal.Label.Note": "Bemærkning til leverandør",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Din besked",
    "ConfirmationModal.Button.PlaceOrder": "Afgiv ordre",

    "PaymentCompleteModal.PaymentCompleted": "Betaling gennemført",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Tak for din betaling. Leverandøren er blevet informeret og vil behandle ordren snart. Vi opdaterer ordrens status og tilføjer trackingnummeret på din ordreside.",
    "PaymentCompleteModal.PaymentIncomplete": "Din ordre er ufuldstændig!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Din bank kræver en bekræftelse af din betaling for at fortsætte",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Brug venligst dette link til at bekræfte betalingen.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Når betalingen er bekræftet, vil leverandøren få besked, og din ordre vil behandles som normalt. Status- og trackingopdateringer føjes til din ordreside. Genindlæs venligst siden efter bekræftelse.",

    "SetYourGoalsModal.Title": "Antal produkter tilføjet til webshoppen",
    "SetYourGoalsModal.Subtitle": "En typisk webshop annoncerer for 25 produkter, før den opnår salg",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} produkt",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} produkter",
    "SetYourGoalsModal.Button": "Sæt mit mål og begynd dropshipping",

    "YourGoalsBanner.Container.Description": "Dine mål",
    "YourGoalsBanner.Header.Title": "Start en virksomhed",
    "YourGoalsBanner.Header.Subtitle": "Gør din webshop klar",
    "YourGoalsBanner.Progress.Completed": "Færdiggjort",
    "YourGoalsBanner.ProductPushedCount": "<0>Mål: {{goalsNumberOfProduct}}</0> annoncerede produkter ",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Tilmeld dig Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Første søgning",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}}/{{goalsNumberOfProduct}} produkt annonceret",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}}/{{goalsNumberOfProduct}} produkter annonceret",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}}/{{goalsNumberOfProduct}} produkt importeret",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}}/{{goalsNumberOfProduct}} produkter importeret",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Automatiseret AliExpress Dropshipping",
    "Aliscraper.Subtitle":
      "Importer tusindvis af AliExpress-produkter til din webshop med ét klik og automatiser din dropshopping-virksomhed.",
    "Aliscraper.Features.Feature1": "Hurtig import med ét klik",
    "Aliscraper.Features.Feature2": "Behandl hundredvis af ordrer på et øjeblik med Spocket",
    "Aliscraper.Features.Feature3": "24/7 kundeservice",
    "Aliscraper.Button": "Download udvidelsen",
    "Aliscraper.Chrome": "Tilgængelig i Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English

    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Forbind din webshop",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Vælg din platform for at komme i gang",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Ugyldigt webshopnavn",
    "ConnectStoreModal.ShopifyForm.Placeholder": "f.eks. MinWebshop",
    "ConnectStoreModal.ShopifyForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.ShopifyForm.Button": "Forbind webshop",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Din autoriseringsnøgle:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Sådan forbinder du din webshop til Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Installér Spocket-plugin",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "herfra",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Aktiver plugin fra din pluginside",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Gå til WordPress-oversigten > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Indsæt din autoriseringsnøgle (anført ovenfor) og klik på »Gem«",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Færdig",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.WixForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.WixForm.Button": "Forbind webshop",
    "ConnectStoreModal.FelexForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.FelexForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.FelexForm.Button": "Forbind webshop",
    "ConnectStoreModal.BigcommerceForm.UrlText": "Bigcommerce Store-URL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Ugyldigt webshopnavn",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "f.eks. MinWebshop",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.BigcommerceForm.Button": "Forbind webshop",
    "ConnectStoreModal.EcwidForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.EcwidForm.Button": "Forbind webshop",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.WhiteLabelForm.Button": "Forbind webshop",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.SquarespaceForm.Button": "Forbind webshop",
    "ConnectStoreModal.SquareForm.FooterText": "Har du ikke en webshop?",
    "ConnectStoreModal.SquareForm.FooterLink": "Få en gratis",
    "ConnectStoreModal.SquareForm.Button": "Forbind webshop",
    "ConnectStoreModal.FelexButton.Explanation":
      "En platform til oprettelse af webshops, så iværksættere kan starte deres dropshipping-virksomhed på mindre end 10 minutter.",
    "ConnectStoreModal.DontHaveStore":  "Har du ikke en webshop?",
    "ConnectStoreModal.TryForFree": "Få en gratis",

    "PaymentTab.Document.Title": "Indstillinger - Automatisering - Spocket",
    "PaymentTab.Title.Billing": "Fakturering",
    "PaymentTab.Tooltip.Billing":
      "Kreditkort vil blive benyttet til behandling af ordrer samt abonnementsbetalinger",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Ændr/sæt dit abonnement på pause",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Ændr dit abonnement",
    "PaymentTab.AccordionItem.Button.Downgrade": "Nedgrader",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Sæt dit abonnement på pause",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Din webshop er allerede blevet sat på pause før",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Sæt dit abonnement på pause",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Du kan sætte din Spocket-konto midlertidigt på pause og vende tilbage til enhver tid uden at miste noget data.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Påmind mig senere",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Påmind mig senere",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 dage før",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Behold mine fordele og påmind mig <1>{{daysBefore}}</1> før mit medlemskab fornyes",
    "RemindMeLaterModal.Header.ReminderSet": "Påmindelse sat",
    "RemindMeLaterModal.Body.DaysBefore": "send e-mail 3 dage før",
    "RemindMeLaterModal.Body.ReminderSet":
      "Din påmindelse er sat, og vi vil <1>{{daysBefore}}</1> dine fordele fornyes, indtil da kan du fortsætte din iværksætterrejse.",
    "RemindMeLaterModal.Button.Continue": "Fortsæt",
    "PauseStoreModal.Title.PauseYourStore": "Sæt din webshop på pause",
    "PauseStoreModal.Description.PauseYourStore":
      "Benyt dette engangstilbud og reducér prisen på dit abonnement med 60% de næste 30 dage",
    "PauseStoreModal.Button.PauseStore": "Sæt webshop på pause",
    "PauseStoreModal.Description.StorePaused":
      "Din webshop er blevet sat på pause, og næste måneds abonnement vil blive reduceret med 60%. Bemærk venligst, at de løbende betalinger fortsætter bagefter.",
    "ConfirmPasswordModal.Title": "Bekræft venligst din adgangskode",
    "ConfirmPasswordModal.SubTitle": "Bekræft venligst din adgangskode af sikkerhedsmæssige årsager",
    "ConfirmPasswordModal.Input.Placeholder": "Din adgangskode",
    "ConfirmPasswordModal.Button.Cancel": "Annuller",
    "ConfirmPasswordModal.Button.Confirm": "Bekræft",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "da",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Voks hurtigere med Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Vælg din faktureringscyklus",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Dine første",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "dage ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "er gratis, annuller under eller efter din prøveperiode",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Din første faktura vil blive planlagt til",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "Din første faktura vil forfalde ved bekræftelse",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "Fakturering vil ske månedligt",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Månedligt ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/måned",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Årligt ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/måned",
    "UpgradeConfirmationModal.YearlyTab.Discount": "Rabat",
    "UpgradeConfirmationModal.TaxRates.Text": "moms",
    "UpgradeConfirmationModal.TaxRates.Period": "mdr.",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "At betale nu",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/måned x 12 måneder",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Dine besparelser: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/år",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "svarende til",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% rabat",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Årlige abonnementer har ikke en prøveperiode",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "Du vil blive opkrævet det fulde beløb med det samme",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Betal med kredit/debitkort",
    "UpgradeConfirmationModal.PayPalTab.Info": "Betal med PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Betalingsoplysninger ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Gør krav på min gratis prøveperiode",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Opgrader til ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Benyttet af 50.000+ iværksættere",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom": "Vores kunder bedømmer os altid 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128-bit SSL-kryptering",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Alle dine betalingsoplysninger er 100% sikre hos os.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "50.000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Benyttet af 50.000+ iværksættere",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom": "Vores kunder bedømmer os altid 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128-bit SSL-kryptering",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Alle dine betalingsoplysninger er 100% sikre hos os.",
    "UpgradeConfirmationModal.Agreement.Intro": "Ved at fortsætte accepterer du ",
    "UpgradeConfirmationModal.Agreement.Terms": "Brugsvilkår",
    "UpgradeConfirmationModal.Agreement.Privacy": "Privatlivspolitik",
    "UpgradeConfirmationModal.Agreement.Refund": "Refusionspolitik",
    "UpgradeConfirmationModal.Agreement.Middle": "og",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Annulleringspolitik",

    "OrderDetails.Title": "Ordredetaljer",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Kreditkort slutter på",
    "CreditCard.Update.Button": "Opdater kort",
    "CreditCard.AddCreditCard.Text":
      "Du har ikke tilføjet et kreditkort endnu. Tilføj venligst et kreditkort fra knappen nedenfor for at give os tilladelse til at behandle dine ordrer automatisk",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Tilføj kreditkort",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Vindende produkter",
    "Sidebar.WinningProducts.Variant": "Nyt",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Hjælpecenter",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Indstillinger",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Vi er her for at hjælpe!",
    "TutorialModal.WhatIsSpocket": "Hvad er Spocket?",
    "TutorialModal.DiscoverProducts": "Opdag produkter",
    "TutorialModal.ProductCustomization": "Brugerdefinering af produkter",
    "TutorialModal.OrderProcessing": "Ordrebehandling",
    "TutorialModal.Settings": "Indstillinger",
    "TutorialModal.GetHelp": "Få hjælp",
    "TutorialModal.JoinTheCommunity": "Deltag i fællesskabet",

    "NotificationCenter.Header": "Notifikationscenter",
    "NotificationCenter.Footer": "Se alle notifikationer",
    "NotificationCenter.NoNotification": "Intet at se her!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English
    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English

    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Mine produkter - Spocket",
    "Products.Title.Header": "Mine produkter",
    "Products.Loading.Top": "Din produktliste indlæses!",
    "Products.Loading.Bottom": "Vent venligst mens vi indlæser dine produkter.",
    "Products.Empty.Search": "Ingen produkter blev fundet!",
    "Products.Empty.Top": "Din produktliste er tom!",
    "Products.Empty.Bottom": "Gå til søg og begynd at tilføje produkter til din importliste.",
    "Products.Empty.Button.Search": "Søg i produkter",
    "Products.Empty.Button.How": "Sådan finder du produkter",
    "Products.Alert.Removed": "Produkt fjernet",

    "Products.Search.Label": "Søg i dine produkter",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Søg",

    "ProductListItem.Inactive.Tooltip": "Dette produkt er ikke længere tilgængeligt hos leverandøren",
    "ProductListItem.Inactive.Title": "Inaktiv",
    "ProductListItem.OutOfStock.Tooltip": "Alle variationer af dette produkt er udsolgt",
    "ProductListItem.OutOfStock.Title": "Udsolgt",
    "ProductListItem.InventoryCount.Singular": "stykke",
    "ProductListItem.InventoryCount.Plural": "stykker",
    "ProductListItem.InventoryCount.End.Singular": "tilbage",
    "ProductListItem.InventoryCount.End.Plural": "tilbage",
    "ProductListItem.Button.View": "Vis i webshop",
    "ProductListItem.Button.Remove": "Fjern fra webshop",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "Importliste - Spocket",
    "ImportList.Title.Header": "Min importliste",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "Din importliste indlæses!",
    "ImportList.Loading.Bottom": "Vent venligst mens vi indlæser dine produkter.",
    "ImportList.Empty.Button.Search": "Søg i produkter",
    "ImportList.Empty.Top": "Din importliste er tom!",
    "ImportList.Empty.Bottom": "Gå til søg og begynd at tilføje produkter til din importliste.",
    "ImportList.Empty.Button.How": "Sådan brugerdefineres produkter",
    "ImportList.Alert.Pushed": "Dette produkt er annonceret i din webshop:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "Søg i dine importerede produkter",
    "ImportList.Search.Placeholder": "Søg i dine produkter",
    "ImportList.Search.Button": "Søg",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Annoncerer for nuværende sides produkter",
    "PushAllModal.Button.Inactive": "Annoncér for nuværende side",
    "PushAllModal.Modal.Text":
      "Er du sikker på, at du ønsker at annoncere for alle produkter på den nuværende side? Alle gyldige produkter på den nuværende side vil blive annonceret på din webshop.",
    "PushAllModal.Modal.Push": "Annoncér for alle",
    "PushAllModal.Modal.Cancel": "Annuller",

    "RemoveAllModal.Button.Active": "Fjerner nuværende sides produkter",
    "RemoveAllModal.Button.Inactive": "Fjern nuværende side",
    "RemoveAllModal.Modal.Text":
      "Er du sikker på, at du ønsker at fjerne alle produkter fra den nuværende side? Alle produkter på den nuværende side af din importliste vil blive fjernet.",
    "RemoveAllModal.Modal.Remove": "Fjern alle",
    "RemoveAllModal.Modal.Cancel": "Annuller",

    "ImportListItem.Tab.Product": "Produkt",
    "ImportListItem.Tab.Description": "Beskrivelse",
    "ImportListItem.Tab.Variants": "Varianter",
    "ImportListItem.Tab.Images": "Billeder",
    "ImportListItem.Remove.Active": "Fjerner produkt",
    "ImportListItem.Remove.Inactive": "Fjern produkt",
    "ImportListItem.Push.Active": "Annonceres for på webshop",
    "ImportListItem.Push.Inactive": "Annoncér for på webshop",
    "ImportListItem.Save.Button": "Gem",
    "ImportListItem.Alert.Removed": "Dette produkt er blevet fjernet fra din importliste: ",
    "ImportListItem.Alert.RemoveFailed": "Noget gik galt, da du forsøgte at fjerne: ",
    "ImportListItem.Alert.CompareAtPrice": "Sammenligningsprisen skal være højere end salgsprisen for: ",
    "ImportListItem.Alert.OneActiveVariation": "Dette produkt har brug for mindst én aktiv variant: ",
    "ImportListItem.Alert.Saved": "Dette produkt er blevet gemt: ",
    "ImportListItem.Alert.ErrorPushing": "Noget gik galt under annonceringen på din webshop",
    "ImportListItem.Alert.ErrorSaving": "Noget gik galt, da din webshop skulle gemmes",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Forbind din webshop for at annoncere for produkter: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Du har brug for mindst én aktiv variant",
    "ImportListItem.Tooltip.Unavailable": "Dette produkt er i øjeblikket ikke tilgængeligt for køb",
    "ImportListItem.Tooltip.Unsaved": "Du har ikke-gemte ændringer",
    "ImportListItem.Tooltip.Profit": "Advarsel: Dit bruttoprofit er negativ for dette produkt",
    "ImportListItem.Tooltip.Unpurchasable":
      "Du kan gemme dette produkt, men det er i øjeblikket ikke tilgængeligt for køb",
    "ImportListItem.Variants.ShowShippingPrices": "Vis forsendelsespriser",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Produktnavn",
    "ItemInfo.ProductTags.Label": "Produkttags",
    "ItemInfo.ProductTags.Remove": "Fjern alle tags",
    "ItemInfo.ProductTags.Placeholder": "Tilføj nyt tag",
    "ItemInfo.ProductType.Label": "Produkttype",
    "ItemInfo.Collection.Label": "Samling",
    "ItemInfo.Collection.Placeholder": "Vælg",
    "ItemInfo.Collection.Option": "Vælg",
    "ItemInfo.SquareLocation.Option": "Vælg",

    "ItemVariantList.Modal.Title": "Forsendelsesdetaljer",
    "ItemVariantList.Modal.Intro": "Sender fra: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Denne vare sendes ikke internationalt.",
    "ItemVariantList.Modal.DoesNotShipTo": "Sendes ikke til: ",
    "ItemVariantList.Table.Head.Destination": "Destination",
    "ItemVariantList.Table.Head.Cost": "Pris",
    "ItemVariantList.Table.Head.Time": "Leveringstid (dage)",
    "ItemVariantList.Table.Body.Domestic": "Nationalt",
    "ItemVariantList.Table.Body.International": "Internationalt",
    "ItemVariantList.VariantsTable.ShippingPrice": "Forsendelsespris ",
    "ItemVariantList.VariantsTable.Image": "Billede",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "Lagerbeholdning",
    "ItemVariantList.VariantsTable.Price": "Pris",
    "ItemVariantList.VariantsTable.SalesPrice": "Salgspris ",
    "ItemVariantList.VariantsTable.Profit": "Profit",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Sammenlign pris",
    "ItemVariantList.VariantsTable.Unavailable": "Dette produkt er i øjeblikket ikke tilgængeligt for køb",
    "ItemVariantList.VariantsTable.Head": "Alle varianter af dette produkt er vist her.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "f.eks: 40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify understøtter ikke mere end 100 varianter.",

    "ItemVariantList.ContextPopover.Title": "Opsæt profit",
    "ItemVariantList.ContextPopover.Apply": "Anvend",

    "ProductVariation.Description":
      "Du kan afgive ordren for vareprøver direkte på Spocket. Følg trinnene nedenfor for at komme hurtigt igennem kassen.",
    "ProductVariation.Label.SelectVariant": "Vælg variant",
    "ProductVariation.Label.SelectTheProductVariation": "Vælg produktvariation",
    "ProductVariation.Qty": "Antal",
    "ProductVariation.Label.NotesForTheSupplier": "Bemærkninger til leverandør",

    "InputAddress.Label.FirstName": "Fornavn",
    "InputAddress.PlaceHolder.FirstName": "Indtast fornavn",
    "InputAddress.Label.LastName": "Efternavn",
    "InputAddress.PlaceHolder.LastName": "Indtast efternavn",
    "InputAddress.Label.StreetAddress": "Adresse",
    "InputAddress.Label.Country": "Land",
    "InputAddress.PlaceHolder.Country": "Søg efter land",
    "InputAddress.Label.State": "Stat",
    "InputAddress.Placeholder.State": "Søg efter stat",
    "InputAddress.Label.City": "By",
    "InputAddress.PlaceHolder.City": "Indtast by",
    "InputAddress.Label.Zip": "Postnummer",
    "InputAddress.PlaceHolder.Zip": "Indtast postnummer",
    "InputAddress.Label.Phone": "Telefonnummer",
    "InputAddress.Placeholder.Phone": "Indtast telefonnummer",

    "OrderReview.OrderDetails": "Ordredetaljer",
    "OrderReview.Unit": "{{count}} enhed",
    "OrderReview.Unit_plural": "{{count}} enheder",
    "OrderReview.ShippingAddress": "Forsendelsesadresse",
    "OrderReview.PaymentMethod": "Betalingsmetode",
    "OrderReview.Amounts": "Beløb",
    "OrderReview.Subtotal": "Subtotal",
    "OrderReview.ShippingCost": "Forsendelsespris",
    "OrderReview.TransactionFees": "Transaktionsgebyrer",
    "OrderReview.TransactionFees.ToolTips":
      "Transaktionsgebyrer inkluderer gebyrer opkrævet af Stripe, Spockets betalingsudbyder samt administrationsgebyrer.",
    "OrderReview.OrderTotal": "Ordre i alt",
    "OrderReview.ItemWithCount": "{{count}} vare",
    "OrderReview.ItemWithCount_plural": "{{count}} varer",
    "OrderReview.Placeholder.CreditCard": "Kreditkort slutter på {{last4}}",

    "PaymentComplete.PaymentCompleted": "Betaling gennemført",
    "PaymentComplete.PaymentCompleted.Note":
      "Tak for din betaling. Leverandøren er blevet informeret og vil behandle ordren snart. Vi opdaterer ordrens status og tilføjer trackingnummeret på din ordreside.",
    "PaymentComplete.PaymentIncomplete": "Din ordre er ufuldstændig!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Din bank kræver en bekræftelse af din betaling for at fortsætte",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink":
      "Brug venligst dette link til at bekræfte betalingen.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Når betalingen er bekræftet, vil leverandøren få besked, og din ordre vil behandles som normalt. Status- og trackingopdateringer føjes til din ordreside. Genindlæs venligst siden efter bekræftelse.",

    "CreditCard.AddCreditCard.Description":
      "Du har ikke tilføjet et kreditkort endnu. Tilføj venligst et kreditkort fra knappen nedenfor for at give os tilladelse til at behandle dine ordrer automatisk",
    "CreditCard.Button.AddCreditCard": "Tilføj kreditkort",
    "CreditCard.Button.UpdateCard": "Opdater kort",
    "CreditCard.UpdateCard.Label.CreditCard": "Kreditkort",
    "CreditCard.PlaceHolder.CreditCard": "Kreditkort slutter på {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Kreditkort",
    "UpdateCreditCardModal.Button.UpdateCard": "Opdater kort",
    "UpdateCreditCardModal.Button.AddCreditCard": "Tilføj kreditkort",
    "UpdateCreditCardModal.Button.Cancel": "Annuller",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Opdater dine kortdetaljer",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Din konto vil blive brugt under ordrebehandling af Spocket-produkter.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Vilkår og betingelser",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "for behandling af betalinger.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Vælg variant",
    "SampleOrderModal.Title.ShippingAddress": "Forsendelsesadresse",
    "SampleOrderModal.Title.PaymentMethod": "Betalingsmetode",
    "SampleOrderModal.Title.Review": "Gennemgå",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Betalingsbekræftelse påkrævet!",
    "SampleOrderModal.Title.OrderPlaced": "Ordre afgivet!",
    "SampleOrderModal.Steps": "Trin {{current}} af {{total}}",
    "SampleOrderModal.FinalStep": "Sidste trin",
    "SampleOrderModal.ConfirmPayment": "Bekræft betaling!",
    "SampleOrderModal.Finished": "Færdiggjort",
    "SampleOrderModal.Footer.Continue": "Fortsæt",
    "SampleOrderModal.Footer.PlaceOrder": "Afgiv ordre",
    "SampleOrderModal.Footer.Close": "Luk",
    "SampleOrderModal.Footer.Cancel": "Annuller",
    "SampleOrderModal.Footer.GoBack": "Gå tilbage",

    "AdvancedFiltersModal.Title.Filters": "Filtre",
    "AdvancedFiltersModal.Title.Shipping": "Forsendelse",
    "AdvancedFiltersModal.Label.ShipsFrom": "Sender fra",
    "AdvancedFiltersModal.Label.ShipsTo": "Sender til",
    "AdvancedFiltersModal.Label.ShippingTime": "Forsendelsestid",
    "AdvancedFiltersModal.Label.Popular": "Populær",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "USA",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Kun tilgængelig for forsendelse i USA",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Alle dage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1 - 3 dage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4 - 7 dage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8 - 14 dage",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15+ dage",
    "AdvancedFiltersModal.Title.ItemsCost": "Varer koster",
    "AdvancedFiltersModal.Label.ItemCost": "Vare koster",
    "AdvancedFiltersModal.Label.ShippingCost": "Forsendelsespris",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Alle priser",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratis",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "$5 og mindre",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "$15 og mindre",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "$25 og mindre",
    "AdvancedFiltersModal.Title.Supplier": "Leverandør",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Leverandører",
    "AdvancedFiltersModal.Label.TopSupplier": "Topleverandør",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Leverandører kendt for deres produkter af høj kvalitet, fantastiske service og gode bedømmelser",
    "AdvancedFiltersModal.Title.Advanced": "Avanceret",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Premium-produkter",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Produkter med store rabatter og hurtig forsendelse",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Bedst sælgende",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Bedst ydende produkter på Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Annuller",
    "AdvancedFiltersModal.Button.ViewResults": "Vis resultater",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Søg efter land...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Lande",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Søg efter leverandør...",
    "AdvancedFiltersModal.Label.All Suppliers": "Alle leverandører",
    "AdvancedFiltersModal.Label.New Suppliers": "Nye leverandører",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Alle dage",
    "AdvancedFilters.ShippingTime.One": "1 - 3 dage",
    "AdvancedFilters.ShippingTime.Four": "4 - 7 dage",
    "AdvancedFilters.ShippingTime.Eight": "8 - 14 dage",
    "AdvancedFilters.ShippingTime.Fifteen": "15+ dage",

    "AdvancedFilters.ShippingCost.Free": "Gratis forsendelse",
    "AdvancedFilters.ShippingCost.Five": "Forsendelse under $5",
    "AdvancedFilters.ShippingCost.Fifteen": "Forsendelse under $15",
    "AdvancedFilters.ShippingCost.TwentyFive": "Forsendelse under $25",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Ryd alle",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Forsendelse",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Søg",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Sender fra",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Sender til",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Leverandør",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Sorter efter",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Købspris",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Topleverandører",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Personlige fakturaer",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Bedst sælgende",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKeyValue.ItemCost.FromTo": "fra {{from}} til {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Indtast søgeord...",
    "Search.Header.Button.Search": "Søg",
    "Search.Header.Button.Filters": "Filtre",

    "Navigation.UpgradeButton.TryPro": "Prøv Spocket Pro GRATIS",
    "Navigation.UpgradeButton.TryEmpire": "Prøv Spocket EMPIRE GRATIS",
    "Navigation.UpgradeButton.TryUnicorn": "Prøv Spocket Unicorn GRATIS",

    "CategoriesCards.Label.MoreCategories": "Flere kategorier",

    "StaticCategories.WomensClothing": "Tøj til kvinder",
    "StaticCategories.WomensClothing.Activewear": "Sportstøj",
    "StaticCategories.WomensClothing.Bodysuits": "Bodystockinger",
    "StaticCategories.WomensClothing.Dresses": "Kjoler",
    "StaticCategories.WomensClothing.JacketsCoats": "Jakker & frakker",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Buksedragter & dragter",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lingeri & undertøj",
    "StaticCategories.WomensClothing.MatchingSets": "Matchende sæt",
    "StaticCategories.WomensClothing.Pants": "Bukser",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Nederdele",
    "StaticCategories.WomensClothing.Sleepwear": "Nattøj",
    "StaticCategories.WomensClothing.Socks": "Sokker",
    "StaticCategories.WomensClothing.SweatersHoodies": "Sweatre & hættetrøjer",
    "StaticCategories.WomensClothing.Swimwear": "Badetøj",
    "StaticCategories.WomensClothing.TankTops": "Tanktoppe",
    "StaticCategories.WomensClothing.TopsBlouses": "Toppe & bluser",
    "StaticCategories.WomensClothing.Tshirts": "T-shirts",

    "StaticCategories.JewelryWatches": "Smykker & ure",
    "StaticCategories.JewelryWatches.Bracelets": "Armbånd",
    "StaticCategories.JewelryWatches.Earrings": "Øreringe",
    "StaticCategories.JewelryWatches.Necklaces": "Halskæder",
    "StaticCategories.JewelryWatches.OtherAccessories": "Andet tilbehør",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Vedhæng, sten & charms",
    "StaticCategories.JewelryWatches.Rings": "Ringe",
    "StaticCategories.JewelryWatches.Sets": "Sæt",
    "StaticCategories.JewelryWatches.Watches": "Ure",

    "StaticCategories.TechAccessories": "Tech tilbehør",
    "StaticCategories.TechAccessories.AudioVideo": "Lyd & video",
    "StaticCategories.TechAccessories.CasesCovers": "Etuier & covers",
    "StaticCategories.TechAccessories.Lighting": "Belysning",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "Tilbehør til mobil & bærbar",
    "StaticCategories.TechAccessories.Mousepads": "Musemåtter",
    "StaticCategories.TechAccessories.Novelty": "Pynteting/gadgets",

    "StaticCategories.KidsBabies": "Børn & babyer",
    "StaticCategories.KidsBabies.BabyClothing": "Babytøj",
    "StaticCategories.KidsBabies.Bathing": "Badetid",
    "StaticCategories.KidsBabies.BlanketsPillows": "Tæpper & puder",
    "StaticCategories.KidsBabies.CapsHeadbands": "Kasketter & hårbånd",
    "StaticCategories.KidsBabies.Footwear": "Fodtøj",
    "StaticCategories.KidsBabies.Furniture": "Møbler",
    "StaticCategories.KidsBabies.KidsClothing": "Børnetøj",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Forældre & tilbehør",

    "StaticCategories.Toys": "Legetøj",
    "StaticCategories.Footwear": "Fodtøj",
    "StaticCategories.Footwear.Boots": "Støvler",
    "StaticCategories.Footwear.Flats": "Flade sko",
    "StaticCategories.Footwear.Heels": "Højhælede sko",
    "StaticCategories.Footwear.Mens": "Sko til mænd",
    "StaticCategories.Footwear.Sandals": "Sandaler",
    "StaticCategories.Footwear.Slippers": "Hjemmesko",
    "StaticCategories.Footwear.SneakersRunners": "Sneakers & løbesko",

    "StaticCategories.BathBeauty": "Bad & skønhed",
    "StaticCategories.BathBeauty.Bodycare": "Kropspleje",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffuser, olie & lys",
    "StaticCategories.BathBeauty.Haircare": "Hårpleje",
    "StaticCategories.BathBeauty.Healthcare": "Sundhed",
    "StaticCategories.BathBeauty.Makeup": "Makeup",
    "StaticCategories.BathBeauty.Nails": "Negle",
    "StaticCategories.BathBeauty.Skincare": "Hudpleje",
    "StaticCategories.BathBeauty.TowelsRobes": "Håndklæder & morgenkåber",

    "StaticCategories.Pets": "Kæledyr",
    "StaticCategories.Pets.BedsBlankets": "Senge & tæpper",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Snore, halsbånd & tøj til kæledyr",
    "StaticCategories.Pets.Petcare": "Kæledyrspleje",
    "StaticCategories.Pets.Toys": "Legetøj",

    "StaticCategories.HomeGarden": "Hjem & have",
    "StaticCategories.HomeGarden.Bathroom": "Badeværelse",
    "StaticCategories.HomeGarden.Clocks": "Ure",
    "StaticCategories.HomeGarden.Drinkware": "Drikkeglas",
    "StaticCategories.HomeGarden.FoodBeverage": "Mad & drikkevarer",
    "StaticCategories.HomeGarden.Furniture": "Møbler",
    "StaticCategories.HomeGarden.Garden": "Have",
    "StaticCategories.HomeGarden.HomeDecor": "Dekorationer til indretning",
    "StaticCategories.HomeGarden.HomeImprovement": "Renovering af hjemmet",
    "StaticCategories.HomeGarden.Kitchen": "Køkken",
    "StaticCategories.HomeGarden.Lighting": "Belysning",
    "StaticCategories.HomeGarden.Organization": "Virksomhed",
    "StaticCategories.HomeGarden.StationeryCrafts": "Kontorartikler & hobby",
    "StaticCategories.HomeGarden.TextilesPillows": "Tekstiler & puder",
    "StaticCategories.HomeGarden.Tools": "Værktøj",

    "StaticCategories.Accessories": "Tilbehør",
    "StaticCategories.Accessories.Belts": "Bælter",
    "StaticCategories.Accessories.Cufflinks": "Manchetknapper",
    "StaticCategories.Accessories.Hats": "Hatte",
    "StaticCategories.Accessories.Keychains": "Nøgleringe",
    "StaticCategories.Accessories.ScarvesBandanas": "Tørklæder & bandanaer",
    "StaticCategories.Accessories.Sunglasses": "Solbriller",

    "StaticCategories.MensClothing": "Tøj til mænd",
    "StaticCategories.MensClothing.Activewear": "Sportstøj",
    "StaticCategories.MensClothing.DressShirts": "Skjorter",
    "StaticCategories.MensClothing.JacketsCoats": "Jakker & frakker",
    "StaticCategories.MensClothing.Pants": "Bukser",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Sokker",
    "StaticCategories.MensClothing.SweatersHoodies": "Sweatre & hættetrøjer",
    "StaticCategories.MensClothing.Swimwear": "Badetøj",
    "StaticCategories.MensClothing.TankTops": "Tanktoppe",
    "StaticCategories.MensClothing.Tshirts": "T-shirts",
    "StaticCategories.MensClothing.Underwear": "Undertøj",

    "StaticCategories.SportsOutdoors": "Sport & udendørs",
    "StaticCategories.SportsOutdoors.Activewear": "Sportstøj",
    "StaticCategories.SportsOutdoors.Biking": "Cykling",
    "StaticCategories.SportsOutdoors.Camping": "Camping",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Udstyr & tilbehør",
    "StaticCategories.SportsOutdoors.Fishing": "Fiskeri",

    "StaticCategories.Gifts": "Gaver",
    "StaticCategories.Gifts.Adults": "Voksne",
    "StaticCategories.Gifts.Funny": "Sjovt",
    "StaticCategories.Gifts.Mugs": "Krus",
    "StaticCategories.Gifts.Novelty": "Pynteting/gadgets",
    "StaticCategories.Gifts.Personalized": "Personligt",
    "StaticCategories.Gifts.Religious": "Religiøst",

    "StaticCategories.Automotive": "Køretøjer",
    "StaticCategories.BagsWallets": "Tasker & punge",
    "StaticCategories.Seasonal": "Sæsonbestemt",
    "StaticCategories.Seasonal.Christmas": "Jul",
    "StaticCategories.Seasonal.FathersDay": "Fars Dag",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Mors Dag",

    "StaticCategories.FestivalsParties": "Festivaller & fester",
    "StaticCategories.FestivalsParties.PartySupplies": "Festartikler",
    "StaticCategories.FestivalsParties.Wedding": "Bryllup",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Hurtig forsendelse i USA",
    "QuickFilters.Label.Under5": "Forsendelse i USA under $5",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Sender fra <2/> {{ country }}",
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Sorter efter",
    "SortBy.DropDown.Item.Relevance": "Relevans",
    "SortBy.DropDown.Item.PriceLowToHigh": "Pris: lav til høj",
    "SortBy.DropDown.Item.PriceHighToLow": "Pris: høj til lav",
    "SortBy.DropDown.Item.Newest": "Nyeste",
    "SortBy.DropDown.Item.ShippingTime": "Forsendelsestid",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Hjem",
    "Breadcrumb.Item.Search": "søg",

    "UpgradeModal.Modal.Title": "Vælg en prisoversigt",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
  
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English


    "Plans.ToggleSection.Monthly": "Månedligt",
    "Plans.ToggleSection.Yearly": "Årligt",
    "Plans.ToggleSection.DiscountInitial": "Få op til",
    "Plans.ToggleSection.DiscountSingular": "Måned",
    "Plans.ToggleSection.DiscountPlural": "Måneder",
    "Plans.ToggleSection.DiscountFinal": "Rabat",
    "Plans.ProrationCost.TextInitial": "Du betaler i øjeblikket",
    "Plans.ProrationCost.TextFinal": "/år. Opgrader blot til Empire for yderligere",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", //English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    
    "Card.DescriptionVariantType.Starter": "Begynd at få salg",
    "Card.DescriptionVariantType.Professional": "Boost salg",
    "Card.DescriptionVariantType.Empire": "Maksimér salg",
    "Card.DescriptionVariantType.Unicorn": "Administrer salg på skala",
    "Card.DescriptionVariantType.Period": "mdr.",
    "Card.DescriptionVariantType.PricePeriod": "/mdr.",
    // eslint-disable-next-line
    "Card.DescriptionVariantType.AnnualDescription": "faktureret årligt for ${{value}}",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Dit nuværende abonnement",
    "Card.DescriptionVariantType.Button.Start": "Start nu",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English

    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Brandet fakturering",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Vindende produkter",
    "Card.DescriptionVariantType.SupplierSourcing": "Leverandørvalg",
    "Card.DescriptionVariantType.ProductRequests": "Produktanmodninger",
    "Card.DescriptionVariantType.BulkCheckout": "Stort parti betal",
    "Card.DescriptionVariantType.Special": "special",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Dit Spocket-abonnement er udløbet.",
    "ReactivationBanner.InfoSubtitle": "Genaktivér dit",
    "ReactivationBanner.CheckList.HighQuality": "Høj kvalitet",
    "ReactivationBanner.CheckList.Curated": "Kureret",
    "ReactivationBanner.CheckList.FastShipping": "Hurtig forsendelse",
    "ReactivationBanner.CheckList.FromUSAndEU": "Fra USA og EU",
    "ReactivationBanner.TextByPlan.Starter.Description":
      "Du vil ikke længere kunne <1>annoncere nogen produkter</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket-konto",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Begynd at annoncere produkter på din webshop nu",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Du vil ikke længere være i stand til at <1>annoncere produkter</1> live eller bruge ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Premium-produkter",
    "ReactivationBanner.TextByPlan.Others.Title": "Premium-konto",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Få adgang til Premium-produkter, der er",
    "ReactivationBanner.Button": "Genaktivér min konto NU",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Annoncering af flere produkter",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Ubegrænsede ordrer",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Brandet fakturering",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Premium-produkter",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Eksklusive tilbud",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Chat- & telefonsupport",
    "UpgradeReasonModal.Modal.Title": "Hvad ønsker du at opnå med Spocket?",
    "UpgradeReasonModal.Modal.Question": "Hvad fik dig til at opgradere?",
    "UpgradeReasonModal.Modal.Button": "Begynd at bruge Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Det hjælper os med at forbedre din oplevelse",

    "UpgradeSuccessModal.Col.One.A": "Tillykke",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Du har låst op for alle de værktøjer, du skal bruge til at opbygge en succesfuld dropshipping-virksomhed.",
    "UpgradeSuccessModal.Col.Two.B": "Du kan nu have ",
    "UpgradeSuccessModal.Col.Two.C": " Happy Spocketing!",
    "UpgradeSuccessModal.Button": "Fortsæt",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 måned",
    "Promotion.TrialDaysNormalizer.28days": "4 uger",
    "Promotion.TrialDaysNormalizer.21days": "3 uger",
    "Promotion.TrialDaysNormalizer.14days": "2 uger",
    "Promotion.TrialDaysNormalizer.7days": "1 uge",
    "Promotion.TrialDaysNormalizer.1day": "1 dag",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} dage",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Få en gratis",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Prøv nu →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Sprog",

    "WhatYouWillLoseModal.Button.Offer": "Fortsæt",
    "WhatYouWillLoseModal.Button.Downgrade": "Jeg giver op, nedgradér min konto",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Du mister alle <1>{{product_count}} importerede produkter</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} mere",
    "WhatYouWillLoseModal.ModalWrapper.Header": "er du allerede ved at give op?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Få 50% rabat",
    "WhatYouWillLoseModal.ModalWrapper.Title": "Fortsæt din iværksætterrejser de næste 3 måneder.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Vi ønsker, at du bliver endnu en succeshistorie. Vi ved, at du allerede har brugt meget tid. Så hold dampen oppe. Vi hepper alle sammen på dig. Team Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Tillykke, vi har allerede tilføjet 50% rabat til dit abonnement over de næste 3 måneder!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Fortsæt",

    "DowngradeReasonModal.DowngradeSection": "Hvordan kan vi gøre Spocket bedre for dig?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Vi vil gerne have din feedback om alt (minimum 100 tegn)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Indtast venligst din feedback (minimum 100 tegn)",
    "DowngradeReasonModal.Button.Downgrade": "Nedgrader",
    "DowngradeReasonModal.ModalWrapper.Header": "Nedgradér {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Kontakt os",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Annuller",

    "DowngradeSuccessModal.Header": "Nedgradering gennemført",
    "DowngradeSuccessModal.Body":
      "Tak for din feedback. Vi forbedrer konstant Spocket hver dag, så vi håber, at du giver os en chance mere snart!",

    "Settings.title": "Indstillinger",
    "Settings.buttonSaveChanges": "Gem ændringer",
    "Settings.buttonSaveAndPreview": "Gem og forhåndsvis",
    "Settings.PricingPlans.Title": "Prispakker",

    "Settings.MenuTab.Plans": "Pakker",
    "Settings.MenuTab.Account": "Konto",
    "Settings.MenuTab.BrandedInvoicing": "Brandet fakturering",
    "Settings.MenuTab.Billing": "Fakturering",
    "Settings.MenuTab.GlobalPricingRules": "Globale prisregler",
    "Settings.MenuTab.Security": "Sikkerhed",

    "AccountTab.documentTitle": "Indstillinger - Konto - Spocket",
    "AccountTab.title": "Kontoindstillinger",
    "AccountTab.shopName": "Webshopnavn",
    "AccountTab.email": "E-mail",
    "AccountTab.shopUrl": "Webshop-URL",
    "AccountTab.defaultCurrency": "Standardvaluta",
    "AccountTab.yourPlan": "Dit abonnement",
    "AccountTab.accountNotActive": "Din konto er ikke aktiv i øjeblikket",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Denne funktion tilføjes snart! For nuværende bedes du forbinde gennem Spocket Shopify-app!",
    "AccountTab.alertAccountSettingsUpdated": "Kontoindstillinger opdateret",
    "AccountTab.alertInvalidShopUrl": "Ugyldig webshop-URL",
    "AccountTab.productsImported": "Produkter importeret",
    "AccountTab.products": "Produkter",
    "AccountTab.premiumProducts": "Premium-produkter",
    "AccountTab.total": "total",
    "AccountTab.signOut": "Log ud",
    "AccountTab.limitReachedProductsAndPremium": "Grænse for produkter og Premium-produkter nået.",
    "AccountTab.limitReachedProducts": "Grænse for produkter nået.",
    "AccountTab.limitReachedPremium": "Grænse for Premium-produkter nået.",
    "AccountTab.buttonSwitchToAnnualPlan": "Skift til årligt abonnement",
    "AccountTab.off30Percent": "30% rabat",
    "AccountTab.off40Percent": "40% rabat",
    "AccountTab.off45Percent": "45% rabat",
    "AccountTab.sellMoreWithEmpirePlan": "Sælg mere med Empire-abonnement",
    "AccountTab.tooltipCurrency":
      "Din valuta administreres gennem din webshop-konto. Alle priser for dine importerede produkter vil blive konverteret til den valuta.",
    "AccountTab.shopNotConnectedYet": "Din webshop er ikke forbundet med denne konto endnu.",
    "AccountTab.connectYourShop": "Forbind din webshop",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Indstillinger - Fakturering - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Fakturaindstillinger gemt!",
    "InvoiceTab.shopLogo": "Logo til webshop",
    "InvoiceTab.contactEmail": "E-mail",
    "InvoiceTab.phoneNumber": "Telefonnummer",
    "InvoiceTab.personalNote": "Personlig bemærkning",
    "InvoiceTab.tooltipPersonalNote":
      "Opsæt en personlig bemærkning, som inkluderes på hver af dine brandede fakturaer",
    "InvoiceTab.brandedInvoicing": "Brandet fakturering",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Tilføj dit brands logo og en personlig bemærkning til alle ordrer fra leverandører, som tilbyder brandet fakturering, når du er på et betalt abonnement",
    "InvoiceTab.disabledInvoicing": "Inkluder brandet fakturering på alle ordrer",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Deaktivering af denne valgmulighed vil fjerne brandet fakturering, når du afgiver ordrer",

    "PricingTab.titleDefaultPricingRules": "Standard prisregler",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Avancerede prisregler",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Tildel cents/ører",
    "PricingTab.tooltipAssignCents":
      "Denne værdi benyttes, når den endelige varepris vises (f.eks. hvis du ønsker prisen på dit produkt til at være XX,99, så tilføjer du 99 i felterne nedenfor).",
    "PricingTab.Markup": "Prisforhøjelse",
    "PricingTab.MarkupType": "Type af prisforhøjelse",
    "PricingTab.SelectDefault": "-- Vælg én --",
    "PricingTab.SelectPercent": "Procent",
    "PricingTab.SelectMultiplier": "Avance",
    "PricingTab.SelectFixed": "Fast",
    "PricingTab.titlePricingRules": "Indstillinger - Prisregler - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Slå avancerede prisregler til/fra",
    "PricingTab.from": "Fra",
    "PricingTab.to": "Til",
    "PricingTab.selectFixedAmount": "Fast beløb",
    "PricingTab.buttonRemove": "Fjern",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Du kan ikke oprette mere end én regel med tomme felter",
    "PricingTab.costRange": "Prisinterval",
    "PricingTab.markup": "Prisforhøjelse",
    "PricingTab.add": "Tilføj",

    "SecurityTab.Document.Title": "Indstillinger - Sikkerhed - Spocket",
    "SecurityTab.alertIncorrectPassword": "Forkert adgangskode",
    "SecurityTab.title": "Sikkerhed",
    "SecurityTab.yourNewPassword": "Din nye adgangskode",
    "SecurityTab.repeatPassword": "Gentag adgangskode",
    "SecurityTab.errorPleaseEnterAPassword": "Indtast venligst en adgangskode.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Bekræft venligst adgangskoden.",
    "SecurityTab.errorPasswordsDoNotMatch": "Adgangskoderne matcher ikke",
    "SecurityTab.errorPasswordShort": "Adgangskoden skal være på mindst 8 tegn",

    "ListingCards.TopSupplierTag.Tooltip":
      "Topleverandører er kendt for deres produkter af høj kvalitet, fantastiske service og gode bedømmelser",
    "ListingCards.TopSupplierTag.Text": "Leverandør",

    "ListingCard.OriginCountry.Intro": "Af",
    "ListingCard.OriginCountry.Tooltip": "Sender fra",
    "ListingCard.Shipping.Price.Title": "Forsendelsespris",
    "ListingCard.Shipping.Price.Free": "GRATIS",
    "ListingCard.Price.Intro": "Listepris",
    "ListingCard.Price.Retail": "Købspris",
    "ListingCard.Shipping.Time.Title": "Forsendelsestid",
    "ListingCard.Shipping.Time.Period": "dage",
    "ListingCard.PremiumIcon.Tooltip": "Sælg produkter eksklusivt med store rabatter og hurtig forsendelse",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Tilføj til importliste",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Annoncér på webshop",
    "ListingCard.StatusTag.Imported.Title": "Importeret",
    "ListingCard.StatusTag.Imported.Text": "Denne vare er allerede på din importliste",
    "ListingCard.StatusTag.Pushed.Title": "I webshop",
    "ListingCard.StatusTag.Pusehd.Text": "Dette produkt er skubbet til din webshop",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Produktbeskrivelse",
    "ListingModal.DetailsSection.Button.ProductVariations": "Åbn produktvariationer",
    "ListingModal.DetailsSection.Button.OrderSamples": "Bestil vareprøver",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Nyt",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "Brandet fakturering er ikke tilgængelig for dette produkt",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Internationale ordrer vil blive sendt fra vores lager i Kina",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Varer, der ikke er på lager i USA, kan blive sendt fra vores lager i Kina",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Ordrer kan blive sendt i flere pakker for at reducere forsendelsestiden på din ordre",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Forbind din webshop for at afgive ordre",
    "ListingModal.DetailsSection.PushedListButton": "Annoncér på webshop",
    "ListingModal.DetailsSection.RemoveListButton": "Fjern fra liste",
    "ListingModal.DetailsSection.ImportListButton": "Tilføj til importliste",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Katalogisering deaktiveret",

    "InfoSection.ShippingTime.Tooltip": "Antal dage før produktet leveres efter afsendelse.",
    "InfoSection.ShippingTime.Title": "Forsendelsestid",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Internationalt",
    "InfoSection.ShippingExcluded.Intro": "Sender ikke til",
    "InfoSection.ShippingExcluded.Various": "Forskellige",
    "InfoSection.ProcessingTime.Tooltip":
      "Antal arbejdsdage før leverandøren sender og oplyser trackingnummer",
    "InfoSection.ProcessingTime.Title": "Behandlingstid",
    "InfoSection.ProcessingTime.Period": "arbejdsdage",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Dette produkt afsendes om {{price_formatted}}, plus {{incremental_price_formatted}} for hvert yderligere produkt i samme ordre",
    "InfoSection.ShippingInfoLine.Period": "arbejdsdage",
    "InfoSection.ShippingInfoLine.Free": "GRATIS",
    "InfoSection.ShippingInfoLine.NoShipping": "Sender ikke",

    "ReturnPolicySection.Title": "Returpolitik",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Fra",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Af",
    "TitleSection.PriceMSRP.Listing": "Listepris",
    "TitleSection.PriceMSRP.Retail": "Købspris",

    "ProductVariationsModal.Title": "Produktvariationer",
    "ProductVariationsModal.Table.Image": "Billede",
    "ProductVariationsModal.Table.Inventory": "Lagerbeholdning",
    "ProductVariationsModal.Table.Price": "Pris",
    "ProductVariationsModal.ImageSpecifications.Title": "Størrelsesoversigt",
    "ProductVariationsModal.ImageSpecifications.Button": "Download",

    "TopSupplier.Tooltip":
      "Topleverandører er kendt for deres produkter af høj kvalitet, fantastiske service og gode bedømmelser",
    "TopSupplier.Text": "Topleverandør",

    "BestSelling.Tooltip.Top": "Dette er et bedst ydende produkt på Spocket",
    "BestSelling.Tooltip.Bottom": "Bedst sælgende",

    "GallerySection.PremiumIcon.Tooltip":
      "Sælg produkter eksklusivt med store rabatter og hurtig forsendelse",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "Ekstra {{value}}% rabat",

    "Upgrade.Title.AnnualToggled": "Få Spocket Premium i dag",
    "Upgrade.Title.MonthlyToggled": "Prøv Spocket Premium gratis i 14 dage",
    "Upgrade.Subtitle":
      "Prøv et Spocket Premium-abonnement i dag og begynd at tilføje højt konverterende produkter fra USA og EU til din webshop.",
    "Upgrade.Button": "Fortsæt uden Spocket Premium",

    "Search.NoProductsFound.Title": "Beklager! Vi kunne ikke finde nogen resultater",
    "Search.NoProductsFound.For": "for",
    "Search.NoProductsFound.Description":
      "Kontrollér venligst stavemåden, prøv et relateret søgeord eller et mere generelt søgeord.",
    "Search.Searching.Title": "Vi søger efter produkter...",
    "Search.Searching.Description": "Vent venligst mens vi indlæser de seneste produkter til dig.",

    "Suppliers.StarRating.Tooltip": "Leverandørs succesrate for ordreopfyldelse",
    "Suppliers.RefundPolicy": "Leverandørs refusionspolitik",
    "Suppliers.Joined.Title": "Tilmeldte sig Spocket",
    "Suppliers.Origin.Title": "Sender fra land",
    "Suppliers.Time.Title": "Gennemsnitlig behandlingstid",
    "Suppliers.HasMore.Title": "Vi søger efter produkter...",
    "Suppliers.HasMore.Subtitle": "Vent venligst mens vi indlæser de seneste produkter til dig.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Topleverandører er kendt for deres produkter af høj kvalitet, fantastiske service og gode bedømmelser",
    "Suppliers.TopSupplierTag.Text": "Topleverandør",

    "AltTextModal.Header.Title": "Rediger billedets detaljer",
    "AltTextModal.Body.Description":
      "Skriv en kort beskrivelse af dette billede for at forbedre søgemaskineoptimering (SEO) og tilgængelighed for synshæmmede kunder.",
    "AltTextModal.Input.Label": "Alt tekst",
    "AltTextModal.Input.Placeholder": "Billedes alt tekst",
    "AltTextModal.Button.Cancel": "Annuller",
    "AltTextModal.Button.Save": "Gem",

    "WistiaModal.WhatIsSpocket.Title": "Hvad er Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Lær hvordan Spocket kan hjælpe dig med at opbygge din virksomhed. Forstå hvordan Spocket fungerer, og hvordan du får mest ud af det.",
    "WistiaModal.WhatIsSpocket.Button": "Begynd at finde produkter",
    "WistiaModal.DiscoverProducts.Title": "Opdag produkter",
    "WistiaModal.DiscoverProducts.Text":
      "Find de rigtige produkter ved hjælp af passende filtre, og tilføj dem så til din webshop.",
    "WistiaModal.DiscoverProducts.Button": "Begynd at finde produkter",
    "WistiaModal.ProductCustomization.Title": "Tilpas dine produkter",
    "WistiaModal.ProductCustomization.Text":
      "Brug importlisten til at ændre dine produkter, så de passer til din webshop. Du kan redigere produktbeskrivelsen, fastsætte prisen, tilføje produktet til en samling samt fjerne billeder.",
    "WistiaModal.ProductCustomization.Button": "Gå til importliste",
    "WistiaModal.OrderProcessing.Title": "Automatiseret ordrebehandling",
    "WistiaModal.OrderProcessing.Text":
      "Opfyld dine ordrer og få leverandøren til at levere produktet direkte til din kundes hoveddør med ét klik.",
    "WistiaModal.OrderProcessing.Button": "Gå til ordrer",
    "WistiaModal.Settings.Title": "Indstillinger",
    "WistiaModal.Settings.Text":
      "På indstillingssiden kan du administrere alle dine kontooplysninger, brande din faktura og tilføje dit kreditkort. Du kan også justere prisreglerne for alle dine produkter og vælge et abonnement.",
    "WistiaModal.Settings.Button": "Opsæt din konto",

    "Footer.Copyright": "Copyright {{year}}, Spocket. Alle rettigheder forbeholdes.",
    "Footer.PrivacyPolicy": "Privatlivspolitik",
    "Footer.TermsConditions": "Vilkår & betingelser",
    "Footer.DataProcessingAddendum": "Databehandlingstillæg",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English

    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
"AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders" ,// English
    
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    
    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English

    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
    
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
    
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
