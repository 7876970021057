import deprecated from "./deprecated";
import paths from "./paths";

export default [
  { from: paths.app.index, to: paths.app.search },
  { from: deprecated.apps, to: paths.settings.apps },
  // Settings:
  { from: paths.settings.index, to: paths.settings.plans },
  { from: deprecated.settings.plan, to: paths.settings.plans },
  { from: deprecated.settings.invoice, to: paths.settings.store },
  { from: deprecated.settings.security, to: paths.settings.account },
  { from: deprecated.settings.payment, to: paths.settings.account }
];
