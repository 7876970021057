import axiosLib from "axios";
import { store } from "utils/store/configureStore";
import i18n from "utils/i18n";

import { signOutUser, setAlertMessage } from "utils/actions/index";

const host = process.env.REACT_APP_API_URL;
const baseHost = process.env.REACT_APP_BASE_API_URL;

let axios;

// Creates a local shared instance of Axios if doesn't exist yet.
const checkAxiosInit = () => {
  if (!axios) {
    axios = axiosLib.create({
      timeout: 60000,
      headers: {
        common: {
          Accept: "application/json",
          "content-type": "application/json"
        }
      }
    });
  }

  if (axios && !axios.defaults.headers.common.Authorization) {
    const { common } = axios.defaults.headers;
    if (localStorage.getItem("auth_token")) {
      axios.defaults.headers.common = {
        ...common,
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`
      };
    }
  }
};

export const signOutAxios = () => {
  axios = undefined;
};

const handleError = err => {
  if (err.response) {
    const { status } = err.response;
    if (status === 401 || status === 403) {
      store.dispatch(setAlertMessage(i18n.t("Api.Error.Authorization"), "error"));
      store.dispatch(signOutUser());
    }
  }
  return err;
};

export const sendGet = async (path, params) => {
  checkAxiosInit();
  return axios.get(host + path, { params }).catch(err => {
    return Promise.reject(handleError(err));
  });
};

export const sendGetWithoutSignOut = async (path, params) => {
  checkAxiosInit();
  return axios.get(host + path, { params }).catch(err => {
    return Promise.reject(err);
  });
};

export const sendPost = async (path, params, base = false) => {
  const needsToBeBase = base ? baseHost : host;

  checkAxiosInit();
  return axios.post(needsToBeBase + path, params).catch(err => {
    return Promise.reject(handleError(err));
  });
};

export const sendPostWithoutSignOut = async (path, params) => {
  checkAxiosInit();
  return axios.post(host + path, params).catch(err => {
    return Promise.reject(err);
  });
};

export const sendPut = async (path, params) => {
  checkAxiosInit();
  return axios.put(host + path, params).catch(err => {
    return Promise.reject(handleError(err));
  });
};

export const sendPatch = async (path, params) => {
  checkAxiosInit();
  return axios.patch(host + path, params);
};

export const sendDelete = async (path, params) => {
  checkAxiosInit();
  return axios.delete(host + path, params).catch(err => {
    return Promise.reject(handleError(err));
  });
};

const queryParams = source => {
  let array = [];

  for (let key in source) {
    array.push(encodeURIComponent(key) + "=" + encodeURIComponent(source[key]));
  }

  return array.join("&");
};

export const mountUrlWithParams = (url, params = {}) => {
  if (params && Object.keys(params).length) {
    const queryParamsSerialized = queryParams(params);
    return `${url}?${encodeURI(queryParamsSerialized)}`;
  } else {
    return `${url}`;
  }
};
