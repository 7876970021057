export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Elenco dei miei ordini",
    "OrderList.Input.Search": "Numero ordine...",
    "OrderList.Label.Status": "Filtra per stato su Spocket",

    "order.status.unpaid": "Non pagato",
    "order.status.paid": "Pagato",
    "order.status.processing": "In elaborazione",
    "order.status.shipped": "Spedito",
    "order.status.cancelled": "Annullato",
    "order.status.pending": "In sospeso",
    "order.status.partially_paid": "Pagato in parte",
    "order.status.partially_refunded": "Rimborsato in parte",
    "order.status.refunded": "Rimborsato",
    "order.status.voided": "Nullo",
    "order.status.authorized": "Autorizzato",
    "order.status.deleted": "Eliminato",
    "order.status.pending_payment_confirmation": "In sospeso",

    "OrderList.Label.DownloadHistory": "Scarica la cronologia ordini in CSV",
    "OrderList.Document.Title": "Ordini - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Cerca prodotti",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Come elaborare gli ordini",
    "OrderList.EmptyOrders.Message.Loading.Title": "I tuoi ordini sono in fase di caricamento!",
    "OrderList.EmptyOrders.Message.Loading.Description": "Attendi mentre recuperiamo i tuoi ordini.",
    "OrderList.EmptyOrders.Message.Title": "L’elenco dei tuoi ordini è vuoto!",
    "OrderList.EmptyOrders.Message.Description":
      "Puoi gestire ed elaborare i tuoi ordini da qui. Vai alla pagina di ricerca per iniziare ad aggiungere prodotti al tuo elenco di prodotti da importare.",
    "OrderList.CheckBox.SelectAllOrders": "Seleziona tutti gli ordini",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} ordine selezionato",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} ordini selezionati",
    "OrderList.CheckBox.BulkCheckoutOrders": "Effettua checkout di massa per l’ordine",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Effettua checkout di massa per gli ordini",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Sincronizza ordini",
    "RefreshModal.Button.Cancel": "Annulla",
    "RefreshModal.Button.Synchronize": "Sincronizza",
    "RefreshModal.Modal.Body":
      "Gli ordini sono automaticamente sincronizzati dal tuo negozio. Sincronizza solo se i dati dell’ordine sono incompleti o non aggiornati. Aggiorna la pagina dopo 30 secondi.",

    "Order.Label.OrderNumber": "Numero ordine",
    "Order.Label.Date": "Data",
    "Order.Label.OrderStatus": "Stato ordine {{integrationName}}",
    "Order.Table.Th.Product": "Prodotto",
    "Order.Table.Th.Qty": "Qtà",
    "Order.Table.Th.UnitPrice": "Prezzo unitario",
    "Order.Table.Th.CheckoutDate": "Data checkout",
    "Order.Table.Th.Action": "Azione",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Visualizza dati cliente",
    "CustomerModal.Modal.Title.CustomerInfo": "Dati cliente",
    "CustomerModal.Modal.Body.CustomerInfo": "Dati cliente",
    "CustomerModal.Modal.Body.Address": "Indirizzo",

    "OrderLine.Button.Checkout": "Checkout",
    "OrderLine.Tooltip.PreviewInvoice": "Anteprima fattura",
    "OrderLine.Tooltip.PaymentReceipt": "Ricevuta di pagamento",
    "OrderLine.Tooltip.TrackOrder": "Traccia ordine",
    "OrderLine.Tooltip.OrderProcessing": "Ordine in elaborazione",
    "OrderLine.Tooltip.OrderCancelled": "Ordine annullato",
    "OrderLine.Tooltip.PleaseConfirm": "Clicca qui per confermare il pagamento dell’ordine",
    "OrderLine.Label.PurchaseEtsy": "Acquista su Etsy",
    "OrderLine.Paying": "In pagamento",
    "OrderLine.Failure": "Non riuscito",
    "OrderLine.Sample": "Campione",
    "OrderLine.ErrorOrderRow": "Impossibile trovare il prodotto ordinato nel sistema! Contatta l’assistenza!",

    "ConfirmationModal.Label.OrderDetails": "Dettagli ordine",
    "ConfirmationModal.Label.Total": "Totale",
    "ConfirmationModal.Label.Shipping": "Spedizione",
    "ConfirmationModal.Label.TransactionFees": "Commissioni di transazione",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Le commissioni di transazione includono le tariffe applicate da Stripe, il fornitore di servizi di pagamento di Spocket, oltre alle commissioni di servizio.",
    "ConfirmationModal.Modal.OrderConfirmation": "Conferma ordine",
    "ConfirmationModal.Modal.Alert.Attention": "Attenzione!",
    "ConfirmationModal.Modal.Alert.Description": "Questo ordine contiene una nota lasciata dal cliente.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Stai per effettuare un ordine per",
    "ConfirmationModal.Modal.Table.Th.Name": "Nome",
    "ConfirmationModal.Modal.Table.Th.Price": "Prezzo",
    "ConfirmationModal.Modal.Table.Th.Qty": "Qtà",
    "ConfirmationModal.Modal.Table.Th.Total": "Totale",
    "ConfirmationModal.Modal.Label.Note": "Nota per il fornitore",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Il tuo messaggio",
    "ConfirmationModal.Button.PlaceOrder": "Effettua ordine",

    "PaymentCompleteModal.PaymentCompleted": "Pagamento completato",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Grazie per il pagamento. Il fornitore è stato informato ed elaborerà l’ordine a breve. Aggiorneremo lo stato dell’ordine e indicheremo il numero di tracking sulla tua pagina degli ordini.",
    "PaymentCompleteModal.PaymentIncomplete": "Il tuo ordine è incompleto!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "La tua banca ha richiesto la conferma del pagamento per poter procedere",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "Utilizza questo link per confermare il pagamento.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Una volta confermato il pagamento, il fornitore ne sarà informato e il tuo ordine sarà elaborato come di norma. Gli aggiornamenti di stato e di tracking saranno indicati sulla tua pagina degli ordini. Ricarica la pagina dopo la conferma.",

    "SetYourGoalsModal.Title": "Numero di prodotti aggiunti al negozio",
    "SetYourGoalsModal.Subtitle":
      "I negozi spingono in media 25 prodotti prima di iniziare a concludere vendite",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} prodotto",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} prodotti",
    "SetYourGoalsModal.Button": "Imposta il mio obiettivo e comincia con il dropshipping",

    "YourGoalsBanner.Container.Description": "I tuoi obiettivi",
    "YourGoalsBanner.Header.Title": "Avviare un’attività",
    "YourGoalsBanner.Header.Subtitle": "Prepara il tuo negozio",
    "YourGoalsBanner.Progress.Completed": "Completato",
    "YourGoalsBanner.ProductPushedCount": "<0>Obiettivo: {{goalsNumberOfProduct}}</0> prodotti spinti",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Iscriviti a Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Prima ricerca",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}} prodotto spinto",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Dropshipping automatizzato per AliExpress",
    "Aliscraper.Features.Feature1": "Importazione rapida in un clic",
    "Aliscraper.Features.Feature2": "Elabora centinaia di ordini all’istante con Spocket",
    "Aliscraper.Features.Feature3": "Assistenza clienti 24/7",
    "Aliscraper.Button": "Scarica l’estensione",
    "Aliscraper.Chrome": "Disponibile sul Chrome Web Store",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Collega il tuo negozio",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Scegli la piattaforma per iniziare",
    "ConnectStoreModal.ShopifyForm.InvalidName": "Nome negozio non valido",
    "ConnectStoreModal.ShopifyForm.Placeholder": "ad es. IlMioNegozio",
    "ConnectStoreModal.ShopifyForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.ShopifyForm.Button": "Collega negozio",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "La tua chiave Auth:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Come collegare il tuo negozio a Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Installa il plugin di Spocket da",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "qui",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Attiva il plugin nella tua pagina plugin",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Vai alla Dashboard WordPress > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Incolla la tua chiave Auth (riportata sopra) e clicca su “Salva”",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Fatto",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.WixForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.WixForm.Button": "Collega negozio",
    "ConnectStoreModal.FelexForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.FelexForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.FelexForm.Button": "Collega negozio",
    "ConnectStoreModal.BigcommerceForm.UrlText": "URL negozio Bigcommerce",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "Nome negozio non valido",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "ad es. IlMioNegozio",
    "ConnectStoreModal.BigcommerceForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.BigcommerceForm.Button": "Collega negozio",
    "ConnectStoreModal.EcwidForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.EcwidForm.Button": "Collega negozio",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.WhiteLabelForm.Button": "Collega negozio",
    "ConnectStoreModal.SquarespaceForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.SquarespaceForm.Button": "Collega negozio",
    "ConnectStoreModal.SquareForm.FooterText": "Non hai un negozio?",
    "ConnectStoreModal.SquareForm.FooterLink": "Prova gratis",
    "ConnectStoreModal.SquareForm.Button": "Collega negozio",
    "ConnectStoreModal.FelexButton.Explanation":
      "Una piattaforma di creazione di negozi online che consente agli imprenditori di avviare negozi di dropshipping in meno di 10 minuti.",
    "ConnectStoreModal.DontHaveStore":  "Non hai un negozio?",
    "ConnectStoreModal.TryForFree": "Prova gratis",

    "PaymentTab.Document.Title": "Impostazioni - Automazione - Spocket",
    "PaymentTab.Title.Billing": "Fatturazione",
    "PaymentTab.Tooltip.Billing":
      "La carta di credito sarà utilizzata per l’elaborazione degli ordini e il pagamento degli abbonamenti.",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Metti in pausa/Modifica il tuo piano",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Modifica il tuo piano",
    "PaymentTab.AccordionItem.Button.Downgrade": "Effettua il downgrade",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Metti in pausa il tuo piano",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused":
      "Il tuo negozio è già stato messo in pausa in passato",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Metti in pausa il tuo piano",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Puoi mettere temporaneamente in pausa il tuo account di Spocket e ritornarci in qualsiasi momento senza perdere i tuoi dati.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Ricordamelo più tardi",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Ricordamelo più tardi",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 giorni prima",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Mantieni i miei benefici e ricordami <1>{{daysBefore}}</1> del rinnovo della mia iscrizione",
    "RemindMeLaterModal.Header.ReminderSet": "Promemoria impostato",
    "RemindMeLaterModal.Body.DaysBefore": "invio email 3 giorni prima",
    "RemindMeLaterModal.Body.ReminderSet":
      "Il tuo promemoria è stato impostato ed è previsto un <1>{{daysBefore}}</1> del rinnovo dei tuoi benefici; fino ad allora, continua il tuo viaggio imprenditoriale.",
    "RemindMeLaterModal.Button.Continue": "Continua",
    "PauseStoreModal.Title.PauseYourStore": "Metti in pausa il tuo negozio",
    "PauseStoreModal.Description.PauseYourStore":
      "Approfitta di questa offerta una tantum e riduci il costo del tuo piano del 60% per i prossimi 30 giorni",
    "PauseStoreModal.Button.PauseStore": "Metti in pausa negozio",
    "PauseStoreModal.Description.StorePaused":
      "Il tuo negozio è stato messo in pausa e il tuo abbonamento per il prossimo mese sarà scontato del 60%. Ti ricordiamo che in seguito riprenderanno i pagamenti regolari.",
    "ConfirmPasswordModal.Title": "Conferma la tua password",
    "ConfirmPasswordModal.SubTitle": "Per motivi di sicurezza, conferma la tua password",
    "ConfirmPasswordModal.Input.Placeholder": "La tua password",
    "ConfirmPasswordModal.Button.Cancel": "Annulla",
    "ConfirmPasswordModal.Button.Confirm": "Conferma",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "it",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Cresci più in fretta con Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Scegli il tuo ciclo di fatturazione",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "I primi",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "giorni ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final": "sono gratis, annulla durante o dopo la prova",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "La tua prima fattura sarà prevista per il",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "La tua prima fattura sarà esigibile alla conferma",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "La fatturazione avrà cadenza mensile",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Mensile ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/mese",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Annuale ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/mese",
    "UpgradeConfirmationModal.YearlyTab.Discount": "Off",
    "UpgradeConfirmationModal.TaxRates.Text": "IVA",
    "UpgradeConfirmationModal.TaxRates.Period": "mese",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Da pagare ora",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/mese X 12 mesi",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "I tuoi risparmi: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/anno",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "pari a",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% in meno",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "I piani annuali non prevedono un periodo di prova",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "L’intero importo ti sarà addebitato immediatamente",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Paga con carta di credito/debito",
    "UpgradeConfirmationModal.PayPalTab.Info": "Paga con PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Dati di pagamento ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Richiedi la prova gratuita",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Effettua l’upgrade a ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Scelto da oltre 50.000 imprenditori",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "I nostri clienti ci hanno regolarmente dato valutazioni di 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "Crittografia SSL 128-bit",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Tutti i tuoi dati di pagamento sono sicuri al 100% con noi.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "50.000+",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Scelto da oltre 50.000 imprenditori",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "I nostri clienti ci hanno regolarmente dato valutazioni di 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "Crittografia SSL 128-bit",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Tutti i tuoi dati di pagamento sono sicuri al 100% con noi.",
    "UpgradeConfirmationModal.Agreement.Intro": "Continuando, acconsenti ai nostri ",
    "UpgradeConfirmationModal.Agreement.Terms": "Termini d’uso",
    "UpgradeConfirmationModal.Agreement.Privacy": "Politica sulla privacy",
    "UpgradeConfirmationModal.Agreement.Refund": "Politica sui rimborsi",
    "UpgradeConfirmationModal.Agreement.Middle": ", e",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Politica di annullamento",

    "OrderDetails.Title": "Dettagli ordine",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Carta di credito che termina in",
    "CreditCard.Update.Button": "Aggiorna carta",
    "CreditCard.AddCreditCard.Text":
      "Non hai ancora aggiunto una carta di credito. Aggiungi una carta di credito cliccando sul pulsante qui sotto, per consentirci di elaborare automaticamente i tuoi ordini",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Aggiungi carta di credito",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Prodotti vincenti",
    "Sidebar.WinningProducts.Variant": "Novità",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "App",
    "Sidebar.HelpCenter": "Centro assistenza",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Impostazioni",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Siamo qui per aiutarti!",
    "TutorialModal.WhatIsSpocket": "Cos’è Spocket?",
    "TutorialModal.DiscoverProducts": "Scopri i prodotti",
    "TutorialModal.ProductCustomization": "Personalizzazione prodotti",
    "TutorialModal.OrderProcessing": "Ordine in elaborazione",
    "TutorialModal.Settings": "Impostazioni",
    "TutorialModal.GetHelp": "Ottieni aiuto",
    "TutorialModal.JoinTheCommunity": "Partecipa alla community",

    "NotificationCenter.Header": "Centro notifiche",
    "NotificationCenter.Footer": "Vedi tutte le notifiche",
    "NotificationCenter.NoNotification": "Non c’è niente da vedere!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English
    
    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English
    
    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "I miei prodotti - Spocket",
    "Products.Title.Header": "I miei prodotti",
    "Products.Loading.Top": "Il tuo elenco prodotti si sta caricando!",
    "Products.Loading.Bottom": "Attendi mentre recuperiamo i tuoi prodotti.",
    "Products.Empty.Search": "Nessun prodotto trovato!",
    "Products.Empty.Top": "Il tuo elenco prodotti è vuoto!",
    "Products.Empty.Bottom":
      "Vai alla pagina di ricerca per iniziare ad aggiungere prodotti al tuo elenco di prodotti da importare.",
    "Products.Empty.Button.Search": "Cerca prodotti",
    "Products.Empty.Button.How": "Come trovare prodotti",
    "Products.Alert.Removed": "Prodotto rimosso",

    "Products.Search.Label": "Cerca tra i prodotti",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Cerca",

    "ProductListItem.Inactive.Tooltip": "Questo prodotto non è più disponibile presso il fornitore",
    "ProductListItem.Inactive.Title": "Inattivo",
    "ProductListItem.OutOfStock.Tooltip": "Tutte le varianti di questo prodotto sono esaurite",
    "ProductListItem.OutOfStock.Title": "Esaurito",
    "ProductListItem.InventoryCount.Singular": "pezzo",
    "ProductListItem.InventoryCount.Plural": "pezzi",
    "ProductListItem.InventoryCount.End.Singular": "rimanente",
    "ProductListItem.InventoryCount.End.Plural": "rimanenti",
    "ProductListItem.Button.View": "Visualizza nel negozio",
    "ProductListItem.Button.Remove": "Rimuovi dal negozio",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "Elenco per l’importazione - Spocket",
    "ImportList.Title.Header": "Il mio elenco per l’importazione",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "Il tuo elenco per l’importazione è in fase di caricamento!",
    "ImportList.Loading.Bottom": "Attendi mentre recuperiamo i tuoi prodotti.",
    "ImportList.Empty.Button.Search": "Cerca prodotti",
    "ImportList.Empty.Top": "Il tuo elenco per l’importazione è vuoto!",
    "ImportList.Empty.Bottom":
      "Vai alla pagina di ricerca per iniziare ad aggiungere prodotti al tuo elenco di prodotti da importare.",
    "ImportList.Empty.Button.How": "Come personalizzare i prodotti",
    "ImportList.Alert.Pushed": "Questo prodotto è stato spinto al negozio:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "Cerca tra i prodotti importati",
    "ImportList.Search.Placeholder": "Cerca tra i prodotti",
    "ImportList.Search.Button": "Cerca",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Spinta prodotti su pagina attuale in corso",
    "PushAllModal.Button.Inactive": "Spingi pagina attuale",
    "PushAllModal.Modal.Text":
      "Vuoi davvero spingere tutti i prodotti dalla pagina attuale? Tutti i prodotti validi sulla pagina attuale saranno spinti al negozio.",
    "PushAllModal.Modal.Push": "Spingi tutti",
    "PushAllModal.Modal.Cancel": "Annulla",

    "RemoveAllModal.Button.Active": "Rimozione prodotti su pagina attuale in corso",
    "RemoveAllModal.Button.Inactive": "Rimuovi pagina attuale",
    "RemoveAllModal.Modal.Text":
      "Vuoi davvero rimuovere tutti i prodotti dalla pagina attuale? Tutti i prodotti sulla pagina attuale saranno rimossi dall’elenco per l’importazione.",
    "RemoveAllModal.Modal.Remove": "Rimuovi tutti",
    "RemoveAllModal.Modal.Cancel": "Annulla",

    "ImportListItem.Tab.Product": "Prodotto",
    "ImportListItem.Tab.Description": "Descrizione",
    "ImportListItem.Tab.Variants": "Varianti",
    "ImportListItem.Tab.Images": "Immagini",
    "ImportListItem.Remove.Active": "Rimozione prodotto",
    "ImportListItem.Remove.Inactive": "Rimuovi prodotto",
    "ImportListItem.Push.Active": "Spinta al negozio in corso",
    "ImportListItem.Push.Inactive": "Spingi al negozio",
    "ImportListItem.Save.Button": "Salva",
    "ImportListItem.Alert.Removed": "Questo prodotto è stato rimosso dal tuo elenco per l’importazione: ",
    "ImportListItem.Alert.RemoveFailed": "Si è verificato un errore nel tentativo di rimuovere: ",
    "ImportListItem.Alert.CompareAtPrice":
      "Il prezzo di confronto dovrebbe essere più alto del prezzo di vendita per: ",
    "ImportListItem.Alert.OneActiveVariation": "Questo prodotto deve avere almeno una variante attiva: ",
    "ImportListItem.Alert.Saved": "Questo prodotto è stato salvato: ",
    "ImportListItem.Alert.ErrorPushing": "Si è verificato un errore durante la spinta al negozio",
    "ImportListItem.Alert.ErrorSaving": "Si è verificato un errore durante il salvataggio del negozio",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Collega il tuo negozio per spingere i prodotti: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Devi avere almeno una variante attiva",
    "ImportListItem.Tooltip.Unavailable": "Questo prodotto non è al momento disponibile per l’acquisto",
    "ImportListItem.Tooltip.Unsaved": "Ci sono modifiche non salvate",
    "ImportListItem.Tooltip.Profit": "Attenzione: Il tuo utile lordo per questo prodotto è negativo",
    "ImportListItem.Tooltip.Unpurchasable":
      "Puoi salvare questo prodotto, ma non è al momento disponibile per l’acquisto",
    "ImportListItem.Variants.ShowShippingPrices": "Mostra costi di spedizione",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Nome prodotto",
    "ItemInfo.ProductTags.Label": "Tag prodotto",
    "ItemInfo.ProductTags.Remove": "Rimuovi tutte le tag",
    "ItemInfo.ProductTags.Placeholder": "Aggiungi nuova tag",
    "ItemInfo.ProductType.Label": "Tipo prodotto",
    "ItemInfo.Collection.Label": "Collezione",
    "ItemInfo.Collection.Placeholder": "Seleziona",
    "ItemInfo.Collection.Option": "Seleziona",
    "ItemInfo.SquareLocation.Option": "Seleziona",

    "ItemVariantList.Modal.Title": "Dettagli di spedizione",
    "ItemVariantList.Modal.Intro": "Spedito da: ",
    "ItemVariantList.Modal.DoesNotShipInternational":
      "Questo articolo non prevede la spedizione internazionale.",
    "ItemVariantList.Modal.DoesNotShipTo": "Non può essere spedito a: ",
    "ItemVariantList.Table.Head.Destination": "Destinazione",
    "ItemVariantList.Table.Head.Cost": "Costo",
    "ItemVariantList.Table.Head.Time": "Tempi di consegna (Giorni)",
    "ItemVariantList.Table.Body.Domestic": "Nazionale",
    "ItemVariantList.Table.Body.International": "Internazionale",
    "ItemVariantList.VariantsTable.ShippingPrice": "Costo di spedizione ",
    "ItemVariantList.VariantsTable.Image": "Immagine",
    "ItemVariantList.VariantsTable.SKU": "Codice",
    "ItemVariantList.VariantsTable.Inventory": "Inventario",
    "ItemVariantList.VariantsTable.Price": "Prezzo",
    "ItemVariantList.VariantsTable.SalesPrice": "Prezzo di vendita ",
    "ItemVariantList.VariantsTable.Profit": "Utile",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Prezzo di confronto",
    "ItemVariantList.VariantsTable.Unavailable":
      "Questo prodotto non è al momento disponibile per l’acquisto",
    "ItemVariantList.VariantsTable.Head":
      "Tutte le varianti disponibili per questo prodotto sono visualizzate qui.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ad es: 40%",
    "ItemVariantList.Alert.MaxVariants": "Shopify non supporta oltre 100 varianti.",

    "ItemVariantList.ContextPopover.Title": "Imposta utile",
    "ItemVariantList.ContextPopover.Apply": "Applica",

    "ProductVariation.Description":
      "Puoi effettuare ordini per i campioni di prodotti direttamente su Spocket. Segui i passaggi qui indicati per un checkout semplice e veloce.",
    "ProductVariation.Label.SelectVariant": "Seleziona variante",
    "ProductVariation.Label.SelectTheProductVariation": "Seleziona la variante del prodotto",
    "ProductVariation.Qty": "Qtà",
    "ProductVariation.Label.NotesForTheSupplier": "Note per il fornitore",

    "InputAddress.Label.FirstName": "Nome",
    "InputAddress.PlaceHolder.FirstName": "Inserisci nome",
    "InputAddress.Label.LastName": "Cognome",
    "InputAddress.PlaceHolder.LastName": "Inserisci cognome",
    "InputAddress.Label.StreetAddress": "Indirizzo",
    "InputAddress.Label.Country": "Paese",
    "InputAddress.PlaceHolder.Country": "Cerca paese",
    "InputAddress.Label.State": "Stato",
    "InputAddress.Placeholder.State": "Cerca stato",
    "InputAddress.Label.City": "Città",
    "InputAddress.PlaceHolder.City": "Inserisci città",
    "InputAddress.Label.Zip": "CAP",
    "InputAddress.PlaceHolder.Zip": "Inserisci CAP",
    "InputAddress.Label.Phone": "Numero di telefono",
    "InputAddress.Placeholder.Phone": "Inserisci numero di telefono",

    "OrderReview.OrderDetails": "Dettagli ordine",
    "OrderReview.Unit": "{{count}} unità",
    "OrderReview.Unit_plural": "{{count}} unità",
    "OrderReview.ShippingAddress": "Indirizzo di spedizione",
    "OrderReview.PaymentMethod": "Metodo di pagamento",
    "OrderReview.Amounts": "Importi",
    "OrderReview.Subtotal": "Subtotale",
    "OrderReview.ShippingCost": "Costi di spedizione",
    "OrderReview.TransactionFees": "Commissioni di transazione",
    "OrderReview.TransactionFees.ToolTips":
      "Le commissioni di transazione includono le tariffe applicate da Stripe, il fornitore di servizi di pagamento di Spocket, oltre alle commissioni di servizio.",
    "OrderReview.OrderTotal": "Totale ordine",
    "OrderReview.ItemWithCount": "{{count}} articolo",
    "OrderReview.ItemWithCount_plural": "{{count}} articoli",
    "OrderReview.Placeholder.CreditCard": "Carta di credito che termina in {{last4}}",

    "PaymentComplete.PaymentCompleted": "Pagamento completato",
    "PaymentComplete.PaymentCompleted.Note":
      "Grazie per il pagamento. Il fornitore è stato informato ed elaborerà l’ordine a breve. Aggiorneremo lo stato dell’ordine e indicheremo il numero di tracking sulla tua pagina degli ordini.",
    "PaymentComplete.PaymentIncomplete": "Il tuo ordine è incompleto!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "La tua banca ha richiesto la conferma del pagamento per poter procedere",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "Utilizza questo link per confermare il pagamento.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Una volta confermato il pagamento, il fornitore ne sarà informato e il tuo ordine sarà elaborato come di norma. Gli aggiornamenti di stato e di tracking saranno indicati sulla tua pagina degli ordini. Ricarica la pagina dopo la conferma.",

    "CreditCard.AddCreditCard.Description":
      "Non hai ancora aggiunto una carta di credito. Aggiungi una carta di credito cliccando sul pulsante qui sotto, per consentirci di elaborare automaticamente i tuoi ordini",
    "CreditCard.Button.AddCreditCard": "Aggiungi carta di credito",
    "CreditCard.Button.UpdateCard": "Aggiorna carta",
    "CreditCard.UpdateCard.Label.CreditCard": "Carta di credito",
    "CreditCard.PlaceHolder.CreditCard": "Carta di credito che termina in {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Carta di credito",
    "UpdateCreditCardModal.Button.UpdateCard": "Aggiorna carta",
    "UpdateCreditCardModal.Button.AddCreditCard": "Aggiungi carta di credito",
    "UpdateCreditCardModal.Button.Cancel": "Annulla",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Aggiorna i dati della carta",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Il tuo account sarà utilizzato per elaborare gli ordini dei prodotti Spocket.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Termini e condizioni",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "per l’elaborazione dei pagamenti.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Seleziona variazione",
    "SampleOrderModal.Title.ShippingAddress": "Indirizzo di spedizione",
    "SampleOrderModal.Title.PaymentMethod": "Metodo di pagamento",
    "SampleOrderModal.Title.Review": "Rivedi",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "Conferma di pagamento richiesta!",
    "SampleOrderModal.Title.OrderPlaced": "Ordine effettuato!",
    "SampleOrderModal.Steps": "Step {{current}} di {{total}}",
    "SampleOrderModal.FinalStep": "Ultimo step",
    "SampleOrderModal.ConfirmPayment": "Conferma il pagamento!",
    "SampleOrderModal.Finished": "Finito",
    "SampleOrderModal.Footer.Continue": "Continua",
    "SampleOrderModal.Footer.PlaceOrder": "Effettua ordine",
    "SampleOrderModal.Footer.Close": "Chiudi",
    "SampleOrderModal.Footer.Cancel": "Annulla",
    "SampleOrderModal.Footer.GoBack": "Torna indietro",

    "AdvancedFiltersModal.Title.Filters": "Filtri",
    "AdvancedFiltersModal.Title.Shipping": "Spedizione",
    "AdvancedFiltersModal.Label.ShipsFrom": "Spedito da",
    "AdvancedFiltersModal.Label.ShipsTo": "Spedito a",
    "AdvancedFiltersModal.Label.ShippingTime": "Tempi di spedizione",
    "AdvancedFiltersModal.Label.Popular": "Popolare",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Stati Uniti",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Disponibile solo per la spedizione negli USA",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Qualsiasi giorno",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 giorni",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 giorni",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 giorni",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15+ giorni",
    "AdvancedFiltersModal.Title.ItemsCost": "Costo articoli",
    "AdvancedFiltersModal.Label.ItemCost": "Costo articolo",
    "AdvancedFiltersModal.Label.ShippingCost": "Costi di spedizione",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Qualsiasi costo",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratis",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5$ o meno",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15$ o meno",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25$ o meno",
    "AdvancedFiltersModal.Title.Supplier": "Fornitore",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Fornitori",
    "AdvancedFiltersModal.Label.TopSupplier": "Fornitore top",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Fornitori riconosciuti per i loro prodotti di alta qualità, il servizio eccellente e le recensioni con valutazioni alte",
    "AdvancedFiltersModal.Title.Advanced": "Avanzato",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Prodotti premium",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Prodotti in sconto e disponibili per spedizione rapida",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Best seller",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Prodotti più venduti su Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Annulla",
    "AdvancedFiltersModal.Button.ViewResults": "Visualizza risultati",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Cerca paese...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Paesi",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Cerca fornitore...",
    "AdvancedFiltersModal.Label.All Suppliers": "Tutti i fornitori",
    "AdvancedFiltersModal.Label.New Suppliers": "Nuovi fornitori",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Qualsiasi giorno",
    "AdvancedFilters.ShippingTime.One": "1-3 giorni",
    "AdvancedFilters.ShippingTime.Four": "4-7 giorni",
    "AdvancedFilters.ShippingTime.Eight": "8-14 giorni",
    "AdvancedFilters.ShippingTime.Fifteen": "15+ giorni",

    "AdvancedFilters.ShippingCost.Free": "Spedizione gratuita",
    "AdvancedFilters.ShippingCost.Five": "Spedizione inferiore a 5$",
    "AdvancedFilters.ShippingCost.Fifteen": "Spedizione inferiore a 15$",
    "AdvancedFilters.ShippingCost.TwentyFive": "Spedizione inferiore a 25$",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Cancella tutto",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Spedizione",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Cerca",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Spedito da",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Spedito a",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Fornitore",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Ordina per",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Costo articolo",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Fornitori top",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Fatture personalizzate",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Best seller",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Premium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "da {{from}} a {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Inserisci parole chiave...",
    "Search.Header.Button.Search": "Cerca",
    "Search.Header.Button.Filters": "Filtri",

    "Navigation.UpgradeButton.TryPro": "Prova GRATIS Spocket Pro",
    "Navigation.UpgradeButton.TryEmpire": "Prova GRATIS Spocket Empire",
    "Navigation.UpgradeButton.TryUnicorn": "Prova GRATIS Spocket Unicorn",

    "CategoriesCards.Label.MoreCategories": "Altre categorie",

    "StaticCategories.WomensClothing": "Abbigliamento da donna",
    "StaticCategories.WomensClothing.Activewear": "Abbigliamento sportivo",
    "StaticCategories.WomensClothing.Bodysuits": "Body",
    "StaticCategories.WomensClothing.Dresses": "Abiti",
    "StaticCategories.WomensClothing.JacketsCoats": "Giacche e cappotti",
    "StaticCategories.WomensClothing.Jeans": "Jeans",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Tutine e salopette",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Intimo e lingerie",
    "StaticCategories.WomensClothing.MatchingSets": "Coordinati",
    "StaticCategories.WomensClothing.Pants": "Pantaloni",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Gonne",
    "StaticCategories.WomensClothing.Sleepwear": "Pigiami e abbigliamento notte",
    "StaticCategories.WomensClothing.Socks": "Calze",
    "StaticCategories.WomensClothing.SweatersHoodies": "Maglioni e felpe",
    "StaticCategories.WomensClothing.Swimwear": "Costumi e abbigliamento mare",
    "StaticCategories.WomensClothing.TankTops": "Canottiere",
    "StaticCategories.WomensClothing.TopsBlouses": "Top e camicette",
    "StaticCategories.WomensClothing.Tshirts": "T-shirt",

    "StaticCategories.JewelryWatches": "Gioielli e orologi",
    "StaticCategories.JewelryWatches.Bracelets": "Braccialetti",
    "StaticCategories.JewelryWatches.Earrings": "Orecchini",
    "StaticCategories.JewelryWatches.Necklaces": "Collane",
    "StaticCategories.JewelryWatches.OtherAccessories": "Altri accessori",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Ciondoli, pietre e pendenti",
    "StaticCategories.JewelryWatches.Rings": "Anelli",
    "StaticCategories.JewelryWatches.Sets": "Set",
    "StaticCategories.JewelryWatches.Watches": "Orologi",

    "StaticCategories.TechAccessories": "Accessori tecnologici",
    "StaticCategories.TechAccessories.AudioVideo": "Audio e video",
    "StaticCategories.TechAccessories.CasesCovers": "Custodie e cover",
    "StaticCategories.TechAccessories.Lighting": "Illuminazione",
    "StaticCategories.TechAccessories.MobileLaptopAccessories":
      "Accessori per cellulare e computer portatile",
    "StaticCategories.TechAccessories.Mousepads": "Mousepad",
    "StaticCategories.TechAccessories.Novelty": "Gadget",

    "StaticCategories.KidsBabies": "Bambini e neonati",
    "StaticCategories.KidsBabies.BabyClothing": "Abbigliamento per neonati",
    "StaticCategories.KidsBabies.Bathing": "Da bagno",
    "StaticCategories.KidsBabies.BlanketsPillows": "Coperte e cuscini",
    "StaticCategories.KidsBabies.CapsHeadbands": "Cappellini e fasce",
    "StaticCategories.KidsBabies.Footwear": "Calzature",
    "StaticCategories.KidsBabies.Furniture": "Arredamento",
    "StaticCategories.KidsBabies.KidsClothing": "Abbigliamento per bambini",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Accessori per genitori e bimbi",

    "StaticCategories.Toys": "Giocattoli",
    "StaticCategories.Footwear": "Calzature",
    "StaticCategories.Footwear.Boots": "Stivaletti",
    "StaticCategories.Footwear.Flats": "Ballerine",
    "StaticCategories.Footwear.Heels": "Col tacco",
    "StaticCategories.Footwear.Mens": "Da uomo",
    "StaticCategories.Footwear.Sandals": "Sandali",
    "StaticCategories.Footwear.Slippers": "Pantofole",
    "StaticCategories.Footwear.SneakersRunners": "Sneaker e scarpe da corsa",

    "StaticCategories.BathBeauty": "Prodotti da bagno e di bellezza",
    "StaticCategories.BathBeauty.Bodycare": "Cura del corpo",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Diffusori, oli e candele",
    "StaticCategories.BathBeauty.Haircare": "Cura dei capelli",
    "StaticCategories.BathBeauty.Healthcare": "Salute",
    "StaticCategories.BathBeauty.Makeup": "Makeup",
    "StaticCategories.BathBeauty.Nails": "Unghie",
    "StaticCategories.BathBeauty.Skincare": "Cura della pelle",
    "StaticCategories.BathBeauty.TowelsRobes": "Accappatoi e asciugamani",

    "StaticCategories.Pets": "Animali",
    "StaticCategories.Pets.BedsBlankets": "Cucce e coperte",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Guinzagli, collari e indumenti per animali",
    "StaticCategories.Pets.Petcare": "Cura degli animali",
    "StaticCategories.Pets.Toys": "Giocattoli",

    "StaticCategories.HomeGarden": "Casa e giardino",
    "StaticCategories.HomeGarden.Bathroom": "Bagno",
    "StaticCategories.HomeGarden.Clocks": "Orologi",
    "StaticCategories.HomeGarden.Drinkware": "Bicchieri e accessori",
    "StaticCategories.HomeGarden.FoodBeverage": "Cibo e bevande",
    "StaticCategories.HomeGarden.Furniture": "Arredamento",
    "StaticCategories.HomeGarden.Garden": "Giardino",
    "StaticCategories.HomeGarden.HomeDecor": "Arredo casa",
    "StaticCategories.HomeGarden.HomeImprovement": "Bricolage",
    "StaticCategories.HomeGarden.Kitchen": "Cucina",
    "StaticCategories.HomeGarden.Lighting": "Illuminazione",
    "StaticCategories.HomeGarden.Organization": "Organizzazione",
    "StaticCategories.HomeGarden.StationeryCrafts": "Cartoleria e attività manuali",
    "StaticCategories.HomeGarden.TextilesPillows": "Tessili e cuscini",
    "StaticCategories.HomeGarden.Tools": "Attrezzi",

    "StaticCategories.Accessories": "Accessori",
    "StaticCategories.Accessories.Belts": "Cinture",
    "StaticCategories.Accessories.Cufflinks": "Gemelli",
    "StaticCategories.Accessories.Hats": "Cappelli",
    "StaticCategories.Accessories.Keychains": "Portachiavi",
    "StaticCategories.Accessories.ScarvesBandanas": "Sciarpe e bandane",
    "StaticCategories.Accessories.Sunglasses": "Occhiali da sole",

    "StaticCategories.MensClothing": "Abbigliamento da uomo",
    "StaticCategories.MensClothing.Activewear": "Abbigliamento sportivo",
    "StaticCategories.MensClothing.DressShirts": "Camicie",
    "StaticCategories.MensClothing.JacketsCoats": "Giacche e cappotti",
    "StaticCategories.MensClothing.Pants": "Pantaloni",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Calze",
    "StaticCategories.MensClothing.SweatersHoodies": "Maglioni e felpe",
    "StaticCategories.MensClothing.Swimwear": "Costumi e abbigliamento mare",
    "StaticCategories.MensClothing.TankTops": "Canottiere",
    "StaticCategories.MensClothing.Tshirts": "T-shirt",
    "StaticCategories.MensClothing.Underwear": "Intimo uomo",

    "StaticCategories.SportsOutdoors": "Sport e attività all’aria aperta",
    "StaticCategories.SportsOutdoors.Activewear": "Abbigliamento sportivo",
    "StaticCategories.SportsOutdoors.Biking": "Bicicletta",
    "StaticCategories.SportsOutdoors.Camping": "Campeggio",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Equipaggiamento e accessori",
    "StaticCategories.SportsOutdoors.Fishing": "Pesca",

    "StaticCategories.Gifts": "Idee regalo",
    "StaticCategories.Gifts.Adults": "Adulti",
    "StaticCategories.Gifts.Funny": "Divertenti",
    "StaticCategories.Gifts.Mugs": "Tazze",
    "StaticCategories.Gifts.Novelty": "Gadget",
    "StaticCategories.Gifts.Personalized": "Personalizzati",
    "StaticCategories.Gifts.Religious": "Religiosi",

    "StaticCategories.Automotive": "Automobilismo",
    "StaticCategories.BagsWallets": "Borse e portafogli",
    "StaticCategories.Seasonal": "Ricorrenze",
    "StaticCategories.Seasonal.Christmas": "Natale",
    "StaticCategories.Seasonal.FathersDay": "Festa del papà",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Festa della mamma",

    "StaticCategories.FestivalsParties": "Festival e feste",
    "StaticCategories.FestivalsParties.PartySupplies": "Articoli per feste",
    "StaticCategories.FestivalsParties.Wedding": "Matrimoni",

    "QuickFilters.Label.Premium": "Premium",
    "QuickFilters.Label.FastUSAShipping": "Spedizione USA rapida",
    "QuickFilters.Label.Under5": "Spedizione USA inferiore a 5$",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Ordina per",
    "SortBy.DropDown.Item.Relevance": "Pertinenza",
    "SortBy.DropDown.Item.PriceLowToHigh": "Prezzo: dal più basso al più alto",
    "SortBy.DropDown.Item.PriceHighToLow": "Prezzo: dal più alto al più basso",
    "SortBy.DropDown.Item.Newest": "Più recente",
    "SortBy.DropDown.Item.ShippingTime": "Tempi di spedizione",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Home",
    "Breadcrumb.Item.Search": "cerca",

    "UpgradeModal.Modal.Title": "Seleziona un piano tariffario",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Mensile",
    "Plans.ToggleSection.Yearly": "Annuale",
    "Plans.ToggleSection.DiscountInitial": "Arriva fino a",
    "Plans.ToggleSection.DiscountSingular": "Mese",
    "Plans.ToggleSection.DiscountPlural": "Mesi",
    "Plans.ToggleSection.DiscountFinal": "Off",
    "Plans.ProrationCost.TextInitial": "Attualmente stai pagando",
    "Plans.ProrationCost.TextFinal": "/anno. Effettua l’upgrade a Empire per la piccola cifra aggiuntiva di",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Inizia a concludere vendite",
    "Card.DescriptionVariantType.Professional": "Promuovi le vendite",
    "Card.DescriptionVariantType.Empire": "Massimizza le vendite",
    "Card.DescriptionVariantType.Unicorn": "Gestisci le vendite su scala",
    "Card.DescriptionVariantType.Period": "mese",
    "Card.DescriptionVariantType.PricePeriod": "/mese",

    "Card.DescriptionVariantType.AnnualDescription": "fatturato su base annuale per {{value}}$",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Il tuo piano attuale",
    "Card.DescriptionVariantType.Button.Start": "Inizia ora",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Fatturazione brandizzata",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Prodotti vincenti",
    "Card.DescriptionVariantType.SupplierSourcing": "Ricerca fornitori",
    "Card.DescriptionVariantType.ProductRequests": "Richiesta prodotti",
    "Card.DescriptionVariantType.BulkCheckout": "Checkout di massa",
    "Card.DescriptionVariantType.Special": "speciale",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Il tuo abbonamento a Spocket è terminato.",
    "ReactivationBanner.InfoSubtitle": "Riattiva il tuo",
    "ReactivationBanner.CheckList.HighQuality": "Alta qualità",
    "ReactivationBanner.CheckList.Curated": "Curato",
    "ReactivationBanner.CheckList.FastShipping": "Spedizione rapida",
    "ReactivationBanner.CheckList.FromUSAndEU": "Da USA e UE",
    "ReactivationBanner.TextByPlan.Starter.Description": "Non potrai più <1>spingere alcun prodotto</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Account Spocket",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Inizia subito a spingere prodotti al tuo negozio",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Non potrai più <1>Push Products</1> live o utilizzare ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Prodotti Premium",
    "ReactivationBanner.TextByPlan.Others.Title": "Account Premium",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Ottieni l’accesso a prodotti Premium che sono",
    "ReactivationBanner.Button": "Riattiva il mio account ADESSO",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Più spinte per i prodotti",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Ordini illimitati",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Fatturazione brandizzata",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Prodotti Premium",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Occasioni esclusive",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Assistenza via chat e telefono",
    "UpgradeReasonModal.Modal.Title": "Cosa vuoi ottenere con Spocket?",
    "UpgradeReasonModal.Modal.Question": "Perché hai effettuato l’upgrade?",
    "UpgradeReasonModal.Modal.Button": "Inizia a usare Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Ci aiuta a offrirti un’esperienza migliore",

    "UpgradeSuccessModal.Col.One.A": "Congratulazioni",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Hai sbloccato tutti gli strumenti di cui hai bisogno per mettere su un’attività di dropshipping di successo.",
    "UpgradeSuccessModal.Col.Two.B": "Ora puoi avere ",
    "UpgradeSuccessModal.Col.Two.C": " Buono Spockettamento!",
    "UpgradeSuccessModal.Button": "Continua",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 mese",
    "Promotion.TrialDaysNormalizer.28days": "4 settimane",
    "Promotion.TrialDaysNormalizer.21days": "3 settimane",
    "Promotion.TrialDaysNormalizer.14days": "2 settimane",
    "Promotion.TrialDaysNormalizer.7days": "1 settimana",
    "Promotion.TrialDaysNormalizer.1day": "1 giorno",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} giorno",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Prova gratis",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Prova ora →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Lingua",

    "WhatYouWillLoseModal.Button.Offer": "Vai avanti",
    "WhatYouWillLoseModal.Button.Downgrade": "Ci rinuncio, effettua il downgrade del mio account",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Perderai tutti i tuoi <1>{{product_count}} prodotti importati</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Premium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "+ {{product_count}} altri",
    "WhatYouWillLoseModal.ModalWrapper.Header": "Stai già rinunciando?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Ottieni il 50% di sconto",
    "WhatYouWillLoseModal.ModalWrapper.Title":
      "Continua il tuo viaggio imprenditoriale per i prossimi 3 mesi.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Vogliamo che la tua diventi una storia di successo. Sappiamo che hai già investito molto tempo fin qui. Allora, vai avanti! Facciamo il tifo per te. Team Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Congratulazioni, abbiamo aggiunto uno sconto del 50% al tuo piano per i prossimi 3 mesi!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Continua",

    "DowngradeReasonModal.DowngradeSection": "Come possiamo migliorare la tua esperienza con Spocket?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Il tuo feedback su qualsiasi cosa ci sarà molto utile (minimo 100 caratteri)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Digita il tuo feedback (minimo 100 caratteri)",
    "DowngradeReasonModal.Button.Downgrade": "Effettua il downgrade",
    "DowngradeReasonModal.ModalWrapper.Header": "Effettua il downgrade di {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Contattaci",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Annulla",

    "DowngradeSuccessModal.Header": "Downgrade effettuato con successo",
    "DowngradeSuccessModal.Body":
      "Grazie per il tuo feedback. Siamo costantemente al lavoro per migliorare Spocket, speriamo che ci vorrai dare un’altra occasione in futuro!",

    "Settings.title": "Impostazioni",
    "Settings.buttonSaveChanges": "Salva modifiche",
    "Settings.buttonSaveAndPreview": "Salva e vedi anteprima",
    "Settings.PricingPlans.Title": "Piani tariffari",

    "Settings.MenuTab.Plans": "Piani",
    "Settings.MenuTab.Account": "Account",
    "Settings.MenuTab.BrandedInvoicing": "Fatturazione brandizzata",
    "Settings.MenuTab.Billing": "Fatturazione",
    "Settings.MenuTab.GlobalPricingRules": "Regole di determinazione prezzi globali",
    "Settings.MenuTab.Security": "Sicurezza",

    "AccountTab.documentTitle": "Impostazioni - Account - Spocket",
    "AccountTab.title": "Impostazioni account",
    "AccountTab.shopName": "Nome negozio",
    "AccountTab.email": "Email",
    "AccountTab.shopUrl": "URL negozio",
    "AccountTab.defaultCurrency": "Valuta predefinita",
    "AccountTab.yourPlan": "Il tuo piano",
    "AccountTab.accountNotActive": "Il tuo account non è al momento attivo",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "Questa funzione sarà aggiunta al più presto! Per il momento, collegati tramite la app Spocket Shopify!",
    "AccountTab.alertAccountSettingsUpdated": "Impostazioni account aggiornate",
    "AccountTab.alertInvalidShopUrl": "URL negozio non valido",
    "AccountTab.productsImported": "Prodotti importati",
    "AccountTab.products": "Prodotti",
    "AccountTab.premiumProducts": "Prodotti Premium",
    "AccountTab.total": "totale",
    "AccountTab.signOut": "Disconnetti",
    "AccountTab.limitReachedProductsAndPremium": "Limite di prodotti e prodotti Premium raggiunto.",
    "AccountTab.limitReachedProducts": "Limite di prodotti raggiunto.",
    "AccountTab.limitReachedPremium": "Limite di prodotti Premium raggiunto.",
    "AccountTab.buttonSwitchToAnnualPlan": "Passa al piano annuale",
    "AccountTab.off30Percent": "30% di sconto",
    "AccountTab.off40Percent": "40% di sconto",
    "AccountTab.off45Percent": "45% di sconto",
    "AccountTab.sellMoreWithEmpirePlan": "Vendi di più con il piano Empire",
    "AccountTab.tooltipCurrency":
      "La tua valuta è determinata dal tuo account del negozio. Tutti i prezzi per i tuoi prodotti importati saranno convertiti in quella valuta.",
    "AccountTab.shopNotConnectedYet": "Il tuo negozio non è ancora stato collegato a questo account.",
    "AccountTab.connectYourShop": "Collega il tuo negozio",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Impostazioni - Fatturazione - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "Impostazioni di fatturazione salvate!",
    "InvoiceTab.shopLogo": "Logo negozio",
    "InvoiceTab.contactEmail": "Email di contatto",
    "InvoiceTab.phoneNumber": "Numero di telefono",
    "InvoiceTab.personalNote": "Nota personale",
    "InvoiceTab.tooltipPersonalNote": "Imposta una nota personale da includere nelle tue fatture brandizzate",
    "InvoiceTab.brandedInvoicing": "Fatturazione brandizzata",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Aggiungi il logo del tuo brand e una nota personale a tutti gli ordini di fornitori che offrono la fatturazione brandizzata su un piano a pagamento",
    "InvoiceTab.disabledInvoicing": "Includi fatturazione brandizzata su tutti gli ordini",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Disattivando questa opzione, non sarà disponibile la fatturazione brandizzata al momento di effettuare ordini",

    "PricingTab.titleDefaultPricingRules": "Regole di determinazione prezzi predefinite",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Regole di determinazione prezzi avanzate",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Imposta centesimi",
    "PricingTab.tooltipAssignCents":
      "Questo valore sarà utilizzato al momento di visualizzare il prezzo finale dei tuoi articoli (ad es. se vuoi che il costo del tuo prodotto sia di XX,99 aggiungi 99 al campo sottostante).",
    "PricingTab.Markup": "Markup",
    "PricingTab.MarkupType": "Tipo di markup",
    "PricingTab.SelectDefault": "-- Seleziona uno --",
    "PricingTab.SelectPercent": "Percentuale",
    "PricingTab.SelectMultiplier": "Moltiplicatore",
    "PricingTab.SelectFixed": "Fisso",
    "PricingTab.titlePricingRules": "Impostazioni - Regole di determinazione prezzi - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Configura regole di determinazione prezzi avanzate",
    "PricingTab.from": "Da",
    "PricingTab.to": "A",
    "PricingTab.selectFixedAmount": "Importo fisso",
    "PricingTab.buttonRemove": "Rimuovi",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "Non puoi creare più di una regola con campi vuoti",
    "PricingTab.costRange": "Fascia di prezzo",
    "PricingTab.markup": "Markup",
    "PricingTab.add": "Aggiungi",

    "SecurityTab.Document.Title": "Impostazioni - Sicurezza - Spocket",
    "SecurityTab.alertIncorrectPassword": "Password errata",
    "SecurityTab.title": "Sicurezza",
    "SecurityTab.yourNewPassword": "La tua nuova password",
    "SecurityTab.repeatPassword": "Ripeti password",
    "SecurityTab.errorPleaseEnterAPassword": "Inserisci una password.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Inserisci la conferma della password.",
    "SecurityTab.errorPasswordsDoNotMatch": "Le password non corrispondono",
    "SecurityTab.errorPasswordShort": "La password deve contenere almeno 8 caratteri",

    "ListingCards.TopSupplierTag.Tooltip":
      "I fornitori top sono riconosciuti per i loro prodotti di alta qualità, il servizio eccellente e le valutazioni alte",
    "ListingCards.TopSupplierTag.Text": "Fornitore",

    "ListingCard.OriginCountry.Intro": "Da",
    "ListingCard.OriginCountry.Tooltip": "Spedito da",
    "ListingCard.Shipping.Price.Title": "Costo di spedizione",
    "ListingCard.Shipping.Price.Free": "GRATIS",
    "ListingCard.Price.Intro": "Costo messa in elenco",
    "ListingCard.Price.Retail": "Prezzo al dettaglio",
    "ListingCard.Shipping.Time.Title": "Tempi di spedizione",
    "ListingCard.Shipping.Time.Period": "giorni",
    "ListingCard.PremiumIcon.Tooltip":
      "Vendi prodotti in sconto e disponibili per spedizione rapida nel tuo negozio",
    "ListingCard.PremiumIcon.Text": "Premium",
    "ListingCard.ListButton.Import": "Aggiungi a elenco per l’importazione",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Spinto al negozio",
    "ListingCard.StatusTag.Imported.Title": "Importato",
    "ListingCard.StatusTag.Imported.Text": "Questo elemento è gia nel tuo elenco import",
    "ListingCard.StatusTag.Pushed.Title": "In negozio",
    "ListingCard.StatusTag.Pusehd.Text": "Questo prodotto è stato spinto al tuo negozio",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Descrizione prodotto",
    "ListingModal.DetailsSection.Button.ProductVariations": "Varianti aperte per il prodotto",
    "ListingModal.DetailsSection.Button.OrderSamples": "Ordina campioni",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Novità",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "La fatturazione brandizzata non è disponibile per questo prodotto",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Gli ordini internazionali saranno spediti dal nostro magazzino in Cina",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Gli articoli esauriti nel magazzino negli USA potrebbero essere spediti dal nostro magazzino in Cina",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Gli ordini possono essere spediti in più pacchi per ridurre i tempi di spedizione complessivi sui tuoi ordini",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Collega il tuo negozio per effettuare l’ordine",
    "ListingModal.DetailsSection.PushedListButton": "Spinto al negozio",
    "ListingModal.DetailsSection.RemoveListButton": "Rimuovi dall’elenco",
    "ListingModal.DetailsSection.ImportListButton": "Aggiungi a elenco per l’importazione",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Prodotto disattivato",

    "InfoSection.ShippingTime.Tooltip": "Numero di giorni impiegati per consegnare il prodotto spedito.",
    "InfoSection.ShippingTime.Title": "Tempi di spedizione",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Tutto il mondo",
    "InfoSection.ShippingExcluded.Intro": "Non può essere spedito a",
    "InfoSection.ShippingExcluded.Various": "Vari",
    "InfoSection.ProcessingTime.Tooltip":
      "Numero di giorni impiegati dal fornitore per spedire e fornire il numero di tracking",
    "InfoSection.ProcessingTime.Title": "Tempi di elaborazione",
    "InfoSection.ProcessingTime.Period": "giorni lavorativi",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Questo prodotto viene spedito per {{price_formatted}}, oltre a {{incremental_price_formatted}} per ogni prodotto aggiuntivo nello stesso ordine",
    "InfoSection.ShippingInfoLine.Period": "giorni lavorativi",
    "InfoSection.ShippingInfoLine.Free": "GRATIS",
    "InfoSection.ShippingInfoLine.NoShipping": "Non può essere spedito",

    "ReturnPolicySection.Title": "Politica sui resi",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Da",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Da",
    "TitleSection.PriceMSRP.Listing": "Prezzo in elenco",
    "TitleSection.PriceMSRP.Retail": "Prezzo al dettaglio",

    "ProductVariationsModal.Title": "Varianti prodotto",
    "ProductVariationsModal.Table.Image": "Immagine",
    "ProductVariationsModal.Table.Inventory": "Inventario",
    "ProductVariationsModal.Table.Price": "Prezzo",
    "ProductVariationsModal.ImageSpecifications.Title": "Tabella dei formati",
    "ProductVariationsModal.ImageSpecifications.Button": "Scarica",

    "TopSupplier.Tooltip":
      "I fornitori top sono riconosciuti per i loro prodotti di alta qualità, il servizio eccellente e le valutazioni alte",
    "TopSupplier.Text": "Fornitore top",

    "BestSelling.Tooltip.Top": "Questo prodotto è tra i più venduti su Spocket",
    "BestSelling.Tooltip.Bottom": "Best seller",

    "GallerySection.PremiumIcon.Tooltip":
      "Vendi prodotti in sconto e disponibili per spedizione rapida nel tuo negozio",
    "GallerySection.PremiumIcon.Text": "Premium",
    "GallerySection.DiscountStamp.Text": "{{value}}% di sconto in più",

    "Upgrade.Title.AnnualToggled": "Ottieni Spocket Premium oggi stesso",
    "Upgrade.Title.MonthlyToggled": "Prova gratis Spocket Premium per 14 giorni",
    "Upgrade.Subtitle":
      "Prova subito un piano Spocket Premium e inizia ad aggiungere al tuo negozio una gamma di prodotti ad alta conversione provenienti da USA ed Europa.",
    "Upgrade.Button": "Continua senza Spocket Premium",

    "Search.NoProductsFound.Title": "Siamo spiacenti! Nessun risultato trovato",
    "Search.NoProductsFound.For": "per",
    "Search.NoProductsFound.Description":
      "Controlla l’ortografia, prova a cercare un termine correlato o un termine di ricerca più generico.",
    "Search.Searching.Title": "Stiamo cercando tra i prodotti...",
    "Search.Searching.Description": "Attendi mentre carichiamo i prodotti più recenti da mostrarti.",

    "Suppliers.StarRating.Tooltip": "Tasso di evasione ordini del fornitore",
    "Suppliers.RefundPolicy": "Politica sui rimborsi del fornitore",
    "Suppliers.Joined.Title": "Membro di Spocket",
    "Suppliers.Origin.Title": "Spedizione dal paese",
    "Suppliers.Time.Title": "Tempi medi di elaborazione",
    "Suppliers.HasMore.Title": "Stiamo cercando tra i prodotti...",
    "Suppliers.HasMore.Subtitle": "Attendi mentre carichiamo i prodotti più recenti da mostrarti.",
    "Suppliers.TopSupplierTag.Tooltip":
      "I fornitori top sono riconosciuti per i loro prodotti di alta qualità, il servizio eccellente e le valutazioni alte",
    "Suppliers.TopSupplierTag.Text": "Fornitore top",

    "AltTextModal.Header.Title": "Modifica dettagli immagine",
    "AltTextModal.Body.Description":
      "Scrivi una breve descrizione di questa immagine per migliorare la SEO (ottimizzazione per i motori di ricerca) e l’accessibilità per i clienti ipovedenti.",
    "AltTextModal.Input.Label": "Alt text",
    "AltTextModal.Input.Placeholder": "Alt text immagine",
    "AltTextModal.Button.Cancel": "Annulla",
    "AltTextModal.Button.Save": "Salva",

    "WistiaModal.WhatIsSpocket.Title": "Cos’è Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Scopri come Spocket può aiutarti ad avviare la tua attività. Impara a conoscere il funzionamento di Spocket e come lo puoi sfruttare al meglio.",
    "WistiaModal.WhatIsSpocket.Button": "Inizia a trovare prodotti",
    "WistiaModal.DiscoverProducts.Title": "Scopri i prodotti",
    "WistiaModal.DiscoverProducts.Text":
      "Trova i prodotti giusti con l’aiuto dei filtri ottimizzati, e aggiungili al tuo negozio.",
    "WistiaModal.DiscoverProducts.Button": "Inizia a trovare prodotti",
    "WistiaModal.ProductCustomization.Title": "Personalizza i tuoi prodotti",
    "WistiaModal.ProductCustomization.Text":
      "Usa l’elenco per l’importazione per modificare i tuoi prodotti nella maniera adatta al tuo negozio. Puoi modificare le descrizioni dei prodotti, impostare i prezzi, aggiungere i prodotti a una collezione o rimuovere le immagini.",
    "WistiaModal.ProductCustomization.Button": "Vai a elenco per l’importazione",
    "WistiaModal.OrderProcessing.Title": "Elaborazione automatizzata ordini",
    "WistiaModal.OrderProcessing.Text":
      "Evadi gli ordini e lascia che sia il fornitore a consegnare il prodotto sulla porta del cliente, in un solo clic.",
    "WistiaModal.OrderProcessing.Button": "Vai agli ordini",
    "WistiaModal.Settings.Title": "Impostazioni",
    "WistiaModal.Settings.Text":
      "La pagina delle impostazioni ti consente di gestire tutte le informazioni relative al tuo account, brandizzare le tue fatture e aggiungere la carta di credito. Puoi anche impostare il prezziario per tutti i tuoi prodotti e scegliere un piano di abbonamento.",
    "WistiaModal.Settings.Button": "Configura il tuo account",

    "Footer.Copyright": "Copyright {{year}}, Spocket. Tutti i diritti riservati",
    "Footer.PrivacyPolicy": "Politica sulla privacy",
    "Footer.TermsConditions": "Termini e condizioni",
    "Footer.DataProcessingAddendum": "Appendice sul trattamento dei dati",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English

    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
