import ApiCall from "utils/apiCall";
import { showModal } from "utils/actions/ui";
import { setAlertMessage } from "utils/actions";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "password.verify.isFetching":
      return { ...state, isFetching: true };

    case "password.verify.fetchSuccess":
      return { ...state, isFetching: false, ...action.response };

    case "password.verify.fetchError":
      return { ...state, isFetching: false, ...action.error };

    default:
      return state;
  }
}

// Actions
export function verifyPassword({ password, paymentProvider = "", activeUpgradeType = "" }) {
  return async (dispatch, getState) => {
    dispatch({ type: "password.verify.isFetching" });

    try {
      const response = await ApiCall.post("/password/verify", { password });

      if (paymentProvider !== "chargebee_payment" || activeUpgradeType !== "chargebee")
        dispatch(showModal("DOWNGRADE_REASON_MODAL"));
      dispatch({
        type: "password.verify.fetchSuccess",
        response
      });
    } catch (error) {
      dispatch(setAlertMessage("Passwords don't match!", "error"));
      dispatch({
        type: "password.verify.fetchError",
        error
      });
    }
  };
}
