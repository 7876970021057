export function signupToPartnerStack() {
  try {
    const growsumo = window.growsumo;

    growsumo.data.name = localStorage.getItem("user_name");
    growsumo.data.email = localStorage.getItem("user_email");
    growsumo.data.customer_key = localStorage.getItem("shop_id");

    growsumo.createSignup();
  } catch (err) {
    console.log(err);
  }
}
