export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "マイオーダーリスト",
    "OrderList.Input.Search": "オーダー番号...",
    "OrderList.Label.Status": "Spocket の状態でのフィルタリング",

    "order.status.unpaid": "未払い",
    "order.status.paid": "支払い済み",
    "order.status.processing": "処理中",
    "order.status.shipped": "発送済み",
    "order.status.cancelled": "キャンセル済み",
    "order.status.pending": "保留中",
    "order.status.partially_paid": "一部支払い済み",
    "order.status.partially_refunded": "一部返金済み",
    "order.status.refunded": "返金済み",
    "order.status.voided": "無効",
    "order.status.authorized": "承認済み",
    "order.status.deleted": "削除済み",
    "order.status.pending_payment_confirmation": "保留中",

    "OrderList.Label.DownloadHistory": "すべてのオーダー履歴をCSV形式でダウンロード",
    "OrderList.Document.Title": "オーダー - Spocket分",
    "OrderList.EmptyOrders.Link.SearchProducts": "商品を検索する",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "オーダーの処理方法",
    "OrderList.EmptyOrders.Message.Loading.Title": "オーダーが読み込まれています！",
    "OrderList.EmptyOrders.Message.Loading.Description": "オーダーが読み込まれるまでお待ちください。",
    "OrderList.EmptyOrders.Message.Title": "オーダーリストは空です！",
    "OrderList.EmptyOrders.Message.Description":
      "ここでオーダーの管理および処理ができます。検索ページに移動して、インポートリストへの商品の追加を開始します。",
    "OrderList.CheckBox.SelectAllOrders": "すべてのオーダーを選択",
    "OrderList.CheckBox.SelectedXOrders": "{{count}}オーダーが選択されました",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}}オーダーが選択されました",
    "OrderList.CheckBox.BulkCheckoutOrders": "一括チェックアウト注文",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "一括チェックアウト注文",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "注文を同期する",
    "RefreshModal.Button.Cancel": "キャンセルする",
    "RefreshModal.Button.Synchronize": "同期する",
    "RefreshModal.Modal.Body":
      "注文はストアから自動的に同期されます。注文情報がない場合や更新されていない場合にのみ同期します。30秒後にページを更新してください。",

    "Order.Label.OrderNumber": "注文番号",
    "Order.Label.Date": "日付",
    "Order.Label.OrderStatus": "{{integrationName}} 注文ステータス",
    "Order.Table.Th.Product": "商品",
    "Order.Table.Th.Qty": "数量",
    "Order.Table.Th.UnitPrice": "単価",
    "Order.Table.Th.CheckoutDate": "チェックアウト日",
    "Order.Table.Th.Action": "アクション",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "顧客情報を見る",
    "CustomerModal.Modal.Title.CustomerInfo": "顧客情報",
    "CustomerModal.Modal.Body.CustomerInfo": "顧客情報",
    "CustomerModal.Modal.Body.Address": "住所",

    "OrderLine.Button.Checkout": "チェックアウト",
    "OrderLine.Tooltip.PreviewInvoice": "請求書のプレビュー",
    "OrderLine.Tooltip.PaymentReceipt": "領収書",
    "OrderLine.Tooltip.TrackOrder": "注文の追跡",
    "OrderLine.Tooltip.OrderProcessing": "注文の処理",
    "OrderLine.Tooltip.OrderCancelled": "注文がキャンセルされました",
    "OrderLine.Tooltip.PleaseConfirm": "クリックしてご注文の支払いを確認してください",
    "OrderLine.Label.PurchaseEtsy": "Etsyで購入",
    "OrderLine.Paying": "支払い",
    "OrderLine.Failure": "失敗",
    "OrderLine.Sample": "サンプル",
    "OrderLine.ErrorOrderRow": "注文したリストがシステム内で見つかりません！サポートにお問い合わせください！",

    "ConfirmationModal.Label.OrderDetails": "注文詳細",
    "ConfirmationModal.Label.Total": "合計",
    "ConfirmationModal.Label.Shipping": "発送",
    "ConfirmationModal.Label.TransactionFees": "取引手数料",
    "ConfirmationModal.Tooltip.TransactionFees":
      "取引手数料には、Spocketの決済プロバイダーであるStripeが請求する手数料やサービス手数料が含まれます。",
    "ConfirmationModal.Modal.OrderConfirmation": "注文の確認",
    "ConfirmationModal.Modal.Alert.Attention": "ご注意！",
    "ConfirmationModal.Modal.Alert.Description": "この注文には、お客様からのメモが含まれています。",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "注文しようとしています",
    "ConfirmationModal.Modal.Table.Th.Name": "名前",
    "ConfirmationModal.Modal.Table.Th.Price": "価格",
    "ConfirmationModal.Modal.Table.Th.Qty": "数量",
    "ConfirmationModal.Modal.Table.Th.Total": "合計",
    "ConfirmationModal.Modal.Label.Note": "サプライヤーへのメモ",
    "ConfirmationModal.Modal.PlaceHolder.Note": "あなたのメッセージ",
    "ConfirmationModal.Button.PlaceOrder": "注文する",

    "PaymentCompleteModal.PaymentCompleted": "支払いが完了しました",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "お支払いありがとうございました。サプライヤーに通知されており、まもなく注文を処理します。注文のステータスを更新し、注文ページに追跡番号を追加します。",
    "PaymentCompleteModal.PaymentIncomplete": "ご注文は不完全です！",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "お手続きを進めるには、銀行でのお支払いの確認が必要です。",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink":
      "お支払いの確認はこちらのリンクからお願いします。",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "お支払いを確認されると、サプライヤーに通知され、注文は通常どおり処理されます。ステータスと追跡の更新が注文ページに追加されます。ご確認後、ページを再度読み込んでください。",

    "SetYourGoalsModal.Title": "ストアに追加された商品の数",
    "SetYourGoalsModal.Subtitle": "一般的の店舗では売り上げを計上する前に25個の商品をプッシュ",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} 商品",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} 商品",
    "SetYourGoalsModal.Button": "目標を設定してドロップシッピングを開始する",

    "YourGoalsBanner.Container.Description": "あなたの目標",
    "YourGoalsBanner.Header.Title": "ビジネスを始める",
    "YourGoalsBanner.Header.Subtitle": "お店を準備しましょう",
    "YourGoalsBanner.Progress.Completed": "完成",
    "YourGoalsBanner.ProductPushedCount": "<0>目標：{{goalsNumberOfProduct}}</0>プッシュされた商品",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Spocketに参加する",
    "YourGoalsBanner.CheckPoints.FirstSearch": "最初の検索",
    "YourGoalsBanner.CheckPoints.ProductsPushed": "{{count}} / {{goalsNumberOfProduct}}プッシュされた商品",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}}プッシュされた商品",
    "YourGoalsBanner.CheckPoints.ProductsImported":
      "{{count}} / {{goalsNumberOfProduct}}インポートされた商品",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}}インポートされた商品",

    "Plan.Starter": "スターター",
    "Plan.Professional": "プロフェッショナル",
    "Plan.Empire": "エンパイア",
    "Plan.Unicorn": "ユニコーン",

    "Aliscraper.Title": "自動化されたAliExpressのドロップシッピング",
    "Aliscraper.Subtitle":
      "何千ものAliExpress製品をワンクリックでeコマースストアにインポートし、ドロップシッピングビジネスを自動化します。",
    "Aliscraper.Features.Feature1": "ワンクリックで高速インポート",
    "Aliscraper.Features.Feature2": "Spocketで数百件の注文を即座に処理",
    "Aliscraper.Features.Feature3": "24時間年中無休のカスタマーサポート",
    "Aliscraper.Button": "拡張機能をダウンロードする",
    "Aliscraper.Chrome": "Chromeウェブストアで入手可能",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "あなたのショップをつなぎます",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "開始するプラットフォームを選択してください",
    "ConnectStoreModal.ShopifyForm.InvalidName": "ストア名が無効です",
    "ConnectStoreModal.ShopifyForm.Placeholder": "例：マイストア",
    "ConnectStoreModal.ShopifyForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.ShopifyForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.ShopifyForm.Button": "ストアをつなげる",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "あなたの認証キー：",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "ストアをSpocketに接続する方法：",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Spocketプラグインのインストールは",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "ここから",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "プラグインのページでプラグインをアクティブ化します",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "WordPressダッシュボードから > Spocketに移動します",
    "ConnectStoreModal.WoocommerceInstruction.Step4": "認証キー（上記）を貼り付けて「保存」をクリックします",
    "ConnectStoreModal.WoocommerceInstruction.Button": "完了する",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.WixForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.WixForm.Button": "ストアをつなげる",
    "ConnectStoreModal.FelexForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.FelexForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.FelexForm.Button": "ストアをつなげる",
    "ConnectStoreModal.BigcommerceForm.UrlText": "ビッグコマースストアのURL",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "ストア名が無効です",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "例：マイストア",
    "ConnectStoreModal.BigcommerceForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.BigcommerceForm.Button": "ストアをつなげる",
    "ConnectStoreModal.EcwidForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.EcwidForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.EcwidForm.Button": "ストアをつなげる",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.WhiteLabelForm.Button": "ストアをつなげる",
    "ConnectStoreModal.SquarespaceForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.SquarespaceForm.Button": "ストアをつなげる",
    "ConnectStoreModal.SquareForm.FooterText": "お店をお持ちではませんか？",
    "ConnectStoreModal.SquareForm.FooterLink": "無料でお試しください",
    "ConnectStoreModal.SquareForm.Button": "ストアをつなげる",
    "ConnectStoreModal.FelexButton.Explanation":
      "起業家向けウェブストア作成プラットフォームでドロップシッピングストアを10分以内に開設。",
    "ConnectStoreModal.DontHaveStore":  "お店をお持ちではませんか？",
    "ConnectStoreModal.TryForFree": "無料でお試しください",

    "PaymentTab.Document.Title": "設定 - 自動化 - Spocket",
    "PaymentTab.Title.Billing": "請求",
    "PaymentTab.Tooltip.Billing": "クレジットカードは、注文処理とサブスクリプションの支払いに使用されます",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "一時停止/プランの変更",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "プランの変更",
    "PaymentTab.AccordionItem.Button.Downgrade": "ダウングレードする",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "プランの一時停止",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "あなたのストアはすでに一時停止されています",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "プランの一時停止",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Spocketアカウントを一時的に停止して、データを失うことなくいつでも戻ってくることができます。",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "後でリマインドする",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "後でリマインドする",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "３日前",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "特典を維持して <1>{{daysBefore}}</1>にメンバーシップが更新されることをリマインドしてください",
    "RemindMeLaterModal.Header.ReminderSet": "リマインダー設定",
    "RemindMeLaterModal.Body.DaysBefore": "３日前にメールを送信",
    "RemindMeLaterModal.Body.ReminderSet":
      "リマインダーが設定され、<1>{{daysBefore}}</1>にあなたの特典が更新されるまで、起業家としての旅を続けてください。",
    "RemindMeLaterModal.Button.Continue": "続ける",
    "PauseStoreModal.Title.PauseYourStore": "ストアの一時休止",
    "PauseStoreModal.Description.PauseYourStore":
      "この1度限りのオファーを利用すると、今後30日間、プラン料金を60％割引します。",
    "PauseStoreModal.Button.PauseStore": "ストアを一時休止する",
    "PauseStoreModal.Description.StorePaused":
      "ストアは一時休止されており、翌月のご利用分が60％引きになります。その後、通常のお支払いが再開されますのでご注意ください。",
    "ConfirmPasswordModal.Title": "パスワードをご確認ください",
    "ConfirmPasswordModal.SubTitle": "セキュリティ上の理由から、パスワードを確認してください",
    "ConfirmPasswordModal.Input.Placeholder": "あなたのパスワード",
    "ConfirmPasswordModal.Button.Cancel": "キャンセルする",
    "ConfirmPasswordModal.Button.Confirm": "確認する",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "ja",
    "Config.Plan.Starter": "スターター",
    "Config.Plan.Professional": "プロ",
    "Config.Plan.Empire": "エンパイア",
    "Config.Plan.Unicorn": "ユニコーン",

    "UpgradeConfirmationModal.Header.Title": "Spocketでより速く成長 ",
    "UpgradeConfirmationModal.Body.Title": "請求サイクルの選択",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "最初の",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "数日間 ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "は無料です。トライアル中またはトライアル後にキャンセルしてください",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "最初の請求は、次のように予定されています",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial": "最初の請求は確認時に行われます",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "毎月請求されます",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "毎月 ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "/月",
    "UpgradeConfirmationModal.YearlyTab.RateType": "毎年 ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "/月",
    "UpgradeConfirmationModal.YearlyTab.Discount": "OFF",
    "UpgradeConfirmationModal.TaxRates.Text": "消費税",
    "UpgradeConfirmationModal.TaxRates.Period": "月",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "今すぐ支払う",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "/月 X 12ヶ月",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "お得になった金額：",
    "UpgradeConfirmationModal.YearlyPanel.Period": "/年",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "に相当",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% 引き",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "年間プランにはトライアル期間はありません",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge": "すぐに全額請求されます",
    "UpgradeConfirmationModal.CreditCardTab.Info": "クレジット/デビットカードで支払う",
    "UpgradeConfirmationModal.PayPalTab.Info": "PayPalで支払う",
    "UpgradeConfirmationModal.PaymentSection.Details": "支払の詳細 ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "無料トライアルを請求する",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "アップグレードする ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "100,000人以上の起業家からの信頼",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "お客様から一貫して５段階評価で５をいただいています。",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "128ビットSSL暗号化",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom": "すべての支払い情報は100％安全です。",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "100,000人以上",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "100,000人以上の起業家からの信頼",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "お客様から一貫して５段階評価で５をいただいています。",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "128ビットSSL暗号化",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom": "すべての支払い情報は100％安全です。",
    "UpgradeConfirmationModal.Agreement.Intro": "続行すると、あなたは以下の内容に同意したことになります ",
    "UpgradeConfirmationModal.Agreement.Terms": "利用規約",
    "UpgradeConfirmationModal.Agreement.Privacy": "個人情報保護方針",
    "UpgradeConfirmationModal.Agreement.Refund": "返金ポリシー",
    "UpgradeConfirmationModal.Agreement.Middle": "、および",
    "UpgradeConfirmationModal.Agreement.Cancelation": "キャンセルポリシー",

    "OrderDetails.Title": "注文詳細",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "で終わるクレジットカード",
    "CreditCard.Update.Button": "カードを更新する",
    "CreditCard.AddCreditCard.Text":
      "まだクレジットカードを追加していません。下のボタンからクレジットカードを追加して、注文を自動的に処理できるようにしてください",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "クレジットカード追加する",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "売れ筋商品",
    "Sidebar.WinningProducts.Variant": "NEW",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "アプリ",
    "Sidebar.HelpCenter": "ヘルプセンター",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "設定",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "私たちがお答えいたします!",
    "TutorialModal.WhatIsSpocket": "Spocketとは?",
    "TutorialModal.DiscoverProducts": "製品の紹介",
    "TutorialModal.ProductCustomization": "製品のカスタマイズ",
    "TutorialModal.OrderProcessing": "オーダー処理",
    "TutorialModal.Settings": "設定",
    "TutorialModal.GetHelp": "お問い合わせ",
    "TutorialModal.JoinTheCommunity": "コミュニティに参加する",

    "NotificationCenter.Header": "通知センター",
    "NotificationCenter.Footer": "すべての通知を見る",
    "NotificationCenter.NoNotification": "ここには何もありません！",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English

    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English
    
    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English

    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "マイプロダクト - Spocket",
    "Products.Title.Header": "マイプロダクト",
    "Products.Loading.Top": "商品リストが読み込まれています！",
    "Products.Loading.Bottom": "商品情報を読み出すまでお待ちください。",
    "Products.Empty.Search": "商品が見つかりませんでした！",
    "Products.Empty.Top": "あなたの商品リストは空です！",
    "Products.Empty.Bottom": "検索ページに移動して、インポートリストへの商品の追加を開始します。",
    "Products.Empty.Button.Search": "商品を検索する",
    "Products.Empty.Button.How": "製品の探し方",
    "Products.Alert.Removed": "製品が削除されました",

    "Products.Search.Label": "製品を検索する",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "検索する",

    "ProductListItem.Inactive.Tooltip": "この製品は、サプライヤーから入手できなくなりました",
    "ProductListItem.Inactive.Title": "非アクティブ",
    "ProductListItem.OutOfStock.Tooltip": "この商品のバリエーションはすべて在庫切れです",
    "ProductListItem.OutOfStock.Title": "在庫切れ",
    "ProductListItem.InventoryCount.Singular": "個",
    "ProductListItem.InventoryCount.Plural": "個",
    "ProductListItem.InventoryCount.End.Singular": "残り",
    "ProductListItem.InventoryCount.End.Plural": "残り",
    "ProductListItem.Button.View": "ストアで見る",
    "ProductListItem.Button.Remove": "ストアから削除する",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "インポートリスト - Spocket",
    "ImportList.Title.Header": "マイインポートリスト",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "インポートリストが読み込まれています！",
    "ImportList.Loading.Bottom": "商品情報を読み出すまでお待ちください。",
    "ImportList.Empty.Button.Search": "商品を検索する",
    "ImportList.Empty.Top": "インポートリストが空です!",
    "ImportList.Empty.Bottom": "検索ページに移動して、インポートリストへの商品の追加を開始します。",
    "ImportList.Empty.Button.How": "製品をカスタマイズする方法",
    "ImportList.Alert.Pushed": "この商品はあなたのストアにプッシュされました：",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "インポートされた製品を検索する",
    "ImportList.Search.Placeholder": "製品を検索する",
    "ImportList.Search.Button": "検索する",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categories", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "現在のページの製品をプッシュする",
    "PushAllModal.Button.Inactive": "現在のページをプッシュする",
    "PushAllModal.Modal.Text":
      "この現在のページからすべての製品をプッシュしてもよろしいですか？現在のページにある有効な商品はすべてあなたのストアにプッシュされます。",
    "PushAllModal.Modal.Push": "すべてプッシュ",
    "PushAllModal.Modal.Cancel": "キャンセルする",

    "RemoveAllModal.Button.Active": "現在のページの製品を削除する",
    "RemoveAllModal.Button.Inactive": "現在のページを削除する",
    "RemoveAllModal.Modal.Text":
      "この現在のページからすべての製品を削除してもよろしいですか？現在のページにある製品はすべてインポートリストから削除されます。",
    "RemoveAllModal.Modal.Remove": "すべて削除",
    "RemoveAllModal.Modal.Cancel": "キャンセルする",

    "ImportListItem.Tab.Product": "商品",
    "ImportListItem.Tab.Description": "説明",
    "ImportListItem.Tab.Variants": "バリアント",
    "ImportListItem.Tab.Images": "画像",
    "ImportListItem.Remove.Active": "製品の削除",
    "ImportListItem.Remove.Inactive": "製品を削除する",
    "ImportListItem.Push.Active": "ストアへのプッシュ",
    "ImportListItem.Push.Inactive": "ストアにプッシュする",
    "ImportListItem.Save.Button": "保存する",
    "ImportListItem.Alert.Removed": "この製品はインポートリストから削除されました：",
    "ImportListItem.Alert.RemoveFailed": "削除しようとして問題が発生しました：",
    "ImportListItem.Alert.CompareAtPrice": "価格で比較すると、販売価格よりも高くなりますす：",
    "ImportListItem.Alert.OneActiveVariation":
      "この製品には、少なくとも1つのアクティブなバリアントが必要です：",
    "ImportListItem.Alert.Saved": "この製品は保存されました：",
    "ImportListItem.Alert.ErrorPushing": "ストアにプッシュする時に何か問題が発生しました",
    "ImportListItem.Alert.ErrorSaving": "ストアを保存する時に問題が発生しました",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "ストアをつないで商品をプッシュしましょう：",
    "ImportListItem.Tooltip.OneActiveVariation": "少なくとも1つのアクティブなバリエーションが必要です",
    "ImportListItem.Tooltip.Unavailable": "この製品は現在購入できません",
    "ImportListItem.Tooltip.Unsaved": "保存されていない変更があります",
    "ImportListItem.Tooltip.Profit": "警告：この製品の粗利はマイナスです",
    "ImportListItem.Tooltip.Unpurchasable": "この製品を保存することはできますが、現在購入できません",
    "ImportListItem.Variants.ShowShippingPrices": "配送料の表示",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "商品名",
    "ItemInfo.ProductTags.Label": "商品タグ",
    "ItemInfo.ProductTags.Remove": "すべてのタグを削除",
    "ItemInfo.ProductTags.Placeholder": "新しいタグを追加",
    "ItemInfo.ProductType.Label": "商品タイプ",
    "ItemInfo.Collection.Label": "コレクション",
    "ItemInfo.Collection.Placeholder": "選択",
    "ItemInfo.Collection.Option": "選択",
    "ItemInfo.SquareLocation.Option": "選択",

    "ItemVariantList.Modal.Title": "配送の詳細",
    "ItemVariantList.Modal.Intro": "発送元：",
    "ItemVariantList.Modal.DoesNotShipInternational": "この商品は海外発送ができません。",
    "ItemVariantList.Modal.DoesNotShipTo": "発送できない国：",
    "ItemVariantList.Table.Head.Destination": "送り先",
    "ItemVariantList.Table.Head.Cost": "費用",
    "ItemVariantList.Table.Head.Time": "配達時間（日数）",
    "ItemVariantList.Table.Body.Domestic": "国内",
    "ItemVariantList.Table.Body.International": "海外",
    "ItemVariantList.VariantsTable.ShippingPrice": "配送料 ",
    "ItemVariantList.VariantsTable.Image": "画像",
    "ItemVariantList.VariantsTable.SKU": "SKU",
    "ItemVariantList.VariantsTable.Inventory": "在庫",
    "ItemVariantList.VariantsTable.Price": "価格",
    "ItemVariantList.VariantsTable.SalesPrice": "販売価格 ",
    "ItemVariantList.VariantsTable.Profit": "利益",
    "ItemVariantList.VariantsTable.CompareAtPrice": "価格で比較",
    "ItemVariantList.VariantsTable.Unavailable": "この製品は現在購入できません",
    "ItemVariantList.VariantsTable.Head": "この製品のすべてのバリアントがここに表示されます。",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "例：40%",
    "ItemVariantList.Alert.MaxVariants": "Shopifyは100以上のバリエーションはサポートしていません。",

    "ItemVariantList.ContextPopover.Title": "利益の設定",
    "ItemVariantList.ContextPopover.Apply": "適用",

    "ProductVariation.Description":
      "サンプル製品はSpocketで直接注文できます。以下の手順で簡単にチェックアウトできます。",
    "ProductVariation.Label.SelectVariant": "バリアントを選択",
    "ProductVariation.Label.SelectTheProductVariation": "製品バリエーションを選択",
    "ProductVariation.Qty": "数量",
    "ProductVariation.Label.NotesForTheSupplier": "サプライヤーへの注意事項",

    "InputAddress.Label.FirstName": "名",
    "InputAddress.PlaceHolder.FirstName": "名を入力してください",
    "InputAddress.Label.LastName": "姓",
    "InputAddress.PlaceHolder.LastName": "姓を入力してください",
    "InputAddress.Label.StreetAddress": "住所",
    "InputAddress.Label.Country": "国",
    "InputAddress.PlaceHolder.Country": "国を検索",
    "InputAddress.Label.State": "県",
    "InputAddress.Placeholder.State": "県を検索",
    "InputAddress.Label.City": "市",
    "InputAddress.PlaceHolder.City": "都市名を入力",
    "InputAddress.Label.Zip": "郵便番号",
    "InputAddress.PlaceHolder.Zip": "郵便番号を入力",
    "InputAddress.Label.Phone": "電話番号",
    "InputAddress.Placeholder.Phone": "電話番号を入力",

    "OrderReview.OrderDetails": "オーダー詳細",
    "OrderReview.Unit": "{{count}} 単位",
    "OrderReview.Unit_plural": "{{count}} 単位",
    "OrderReview.ShippingAddress": "配送先住所",
    "OrderReview.PaymentMethod": "支払い方法",
    "OrderReview.Amounts": "金額",
    "OrderReview.Subtotal": "小計",
    "OrderReview.ShippingCost": "配送料",
    "OrderReview.TransactionFees": "取引手数料",
    "OrderReview.TransactionFees.ToolTips":
      "取引手数料には、Spocketの決済プロバイダーであるStripeが請求する手数料やサービス手数料が含まれます。",
    "OrderReview.OrderTotal": "オーダー合計",
    "OrderReview.ItemWithCount": "{{count}} アイテム",
    "OrderReview.ItemWithCount_plural": "{{count}} アイテム",
    "OrderReview.Placeholder.CreditCard": "{{last4}}で終わるクレジットカード",

    "PaymentComplete.PaymentCompleted": "支払いが完了しました",
    "PaymentComplete.PaymentCompleted.Note":
      "お支払いありがとうございました。サプライヤーに通知されており、まもなく注文を処理します。注文のステータスを更新し、注文ページに追跡番号を追加します。",
    "PaymentComplete.PaymentIncomplete": "ご注文は不完全です！",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "お手続きを進めるには、銀行でのお支払いの確認が必要です。",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "お支払いの確認はこちらのリンクからお願いします。",
    "PaymentComplete.PaymentIncomplete.Note":
      "お支払いを確認されると、サプライヤーに通知され、注文は通常どおり処理されます。ステータスと追跡の更新が注文ページに追加されます。ご確認後、ページを再度読み込んでください。",

    "CreditCard.AddCreditCard.Description":
      "まだクレジットカードを追加していません。下のボタンからクレジットカードを追加して、注文を自動的に処理できるようにしてください",
    "CreditCard.Button.AddCreditCard": "クレジットカード追加する",
    "CreditCard.Button.UpdateCard": "カードを更新する",
    "CreditCard.UpdateCard.Label.CreditCard": "クレジットカード",
    "CreditCard.PlaceHolder.CreditCard": "{{last4}}で終わるクレジットカード",

    "UpdateCreditCardModal.Label.CreditCard": "クレジットカード",
    "UpdateCreditCardModal.Button.UpdateCard": "カードを更新する",
    "UpdateCreditCardModal.Button.AddCreditCard": "クレジットカード追加する",
    "UpdateCreditCardModal.Button.Cancel": "キャンセルする",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "カードの詳細を更新する",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "あなたのアカウントは、Spocket製品のオーダー処理中に使用されます。",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "ご利用規約",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "支払い処理用",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "バリエーションを選択",
    "SampleOrderModal.Title.ShippingAddress": "配送先住所",
    "SampleOrderModal.Title.PaymentMethod": "支払い方法",
    "SampleOrderModal.Title.Review": "レビュー",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "支払い確認が必要です！",
    "SampleOrderModal.Title.OrderPlaced": "オーダー済み！",
    "SampleOrderModal.Steps": "ステップ  {{total}} の{{current}}",
    "SampleOrderModal.FinalStep": "最終ステップ",
    "SampleOrderModal.ConfirmPayment": "お支払いをご確認ください！",
    "SampleOrderModal.Finished": "完了",
    "SampleOrderModal.Footer.Continue": "続ける",
    "SampleOrderModal.Footer.PlaceOrder": "注文する",
    "SampleOrderModal.Footer.Close": "閉じる",
    "SampleOrderModal.Footer.Cancel": "キャンセルする",
    "SampleOrderModal.Footer.GoBack": "戻る",

    "AdvancedFiltersModal.Title.Filters": "フィルター",
    "AdvancedFiltersModal.Title.Shipping": "発送",
    "AdvancedFiltersModal.Label.ShipsFrom": "発送元",
    "AdvancedFiltersModal.Label.ShipsTo": "配送先",
    "AdvancedFiltersModal.Label.ShippingTime": "配送時間",
    "AdvancedFiltersModal.Label.Popular": "ポピュラー",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "アメリカ",
    "AdvancedFiltersModal.Label.Country.Europe": "ヨーロッパ",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "米国への発送のみ可能",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "すべての配達日数",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1〜3日",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4〜7日",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8〜14日",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "15日以上",
    "AdvancedFiltersModal.Title.ItemsCost": "アイテムの費用",
    "AdvancedFiltersModal.Label.ItemCost": "アイテムの費用",
    "AdvancedFiltersModal.Label.ShippingCost": "配送料",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "すべての配送費用",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "無料",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5ドル以下",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15ドル以下",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25ドル以下",
    "AdvancedFiltersModal.Title.Supplier": "サプライヤー",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "サプライヤー",
    "AdvancedFiltersModal.Label.TopSupplier": "トップサプライヤー",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "質の高い製品、優れたサービス、高い評価で知られるサプライヤー",
    "AdvancedFiltersModal.Title.Advanced": "アドバンス",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "プレミアム商品",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription": "割引率が高く、出荷が早い商品",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "ベストセラー",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription":
      "Spocketで最高のパフォーマンスを発揮する製品",
    "AdvancedFiltersModal.Button.Cancel": "キャンセルする",
    "AdvancedFiltersModal.Button.ViewResults": "結果を見る",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "国を検索...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "国",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "サプライヤーの検索...",
    "AdvancedFiltersModal.Label.All Suppliers": "すべてのサプライヤー",
    "AdvancedFiltersModal.Label.New Suppliers": "新規サプライヤー",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "すべての配達日数",
    "AdvancedFilters.ShippingTime.One": "1〜3日",
    "AdvancedFilters.ShippingTime.Four": "4〜7日",
    "AdvancedFilters.ShippingTime.Eight": "8〜14日",
    "AdvancedFilters.ShippingTime.Fifteen": "15日以上",

    "AdvancedFilters.ShippingCost.Free": "送料無料",
    "AdvancedFilters.ShippingCost.Five": "5ドル以下の送料",
    "AdvancedFilters.ShippingCost.Fifteen": "15ドル以下の送料",
    "AdvancedFilters.ShippingCost.TwentyFive": "25ドル以下の送料",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "すべてクリア",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "発送",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "検索する",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "発送元",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "配送先",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "サプライヤー",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "並び替え",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "アイテム費用",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "トップサプライヤー",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "パーソナライズされた請求書",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "ベストセラー",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "プレミアム",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "{{from}} から {{to}} まで",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "キーワードを入力...",
    "Search.Header.Button.Search": "検索する",
    "Search.Header.Button.Filters": "フィルター",

    "Navigation.UpgradeButton.TryPro": "Spocket Proを無料で試す",
    "Navigation.UpgradeButton.TryEmpire": "Spocket EMPIREを無料で試す",
    "Navigation.UpgradeButton.TryUnicorn": "Spocket Unicornを無料で試す",

    "CategoriesCards.Label.MoreCategories": "その他のカテゴリー",

    "StaticCategories.WomensClothing": "婦人服",
    "StaticCategories.WomensClothing.Activewear": "アクティブウェア",
    "StaticCategories.WomensClothing.Bodysuits": "ボディスーツ",
    "StaticCategories.WomensClothing.Dresses": "ドレス",
    "StaticCategories.WomensClothing.JacketsCoats": "ジャケット＆コート",
    "StaticCategories.WomensClothing.Jeans": "ジーンズ",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "ジャンプスーツ＆ロンパース",
    "StaticCategories.WomensClothing.Leggings": "レギンス",
    "StaticCategories.WomensClothing.LingerieUnderwear": "ランジェリー＆下着",
    "StaticCategories.WomensClothing.MatchingSets": "マッチングセット",
    "StaticCategories.WomensClothing.Pants": "パンツ",
    "StaticCategories.WomensClothing.Shorts": "ショーツ",
    "StaticCategories.WomensClothing.Skirts": "スカート",
    "StaticCategories.WomensClothing.Sleepwear": "スリープウェア",
    "StaticCategories.WomensClothing.Socks": "ソックス",
    "StaticCategories.WomensClothing.SweatersHoodies": "セーター＆パーカー",
    "StaticCategories.WomensClothing.Swimwear": "スイムウェア",
    "StaticCategories.WomensClothing.TankTops": "タンクトップ",
    "StaticCategories.WomensClothing.TopsBlouses": "トップス＆ブラウス",
    "StaticCategories.WomensClothing.Tshirts": "Tシャツ",

    "StaticCategories.JewelryWatches": "ジュエリー＆時計",
    "StaticCategories.JewelryWatches.Bracelets": "ブレスレット",
    "StaticCategories.JewelryWatches.Earrings": "イヤリング",
    "StaticCategories.JewelryWatches.Necklaces": "ネックレス",
    "StaticCategories.JewelryWatches.OtherAccessories": "その他のアクセサリ",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "ペンダント、宝石、チャーム",
    "StaticCategories.JewelryWatches.Rings": "リング",
    "StaticCategories.JewelryWatches.Sets": "セット",
    "StaticCategories.JewelryWatches.Watches": "時計",

    "StaticCategories.TechAccessories": "家電アクセサリ",
    "StaticCategories.TechAccessories.AudioVideo": "オーディオ＆ビデオ",
    "StaticCategories.TechAccessories.CasesCovers": "ケース＆カバー",
    "StaticCategories.TechAccessories.Lighting": "照明",
    "StaticCategories.TechAccessories.MobileLaptopAccessories": "モバイル＆ラップトップアクセサリ",
    "StaticCategories.TechAccessories.Mousepads": "マウスパッド",
    "StaticCategories.TechAccessories.Novelty": "ノベルティ",

    "StaticCategories.KidsBabies": "キッズ＆ベビー",
    "StaticCategories.KidsBabies.BabyClothing": "ベビー服",
    "StaticCategories.KidsBabies.Bathing": "バス用品",
    "StaticCategories.KidsBabies.BlanketsPillows": "毛布＆枕",
    "StaticCategories.KidsBabies.CapsHeadbands": "帽子＆ヘッドバンド",
    "StaticCategories.KidsBabies.Footwear": "フットウェア",
    "StaticCategories.KidsBabies.Furniture": "家具",
    "StaticCategories.KidsBabies.KidsClothing": "子供服",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "子育て＆アクセサリー",

    "StaticCategories.Toys": "おもちゃ",
    "StaticCategories.Footwear": "フットウェア",
    "StaticCategories.Footwear.Boots": "ブーツ",
    "StaticCategories.Footwear.Flats": "フラットシューズ",
    "StaticCategories.Footwear.Heels": "ハイヒール",
    "StaticCategories.Footwear.Mens": "メンズ",
    "StaticCategories.Footwear.Sandals": "サンダル",
    "StaticCategories.Footwear.Slippers": "スリッパ",
    "StaticCategories.Footwear.SneakersRunners": "スニーカー＆ランニング",

    "StaticCategories.BathBeauty": "バス＆ビューティー",
    "StaticCategories.BathBeauty.Bodycare": "ボディケア",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "ディフューザー、オイル、キャンドル",
    "StaticCategories.BathBeauty.Haircare": "ヘアケア",
    "StaticCategories.BathBeauty.Healthcare": "ヘルスケア",
    "StaticCategories.BathBeauty.Makeup": "メイクアップ",
    "StaticCategories.BathBeauty.Nails": "ネイル",
    "StaticCategories.BathBeauty.Skincare": "スキンケア",
    "StaticCategories.BathBeauty.TowelsRobes": "タオル＆ローブ",

    "StaticCategories.Pets": "ペット用品",
    "StaticCategories.Pets.BedsBlankets": "ベッド＆毛布",
    "StaticCategories.Pets.LeashesCollarsPetwear": "リード、首輪、ペットウェア",
    "StaticCategories.Pets.Petcare": "ペットケア",
    "StaticCategories.Pets.Toys": "おもちゃ",

    "StaticCategories.HomeGarden": "ホーム＆ガーデン",
    "StaticCategories.HomeGarden.Bathroom": "バスルーム",
    "StaticCategories.HomeGarden.Clocks": "置き時計",
    "StaticCategories.HomeGarden.Drinkware": "ドリンクウェア",
    "StaticCategories.HomeGarden.FoodBeverage": "食品＆飲料",
    "StaticCategories.HomeGarden.Furniture": "家具",
    "StaticCategories.HomeGarden.Garden": "ガーデン",
    "StaticCategories.HomeGarden.HomeDecor": "ホームデコ",
    "StaticCategories.HomeGarden.HomeImprovement": "ホームインプルーブメント",
    "StaticCategories.HomeGarden.Kitchen": "キッチン",
    "StaticCategories.HomeGarden.Lighting": "照明",
    "StaticCategories.HomeGarden.Organization": "整理・収納",
    "StaticCategories.HomeGarden.StationeryCrafts": "文房具＆クラフト",
    "StaticCategories.HomeGarden.TextilesPillows": "テキスタイル＆枕",
    "StaticCategories.HomeGarden.Tools": "ツール",

    "StaticCategories.Accessories": "アクセサリー",
    "StaticCategories.Accessories.Belts": "ベルト",
    "StaticCategories.Accessories.Cufflinks": "カフスボタン",
    "StaticCategories.Accessories.Hats": "帽子",
    "StaticCategories.Accessories.Keychains": "キーホルダー",
    "StaticCategories.Accessories.ScarvesBandanas": "スカーフ＆バンダナ",
    "StaticCategories.Accessories.Sunglasses": "サングラス",

    "StaticCategories.MensClothing": "紳士服",
    "StaticCategories.MensClothing.Activewear": "アクティブウェア",
    "StaticCategories.MensClothing.DressShirts": "ドレスシャツ",
    "StaticCategories.MensClothing.JacketsCoats": "ジャケット＆コート",
    "StaticCategories.MensClothing.Pants": "パンツ",
    "StaticCategories.MensClothing.Shorts": "ショーツ",
    "StaticCategories.MensClothing.Socks": "ソックス",
    "StaticCategories.MensClothing.SweatersHoodies": "セーター＆パーカー",
    "StaticCategories.MensClothing.Swimwear": "スイムウェア",
    "StaticCategories.MensClothing.TankTops": "タンクトップ",
    "StaticCategories.MensClothing.Tshirts": "Tシャツ",
    "StaticCategories.MensClothing.Underwear": "下着",

    "StaticCategories.SportsOutdoors": "スポーツ＆アウトドア",
    "StaticCategories.SportsOutdoors.Activewear": "アクティブウェア",
    "StaticCategories.SportsOutdoors.Biking": "自転車",
    "StaticCategories.SportsOutdoors.Camping": "キャンプ",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "機器＆アクセサリー",
    "StaticCategories.SportsOutdoors.Fishing": "釣り",

    "StaticCategories.Gifts": "ギフト",
    "StaticCategories.Gifts.Adults": "アダルト",
    "StaticCategories.Gifts.Funny": "ジョーク",
    "StaticCategories.Gifts.Mugs": "マグカップ",
    "StaticCategories.Gifts.Novelty": "ノベルティ",
    "StaticCategories.Gifts.Personalized": "パーソナライズ",
    "StaticCategories.Gifts.Religious": "信仰・宗教",

    "StaticCategories.Automotive": "自動車",
    "StaticCategories.BagsWallets": "バッグ＆財布",
    "StaticCategories.Seasonal": "季節物",
    "StaticCategories.Seasonal.Christmas": "クリスマス",
    "StaticCategories.Seasonal.FathersDay": "父の日",
    "StaticCategories.Seasonal.Halloween": "ハロウィーン",
    "StaticCategories.Seasonal.MothersDay": "母の日",

    "StaticCategories.FestivalsParties": "フェスティバル＆パーティー",
    "StaticCategories.FestivalsParties.PartySupplies": "パーティー用品",
    "StaticCategories.FestivalsParties.Wedding": "ウェディング",

    "QuickFilters.Label.Premium": "プレミアム",
    "QuickFilters.Label.FastUSAShipping": "迅速な米国発送",
    "QuickFilters.Label.Under5": "5ドル未満の米国の送料",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "並び替え",
    "SortBy.DropDown.Item.Relevance": "関連",
    "SortBy.DropDown.Item.PriceLowToHigh": "価格：低から高",
    "SortBy.DropDown.Item.PriceHighToLow": "価格：高から低",
    "SortBy.DropDown.Item.Newest": "最新",
    "SortBy.DropDown.Item.ShippingTime": "配送時間",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "ホーム",
    "Breadcrumb.Item.Search": "検索",

    "UpgradeModal.Modal.Title": "料金プランを選択する",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "毎月",
    "Plans.ToggleSection.Yearly": "毎年",
    "Plans.ToggleSection.DiscountInitial": "まで取得",
    "Plans.ToggleSection.DiscountSingular": "ヶ月",
    "Plans.ToggleSection.DiscountPlural": "ヶ月",
    "Plans.ToggleSection.DiscountFinal": "OFF",
    "Plans.ProrationCost.TextInitial": "あなたは現在、支払いをしています",
    "Plans.ProrationCost.TextFinal": "/年追加のみでエンパイアにアップグレード",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", // English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "販売を開始",
    "Card.DescriptionVariantType.Professional": "販売を伸ばす",
    "Card.DescriptionVariantType.Empire": "販売を最大化する",
    "Card.DescriptionVariantType.Unicorn": "大規模な販売管理",
    "Card.DescriptionVariantType.Period": "月",
    "Card.DescriptionVariantType.PricePeriod": "/月",

    "Card.DescriptionVariantType.AnnualDescription": "毎年{{value}}ドル請求されます",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "あなたの現在のプラン",
    "Card.DescriptionVariantType.Button.Start": "今すぐはじめる",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "ブランドインボイス",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "売れ筋商品",
    "Card.DescriptionVariantType.SupplierSourcing": "サプライヤーの調達",
    "Card.DescriptionVariantType.ProductRequests": "製品のリクエスト",
    "Card.DescriptionVariantType.BulkCheckout": "一括チェックアウト",
    "Card.DescriptionVariantType.Special": "スペシャル",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Spocketのサブスクリプションが終了しました。",
    "ReactivationBanner.InfoSubtitle": "再開します",
    "ReactivationBanner.CheckList.HighQuality": "高品質",
    "ReactivationBanner.CheckList.Curated": "キュレーション",
    "ReactivationBanner.CheckList.FastShipping": "迅速な配送",
    "ReactivationBanner.CheckList.FromUSAndEU": "米国と欧州から",
    "ReactivationBanner.TextByPlan.Starter.Description": "<1>製品のプッシュ</1>ができなくなります",
    "ReactivationBanner.TextByPlan.Starter.Title": "Spocket アカウント",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "今すぐストアに商品をプッシュしましょう",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "<1>プッシュ製品</1>をライブまたは使用することができなくなります ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "プレミアム製品",
    "ReactivationBanner.TextByPlan.Others.Title": "プレミアムアカウント",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "以下のプレミアム商品にアクセスできます",
    "ReactivationBanner.Button": "今すぐアカウントを再開する",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "より多くの製品をプッシュ",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "無制限のオーダー",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "ブランドインボイス",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "プレミアム製品",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "お得な情報",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "チャット＆コールサポート",
    "UpgradeReasonModal.Modal.Title": "Spocketで実現したいことは何ですか？",
    "UpgradeReasonModal.Modal.Question": "アップグレードした理由は何ですか？",
    "UpgradeReasonModal.Modal.Button": "Spocket {{plan}}の使用を開始する",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "お客様の体験を向上させることができます",

    "UpgradeSuccessModal.Col.One.A": "おめでとうございます。",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "あなたはドロップシッピングビジネスを成功させるのに必要なすべてのツールのロックを解除しました。",
    "UpgradeSuccessModal.Col.Two.B": "今なら入手可能 ",
    "UpgradeSuccessModal.Col.Two.C": " ハッピー Spocketing!",
    "UpgradeSuccessModal.Button": "続ける",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1ヶ月",
    "Promotion.TrialDaysNormalizer.28days": "4週間",
    "Promotion.TrialDaysNormalizer.21days": "3週間",
    "Promotion.TrialDaysNormalizer.14days": "2週間",
    "Promotion.TrialDaysNormalizer.7days": "1週間",
    "Promotion.TrialDaysNormalizer.1day": "1日",
    "Promotion.TrialDaysNormalizer.Days": "{{value}}日間",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "無料でお試しください",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "今すぐお試しください→",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "言語",

    "WhatYouWillLoseModal.Button.Offer": "続ける",
    "WhatYouWillLoseModal.Button.Downgrade": "見切りを付けて、アカウントをダウングレードします",
    "WhatYouWillLoseModal.ProductSection.Title":
      "<1>{{product_count}}つのインポートされた製品</1>はすべて失われます",
    "WhatYouWillLoseModal.Product.PremiumTag": "プレミアム",
    "WhatYouWillLoseModal.Product.SkeletonImage": "{{product_count}} 以上",
    "WhatYouWillLoseModal.ModalWrapper.Header": "もうおやめになりますか？",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "50％OFFをゲット",
    "WhatYouWillLoseModal.ModalWrapper.Title": "これから3ヶ月間、起業の旅を続けましょう",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "新たな成功者になってください。すでに多くの時間を投資していらっしゃいますね。その調子で続けましょう。みんなであなたを応援しています。Spocket チーム",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "おめでとうございます！ これから3ヶ月間、プランに50％割引が追加されました!",
    "FiftyOff3MonthsOfferSuccessModal.Button": "続ける",

    "DowngradeReasonModal.DowngradeSection": "どうすればSpocketをもっと良く使えるようになるのでしょうか？",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "どんなことでも構いません。ご意見・ご感想をお願いします（100文字以上）",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "ご意見・ご感想をご記入ください（100文字以上）",
    "DowngradeReasonModal.Button.Downgrade": "ダウングレードする",
    "DowngradeReasonModal.ModalWrapper.Header": "{{storeName}}をダウングレードしますか？",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "お問い合わせ",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "キャンセルする",

    "DowngradeSuccessModal.Header": "ダウングレード成功",
    "DowngradeSuccessModal.Body":
      "ご意見ありがとうございます。私たちは日々Spocketの改善に努めておりますが、またの機会を楽しみにしております！",

    "Settings.title": "設定",
    "Settings.buttonSaveChanges": "変更内容を保存する",
    "Settings.buttonSaveAndPreview": "保存してプレビューする",
    "Settings.PricingPlans.Title": "料金プラン",

    "Settings.MenuTab.Plans": "プラン",
    "Settings.MenuTab.Account": "アカウント",
    "Settings.MenuTab.BrandedInvoicing": "ブランドインボイス",
    "Settings.MenuTab.Billing": "請求",
    "Settings.MenuTab.GlobalPricingRules": "グローバルな価格設定ルール",
    "Settings.MenuTab.Security": "セキュリティ",

    "AccountTab.documentTitle": "設定 - アカウント - Spocket",
    "AccountTab.title": "アカウントの設定",
    "AccountTab.shopName": "ショップ名",
    "AccountTab.email": "Eメール",
    "AccountTab.shopUrl": "ショップのURL",
    "AccountTab.defaultCurrency": "デフォルトの通貨",
    "AccountTab.yourPlan": "あなたのプラン",
    "AccountTab.accountNotActive": "あなたのアカウントは現在アクティブではありません",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "この機能はまもなく追加されます！今はひとまず、Spocket Shopifyアプリから接続してみてください！",
    "AccountTab.alertAccountSettingsUpdated": "アカウント設定が更新されました",
    "AccountTab.alertInvalidShopUrl": "無効なショップURL",
    "AccountTab.productsImported": "インポートされた製品",
    "AccountTab.products": "製品",
    "AccountTab.premiumProducts": "プレミアム製品",
    "AccountTab.total": "合計",
    "AccountTab.signOut": "サインアウト",
    "AccountTab.limitReachedProductsAndPremium": "製品・プレミアム製品が上限に達しました。",
    "AccountTab.limitReachedProducts": "製品の制限に達しました。",
    "AccountTab.limitReachedPremium": "プレミアム製品の制限に達しました。",
    "AccountTab.buttonSwitchToAnnualPlan": "年間プランに切り替える",
    "AccountTab.off30Percent": "30% OFF",
    "AccountTab.off40Percent": "40% OFF",
    "AccountTab.off45Percent": "45% OFF",
    "AccountTab.sellMoreWithEmpirePlan": "エンパイアプランでもっと売る",
    "AccountTab.tooltipCurrency":
      "お客様の通貨はストアアカウントで管理されています。インポートした製品の価格はすべてその通貨に換算されます。",
    "AccountTab.shopNotConnectedYet": "あなたのショップはこのアカウントとはまだ接続していません。",
    "AccountTab.connectYourShop": "ショップを接続する",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "設定 - 請求書 - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "請求書の設定が保存されました！",
    "InvoiceTab.shopLogo": "ショップロゴ",
    "InvoiceTab.contactEmail": "連絡先メールアドレス",
    "InvoiceTab.phoneNumber": "電話番号",
    "InvoiceTab.personalNote": "個人的なメモ",
    "InvoiceTab.tooltipPersonalNote": "ブランド化された各請求書に含まれる個人的なメモを設定します",
    "InvoiceTab.brandedInvoicing": "ブランドインボイス",
    "InvoiceTab.tooltipBrandedInvoicing":
      "有料プランをご利用の場合、ブランドのロゴと個人的なメモを、ブランドの請求書を提供しているサプライヤーからのすべての注文に追加します",
    "InvoiceTab.disabledInvoicing": "すべての注文にブランド化された請求書を含める",
    "InvoiceTab.tooltipDisabledInvoicing":
      "このオプションをオフにすると、注文時にブランド化された請求書が除外されます。",

    "PricingTab.titleDefaultPricingRules": "デフォルトの価格設定ルール",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "高度な価格設定ルール",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "数値を割り当てる",
    "PricingTab.tooltipAssignCents":
      "この値は、商品の最終価格を表示する際に使用されます（例えば、商品の価格をXX.99に設定したい場合は、以下のフィールドに99を追加します）。",
    "PricingTab.Markup": "利幅",
    "PricingTab.MarkupType": "利幅タイプ",
    "PricingTab.SelectDefault": " --  一つ選択してください  -- ",
    "PricingTab.SelectPercent": "パーセント",
    "PricingTab.SelectMultiplier": "乗数",
    "PricingTab.SelectFixed": "固定の",
    "PricingTab.titlePricingRules": "設定 - 価格設定ルール - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "高度な価格設定ルールを切り替える",
    "PricingTab.from": "から",
    "PricingTab.to": "まで",
    "PricingTab.selectFixedAmount": "定額",
    "PricingTab.buttonRemove": "削除する",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "空のフィールドで複数のルールを作成することはできません",
    "PricingTab.costRange": "コスト範囲",
    "PricingTab.markup": "利幅",
    "PricingTab.add": "追加",

    "SecurityTab.Document.Title": "設定 - セキュリティ - Spocket",
    "SecurityTab.alertIncorrectPassword": "パスワードが正しくありません",
    "SecurityTab.title": "セキュリティ",
    "SecurityTab.yourNewPassword": "新しいパスワード",
    "SecurityTab.repeatPassword": "パスワードを再度入力してください",
    "SecurityTab.errorPleaseEnterAPassword": "パスワードを入力してください。",
    "SecurityTab.errorPasswordsConfirmationNeeded": "パスワードの確認を入力してください。",
    "SecurityTab.errorPasswordsDoNotMatch": "パスワードが一致していません",
    "SecurityTab.errorPasswordShort": "パスワードは8文字以上である必要があります",

    "ListingCards.TopSupplierTag.Tooltip":
      "トップサプライヤーは、高品質の製品、優れたサービス、高い評価で知られています",
    "ListingCards.TopSupplierTag.Text": "サプライヤー",

    "ListingCard.OriginCountry.Intro": "まで",
    "ListingCard.OriginCountry.Tooltip": "発送元",
    "ListingCard.Shipping.Price.Title": "配送料",
    "ListingCard.Shipping.Price.Free": "無料",
    "ListingCard.Price.Intro": "リスティング費用",
    "ListingCard.Price.Retail": "小売価格",
    "ListingCard.Shipping.Time.Title": "配送時間",
    "ListingCard.Shipping.Time.Period": "数日間",
    "ListingCard.PremiumIcon.Tooltip": "独占的で、割引率が高く、出荷が迅速な商品を売ってください",
    "ListingCard.PremiumIcon.Text": "プレミアム",
    "ListingCard.ListButton.Import": "インポートリストに追加する",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "ストアにて販売する",
    "ListingCard.StatusTag.Imported.Title": "輸入品",
    "ListingCard.StatusTag.Imported.Text": "この品目は輸入品目録にすでに存在します",
    "ListingCard.StatusTag.Pushed.Title": "ストアに入庫",
    "ListingCard.StatusTag.Pusehd.Text": "この品目はストアにプッシュされました",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "製品説明",
    "ListingModal.DetailsSection.Button.ProductVariations": "製品バリエーションを開く",
    "ListingModal.DetailsSection.Button.OrderSamples": "サンプルを注文する",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "NEW",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "この製品では、ブランド化された請求書の発行は利用できません",
    "ListingModal.DetailsSection.InternationalShipping.Alert": "海外オーダーは中国の倉庫から発送されます",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "米国の倉庫で在庫切れの商品は、中国の倉庫から発送される場合があります",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "ご注文の全体的な配送時間を短縮するため、複数のパッケージで送信される場合があります",
    "ListingModal.DetailsSection.ConnectStore.Alert": "ストアを接続してオーダーする",
    "ListingModal.DetailsSection.PushedListButton": "ストアにて販売する",
    "ListingModal.DetailsSection.RemoveListButton": "リストから削除する",
    "ListingModal.DetailsSection.ImportListButton": "インポートリストに追加する",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "リストを非アクティブ化する",

    "InfoSection.ShippingTime.Tooltip": "発送後のお届けまでの日数",
    "InfoSection.ShippingTime.Title": "配送時間",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "ワールドワイド",
    "InfoSection.ShippingExcluded.Intro": "次の国には発送されません",
    "InfoSection.ShippingExcluded.Various": "各種",
    "InfoSection.ProcessingTime.Tooltip": "サプライヤーが出荷し、追跡番号を提供するまでの営業日数",
    "InfoSection.ProcessingTime.Title": "処理時間",
    "InfoSection.ProcessingTime.Period": "営業日",
    "InfoSection.ShippingInfoLine.Tooltip":
      "この商品は{{price_formatted}}で出荷され、同じ注文で追加の商品を注文するたびに{{incremental_price_formatted}}が加算されます。",
    "InfoSection.ShippingInfoLine.Period": "営業日",
    "InfoSection.ShippingInfoLine.Free": "無料",
    "InfoSection.ShippingInfoLine.NoShipping": "出荷されません",

    "ReturnPolicySection.Title": "返品ポリシー",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "から",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "まで",
    "TitleSection.PriceMSRP.Listing": "表示価格",
    "TitleSection.PriceMSRP.Retail": "小売価格",

    "ProductVariationsModal.Title": "製品バリエーション",
    "ProductVariationsModal.Table.Image": "画像",
    "ProductVariationsModal.Table.Inventory": "在庫",
    "ProductVariationsModal.Table.Price": "価格",
    "ProductVariationsModal.ImageSpecifications.Title": "サイズ表",
    "ProductVariationsModal.ImageSpecifications.Button": "ダウンロード",

    "TopSupplier.Tooltip": "トップサプライヤーは、高品質の製品、優れたサービス、高い評価で知られています",
    "TopSupplier.Text": "トップサプライヤー",

    "BestSelling.Tooltip.Top": "これはSpocketで最高のパフォーマンスを発揮する製品です",
    "BestSelling.Tooltip.Bottom": "ベストセラー",

    "GallerySection.PremiumIcon.Tooltip": "独占的で、割引率が高く、出荷が迅速な商品を売ってください",
    "GallerySection.PremiumIcon.Text": "プレミアム",
    "GallerySection.DiscountStamp.Text": "さらに{{value}}％OFF",

    "Upgrade.Title.AnnualToggled": "今すぐSpocketプレミアムを入手",
    "Upgrade.Title.MonthlyToggled": "Spocketプレミアムを14日間無料で試す",
    "Upgrade.Subtitle":
      "今すぐSpocketプレミアムプランを試して、米国とヨーロッパからの高コンバージョン商品をストアに追加してみましょう。",
    "Upgrade.Button": "Spocketプレミアムを使わずに続ける",

    "Search.NoProductsFound.Title": "申し訳ありません！結果は見つかりませんでした",
    "Search.NoProductsFound.For": "次の条件では",
    "Search.NoProductsFound.Description":
      "スペルを確認し、関連する検索用語またはより一般的な検索用語を試してみてください。",
    "Search.Searching.Title": "商品を探しています...",
    "Search.Searching.Description": "最新の製品がロードされるまでお待ちください。",

    "Suppliers.StarRating.Tooltip": "サプライヤーのフルフィルメント成功率",
    "Suppliers.RefundPolicy": "サプライヤーの返金ポリシー",
    "Suppliers.Joined.Title": "Spocket に参加しました",
    "Suppliers.Origin.Title": "国からの発送",
    "Suppliers.Time.Title": "平均処理時間",
    "Suppliers.HasMore.Title": "商品を探しています...",
    "Suppliers.HasMore.Subtitle": "最新の製品がロードされるまでお待ちください。",
    "Suppliers.TopSupplierTag.Tooltip":
      "トップサプライヤーは、高品質の製品、優れたサービス、高い評価で知られています",
    "Suppliers.TopSupplierTag.Text": "トップサプライヤー",

    "AltTextModal.Header.Title": "画像の詳細を編集する",
    "AltTextModal.Body.Description":
      "この画像の簡単な説明を書いて、視覚障害のある顧客の検索エンジン最適化（SEO）とアクセシビリティを改善します。",
    "AltTextModal.Input.Label": "代替テキスト",
    "AltTextModal.Input.Placeholder": "画像代替テキスト",
    "AltTextModal.Button.Cancel": "キャンセルする",
    "AltTextModal.Button.Save": "保存する",

    "WistiaModal.WhatIsSpocket.Title": "Spocketとは?",
    "WistiaModal.WhatIsSpocket.Text":
      "Spocketがどのようにビジネスの構築に役立つかを学びましょう。Spocketの仕組みを理解し、どのように活用すればいいのかを理解しましょう。",
    "WistiaModal.WhatIsSpocket.Button": "製品の検索を開始する",
    "WistiaModal.DiscoverProducts.Title": "製品の紹介",
    "WistiaModal.DiscoverProducts.Text":
      "フィルタを適切に利用して、適切な商品を見つけ、お店に加えてください。",
    "WistiaModal.DiscoverProducts.Button": "製品の検索を開始する",
    "WistiaModal.ProductCustomization.Title": "製品のカスタマイズ",
    "WistiaModal.ProductCustomization.Text":
      "インポートリストを使用して、ストアに合わせて商品を変更します。商品説明の編集、価格の設定、商品をコレクションに追加するほか、画像を削除することができます。",
    "WistiaModal.ProductCustomization.Button": "インポートリストに移動する",
    "WistiaModal.OrderProcessing.Title": "自動オーダー処理",
    "WistiaModal.OrderProcessing.Text":
      "お客様のご注文を満たし、ワンクリックでサプライヤがお客様の玄関先まで製品をお届けします。",
    "WistiaModal.OrderProcessing.Button": "オーダーに移動する",
    "WistiaModal.Settings.Title": "設定",
    "WistiaModal.Settings.Text":
      "設定ページでは、すべてのアカウント情報の管理や請求書のブランド化、クレジットカードの追加を行うことができます。すべての製品の価格設定ルールを調整し、サブスクリプションプランを選択することもできます。",
    "WistiaModal.Settings.Button": "アカウントを設定する",

    "Footer.Copyright": "Copyright {{year}}, Spocket無断複写・転載を禁じます",
    "Footer.PrivacyPolicy": "個人情報保護方針",
    "Footer.TermsConditions": "ご利用規約",
    "Footer.DataProcessingAddendum": "データ処理覚書",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    

    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
    
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English
    
    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
