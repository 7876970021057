export default {
  translation: {
    "GenericError": "There was an error processing the request. Please contact customer support.", // English
    
    "OrderList.Header.Title": "Mi lista de pedidos",
    "OrderList.Input.Search": "Número de pedido...",
    "OrderList.Label.Status": "Filtrar por estado en Spocket",

    "order.status.unpaid": "Sin pagar",
    "order.status.paid": "Pagado",
    "order.status.processing": "Procesando",
    "order.status.shipped": "Enviado",
    "order.status.cancelled": "Cancelado",
    "order.status.pending": "Pendiente",
    "order.status.partially_paid": "Pagado parcialmente",
    "order.status.partially_refunded": "Reembolsado parcialmente",
    "order.status.refunded": "Reembolsado",
    "order.status.voided": "Inválido",
    "order.status.authorized": "Autorizado",
    "order.status.deleted": "Eliminado",
    "order.status.pending_payment_confirmation": "Pendiente",

    "OrderList.Label.DownloadHistory": "Descargar archivo CSV con todo el historial de pedidos",
    "OrderList.Document.Title": "Pedidos - Spocket",
    "OrderList.EmptyOrders.Link.SearchProducts": "Buscar productos",
    "OrderList.EmptyOrders.Tip.HowToProcessOrders": "Cómo procesar los pedidos",
    "OrderList.EmptyOrders.Message.Loading.Title": "Estamos cargando tus pedidos.",
    "OrderList.EmptyOrders.Message.Loading.Description": "Por favor espera mientras buscamos tus pedidos.",
    "OrderList.EmptyOrders.Message.Title": "¡Tu lista de pedidos está vacía!",
    "OrderList.EmptyOrders.Message.Description":
      "Puedes gestionar y procesar tus pedidos aquí. Visita la página de búsqueda para comenzar a agregar productos a tu lista de importación.",
    "OrderList.CheckBox.SelectAllOrders": "Seleccionar todos los pedidos",
    "OrderList.CheckBox.SelectedXOrders": "{{count}} pedido seleccionado",
    "OrderList.CheckBox.SelectedXOrders_plural": "{{count}} pedido seleccionado",
    "OrderList.CheckBox.BulkCheckoutOrders": "Finalización en bloque del pedido",
    "OrderList.CheckBox.BulkCheckoutOrders_plural": "Finalización en bloque de los pedidos",

    "AfterConfirmCheckoutOrderModal.Title": "Bulk Checkout Confirmation", // English
    "AfterConfirmCheckoutOrderModal.Subtitle": "Your orders are being processed.", // English
    "AfterConfirmCheckoutOrderModal.Description": "Your order statuses will update shortly, and tracking numbers will be added when the orders have been fulfilled.", // English

    "RefreshModal.Button.SynchronizeOrders": "Sincronizar pedidos",
    "RefreshModal.Button.Cancel": "Cancelar",
    "RefreshModal.Button.Synchronize": "Sincronizar",
    "RefreshModal.Modal.Body":
      "Los pedidos se sincronizan automáticamente desde tu tienda. Solo se sincroniza si la información del pedido no aparece o no se ha actualizado. Actualiza la página después de 30 segundos.",

    "Order.Label.OrderNumber": "Número de pedido",
    "Order.Label.Date": "Fecha",
    "Order.Label.OrderStatus": "Estado del pedido {{integrationName}}",
    "Order.Table.Th.Product": "Producto",
    "Order.Table.Th.Qty": "CANT.",
    "Order.Table.Th.UnitPrice": "Precio por unidad",
    "Order.Table.Th.CheckoutDate": "Fecha de finalización",
    "Order.Table.Th.Action": "Acción",

    "MessageSupplierModal.Title": "Message your supplier directly!", // English
    "MessageSupplierModal.Description": "This exciting new feature is available as an add on across all the plans. Learn more about this feature and upgrade now to chat directly with your Suppliers!", // English
    "MessageSupplierModal.ModalButton.Title": "Communicate with supplier", // English
    "MessageSupplierModal.ModalButton.Label": "Communicate with Supplier", // English

    "SupplierWithoutChatModal.Title": "This supplier does not support the Chat feature, yet.", // English
    "SupplierWithoutChatModal.Description1": "Oh no! This supplier has not been set up to support the Chat feature yet. But rest assured that we are trying our best to get the supplier on board!", // English
    "SupplierWithoutChatModal.Description2": "For now, you can contact our <span>Customer Support</span> team for high-priority support for order inquiries for this supplier.", // English
    "SupplierWithoutChatModal.CancelButton.Title": "Cancel", // English
    "SupplierWithoutChatModal.CancelButton.Label": "Cancel", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Title": "Talk to customer support", // English
    "SupplierWithoutChatModal.TalkToSupportButton.Text": "Talk to Customer Support", // English

    "CustomerModal.Button.ViewCustomerInfo": "Ver info. del cliente",
    "CustomerModal.Modal.Title.CustomerInfo": "Info. del cliente",
    "CustomerModal.Modal.Body.CustomerInfo": "Info. del cliente",
    "CustomerModal.Modal.Body.Address": "Dirección",

    "OrderLine.Button.Checkout": "Finalizar pedido",
    "OrderLine.Tooltip.PreviewInvoice": "Vista previa de la factura",
    "OrderLine.Tooltip.PaymentReceipt": "Recibo de pago",
    "OrderLine.Tooltip.TrackOrder": "Seguimiento del pedido",
    "OrderLine.Tooltip.OrderProcessing": "Procesamiento del pedido",
    "OrderLine.Tooltip.OrderCancelled": "Pedido cancelado",
    "OrderLine.Tooltip.PleaseConfirm": "Haz clic para confirmar el pago de tu pedido",
    "OrderLine.Label.PurchaseEtsy": "Comprar en Etsy",
    "OrderLine.Paying": "Pagando",
    "OrderLine.Failure": "Fallo",
    "OrderLine.Sample": "Muestra",
    "OrderLine.ErrorOrderRow":
      "No se puede encontrar la lista solicitada en el sistema. Ponte en contacto con el servicio de ayuda.",

    "ConfirmationModal.Label.OrderDetails": "Detalles del pedido",
    "ConfirmationModal.Label.Total": "Total",
    "ConfirmationModal.Label.Shipping": "Envío",
    "ConfirmationModal.Label.TransactionFees": "Costes de la transacción",
    "ConfirmationModal.Tooltip.TransactionFees":
      "Los costes de la transacción incluyen las tarifas que cobran Stripe, el proveedor de pagos de Spocket, así como también las tarifas por servicio.",
    "ConfirmationModal.Modal.OrderConfirmation": "Confirmación del pedido",
    "ConfirmationModal.Modal.Alert.Attention": "¡Atención!",
    "ConfirmationModal.Modal.Alert.Description": "Este pedido contiene una nota del cliente.",
    "ConfirmationModal.Modal.YoureAboutToPlaceOrder": "Estás a punto de realizar un pedido para",
    "ConfirmationModal.Modal.Table.Th.Name": "Nombre",
    "ConfirmationModal.Modal.Table.Th.Price": "Precio",
    "ConfirmationModal.Modal.Table.Th.Qty": "CANT.",
    "ConfirmationModal.Modal.Table.Th.Total": "Total",
    "ConfirmationModal.Modal.Label.Note": "Nota al proveedor",
    "ConfirmationModal.Modal.PlaceHolder.Note": "Tu mensaje",
    "ConfirmationModal.Button.PlaceOrder": "Realizar pedido",

    "PaymentCompleteModal.PaymentCompleted": "Pago completado",
    "PaymentCompleteModal.PaymentCompleted.Note":
      "Gracias por el pago. Hemos notificado al proveedor y tu pedido se procesará a la brevedad. Actualizaremos el estado del pedido y agregaremos el número de seguimiento en la página de tus pedidos.",
    "PaymentCompleteModal.PaymentIncomplete": "¡Tu pedido está incompleto!",
    "PaymentCompleteModal.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Tu banco requiere que confirmes tu pago para proceder",
    "PaymentCompleteModal.PaymentIncomplete.ConfirmationLink": "Usa este enlace para confirmar el pago.",
    "PaymentCompleteModal.PaymentIncomplete.Note":
      "Una vez que el pago esté confirmado, se notificará al proveedor y procesaremos tu pedido como lo hacemos habitualmente. Las noticias sobre el estado y el seguimiento se agregarán a la página de tus pedidos. Vuelve a cargar la página después de la confirmación.",

    "SetYourGoalsModal.Title": "Número de productos agregados a la tienda",
    "SetYourGoalsModal.Subtitle": "Una tienda típica promociona 25 productos antes de obtener ventas",
    "SetYourGoalsModal.DropdownItem.Product": "{{count}} producto",
    "SetYourGoalsModal.DropdownItem.Product_plural": "{{count}} productos",
    "SetYourGoalsModal.Button": "Establecer mi objetivo y comenzar con el dropshipping",

    "YourGoalsBanner.Container.Description": "Tus objetivos",
    "YourGoalsBanner.Header.Title": "Comenzar una empresa",
    "YourGoalsBanner.Header.Subtitle": "Prepara tu tienda",
    "YourGoalsBanner.Progress.Completed": "Completados",
    "YourGoalsBanner.ProductPushedCount": "<0>Objetivo: {{goalsNumberOfProduct}}</0> productos promocionados",
    "YourGoalsBanner.CheckPoints.JoinSpocket": "Únete a Spocket",
    "YourGoalsBanner.CheckPoints.FirstSearch": "Primera búsqueda",
    "YourGoalsBanner.CheckPoints.ProductsPushed":
      "{{count}} / {{goalsNumberOfProduct}} producto promocionado",
    "YourGoalsBanner.CheckPoints.ProductsPushed_plural":
      "{{count}} / {{goalsNumberOfProduct}} productos promocionados",
    "YourGoalsBanner.CheckPoints.ProductsImported": "{{count}} / {{goalsNumberOfProduct}} producto importado",
    "YourGoalsBanner.CheckPoints.ProductsImported_plural":
      "{{count}} / {{goalsNumberOfProduct}} productos importados",

    "Plan.Starter": "Starter",
    "Plan.Professional": "Professional",
    "Plan.Empire": "Empire",
    "Plan.Unicorn": "Unicorn",

    "Aliscraper.Title": "Dropshipping automatizado de AliExpress",
    "Aliscraper.Subtitle":
      "Importa miles de productos de AliExpress a tu tienda de eCommerce con un solo clic y automatiza el dropshipping.",
    "Aliscraper.Features.Feature1": "Importación rápida con un solo clic",
    "Aliscraper.Features.Feature2": "Procesa cientos de pedidos de manera instantánea con Spocket",
    "Aliscraper.Features.Feature3": "Servicio de atención al cliente 24/7",
    "Aliscraper.Button": "Descarga la extensión",
    "Aliscraper.Chrome": "Disponible en la tienda web de Chrome",

    "AliscraperPage.Title": "AliScraper", // English
    "AliscraperPage.HeroTitle": "<h1>Automate <span>AliExpress</span> Dropshipping for <span>100% FREE</span></h1>", // English
    "AliscraperPage.HeroSubtitle": "Import thousands of AliExpress dropshipping products instantly. Filter shipping destination and carrier with one click. Save time, and focus on building your store and brand.", // English
    "AliscraperPage.ButtonCTA": "Download AliScraper Free", // English
    "AliscraperPage.WebStoreText": "Available in the Chrome Web Store", // English
    "AliscraperPage.InstallCount": "50,000+ installs", // English
    "AliscraperPage.Reviews": "(155 reviews)", // English
    "AliscraperPage.VideoTitle": "<h2>See <span>AliScraper</span> in Action 🚀</h2>", // English
    "AliscraperPage.HowItWorks.Title": "How it works", // English
    "AliscraperPage.HowItWorks.Install": "Install", // English
    "AliscraperPage.HowItWorks.Step1Installed": "<p><span>Step 1.</span> Open AliExpress then go to extensions and click on AliScraper.</p>", // English
    "AliscraperPage.HowItWorks.Step1": "<p><span>Step 1.</span> Click on the install button to get the browser extension.</p>", // English
    "AliscraperPage.HowItWorks.Step2": "<p><span>Step 2.</span> Login with your Spocket account.</p>", // English
    "AliscraperPage.HowItWorks.Step3": "<p><span>Step 3.</span> Open AliExpress Product, Category or Search page.</p>", // English
    "AliscraperPage.HowItWorks.Step4": "<p><span>Step 4.</span> Click on the Import to Spocket button on Product, Category or Search page</p>", // English
    "AliscraperPage.HowItWorks.Enjoy": "<p><span>Enjoy.</span> Now your products are imported to Spocket 🎉.</p>", // English
    "AliscraperPage.Installed": "You have successfully installed AliScraper", // English
    "AliscraperPage.HeroTitleInstalled": "<h1>Start <span>Importing products</span> from AliExpress with AliScraper</h1>", // English
    "AliscraperPage.AliExpressLink": "Go to AliExpress", // English

    "SpocketLabs.Title": "Beauty & Cosmetics Private Label", // English
    "SpocketLabs.TopPartner": "Spocket Top Partner", // English
    "SpocketLabs.HeroTitle": "Build your own <1>beauty</1> empire", // English
    "SpocketLabs.HeroSubtitle": "Dropship high quality, 100% natural, vegan beauty products with Jubilee.", // English
    "SpocketLabs.PrivateLabelPackaging": "Private Label Packaging", // English
    "SpocketLabs.NoMinimumOrder": "No Minimum Order Quantity", // English
    "SpocketLabs.FastShipping": "Fast US <1>US</1> Shipping", // English
    "SpocketLabs.ButtonCTA": "Try Jubilee for FREE", // English
    "SpocketLabs.HowItWorks.Title": "How it works", // English
    "SpocketLabs.HowItWorks.Step1": "Install the app", // English
    "SpocketLabs.HowItWorks.Step2": "Upload your logo and brand", // English
    "SpocketLabs.HowItWorks.Step3": "Find beauty products", // English
    "SpocketLabs.HowItWorks.Step4": "Push to store and start selling! 🎉", // English


    "SpocketIphoneApp.Title": "Dropshipping iOS App", // English
    "SpocketIphoneApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally. Add them to your store within minutes, and start selling.", // English
    "SpocketIphoneApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketIphoneApp.Features.Feature2": "Exclusive discounts on products and iOS plans", // English
    "SpocketIphoneApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAndroidApp.Title": "Dropshipping Android App", // English
    "SpocketAndroidApp.Subtitle":
      "Discover fast shipping dropshipping products from pre-vetted suppliers in the US, Europe, and globally.", // English
    "SpocketAndroidApp.Features.Feature1": "Manage your online store from anywhere at any time", // English
    "SpocketAndroidApp.Features.Feature2": "Exclusive discounts on products", // English
    "SpocketAndroidApp.Features.Feature3": "24/7 Premium customer support", // English

    "SpocketAlibaba.Title": "Alibaba.com", // English
    "SpocketAlibaba.Subtitle":
      "Find trending products on Alibaba instantly and save time on your search for winning dropshipping products. Add products to your store within minutes, and start selling.", // English
    "SpocketAlibaba.Features.Feature1": "Save time finding winning products", // English
    "SpocketAlibaba.Features.Feature2": "Instant integration with your online store through Spocket", // English
    "SpocketAlibaba.Features.Feature3": "Access to thousands of unsaturated niches", // English
    "SpocketAlibaba.Button": "Download the Extention",

    "ConnectStoreModal.Header.Title": "Conecta tu tienda",
    "ConnectStoreModal.Header.Connected": "Uh oh! Seems that you already have a connected store!", // English
    "ConnectStoreModal.Header.Subtitle": "Don't have a store?", // English
    "ConnectStoreModal.Header.SubtitleCTA": "Start one for free", // English
    "ConnectStoreModal.Header.Featured": "Featured", // English
    "ConnectStoreModal.Footer.Text": "Elige tu plataforma para comenzar",
    "ConnectStoreModal.ShopifyForm.InvalidName": "El nombre de la tienda no es válido",
    "ConnectStoreModal.ShopifyForm.Placeholder": "p. ej.: MyStore",
    "ConnectStoreModal.ShopifyForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.ShopifyForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.ShopifyForm.Button": "Conectar tienda",
    "ConnectStoreModal.ShopifyForm.Search.Title": "Connect Your Shopify Store", // English
    "ConnectStoreModal.ShopifyForm.Search.Subtitle": "Create an E-commerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day.", // English
    "ConnectStoreModal.ShopifyForm.Search.CTATitle": "Don't have Shopify account?", // English
    "ConnectStoreModal.ShopifyForm.Search.CTAText": "Try For Free", // English
    "ConnectStoreModal.SuccessConnected.Title": "You're all set up!", // English
    "ConnectStoreModal.SuccessConnected.Subtitle": "You have successfully connected Shopify store", // English
    "ConnectStoreModal.SuccessConnected.CTA": "Go To Search Page", // English
    "ConnectStoreModal.WoocommerceInstruction.Installation": "Installation Instructions", // English
    "ConnectStoreModal.WoocommerceInstruction.StoreIdText": "Tu clave de autenticación:",
    "ConnectStoreModal.WoocommerceInstruction.Intro": "Cómo conectar tu tienda a Spocket:",
    "ConnectStoreModal.WoocommerceInstruction.Step1": "Instala el complemento de Spocket desde",
    "ConnectStoreModal.WoocommerceInstruction.Step1Link": "aquí",
    "ConnectStoreModal.WoocommerceInstruction.Step2": "Activa el complemento en tu página de complementos",
    "ConnectStoreModal.WoocommerceInstruction.Step3": "Ve al panel de WordPress > Spocket",
    "ConnectStoreModal.WoocommerceInstruction.Step4":
      "Pega tu clave de autenticación (aparece arriba) y haz clic en «Guardar»",
    "ConnectStoreModal.WoocommerceInstruction.Button": "Listo",
    "ConnectStoreModal.EbayForm.FooterText": "Don't have a store?", // English
    "ConnectStoreModal.EbayForm.FooterLink": "Try for free", // English
    "ConnectStoreModal.WixForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.WixForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.WixForm.Button": "Conectar tienda",
    "ConnectStoreModal.FelexForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.FelexForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.FelexForm.Button": "Conectar tienda",
    "ConnectStoreModal.BigcommerceForm.UrlText": "URL de la tienda Bigcommerce",
    "ConnectStoreModal.BigcommerceForm.InvalidName": "El nombre de la tienda no es válido",
    "ConnectStoreModal.BigcommerceForm.Placeholder": "p. ej.: MyStore",
    "ConnectStoreModal.BigcommerceForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.BigcommerceForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.BigcommerceForm.Button": "Conectar tienda",
    "ConnectStoreModal.EcwidForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.EcwidForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.EcwidForm.Button": "Conectar tienda",
    "ConnectStoreModal.WhiteLabelForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.WhiteLabelForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.WhiteLabelForm.Button": "Conectar tienda",
    "ConnectStoreModal.SquarespaceForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.SquarespaceForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.SquarespaceForm.Button": "Conectar tienda",
    "ConnectStoreModal.SquareForm.FooterText": "¿No tienes una tienda?",
    "ConnectStoreModal.SquareForm.FooterLink": "Pruébala gratis",
    "ConnectStoreModal.SquareForm.Button": "Conectar tienda",
    "ConnectStoreModal.FelexButton.Explanation":
      "Plataforma de creación de tiendas web que permite a los empresarios crear una tienda de dropshipping en menos de 10 minutos.",
    "ConnectStoreModal.DontHaveStore":  "¿No tienes una tienda?",
    "ConnectStoreModal.TryForFree": "Pruébala gratis",

    "PaymentTab.Document.Title": "Ajustes - Automatización - Spocket",
    "PaymentTab.Title.Billing": "Facturación",
    "PaymentTab.Tooltip.Billing":
      "Las tarjetas de crédito se usarán para el procesamiento del pedido y los pagos de la suscripción",
    "PaymentTab.AccordionItem.PauseChangeYourPlan": "Pausar o cambiar tu plan",
    "PaymentTab.AccordionItem.Label.ChangeYourPlan": "Cambiar tu plan",
    "PaymentTab.AccordionItem.Button.Downgrade": "Bajar de categoría",
    "PaymentTab.AccordionItem.Label.PauseYourPlan": "Pausar tu plan",
    "PaymentTab.AccordionItem.Label.StoreHasBeenPaused": "Tu tienda ya estuvo pausada anteriormente",
    "PaymentTab.AccordionItem.Button.PauseYourPlan": "Pausar tu plan",
    "PaymentTab.AccordionItem.Tooltip.PauseStore":
      "Puedes pausar tu cuenta de Spocket de manera temporal y regresar a ella sin perder ningún dato.",
    "PaymentTab.AccordionItem.Label.RemindMeLater": "Recuérdamelo más tarde",
    "PaymentTab.AccordionItem.Button.RemindMeLater": "Recuérdamelo más tarde",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater.3DaysBefore": "3 días antes",
    "PaymentTab.AccordionItem.Tooltip.RemindMeLater":
      "Conservar mis beneficios y recordarme <1>{{daysBefore}}</1> sobre la renovación de mi membresía",
    "RemindMeLaterModal.Header.ReminderSet": "Recordatorio establecido",
    "RemindMeLaterModal.Body.DaysBefore": "te enviaremos un correo electrónico 3 días antes de que",
    "RemindMeLaterModal.Body.ReminderSet":
      "El recordatorio ya está establecido y <1>{{daysBefore}}</1> se renueven tus beneficios. Hasta entonces, sigue avanzando en tu camino empresarial.",
    "RemindMeLaterModal.Button.Continue": "Continuar",
    "PauseStoreModal.Title.PauseYourStore": "Pausar tu tienda",
    "PauseStoreModal.Description.PauseYourStore":
      "Aprovecha esta oferta única y reduce el precio de tu plan un 60 % durante los próximos 30 días",
    "PauseStoreModal.Button.PauseStore": "Pausar tienda",
    "PauseStoreModal.Description.StorePaused":
      "Pausamos tu tienda y la suscripción del próximo mes se reducirá un 60 %. Ten en cuenta que después de ese periodo volverás a los pagos regulares.",
    "ConfirmPasswordModal.Title": "Confirma tu contraseña",
    "ConfirmPasswordModal.SubTitle": "Por razones de seguridad, confirma tu contraseña",
    "ConfirmPasswordModal.Input.Placeholder": "Tu contraseña",
    "ConfirmPasswordModal.Button.Cancel": "Cancelar",
    "ConfirmPasswordModal.Button.Confirm": "Confirmar",
    "ConfirmPasswordModal.Error.Generic": "There was an error processing the request. Please contact Customer Support.", // English

    "NewPasswordForm.Title": "Please set a password before logging out!", // English
    "NewPasswordForm.PasswordInput.Placeholder": "New Password", // English
    "NewPasswordForm.ConfirmPasswordInput.Placeholder": "Confirm New Password", // English

    "Config.MomentJs.Locale": "es",
    "Config.Plan.Starter": "Starter",
    "Config.Plan.Professional": "Pro",
    "Config.Plan.Empire": "Empire",
    "Config.Plan.Unicorn": "Unicorn",

    "UpgradeConfirmationModal.Header.Title": "Crece más rápido con Spocket ",
    "UpgradeConfirmationModal.Body.Title": "Elige tu ciclo de facturación",
    "UpgradeConfirmationModal.BulletPoints.Trial.Initial": "Tus primeros",
    "UpgradeConfirmationModal.BulletPoints.Trial.BeforeNumber": "",
    "UpgradeConfirmationModal.BulletPoints.Trial.AfterNumber": "días ",
    "UpgradeConfirmationModal.BulletPoints.Trial.Final":
      "son gratis, cancela durante el periodo de prueba o cuando este finalice",
    "UpgradeConfirmationModal.BulletPoints.FirstBill": "Tu primera factura se programará para",
    "UpgradeConfirmationModal.BulletPoints.FirstBillNoTrial":
      "Tu primera factura se deberá abonar después de la confirmación",
    "UpgradeConfirmationModal.BulletPoints.Recurrence": "La facturación se realizará de manera mensual",
    "UpgradeConfirmationModal.MonthlyTab.RateType": "Mensualmente ",
    "UpgradeConfirmationModal.MonthlyTab.RateFrequency": "por mes",
    "UpgradeConfirmationModal.YearlyTab.RateType": "Anualmente ",
    "UpgradeConfirmationModal.YearlyTab.RateFrequency": "por mes",
    "UpgradeConfirmationModal.YearlyTab.Discount": "de descuento",
    "UpgradeConfirmationModal.TaxRates.Text": "impuesto sobre las ventas",
    "UpgradeConfirmationModal.TaxRates.Period": "mes",
    "UpgradeConfirmationModal.YearlyPanel.PayNow": "Para pagar ahora",
    "UpgradeConfirmationModal.YearlyPanel.PerMonth": "por mes X 12 meses",
    "UpgradeConfirmationModal.YearlyPanel.Savings": "Tus ahorros: ",
    "UpgradeConfirmationModal.YearlyPanel.Period": "por año",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentInitial": "equivalente a",
    "UpgradeConfirmationModal.YearlyPanel.EquivalentFinal": "% de descuento",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Trial": "Los planes anuales no tienen periodo de prueba",
    "UpgradeConfirmationModal.YearlyPanel.Warning.Charge":
      "Se te cobrará el importe completo de manera inmediata",
    "UpgradeConfirmationModal.CreditCardTab.Info": "Pagar con tarjeta de crédito/débito",
    "UpgradeConfirmationModal.PayPalTab.Info": "Pagar con PayPal",
    "UpgradeConfirmationModal.PaymentSection.Details": "Detalles del pago ",
    "UpgradeConfirmationModal.CheckoutButton.FreeTrial": "Reclamar mi periodo de prueba gratis",
    "UpgradeConfirmationModal.CheckoutButton.Upgrade": "Pasar a ",
    "UpgradeConfirmationModal.FooterTestA.Info1.Top": "Más de 50 000 empresarios la eligieron",
    "UpgradeConfirmationModal.FooterTestA.Info1.Bottom":
      "Nuestros clientes nos han calificado sistemáticamente con un 5/5.",
    "UpgradeConfirmationModal.FooterTestA.Info2.Top": "Cifrado SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestA.Info2.Bottom":
      "Todos los datos de tus pagos están 100 % seguros con nosotros.",
    "UpgradeConfirmationModal.FooterTestB.Avatars": "Más de 50 000",
    "UpgradeConfirmationModal.FooterTestB.Info1.Top": "Más de 50 000 empresarios la eligieron",
    "UpgradeConfirmationModal.FooterTestB.Info1.Bottom":
      "Nuestros clientes nos han calificado sistemáticamente con un 5/5.",
    "UpgradeConfirmationModal.FooterTestB.Info2.Top": "Cifrado SSL de 128 bits",
    "UpgradeConfirmationModal.FooterTestB.Info2.Bottom":
      "Todos los datos de tus pagos están 100 % seguros con nosotros.",
    "UpgradeConfirmationModal.Agreement.Intro": "Si decides seguir, aceptas lo siguiente: ",
    "UpgradeConfirmationModal.Agreement.Terms": "Términos de uso",
    "UpgradeConfirmationModal.Agreement.Privacy": "Política de privacidad",
    "UpgradeConfirmationModal.Agreement.Refund": "Política de reembolso",
    "UpgradeConfirmationModal.Agreement.Middle": "y",
    "UpgradeConfirmationModal.Agreement.Cancelation": "Política de cancelación",

    "OrderDetails.Title": "Información del pedido",

    "CreditCard.Update.Label": "Credit/Debit Card", // English
    "CreditCard.Update.InputValue": "Tarjeta de crédito que finaliza en",
    "CreditCard.Update.Button": "Actualizar tarjeta",
    "CreditCard.AddCreditCard.Text":
      "Todavía no has agregado una tarjeta de crédito. Agrega una de las tarjetas de crédito que aparecen a continuación para que podamos procesar tus pedidos de manera automática",
    "CreditCard.AddCreditCardSubscription.Text": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically", // ENGLISH
    "CreditCard.AddCreditCard.Button": "Agregar tarjeta de crédito",

    "CardDeclineModal.Title": "Your current subscription payment is overdue", // English
    "CardDeclineModal.Description": "The credit card we have on file rejected the charge for your Spocket subscription. Paid features will be blocked until we're able to confirm payment. Please update your credit card as soon as possible.", // English
    "CardDeclineModal.Button.Title": "Update your credit card", // English
    "CardDeclineModal.Button.Label": "Update your card", // English

    "Sidebar.SearchProducts": "Find Products", // English
    "Sidebar.UsEuProducts": "US/EU Products", // English
    "Sidebar.AliExpress": "AliExpress", // English
    "Sidebar.ImageSearch": "Image Search", // English
    "Sidebar.WinningProducts": "Productos ganadores",
    "Sidebar.WinningProducts.Variant": "Nuevo",
    "Sidebar.ImportList": "Import List", // English
    "Sidebar.MyProducts": "Products", // English
    "Sidebar.MyOrders": "Orders", // English
    "Sidebar.Apps": "Apps",
    "Sidebar.HelpCenter": "Centro de ayuda",
    "Sidebar.MyShop": "Your Store", // English
    "Sidebar.Settings": "Ajustes",
    "Sidebar.MyMessages": "Supplier Chat", // English
    "Sidebar.Academy": "Academy", // English

    "TutorialModal.Title": "Estamos aquí para ayudarte!",
    "TutorialModal.WhatIsSpocket": "¿Qué es Spocket?",
    "TutorialModal.DiscoverProducts": "Descubre productos",
    "TutorialModal.ProductCustomization": "Personalización de productos",
    "TutorialModal.OrderProcessing": "Procesamiento del pedido",
    "TutorialModal.Settings": "Ajustes",
    "TutorialModal.GetHelp": "Obtener ayuda",
    "TutorialModal.JoinTheCommunity": "Únete a la comunidad",

    "NotificationCenter.Header": "Centro de notificaciones",
    "NotificationCenter.Footer": "Ver todas las notificaciones",
    "NotificationCenter.NoNotification": "¡Nada para ver aquí!",
    "NotificationCenter.NewMessageRefresh": "You have new notifications. Please refresh the page to view them.", // English

    "NotificationContent.Bulk.Success": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> successfully pushed to store", // English
    "NotificationContent.Bulk.Alert": "<1>Bulk Push Activity</1>: <1>{{successValue}}/{{totalValue}}</1> successfully pushed, <1>{{failureValue}}/{{totalValue}}</1> failed to push to store", // English
    "NotificationContent.Bulk.Failure": "<1>Bulk Push Activity</1>: <1>{{value}}/{{value}}</1> failed to push to store", // English
    "NotificationCenter.EmptyState.Title": "You have no notifications", // English
    "NotificationCenter.EmptyState.Description": "Check this space for any cost change, bulk push or supplier fulfilment updates", // English
    "NotificationCenter.ErrorMessage.Read": "Failed to read notification", // English
    "NotificationCenter.ErrorMessage.Unread": "Failed to unread notification", // English
    "NotificationCenter.ErrorMessage.Restore": "Failed to restore to import list", // English

    "NotificationCenter.TableHeader.Shipping": "Shipping type", // English
    "NotificationCenter.TableHeader.Product": "SKU", // English
    "NotificationCenter.TableHeader.OldCost": "Old Cost", // English
    "NotificationCenter.TableHeader.NewCost": "New Cost", // English
    
    "NotificationCenter.NotificationCard.Restore": "Restore To Import List", // English
    "NotificationCenter.NotificationCard.Restored": "Restored", // English
    "NotificationCenter.NotificationCard.Read": "Mark as Read", // English
    "NotificationCenter.NotificationCard.Unread": "Mark as Unread", // English
    "NotificationCenter.ErrorMessage.AllRead": "Failed to mark all notifications as read", // English

    "NotificationCenter.Button.MarkAllAsRead": "Mark all as read", // English
    "NotificationCenter.NotificationCard.ErrorOptOut": "There was an error sending your request to disable this pop-up. Please try again.", // English
    
    "NotificationCenter.NotificationType.ShippingCost": " has a shipping cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.ProductCost": " has a product cost update", // English, please leave this extra space
    "NotificationCenter.NotificationType.VacationSupplier": "Supplier <1>{{ supplierAlias }}</1> will have delayed order processing between <1>{{ startDate }}</1> and <1>{{ endDate }}</1>", // English
    "NotificationCenter.NotificationType.ShippingSupplier": "Supplier <1>{{ supplierAlias }}</1> has changed their shipping cost", // English
    "NotificationCenter.NotificationType.ImportProduct": "Product import from {{ source }} {{ status }}{{ productText }}{{reasonText}}", // English
    "NotificationCenter.NotificationType.ImportProductReason": "Reason: ", // English

    "Products.Title.Head": "Mis productos - Spocket",
    "Products.Title.Header": "Mis productos",
    "Products.Loading.Top": "¡Se está cargando la lista de tus productos!",
    "Products.Loading.Bottom": "Te pedimos que esperes mientras buscamos tus productos.",
    "Products.Empty.Search": "¡No encontramos ningún producto!",
    "Products.Empty.Top": "¡La lista de productos está vacía!",
    "Products.Empty.Bottom":
      "Visita la página de búsqueda para comenzar a agregar productos a tu lista de importación.",
    "Products.Empty.Button.Search": "Buscar productos",
    "Products.Empty.Button.How": "Cómo encontrar productos",
    "Products.Alert.Removed": "Producto eliminado",

    "Products.Search.Label": "Buscar tus productos",
    "Products.Search.Placeholder": "Search Your Products", // English
    "Products.Search.Button": "Buscar",

    "ProductListItem.Inactive.Tooltip": "Este producto ya no está disponible en el proveedor",
    "ProductListItem.Inactive.Title": "Inactivo",
    "ProductListItem.OutOfStock.Tooltip": "Todas las variantes de este producto están agotadas",
    "ProductListItem.OutOfStock.Title": "Agotado",
    "ProductListItem.InventoryCount.Singular": "unidad",
    "ProductListItem.InventoryCount.Plural": "unidades",
    "ProductListItem.InventoryCount.End.Singular": "queda",
    "ProductListItem.InventoryCount.End.Plural": "quedan",
    "ProductListItem.Button.View": "Ver en la tienda",
    "ProductListItem.Button.Remove": "Eliminar de la tienda",
    "ProductListItem.ItemVariant.LowStock.Label": "Low Stock", // ENGLISH

    "ImportList.Title.Head": "Lista de importación - Spocket",
    "ImportList.Title.Header": "Mi lista de importación",
    "ImportList.Title.Error": "Failed to Push", // ENGLISH
    "ImportList.Loading.Top": "¡Tu lista de importación se está cargando!",
    "ImportList.Loading.Bottom": "Te pedimos que esperes mientras buscamos tus productos.",
    "ImportList.Empty.Button.Search": "Buscar productos",
    "ImportList.Empty.Top": "¡Tu lista de importación está vacía!",
    "ImportList.Empty.Bottom":
      "Visita la página de búsqueda para comenzar a agregar productos a tu lista de importación.",
    "ImportList.Empty.Button.How": "Cómo personalizar productos",
    "ImportList.Alert.Pushed": "Este producto se está promocionando en tu tienda:",
    "ImportList.Alert.CannotPush": "This product can't be pushed to your store:", // ENGLISH
    "ImportList.LowStockVariants.Label": "Low stock variants", // ENGLISH

    "ImportList.Actions.PushAll.Label": "Push All", // ENGLISH
    "ImportList.Actions.PushAll.Title": "Push all the products in Import List to store", // ENGLISH
    "ImportList.Actions.PushAll.Error": "There was an error with the push process: ", // ENGLISH
    "ImportList.Actions.PushAll.Warning": "There are unsaved changes in one or more products.", // ENGLISH
    "ImportList.Actions.RemoveAll.Label": "Remove All", // ENGLISH
    "ImportList.Actions.RemoveAll.Title": "Remove all the products in Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Success": "All items were removed from your Import List", // ENGLISH
    "ImportList.Actions.RemoveAll.Error": "There was an error with the remove all process: ", // ENGLISH

    "ImportList.PushAllModal.Title": "Push All?", // ENGLISH
    "ImportList.PushAllModal.Description": "You have <1>{{numberOfProducts}} product(s)</1> in your Import List. This process could take more than <1>{{time}}</1>. Come back at anytime to check the status of your product pushes.", // ENGLISH
    "ImportList.PushAllModal.Alert.Title": "Some products may not push to store due to the following reasons:", // ENGLISH
    "ImportList.PushAllModal.Alert.List1": "There are no <1>active variants</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List2": "Currently <1>unavailable</1> for purchase", // ENGLISH
    "ImportList.PushAllModal.Alert.List3": " Some variants have a <1>negative gross profit</1>", // ENGLISH
    "ImportList.PushAllModal.Alert.List4": "Sales Price is <1>higher</1> than Compare At Price", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Title": "Cancel push all products", // ENGLISH
    "ImportList.PushAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.PushAllModal.PushButton.Title": "Push all products to your store", // ENGLISH
    "ImportList.PushAllModal.PushButton.Label": "Yes, Push All", // ENGLISH
    "ImportList.PushAllModal.Minutes": "minute(s)", // ENGLISH
    "ImportList.PushAllModal.Hours": "hour(s)", // ENGLISH

    "ImportList.RemoveAllModal.Title": "Remove All", // ENGLISH
    "ImportList.RemoveAllModal.Description": "Are you sure? Once you click <1>\"Yes, I'm sure\"</1> all products from your Import List will be removed. This action cannot be undone.", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Title": "Cancel remove all products", // ENGLISH
    "ImportList.RemoveAllModal.CancelButton.Label": "Cancel", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Title": "Remove all products from Import List", // ENGLISH
    "ImportList.RemoveAllModal.RemoveButton.Label": "Yes, I'm sure", // ENGLISH

    "ImportList.BulkImportActive.Title": "Your Bulk Push is in progress <1>({{value}} products)</1>", // ENGLISH
    "ImportList.BulkImportActive.Stats.Success": "Successful Pushes", // ENGLISH
    "ImportList.BulkImportActive.Stats.Fail": "Failed Pushes", // ENGLISH
    "ImportList.BulkImportActive.Tooltip": "Recently imported products or products that failed to push to store.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.Empty": "Your Import List is empty!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Title.NotEmpty": "You have new products in your Import List!", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.Empty": "While you wait, head over to the <1>Search page</1> and continue looking for awesome products to add to your Import List.", // ENGLISH
    "ImportList.BulkImportActive.SubText.Description.NotEmpty": "You can continue using the Import List after your Bulk Push is completed.", // ENGLISH

    "ImportList.Description.Stats.Words": "Words:", // ENGLISH
    "ImportList.Description.Stats.Chars": "Chars: <1>{{amount}}/{{limit}}</1>", // ENGLISH

    "ImportList.Search.Label": "Busca entre tus productos importados",
    "ImportList.Search.Placeholder": "Buscar tus productos",
    "ImportList.Search.Button": "Buscar",

    "ImportList.PageTitle": "Import List", // English
    "ImportList.HeaderTags.Premium": "Premium Product", // English
    "ImportList.LinksSection.Supplier.Title": "Visit supplier page", // English
    "ImportList.LinksSection.ProductLink.Text": "View Product", // English
    "ImportList.LinksSection.ProductLink.Title": "Visit product page", // English
    "ImportList.Tooltip.Tags": "Use descriptive keywords to help organize products and improve its searchability.", // English
    "ImportList.CategorySection.Label": "Category", // English
    "ImportList.CategorySection.Placeholder": "Enter category", // English
    "ImportList.CategorySection.Categories.Tooltip": "Select category to push this product listing to. These categories are already created on your marketplace.", // English
    "ImportList.CategorySection.Attributes.Tooltip": "Select attributes associated with category you selected before you push this product listing.", // English
    "ImportList.CategoriesSection.Label": "Categories", // English
    "ImportList.CategoriesSection.AtributesAdded": "Atributes added", // English
    "ImportList.CategoriesSection.SearchError": "There was an error fetching the categoies", // English
    "ImportList.CategoriesSection.NeedAtributes": "Need attributes", // English
    "ImportList.CategoriesSection.Modal.Attributes.Tooltip": "Attribute name associated with category you selected.", // English
    "ImportList.CategoriesSection.Modal.Values.Tooltip": "Attribute values for corresponding category attribute.", // English
    "ImportList.CategoriesSection.Modal.Title": "Add Attributes for Selected Category", // English
    "ImportList.CategoriesSection.Modal.CategoryAttributes": "Category Attributes", // English
    "ImportList.CategoriesSection.Modal.Values": "Values", // English
    "ImportList.CategoriesSection.Modal.Apply": "Apply", // English
    "ImportList.CategoriesSection.Modal.Cancel": "Cancel", // English
    "ImportList.CategoriesSection.Modal.Clear": "Clear selection", // English
    "ImportList.CategoriesSection.Modal.Error": "All fields are required", // English
    "ImportList.CategoriesSection.Modal.Input.Placeholder": "Type here", // English
    "ImportList.Tooltip.Category": "Categories help you organize your store and your customers find what they're looking for.", // English
    "ImportList.CollectionSection.Label": "Collection(s)", // English
    "ImportList.CollectionSection.Disabled.Placeholder": "Not supported", // English
    "ImportList.CollectionSection.Disabled.Tooltip": "Collections are not supported by your connected store provider", // English
    "ImportList.Tooltip.Collection": "Select collection(s) to push this product listing to. Collections must first be created in your store.", // English

    "ImportList.Footer.RemoveButton.Label": "Remove Product", // English
    "ImportList.Footer.RemoveButton.Title": "Remove this item from Import List", // English
    "ImportList.Footer.SaveButton.Label": "Save", // English
    "ImportList.Footer.SaveButton.Title": "Save item changes", // English
    "ImportList.Footer.SaveButton.Tooltip.NoUnsaved": "There are no unsaved changes", // English

    "ImportList.ImageAltText.ErrorMessage": "You have reached the maximum limit.", // English
    "ImportList.ImageAltText.Placeholder": "Enter Alt text", // English
    "ImportList.ImageAltText.Tooltip": "Write a brief description of this image to improve searach engine optimization (SEO) and accessibility for visually impaired customers.", // English
    "ImportList.ImageAltText.Button.Title": "Click to add image alt text", // English
    "ImportList.ImageAltText.Button.Label": "Click to Add Alt Text", // English
    "ImportList.ImportListImage.SelectImageButton.Title": "Click to select image", // English
    "ImportList.ImportListImage.ZoomImageButton.Title": "Click to see larger image", // English

    "ImportList.ListingErrors.Title": "Your listing has the following errors:", // English
    "ImportList.ListingErrors.NoStore": "Click on <1>Shop</1> on the side navigation bar to connect and push products to your store.", // English
    "ImportList.ListingErrors.Unpurchasable": "You may save this product, but it is currently unavailable for purchase.", // English
    "ImportList.ListingErrors.NoTitle": "Product Name cannot be blank.", // English
    "ImportList.ListingErrors.CompareAtPrice": "Sales Price is greater than Compare At Price.", // English
    "ImportList.ListingErrors.NoActiveVariations": "Select at least one variant.", // English
    "ImportList.ListingErrors.VariationSalesPriceError": "At least one variation does not have a sales price.", // English
    "ImportList.ListingErrors.NoEbayCategoryError": "There is an error with the category selection.", // English

    "ImportList.ConnectStorePopup.Title": "Connect Your Store", // English
    "ImportList.ConnectStorePopup.Subtitle": "<subtitle>Go to <highlight>My Store</highlight> to connect and push products</subtitle>", // English
    "ImportList.ConnectStorePopup.Cancel": "Cancel", // English
    "ImportList.ConnectStorePopup.Submit": "Go to My Store", // English

    "PushAllModal.Button.Active": "Promocionando los productos de la página actual",
    "PushAllModal.Button.Inactive": "Promocionar página actual",
    "PushAllModal.Modal.Text":
      "¿Seguro que quieres promocionar todos los productos de esta página? Todos los productos válidos en la página actual se promocionarán en tu tienda.",
    "PushAllModal.Modal.Push": "Promocionar todo",
    "PushAllModal.Modal.Cancel": "Cancelar",

    "RemoveAllModal.Button.Active": "Eliminando los productos de la página actual",
    "RemoveAllModal.Button.Inactive": "Eliminar la página actual",
    "RemoveAllModal.Modal.Text":
      "¿Seguro que quieres eliminar todos los productos de esta página? Todos los productos en la página actual quedarán eliminados de la lista de importación.",
    "RemoveAllModal.Modal.Remove": "Eliminar todo",
    "RemoveAllModal.Modal.Cancel": "Cancelar",

    "ImportListItem.Tab.Product": "Producto",
    "ImportListItem.Tab.Description": "Descripción",
    "ImportListItem.Tab.Variants": "Variantes",
    "ImportListItem.Tab.Images": "Imágenes",
    "ImportListItem.Remove.Active": "Eliminando producto",
    "ImportListItem.Remove.Inactive": "Eliminar producto",
    "ImportListItem.Push.Active": "Promocionando en la tienda",
    "ImportListItem.Push.Inactive": "Promocionar en la tienda",
    "ImportListItem.Save.Button": "Guardar",
    "ImportListItem.Alert.Removed": "Se eliminó este producto de tu lista de importación: ",
    "ImportListItem.Alert.RemoveFailed": "Hubo un error al intentar eliminar: ",
    "ImportListItem.Alert.CompareAtPrice":
      "Comparable en precio debería ser mayor que el precio de venta para: ",
    "ImportListItem.Alert.OneActiveVariation": "Este producto necesita al menos una variante activa: ",
    "ImportListItem.Alert.Saved": "Se guardó este producto: ",
    "ImportListItem.Alert.ErrorPushing": "Hubo un error al promocionar en tu tienda",
    "ImportListItem.Alert.ErrorSaving": "Hubo un error al guardar tu tienda",
    "ImportListItem.Alert.NeedToUpgrade": "Limit of products reached. Please upgrade to push this item to your store.", // English
    "ImportListItem.Modal.ConnectStore": "Conecta tu tienda para promocionar productos: ",
    "ImportListItem.Tooltip.OneActiveVariation": "Necesitas al menos una variante activa",
    "ImportListItem.Tooltip.Unavailable": "Este producto no se puede comprar en este momento",
    "ImportListItem.Tooltip.Unsaved": "Tienes cambios sin guardar",
    "ImportListItem.Tooltip.Profit": "Advertencia: Tu ganancia bruta es negativa para este producto",
    "ImportListItem.Tooltip.Unpurchasable":
      "Puedes guardar este producto, pero en este momento no puedes comprarlo",
    "ImportListItem.Variants.ShowShippingPrices": "Mostrar precios de envío",

    "ImportList.Filters": "Filters", // English
    "ImportList.Filters.Title": "Filter", // English
    "ImportList.Filters.Supplier": "Supplier", // English
    "ImportList.Filters.Supplier.Placeholder": "Select supplier", // English
    "ImportList.Filters.ProductsFrom": "Products from", // English
    "ImportList.Filters.ProductsFrom.Spocket": "Spocket", // English
    "ImportList.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "ImportList.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "ImportList.Filters.Button.Reset": "Reset", // English
    "ImportList.Filters.Button.Title.Reset": "Reset all filter options", // English
    "ImportList.Filters.Button.Apply": "Apply Filter", // English
    "ImportList.Filters.Button.Title.Apply": "Apply selected filters", // English

    "ImportList.Variants.SelectAll": "Select All", // English
    "ImportList.Variants.Image": "Image", // English
    "ImportList.Variants.Sku": "SKU", // English
    "ImportList.Variants.Inventory": "Inventory", // English
    "ImportList.Variants.Cost": "Cost", // English
    "ImportList.Variants.Shipping": "Shipping", // English
    "ImportList.Variants.Profit": "Profit", // English
    "ImportList.Variants.SalesPrice": "Sales Price", // English
    "ImportList.Variants.CompareAtPrice": "Compare At Price", // English
    "ImportList.Variants.NotPurchasable.Title": "Product is currently unavailable for purchase", // English
    "ImportList.Variants.NotPurchasable.Description": "Head over to the search page to browse for similar products.", // English
    "ImportList.Variants.Variant.EditPriceHeader.SalesPrice": "Sales Price", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Title": "Set prices", // English
    "ImportList.Variants.Variant.EditPriceHeader.HeaderButton.Label": "Edit", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin": "Set Profit Margin", // English
    "ImportList.Variants.Variant.PriceInputs.SetProfitMargin.Tooltip": "Add a % profit margin on cost + domestic shipping price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice": "Set Sales Price", // English
    "ImportList.Variants.Variant.PriceInputs.SetSalesPrice.Tooltip": "Set a fixed sales price for all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Error": "Please enter a valid value", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Label": "Apply to all variants", // English
    "ImportList.Variants.Variant.PriceInputs.Button.Title": "Apply change to all variants", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Header": "Shipping", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.Button.Title": "Check shipping prices", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Label": "Show Shipping Details", // English
    "ImportList.Variants.Variant.ShippingPriceHeader.DetailsButton.Title": "Show dhipping details for this item", // English
    "ImportList.Variants.Variant.VariantInventory.LowStock": "Low Stock", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Title": "Show/hide variants", // English
    "ImportList.Variants.Variant.VariantsCollapseTogge.Button.Label": "{{value}} variant(s) selected", // English

    "ItemInfo.ProductName.Label": "Nombre del producto",
    "ItemInfo.ProductTags.Label": "Etiquetas del producto",
    "ItemInfo.ProductTags.Remove": "Eliminar todas las etiquetas",
    "ItemInfo.ProductTags.Placeholder": "Agregar etiqueta nueva",
    "ItemInfo.ProductType.Label": "Tipo de producto",
    "ItemInfo.Collection.Label": "Colección",
    "ItemInfo.Collection.Placeholder": "Seleccionar",
    "ItemInfo.Collection.Option": "Seleccionar",
    "ItemInfo.SquareLocation.Option": "Seleccionar",

    "ItemVariantList.Modal.Title": "Información del envío",
    "ItemVariantList.Modal.Intro": "Se envía desde: ",
    "ItemVariantList.Modal.DoesNotShipInternational": "Este artículo no tiene envío internacional.",
    "ItemVariantList.Modal.DoesNotShipTo": "No se puede enviar a: ",
    "ItemVariantList.Table.Head.Destination": "Destino",
    "ItemVariantList.Table.Head.Cost": "Coste",
    "ItemVariantList.Table.Head.Time": "Tiempo de entrega (días)",
    "ItemVariantList.Table.Body.Domestic": "Nacional",
    "ItemVariantList.Table.Body.International": "Internacional",
    "ItemVariantList.VariantsTable.ShippingPrice": "Precio de envío ",
    "ItemVariantList.VariantsTable.Image": "Imagen",
    "ItemVariantList.VariantsTable.SKU": "Código de referencia",
    "ItemVariantList.VariantsTable.Inventory": "Inventario",
    "ItemVariantList.VariantsTable.Price": "Precio",
    "ItemVariantList.VariantsTable.SalesPrice": "Precio de venta ",
    "ItemVariantList.VariantsTable.Profit": "Ganancia",
    "ItemVariantList.VariantsTable.CompareAtPrice": "Comparable en precio",
    "ItemVariantList.VariantsTable.Unavailable": "Este producto no se puede comprar en este momento",
    "ItemVariantList.VariantsTable.Head": "Todas las variantes de este producto se muestran aquí.",
    "ItemVariantList.VariantsTable.ContextPopover.Placeholder": "ejemplo: 40 %",
    "ItemVariantList.Alert.MaxVariants": "Shopify no acepta más de 100 variantes.",

    "ItemVariantList.ContextPopover.Title": "Establecer ganancia",
    "ItemVariantList.ContextPopover.Apply": "Aplicar",

    "ProductVariation.Description":
      "Puedes realizar un pedido para obtener productos de muestra directamente en Spocket. Sigue los siguientes pasos para una finalización del pedido fácil y rápida.",
    "ProductVariation.Label.SelectVariant": "Seleccionar variante",
    "ProductVariation.Label.SelectTheProductVariation": "Seleccionar la variante del producto",
    "ProductVariation.Qty": "Cant.",
    "ProductVariation.Label.NotesForTheSupplier": "Notas para el proveedor",

    "InputAddress.Label.FirstName": "Nombre",
    "InputAddress.PlaceHolder.FirstName": "Introducir nombre",
    "InputAddress.Label.LastName": "Apellido",
    "InputAddress.PlaceHolder.LastName": "Introducir apellido",
    "InputAddress.Label.StreetAddress": "Dirección",
    "InputAddress.Label.Country": "País",
    "InputAddress.PlaceHolder.Country": "Buscar país",
    "InputAddress.Label.State": "Estado",
    "InputAddress.Placeholder.State": "Buscar estado",
    "InputAddress.Label.City": "Ciudad",
    "InputAddress.PlaceHolder.City": "Introducir ciudad",
    "InputAddress.Label.Zip": "Código postal",
    "InputAddress.PlaceHolder.Zip": "Introducir código postal",
    "InputAddress.Label.Phone": "Número de teléfono",
    "InputAddress.Placeholder.Phone": "Introducir número de teléfono",

    "OrderReview.OrderDetails": "Información del pedido",
    "OrderReview.Unit": "{{count}} unidad",
    "OrderReview.Unit_plural": "{{count}} unidades",
    "OrderReview.ShippingAddress": "Dirección de envío",
    "OrderReview.PaymentMethod": "Método de pago",
    "OrderReview.Amounts": "Cantidades",
    "OrderReview.Subtotal": "Subtotal",
    "OrderReview.ShippingCost": "Coste del envío",
    "OrderReview.TransactionFees": "Costes de la transacción",
    "OrderReview.TransactionFees.ToolTips":
      "Los costes de la transacción incluyen las tarifas que cobran Stripe, el proveedor de pagos de Spocket, así como también las tarifas por servicio.",
    "OrderReview.OrderTotal": "Total del pedido",
    "OrderReview.ItemWithCount": "{{count}} artículo",
    "OrderReview.ItemWithCount_plural": "{{count}} artículos",
    "OrderReview.Placeholder.CreditCard": "Tarjeta de crédito que finaliza en {{last4}}",

    "PaymentComplete.PaymentCompleted": "Pago completado",
    "PaymentComplete.PaymentCompleted.Note":
      "Gracias por el pago. Hemos notificado al proveedor y tu pedido se procesará a la brevedad. Actualizaremos el estado del pedido y agregaremos el número de seguimiento en la página de tus pedidos.",
    "PaymentComplete.PaymentIncomplete": "¡Tu pedido está incompleto!",
    "PaymentComplete.PaymentIncomplete.BankRequiresConfirmation.Note":
      "Tu banco requiere que confirmes tu pago para proceder",
    "PaymentComplete.PaymentIncomplete.ConfirmationLink": "Usa este enlace para confirmar el pago.",
    "PaymentComplete.PaymentIncomplete.Note":
      "Una vez que el pago esté confirmado, se notificará al proveedor y procesaremos tu pedido como lo hacemos habitualmente. Las noticias sobre el estado y el seguimiento se agregarán a la página de tus pedidos. Vuelve a cargar la página después de la confirmación.",

    "CreditCard.AddCreditCard.Description":
      "Todavía no has agregado una tarjeta de crédito. Agrega una de las tarjetas de crédito que aparecen a continuación para que podamos procesar tus pedidos de manera automática",
    "CreditCard.Button.AddCreditCard": "Agregar tarjeta de crédito",
    "CreditCard.Button.UpdateCard": "Actualizar tarjeta",
    "CreditCard.UpdateCard.Label.CreditCard": "Tarjeta de crédito",
    "CreditCard.PlaceHolder.CreditCard": "Tarjeta de crédito que finaliza en {{last4}}",

    "UpdateCreditCardModal.Label.CreditCard": "Tarjeta de crédito",
    "UpdateCreditCardModal.Button.UpdateCard": "Actualizar tarjeta",
    "UpdateCreditCardModal.Button.AddCreditCard": "Agregar tarjeta de crédito",
    "UpdateCreditCardModal.Button.Cancel": "Cancelar",
    "UpdateCreditCardModal.Label.UpdateCardDetails": "Actualiza la información de tu tarjeta",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.One":
      "Usaremos tu cuenta durante el procesamiento del pedido de tus productos en Spocket.",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Two": "Términos y condiciones",
    "UpdateCreditCardModal.Label.TermsAndConditionsNote.Three": "para el procesamiento de pagos.",
    "UpdateCreditCardModal.Error.CustomInputs": "All fields need to be completed.", // English

    "ExtraCreditCardInputs.Label.Name": "Full Name", // English
    "ExtraCreditCardInputs.Label.Country": "Country", // English

    "SampleOrderModal.Title.SelectVariation": "Seleccionar variante",
    "SampleOrderModal.Title.ShippingAddress": "Dirección de envío",
    "SampleOrderModal.Title.PaymentMethod": "Método de pago",
    "SampleOrderModal.Title.Review": "Revisar",
    "SampleOrderModal.Title.PaymentConfirmationRequired": "¡Debes confirmar el pago!",
    "SampleOrderModal.Title.OrderPlaced": "¡Pedido realizado!",
    "SampleOrderModal.Steps": "Paso {{current}} de {{total}}",
    "SampleOrderModal.FinalStep": "Paso final",
    "SampleOrderModal.ConfirmPayment": "¡Confirma el pago!",
    "SampleOrderModal.Finished": "Finalizado",
    "SampleOrderModal.Footer.Continue": "Continuar",
    "SampleOrderModal.Footer.PlaceOrder": "Realizar pedido",
    "SampleOrderModal.Footer.Close": "Cerrar",
    "SampleOrderModal.Footer.Cancel": "Cancelar",
    "SampleOrderModal.Footer.GoBack": "Volver",

    "AdvancedFiltersModal.Title.Filters": "Filtros",
    "AdvancedFiltersModal.Title.Shipping": "Envío",
    "AdvancedFiltersModal.Label.ShipsFrom": "Se envía desde",
    "AdvancedFiltersModal.Label.ShipsTo": "Se envía a",
    "AdvancedFiltersModal.Label.ShippingTime": "Tiempo de envío",
    "AdvancedFiltersModal.Label.Popular": "Popular",
    "AdvancedFiltersModal.Label.Country.UnitedStates": "Estados Unidos",
    "AdvancedFiltersModal.Label.Country.Europe": "Europa",
    "AdvancedFiltersModal.Label.ShippingTimeDescription": "Solo se puede enviar a los Estados Unidos",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Any": "Cualquier número de días",
    "AdvancedFiltersModal.RadioOption.ShippingTime.One": "1-3 días",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Four": "4-7 días",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Eight": "8-14 días",
    "AdvancedFiltersModal.RadioOption.ShippingTime.Fifteen": "Más de 15 días",
    "AdvancedFiltersModal.Title.ItemsCost": "Coste de los artículos",
    "AdvancedFiltersModal.Label.ItemCost": "Coste del artículo",
    "AdvancedFiltersModal.Label.ShippingCost": "Coste del envío",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Any": "Cualquier coste",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Free": "Gratis",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Five": "5 $ o menos",
    "AdvancedFiltersModal.RadioOption.ShippingCost.Fifteen": "15 $ o menos",
    "AdvancedFiltersModal.RadioOption.ShippingCost.TwentyFive": "25 $ o menos",
    "AdvancedFiltersModal.Title.Supplier": "Proveedor",
    "AdvancedFiltersModal.Select.MenuTitle.Suppliers": "Proveedores",
    "AdvancedFiltersModal.Label.TopSupplier": "Proveedor principal",
    "AdvancedFiltersModal.Label.TopSupplierDescription":
      "Proveedores conocidos por la alta calidad de sus productos, su gran servicio y sus buenas calificaciones",
    "AdvancedFiltersModal.Title.Advanced": "Avanzado",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProducts": "Productos prémium",
    "AdvancedFiltersModal.Checkbox.Label.PremiumProductsDescription":
      "Productos con grandes descuentos y envío rápido",
    "AdvancedFiltersModal.Checkbox.Label.BestSeller": "Más vendido",
    "AdvancedFiltersModal.Checkbox.Label.BestSellerDescription": "Productos con mejor desempeño en Spocket",
    "AdvancedFiltersModal.Button.Cancel": "Cancelar",
    "AdvancedFiltersModal.Button.ViewResults": "Ver resultados",
    "AdvancedFiltersModal.Select.PlaceHolder.Countries": "Buscar país...",
    "AdvancedFiltersModal.Select.MenuTitle.Countries": "Países",
    "AdvancedFiltersModal.Select.PlaceHolder.Suppliers": "Buscar proveedor...",
    "AdvancedFiltersModal.Label.All Suppliers": "Todos los proveedores",
    "AdvancedFiltersModal.Label.New Suppliers": "Proveedores nuevos",
    "AdvancedFiltersModal.Label.Currencies": "Search products with multiple currencies", // English
    "AdvancedFiltersModal.Label.Currency": "Currency:", // English
    "AdvancedFiltersModal.Label.Currency.USDollar": "US Dollar ($)", // English
    "AdvancedFiltersModal.Label.Currency.Euro": "Euro (€)", // English
    "AdvancedFiltersModal.Label.CurrenciesDescription": "Search products with {{currencies}}", // English

    "AdvancedFilters.ShippingTime.Any": "Cualquier número de días",
    "AdvancedFilters.ShippingTime.One": "1-3 días",
    "AdvancedFilters.ShippingTime.Four": "4-7 días",
    "AdvancedFilters.ShippingTime.Eight": "8-14 días",
    "AdvancedFilters.ShippingTime.Fifteen": "Más de 15 días",

    "AdvancedFilters.ShippingCost.Free": "Envío gratis",
    "AdvancedFilters.ShippingCost.Five": "Envío de menos de 5 $",
    "AdvancedFilters.ShippingCost.Fifteen": "Envío de menos de 15 $",
    "AdvancedFilters.ShippingCost.TwentyFive": "Envío de menos de 25 $",

    "AdvancedFilters.transpileFiltersKey.Label.ClearAll": "Borrar todo",
    "AdvancedFilters.transpileFiltersKey.Label.Shipping": "Envío",
    "AdvancedFilters.transpileFiltersKey.Label.Keywords": "Buscar",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsFrom": "Se envía desde",
    "AdvancedFilters.transpileFiltersKey.Label.ShipsTo": "Se envía a",
    "AdvancedFilters.transpileFiltersKey.Label.Supplier": "Proveedor",
    "AdvancedFilters.transpileFiltersKey.Label.SortBy": "Ordenar por",
    "AdvancedFilters.transpileFiltersKey.Label.ItemCost": "Coste del artículo",
    "AdvancedFilters.transpileFiltersKey.Value.TopSuppliers": "Proveedores principales",
    "AdvancedFilters.transpileFiltersKey.Value.PersonalizedInvoices": "Facturas personalizadas",
    "AdvancedFilters.transpileFiltersKey.Value.BestSeller": "Más vendido",
    "AdvancedFilters.transpileFiltersKey.Value.Premium": "Prémium",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.FromTo": "de {{from}} a {{to}}",
    "AdvancedFilters.transpileFiltersKey.Value.ItemCost.JustFrom": "from {{from}}", // * ENGLISH
    "AdvancedFilters.transpileFiltersKey.Label.Inventory": "Inventory", // * ENGLISH

    "Search.Header.PlaceHolder.Search": "Introducir palabras clave...",
    "Search.Header.Button.Search": "Buscar",
    "Search.Header.Button.Filters": "Filtros",

    "Navigation.UpgradeButton.TryPro": "Prueba Spocket Pro GRATIS",
    "Navigation.UpgradeButton.TryEmpire": "Prueba Spocket EMPIRE GRATIS",
    "Navigation.UpgradeButton.TryUnicorn": "Prueba Spocket Unicorn GRATIS",

    "CategoriesCards.Label.MoreCategories": "Más categorías",

    "StaticCategories.WomensClothing": "Indumentaria femenina",
    "StaticCategories.WomensClothing.Activewear": "Indumentaria deportiva",
    "StaticCategories.WomensClothing.Bodysuits": "Bodies",
    "StaticCategories.WomensClothing.Dresses": "Vestidos",
    "StaticCategories.WomensClothing.JacketsCoats": "Chaquetas y abrigos",
    "StaticCategories.WomensClothing.Jeans": "Vaqueros",
    "StaticCategories.WomensClothing.JumpsuitsRompers": "Monos",
    "StaticCategories.WomensClothing.Leggings": "Leggings",
    "StaticCategories.WomensClothing.LingerieUnderwear": "Lencería y ropa interior",
    "StaticCategories.WomensClothing.MatchingSets": "Conjuntos",
    "StaticCategories.WomensClothing.Pants": "Pantalones",
    "StaticCategories.WomensClothing.Shorts": "Shorts",
    "StaticCategories.WomensClothing.Skirts": "Faldas",
    "StaticCategories.WomensClothing.Sleepwear": "Ropa de dormir",
    "StaticCategories.WomensClothing.Socks": "Calcetines",
    "StaticCategories.WomensClothing.SweatersHoodies": "Suéteres y sudaderas",
    "StaticCategories.WomensClothing.Swimwear": "Trajes de baño",
    "StaticCategories.WomensClothing.TankTops": "Camisetas sin manga",
    "StaticCategories.WomensClothing.TopsBlouses": "Tops y blusas",
    "StaticCategories.WomensClothing.Tshirts": "Camisetas",

    "StaticCategories.JewelryWatches": "Joyas y relojes",
    "StaticCategories.JewelryWatches.Bracelets": "Brazaletes",
    "StaticCategories.JewelryWatches.Earrings": "Pendientes",
    "StaticCategories.JewelryWatches.Necklaces": "Collares",
    "StaticCategories.JewelryWatches.OtherAccessories": "Otros accesorios",
    "StaticCategories.JewelryWatches.PendantsStonesCharms": "Colgantes, piedras y dijes",
    "StaticCategories.JewelryWatches.Rings": "Anillos",
    "StaticCategories.JewelryWatches.Sets": "Conjuntos",
    "StaticCategories.JewelryWatches.Watches": "Relojes",

    "StaticCategories.TechAccessories": "Accesorios tecnológicos",
    "StaticCategories.TechAccessories.AudioVideo": "Audio y vídeo",
    "StaticCategories.TechAccessories.CasesCovers": "Estuches y fundas",
    "StaticCategories.TechAccessories.Lighting": "Iluminación",
    "StaticCategories.TechAccessories.MobileLaptopAccessories":
      "Accesorios para teléfonos móviles y portátiles",
    "StaticCategories.TechAccessories.Mousepads": "Alfombrillas de ratón",
    "StaticCategories.TechAccessories.Novelty": "Novedades",

    "StaticCategories.KidsBabies": "Niños y bebés",
    "StaticCategories.KidsBabies.BabyClothing": "Ropa de bebé",
    "StaticCategories.KidsBabies.Bathing": "Baño",
    "StaticCategories.KidsBabies.BlanketsPillows": "Sábanas y almohadas",
    "StaticCategories.KidsBabies.CapsHeadbands": "Gorras y cintas para el cabello",
    "StaticCategories.KidsBabies.Footwear": "Calzado",
    "StaticCategories.KidsBabies.Furniture": "Muebles",
    "StaticCategories.KidsBabies.KidsClothing": "Indumentaria infantil",
    "StaticCategories.KidsBabies.ParenthoodAccessories": "Crianza y accesorios",

    "StaticCategories.Toys": "Juguetes",
    "StaticCategories.Footwear": "Calzado",
    "StaticCategories.Footwear.Boots": "Botas",
    "StaticCategories.Footwear.Flats": "Calzado plano",
    "StaticCategories.Footwear.Heels": "Tacones",
    "StaticCategories.Footwear.Mens": "Hombre",
    "StaticCategories.Footwear.Sandals": "Sandalias",
    "StaticCategories.Footwear.Slippers": "Pantuflas",
    "StaticCategories.Footwear.SneakersRunners": "Zapatillas",

    "StaticCategories.BathBeauty": "Baño y belleza",
    "StaticCategories.BathBeauty.Bodycare": "Cuidado corporal",
    "StaticCategories.BathBeauty.DiffusersOilsCandles": "Difusores, aceites y velas",
    "StaticCategories.BathBeauty.Haircare": "Cuidado capilar",
    "StaticCategories.BathBeauty.Healthcare": "Cuidado de la salud",
    "StaticCategories.BathBeauty.Makeup": "Maquillaje",
    "StaticCategories.BathBeauty.Nails": "Uñas",
    "StaticCategories.BathBeauty.Skincare": "Cuidado de la piel",
    "StaticCategories.BathBeauty.TowelsRobes": "Toallas y batas",

    "StaticCategories.Pets": "Mascotas",
    "StaticCategories.Pets.BedsBlankets": "Camas y ropa de cama",
    "StaticCategories.Pets.LeashesCollarsPetwear": "Correas, collarines y accesorios para mascotas",
    "StaticCategories.Pets.Petcare": "Cuidado de mascotas",
    "StaticCategories.Pets.Toys": "Juguetes",

    "StaticCategories.HomeGarden": "Hogar y jardín",
    "StaticCategories.HomeGarden.Bathroom": "Baño",
    "StaticCategories.HomeGarden.Clocks": "Relojes",
    "StaticCategories.HomeGarden.Drinkware": "Copas y vasos",
    "StaticCategories.HomeGarden.FoodBeverage": "Alimentos y bebidas",
    "StaticCategories.HomeGarden.Furniture": "Muebles",
    "StaticCategories.HomeGarden.Garden": "Jardín",
    "StaticCategories.HomeGarden.HomeDecor": "Decoración del hogar",
    "StaticCategories.HomeGarden.HomeImprovement": "Mejoras para el hogar",
    "StaticCategories.HomeGarden.Kitchen": "Cocina",
    "StaticCategories.HomeGarden.Lighting": "Iluminación",
    "StaticCategories.HomeGarden.Organization": "Organización",
    "StaticCategories.HomeGarden.StationeryCrafts": "Papelería y manualidades",
    "StaticCategories.HomeGarden.TextilesPillows": "Artículos textiles y almohadas",
    "StaticCategories.HomeGarden.Tools": "Herramientas",

    "StaticCategories.Accessories": "Accesorios",
    "StaticCategories.Accessories.Belts": "Cinturones",
    "StaticCategories.Accessories.Cufflinks": "Gemelos",
    "StaticCategories.Accessories.Hats": "Sombreros",
    "StaticCategories.Accessories.Keychains": "Llaveros",
    "StaticCategories.Accessories.ScarvesBandanas": "Bufandas y bandanas",
    "StaticCategories.Accessories.Sunglasses": "Gafas de sol",

    "StaticCategories.MensClothing": "Indumentaria masculina",
    "StaticCategories.MensClothing.Activewear": "Indumentaria deportiva",
    "StaticCategories.MensClothing.DressShirts": "Camisas de vestir",
    "StaticCategories.MensClothing.JacketsCoats": "Chaquetas y abrigos",
    "StaticCategories.MensClothing.Pants": "Pantalones",
    "StaticCategories.MensClothing.Shorts": "Shorts",
    "StaticCategories.MensClothing.Socks": "Calcetines",
    "StaticCategories.MensClothing.SweatersHoodies": "Suéteres y sudaderas",
    "StaticCategories.MensClothing.Swimwear": "Trajes de baño",
    "StaticCategories.MensClothing.TankTops": "Camisetas sin manga",
    "StaticCategories.MensClothing.Tshirts": "Camisetas",
    "StaticCategories.MensClothing.Underwear": "Ropa interior",

    "StaticCategories.SportsOutdoors": "Deporte y vida al aire libre",
    "StaticCategories.SportsOutdoors.Activewear": "Indumentaria deportiva",
    "StaticCategories.SportsOutdoors.Biking": "Ciclismo",
    "StaticCategories.SportsOutdoors.Camping": "Camping",
    "StaticCategories.SportsOutdoors.EquipmentAccessories": "Equipos y accesorios",
    "StaticCategories.SportsOutdoors.Fishing": "Pesca",

    "StaticCategories.Gifts": "Regalos",
    "StaticCategories.Gifts.Adults": "Adultos",
    "StaticCategories.Gifts.Funny": "Divertidos",
    "StaticCategories.Gifts.Mugs": "Tazas",
    "StaticCategories.Gifts.Novelty": "Novedades",
    "StaticCategories.Gifts.Personalized": "Personalizados",
    "StaticCategories.Gifts.Religious": "Religiosos",

    "StaticCategories.Automotive": "Automotores",
    "StaticCategories.BagsWallets": "Bolsos y carteras de bolsillo",
    "StaticCategories.Seasonal": "De temporada",
    "StaticCategories.Seasonal.Christmas": "Navidad",
    "StaticCategories.Seasonal.FathersDay": "Día del padre",
    "StaticCategories.Seasonal.Halloween": "Halloween",
    "StaticCategories.Seasonal.MothersDay": "Día de la madre",

    "StaticCategories.FestivalsParties": "Festivales y fiestas",
    "StaticCategories.FestivalsParties.PartySupplies": "Suministros para fiestas",
    "StaticCategories.FestivalsParties.Wedding": "Bodas",

    "QuickFilters.Label.Premium": "Prémium",
    "QuickFilters.Label.FastUSAShipping": "Envío rápido a Estados Unidos",
    "QuickFilters.Label.Under5": "Envío a Estados Unidos de menos de 5 $",
    "QuickFilters.Label.MoreThan": "More than {{ inventoryAmount }}", // * ENGLISH
    "QuickFilters.Label.ShowAll": "Show All", // * ENGLISH
    "QuickFilters.Label.ShipsFrom": "<1/> Ship from <2/> {{ country }}", // * ENGLISH
    "QuickFilters.Label.ShipsFromAnywhere": "Anywhere", // * ENGLISH
    "QuickFilters.Label.Currency": "Currency", //English
    "QuickFilters.Label.Currency.All": "All", //English

    "SortBy.Label.SortBy": "Ordenar por",
    "SortBy.DropDown.Item.Relevance": "Relevancia",
    "SortBy.DropDown.Item.PriceLowToHigh": "Precio: menor a mayor",
    "SortBy.DropDown.Item.PriceHighToLow": "Precio: mayor a menor",
    "SortBy.DropDown.Item.Newest": "Más nuevo",
    "SortBy.DropDown.Item.ShippingTime": "Tiempo de envío",
    "SortBy.DropDown.Item.Default": "Default", // English
    "SortBy.Label.SortBy.Price": "Sort by Price", // English
    "SortBy.DropDown.Item.LowToHigh": "Low To High", // English
    "SortBy.DropDown.Item.HighToLow": "High To Low", // English

    "Breadcrumb.Item.Home": "Inicio",
    "Breadcrumb.Item.Search": "buscar",

    "UpgradeModal.Modal.Title": "Seleccionar un plan de precios",
    "UpgradeModal.Modal.SubTitle": "Unlock more benefits! Upgrade to <1> {{ plan }} </1> or above.", // English
    "UpgradeModal.Modal.BannerText": "Select a <1>Spocket</1> plan and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English
    "UpgradeModal.Modal.BannerText.PopularPlan": "Most Popular", // English
    
    "CountdownBanner.Component.BannerText": "Select a Spocket plan <1>NOW</1> and <2>get 2 weeks FREE.</2> <3>Cancel Anytime.</3>", // English

    "Plans.ToggleSection.Monthly": "Mensualmente",
    "Plans.ToggleSection.Yearly": "Anualmente",
    "Plans.ToggleSection.DiscountInitial": "Hasta",
    "Plans.ToggleSection.DiscountSingular": "Mes",
    "Plans.ToggleSection.DiscountPlural": "Meses",
    "Plans.ToggleSection.DiscountFinal": "de descuento",
    "Plans.ProrationCost.TextInitial": "Actualmente pagas",
    "Plans.ProrationCost.TextFinal": "por año Opta por Empire por un importe adicional de",
    "Plans.MobileWarning": "Mobile app upgrades can only be modified through the app", //English

    "Card.Title.PeriodSingular": "month", //English
    "Card.Title.PeriodPlural": "months", //English
    "Card.Title.Off": "off", //English
    "Card.DescriptionVariantType.Starter": "Comienza a obtener ventas",
    "Card.DescriptionVariantType.Professional": "Aumenta las ventas",
    "Card.DescriptionVariantType.Empire": "Maximiza las ventas",
    "Card.DescriptionVariantType.Unicorn": "Gestiona las ventas a escala",
    "Card.DescriptionVariantType.Period": "mes",
    "Card.DescriptionVariantType.PricePeriod": "/mes",

    "Card.DescriptionVariantType.AnnualDescription": "facturado anualmente a {{value}} $",
    "Card.DescriptionVariantType.MonthlyDescription": "Free 14-day trial", // English
    "Card.DescriptionVariantType.CurrentPlan": "Tu plan actual",
    "Card.DescriptionVariantType.Button.Start": "Comenzar ahora",
    "Card.DescriptionVariantType.Button.Try": "Try for FREE", // English

    "Card.DescriptionVariantType.FeatureTitle.Starter": "What you get:", // English
    "Card.DescriptionVariantType.FeatureTitle.Professional": "Everything in Starter, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Empire": "Everything in Pro, plus:", // English
    "Card.DescriptionVariantType.FeatureTitle.Unicorn": "Everything in Empire, plus:", // English
    
    "Card.DescriptionVariantType.UniqueProducts": "<1>{{value}}</1> unique products", // English
    "Card.DescriptionVariantType.PremiumProducts": "<1>{{value}}</1> premium products", // English
    "Card.DescriptionVariantType.EmailSupport": "<1>{{value}}</1> & Email support", // English
    "Card.DescriptionVariantType.Invoicing": "Facturación con marca",
    "Card.DescriptionVariantType.ChatSupport": "24/7 Chat & Email Support", // English
    "Card.DescriptionVariantType.WinningProducts": "Productos ganadores",
    "Card.DescriptionVariantType.SupplierSourcing": "Abastecimiento de proveedores",
    "Card.DescriptionVariantType.ProductRequests": "Solicitudes de producto",
    "Card.DescriptionVariantType.BulkCheckout": "Finalización en bloque",
    "Card.DescriptionVariantType.Special": "especial",
    "Card.DescriptionVariantType.AliExpressDropshipping": "AliExpress Dropshipping", // English
    "Card.DescriptionVariantType.TransactionFee": "<1>{{value}}</1> transaction fee", // English
    "Card.DescriptionVariantType.ProductCatalog": "7million+ product catalog", // English
    "Card.DescriptionVariantType.ImageSearch": "Image Search", // English
    "Card.DescriptionVariantType.ChatSupplier": "Chat with suppliers", // English
    "Card.DescriptionVariantType.EbayDropshipping": "Ebay dropshipping", // English
    "Card.DescriptionVariantType.Orders": "<1>{{value}}</1> Orders", // English
    "Card.DescriptionVariantType.SpocketAcademy": "Spocket <1>{{value}}</1>", // English
    "Card.DescriptionVariantType.EmailSupport.Highlight": "Vip Chat", // English
    "Card.DescriptionVariantType.Orders.Highlight": "Unlimited", // English
    "Card.DescriptionVariantType.SpocketAcademy.Highlight": "Academy", // English

    "ReactivationBanner.Alert": "Tu suscripción Spocket ha finalizado.",
    "ReactivationBanner.InfoSubtitle": "Reactiva tu",
    "ReactivationBanner.CheckList.HighQuality": "Alta calidad",
    "ReactivationBanner.CheckList.Curated": "Seleccionada especialmente",
    "ReactivationBanner.CheckList.FastShipping": "Envío rápido",
    "ReactivationBanner.CheckList.FromUSAndEU": "De Estados Unidos y la Unión Europea",
    "ReactivationBanner.TextByPlan.Starter.Description": "Ya no podrás <1>promocionar ningún producto</1>",
    "ReactivationBanner.TextByPlan.Starter.Title": "Cuenta Spocket",
    "ReactivationBanner.TextByPlan.Starter.Subtitle": "Comienza a promocionar productos en tu tienda ahora",
    "ReactivationBanner.TextByPlan.Others.Description.PartOne":
      "Ya no puedes <1>promocionar productos</1> en vivo ni usar ",
    "ReactivationBanner.TextByPlan.Others.Description.PartTwo": "Productos prémium",
    "ReactivationBanner.TextByPlan.Others.Title": "Cuenta prémium",
    "ReactivationBanner.TextByPlan.Others.Subtitle": "Obtén acceso a productos prémium que son",
    "ReactivationBanner.Button": "Reactivar mi cuenta AHORA",

    "AnnualPromotionModal.ButtonContainerTop.LimitedTime": "Limited time offer", // English
    "AnnualPromotionModal.ButtonContainerTop.Discount": "Save up to {{ discount }}%", // English
    "AnnualPromotionModal.ButtonContainerTop.PlanDescription": "Get {{ planName }} at {{ monthValue }}/mo, billed annually at {{ yearValue }}", // English
    "AnnualPromotionModal.ButtonContainerTimer.Minutes": "minutes", // English
    "AnnualPromotionModal.ButtonContainerTimer.Seconds": "seconds", // English
    "AnnualPromotionModal.ButtonDisclaimer": "By clicking, you agree to the <1>T&C</1>, <2>Privacy</2>, <3>Cancellation</3> and <4>Refund</4> policies.", // English
    "AnnualPromotionModal.AnnualButton.Button.Title": "Upgrade your plan to annual", // English
    "AnnualPromotionModal.AnnualButton.Button.Label": "Upgrade to annual", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Title": "Continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.Cancel.Label": "No, continue with monthly plan", // English
    "AnnualPromotionModal.AnnualButton.DisclaimerText": "You will be charged in full immediately.", // English
    "AnnualPromotionModal.ImageContainer.QuoteText": "\"I have earned <1>$442,991</1> USD in just six months by building a dropshipping business that people loved\".", // English

    "UpgradeReasonModal.Benefits.IncreasedProducts": "Más productos promocionados",
    "UpgradeReasonModal.Benefits.UnlimitedOrders": "Pedidos ilimitados",
    "UpgradeReasonModal.Benefits.BrandedInvoicing": "Facturación con marca",
    "Checkout.PlanFeatures.SupplierSourcing": "Supplier <strong>sourcing</strong>", // English
    "Checkout.PlanFeatures.ProductRequests": "Product <strong>requests</strong>", // English  
    "UpgradeReasonModal.Benefits.PremiumProducts": "Productos prémium",
    "UpgradeReasonModal.Benefits.ExclusiveDeals": "Ofertas exclusivas",
    "UpgradeReasonModal.Benefits.ChatCallSupport": "Servicio de asistencia por chat y teléfono",
    "UpgradeReasonModal.Modal.Title": "¿Qué quieres lograr con Spocket?",
    "UpgradeReasonModal.Modal.Question": "¿Por qué te has pasado de plan?",
    "UpgradeReasonModal.Modal.Button": "Comienza a usar Spocket {{plan}}",
    "UpgradeReasonModal.UpgradeComment.PlaceHolder": "Nos ayuda a mejorar tu experiencia",

    "UpgradeSuccessModal.Col.One.A": "Felicitaciones",
    "UpgradeSuccessModal.Col.One.Yearly": "You are now on the {{alias}} Annual plan!", // English
    "UpgradeSuccessModal.Col.One.Monthly": "You are now on the {{alias}} Monthly plan!", // English
    "UpgradeSuccessModal.Col.Two.A":
      "Has desbloqueado todas las herramientas que necesitas para construir un negocio de dropshipping exitoso.",
    "UpgradeSuccessModal.Col.Two.B": "Ahora puedes tener ",
    "UpgradeSuccessModal.Col.Two.C": " ¡Disfruta Spocket!",
    "UpgradeSuccessModal.Button": "Continuar",
    "UpgradeSuccessModal.FeaturesIntro": "You can now have:", // English

    "Promotion.TrialDaysNormalizer.30days": "1 mes",
    "Promotion.TrialDaysNormalizer.28days": "4 semanas",
    "Promotion.TrialDaysNormalizer.21days": "3 semanas",
    "Promotion.TrialDaysNormalizer.14days": "2 semanas",
    "Promotion.TrialDaysNormalizer.7days": "1 semana",
    "Promotion.TrialDaysNormalizer.1day": "1 día",
    "Promotion.TrialDaysNormalizer.Days": "{{value}} días",
    "Promotion.TrialDaysPlan.Title.FreePlan": "Try Spocket {{ planName }} and get 2 weeks free!", // English
    "Promotion.TrialDaysPlan.Title.StarterPlan": "Try Spocket {{ planName }} and get access to Premium products", // English
    "Promotion.TrialDaysPlan.Title.ProPlan": "Try Spocket {{ planName }} and start chatting with Suppliers", // English
    "Promotion.TrialDaysPlan.Text": "Try Spocket {{nextPlan}} now and get {{value}} free!", // English
    "Promotion.Button": "Pruébala gratis",
    "Promotion.Button.ExpiredTrial": "Start Now", // English
    "StickyPromotion.Text": "Try Spocket {{nextPlan}} free for", // English
    "StickyPromotion.Text.ExpiredTrial": "Try Spocket {{nextPlan}}", // English
    "StickyPromotion.Button": "Probar ahora →",
    "StickyPromotion.Button.ExpiredTrial": "Start now →", // English


    "Language.Title": "Idioma",

    "WhatYouWillLoseModal.Button.Offer": "Continuar",
    "WhatYouWillLoseModal.Button.Downgrade": "Me doy por vencido, quiero bajar de categoría mi cuenta",
    "WhatYouWillLoseModal.ProductSection.Title":
      "Perderás todos los <1>{{product_count}} productos importados</1>",
    "WhatYouWillLoseModal.Product.PremiumTag": "Prémium",
    "WhatYouWillLoseModal.Product.SkeletonImage": "Más de {{product_count}}",
    "WhatYouWillLoseModal.ModalWrapper.Header": "¿ya te das por vencido?",
    "WhatYouWillLoseModal.ModalWrapper.Title.Tag": "Obtén un 50 % de descuento",
    "WhatYouWillLoseModal.ModalWrapper.Title": "Continúa tu camino empresarial durante los próximos 3 meses.",
    "WhatYouWillLoseModal.ModalWrapper.Subtitle":
      "Queremos que la tuya también sea una historia de éxito. Sabemos que llevas mucho tiempo invertido. Así que continúa. Siempre estaremos para lo que necesites. El equipo de Spocket",

    "FiftyOff3MonthsOfferSuccessModal.Header":
      "Felicitaciones, hemos agregado un descuento del 50 % a tu plan para los próximos 3 meses.",
    "FiftyOff3MonthsOfferSuccessModal.Button": "Continuar",

    "DowngradeReasonModal.DowngradeSection":
      "¿Cómo podemos hacer que Spocket se adapte mejor a tus necesidades?",
    "DowngradeReasonModal.DowngradeSection.TextArea.PlaceHolder":
      "Queremos escuchar tu opinión (100 caracteres como mínimo)",
    "DowngradeReasonModal.DowngradeSection.Tooltip": "Escribe tu comentario (100 caracteres como mínimo)",
    "DowngradeReasonModal.Button.Downgrade": "Bajar de categoría",
    "DowngradeReasonModal.ModalWrapper.Header": "¿Quieres bajar de categoría a {{storeName}}?",
    "DowngradeReasonModal.ModalWrapper.ContactUs": "Contáctanos",
    "DowngradeReasonModal.ModalWrapper.Footer.Button.Cancel": "Cancelar",

    "DowngradeSuccessModal.Header": "Ya bajamos tu categoría",
    "DowngradeSuccessModal.Body":
      "Gracias por tus comentarios. Estamos mejorando Spocket constantemente. Esperamos que pronto nos des otra oportunidad.",

    "Settings.title": "Ajustes",
    "Settings.buttonSaveChanges": "Guardar cambios",
    "Settings.buttonSaveAndPreview": "Guardar y consultar vista previa",
    "Settings.PricingPlans.Title": "Planes de precios",

    "Settings.MenuTab.Plans": "Planes",
    "Settings.MenuTab.Account": "Cuenta",
    "Settings.MenuTab.BrandedInvoicing": "Facturación con marca",
    "Settings.MenuTab.Billing": "Facturación",
    "Settings.MenuTab.GlobalPricingRules": "Reglas de precios globales",
    "Settings.MenuTab.Security": "Seguridad",

    "AccountTab.documentTitle": "Ajustes - Cuenta - Spocket",
    "AccountTab.title": "Ajustes de cuenta",
    "AccountTab.shopName": "Nombre de la tienda",
    "AccountTab.email": "Correo electrónico",
    "AccountTab.shopUrl": "URL de la tienda",
    "AccountTab.defaultCurrency": "Moneda predeterminada",
    "AccountTab.yourPlan": "Tu plan",
    "AccountTab.accountNotActive": "Tu cuenta no está activa en este momento",
    "AccountTab.alertFeatureWillBeAddedSoon":
      "¡Pronto agregaremos esta función! Por ahora, conéctate a través de la app de Shopify de Spocket.",
    "AccountTab.alertAccountSettingsUpdated": "Ajustes de cuenta actualizados",
    "AccountTab.alertInvalidShopUrl": "La URL de la tienda no es válida",
    "AccountTab.productsImported": "Productos importados",
    "AccountTab.products": "Productos",
    "AccountTab.premiumProducts": "Productos prémium",
    "AccountTab.total": "total",
    "AccountTab.signOut": "Cerrar sesión",
    "AccountTab.limitReachedProductsAndPremium": "Has llegado al límite de productos y productos prémium.",
    "AccountTab.limitReachedProducts": "Has llegado al límite de productos.",
    "AccountTab.limitReachedPremium": "Has llegado al límite de productos prémium.",
    "AccountTab.buttonSwitchToAnnualPlan": "Cambiar al plan anual",
    "AccountTab.off30Percent": "30 % de descuento",
    "AccountTab.off40Percent": "40 % de descuento",
    "AccountTab.off45Percent": "45 % de descuento",
    "AccountTab.sellMoreWithEmpirePlan": "Vende más con el plan Empire",
    "AccountTab.tooltipCurrency":
      "Tu moneda se gestiona a través de la cuenta de tu tienda. Todos los precios de tus productos importados se convertirán a esa moneda.",
    "AccountTab.shopNotConnectedYet": "No has conectado tu tienda con esta cuenta aún.",
    "AccountTab.connectYourShop": "Conecta tu tienda",

    "SignOut.SignOutConfirmation.Title": "Log Out?", // English
    "SignOut.SignOutConfirmation.Text": "Are you sure you want to log out from Spocket?", // English
    "SignOut.SignOutConfirmation.Confirm.Title": "Log out of Spocket", // English
    "SignOut.SignOutConfirmation.Confirm.Label": "Log out", // English
    "SignOut.SignOutConfirmation.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutConfirmation.Cancel.Label": "Cancel", // English

    "SignOut.SignOutNewPassword.Title": "Hold Up!", // English
    "SignOut.SignOutNewPassword.Text": "Please set a password before logging out!", // English
    "SignOut.SignOutNewPassword.Confirm.Title": "Save & Log out of Spocket", // English
    "SignOut.SignOutNewPassword.Confirm.Label": "Save & Log out", // English
    "SignOut.SignOutNewPassword.Cancel.Title": "Cancel log out", // English
    "SignOut.SignOutNewPassword.Cancel.Label": "Cancel", // English
    "SignOut.SignOutNewPassword.Error.NoPassword": "Please enter a password", // English
    "SignOut.SignOutNewPassword.Error.NoPasswordConfirmation": "Please enter a password confirmation", // English
    "SignOut.SignOutNewPassword.Error.PasswordsDontMatch": "Passwords do not match", // English
    "SignOut.SignOutNewPassword.Error.PasswordsLength": "Password must be 8 characters or longer", // English

    "InvoiceTab.documentTitle": "Ajustes - Facturación - Spocket",
    "InvoiceTab.alertInvoiceSettingSaved": "¡Se han guardado los ajustes de tu factura!",
    "InvoiceTab.shopLogo": "Logotipo de la tienda",
    "InvoiceTab.contactEmail": "Correo electrónico de contacto",
    "InvoiceTab.phoneNumber": "Número de teléfono",
    "InvoiceTab.personalNote": "Nota personal",
    "InvoiceTab.tooltipPersonalNote":
      "Escribe una nota personal que se incluirá en cada una de tus facturas con marca",
    "InvoiceTab.brandedInvoicing": "Facturación con marca",
    "InvoiceTab.tooltipBrandedInvoicing":
      "Con el plan de pago, puedes agregar el logotipo de tus marcas y una nota personal a todos los pedidos de los proveedores que ofrecen facturación con marca",
    "InvoiceTab.disabledInvoicing": "Incluye la facturación con marca en todos los pedidos",
    "InvoiceTab.tooltipDisabledInvoicing":
      "Al desactivar esta opción, la facturación con marca quedará excluida cuando realices tus pedidos",

    "PricingTab.titleDefaultPricingRules": "Reglas de precios predeterminadas",
    "PricingTab.tooltipTitleDefaultPricingRules": "Set a markup on product cost + domestic shipping cost for all your imported listings", // English
    "PricingTab.titleAdvancedPricingRules": "Reglas avanzadas de precios",
    "PricingTab.tooltipTitleAdvancedPricingRules": "Set your markups based on total cost ranges (product cost + domestic shipping cost)", // English
    "PricingTab.assignCents": "Asignar céntimos",
    "PricingTab.tooltipAssignCents":
      "Este valor se usará al mostrar el precio final de tus artículos (por ejemplo: si quieres que el coste de tu producto sea XX,99, entonces agrega 99 en los campos que aparecen más abajo).",
    "PricingTab.Markup": "Margen de ganancia",
    "PricingTab.MarkupType": "Tipo de margen de ganancia",
    "PricingTab.SelectDefault": "-- Seleccionar uno --",
    "PricingTab.SelectPercent": "Porcentaje",
    "PricingTab.SelectMultiplier": "Multiplicador",
    "PricingTab.SelectFixed": "Fijo",
    "PricingTab.titlePricingRules": "Ajustes - Reglas de precio - Spocket",
    "PricingTab.toggleAdvancedPricingRules": "Activar o desactivar las reglas avanzadas de precios",
    "PricingTab.from": "Desde",
    "PricingTab.to": "Hasta",
    "PricingTab.selectFixedAmount": "Importe fijo",
    "PricingTab.buttonRemove": "Eliminar",
    "PricingTab.alertCannotCreateMoreThanOneRuleWithEmptyFields":
      "No puedes crear más de una regla con campos vacíos",
    "PricingTab.costRange": "Rango de coste",
    "PricingTab.markup": "Margen de ganancia",
    "PricingTab.add": "Agregar",

    "SecurityTab.Document.Title": "Ajustes - Seguridad - Spocket",
    "SecurityTab.alertIncorrectPassword": "Contraseña incorrecta",
    "SecurityTab.title": "Seguridad",
    "SecurityTab.yourNewPassword": "Tu nueva contraseña",
    "SecurityTab.repeatPassword": "Repetir contraseña",
    "SecurityTab.errorPleaseEnterAPassword": "Introduce una contraseña.",
    "SecurityTab.errorPasswordsConfirmationNeeded": "Introduce la confirmación de la contraseña.",
    "SecurityTab.errorPasswordsDoNotMatch": "Las contraseñas no coinciden",
    "SecurityTab.errorPasswordShort": "La contraseña debe tener 8 caracteres o más",

    "ListingCards.TopSupplierTag.Tooltip":
      "Los proveedores principales son conocidos por la alta calidad de sus productos, su gran servicio y sus buenas calificaciones",
    "ListingCards.TopSupplierTag.Text": "Proveedor",

    "ListingCard.OriginCountry.Intro": "Por",
    "ListingCard.OriginCountry.Tooltip": "Se envía desde",
    "ListingCard.Shipping.Price.Title": "Precio de envío",
    "ListingCard.Shipping.Price.Free": "GRATIS",
    "ListingCard.Price.Intro": "Coste de lista",
    "ListingCard.Price.Retail": "Precio de venta",
    "ListingCard.Shipping.Time.Title": "Tiempo de envío",
    "ListingCard.Shipping.Time.Period": "días",
    "ListingCard.PremiumIcon.Tooltip":
      "Vende en tu tienda productos exclusivos, con grandes descuentos y envío rápido",
    "ListingCard.PremiumIcon.Text": "Prémium",
    "ListingCard.ListButton.Import": "Agregar a la lista de importación",
    "ListingCard.ListButton.Remove": "Remove from Import List", // English
    "ListingCard.ListButton.Pushed": "Promocionado en la tienda",
    "ListingCard.StatusTag.Imported.Title": "Importado",
    "ListingCard.StatusTag.Imported.Text": "Este artículo ya está en tu lista de importación",
    "ListingCard.StatusTag.Pushed.Title": "En la tienda",
    "ListingCard.StatusTag.Pusehd.Text": "Este artículo se está promocionando en tu tienda",
    "ListingCard.Error.ImportLimit": "You have reached the limit of products in your import list", // English
    "ListingCard.OrderSamples": "Order Samples", // English

    "ListingModal.Page.Title": "Product Details", // English
    "ListingModal.Tabs.DescriptionHeader": "Product Description", // English
    "ListingModal.Tabs.ReviewsHeader": "Reviews", // English
    "ListingModal.MainSection.Title": "Descripción del producto",
    "ListingModal.DetailsSection.Button.ProductVariations": "Abrir variantes del producto",
    "ListingModal.DetailsSection.Button.OrderSamples": "Solicitar muestras",
    "ListingModal.DetailsSection.Button.OrderSamples.New": "Nuevo",
    "ListingModal.DetailsSection.BrandedInvoicing.Alert":
      "La facturación con marca no está disponible para este producto",
    "ListingModal.DetailsSection.InternationalShipping.Alert":
      "Los pedidos internacionales se enviarán desde nuestro almacén en China",
    "ListingModal.DetailsSection.StockUsa.Alert":
      "Los artículos que estén agotados en el almacén de los Estados Unidos, probablemente, se envíen desde nuestro almacén en China",
    "ListingModal.DetailsSection.MultiplePackage.Alert":
      "Es posible que se envíen los pedidos en varios paquetes para reducir el tiempo de entrega general de tus pedidos",
    "ListingModal.DetailsSection.ConnectStore.Alert": "Conecta tu tienda para realizar un pedido",
    "ListingModal.DetailsSection.PushedListButton": "Promocionado en la tienda",
    "ListingModal.DetailsSection.RemoveListButton": "Eliminar de la lista",
    "ListingModal.DetailsSection.ImportListButton": "Agregar a la lista de importación",
    "ListingModal.DetailsSection.ImportListButton.Deactivated": "Lista desactivada",

    "InfoSection.ShippingTime.Tooltip":
      "Número de días para que se entregue el producto después de su envío.",
    "InfoSection.ShippingTime.Title": "Tiempo de envío",
    "InfoSection.PolicySection.Title": "If the return is due to supplier error (e.g. defective/damaged item, wrong product delivered, etc), the supplier will shoulder the return shipping fee. However, if the return is due to customer issue (e.g. wrong item ordered, customer remorse, etc), the customer is responsible for return postage.", // English
    "InfoSection.ShippingInfo.Worldwide": "Internacional",
    "InfoSection.ShippingExcluded.Intro": "No se puede enviar a",
    "InfoSection.ShippingExcluded.Various": "Diferentes",
    "InfoSection.ProcessingTime.Tooltip":
      "Número de días hábiles que tarda el proveedor en realizar el envío y proporcionar un número de seguimiento",
    "InfoSection.ProcessingTime.Title": "Tiempo de procesamiento",
    "InfoSection.ProcessingTime.Period": "días hábiles",
    "InfoSection.ShippingInfoLine.Tooltip":
      "Este producto se envía por {{price_formatted}}, más {{incremental_price_formatted}} por cada producto adicional en el mismo pedido",
    "InfoSection.ShippingInfoLine.Period": "días hábiles",
    "InfoSection.ShippingInfoLine.Free": "GRATIS",
    "InfoSection.ShippingInfoLine.NoShipping": "No hace envíos",

    "ReturnPolicySection.Title": "Política de devoluciones",

    "TitleSection.TitleCountryOrigin.CountryOrigin.Intro": "Desde",
    "TitleSection.TitleCountryOrigin.SupplierShopName.Intro": "Por",
    "TitleSection.PriceMSRP.Listing": "Precio de lista",
    "TitleSection.PriceMSRP.Retail": "Precio de venta",

    "ProductVariationsModal.Title": "Variantes del producto",
    "ProductVariationsModal.Table.Image": "Imagen",
    "ProductVariationsModal.Table.Inventory": "Inventario",
    "ProductVariationsModal.Table.Price": "Precio",
    "ProductVariationsModal.ImageSpecifications.Title": "Tabla de tallas",
    "ProductVariationsModal.ImageSpecifications.Button": "Descargar",

    "TopSupplier.Tooltip":
      "Los proveedores principales son conocidos por la alta calidad de sus productos, su gran servicio y sus buenas calificaciones",
    "TopSupplier.Text": "Proveedor principal",

    "BestSelling.Tooltip.Top": "Este es uno de los productos principales de Spocket",
    "BestSelling.Tooltip.Bottom": "Más vendido",

    "GallerySection.PremiumIcon.Tooltip":
      "Vende en tu tienda productos exclusivos, con grandes descuentos y envío rápido",
    "GallerySection.PremiumIcon.Text": "Prémium",
    "GallerySection.DiscountStamp.Text": "{{value}} % de descuento adicional",

    "Upgrade.Title.AnnualToggled": "Obtén Spocket prémium hoy",
    "Upgrade.Title.MonthlyToggled": "Prueba Spocket prémium gratis durante 14 días",
    "Upgrade.Subtitle":
      "Prueba un plan prémium de Spocket hoy y comienza a agregar en tu tienda productos con alta conversión desde Estados Unidos y Europa.",
    "Upgrade.Button": "Continuar sin Spocket prémium",

    "Search.NoProductsFound.Title": "¡Te pedimos disculpas! No pudimos encontrar resultados",
    "Search.NoProductsFound.For": "para",
    "Search.NoProductsFound.Description":
      "Verifica que esté bien escrito, intenta buscar un término relacionado o uno más general.",
    "Search.Searching.Title": "Estamos buscando productos...",
    "Search.Searching.Description": "Espera mientras cargamos los últimos productos para ti.",

    "Suppliers.StarRating.Tooltip": "Tasa de cumplimiento de pedidos por parte del proveedor",
    "Suppliers.RefundPolicy": "Política de reembolso del proveedor",
    "Suppliers.Joined.Title": "Se unió a Spocket",
    "Suppliers.Origin.Title": "Envío desde el país",
    "Suppliers.Time.Title": "Tiempo promedio de procesamiento",
    "Suppliers.HasMore.Title": "Estamos buscando productos...",
    "Suppliers.HasMore.Subtitle": "Espera mientras cargamos los últimos productos para ti.",
    "Suppliers.TopSupplierTag.Tooltip":
      "Los proveedores principales son conocidos por la alta calidad de sus productos, su gran servicio y sus buenas calificaciones",
    "Suppliers.TopSupplierTag.Text": "Proveedor principal",

    "AltTextModal.Header.Title": "Editar información de la imagen",
    "AltTextModal.Body.Description":
      "Escribe una breve descripción de esta imagen para mejorar el posicionamiento en los buscadores (SEO) y la accesibilidad para los clientes con discapacidad visual.",
    "AltTextModal.Input.Label": "Texto alternativo",
    "AltTextModal.Input.Placeholder": "Texto alternativo de la imagen",
    "AltTextModal.Button.Cancel": "Cancelar",
    "AltTextModal.Button.Save": "Guardar",

    "WistiaModal.WhatIsSpocket.Title": "¿Qué es Spocket?",
    "WistiaModal.WhatIsSpocket.Text":
      "Conoce de qué manera Spocket puede ayudarte a desarrollar tu negocio. Aprende cómo funciona Spocket y cómo aprovecharlo al máximo.",
    "WistiaModal.WhatIsSpocket.Button": "Comienza a buscar productos",
    "WistiaModal.DiscoverProducts.Title": "Descubre productos",
    "WistiaModal.DiscoverProducts.Text":
      "Encuentra los productos correctos con la ayuda de los filtros adecuados y agrégalos a tu tienda.",
    "WistiaModal.DiscoverProducts.Button": "Comienza a buscar productos",
    "WistiaModal.ProductCustomization.Title": "Personaliza tus productos",
    "WistiaModal.ProductCustomization.Text":
      "Usa la lista de importación para modificar tus productos para que se adapten a tu tienda. Puedes editar la descripción del producto, establecer los precios, agregar el producto a una colección y eliminar imágenes.",
    "WistiaModal.ProductCustomization.Button": "Ir a la lista de importación",
    "WistiaModal.OrderProcessing.Title": "Procesamiento automatizado de pedidos",
    "WistiaModal.OrderProcessing.Text":
      "Completa tus pedidos y haz que el proveedor entregue el producto en la puerta del cliente con un solo clic.",
    "WistiaModal.OrderProcessing.Button": "Ir a los pedidos",
    "WistiaModal.Settings.Title": "Ajustes",
    "WistiaModal.Settings.Text":
      "La página de ajustes te permite gestionar toda la información de tu cuenta, ponerle la marca a tus facturas y agregar tu tarjeta de crédito. También puedes ajustar las reglas de precios para todos tus productos y elegir un plan de suscripción.",
    "WistiaModal.Settings.Button": "Configura tu cuenta",

    "Footer.Copyright": "Copyright {{year}}, Spocket. Todos los derechos reservados",
    "Footer.PrivacyPolicy": "Política de privacidad",
    "Footer.TermsConditions": "Términos y condiciones",
    "Footer.DataProcessingAddendum": "Apéndice sobre procesamiento de datos",

    "AlibabaOrderDetails.Title": "Order Shipments", // English
    "AlibabaOrderDetails.Subtitle": "This order is divided into multiple shipments, and each can be accessed at alibaba.com", // English
    "AlibabaOrderDetails.TableHeader.OrderId": "Order ID", // English
    "AlibabaOrderDetails.TableHeader.Status": "Status", // English
    "AlibabaOrderDetails.TableHeader.Action": "Action", // English
    "AlibabaOrderDetails.Button.OpenOrder": "Open Order", // English

    "AliExpressOrderDetails.Subtitle": "This order may be divided into multiple shipments.", // English

    "Maintenance.GoBackHome": "Go Back Home", //English
    "Maintenance.MainMessage": "<1>Spocket will be back soon! <2>Spocket is running into some issues, but we're fixing it as we speak.</2><1/>", //English

    "ConnectStore.Alert.Message": "Please connect your store", //English
    "ConnectStore.Store.Intro.Shopify": "Add your Shopify Store URL", //English
    "ConnectStore.Button": "Connect Store", //English
    "ConnectStore.Error.MissingUrl": "*Add a store URL", //English
    "ConnectStore.Error.Select": "*Select a country", //English
    "ConnectStore.Button.Title": "Connect your store to Ebay", //English
    "ConnectStoreModal.WoocommerceInstruction.UpdatedStep1": "Install Spocket plugin by clicking on \"Connect\" button below.", //English


    "Products.Search.Filter.Button": "Filter", //English
    "Products.Filters.Option.LowStock": "Low stock (less than 10)", //English
    "Products.Filters.Option.OutOfStock": "Out of stock", //English
    "Products.Filters.Option.Deactivated": "Deactivate", //English
    "Products.Filters.Option.Default": "Select status", //English
    "Products.Filters.Title": "Filter", //English
    "Products.Filters.Subtitle": "Inventory Status", //English
    "Products.Filters.Button.Reset": "Reset", //English
    "Products.Filters.Button.Title.Reset": "Reset all filter options", //English
    "Products.Filters.Button.Apply": "Apply Filter", //English
    "Products.Filters.Button.Title.Apply": "Apply selected filters", //English
    "Products.Filters.Tag.InventoryStatus": "Inventory status: ", //English

    "CardImage.ArrowContainer.NextImage": "Next image", //English
    "CardImage.ArrowContainer.PreviousImage": "Previous image", //English

    "Api.Error.Authorization": "Your session has expired. Please log in.", //English
    "Api.Error.NoPage": "There was something wrong. The page you're trying to access does not exist.", //English

    "Settings.Title": "My Settings", //English
    "Settings.Error.Title": "Internal server error!", //English
    "Settings.Error.Text": "There was an error loading this page. Please try refreshing this page or contact customer support.", //English
    "Settings.Tabs.Title.Plans": "Plans", //English
    "Settings.Tabs.Title.Account": "Account", //English
    "Settings.Tabs.Title.Membership": "Membership", //English
    "Settings.Tabs.Title.Store": "Store", //English
    "Settings.Tabs.Title.Pricing": "Global Pricing", //English
    "Settings.Tabs.Title.Apps": "Apps", //English
    "Settings.PageTitle.plans" : "Plan Details - Spocket", //English
    "Settings.PageTitle.account" : "Account Settings - Spocket", //English
    "Settings.PageTitle.membership" : "Membership Settings - Spocket", //English
    "Settings.PageTitle.store" : "Store Settings - Spocket", //English
    "Settings.PageTitle.pricing" : "Pricing Settings - Spocket", //English
    "Settings.PageTitle.apps" : "Apps - Spocket", //English

    "Settings.Apps.Title": "Apps", //English
    "Settings.Apps.Description": "This section contains information about your apps.", //English
    "Settings.Apps.Aliexpress.Title": "Connect To AliExpress", //English
    "Settings.Apps.Aliexpress.Description": "Access millions of low cost dropshipping products from AliExpress, with automated order sync from Spocket", //English
    "Settings.Apps.Aliexpress.Feature.1": "Access to 100 million+ products", //English
    "Settings.Apps.Aliexpress.Feature.2": "Automated order sync", //English
    "Settings.Apps.Aliexpress.Feature.3": "Manage all dropship orders in centralized location", //English
    "Settings.Apps.Aliexpress.Connected": "You have successfully connected to AliExpress.", //English
    "Settings.Apps.Aliexpress.Connected.Button": "Go to AliExpress", //English

    "Settings.Membership.MembershipInformation.Title": "Membership Information", //English
    "Settings.Membership.MembershipInformation.Subtitle": "This section contains information about your membership with Spocket.", //English
    "Settings.Membership.MembershipTrialWarning.NoDaysRemaining": "Your free trial has expired and your plan will automatically renew today. You will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipTrialWarning.Text": "Your free trial is expiring in {{remaining_days}} day(s), your plan will automatically renew on <1>{{renew_date}}</1> and you will be charged <1>{{renew_formatted_value}}</1>.", //English
    "Settings.Membership.MembershipUserData.YourPlan": "Your Plan", //English
    "Settings.Membership.MembershipUserData.Joined": "Joined Spocket at", //English
    "Settings.Membership.MembershipUserData.Amount": "Plan Amount", //English
    "Settings.Membership.MembershipUserData.NextBilling": "Next Billing Cycle", // English
    "Settings.Membership.MembershipUserData.PlanValidUntil": "Plan Valid Until", //English
    "Settings.Membership.MembershipUserData.Cycle.Annual": "year", //English
    "Settings.Membership.MembershipUserData.Cycle.Month": "month", //English
    "Settings.Membership.MembershipCancellation.Title": "Membership Cancellation", //English
    "Settings.Membership.MembershipCancellation.Subtitle": "This section allows provides you with options to pause or cancel your membership.", //English
    "Settings.Membership.MembershipCancellation.Button.Title": "Cancel your Spocket membership", //English
    "Settings.Membership.MembershipCancellation.Button.Label": "Cancel Membership", //English
    "Settings.Membership.MembershipCancellation.Tooltip": "You have cancelled your Spocket membership. Enjoy all plan benefits until this date. Renew your membership to avoid any interruptions.", //English
    "Settings.Membership.MembershipRemindLater.Title": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Subtitle": "You can continue to keep your benefits and set a reminder about your membership 3 days before it renews.", //English
    "Settings.Membership.MembershipRemindLater.Subtitle.Active": "You have set a reminder to notify you 3 days before the renewal of your membership.", //English
    "Settings.Membership.MembershipRemindLater.Button.Title": "Activate reminder", //English
    "Settings.Membership.MembershipRemindLater.Button.Label": "Remind me later", //English
    "Settings.Membership.MembershipRemindLater.Error": "There was an error changing the reminder", //English
    "Settings.Membership.MembershipPause.Title": "Pause your plan", //English
    "Settings.Membership.MembershipPause.Subtitle": "You can pause your Spocket account temporarily and come back at any time without losing any data.", //English
    "Settings.Membership.MembershipPause.Button.Title": "Pause current plan", //English
    "Settings.Membership.MembershipPause.Button.Label": "Pause plan", //English
    "Settings.Membership.MembershipPause.PauseCall.Success": "Your plan was successfully paused.", //English
    "Settings.Membership.MembershipPause.PauseCall.Error": "There was an error pausing your plan.", //English
    "Settings.Membership.UpgradePlan.Title": "Sell more with <1>{{name}} Plan</1>", //English
    "Settings.Membership.UpgradePlan.Yearly": "Yearly", //English
    "Settings.Membership.UpgradePlan.Description": "This Plan was Recommended by leading Dropshippers", //English
    "Settings.Membership.UpgradePlan.Button.Label": "Upgrade Plan", //English
    "Settings.Membership.UpgradePlan.Button.Title": "Upgrade your plan", //English
    "Settings.Membership.UpgradeAnnual.Title": "Save up to {{value}} with <1>{{name}} - Annual Plan</1>", //English
    "Settings.Membership.UpgradeAnnual.Description": "Get up to 5 months off by paying yearly!", //English
    "Settings.Membership.UpgradeAnnual.Button.Label": "Switch to Annual", //English
    "Settings.Membership.UpgradeAnnual.Button.Title": "Switch to annual plan", //English
    "Settings.Membership.Billing.Header.Billing": "Billing Period", //English
    "Settings.Membership.Billing.Header.Amount": "Amount", //English
    "Settings.Membership.Billing.Header.Status": "Status", //English
    "Settings.Membership.Billing.Header.Invoice": "E-Invoice", //English
    "Settings.Membership.Billing.Title": "Billing History", //English
    "Settings.Membership.Billing.Description": "This section contains information about your billing history.", //English
    "Settings.Membership.Billing.RequestInvoices": "Contact your store provider to request invoices for your Spocket subscription.", //English
    "Settings.Membership.Billing.EmptyState": "There are no invoices available for your account at the moment.", //English
    "Settings.Membership.Billing.Table.Amount.Tooltip": "has been credited", //English
    "Settings.Membership.Billing.Table.Billing.From": "From: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.Billing.To": "To: <1>{{ date }}</1>", //English
    "Settings.Membership.Billing.Table.StatusMessage.Payment": "This payment is either due soon or in the process of being collected", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotApplicable": "This invoice is either posted, pending or voided", //English
    "Settings.Membership.Billing.Table.StatusMessage.NotPaid": "This payment has failed", //English
    "Settings.Membership.Billing.ErrorDownload": "Error while downloading file", // English

    "Settings.Membership.Addon.Chat.Title": "Add On - Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Subitle": "This section provides you with options to pause or cancel your add ons membership.", // English
    "Settings.Membership.Addon.Chat.RightTitle": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.CancelButton.Label": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.CancelButton.Title": "Cancel this Add On", // English
    "Settings.Membership.Addon.Chat.Enabled.Description": "You are currently subscribed to chat message feature to communicate with suppliers directly", // English
    "Settings.Membership.Addon.Chat.Disabled.Description": "You are not currently subscribed to chat message feature to communicate with suppliers directly. Click on the below button to buy this Add On feature", // English
    "Settings.Membership.Addon.Chat.Info.AddOns": "Your Add Ons", // English
    "Settings.Membership.Addon.Chat.Info.AddOns.Name": "Communicate with Supplier", // English
    "Settings.Membership.Addon.Chat.Info.Amount": "Plan Amount", // English
    // eslint-disable-next-line
    "Settings.Membership.Addon.Chat.Info.Amount.Text": "{{value}}/month", // English
    "Settings.Membership.Addon.Chat.Info.Cycle": "Next Billing Cycle", // English
    "Settings.Membership.Addon.Chat.Modal.Title": "We’re sorry to see you go", // English
    "Settings.Membership.Addon.Chat.Modal.Description": "Once you click <1>“Cancel Add On”</1>, you will no longer be able to communicate with suppliers directly. ", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Title": "Keep Add On", // English
    "Settings.Membership.Addon.Chat.Modal.KeepAddonButton.Label": "Keep My Add On for $5.99", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Title": "Cancel Add On", // English
    "Settings.Membership.Addon.Chat.Modal.CancelAddonButton.Label": "Cancel Add On", // English

    "Settings.StoreSettings.StoreInformation.Title" : "Store Information",  // English
    "Settings.StoreSettings.StoreInformation.Subtitle": "This section contains basic information about your store.", // English
    "Settings.StoreSettings.ImportedProducts.Title" : "Imported Product Stats", // English
    "Settings.StoreSettings.ImportedProducts.Subtitle" : "This section contains information about all your imported and premium products.", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductsTitle" : "Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.PremiumTitle" : "Premium Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.AliExpressTitle" : "AliExpress Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.UnlimitedProducts" : "Unlimited Products", // English
    "Settings.StoreSettings.ImportedProducts.Products.ProductQuantity" : "Out of {{productQuantity}}", // English
    "Settings.StoreSettings.BrandedInvoicing.Title" : "Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Subtitle" : "This section can be used to setup branded invoices for your store.", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Title" : "Activate Branded Invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Subtitle" : "Include Branded Invoicing on All Orders", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Error": "There was an error setting the branded invoicing", // English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmail": "Contact Email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailPlaceholder": "Please enter your store's contact email...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.ContactEmailError": "please enter a valid email", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Phone": "Phone Number", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.PhonePlaceholder": "Please enter your store's phone number...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URL": "Invoice Store URL", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLPlaceholder": "Please enter your store's custom URL address...", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.URLTooltip": "You can include a custom domain URL in your invoice.", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.Note": "Personal Note", //English
    "Settings.StoreSettings.BrandedInvoicing.Activate.NotePlaceholder": "Please enter a message that you want to include in your branded invoice...", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Title": "Shop Logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadText": "<1>Upload a file</1> or drag and drop", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.UploadDescription": "PNG, JPG, GIF up to 10MB", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.ErrorSize": "The uploaded file is too large", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Error": "There was an error uploading the file", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Reupload": "Re-upload File", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Success": "The image was saved successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Mutate.Error": "There was an error updating the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Success": "The image was deleted successfully", //English
    "Settings.StoreSettings.BrandedInvoicing.BrandedInvoiceLogo.Delete.Error": "There was an error deleting the logo", //English
    "Settings.StoreSettings.BrandedInvoicing.Success": "Branded invoice has been updated", // English
    "Settings.StoreSettings.BrandedInvoicing.Error": "Currently unable to update your branded invoice", //English
    "Settings.StoreSettings.BrandedInvoicing.Preview": "Preview Invoice", //English
    "Settings.StoreSettings.SquareLocation.Title": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Subtitle": "This section can be used to specify the locations you can sell, based on your Square Integration settings.", // English
    "Settings.StoreSettings.SquareLocation.GetError": "There was an fetching Square Location information",
    "Settings.StoreSettings.SquareLocation.Dropdown.Label": "Square Location", // English
    "Settings.StoreSettings.SquareLocation.Dropdown.Default": "Select Square Location", // English
    "Settings.StoreSettings.SquareLocation.Button.Label": "Save", // English
    "Settings.StoreSettings.SquareLocation.Button.Title": "Change your Square Location", // English
    "Settings.StoreSettings.SquareLocation.Success": "Square Location updated successfully", // English
    "Settings.StoreSettings.SquareLocation.Error": "There was an error on the Square Location update", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Title": "Connect your Store", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.NoStore.Subtitle": "Your shop has not been connected with this account yet.", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Subtitle": "Store Name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.Button": "Connect", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.BlankError": "store name can not be empty", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreUrl": "Store URL", //English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StorePlan": "Your Plan", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.StoreCurrency": "Default Currency", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.UpdateError": "currently unable to update store name", // English
    "Settings.StoreSettings.StoreInformation.ConnectStore.Store.InputPlaceholder": "Please enter a new store name...", //English

    "Settings.AccountInformation.Title": "Account Information", // English
    "Settings.AccountInformation.Subtitle": "This section contains information about your email details and your current active membership plan.", // English
    "Settings.AccountInformation.UpdatePassword.Title": "Update Password", // English
    "Settings.AccountInformation.UpdatePassword.Subtitle": "This section can be used to update and reset your account password.", // English
    "Settings.AccountInformation.Password.Title": "New Password", // English
    "Settings.AccountInformation.Password.Placeholder": "Please enter the new password...", // English
    "Settings.AccountInformation.RepeatPassword.Title": "Repeat Password", // English
    "Settings.AccountInformation.RepeatPassword.Placeholder": "Please re-enter the new password...", // English
    "Settings.AccountInformation.UpdatePassword.Error": "an error occurred while trying to update your password. Please try again", // English
    "Settings.AccountInformation.PaymentInformation.Title": "Payment Information",  // English
    "Settings.AccountInformation.PaymentInformation.Subtitle": "This section contains information about your payment methods for membership and order billing.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Title": "Order Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Order.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Order.SubtitleWithCard": "You have added the following payment methods to pay for your orders.",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Title": "Membership Billing",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.Subtitle": "You have not added any credit/debit card yet. Please add a card below to allow us to process your membership automatically",  // English
    "Settings.AccountInformation.PaymentInformation.Membership.SubtitleWithCard": "You have added the following credit/debit card to process your membership bills automatically.",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteCreditCard": "Delete Card",  // English
    "Settings.AccountInformation.PaymentInformation.NameOnCard": "Name on Card",  // English
    "Settings.AccountInformation.PaymentInformation.CardNumber": "Credit/Debit Card Number",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteSuccess": "your credit/debit card has been removed",  // English
    "Settings.AccountInformation.PaymentInformation.DeleteError": "unable to delete credit card",  // English
    "Settings.AccountInformation.PaymentInformation.AddressPlaceholder": "Enter an address",  // English
    "Settings.AccountInformation.Language.Title": "Language Preference", // English
    "Settings.AccountInformation.Language.Subtitle": "This section can be used to update the language for your Account.", // English
    "Settings.AccountInformation.Language.Label": "Language", // English

    "ExistingPayPalAccount.Email": "PayPal Account Email", // English
    "ExistingPayPalAccount.Button.Label": "Remove Account", // English
    "ExistingPayPalAccount.Button.Title": "Remove linked PayPal account", // English
    "ExistingPayPalAccount.Success": "Your PayPal account was successfully disconnected.", // English

    "ConfirmPayPalDisconnectModal.Title": "Remove PayPal?", // English
    "ConfirmPayPalDisconnectModal.Description": "Once you click <1>\"Remove PayPal\"</1>, your PayPal account will be disconnected. This cannot be undone.", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Label": "Remove PayPal", // English
    "ConfirmPayPalDisconnectModal.DisconnectButton.Title": "Remove PayPal account", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Label": "Cancel", // English
    "ConfirmPayPalDisconnectModal.CancelButton.Title": "Cancel account disconnection", // English

    "Settings.Pricing.Subtitle": "This section can be used to set global product pricing rules for all your products.", // English
    "Settings.Pricing.RulesUpdateSuccess": "Pricing rules updated", // English
    "Settings.Pricing.RulesUpdateError": "Unable to update pricing rules", // English
    "Settings.AdvancedPricing.Subtitle": "Toggle on to add Advanced/Custom Pricing rules for your products", // English
    "Settings.AdvancedPricing.AddNewPricing": "Add New Pricing Rule", // English
    "Settings.AdvancedPricing.AddNewPricingButton": "+ Add New Pricing Rule", // English
    "Settings.AdvancedPricing.Modal.Warning": "By pressing save you will <1>permanently delete</1> any previously saved advanced pricing rules. <2>Are you sure you want to continue?</2>", // English
    "Settings.AdvancedPricing.InvalidToPrice": "From price must be more than To price", // English
    "Settings.AdvancedPricing.ConflictingRule": "One or more of your rules conflict", // English

    "Chat.Title": "Supplier Chat", // English
    "Chat.Start.ButtonText": "Chat with supplier", // English
    "Chat.Start.Button": "Start Chat", // English
    "Chat.Start.Button.Mobile": "Start", // English
    "Chat.SupplierOrderInfo.Status.OrderReceived": "Order Received", // English
    "Chat.SupplierOrderInfo.Status.Processing": "Processing", // English
    "Chat.SupplierOrderInfo.Status.Shipped": "Shipped", // English
    "Chat.SupplierOrderInfo.Status.Delivered": "Delivered", // English
    "Chat.SupplierOrderInfo.Status.Cancelled": "Cancelled", // English
    "Chat.ChatChannelPreview.OrderNumberLabel": "Order no:", // English
    "Chat.EmptyPlaceholder.Warning": "You have not started a conversation with the supplier yet. To contact a supplier, please go to My Orders page and select <1>Contact Supplier</1> from the relevant order. Click <2>here</2> to learn more about the direct messaging feature.", // English
    "Chat.EmptyPlaceholder.Title": "No messages", // English
    "Chat.EmptyPlaceholder.Description": "Click the <1>+ Start Chat</1> button in the top-right to create a message.", // English
    "Chat.NewChatMessageModal.SelectTopic.Description": "Please select a topic for your inquiry", // English

    "Chat.NewChatMessageModal.Title": "New Message", // English
    
    "Chat.NewChatMessageModal.SelectTopic.Option.GeneralInquiry": "General Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.ProductInquiry": "Product Inquiry", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.DeliveredOrderIssues": "Delivered Order Issues", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Processing": "Processing/Shipping Status", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Refund": "Refund", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Feedback": "Feedback", // English
    "Chat.NewChatMessageModal.SelectTopic.Option.Others": "Others", // English
    "Chat.NewChatMessageModal.MessageTextArea.Placeholder": "Write a message...", // English

    "Checkout.Title": "Checkout", // English
    "Checkout.PaymentMethods.Title": "Choose your payment method", // English
    "Checkout.PaymentMethods.PayPal": "PayPal", // English
    "Checkout.PaymentMethods.CreditCard": "Credit/Debit Card", // English
    "Checkout.PaymentMethods.GPay": "Google Pay", // English
    "Checkout.PaymentForm.SavedCard": "Saved Credit/Debit Card", // English
    "Checkout.BillingCycles.Title": "Choose your billing cycle", // English
    "Checkout.BillingCycles.Monthly": "Monthly", // English
    "Checkout.BillingCycles.Yearly": "Yearly", // English
    "Checkout.BillingCycleTabs.Off": "<1></1> Off", // English
    "Checkout.BackButton": "Back", // English
    "Checkout.Features.SafeCheckout": "Guaranteed <strong>safe & secure</strong> checkout", // English
    "Checkout.Features.TrustedByEntrepreneurs.Title": "Trusted by 150,000+ Entrepreneurs", // English
    "Checkout.Features.TrustedByEntrepreneurs.Description": "Our customers have consistenly rated us 5/5.", // English
    "Checkout.Alert.Paypal": "You will be redirected to PayPal to complete your transaction.", // English
    "Checkout.Alert.Shopify": "You will be redirected to Shopify to complete your transaction.", // English
    "Checkout.Alert.Wix": "You will be redirected to Wix to complete your transaction.", // English
    "Checkout.SelectedPlanInfo.Year": "year", // English
    "Checkout.SelectedPlanInfo.Month": "month", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsLeftInAccount": "Credits Left", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.TotalAmount": "Total Amount", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.Credits": "Credits", // English
    "Checkout.SelectedPlanInfo.ProrationInfo.CreditsInfoText": "Prorated Refundable Credits <strong>(<1></1>)</strong> will be created for the unused period of <2></2> - <3></3> and applied on the immediate invoice.", // English
    "Checkout.RedirectUser": "There is something wrong with the selected plan or period.", // English
    "Checkout.Checkboxes.Policies": "By clicking, you agree to the <1>T&C</1>, <2>Privacy Policy</2>, <3>Cancellation Policy</3> and <4>Refund Policy</4>.", // English
    "Checkout.Checkboxes.Refund.Title": "See our Refund Policy", // English
    "Checkout.Checkboxes.Cancellation.Title": "See our Cancellation Policy", // English
    "Checkout.Checkboxes.Terms.Title": "See our Terms of Service", // English
    "Checkout.Checkboxes.Privacy.Title": "See our Privacy Policy", // English
    "Checkout.Button.Claim": "Claim Your Trial", // English
    "Checkout.Button.Free": "Claim Free Trial", // English
    "Checkout.Button.Start": "Start Membership", // English
    "Checkout.Button.Upgrade": "Upgrade to {{ planName }}", // English
    "Checkout.Button.Downgrade": "Downgrade to {{ planName }}", // English
    "Checkout.ChangePlanLink.Text": "Change Plan", // English
    "Checkout.ChangePlanLink.Title": "Change the selected plan", // English
    "Checkout.PlanPrice.TrialDaysLeft": "{{ remainingTrialDays }} day(s) Trial Period", // English
    "Checkout.TrialNotification": "We won't charge you until your free trial ends on <b>{{ date }}<b>.", // English
    "Checkout.PlanPeriodToggle.DiscountIntro": "Save with annual billing", // English
    // eslint-disable-next-line
    "Checkout.PlanPeriodToggle.DiscountText": "${{ value }}.00 off", // English
    "Checkout.PlanFeatures.UniqueProducts": "<strong>{{ value }}</strong> unique products", // English
    "Checkout.PlanFeatures.ChatSupport": "<strong>Chat 24x7</strong> support", // English
    "Checkout.PlanFeatures.PremiumProducts": "<strong>{{ value }}</strong> premium products", // English
    "Checkout.PlanFeatures.BrandedInvoicing": "Branded Invoicing", // English
    "Checkout.PlanFeatures.Communicate": "Communicate with supplier", // English
    "Checkout.PlanFeatures.Communicate.Tooltip": "Chat directly with suppliers for order related queries", // English
    "Checkout.PlanFeatures.Ebay": "eBay Dropshipping", // English
    "Checkout.PlanFeatures.AliExpress": "AliExpress Dropshipping", // English
    "Checkout.PlanFeatures.SupplierCommunication": "Supplier Communication", // English
    "Checkout.PlanFeatures.ImageSearch": "Image Search", // English
    "Checkout.PlanFeatures.WinningProducts": "Winning Products", // English
    "Checkout.ButtonPaymentText.Yearly": "Yearly plans do not have a trial period, <1>you will be charged in full immediately.</1>", // English
    "Checkout.ButtonPaymentText.Monthly": "After trial you will pay {{ price }}/month. <1>Cancel Anytime.</1>", // English
    "Checkout.CouponCode.Button.Title": "Add coupon code", // English
    "Checkout.CouponCode.Button.Label": "Have a Coupon Code?", // English
    "Checkout.CouponCode.Input.Placeholder": "Enter your coupon code here", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Title": "Apply coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Submit.Label": "Apply", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Title": "Remove coupon", // English
    "Checkout.CouponCode.Input.CouponButton.Remove.Label": "Remove", // English
    "Checkout.CouponCode.Error.Required": "This field is required", // English

    "ErrorModal.Button.Text":"Talk to Customer Support", // English
    "ErrorModal.Button.Title":"Click the button to talk to Customer Support about this error", // English

    "AddonSuccessModal.Title": "Hooray!! Purchase Successful. You can start communicating with suppliers", // English
    "AddonSuccessModal.Button.Title":"Click the button start chatting", // English
    "AddonSuccessModal.Button.Label":"Start chatting now", // English
    "ChatAddonSlider.Slides.New": "New", // English

    "Cancellation.Back": "Back", // English
    "Cancellation.Intro": "Hey {{name}} 👋, save more by switching to <1>annual payments</1>", // English

    "Benefits.Benefit1.Title": "Premium Products", // English
    "Benefits.Benefit1.Description": "Sell exclusive, highly discounted & fast shipping products on your shop.", // English
    "Benefits.Benefit2.Title": "Branded Invoicing", // English
    "Benefits.Benefit2.Description": "Add unique brand for your customers' packages.", // English
    "Benefits.Benefit3.Title": "Unique Products", // English
    "Benefits.Benefit3.Description": "Discover Unique and winning products that your target audience loves and sell like crazy.", // English
    "Benefits.Benefit4.Title": "Bulk Checkout", // English
    "Benefits.Benefit4.Description": "Process all your orders with just one click.", // English
    "Benefits.Benefit5.Title": "24/7 Chat Support", // English
    "Benefits.Benefit5.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Benefit6.Title": "Supplier Sourcing", // English
    "Benefits.Benefit6.Description": "Reliable US, EU and Global suppliers with fast shipping on high quality winning products.", // English
    "Benefits.Benefit7.Title": "Email Support", // English
    "Benefits.Benefit7.Description": "Share your queries with our Customer Support team, anytime.", // English
    "Benefits.Cancel.Button.Label": "Downgrade Membership", // English
    "Benefits.Cancel.Button.Title": "Downgrade your membership", // English
    "Benefits.Keep.Button.Label": "Keep My Benefits", // English
    "Benefits.Keep.Button.Title": "Keep benefits", // English

    "HelperCenter.Tooltip": "Help & Resources", // English

    "Pagination.Page": "Page", // English
    "Pagination.Of": "of {{value}}", // English
    "Pagination.First.Title": "Go to first page", // English
    "Pagination.Previous.Title": "Go to previous page", // English
    "Pagination.Next.Title": "Go to next page", // English
    "Pagination.Last.Title": "Go to last page", // English

    "Orders.Title.Head": "My Orders - Spocket", // English
    "Orders.PageTitle": "Orders", // English
    "Orders.OrderInfo.OrderNumber": "Order No. <1>{{orderNumber}}</1>", // English
    "Orders.OrderInfo.OrderDate": "Date: <1>{{orderDate}}</1>", // English
    "Orders.OrderInfo.OrderDate.Tooltip": "Date when this order was placed on your store.", // English
    "Orders.OrderInfo.CustomerModal.Button.Title": "See order's customer information", // English
    "Orders.OrderInfo.CustomerModal.Button.Label": "Customer Info", // English
    "Orders.OrderInfo.Sample": "Sample Order", // English
    "Orders.OrderInfo.Status": "{{integrationName}} Status: <1>{{status}}</1>", // English
    "Orders.Empty.Top": "Your orders list is empty", // English
    "Orders.Empty.Bottom": "Head over to the search page and start looking for great products to sell", // English
    "Orders.Empty.Button.Search.Title": "Go to search page to add products to your store", // English
    "Orders.Empty.Button.Search.Label": "Go to search page ", // English
    "Orders.Search.NoOrdersFound.Title": "No results for your search", // English
    "Orders.Search.NoOrdersFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English
    "Orders.Checkout.Button.Label": "Checkout", // English
    "Orders.Checkout.Button.Title": "Checkout these items", // English
    "Orders.Cancel.Button.Enabled": "Cancel this order", // English
    "Orders.Cancel.Button.Disabled": "This order can't be canceled", // English
    "Orders.Checkout.ErrorsTooltip.Title": "Error(s):", // English
    "Orders.OrderStatus.DropdownLabel": "Order Status", // English
    "Orders.OrderStatus.ChipLabel": "Order status: ", // English
    "Orders.OrderStatus.Cancelled": "Canceled", // English
    "Orders.OrderStatus.Refunded": "Refunded", // English
    "Orders.OrderStatus.Unpaid": "Unpaid", // English
    "Orders.OrderStatus.Paid": "Order Placed", // English
    "Orders.OrderStatus.Processing": "Processing", // English
    "Orders.OrderStatus.Shipping": "Shipped", // English
    "Orders.ExportButton.Label": "Download Order History CSV", // English
    "Orders.ExportModal.Title": "Select Range", // English
    "Orders.ExportModal.From": "From", // English
    "Orders.ExportModal.To": "To", // English
    "Orders.ExportModal.Submit": "Download CSV", // English
    "Orders.Search.Label": "Search through your orders", // English
    "Orders.Search.Placeholder": "Order number", // English
    "Orders.Search.Button": "Search", // English
    "Orders.SyncButton.Tooltip": "Import any new orders on your store to Spocket.", // English
    "Orders.SyncButton.Button": "Synchronize Orders", // English
    "Orders.Table.Header.Images": "Images", // English
    "Orders.Table.Header.Title": "Title", // English
    "Orders.Table.Header.Quantity": "Quantity", // English
    "Orders.Table.Header.UnitCost": "Unit Cost", // English
    "Orders.Table.Header.CheckoutDate": "Checkout Date", // English
    "Orders.Table.Header.Supplier": "Supplier", // English
    "Orders.Table.Header.Status": "Status", // English
    "Orders.Table.Header.Action": "Action", // English
    "Orders.Table.Header.Total": "Total", // English
    "Orders.Table.OutOfStock": "Out Of Stock", // English
    "Orders.StatusColumn.Refunded.Tag": "Refunded", // English
    "Orders.StatusColumn.Refunded.Tooltip": "<1>{{value}}</1> has been refunded for this order.", // English
    "Orders.StatusColumn.AliExpress.Tooltip": "Order payment must be made on AliExpress. Any order updates or cancellations will be available on AliExpress.", // English
    "Orders.ActionsColumn.Tooltip.Shipping": "Get shipping details", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Cancelled": "Tracking not available", // English
    "Orders.ActionsColumn.Tooltip.Shipping.Unavailable": "No shipping details available yet", // English
    "Orders.ActionsColumn.Tooltip.Invoice": "Preview order invoice", // English
    "Orders.ActionsColumn.Tooltip.Invoice.Cancelled": "Invoice not available", // English
    "Orders.ActionsColumn.Tooltip.Receipt": "Preview order receipt", // English
    "Orders.ActionsColumn.Tooltip.Receipt.Cancelled": "Receipt not available", // English
    "Orders.ActionsColumn.Tooltip.Payment": "This payment is still being processed", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Tooltip": "Please verify this transaction with your card provider", // English
    "Orders.ActionsColumn.Tooltip.ConfirmPayment.Error": "There was an error with payment confirmation", // English
    "Orders.CancelOrderModal.Title": "Are you sure?", // English
    "Orders.CancelOrderModal.Description": "Once canceled, you will no longer be able to place this order. This action cannot be undone.", // English
    "Orders.CancelOrderModal.Back.Title": "Go back", // English
    "Orders.CancelOrderModal.Back.Label": "Go Back", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Title": "Cancel this order", // English
    "Orders.CancelOrderModal.ConfirmCancelation.Label": "Yes, Cancel Order", // English
    "Orders.CancelButton.Error": "There was an error canceling the order, plase contact support", // English
    "Orders.PaymentConfirmation.Button.Label": "Confirm Payment", // English
    "Orders.PaymentConfirmation.Button.Title": "Confirm payment", // English
    "Orders.AlibabaDetails.Button.Label": "Order Details", // English
    "Orders.AlibabaDetails.Button.Title": "See more information about this order", // English
    "Orders.OrderErrors.Warning": "Warning", // English
    "Orders.Bulk.Button.Label": "Bulk checkout orders", // English
    "Orders.Bulk.Button.Title": "Bulk checkout selected orders", // English
    "Orders.Bulk.Error": "There was an error processing your order.", // English
    "Orders.Bulk.Text.NoOrders": "No orders available", // English
    "Orders.Bulk.Text.SelectAll": "Select all unpaid orders", // English
    "Orders.Bulk.Text.Selected": "{{value}} unpaid order(s) selected", // English

    "ChatButton.Tooltip": "Chat with order supplier", // English
    "ChatButton.Tooltip.Cancelled": "Chat not available", // English
    
    "ModalWithSteps.Cancel": "Cancel", // English
    "ModalWithSteps.GoBack": "Go Back", // English
    "ModalWithSteps.Continue": "Continue", // English
    "ModalWithSteps.Confirm": "Confirm", // English
    "ModalWithSteps.StepsCount": "Step {{ currentStep }} of {{ stepsLength }}", // English

    "Orders.OrderConfirmationSteps.PaypalTotal": "PayPal", //English
    "Orders.OrderConfirmationSteps.StripeTotal": "Credit Card", //English
    "Orders.OrderConfirmationSteps.Title": "Order Confirmation", // English
    "Orders.OrderConfirmationSteps.Subtotal": "Subtotal", // English
    "Orders.OrderConfirmationSteps.ShippingCost": "Shipping Cost", // English
    "Orders.OrderConfirmationSteps.TransactionFees": "Transaction Fee", // English
    "Orders.OrderConfirmationSteps.GrandTotal": "Grand Total", // English
    "Orders.OrderConfirmationSteps.OrderNote.Label": "Note to Supplier", // English
    "Orders.OrderConfirmationSteps.OrderNote.Placeholder": "Your message...", // English
    "Orders.OrderConfirmationSteps.OrderCheckbox": "I confirm that the orders above are accurate and I would like to proceed with checking out these orders", // English
    "Orders.OrderConfirmationSteps.AliExpress": "Calculated on AliExpress", // English
    "Orders.OrderConfirmatinoSteps.Error": "There was an error processing the order", // English

    "Orders.AlibabaOrdersModal.Status.Paid": "Completed", // English
    "Orders.AlibabaOrdersModal.Status.Paying": "Pending", // English
    "Orders.AlibabaOrdersModal.Status.PayFailed": "Failed", // English
    "Orders.AlibabaOrdersModal.Status.Shipped": "Shipped", // English
    "Orders.AlibabaOrdersModal.Status.WaitingPayment": "Waiting Payment", // English

    "Orders.CustomerModal.Name": "Name", // English
    "Orders.CustomerModal.Email": "Email", // English
    "Orders.CustomerModal.Address": "Address", // English
    
    "Products.Title": "My Products - Spocket", // English
    "Products.PageTitle": "Products", // English
    "Products.Product.Button.ViewStore.Label": "View on Store", // English
    "Products.Product.Button.ViewStore.Title": "View product on store", // English
    "Products.Product.Button.RestoreImportList.Label": "Restore to Import List", // English
    "Products.Product.Button.RestoreImportList.Title": "Restore product to Import List", // English
    "Products.Product.Button.RestoreImportList.Success": "Product successfully restored", // English
    "Products.Product.Button.RestoreImportList.Error": "There was an error restoring the product", // English
    "Products.Product.Button.AmazonTooltip": "Listing currently processing. Refresh to view updates.", // English
    "Products.Product.RemoveProduct.Button.Title": "Remove product from store", // English
    "Products.Product.RemoveProduct.Success": "Product successfully removed", // English
    "Products.Product.RemoveProduct.Error": "There was an error removing the product", // English
    "Products.Product.RemoveProduct.Tooltip": "Remove product from your store", // English
    "Products.Product.Tags.Premium": "Premium", // English
    "Products.Product.Tags.Inactive": "Inactive", // English
    "Products.Product.Tags.Inactive.Tooltip": "This product has been deactivated by the supplier.", // English
    "Products.Product.Tags.Stock": "Out of Stock", // English
    "Products.Checkbox.CheckboxText": "Don't show me this message again", // English
    "Products.RestoreImportListModal.Title": "Restore to Import List?", // English
    "Products.RestoreImportListModal.Text": "This product will be immediately <1>removed</1> from your store and <1>restored</1> to your Import List.", // English
    "Products.RestoreImportListModal.Button.Cancel.Title": "Cancel restore", // English
    "Products.RestoreImportListModal.Button.Cancel.Label": "Cancel", // English
    "Products.RestoreImportListModal.Button.Restore.Title": "Restore product to Import List", // English
    "Products.RestoreImportListModal.Button.Restore.Label": "Restore to Import List", // English
    "Products.RemoveItemModal.Title": "Remove From Store?", // English
    "Products.RemoveItemModal.Text": " This product will be immediately <1>removed</1> from your store. This action cannot be undone.", // English
    "Products.RemoveItemModal.Button.Cancel.Title": "Cancel removal", // English
    "Products.RemoveItemModal.Button.Cancel.Label": "Cancel", // English
    "Products.RemoveItemModal.Button.Restore.Title": "Remove product from Store", // English
    "Products.RemoveItemModal.Button.Restore.Label": "Remove From Store", // English
  
    "Products.Filters.Supplier": "Supplier", // English
    "Products.Filters.Supplier.Placeholder": "Select supplier", // English
    "Products.Filters.ProductsFrom": "Products from", // English
    "Products.Filters.ProductsFrom.Spocket": "Spocket", // English
    "Products.Filters.ProductsFrom.AliExpress": "AliExpress", // English
    "Products.Filters.ProductsFrom.Placeholder": "Select products from", // English
    "Products.Empty.Button.Search.Label": "Search Products", // English
    "Products.Empty.Button.Search.Title": "Search for products", // English
    "Products.Empty.Link.DiscoverProducts.Label": "How to find products", // English
    "Products.Empty.Link.DiscoverProducts.Title": "Discover how to find products", // English
    "Products.Search.NoProductsFound.Title": "No results for your search", // English
    "Products.Search.NoProductsFound.Description": "Try adjusting your search or filter to find what you're looking for.", // English

    "ImageSearchSlider.Title": "Image Search", // English
    "ImageSearchSlider.Button.Search.Label": "Find Trending Products", // English
    "ImageSearchSlider.Button.Search.Title": "Find Trending Products", // English
    "ImageSearchSlider.Slides.Title": "Search products with an image!", // English
    "ImageSearchSlider.Slide01": "Find trending products related to the uploaded image.", // English
    "ImageSearchSlider.Slide02": "Add trending products to the Import List in one click.", // English
  
    "FeedbackDynamicStoreModal.Title": "Your opinion matters!", // English
    "FeedbackDynamicStoreModal.Description": `We have worked hard to create the best experience for you. If the Spocket app has added value to your entrepreneurial journey, <1>Please give us your love</1> and support by sharing  your experience on {{storeName}}.`, // English
    "FeedbackDynamicStoreModal.Button.Title": "Rate Spocket on {{storeName}}", // English

    "WinningProducts.Title.Head": "Winning Products - Spocket", // English
    "WinningProducts.Title": "Winning Products", // English

    "AliExpressConnectAlert.Expired.Title": "Your AliExpress authentication has expired!", // English
    "AliExpressConnectAlert.ExpiringSoon.Title": "Your AliExpress authentication is expiring in {{value}} day(s)!", // English
    "AliExpressConnectAlert.Connect.Title": "Connect an AliExpress account to auto-sync your orders from Spocket", // English
    "AliExpressConnectAlert.Description": "AliExpress requires users to periodically re-authorize third-party apps for security purposes. Please re-authorize Spocket immediately for automated order syncing.", // English
    "AliExpressConnectAlert.Button.Label": "Connect AliExpress", // English
    "AliExpressConnectAlert.Button.Title": "Connect AliExpress account", // English

    "ConnectAliexpressModal.Title": "Connect AliExpress Account", // English
    "ConnectAliexpressModal.Text": "Connect your AliExpress account to sync orders from Spocket", // English
    "ConnectAliexpressModal.Button.Cancel.Title": "Connect later", // English
    "ConnectAliexpressModal.Button.Cancel.Label": "I'll do it later", // English
    "ConnectAliexpressModal.Button.Restore.Title": "Connect AliExpress account", // English
    "ConnectAliexpressModal.Button.Restore.Label": "Connect AliExpress", // English
    "ConnectAliexpressModal.ConnectLink.Error": "There was an error fetching the link to connect your account", // English
    "ConnectAliexpressModal.SaveOptOut.Error": "There was an error processing your selection", // English

    "AliexpressRegisterComponent.GenericError": "There was an error connecting your account. Please contact our support.", // English
  
    "WinningProducts.Modal.Title": "Find <strong>Winning Products</strong> Now!", // English
    "WinningProducts.Modal.Subtitle": "Sell the hottest products with the click of a button!", // English
    "WinningProducts.Modal.Lead.BestSellers": "Best Sellers", // English
    "WinningProducts.Modal.Lead.Ships": "Ships From US/EU", // English
    "WinningProducts.Modal.Lead.New": "New Listings Weekly", // English
    "WinningProducts.Modal.Button": "Unlock this page", // English
    "WinningProducts.SkipButton.Title": "Skip for now", // English
        
    "PaymentModal.Title": "Payment Method", // English
    "PaymentModal.Subtitle": "You have not added any payment method yet. Please add a credit card or connect your PayPal to pay for your orders.",// English
    "PaymentModal.Card": "Credit Card",// English
    "PaymentModal.CardCTA": "Add Credit Card",// English
    "PaymentModal.Email": "PayPal Account Email",// English
    "PaymentModal.OnlyPaypal": "This supplier only accepts payments via PayPal.",// English
    "PaymentModal.OnlyStripe": "This supplier only accepts payments via credit card.",// English
    "PaymentModal.SomeOnlyPayPal": "Some orders can only be placed using PayPal.",// English
    "PaymentModal.SomeOnlyStripe": "Some orders can only be placed using credit card.",// English
    "PaymentModal.DisabledMessage": "<b>Warning</b>: Some orders excluded due to missing payment information",//English

    "UpgradeCouponModal.Title": "Select a Spocket plan <1>NOW</1> and get <2>2 weeks free.</2>", // English
    "UpgradeCouponModal.SkipButton.Title": "Skip for now", // English
    "UpgradeCouponModal.SkipButton.Label": "Skip for now", // English
    "UpgradeCouponModal.Description.Starter": "Boost sales", // English
    "UpgradeCouponModal.Description.Pro": "Maximize sales", // English
    "UpgradeCouponModal.Description.Empire": "Manage sales at scale", // English
    "UpgradeCouponModal.Button.Label": "Try for FREE", // English
    "UpgradeCouponModal.Button.Title": "Try this plan for free", // English

    "SmartliModal.Title": "Want to Supercharge Your Sales?", // English
    "SmartliModal.Subtitle": "Smartli's AI Text Generator Can Help!", // English
    "SmartliModal.ListTitle": "Auto-generate", // English
    "SmartliModal.ListItem.One": "Fast SEO optimized product descriptions", // English
    "SmartliModal.ListItem.Two": "Ads for Facebook, Google & major social platforms", // English
    "SmartliModal.ListItem.Three": "Blogs for improving SEO", // English
    "SmartliModal.Button.Text": "Try Smartli Free", // English
    "SmartliModal.CancelButton.Text": "Do it manually", // English
    "SmartliModal.HoverButton.Text": "Ask AI to write", // English

    "Academy.SmallTitle": "SPOCKET ACADEMY", // English
    "Academy.Title": "Start and Grow Your", // English
    "Academy.TitleHighlight": "Dropshipping Business.", // English
    "Academy.Description": "Learn everything you need to launch your site, drive traffic and generate sales with over 40 premium courses designed by industry experts to help you succeed.", // English
    "Academy.Description.LineOne": "Learn everything you need to launch your site, drive traffic and", // English
    "Academy.Description.LineTwo": "generate sales with over 40 premium courses designed by industry", // English
    "Academy.Description.LineThree": "experts to help you succeed.", // English
    "Academy.Button": "Start Learning", // English
    "Academy.CourseButton": "View courses", // English
    "Academy.Course.Title": "Courses", // English
    "Academy.Course.One.Title": "Email", // English
    "Academy.Course.One.Description": "02 Lessons", // English
    "Academy.Course.Two.Title": "Facebook", // English
    "Academy.Course.Two.Description": "07 Lessons", // English
    "Academy.Course.Three.Title": "Instagram", // English
    "Academy.Course.Three.Description": "03 Lessons", // English
    "Academy.Course.Four.Title": "Sales", // English
    "Academy.Course.Four.Description": "12 Lessons", // English
    "Academy.Course.Five.Title": "SEO", // English
    "Academy.Course.Five.Description": "02 Lessons", // English
    "Academy.Course.Six.Title": "Starting Fresh", // English
    "Academy.Course.Six.Description": "02 Lessons", // English
    "Academy.Course.Seven.Title": "Traffic", // English
    "Academy.Course.Seven.Description": "04 Lessons", // English
  }
};
