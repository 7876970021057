// React and Redux
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Libs
import paths from "routes/paths";

// Action
import { verify } from "utils/actions";
import { signInUser, signOutUser } from "utils/actions";

// Utils
import { isAuthenticated } from "utils/features";
import { useNavigate, useLocation } from "react-router-dom";

export default AuthenticatedComponent => {
  const Auth = ({ verify }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const accessDenied = () => {
      saveUrlToRedirectAfterLogin();
      navigate(paths.auth.login);
    };

    const saveUrlToRedirectAfterLogin = () => {
      if (pathname !== paths.auth.login) {
        localStorage.setItem("redirect_to_url_after_signin", pathname);
      }
    };

    useEffect(() => {
      const currentToken = isAuthenticated();
      if (!currentToken) {
        accessDenied();
      } else {
        verify(navigate);
      }
    }, []);

    return isAuthenticated() ? <AuthenticatedComponent /> : null;
  };

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ verify, signInUser, signOutUser }, dispatch);
  }

  return connect(null, mapDispatchToProps)(Auth);
};
