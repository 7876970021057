import React, { lazy } from "react";

import { QueryParamProvider, StringParam, useQueryParams } from "use-query-params";

import requireAuth from "components/RequireAuth";
import requireShop from "components/requireShop";
import requireNonAmazonAccount from "components/RequireNonAmazonAccount";

import routes from "./routes";
import redirects from "./redirects";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { isSpocketUser } from "utils/email";

import { ErrorPage } from "pages/ErrorPage";
const Navigation = lazy(() => import("components/Navigation"));

const navWrapper = Component => props => {
  const [query] = useQueryParams({
    pricing: StringParam,
    show_rating: StringParam
  });

  return (
    <Navigation {...props} pricing={query.pricing} showRating={query.show_rating}>
      <Component />
    </Navigation>
  );
};

const WrapperUseQueryParams = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <QueryParamProvider
      history={{ replace: navigate, push: navigate }}
      location={location}
      adapter={ReactRouter6Adapter}
    >
      {children}
    </QueryParamProvider>
  );
};

export const makeRedirects = () => {
  return redirects.map(({ from, to }) => ({
    path: from,
    element: <Navigate to={to} replace />
  }));
};

export const makeRoutes = () => {
  const isSpocketUserVal = isSpocketUser();

  return [
    ...routes
      .map(({ path, Component, app, shop, blockedForAmazonUsers, spocketUser }) => {
        if (app) {
          Component = requireAuth(navWrapper(Component));
        }

        if (shop) {
          Component = requireShop(Component);
        }

        if (blockedForAmazonUsers) {
          Component = requireNonAmazonAccount(Component);
        }

        if (spocketUser !== undefined && spocketUser !== isSpocketUserVal) return null;

        return {
          path,
          element: (
            <WrapperUseQueryParams>
              <Component />
            </WrapperUseQueryParams>
          ),
          errorElement: isSpocketUserVal ? undefined : "Something has gone wrong, please contact support!"
        };
      })
      .filter(t => t),
    { errorElement: <ErrorPage /> }
  ];
};
